import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextareaAutosize,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import './DeclineDocument.css';

const DeclineDocument = ({ isApprovingDocument, open, handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [remark, setRemark] = useState('');

  const onSubmit = async () => {
    if (!isApprovingDocument) handleSubmit(remark);
  };

  const handleChangeRemark = (event) => {
    setRemark(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="wrapper-reject-project">
        <DialogTitle
          style={{
            paddingBottom: '0',
          }}
          onClose={handleClose}
        >
          <p className="dialog-title">{t('SignatureRequestDeclineConfirmation')}</p>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            size="large"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: '0',
            paddingRight: '80px',
          }}
        >
          <p className="dialog-content">{t('SignatureRequestDeclineConfirmationDescription')}</p>
          <TextareaAutosize
            className="text-area-reason"
            aria-label="empty textarea"
            minRows={8}
            value={remark}
            onChange={handleChangeRemark}
            placeholder={t('SignatureRequestDeclineReason')}
          />
        </DialogContent>
        <DialogActions
          style={{
            padding: '20px 80px',
          }}
        >
          <div
            onClick={handleClose}
            className={`${
              isApprovingDocument ? 'disabled-btn' : 'light-btn'
            } action-button uppercase mr1`}
          >
            {t('SignatureRequestCancelButton')}
          </div>
          <div
            onClick={onSubmit}
            className={`${
              isApprovingDocument ? 'disabled-btn' : 'danger-btn uppercase'
            } MuiButton-containedPrimary w-45 action-button`}
          >
            {t('SignatureRequestDeclineProjectButton')}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeclineDocument;
