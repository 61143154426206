import { Box, Button, Dialog, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PREFIX = 'videoSignDialog';

export const classes = {
  timer: `${PREFIX}-timer`,
  lessTimer: `${PREFIX}-less-timer`,
  dialogContent: `${PREFIX}-dialog-content`,
  dialogContentRed: `${PREFIX}-dialog-content-red`,
  dialogTitle: `${PREFIX}-dialog-title`,
  dialogTitleRed: `${PREFIX}-dialog-title-red`,
};

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'bringToFront',
})(({ theme, bringToFront }) => ({
  borderRadius: '8px',
  zIndex: bringToFront ? '9999' : 'auto',
  [`.${classes.dialogContent}`]: {
    ...theme.typography.body_medium,
    color: theme.palette.text.primary,
  },
  [`.${classes.dialogContentRed}`]: {
    ...theme.typography.label_large,
    color: theme.palette.error.main,
  },
  [`.${classes.dialogTitle}`]: {
    ...theme.typography.headline_small,
  },
  [`.${classes.dialogTitleRed}`]: {
    ...theme.typography.headline_small,
    color: theme.palette.error.main,
  },
}));

export const RedBox = styled(Box)(({ theme }) => ({
  background: theme.palette.error.extraLight,
  padding: theme.spacing(4),
  borderRadius: 8,
}));

export const RedButton = styled(LoadingButton)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  background: theme.palette.error.extraLight,
  color: theme.palette.error.main,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.error.extraLight,
  },
}));

export const LightCancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.supporting.light,
  color: theme.palette.primary.main,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.supporting.main,
  },
}));
