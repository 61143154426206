import { Button, List, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useDedocoVideoSigning from '../../../../hooks/useDedocoVideoSigning';
import { Box, classes, MAX_WIDTH } from '../Sidebar.style';
import ChooseNextActorItem from './ChooseNextActorItem';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DVideoSignEvents } from '../../../../hooks/useDedocoVideoSigning/constants';
import { CypressIds } from '../../../../constant/cypressIds';

export default function HostChooseNextActor() {
  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const {
    getAttendees,
    getActingOrder,
    nextAction,
    registerEvent,
    getRoom,
    isSigningSessionComplete,
    documentToSign,
  } = useDedocoVideoSigning();

  useEffect(() => {
    const unregister = registerEvent(DVideoSignEvents.CHOOSE_NEXT_ACTION, () => {
      setSelectedNextActor('');
    });
    return () => unregister();
  }, [registerEvent]);

  const attendees = getAttendees();
  const { actingOrder } = getActingOrder();
  const room = getRoom();

  const [selectedNextActor, setSelectedNextActor] = useState();
  const [displayChooseNextActorPanel, setDisplayChooseNextActorPanel] = useState(true);

  useEffect(() => {
    const unregisterNextAction = registerEvent(DVideoSignEvents.NEXT_ACTION, () => {
      // once receive next-action event, means choose next actor success
      // should hide the choose next actor panel
      setDisplayChooseNextActorPanel(false);
    });
    const unregisterChooseNextAction = registerEvent(DVideoSignEvents.CHOOSE_NEXT_ACTION, () => {
      // once receive choose-next-action event
      // should display the choose next actor panel
      setDisplayChooseNextActorPanel(true);
      setSelectedNextActor('');
    });

    const unregisterLeftCall = registerEvent(DVideoSignEvents.LEFT_CALL, ({ leftCallAttendee }) => {
      // once receive left-call event, check if the left call attendee is the current actor
      // if yes, should display the choose next actor panel
      if (room.nextActor?.userId === leftCallAttendee.userId) {
        setDisplayChooseNextActorPanel(true);
        setSelectedNextActor('');
      }
    });
    return () => {
      unregisterNextAction();
      unregisterChooseNextAction();
      unregisterLeftCall();
    };
  }, [registerEvent, room.nextActor?.userId]);

  const confirmNextActor = useCallback(() => {
    nextAction(id, documentToSign.data.recipientId, selectedNextActor);
  }, [id, nextAction, documentToSign.data.recipientId, selectedNextActor]);

  return (
    <>
      {displayChooseNextActorPanel && !isSigningSessionComplete() && (
        <Box sx={{ marginTop: theme.spacing(6) }}>
          <Stack>
            <Typography className={classes.title} variant="label_medium" align="left" gutterBottom>
              {t('SelectNextActor')}
            </Typography>
            <List sx={{ width: '100%', maxWidth: MAX_WIDTH }}>
              {actingOrder.map(
                (item, index) =>
                  attendees.includes(item.userId) &&
                  !item.acted && (
                    <ChooseNextActorItem
                      index={index}
                      key={item.userId}
                      selected={selectedNextActor === item.userId}
                      onSelect={() => {
                        setSelectedNextActor(item.userId);
                      }}
                      {...item}
                    />
                  ),
              )}
            </List>
            <Button
              disabled={!selectedNextActor}
              variant="contained"
              onClick={confirmNextActor}
              sx={{ marginTop: theme.spacing(6) }}
              data-cypress={CypressIds.confirmSignerButton}
            >
              {t('SelectNextActorConfirm')}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
