import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import FieldItem from './FieldItem';
import {
  classes,
  FieldAccordion,
  FieldAccordionDetails,
  FieldAccordionSummary,
} from './Fields.style';

function Fields({ type, title, items }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();

  const toggleAccordion = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const isFocused = false;
  const getBorder = () => {
    if (isFocused) {
      return `1px solid ${theme.palette.primary.main} !important`;
    } else {
      return `1px solid ${theme.palette.background.paper} !important`;
    }
  };

  const getCircleBackground = () => {
    if (isFocused) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.supporting.main;
    }
  };

  const getCircleColor = () => {
    if (isFocused) {
      return theme.palette.primary.contrastText;
    } else {
      return theme.palette.supporting.contrastText;
    }
  };

  if (items.length === 0) return null;

  return (
    <FieldAccordion
      expanded={isExpanded}
      sx={{ boxShadow: 'none', border: getBorder() }}
      elevation={0}
      disableGutters
    >
      <FieldAccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordion}>
        <Typography className={classes.fieldLabel} variant="label_medium">
          {title}
          <div
            className={classes.numberOfFields}
            style={{ backgroundColor: getCircleBackground(), color: getCircleColor() }}
          >
            {items.length}
          </div>
        </Typography>
      </FieldAccordionSummary>
      <FieldAccordionDetails>
        {items.map((item) => (
          <FieldItem key={item.fieldId} type={type} item={item} />
        ))}
      </FieldAccordionDetails>
    </FieldAccordion>
  );
}

export default Fields;
