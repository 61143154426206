import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { removeScreenTrack, setSharingScreen } from '../../../redux/slices/twilioSlice/twilioSlice';

export default function ScreenTrack({ name, screen, room, participantId }) {
  const dispatch = useDispatch();
  const shareScreenContainerRef = useRef();

  useEffect(() => {
    const toggleZoom = (trackElement) => {
      if (!trackElement.classList.contains('video-zoomed')) {
        // zoom in
        trackElement.classList.add('video-zoomed');
      } else {
        // zoom out
        trackElement.classList.remove('video-zoomed');
      }
    };

    const trackElement = screen.attach();
    shareScreenContainerRef.current.appendChild(trackElement);

    trackElement.addEventListener('click', () => {
      toggleZoom(trackElement);
    });

    screen.mediaStreamTrack.onended = () => {
      if (shareScreenContainerRef.current?.childNodes?.length > 0) {
        shareScreenContainerRef.current?.removeChild(
          shareScreenContainerRef.current?.childNodes?.[0],
        );
      }
      room?.localParticipant?.unpublishTrack(screen);
      dispatch(removeScreenTrack(participantId));
      // when local participant stop sharing
      if (room?.localParticipant?.sid === participantId) {
        dispatch(setSharingScreen(false));
      }
    };

    return () => {
      dispatch(removeScreenTrack(participantId));
      if (room?.localParticipant?.sid === participantId) {
        dispatch(setSharingScreen(false));
      }
    };
  }, [dispatch, room?.localParticipant, screen, participantId]);

  return (
    <div>
      <div
        id="dvideosign-share-screen"
        ref={shareScreenContainerRef}
        style={{
          cursor: 'pointer',
        }}
      />
      <Typography variant="body_small" sx={{ textOverflow: 'wrap' }}>
        {`${name}'s screen`}
      </Typography>
    </div>
  );
}
