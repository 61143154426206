import { useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentToSignSelector } from '../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import useForceUpdate from '../useForceUpdate';
import useDedocoVideoSigningStore from './DedocoVideoSigningStore';

export default function useDedocoVideoSigning({ socket, store } = {}) {
  const { registerComponent, getRoom, isSigningSessionComplete, ...dVideoSignStore } =
    useDedocoVideoSigningStore({ socket, store });

  const documentToSign = useSelector(getDocumentToSignSelector);

  const { isMeetingHost } = documentToSign.data || {};

  const { meetingStartAt } = getRoom();

  const isMeetingStarted = useMemo(() => {
    if (documentToSign?.data?.meeting) {
      return documentToSign?.data?.meeting.isActive;
    }
    return false;
  }, [documentToSign?.data?.meeting]);

  const isCallEndBeforeSigningComplete = useMemo(() => {
    return !isSigningSessionComplete() && !isMeetingStarted && meetingStartAt;
  }, [isMeetingStarted, isSigningSessionComplete, meetingStartAt]);

  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    if (registerComponent) {
      const unregister = registerComponent(forceUpdate);
      return () => {
        unregister();
      };
    }
  }, [forceUpdate, registerComponent]);

  return {
    ...dVideoSignStore,
    isMeetingHost,
    documentToSign,
    isMeetingStarted,
    getRoom,
    isSigningSessionComplete,
    isCallEndBeforeSigningComplete,
  };
}
