import { Divider, Grid } from '@mui/material';
import React from 'react';
import { useDedocoSigning } from '../../../hooks/useDedocoSigning';
import InfoSidebar from '../Sidebar/InfoSidebar';
import { BottomSlideUp, classes, Root } from './SlideUpPanel.styles';
import useRecipientRole from '../../../hooks/useRecipientRole';
import { useTranslation } from 'react-i18next';
import DeMailTouchPointBox from '../Sidebar/InfoSidebar/DeMailTouchPoint';
import SignerTabs from '../Sidebar/SignerTabs';

export default function SlideUpPanel({
  open,
  setOpen,
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
  noOfRemainingFields,
}) {
  const { documentToSign } = useDedocoSigning();
  const { t } = useTranslation();

  // Swipe down handler to close panel
  let touchStartY;
  let touchEndY;
  const handleTouchStart = (e) => {
    touchStartY = e.changedTouches[0].screenY;
  };
  const handleTouchEnd = (e) => {
    touchEndY = e.changedTouches[0].screenY;
    if (touchEndY > touchStartY + 20) {
      setOpen(false);
    }
  };
  const { isObserver } = useRecipientRole();

  const renderContent = () => {
    const isInstantSign = Boolean(documentToSign?.data?.isInstantSign);
    const nextSigner = documentToSign?.nextSigner;
    if (documentToSign.submitDocumentCompleted) {
      let title = t('CompletionSuccessTitle');
      let body = t('CompletionSuccessText');
      if (isInstantSign) {
        title = t('InstantSignCompletionSuccessTitle', {
          name: documentToSign?.data?.recipientName ?? '',
          completionMsg: nextSigner ? '' : t('DocumentIsNowComplete'),
        });
        if (nextSigner) body = '';
      }
      return (
        <InfoSidebar
          title={title}
          body={body}
          handleDownloadPdf={handleDownloadPdf}
          showDemailTouchPoint={true}
          compactMode={true}
          nextSigner={nextSigner}
        />
      );
    }

    if (documentToSign.isDocumentApproved) {
      return (
        <InfoSidebar
          title={t('ApproveDocumentTitle')}
          body={t('ApproveDocumentBody')}
          handleDownloadPdf={handleDownloadPdf}
          showDemailTouchPoint={true}
          compactMode={true}
        />
      );
    }

    if (documentToSign.isDocumentRejected) {
      return (
        <InfoSidebar
          title={t('RejectDocumentTitle')}
          body={t('ExpiredDocumentBody')}
          handleDownloadPdf={handleDownloadPdf}
          showDownloadButton={false}
          showDemailTouchPoint={true}
          compactMode={true}
        />
      );
    }

    return (
      <>
        {isObserver && (
          <>
            <Grid item>
              <DeMailTouchPointBox backgroundColor="none" compactMode />
            </Grid>
            <Divider light className={classes.divider} />
          </>
        )}
        <SignerTabs
          variant="fullWidth"
          noOfRemainingFields={noOfRemainingFields}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
        />
      </>
    );
  };

  return (
    <Root>
      <React.Fragment key="bottom">
        <BottomSlideUp anchor="bottom" open={open} onClose={() => setOpen(false)}>
          <Grid
            container
            flexDirection="column"
            wrap="nowrap"
            role="presentation"
            className={classes.contentContainer}
          >
            <Grid container item justifyContent="center">
              <Grid item onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                <div className={classes.slideUp} />
              </Grid>
            </Grid>
            <Grid className={classes.content} container item>
              {renderContent()}
            </Grid>
          </Grid>
        </BottomSlideUp>
      </React.Fragment>
    </Root>
  );
}
