import { useCallback, useRef, useState } from 'react';

export default function useReferredState(initialValue) {
  const [state, setState] = useState(initialValue);
  const ref = useRef(state);

  const setReferredState = useCallback((value) => {
    ref.current = value;
    setState(value);
  }, []);

  return [ref, setReferredState];
}
