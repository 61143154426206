import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formStateSelector } from '../../../../../redux/slices/formSlice';
import FieldListSection from '../../../Sidebar/FieldList/FieldListSection';
import { FormFieldTypes } from '../../constants';
import Fields from './Fields';

export default function FieldSummary() {
  const { t } = useTranslation();
  const { form } = useSelector(formStateSelector);
  const fields = form.fields;

  const createFieldItemSummary = (field) => ({
    fieldId: field.fieldId,
    label: field.label,
    pageNumber: field.placement.page,
    isRequired: field.isRequired,
  });

  const isDigitalSigning = fields[FormFieldTypes.digiSignatures].length > 0;
  const signatures = isDigitalSigning
    ? fields[FormFieldTypes.digiSignatures].map(createFieldItemSummary)
    : fields[FormFieldTypes.esignatures].map(createFieldItemSummary);
  const signatureType = isDigitalSigning
    ? FormFieldTypes.digiSignatures
    : FormFieldTypes.esignatures;
  const dates = fields[FormFieldTypes.dates].map(createFieldItemSummary);
  const initials = fields[FormFieldTypes.initials].map(createFieldItemSummary);
  const stamps = fields[FormFieldTypes.stamps].map(createFieldItemSummary);
  const texts = fields[FormFieldTypes.inputs].map(createFieldItemSummary);
  const dropdownLists = fields[FormFieldTypes.dropdownLists].map(createFieldItemSummary);
  const checkboxes = fields[FormFieldTypes.checkboxes].map(createFieldItemSummary);

  return (
    <FieldListSection title={'Field Summary'}>
      <Fields title={t('SidebarSignatures')} type={signatureType} items={signatures} />
      <Fields title={t('SidebarDates')} type={FormFieldTypes.dates} items={dates} />
      <Fields title={t('SidebarInitials')} type={FormFieldTypes.initials} items={initials} />
      <Fields title={t('SidebarStamps')} type={FormFieldTypes.stamps} items={stamps} />
      <Fields title={t('SidebarTexts')} type={FormFieldTypes.inputs} items={texts} />
      <Fields
        title={t('SidebarDropdownLists')}
        type={FormFieldTypes.dropdownLists}
        items={dropdownLists}
      />
      <Fields title={t('SidebarCheckboxes')} type={FormFieldTypes.checkboxes} items={checkboxes} />
    </FieldListSection>
  );
}
