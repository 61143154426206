// eslint-disable-next-line no-unused-vars
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { verifyOTP } from '../otpSlice/otpSlice';
import { joinMeeting, startMeeting } from '../twilioSlice/twilioSlice';
import {
  approveDocumentApi,
  declineDocumentApi,
  getApproverDetailsApi,
  getDocumentToSignApi,
  getFolderNameApi,
  getObserverDetailsApi,
  signDocumentApi,
} from './dVideoSignApi';

// can change its name by need
const SLICE_NAME = 'dVideoSign';
export const DVIDEO_SIGN_GET_DOCUMENT_TO_SIGN = `${SLICE_NAME}/getDocumentToSign`;
export const DVIDEO_SIGN_GET_OBSERVER_DETAILS = `${SLICE_NAME}/getObserverDetails`;
export const DVIDEO_SIGN_GET_APPROVER_DETAILS = `${SLICE_NAME}/getApproverDetails`;

function handleApiError(state, { payload = {} }) {
  console.error(payload.desc);
  // Set error state with erorr code
  state.error = payload.code;
  state.isLoading = false;
}

export const getFolderName = createAsyncThunk(
  `${SLICE_NAME}/getFolderName`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await getFolderNameApi(id);
      return response.data.name;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDocumentToSign = createAsyncThunk(
  DVIDEO_SIGN_GET_DOCUMENT_TO_SIGN,
  async ({ id, uid, secret }, { rejectWithValue }) => {
    try {
      const response = await getDocumentToSignApi({ id, uid, secret });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getObserverDetails = createAsyncThunk(
  DVIDEO_SIGN_GET_OBSERVER_DETAILS,
  async ({ id, uid, secret }, { rejectWithValue }) => {
    try {
      const response = await getObserverDetailsApi({ id, uid, secret });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getApproverDetails = createAsyncThunk(
  DVIDEO_SIGN_GET_APPROVER_DETAILS,
  async ({ id, uid, secret }, { rejectWithValue }) => {
    try {
      const response = await getApproverDetailsApi({ id, uid, secret });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const approveDocument = createAsyncThunk(
  `${SLICE_NAME}/approveDocument`,
  async ({ data, id, uid }, { rejectWithValue }) => {
    try {
      const response = await approveDocumentApi({ data, id, uid });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const declineDocument = createAsyncThunk(
  `${SLICE_NAME}/declineDocument`,
  async ({ data, id, uid }, { rejectWithValue }) => {
    try {
      const response = await declineDocumentApi({ data, id, uid });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const signDocument = createAsyncThunk(
  `${SLICE_NAME}/signDocument`,
  async ({ data, id, uid }, { rejectWithValue }) => {
    try {
      const response = await signDocumentApi({ data, id, uid });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const convertDocumentData = (data) => {
  return {
    data: data,
    folder_name: data.folder_name ? data.folder_name : '',
    document: data.business_process,
    message: '',
    document_hashes: data.document_hashes,
    //Gary removed appended file type
    fileName: data.document_name,
    isloaded: true,
    isLoading: false,
  };
};

const handleGetDocument = (state, action) => {
  return {
    ...state,
    ...convertDocumentData(action.payload),
  };
};

const initialState = {
  isLoading: false,
  error: null,
  folderName: '',
  isTimeoutEndMeeting: false,
};

export const dVideoSignSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    acceptTnc: (state) => {
      state.tncAccepted = true;
    },
    savePdfBase64String: (state, action) => {
      state.pdfBase64String = action.payload;
    },
    setMeetingActive: (state, { payload = true }) => {
      state.data.meeting.isActive = payload;
      state.document.meeting.isActive = payload;
      state.data.business_process.meeting.isActive = payload;
    },
    updateSavedSignature: (state, action) => {
      state.data.savedSignatures = action.payload;
    },
    otherApproveDocument: (state) => {
      state.documentUpdatedApprovement = true;
      state.isDocumentApproved = true;
    },
    otherRejectDocument: (state) => {
      state.documentUpdatedApprovement = true;
      state.isDocumentRejected = true;
    },
    otherSignDocument: (state) => {
      state.submitDocumentCompleted = true;
    },
    setDocumentToSign: (state, action) => {
      return { ...action.payload, isTimeoutEndMeeting: false };
    },
    changeUpdate: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
      state.document = {
        ...state.document,
        ...action.payload.business_process,
      };
      state.document_hashes = action.payload.document_hashes;
    },
    setTimeoutEndMeeting: (state, action) => {
      state.isTimeoutEndMeeting = action.payload;
    },
  },
  extraReducers: (builder) => {
    //getDocumentToSign
    builder.addCase(getDocumentToSign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDocumentToSign.rejected, handleApiError);
    builder.addCase(getDocumentToSign.fulfilled, handleGetDocument);
    //getObserverDetails
    builder.addCase(getObserverDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getObserverDetails.rejected, handleApiError);
    builder.addCase(getObserverDetails.fulfilled, handleGetDocument);
    //getApproverDetails
    builder.addCase(getApproverDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApproverDetails.rejected, handleApiError);
    builder.addCase(getApproverDetails.fulfilled, handleGetDocument);
    //approveDocument
    builder.addCase(approveDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveDocument.rejected, handleApiError);
    builder.addCase(approveDocument.fulfilled, (state) => {
      state.documentUpdatedApprovement = true;
      state.isDocumentApproved = true;
      state.isLoading = false;
    });
    //declineDocument
    builder.addCase(declineDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(declineDocument.rejected, handleApiError);
    builder.addCase(declineDocument.fulfilled, (state) => {
      state.documentUpdatedApprovement = true;
      state.isDocumentRejected = true;
      state.isLoading = false;
    });
    //signDocument
    builder.addCase(signDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signDocument.rejected, handleApiError);
    builder.addCase(signDocument.fulfilled, (state) => {
      state.submitDocumentCompleted = true;
      state.isLoading = false;
    });
    //getFolderName
    builder.addCase(getFolderName.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFolderName.rejected, handleApiError);
    builder.addCase(getFolderName.fulfilled, (state, action) => {
      state.folderName = action.payload;
      state.isLoading = false;
    });
    //startMeeting
    builder.addCase(startMeeting.fulfilled, (state) => {
      state.isTimeoutEndMeeting = false;
    });
    //joinMeeting
    builder.addCase(joinMeeting.fulfilled, (state) => {
      state.isTimeoutEndMeeting = false;
    });
    // verifySigningOTP
    builder.addCase(verifyOTP.fulfilled, handleGetDocument);
  },
});

const { reducer, actions } = dVideoSignSlice;

export const {
  acceptTnc,
  savePdfBase64String,
  setMeetingActive,
  updateSavedSignature,
  otherApproveDocument,
  otherRejectDocument,
  otherSignDocument,
  setDocumentToSign,
  changeUpdate,
  setTimeoutEndMeeting,
} = actions;

export const getDocumentToSignSelector = (state) => state[SLICE_NAME];

export default reducer;
