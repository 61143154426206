import {
  ZOOMIN,
  ZOOMOUT,
  NEXTPAGE,
  PREVPAGE,
  TOTALPAGES,
  SETPAGE,
  SETFULLZOOM,
  SETZOOM,
} from './actiontypes';
export function zoomIn() {
  return (dispatch) => {
    dispatch({
      type: ZOOMIN,
    });
  };
}
export function zoomOut() {
  return (dispatch) => {
    dispatch({
      type: ZOOMOUT,
    });
  };
}
export function nextPage() {
  return (dispatch) => {
    dispatch({
      type: NEXTPAGE,
    });
  };
}
export function prevPage() {
  return (dispatch) => {
    dispatch({
      type: PREVPAGE,
    });
  };
}
export function setTotalPages(totalPages) {
  return (dispatch) => {
    dispatch({
      type: TOTALPAGES,
      payload: totalPages,
    });
  };
}
export function setPage(pagenumber) {
  return (dispatch) => {
    dispatch({
      type: SETPAGE,
      payload: pagenumber,
    });
  };
}
export function setFullzoom(pagenumber) {
  return (dispatch) => {
    dispatch({
      type: SETFULLZOOM,
      payload: pagenumber,
    });
  };
}
export function setZoom(zoom) {
  return (dispatch) => {
    dispatch({
      type: SETZOOM,
      payload: zoom,
    });
  };
}
