import {
  QrDisplayRoot,
  QrCodeHeader,
  QrCodeSubHeader,
  QrCodeBorder,
  QrSingPassFrame,
  ExpiredQrRoot,
  ExpiredQrOverlay,
  ExpiredQrCaption,
  ExpiredQrRefreshButton,
} from './QrCode.styles';
import { QRCode as QrCodeDisplay } from 'react-qrcode-logo';
import { useWindowResize } from '../../../../../hooks/useWindowResize';
import singPassFrame from '../../../../../assets/Singpass QR frame.svg';
import { useTranslation } from 'react-i18next';

export const QrCode = ({ qrData }) => {
  return (
    <QrDisplayRoot>
      <QrCodeMessage />
      <QrCodeBox qrData={qrData} isClickable={true} />
    </QrDisplayRoot>
  );
};

export const ExpiredQrCode = ({ qrData, onRefreshQr }) => {
  const { t } = useTranslation();
  return (
    <ExpiredQrRoot>
      <ExpiredQrOverlay />
      <ExpiredQrCaption>{t('SigningRequestExpiredQRHeader')}</ExpiredQrCaption>
      <ExpiredQrRefreshButton onClick={onRefreshQr}>
        {t('NdiRefreshQrButton')}
      </ExpiredQrRefreshButton>
      <QrCodeBox qrData={qrData} isClickable={false} />
    </ExpiredQrRoot>
  );
};

const QrCodeMessage = () => {
  const { t } = useTranslation();
  const { dimensions } = useWindowResize();

  return (
    <>
      <QrCodeHeader>
        {dimensions.width < 760 ? (
          <>{t('SigningRequestQRCodeMessageHeader')}</>
        ) : (
          <>{t('SigningRequestQRCodeMessageHeaderAlt')}</>
        )}
      </QrCodeHeader>
      <QrCodeSubHeader>{t('SigningRequestQRCodeMessageHeaderText')}</QrCodeSubHeader>
    </>
  );
};

const QrCodeBox = ({ qrData, isClickable }) => {
  const qrCodeData = (
    <>
      <QrSingPassFrame src={singPassFrame} />
      <QrCodeDisplay size={210} value={qrData} logoImage="/Singpass app icon.svg" logoWidth={48} />
    </>
  );

  let qrBox = isClickable ? <a href={qrData}>{qrCodeData}</a> : qrCodeData;

  return <QrCodeBorder>{qrBox}</QrCodeBorder>;
};
