import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Button, Modal, CircularProgress } from '@mui/material';
import { FileDrop } from 'react-file-drop';
import { getFileHash, getBase64FileHash } from '../../utils/hash';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ModalData from './ModalData';
import DescriptionIcon from '@mui/icons-material/Description';
import docverified from '../../assets/docverified.png';
import verifydoc from '../../assets/verfidoc.png';
import { FILEURL } from '../../redux/actions/actiontypes';
import { downloadFileFromDSS } from '../../lib/api/dss';
import { getPublicForm } from '../../lib/api';
import './SigningRequest.css';

import { useTranslation } from 'react-i18next';

const PREFIX = 'VerifyDocument';

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: '400px',
    width: '500px',
    outline: 'none',
    borderRadius: '1%',
  },
}));

export default function VerifyDocument(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const documenttosign = useSelector((state) => state.documenttosign);
  const [file, Setfile] = useState({ selectedfile: '', hash: '' });
  const [fileVerified, setFileVerified] = useState(0);

  const [open, setOpen] = useState(false);

  const verified = () => {
    props.setFileName(file.selectedfile.name);

    var oFReader = new FileReader();
    oFReader.readAsDataURL(file.selectedfile);

    oFReader.onload = function (oFREvent) {
      props.setPdfBase64String(oFREvent.target.result.replace('data:application/pdf;base64,', ''));
      props.setfileUrl(oFREvent.target.result);
      dispatch({
        type: FILEURL,
        payload: oFREvent.target.result,
      });
    };
    props.setShow(1);
  };

  //Gary (Dedoco Secure Share file fetching logic)
  useEffect(() => {
    async function fetchData() {
      if (props.fileShareKey && !props.publicVariable) {
        props.setShow(1);
        props.setSeverity('info');

        let final = await downloadFileFromDSS(props.fileShareKey);
        let fileURL = `data:application/pdf;base64,${final}`;
        let hashedDocument = getBase64FileHash(fileURL);

        if (
          documenttosign.document_hashes[documenttosign.document_hashes.length - 1] ===
          hashedDocument
        ) {
          props.setFileName(documenttosign.fileName);
          props.setHash(hashedDocument);
          props.setfileUrl(fileURL);
          props.setPdfBase64String(final);
          props.setSnackBarOpen(true);
          props.setSeverity('success');
          props.setSnackBarMessage(t('VerifyDocumentMessageFileLoaded'));
          dispatch({
            type: FILEURL,
            payload: fileURL,
          });
        } else {
          props.setSnackBarOpen(true);
          props.setSeverity('error');
          props.setSnackBarMessage(t('VerifyDocumentErrorDocumentMismatched'));
        }
      } else if (props.publicVariable) {
        props.setShow(1);
        props.setSnackBarOpen(true);
        props.setSeverity('info');
        props.setSnackBarMessage(t('VerifyDocumentMessageFetchingPDF'));
        getPublicForm(props.b64Url)
          .then((data) => {
            let fileURL = data.file.includes('base64')
              ? data.file
              : `data:application/pdf;base64,${data.file}`;
            let hashedDocument = getBase64FileHash(fileURL);

            if (
              documenttosign.document_hashes[documenttosign.document_hashes.length - 1] ===
              hashedDocument
            ) {
              props.setFileName(documenttosign.fileName);
              props.setHash(hashedDocument);
              props.setfileUrl(fileURL);
              props.setSnackBarOpen(true);
              props.setSeverity('success');
              props.setSnackBarMessage(t('VerifyDocumentMessageFileLoaded'));
              dispatch({
                type: FILEURL,
                payload: fileURL,
              });
            } else {
              props.setSnackBarOpen(true);
              props.setSeverity('error');
              props.setSnackBarMessage(t('VerifyDocumentErrorDocumentMismatched'));
            }
          })
          .catch(() => {
            props.setSnackBarOpen(true);
            props.setSeverity('error');
            props.setSnackBarMessage(t('VerifyDocumentErrorFetchDocument'));
          });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttosign.document_hashes]);

  //Gary auto redirect when file is verified
  useEffect(() => {
    if (fileVerified === 1) {
      setTimeout(() => {
        verified();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileVerified]);

  const verifyDoc = (fileHash, docHash) => {
    // For manual document verification, users are expected to use the original documents to verify.
    // This is more intuitive to them.
    const correctDocHash = docHash?.[0] || '';

    if (fileHash === '' || correctDocHash === '') {
      setFileVerified(0);
    } else {
      if (fileHash === correctDocHash) {
        props.setData({
          ...props.data,
          initial_document_hash: correctDocHash,
          final_document_hash: fileHash,
        });
        setFileVerified(1);
      } else {
        setFileVerified(-1);
      }
    }
  };

  useEffect(() => {
    verifyDoc(file.hash, documenttosign.document_hashes);
    props.setHash(file.hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.hash]);

  const getFile = async (e) => {
    if (e.target.files[0]) {
      setOpen(true);
      setFileVerified(0);

      Setfile({
        selectedfile: e.target.files[0],
        hash: await getFileHash(e.target.files[0]),
      });
    }
  };

  const fileUploaded = async (files) => {
    const file = files[0];

    if (file.type.match('application/pdf')) {
      setOpen(true);
      setFileVerified(0);

      Setfile({ selectedfile: file, hash: await getFileHash(file) });
    } else {
      alert(t('VerifyDocumentErrorFileNotSupported'));
    }
  };

  if (props.fileShareKey || props.publicVariable) {
    return <CircularProgress style={{ position: 'absolute', left: '46%', top: '50%' }} />;
  }
  return (
    <StyledGrid container spacing={0} className="h-90 mt-1">
      <Grid item sm={false} md={3} lg={3} xs={false} className="side"></Grid>
      <Grid item sm={12} md={6} lg={6} xs={12} className="center p2 ">
        <center>
          <img src={verifydoc} className="verify-doc" alt="Verify Document" />
          <h2 className="htag">{t('VerifyDocumentHeader')}</h2>
          <span className="fws">
            <div dangerouslySetInnerHTML={{ __html: t('VerifyDocumentSubHeader') }}></div>{' '}
            <a href="https://www.dedoco.com/faq" rel="noopener noreferrer" target="_blank">
              <u>{t('VerifyDocumentLearnMoreLink')}</u>
            </a>
          </span>
          <p className="blue-box">
            {' '}
            <DescriptionIcon className="doc-icon" /> {t('VerifyDocumentCardHeader')}{' '}
            <b> {documenttosign.fileName}</b> {t('VerifyDocumentCardHeaderText')}
          </p>
          <div className="dropzone-container">
            {fileVerified !== 1 ? (
              <FileDrop className="Dropzone" onDrop={(files, _event) => fileUploaded(files)}>
                <div className="center">
                  <h3>{t('VerifyDocumentUploadHeader')}</h3>
                  <div className="imageUpload">
                    <input
                      style={{ display: 'none' }}
                      id="verify-document-file-upload"
                      onChange={(e) => getFile(e)}
                      type="file"
                      accept="application/pdf"
                    />
                    <label htmlFor="verify-document-file-upload">
                      <Button variant="contained" component="span" className="choose-file-button">
                        {t('VerifyDocumentUploadChooseFileButton')}
                      </Button>
                    </label>
                  </div>
                </div>
              </FileDrop>
            ) : (
              <center>
                <img src={docverified} className="doc-veried" alt="Verified Doc"></img>
                <h4 className="m05">{t('VerifiyDocumentVerified')}</h4>
                <div>
                  <DescriptionIcon className="doc-icon" />
                  <span
                    className="p05"
                    onClick={() => {
                      Setfile({ selectedfile: '', hash: '' });
                      setFileVerified(0);
                      props.setFileName('');
                    }}
                  >
                    {props.fileName} x
                  </span>
                </div>
              </center>
            )}
          </div>
          {fileVerified !== 1 ? (
            <Button variant="contained" disabled className="continue-button  m-0">
              {t('VerifyDocumentContinueButton')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="continue-button  m-0"
              onClick={verified}
            >
              {t('VerifyDocumentContinueButton')}
            </Button>
          )}
        </center>
      </Grid>
      <Grid item sm={false} md={3} lg={3} xs={false} className="side"></Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Button
              className="close-button-2"
              onClick={() => {
                setOpen(false);
              }}
            >
              x
            </Button>
            <ModalData
              status={fileVerified}
              close={() => {
                setOpen(false);
              }}
            />
          </div>
        </Fade>
      </Modal>
    </StyledGrid>
  );
}
