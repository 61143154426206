import { Grid, TextField } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { classes, CustomTextPadRoot } from './CustomTextPad.style';

const CustomTextPad = ({ value, handleValueChange, style, inputRef }) => {
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (e) => {
      handleValueChange?.(e.target.value);
    },
    [handleValueChange],
  );

  return (
    <CustomTextPadRoot sx={style}>
      <Grid className={classes.inner}>
        <TextField
          className={classes.textField}
          id="form-sign-input-custom-text-input"
          label=""
          value={value}
          variant="standard"
          onChange={handleOnChange}
          // Set attribute for the inner <input/> or <textarea/> element
          inputProps={{
            'data-testid': 'form-sign-input-custom-text-input',
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={t('CustomTextPlaceholder')}
          maxRows={4}
          multiline
          inputRef={inputRef}
        />
      </Grid>
    </CustomTextPadRoot>
  );
};

export default CustomTextPad;
