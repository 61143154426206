import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import { DateFormat } from '../../utils/dateFormat';
import { useFeatureFlag, featureNameMap } from '../../hooks/useFeatureFlag';

function MaintenanceBanner() {
  const maintenance = useFeatureFlag(featureNameMap.platformMaintenanceBanner, {
    useVariant: true,
  });
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState();

  useEffect(() => {
    if (!maintenance?.payload?.value) return;
    const info = JSON.parse(maintenance.payload.value);

    if (info.severity) {
      setSeverity(info.severity);
    }

    if (info.message) {
      setMessage(info.message);
    } else if (info.start && info.end) {
      const startDate = dayjs(info.start);
      const endDate = dayjs(info.end);

      let dateString = `${startDate.format(
        DateFormat.FULL_DATE_WITH_WEEKDAY,
      )} at ${startDate.format(DateFormat.HOUR_MINUTE_AMPM)} to `;

      if (startDate.isSame(endDate, 'day')) {
        dateString += `${endDate.format(DateFormat.HOUR_MINUTE_AMPM)}`;
      } else {
        dateString += `${endDate.format(DateFormat.FULL_DATE_WITH_WEEKDAY)} at ${endDate.format(
          DateFormat.HOUR_MINUTE_AMPM,
        )}`;
      }

      let msg = `System scheduled maintenance from ${dateString} (UTC${endDate.format(
        DateFormat.ZONE_OFFSET,
      )}).`;
      if (info.disabledFeature) {
        msg += ` ${info.disabledFeature} will not be available during the downtime.`;
      }
      setMessage(msg);
    }
  }, [maintenance]);

  return (
    <>
      {maintenance?.enabled && (
        <Alert severity={severity || 'warning'} square sx={{ color: '#000' }}>
          {message}
        </Alert>
      )}
    </>
  );
}

export default MaintenanceBanner;
