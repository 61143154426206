import styled from '@emotion/styled';

const PREFIX = 'Participant';

export const classes = {
  root: `${PREFIX}-root`,
  video: `${PREFIX}-video`,
  videoPlaceholder: `${PREFIX}-video-placeholder`,
  name: `${PREFIX}-name`,
};

export const ParticipantRoot = styled('div')(({ theme, isMainParticipant }) => ({
  flex: isMainParticipant ? '90%' : '50%',
  paddingBottom: theme.spacing(4),
  [`& .${classes.name}`]: {
    width: isMainParticipant ? 180 : 90,
    display: 'block',
  },
  [`& .${classes.video},.${classes.videoPlaceholder}`]: {
    width: isMainParticipant ? 200 : 90,
    borderRadius: 8,
    height: isMainParticipant ? 155 : 70,
  },
  [`& .${classes.videoPlaceholder}`]: {
    background: theme.palette.supporting.light,
    color: theme.palette.primary.main,
    ...theme.typography.headline_large,
  },
}));
