import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, ClickAwayListener, MenuItem, Popper, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropboxIcon from '../../../../assets/dropbox.svg';
import GoogleIcon from '../../../../assets/google.svg';
import MicrosoftIcon from '../../../../assets/microsoft.svg';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { useFeatureFlag, featureNameMap } from '../../../../hooks/useFeatureFlag';
import {
  classes,
  DownloadButtonGroup,
  DownloadOptionList,
  StyledPaper,
} from './DownloadButton.styles';
import { DownloadOptions } from './lib/DownloadOptions';
import DropboxSaver from './lib/DropboxSaver';
import saveToLocalStorage from './lib/fileSaver';
import GoogleDriverSaver from './lib/GoogleDriveSaver';
import OneDriveSaver from './lib/OneDriveSaver';
import { CypressIds } from '../../../../constant/cypressIds';

const DownloadButton = (props) => {
  const {
    handleDownloadPdf,
    isHidden = false,
    localDownloadOnly = false,
    fullWidth = false,
    isDownloadPage = false,
    isForm = false,
    useNewDedocoSigning = false,
  } = props;

  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);

  const { documentToSign } = useGetRequiredDedocoSigning(useNewDedocoSigning);

  const enableGoogleDriveOption = useFeatureFlag(featureNameMap.downloadOptionsGoogleDrive);
  const enableOneDriveOption = useFeatureFlag(featureNameMap.downloadOptionsOneDrive);
  const enableDropboxOption = useFeatureFlag(featureNameMap.downloadOptionsDropbox);

  const handleOpenMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const downloadFileToLocalStorage = useCallback(() => {
    handleDownloadPdf &&
      handleDownloadPdf({
        downloadOption: DownloadOptions.LOCAL,
        downloadFunction: saveToLocalStorage,
      });
    setOpenMenu(false);
  }, [handleDownloadPdf]);

  const downloadFileToGoogleDrive = useCallback(
    (saveFile) => {
      handleDownloadPdf({
        downloadOption: DownloadOptions.GOOGLE_DRIVE,
        downloadFunction: saveFile,
      });
    },
    [handleDownloadPdf],
  );

  const downloadFileToDropbox = useCallback(
    (saveFile) => {
      handleDownloadPdf({
        downloadOption: DownloadOptions.DROPBOX,
        downloadFunction: saveFile,
      });
    },
    [handleDownloadPdf],
  );

  const downloadFileToOneDrive = useCallback(
    (saveFile) => {
      handleDownloadPdf({
        downloadOption: DownloadOptions.ONE_DRIVE,
        downloadFunction: saveFile,
      });
    },
    [handleDownloadPdf],
  );

  const buttonWithOptions = (
    <>
      {/* Pass className from props so that when `styled` is used on this component in a parent component, the styles will apply to this button. */}
      <DownloadButtonGroup
        ref={anchorRef}
        className={`${classes.buttonGroup} ${props.className}`}
        variant="contained"
        color="primary"
        disabled={!isForm && !isDownloadPage && !documentToSign?.tncAccepted}
        fullWidth={fullWidth}
        sx={{
          display: isHidden ? 'none' : 'flex',
        }}
      >
        <Button
          onClick={downloadFileToLocalStorage}
          className={classes.leftButton}
          data-cypress={CypressIds.downloadPdfButton}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          {t('HeaderDownloadBtn')}
        </Button>
        <Button className={classes.rightButton} onClick={handleOpenMenu}>
          <ExpandMoreIcon />
        </Button>
      </DownloadButtonGroup>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        sx={{ paddingTop: 2, zIndex: 9999 }}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
            },
          },
        ]}
      >
        <StyledPaper>
          <ClickAwayListener onClickAway={handleClose}>
            <DownloadOptionList id="split-button-menu">
              <MenuItem
                key="device-option"
                className={classes.downloadOption}
                onClick={downloadFileToLocalStorage}
              >
                <div className={classes.optionContent}>
                  <FileDownloadOutlinedIcon sx={{ color: '#767685' }} />
                  <Typography align="center">Device</Typography>
                </div>
              </MenuItem>
              {enableGoogleDriveOption && (
                <MenuItem
                  key="google-drive-option"
                  className={classes.downloadOption}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  <GoogleDriverSaver onClick={downloadFileToGoogleDrive}>
                    <div className={classes.optionContent}>
                      <img alt="google" className={classes.optionIcon} src={GoogleIcon} />
                      <Typography align="center">Google Drive</Typography>
                    </div>
                  </GoogleDriverSaver>
                </MenuItem>
              )}
              {enableOneDriveOption && (
                <MenuItem
                  key="onedrive-option"
                  className={classes.downloadOption}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  <OneDriveSaver
                    action="query"
                    advanced={{
                      redirectUri: window.location.origin,
                    }}
                    onClick={downloadFileToOneDrive}
                    disabled={!enableOneDriveOption}
                  >
                    <div className={classes.optionContent}>
                      <img alt="microsoft" className={classes.optionIcon} src={MicrosoftIcon} />
                      <Typography align="center">OneDrive</Typography>
                    </div>
                  </OneDriveSaver>
                </MenuItem>
              )}
              {enableDropboxOption && (
                <MenuItem
                  key="dropbox-option"
                  className={classes.downloadOption}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  <DropboxSaver onClick={downloadFileToDropbox} disabled={!enableDropboxOption}>
                    <div className={classes.optionContent}>
                      <img alt="dropbox" className={classes.optionIcon} src={DropboxIcon} />
                      <Typography align="center">Dropbox</Typography>
                    </div>
                  </DropboxSaver>
                </MenuItem>
              )}
            </DownloadOptionList>
          </ClickAwayListener>
        </StyledPaper>
      </Popper>
    </>
  );

  const localDownloadButton = (
    <Button
      sx={{
        display: isHidden ? 'none' : 'flex',
      }}
      className={props.className}
      variant="contained"
      color="primary"
      startIcon={<FileDownloadOutlinedIcon />}
      fullWidth
      onClick={downloadFileToLocalStorage}
    >
      {t('HeaderDownloadBtn')}
    </Button>
  );

  return localDownloadOnly ? localDownloadButton : buttonWithOptions;
};

export default DownloadButton;
