import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import formIcon from '../../../assets/form-action-icon.svg';
import {
  FormNdiSigningProvider,
  useFormNdiSigningContext,
} from '../../../contexts/ndiSigningContexts/useFormNdiSigningContext';
import { PdfTronProvider } from '../../../contexts/usePdfTronContext';
import useWindowSize from '../../../hooks/useWindowSize';
import { fetchForm, formStateSelector } from '../../../redux/slices/formSlice';
import { FormErrorType } from '../../../redux/slices/formSlice/constants';
import { dedocoTheme } from '../../../themes/dedocoTheme';
import BlurredPdf from '../BlurredPdf';
import DedocoLayout from '../DedocoLayout';
import { CustomSnackbar } from '../UI/Snackbar';
import { BrowserNotSupported } from './BrowserNotSupported';
import { Root } from './Form.style';
import FormRecipientDisplay from './FormRecipientDisplay';
import PdfWebViewer from './PdfWebViewer';
import Sidebar from './Sidebar';

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formFiller, formSubmissionError, isSubmittingForm } = useSelector(formStateSelector);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: '',
    message: '',
    duration: null,
  });
  const { ndiSigningSnackbarState } = useFormNdiSigningContext();
  const { formId } = useParams();
  const windowSize = useWindowSize();

  useEffect(() => {
    dispatch(fetchForm(formId));
  }, [formId, dispatch]);

  useEffect(() => {
    setSnackbarState(ndiSigningSnackbarState);
  }, [ndiSigningSnackbarState]);

  useEffect(() => {
    if (formSubmissionError) {
      const message =
        formSubmissionError.type === FormErrorType.LowRecaptchaScore
          ? t('FormSubmissionFailedRecaptchaSnackbarMessage')
          : t('FormSubmissionFailedSnackbarMessage');
      setSnackbarState({
        open: true,
        severity: 'error',
        duration: 6000,
        message,
        onClose: (_, reason) => {
          if (reason === 'timeout') {
            setSnackbarState({
              open: false,
            });
          }
        },
      });
    } else if (isSubmittingForm) {
      setSnackbarState({
        open: true,
        severity: 'info',
        message: t('SubmittingFormSnackbarMessage'),
      });
    } else {
      setSnackbarState({
        open: false,
      });
    }
  }, [isSubmittingForm, formSubmissionError, t]);

  const isFillerDataSubmitted = formFiller?.name && formFiller?.email && formFiller?.agreedToTnC;

  const renderWebViewer = () => {
    if (isFillerDataSubmitted) {
      return <PdfWebViewer />;
    } else {
      return <BlurredPdf />;
    }
  };

  const navbarEmailComponent = isFillerDataSubmitted && (
    <FormRecipientDisplay name={formFiller?.name} email={formFiller?.email} />
  );

  const isBrowserNotSupported = isMobileOnly || windowSize.width < 840;

  const renderFormPage = () => {
    if (isBrowserNotSupported) {
      return <BrowserNotSupported />;
    } else {
      return (
        <>
          <CustomSnackbar {...snackbarState} />
          <Root>
            {renderWebViewer()}
            <Sidebar />
          </Root>
        </>
      );
    }
  };

  return (
    <DedocoLayout headerComponent={navbarEmailComponent} actionIcon={formIcon} actionIconAlt="form">
      {renderFormPage()}
    </DedocoLayout>
  );
};

const FormWithProviders = () => {
  return (
    <ThemeProvider theme={dedocoTheme}>
      <PdfTronProvider>
        <FormNdiSigningProvider>
          <Form />
        </FormNdiSigningProvider>
      </PdfTronProvider>
    </ThemeProvider>
  );
};

export default FormWithProviders;
