import { Skeleton, Stack } from '@mui/material';

const StandardSkeleton = (props) => {
  return (
    <Skeleton
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      animation="wave"
      variant="rounded"
      width="100%"
      {...props}
    />
  );
};

export const ContactFormPlaceholder = () => {
  return (
    <Stack spacing={2}>
      <StandardSkeleton height={60} />
      <StandardSkeleton height={120} />
      <StandardSkeleton height={180} />
      <StandardSkeleton height={40} />
    </Stack>
  );
};

export const FieldsPlaceholder = () => {
  return (
    <Stack height="100%" justifyContent="space-between" overflow={'auto'}>
      <Stack spacing={2}>
        <StandardSkeleton height={40} />
        <StandardSkeleton height={120} />
        <StandardSkeleton height={140} />
        <StandardSkeleton height={120} />
        <StandardSkeleton height={140} />
      </Stack>
      <StandardSkeleton height={40} />
    </Stack>
  );
};

export const WebViewerPlaceholder = () => {
  return (
    <Stack height="100%" justifyContent={'center'} direction={'row'} overflow={'auto'}>
      <Skeleton
        height="calc(100% - 60px)"
        width="80%"
        animation="wave"
        variant="rounded"
        sx={{
          backgroundColor: (theme) => theme.palette.background.main,
          border: `2px solid #f1f1f1`,
        }}
      />
    </Stack>
  );
};
