import { styled, Avatar } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'fit-content',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const UserAvatar = styled(Avatar)(({ theme }) => ({
  fontWeight: 700,
  backgroundColor: theme.palette.supporting.main,
  color: theme.palette.primary.main,
  fontSize: '1rem',
}));
