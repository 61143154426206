import { Grid, styled } from '@mui/material';

const PREFIX = 'SigningPad';

export const classes = {
  signingPadContainer: `${PREFIX}-signing-container`,
  signatureCanvas: `${PREFIX}-signature-canvas`,
  colorPickerClearContainer: `${PREFIX}-color-picker-clear-container`,
  clearButton: `${PREFIX}-clear-button`,
  colorPickerBox: `${PREFIX}-color-picker-box`,
  colorPicker: `${PREFIX}-color-picker`,
  colorPickerOuter: `${PREFIX}-color-picker-outer`,
  colorPickerActive: `${PREFIX}-color-picker-active`,
};

export const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.supporting.extraLight,
  borderRadius: '8px',

  [`& .${classes.signatureCanvas}`]: {
    backgroundColor: theme.palette.background.main,
    border: '1px dashed #6531FF',
    borderRadius: '4px',
    width: '100%',
    height: '146px',
  },
  [`& .MuiDivider-root`]: {
    border: `1px solid ${theme.palette.stroke.dark}`,
    width: '100%',
    marginTop: theme.spacing(4),
  },
  [`& .${classes.colorPickerClearContainer}`]: {
    height: '32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  [`& .${classes.clearButton}`]: {
    color: theme.palette.primary.main,
    padding: 0,
    minWidth: 'auto',
  },
  [`& .${classes.colorPickerBox}`]: {
    display: 'flex',
  },
  [`& .${classes.colorPickerOuter}`]: {
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  [`& .${classes.colorPickerActive}`]: {
    border: `1px solid ${theme.palette.background.base}`,
    borderRadius: '50%',
  },
  [`& .${classes.colorPicker}`]: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
  },
  [`textarea`]: {
    fontSize: `14px !important`,
    fontWeight: `400 !important`,
    lineHeight: '20px !important',
    color: `${theme.palette.text.secondary} !important`,
  },
  [`& .${classes.textField}`]: {
    width: `80% !important`,
  },
}));
