import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { loadScript } from './utils/domUtil';
import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './Color.css';
import { DownloadDocument } from './components/DedocoDifference/DownloadDocument';
import Form from './components/DedocoDifference/Form';
import SigningRequest from './components/DedocoDifference/SigningRequest';
import { SingPassLoginRedirectPage } from './components/DedocoDifference/SingPassLoginRedirectPage';
import DownloadVideo from './components/DownloadVideo/DownloadVideo';
import MaintenancePage from './components/MaintenancePage/MaintenancePage';
import DocumentVerificationSuccess from './components/Signingrequest/DocumentVerificationSuccess';
import Main from './components/Signingrequest/Main';
import RejectDocument from './components/Signingrequest/RejectDocument';
import OldSingPassRedirectPage from './components/Signingrequest/SingPassAuth/SingPassRedirectPage';
import Initiator from './components/Webex/Initiator';
import SharedApp from './components/Webex/Sharedapp';
import { withGoogleRecaptchaProvider } from './hooks/useGoogleRecaptcha';
import { WebexProvider } from './contexts/useWebexContext';
import { featureNameMap, useFeatureFlag } from './hooks/useFeatureFlag';
import { dedocoTheme } from './themes/dedocoTheme';
import { oldTheme } from './themes/oldTheme';
import { process_env } from './utils/envData';
import MaintenanceBanner from './components/MaintenanceBanner/MaintenanceBanner';
import { SgdsMasthead } from './components/SgdsMasthead';
import { Stack } from '@mui/material';
import { JoinMeetingErrorPage } from './components/DedocoDifference/VideoSigningRequest/JoinMeetingErrorPage';
import OpenApiV2 from './components/DedocoDifference/OpenApiV2';

function App() {
  const enableWebexRoutes = useFeatureFlag(featureNameMap.webex);
  const shouldUseNewTheme = useFeatureFlag(featureNameMap.signingAppDedocoDifference);
  // script to disable pull to refresh on Chrome iOS
  // loadScript('/scripts/disablePullToRefresh.js');

  // This is required for OneDrive file picker feature to work properly.
  // After authenticating and authorizing, OneDrive file picker is set to redirect to a page in this single-page web app.
  // The destination page must have this script loaded in order for the redirection to the actual file picker web page to happen.
  // Since this is a single-page web app, loading the OneDrive file picker script here will ensure the script is loaded regardless of the destination page.
  const hasOneDriveFeature = useFeatureFlag(featureNameMap.downloadOptionsOneDrive);

  if (hasOneDriveFeature) {
    loadScript('/scripts/oneDrive.js');
  }

  const theme = shouldUseNewTheme ? dedocoTheme : oldTheme;

  const shouldUseV1UI = process_env('REACT_APP_USE_V1_UI') === 'true';
  const SigningRequestComponent = shouldUseV1UI ? Main : SigningRequest;
  const SingPassRedirectComponent = shouldUseV1UI
    ? OldSingPassRedirectPage
    : SingPassLoginRedirectPage;

  return (
    <Stack width="100%" height="100%">
      {process_env('REACT_APP_SHOW_SGDS_MASTHEAD') === 'true' && <SgdsMasthead />}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <WebexProvider>
            <div className="App">
              <MaintenanceBanner />
              <Router>
                <Switch>
                  {/* Routes for dForms */}
                  <Route exact path="/forms/:formId" component={Form} />

                  {/* Routes to the old version of Signing App. Kept these for users who prefer the old version. */}
                  <Route exact path="/sign/v1/:id/:uid" component={Main} />
                  <Route exact path="/observe/v1/:id/:uid" component={Main} />
                  <Route exact path="/approve/v1/:id/:uid" component={Main} />
                  <Route exact path="/sign/v1/:id/:uid/:key" component={Main} />
                  <Route exact path="/observe/v1/:id/:uid/:key" component={Main} />
                  <Route exact path="/approve/v1/:id/:uid/:key" component={Main} />

                  <Route exact path="/sign/:id" component={Main} />
                  <Route exact path="/sign/:id/:uid" component={SigningRequestComponent} />
                  <Route exact path="/approve/:id/:uid" component={SigningRequestComponent} />
                  <Route exact path="/observe/:id/:uid" component={SigningRequestComponent} />
                  <Route
                    exact
                    path="/:public/sign/:id/:uid/:url"
                    render={(props) => <Main isOpenApi {...props} />}
                  />
                  <Route
                    exact
                    path="/:public/observe/:id/:uid/:url"
                    render={(props) => <Main isOpenApi {...props} />}
                  />
                  <Route exact path="/singpass-login" component={SingPassRedirectComponent} />
                  <Route exact path="/done/:id/:uid" component={DocumentVerificationSuccess} />
                  <Route exact path="/done/decline/:id/:uid" component={RejectDocument} />
                  {/*Gary added secure share key into url to load PDF*/}
                  <Route exact path="/sign/:id/:uid/:key" component={SigningRequestComponent} />
                  <Route exact path="/observe/:id/:uid/:key" component={SigningRequestComponent} />
                  <Route exact path="/approve/:id/:uid/:key" component={SigningRequestComponent} />
                  <Route exact path="/video-sign/unable-to-join" component={JoinMeetingErrorPage} />
                  {/* open api v2 */}
                  <Route path="/v2/openapi/sign/:id/:uid" component={OpenApiV2} />
                  <Route path="/v2/openapi/observe/:id/:uid" component={OpenApiV2} />
                  <Route path="/v2/openapi/approve/:id/:uid" component={OpenApiV2} />
                  <Route
                    exact
                    path="/:public/done/:id/:uid"
                    component={DocumentVerificationSuccess}
                  />
                  <Route exact path="/records/download" component={DownloadVideo} />
                  <Route exact path="/download/:filename/:key" component={DownloadDocument} />
                  <Route exact path="/maintenance" component={MaintenancePage} />

                  {enableWebexRoutes && [
                    <Route exact path="/initiator" component={Initiator} key="webex-1" />,
                    <Route
                      exact
                      path="/sharedapp/:documentid"
                      component={SharedApp}
                      key="webex-2"
                    />,
                    <Route
                      exact
                      path="/webex-observe/:key"
                      component={SigningRequest}
                      key="webex-3"
                    />,
                  ]}
                </Switch>
              </Router>
            </div>
          </WebexProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Stack>
  );
}

export default withGoogleRecaptchaProvider(App);
