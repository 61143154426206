import {
  ChallengeCodeRoot,
  ChallengeCodeHeader,
  ChallengeCodeDisplay,
  ChallengeCodeDigit,
} from './ChallengeCode.styles';
import { useTranslation } from 'react-i18next';

export const ChallengeCode = ({ challengeCode }) => {
  const { t } = useTranslation();

  return (
    <ChallengeCodeRoot>
      <ChallengeCodeHeader>{t('NdiSigningChallengeCodeHeader')}</ChallengeCodeHeader>
      <ChallengeCodeDisplay>
        <ChallengeCodeDigit>{challengeCode?.slice(0, 1)}</ChallengeCodeDigit>
        <ChallengeCodeDigit>{challengeCode?.slice(1, 2)}</ChallengeCodeDigit>
        <ChallengeCodeDigit>{challengeCode?.slice(2, 3)}</ChallengeCodeDigit>
        <ChallengeCodeDigit>{challengeCode?.slice(-1)}</ChallengeCodeDigit>
      </ChallengeCodeDisplay>
    </ChallengeCodeRoot>
  );
};
