import { getEmailIcon } from '../assets/colorable-svgs';
import {
  createWidgetLayout,
  createFormWidgetLayout,
  createTextDisplayAnnotation,
} from './pdftronWidgetCommon';

export const createEmailWidgetAnnotation = (
  fieldId,
  text,
  name,
  descriptor,
  color,
  fieldFlags,
  writable,
  pdfTronInstance,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    tooltipName: descriptor,
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, null);
  const textWidgetLayout = createWidgetLayout(iframeWindow, {
    iconSrc: getEmailIcon(color),
    title: descriptor || 'Email',
    color,
    tagText: name,
  });

  if (!writable) {
    if (text) {
      const textAnnot = createTextDisplayAnnotation(text, Annotations);
      widgetAnnot.setAssociatedSignatureAnnotation(textAnnot);
      textWidgetLayout.hideLabel();
    }
    widgetAnnot.createInnerElement = () => {
      return textWidgetLayout;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      return textWidgetLayout;
    };
  }

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const createFormEmailInputWidgetAnnotation = (
  fieldId,
  fieldFlags,
  textInput,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });

  const textWidgetLayout = createFormWidgetLayout(iframeWindow, {
    labelText: textInput.label,
    isRequired: textInput.isRequired,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, null);
  widgetAnnot.createInnerElement = () => {
    return textWidgetLayout;
  };

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const isEmail = (id) => typeof id === 'string' && id?.startsWith('email');
