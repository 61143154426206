import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress as MuiCircularProgress, circularProgressClasses } from '@mui/material';

export default function CircularProgress({
  variant,
  value,
  size = 40,
  thickness = 5,
  ...otherProps
}) {
  return (
    <Box sx={{ position: 'relative' }}>
      <MuiCircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={size}
        thickness={thickness}
        {...otherProps}
        value={100}
      />
      <MuiCircularProgress
        variant={variant}
        value={value}
        disableShrink
        sx={{
          color: (theme) => theme.palette.primary.main,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={thickness}
        {...otherProps}
      />
    </Box>
  );
}
