import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReplaceSubstringsWithComponents } from '../../../../hooks/useReplaceStringWithComponent';

export const RecaptchaLabel = ({ show, ...props }) => {
  const { t } = useTranslation();
  const labelWithLinks = useReplaceSubstringsWithComponents(t('GoogleRecaptchaLabel'), {
    [t('GoogleRecaptchaLabelPrivacyPolicy')]: PrivacyPolicyLink,
    [t('GoogleRecaptchaLabelTermsOfService')]: TermsOfServiceLink,
  });

  return (
    show && (
      <Typography {...props} align="left" variant="body_small" color="grey.500">
        {labelWithLinks}
      </Typography>
    )
  );
};

const PrivacyPolicyLink = ({ value }) => {
  return <LinkComponent href="https://policies.google.com/privacy">{value}</LinkComponent>;
};

const TermsOfServiceLink = ({ value }) => {
  return <LinkComponent href="https://policies.google.com/terms">{value}</LinkComponent>;
};

const LinkComponent = ({ children, href }) => {
  const theme = useTheme();
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
      href={href}
    >
      {children}
    </a>
  );
};
