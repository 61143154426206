import { Grid, styled } from '@mui/material';

const PREFIX = 'CustomTextPad';

export const classes = {
  inner: `${PREFIX}-inner`,
  textField: `${PREFIX}-textField`,
};

export const CustomTextPadRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderRadius: `6px !important`,
  width: '100%',

  [`& .${classes.inner}`]: {
    border: `1px dashed ${theme.palette.primary.light}`,
    minHeight: '136px',
    background: theme.palette.background.main,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    boxSizing: 'border-box',
  },

  [`textarea`]: {
    fontWeight: `400 !important`,
    '::placeholder': {
      fontSize: '14px !important',
    },
  },

  [`& .${classes.textField}`]: {
    width: `80% !important`,
  },
}));
