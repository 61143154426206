import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { process_env } from '../utils/envData';

export function withGoogleRecaptchaProvider(WrappedComponent) {
  const siteKey = process_env('REACT_APP_RECAPTCHA_SITE_KEY');
  return siteKey
    ? function ComponentWithGoogleRecaptchaProvider(props) {
        return (
          <GoogleReCaptchaProvider reCaptchaKey={siteKey} useRecaptchaNet={true}>
            <WrappedComponent {...props} />
          </GoogleReCaptchaProvider>
        );
      }
    : WrappedComponent;
}

export function withGoogleRecaptchaProps(WrappedComponent) {
  return process_env('REACT_APP_RECAPTCHA_SITE_KEY')
    ? withGoogleReCaptcha(WrappedComponent)
    : WrappedComponent;
}
