import {
  Root,
  Header,
  Content,
  SingPassContent,
  LoadingSpinner,
  Footer,
  FooterMessage,
  CancelButton,
} from './QrAndChallengeCodeModal.styles';

import { ChallengeCode } from './ChallengeCode';
import { QrCode, ExpiredQrCode } from './QrCode';
import { WaitingForSignature } from './WaitingForSignature';
import { ExpiredSigningSession } from './ExpiredSigningSession';
import { SomethingWrongError } from './SomethingWrongError';
import { useTranslation } from 'react-i18next';
import { SigningStates } from '../../shared';
import SingPassLoadingSpinner from '../../../../assets/SingPassSpinner.svg';

const QrAndChallengeCodeModal = ({
  open,
  onClose,
  qrData,
  challengeCode,
  timerMinutes,
  timerSeconds,
  signingStep,
  hasError,
  isLoading,
  onRefreshQr,
}) => {
  const { t } = useTranslation();
  const isExpired = timerMinutes === 0 && timerSeconds === 0;

  return (
    <Root open={open}>
      <>
        <Header>{t('QrAndChallengeCodeModalTitle')}</Header>
        <Content>
          <SingPassContentDisplay
            qrData={qrData}
            challengeCode={challengeCode}
            signingStep={signingStep}
            isExpired={isExpired}
            isLoading={isLoading}
            hasError={hasError}
            onRefreshQr={onRefreshQr}
          />
        </Content>
        <Footer>
          <FooterText
            qrData={qrData}
            timerMinutes={timerMinutes}
            timerSeconds={timerSeconds}
            signingStep={signingStep}
          />
          <CancelButton onClick={onClose}>{t('QrAndChallengeCodeModalCancelButton')}</CancelButton>
        </Footer>
      </>
    </Root>
  );
};

const SingPassContentDisplay = ({
  qrData,
  challengeCode,
  signingStep,
  isExpired,
  isLoading,
  hasError,
  onRefreshQr,
}) => {
  let content = '';

  if (hasError) {
    content = <SomethingWrongError />;
  } else if (isLoading) {
    content = <LoadingSpinner src={SingPassLoadingSpinner} />;
  } else if (signingStep === SigningStates.QR_SCANNING) {
    content = (
      <QrScanningStepContent
        isExpired={isExpired}
        qrData={qrData}
        challengeCode={challengeCode}
        onRefreshQr={onRefreshQr}
      />
    );
  } else if (signingStep === SigningStates.SIGNING) {
    content = (
      <PendingSigningContent
        isExpired={isExpired}
        challengeCode={challengeCode}
        onRefreshQr={onRefreshQr}
      />
    );
  }

  return <SingPassContent>{content}</SingPassContent>;
};

const QrScanningStepContent = ({ isExpired, qrData, challengeCode, onRefreshQr }) => {
  let content = '';
  if (isExpired) {
    content = (
      <>
        <ChallengeCode challengeCode={challengeCode} />
        <ExpiredQrCode qrData={qrData} onRefreshQr={onRefreshQr} />
      </>
    );
  } else if (qrData && challengeCode) {
    content = (
      <>
        <ChallengeCode challengeCode={challengeCode} />
        <QrCode qrData={qrData} />
      </>
    );
  }

  return content;
};

const PendingSigningContent = ({ isExpired, challengeCode, onRefreshQr }) => {
  let content = '';

  if (isExpired) {
    content = <ExpiredSigningSession onRefreshQr={onRefreshQr} />;
  } else if (challengeCode) {
    content = (
      <>
        <ChallengeCode challengeCode={challengeCode} />
        <WaitingForSignature />
      </>
    );
  }

  return content;
};

const FooterText = ({ qrData, timerMinutes, timerSeconds, signingStep }) => {
  const { t } = useTranslation();
  let message = '';
  if (!qrData) {
    message = t('NdiSigningGeneratingQrAndChallengeCode');
  } else {
    message =
      signingStep === SigningStates.SIGNING
        ? t('NdiWaitingForSignatureFooterMessage', { timerMinutes, timerSeconds })
        : t('NdiQrScanningFooterMessage', { timerMinutes, timerSeconds });
  }

  return <FooterMessage>{message}</FooterMessage>;
};

export default QrAndChallengeCodeModal;
