import { styled } from '@mui/material';

const PREFIX = 'DatePicker';

export const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-back-button`,
  backButtonText: `${PREFIX}-back-button-text`,
  customDateTitle: `${PREFIX}-custom-date-title`,
  calender: `${PREFIX}-calender`,
};

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  display: 'grid',
  width: '100%',
  [`& .${classes.calender}`]: {
    width: '100%',
    marginTop: theme.spacing(5),
    backgroundColor: theme.palette.supporting.extraLight,
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    '& .MuiPickersCalendarHeader-root': {
      margin: 0,
    },
  },
}));
