import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '../../../../assets/info-icon.svg';
import { useFormNdiSigningContext } from '../../../../contexts/ndiSigningContexts/useFormNdiSigningContext';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import { withGoogleRecaptchaProps } from '../../../../hooks/useGoogleRecaptcha';
import { downloadFileFromDSS } from '../../../../lib/api/dss';
import { formStateSelector, submitForm } from '../../../../redux/slices/formSlice';
import { getPdfWithAnnotsAsDataUrl } from '../../../../utils/pdftron';
import InfoSidebar from '../../Sidebar/InfoSidebar';
import { dFormRecaptchaAction } from '../constants';
import FieldSummary from './FieldSummary';
import FillerDetails from './FillerDetails';
import InvalidForm from './InvalidForm';
import { ContactFormPlaceholder, FieldsPlaceholder } from './Placeholder';
import { classes, Root } from './Sidebar.style';

const Sidebar = () => {
  const { t } = useTranslation();
  const {
    formFiller,
    form,
    isFormSubmitted,
    isLoadingFields,
    submittedFormDssKey,
    isFetchingForm,
    fetchFormFailed,
  } = useSelector(formStateSelector);

  // temporary states for development
  // Currently the backend does not return separate errors.
  const isFormClosed = false; // this data should come from the backend
  const isFormUnavailable = fetchFormFailed;
  const isFillerDetailsFilled = formFiller.name && formFiller.email && formFiller.agreedToTnC;

  const handleDownloadSubmittedForm = useCallback(
    async ({ downloadFunction }) => {
      if (isFormSubmitted && submittedFormDssKey) {
        let submittedForm = await downloadFileFromDSS(submittedFormDssKey);
        submittedForm = submittedForm.startsWith('data:application/pdf;base64,')
          ? submittedForm
          : 'data:application/pdf;base64,' + submittedForm;

        downloadFunction({
          data: submittedForm,
          filename: form.name,
        });
      }
    },
    [isFormSubmitted, submittedFormDssKey, form.name],
  );

  const renderSidebarContent = () => {
    if (isFormClosed) {
      return <InvalidForm formTitle={form.name} message={t('FormsClosed')} />;
    } else if (isFormUnavailable) {
      return <InvalidForm formTitle={form.name} message={t('FormsUnavailable')} />;
    } else if (isFormSubmitted) {
      return (
        <InfoSidebar
          title={t('FormSubmittedTitle')}
          body={t('FormSubmittedDescription')}
          handleDownloadPdf={handleDownloadSubmittedForm}
          isForm
        />
      );
    } else if (!isFillerDetailsFilled) {
      return isFetchingForm ? <ContactFormPlaceholder /> : <FillerDetails />;
    } else {
      return isLoadingFields ? <FieldsPlaceholder /> : <FieldsViewWithGoogleRecaptchaProps />;
    }
  };

  return <Root isCompletion={isFormSubmitted}>{renderSidebarContent()}</Root>;
};

const FieldsView = ({ googleReCaptchaProps }) => {
  const { form, isSubmittingForm, isAllRequiredFieldsFilled } = useSelector(formStateSelector);

  const { instance } = usePdfTronContext();
  const dispatch = useDispatch();
  const { setAllowNdiSigning, initiateNdiSigning } = useFormNdiSigningContext();
  const [isSingPassSigning, setIsSingPassSigning] = useState(false);

  useEffect(() => {
    if (!form) return;
    setIsSingPassSigning(form.fields.digiSignatures.length > 0);
  }, [form]);

  useEffect(() => {
    setAllowNdiSigning(isAllRequiredFieldsFilled);
  }, [isAllRequiredFieldsFilled, setAllowNdiSigning]);

  const handleSubmitForm = useCallback(async () => {
    if (!form?.id) {
      return;
    }

    const recaptchaToken = await googleReCaptchaProps?.executeRecaptcha(
      dFormRecaptchaAction.FormSubmit,
    );

    const formBase64 = (await getPdfWithAnnotsAsDataUrl(instance))?.split(',')[1];
    await dispatch(
      submitForm({
        recaptchaToken,
        formId: form.id,
        formBase64,
      }),
    );
  }, [instance, form?.id, dispatch, googleReCaptchaProps]);

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.fieldSummaryContainer}>
        <FieldSummary />
      </Grid>
      <Grid item className={classes.notice}>
        {isSingPassSigning && isAllRequiredFieldsFilled && <SingPassSigningNotice />}
      </Grid>
      <Grid item className={classes.actionButtons}>
        {isSingPassSigning ? (
          <SignWithSingPassButton
            onClick={initiateNdiSigning}
            isDisabled={!isAllRequiredFieldsFilled || isSubmittingForm}
          />
        ) : (
          <SubmitFormButton
            onClick={handleSubmitForm}
            isDisabled={!isAllRequiredFieldsFilled || isSubmittingForm}
          />
        )}
      </Grid>
    </Grid>
  );
};

const FieldsViewWithGoogleRecaptchaProps = withGoogleRecaptchaProps(FieldsView);

const SingPassSigningNotice = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.singPassSigningNotice}>
      <img className={classes.singPassSigningNoticeIcon} src={InfoIcon} />
      <div className={classes.singPassSigningNoticeMessage}>
        <Typography variant="title_medium" color="info.dark" align="left">
          {t('FormSingPassSigningNoticeTitle')}
        </Typography>
        <Typography variant="body_medium" color="text.primary" align="left">
          {t('FormSingPassSigningNoticeMessage')}
        </Typography>
      </div>
    </div>
  );
};

const SubmitFormButton = ({ onClick, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained-round"
      className={classes.submitFormButton}
      onClick={onClick}
      disabled={isDisabled}
    >
      {t('SubmitFormButton')}
    </Button>
  );
};

const SignWithSingPassButton = ({ onClick, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained-round"
      className={classes.signWithSingPassButton}
      onClick={onClick}
      disabled={isDisabled}
    >
      {t('SignWithSingPassButton')}
    </Button>
  );
};

export default Sidebar;
