import { styled } from '@mui/material';

const PREFIX = 'UploadFile';

export const classes = {
  documentIcon: `${PREFIX}-documentIcon`,
  fileDrop: `${PREFIX}-fileDrop`,
  innerFileDrop: `${PREFIX}-innerFileDrop`,
  fileDropText: `${PREFIX}-fileDropText`,
  fileDropOr: `${PREFIX}-fileDropOr`,
  fileDropButton: `${PREFIX}-fileDropButton`,
  fileInput: `${PREFIX}-fileInput`,
  documentVerified: `${PREFIX}-documentVerified`,
  documentVerifiedMismatch: `${PREFIX}-documentVerifiedMismatch`,
  tryAgain: `${PREFIX}-tryAgain`,
  fileDropSpan: `${PREFIX}-fileDropSpan`,
  uploadedFileBox: `${PREFIX}-uploaded-file-box`,
  removeFileButton: `${PREFIX}-remove-file`,
};

export const Root = styled('div')(({ theme }) => ({
  width: '100%',

  [`& .${classes.fileDrop}`]: {
    backgroundColor: theme.palette.background.main,
    height: 160,
    borderRadius: 4,
    padding: theme.spacing(2),
  },

  [`& .${classes.innerFileDrop}`]: {
    border: `2px dashed ${theme.palette.primary.light}`,
    height: '136px',
    marginTop: theme.spacing(2.5),
    background: theme.palette.background.main,
    width: '100%',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    boxSizing: 'border-box',
  },

  [`& .${classes.fileDropText}`]: {
    color: theme.palette.primary.light,
  },

  [`& .${classes.fileDropOr}`]: {
    color: theme.palette.lightGrey.dark,
    marginTop: theme.spacing(-1),
  },

  [`& .${classes.fileDropOr}:before, .${classes.fileDropOr}:after`]: {
    content: '""',
    width: 28,
    height: 0.8,
    background: theme.palette.lightGrey.dark,
    display: 'inline-block',
    position: 'relative',
    opacity: 0.3,
    flex: 1,
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.fileDropSpan}`]: {
    margin: theme.spacing(0, 1),
    fontSize: 8,
  },

  [`& .${classes.fileDropButton}`]: {
    color: theme.palette.primary.main,
    background: theme.palette.supporting.light,
    borderRadius: 4,
    boxShadow: 'none',
    textTransform: 'none',
    maxWidth: '60%',
    gap: theme.spacing(2),
    fontSize: 12,
    padding: theme.spacing(2, 4),
  },

  [`& .${classes.fileInput}`]: {
    display: 'none',
  },

  [`& .${classes.uploadedFileBox}`]: {
    width: '45%',
    backgroundColor: theme.palette.supporting.light,
    border: `1px solid ${theme.palette.stroke.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    wordBreak: 'break-all',
  },

  [`& .${classes.removeFileButton}`]: {
    padding: '2.5px',
    alignSelf: 'flex-start',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    [`& .MuiSvgIcon-root`]: {
      fontSize: '11px',
    },
  },
}));
