import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector, useDispatch } from 'react-redux';
import {
  zoomIn,
  zoomOut,
  nextPage,
  prevPage,
  setTotalPages,
  setZoom,
  setPage,
} from '../../redux/actions/pdfviewerfunctions';
import { Grid, Button } from '@mui/material';
import ZoomOutLightIcon from '../../assets/zoom-out-light.png';
import ZoomInLightIcon from '../../assets/zoom-in-light.png';
import './DocumentViewer.css';

import { useTranslation } from 'react-i18next';

// Worker file should ideally be the same as the one used for Open API visual builder to ensure consistency.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfViewer(props) {
  const { t } = useTranslation();
  const { setPdfHeight, setPdfWidth, setPdfLeft } = props;
  const dispatch = useDispatch();
  const pdfPageRef = useRef(null);
  const headerToolBar = document.getElementsByClassName('doc-view-header');
  const pdfContainer = document.getElementsByClassName('document');
  const PdfViewerStates = useSelector((state) => state.PdfViewerStates);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pageInput, setPageInput] = useState(PdfViewerStates.currentPage);
  const isMdDown = window.innerWidth < 900;

  function onDocumentLoadSuccess({ numPages }) {
    props.setIsPdfLoading(false);
    setPdfLoading(false);
    dispatch(setZoom(0));
    dispatch(setTotalPages(numPages));
    setTimeout(function () {
      dispatch(setZoom(1.3));
    }, 200);
    dispatch(setPage(1));
    dispatch(setPage(1));
  }

  const next = () => {
    const nextPageNum = PdfViewerStates.currentPage + 1;

    if (!isValidPageNumber(nextPageNum)) {
      return;
    }

    dispatch(nextPage());
    setPageInput(nextPageNum);
  };

  const prev = () => {
    const prevPageNum = PdfViewerStates.currentPage - 1;

    if (!isValidPageNumber(prevPageNum)) {
      return;
    }

    dispatch(prevPage());
    setPageInput(prevPageNum);
  };

  const zoomInHandle = () => {
    dispatch(zoomIn());
  };

  const isValidPageNumber = (value) => {
    return value && parseInt(value) > 0 && value <= PdfViewerStates.totalPages;
  };

  const zoomOutHandle = () => {
    dispatch(zoomOut());
  };

  const onPageRenderSuccess = useCallback(() => {
    setPdfHeight(pdfPageRef.current.offsetHeight);
    setPdfWidth(pdfPageRef.current.offsetWidth);
    setPdfLeft(pdfPageRef.current.offsetLeft);
  }, [setPdfHeight, setPdfWidth, setPdfLeft]);

  useEffect(() => {
    function handleResize() {
      if (headerToolBar.length > 0) {
        if (isMdDown) {
          headerToolBar[0].style.width = '100% !important';
          pdfContainer[0].style.width =
            parseInt(window.innerWidth * (PdfViewerStates.zoom - 0.3)) - 5 + 'px';
        } else {
          headerToolBar[0].style.width = 'auto';
          pdfContainer[0].style.width = '100% !important';
        }

        props.setPdfHeaderHeight(headerToolBar[0].offsetHeight);
      }
    }

    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (props.getDimensions !== undefined && props.getDimensions === true) {
      if (pdfPageRef.current) {
        headerToolBar[0].style.width = 'auto';
        if (headerToolBar.length > 0) {
          if (isMdDown) {
            headerToolBar[0].style.width = '100% !important';
          } else {
            headerToolBar[0].style.width = 'auto';
          }
        }
        props.setPdfHeaderHeight(headerToolBar[0].offsetHeight);
      }
    }
  }, [PdfViewerStates.zoom, PdfViewerStates.currentPage, props, headerToolBar, isMdDown]);

  return (
    <div className="pdf" style={{ paddingTop: '0px' }}>
      <Grid
        container
        spacing={0}
        justifyContent="space-between"
        style={{ padding: '10px 16px' }}
        className="doc-view-header"
        item
        xs={12}
        md={12}
      >
        <Grid container item xs={8} md={8} spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <div className="file-name">
              {props.FileName.length > 40 ? props.FileName.slice(0, 41) + '...' : props.FileName}
            </div>
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <div className="current-page">
              <Button variant="outlined" className="input-button" onClick={() => prev()}>
                ᐸ
              </Button>
              {/* <div className="page-number">{PdfViewerStates.currentPage}</div> */}
              <input
                style={{ width: '35px', textAlign: 'center', margin: '0 5px' }}
                value={pageInput}
                onChange={(e) => {
                  const value = e.target.value;
                  setPageInput(value);
                  if (isValidPageNumber(value)) {
                    dispatch(setPage(parseInt(value)));
                  }
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (isValidPageNumber(value)) {
                    // Remove leading zeros
                    setPageInput(parseInt(value).toString());
                  } else {
                    setPageInput(PdfViewerStates.currentPage.toString());
                  }
                }}
              />
              <Button variant="outlined" className="input-button" onClick={() => next()}>
                {' '}
                ᐳ{''}
              </Button>
              <div
                style={{
                  marginLeft: '8px',
                }}
              >
                / {PdfViewerStates.totalPages} {t('PdfPage')}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          item
          xs={4}
          md={4}
        >
          <Grid item className="zoom-button">
            <img
              src={ZoomOutLightIcon}
              className="zoom-icon"
              onClick={() => zoomOutHandle()}
              alt="zoom-out-logo"
            />
          </Grid>
          <Grid item className="text-align-center">
            <span className="zoom-percentage">
              {parseInt(Math.round((PdfViewerStates.zoom - 0.3) * 100))}%
            </span>
          </Grid>
          <Grid item className="zoom-button">
            <img
              src={ZoomInLightIcon}
              className="zoom-icon"
              onClick={() => zoomInHandle()}
              alt="zoom-out-logo"
            />
          </Grid>
        </Grid>
      </Grid>

      <center style={{ visibility: pdfLoading ? 'hidden' : 'visible' }}>
        <Document
          file={props.pdf}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
          className="document"
        >
          {isMdDown ? (
            <Page
              pageNumber={PdfViewerStates.currentPage}
              canvasRef={pdfPageRef}
              onRenderSuccess={onPageRenderSuccess}
              width={parseInt(window.innerWidth * (PdfViewerStates.zoom - 0.3)) - 5}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ) : (
            <Page
              pageNumber={PdfViewerStates.currentPage}
              canvasRef={pdfPageRef}
              onRenderSuccess={onPageRenderSuccess}
              scale={PdfViewerStates.zoom}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          )}
        </Document>
      </center>
    </div>
  );
}
