import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNdiSigningContext } from '../../../contexts/ndiSigningContexts/useNdiSigningContext';
import { usePdfTronContext } from '../../../contexts/usePdfTronContext';
import { useWebexContext } from '../../../contexts/useWebexContext';
import { useDedocoSigning } from '../../../hooks/useDedocoSigning';
import useRecipientRole from '../../../hooks/useRecipientRole';
import { base64ToBlob } from '../../../utils/base64';
import { appendTagName, isCheckbox } from '../../../utils/checkboxWidget';
import { isActualDate, isCustomDate } from '../../../utils/dateWidget';
import {
  drawConfirmations,
  drawCustomTexts,
  drawDigitalSignatures,
  drawESignatures,
  getAnnotationContentAsImage,
  getAnnotationTextAsImage,
  getAnnotInfoFromFieldId,
  getFieldIdFromAssociatedAnnotId,
  getRotatedDimensions,
  getSortedAnnotationsList,
  normalStyles,
} from '../../../utils/pdftron';
import { shouldRenderSigningPad } from '../../../utils/renderCondition';
import { isInitials, isSignature } from '../../../utils/signatureWidget';
import { isAlphanumeric, isCustomText, isUrl } from '../../../utils/textWidget';
import { WebViewerPlaceholder } from '../Form/Sidebar/Placeholder';
import CustomTextBox from '../Sidebar/CustomTextBox';
import CustomDatePicker from '../Sidebar/DatePicker';
import SignatureSigning from '../Sidebar/SignatureSigning';
import SlideUpPanel from '../SlideUpPanel';
import NextButton from './NextButton';
import { ActionButtonWrapper, WebViewerRoot, WebViewerWrapper } from './PdfWebViewer.style';
import Toolbar from './Toolbar';
import { CypressIds } from '../../../constant/cypressIds';
import { isEmail } from '../../../utils/emailWidget';
import EmailBox from '../Sidebar/EmailBox';
import { validateAlphanumeric, validateUrl } from '../../../utils/validation';
import { getFontSize } from '../../../utils/pdftronWidgetCommon';
import { isDropdownList } from '../../../utils/dropdownListWidget';
import { DropdownList } from '../Sidebar/DropdownList';

const PdfWebViewer = ({
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
  setHasModified,
  noOfRemainingFields,
}) => {
  const viewer = useRef(null);
  const {
    instance,
    setInstance,
    loadDocumentToWebViewer,
    pdfTronSettings,
    sortedAnnotationsList,
    setSortedAnnotationsList,
    removeHotKeys,
  } = usePdfTronContext();
  const {
    documentToSign,
    currentParticipant,
    currentField,
    setCurrentField,
    resetCurrentField,
    projectSigners,
    getCurrentSignerIndex,
    uid,
    annotsLoaded,
    setAnnotsLoaded,
    updateSignerInputs,
    deleteSignerInputs,
    isUsingUpdatedDocument,
  } = useDedocoSigning();
  const { isSigner, isObserver, isApprover } = useRecipientRole();
  const { t } = useTranslation();
  const {
    isWebexSigning,
    isMeetingHost: isWebexMeetingHost,
    isWebexSigningSessionComplete,
  } = useWebexContext();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // state
  const [openSlideUpPanel, setOpenSlideUpPanel] = useState(false);

  // ref
  const isFirstDocumentLoading = useRef(true);

  // Used to pass latest currentField into useEffect that doesn't have currentField as dependency
  const currentFieldRef = useRef();
  currentFieldRef.current = currentField;

  const isFillingUsingSidebar = currentField.isFilling && currentField.isUsingSidebar;

  let documentViewer, Annotations, annotationManager, AnnotationChangedActions, iframeWindow;
  if (instance) {
    ({ documentViewer, Annotations, annotationManager } = instance.Core);
    ({ iframeWindow } = instance.UI);
    AnnotationChangedActions = instance.Core.AnnotationManager.AnnotationChangedActions;
  }

  useEffect(() => {
    !instance &&
      WebViewer(pdfTronSettings, viewer.current).then((newInstance) => {
        setInstance(newInstance);
        // Enable continuous page scrolling
        const { documentViewer: _documentViewer, DisplayMode, DisplayModes } = newInstance.Core;
        const displayModeManager = _documentViewer.getDisplayModeManager();
        displayModeManager.setDisplayMode(
          new DisplayMode(_documentViewer, DisplayModes.Continuous),
        );
      });

    return () => {
      setInstance(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!instance) {
      return;
    }
    // Load document to Web Viewer
    if (documentToSign?.pdfBase64String) {
      loadDocumentToWebViewer(
        base64ToBlob(documentToSign?.pdfBase64String),
        { sync: true },
        async () => {
          if (isFirstDocumentLoading.current) {
            await drawAllSignerAnnotations();
          }

          if (isWebexSigning && !isFirstDocumentLoading.current) {
            if (isWebexMeetingHost || isObserver || isWebexSigningSessionComplete) {
              await drawAllSignerAnnotations();
            }
          }
          isFirstDocumentLoading.current = false;
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, documentToSign?.pdfBase64String, documentToSign?.document?.signers]);

  useEffect(() => {
    if (!instance) {
      return;
    }

    removeHotKeys();
    const updateFieldInput = (field) => {
      const widgetAnnot = field.widgets[0];
      // ignore pdf itself annotation
      // in case the pdf itself has annotation that does not have page number
      if (
        !widgetAnnot?.PageNumber ||
        !widgetAnnot?.Height ||
        !widgetAnnot?.Width ||
        !widgetAnnot?.X ||
        !widgetAnnot?.Y
      )
        return;

      const pageInfo = documentViewer.getDocument().getPageInfo(widgetAnnot.PageNumber);
      const annotMetadata = {
        page: widgetAnnot.PageNumber,
        height: widgetAnnot.Height,
        width: widgetAnnot.Width,
        x: widgetAnnot.X / pageInfo.width,
        y: widgetAnnot.Y / pageInfo.height,
        signerName: currentParticipant.name,
      };
      if (isCheckbox(field.name)) {
        annotMetadata.type = 'image';
        updateSignerInputs({ dataName: field.name, value: field.value, annotMetadata });
      }
    };

    documentViewer.addEventListener('annotationsLoaded', () => {
      const sortedAnnotationsList = getSortedAnnotationsList(annotationManager);
      const filteredAnnotationsList = sortedAnnotationsList.filter((annot) => {
        if (isSigner) {
          return getAnnotInfoFromFieldId(annot.id).signerIndex === getCurrentSignerIndex();
        }
        return true;
      });

      setSortedAnnotationsList(filteredAnnotationsList);

      const annotsList = annotationManager?.getAnnotationsList();
      annotsList?.forEach((annot) => {
        const annotInfo = getAnnotInfoFromFieldId(annot.Id);
        const signerInfo = projectSigners[annotInfo.signerIndex];
        if (annotInfo.type.includes('checkbox')) {
          appendTagName(annot.Id, signerInfo.name, signerInfo.color, iframeWindow);
        }
      });

      const fieldManager = annotationManager?.getFieldManager();
      fieldManager.forEachField((field) => {
        updateFieldInput(field);
      });

      setAnnotsLoaded(true);
    });

    annotationManager.addEventListener('fieldChanged', (field) => {
      updateFieldInput(field);
    });

    const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
    signatureTool.addEventListener('locationSelected', (_, signatureWidget) => {
      setCurrentField(signatureWidget.Id, true);
    });

    annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
      // Handle widget annotations that are added during document load.
      if (action === AnnotationChangedActions.ADD) {
        annotations.forEach((annot) => {
          if (!(annot instanceof Annotations.WidgetAnnotation)) return;

          // getCustomStyles function is called when page zoom changes.
          Annotations.WidgetAnnotation.getCustomStyles = normalStyles.bind(
            null,
            iframeWindow,
            documentViewer,
            Annotations,
          );
        });
      }
    });

    annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
      // Handle annotations that are added as signer inputs.
      if (action === AnnotationChangedActions.ADD || action === AnnotationChangedActions.MODIFY) {
        annotations.forEach(async (annot) => {
          const currentFieldId = currentFieldRef.current.fieldId;

          const fieldId = getFieldIdFromAssociatedAnnotId(annot.Id);
          if (!fieldId) {
            // Means that annot is not a signer input
            return;
          }

          let selectedId = '';
          if (Array.isArray(currentFieldId)) {
            const index = currentFieldId.indexOf(fieldId);

            if (index !== -1) {
              [selectedId] = currentFieldId.splice(index, 1);
            }

            // If currentFieldId is an array, it means that multiple annotations need to be drawn at one go.
            // Therefore, we should not clear currentField until all the annotations are drawn.
            if (currentFieldId.length === 0) {
              resetCurrentField();
            }
          } else {
            selectedId = currentFieldId;
            resetCurrentField();
          }

          annot.disableRotationControl();
          annot.NoResize = true;
          annot.NoMove = true;

          // Actual date is inserted during initial load, so there will be no selected widget.
          if ((selectedId && selectedId === fieldId) || isActualDate(fieldId)) {
            setHasModified(true);
            const doc = documentViewer.getDocument();
            const pageInfo = doc.getPageInfo(annot.PageNumber);
            const rotation = doc.getPageRotation(annot.PageNumber);
            const rotatedDimensions = getRotatedDimensions(rotation, {
              height: annot.Height,
              width: annot.Width,
            });
            const annotMetadata = {
              page: annot.PageNumber,
              width: rotatedDimensions.width,
              height: rotatedDimensions.height,
              x: annot.X / pageInfo.width,
              y: annot.Y / pageInfo.height,
              signerName: currentParticipant.name,
            };
            if (annot.Subject === 'Stamp') {
              // Call getAnnotationContentAsImage
              // to draw the image to fit object's box
              const imageData = await getAnnotationContentAsImage(annot);
              annotMetadata.type = 'image';
              updateSignerInputs({
                dataName: fieldId,
                value: imageData,
                annotMetadata,
              });
            } else if (annot.Subject === 'Free Text') {
              let contents;
              // If selected annot is signature, get contents as data urls,
              // otherwise get the contents as text.
              if (isSignature(selectedId) || isInitials(selectedId)) {
                contents = await getAnnotationTextAsImage(annot, documentViewer);
                annotMetadata.type = 'image';
              } else {
                contents = annot.getContents();
                annotMetadata.type = 'text';
                annotMetadata.fontSize = parseFloat(getFontSize(contents.length));
              }
              updateSignerInputs({ dataName: fieldId, value: contents, annotMetadata });
            }
          }
        });
      }
      if (action === AnnotationChangedActions.DELETE) {
        annotations.forEach((annot) =>
          deleteSignerInputs(getFieldIdFromAssociatedAnnotId(annot.Id)),
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(() => {
    if (currentField.isFilling) setOpenSlideUpPanel(false);
  }, [currentField.isFilling]);

  useEffect(() => {
    if (isWebexSigning && documentToSign.submitDocumentCompleted) {
      setOpenSlideUpPanel(false);
    }
  }, [isWebexSigning, documentToSign.submitDocumentCompleted]);

  const drawAllSignerAnnotations = () => {
    return Promise.all(
      projectSigners.map((signer, index) => {
        return drawSignerAnnotations(signer, index);
      }),
    );
  };

  const { initiateNdiSigningRef } = useNdiSigningContext();

  const drawSignerAnnotations = (signer, signerIndex) => {
    const {
      id,
      customTexts,
      esignatures,
      digiSignatures,
      confirmations,
      name,
      signerName,
      color,
      email,
      signerEmail,
      hasSigned,
    } = signer;

    let isCurrentSigner = false;
    if (id === uid) {
      isCurrentSigner = true;
    }

    const signerDetails = {
      signerIndex,
      name: name || signerName,
      email: email || signerEmail,
      color,
      hasSigned,
      isCurrentSigner,
    };

    drawConfirmations(
      confirmations,
      signerDetails,
      isObserver,
      isApprover,
      isUsingUpdatedDocument,
      instance,
    );

    return Promise.all([
      drawESignatures(esignatures, signerDetails, isUsingUpdatedDocument, instance),
      drawCustomTexts(
        customTexts,
        signerDetails,
        isObserver,
        isApprover,
        isUsingUpdatedDocument,
        instance,
      ),
      drawDigitalSignatures(digiSignatures, signerDetails, initiateNdiSigningRef, instance),
    ]);
  };

  // Handle on signing object click
  const renderFillingComponent = useCallback(() => {
    if (!isFillingUsingSidebar || isMdUp) return <></>;
    const currentFieldId = currentField.fieldId;

    const renderDropdownList = () => {
      const annotInfo = getAnnotInfoFromFieldId(currentFieldId);
      const dropdownInfo = projectSigners[annotInfo.signerIndex].customTexts[annotInfo.index];
      return <DropdownList label={dropdownInfo.label} options={dropdownInfo.options} />;
    };

    return (
      <Box
        sx={{
          display: isFillingUsingSidebar ? 'block' : 'none',
          height: '100%',
          padding: theme.spacing(4),
          boxSizing: 'border-box',
          width: '100%',
          backgroundColor: (theme) => theme.palette.background.main,
        }}
      >
        {shouldRenderSigningPad(currentFieldId) && <SignatureSigning />}
        {isCustomText(currentFieldId) && <CustomTextBox />}
        {isUrl(currentFieldId) && (
          <CustomTextBox validationFunc={validateUrl} errorMessage={t('EnterValidUrl')} />
        )}
        {isAlphanumeric(currentFieldId) && (
          <CustomTextBox
            validationFunc={validateAlphanumeric}
            errorMessage={t('NoSpecialCharacters')}
          />
        )}
        {isEmail(currentFieldId) && <EmailBox />}
        {isCustomDate(currentFieldId) && <CustomDatePicker />}
        {isDropdownList(currentFieldId) && renderDropdownList()}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFillingUsingSidebar, currentField]);

  const handleClick = () => {
    if (isWebexSigning) {
      if (isSigner) {
        handleSignDoc();
      } else if (isApprover) {
        handleApproveDoc();
      }
    } else {
      setOpenSlideUpPanel(true);
    }
  };

  const toHideWebViewer = useCallback(() => {
    const isMobileSize = !isMdUp;
    const completedWebexSigning =
      isWebexSigningSessionComplete ||
      (!isWebexMeetingHost && isWebexSigning && documentToSign.submitDocumentCompleted);
    return isMobileSize && (isFillingUsingSidebar || completedWebexSigning);
  }, [
    documentToSign.submitDocumentCompleted,
    isFillingUsingSidebar,
    isWebexMeetingHost,
    isWebexSigning,
    isWebexSigningSessionComplete,
    isMdUp,
  ]);

  const styles = useMemo(() => {
    if (toHideWebViewer()) {
      return { display: 'none' };
    }
    return { flex: 1 };
  }, [toHideWebViewer]);

  const buttonText = useMemo(() => {
    if (isWebexSigning && isSigner) {
      return t('SaveAndConfirm');
    }
    if (isSigner) return t('MobileSignDocumentButton');
    if (isObserver) return t('MobileMoreActionsButton');
    if (isApprover) return t('MobileApproveDocumentButton');
  }, [isApprover, isObserver, isSigner, isWebexSigning, t]);

  const disableButton = !!(isWebexSigning && disableSubmitButton);
  const displayButton = !(isWebexMeetingHost && isObserver);

  return (
    <>
      <WebViewerRoot sx={styles}>
        <Toolbar documentViewer={documentViewer} fileName={documentToSign?.fileName} />
        {!annotsLoaded && <WebViewerPlaceholder />}
        <WebViewerWrapper
          ref={viewer}
          data-cypress={CypressIds.pdfWebViewer}
          displayButton={displayButton}
          style={{ display: !annotsLoaded ? 'none' : '' }}
        ></WebViewerWrapper>
        <ActionButtonWrapper display={displayButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClick}
            disabled={disableButton}
          >
            {buttonText}
          </Button>
        </ActionButtonWrapper>
        <SlideUpPanel
          open={openSlideUpPanel}
          setOpen={setOpenSlideUpPanel}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
          noOfRemainingFields={noOfRemainingFields}
        />
        {sortedAnnotationsList?.length > 1 && <NextButton />}
      </WebViewerRoot>
      {renderFillingComponent()}
    </>
  );
};

export default PdfWebViewer;
