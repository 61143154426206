import {
  ApprovalModalRoot,
  ApprovalModalTitle,
  ApprovalModalActions,
  ApprovalCloseIcon,
  CloseButton,
  ApproveButton,
  CancelButton,
} from './ConfirmDocumentApprovalModal.styles';
import { useTranslation } from 'react-i18next';

const ConfirmDocumentApprovalModal = ({ open, onApprove, onClose }) => {
  const { t } = useTranslation();
  return (
    <ApprovalModalRoot open={open}>
      <ApprovalModalTitle>
        {t('ConfirmDocumentApprovalModalHeader')}
        <CloseButton onClick={onClose}>
          <ApprovalCloseIcon />
        </CloseButton>
      </ApprovalModalTitle>
      <ApprovalModalActions>
        <CancelButton onClick={onClose}>
          {t('ConfirmDocumentApprovalModalCancelButton')}
        </CancelButton>
        <ApproveButton data-testid="approve-modal-approve-button" onClick={onApprove}>
          {t('ConfirmDocumentApprovalModalApproveButton')}
        </ApproveButton>
      </ApprovalModalActions>
    </ApprovalModalRoot>
  );
};

export default ConfirmDocumentApprovalModal;
