import { styled } from '@mui/material/styles';
import { Circle } from '@mui/icons-material';

export const LoginButton = styled('img')(({ theme }) => ({
  width: '320px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '280px',
  },
}));

export const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
  margin: theme.spacing('auto', 0, 0, 0),
}));

export const FooterLink = styled('a')(() => ({
  color: '#4F4F4F',
  fontWeight: 400,
  fontSize: '16px',
}));

export const FooterSeparator = styled(Circle)(() => ({
  width: '9px',
  height: '9px',
  color: '#4D5F78',
}));
