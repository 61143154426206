import React from 'react';
import Popup from 'reactjs-popup';
import { requestExpiredMessage } from '../Signingrequest/ndiSigningFunctions';

const QrCodePopUp = (props) => {
  return (
    <>
      <Popup className="my-popup3" open={props.onOpen} modal nested closeOnDocumentClick={false}>
        {(close) => (
          <div className="modal3">
            <button
              style={{ visibility: 'visible' }}
              className="close"
              onClick={() => {
                props.setOpen(false);
                close();
                props.onReset();
              }}
            >
              &times;
            </button>
            <div>
              <div
                style={{
                  textAlign: 'center',
                  padding: '30px',
                  fontWeight: 'bold',
                  fontSize: '30px',
                  fontFamily: 'Lato',
                  color: '#504E56',
                }}
              >
                Digital Signature(NDI)
              </div>
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: '#e1e1e1',
                  height: '565px',
                  paddingLeft: '130px',
                  paddingTop: '20px',
                }}
              >
                {props.qrData && props.challenge && !props.loading && !props.signingError ? (
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: '393px',
                      textAlign: 'center',
                      height: '524px',
                    }}
                  >
                    {/* if times up during transition up or signing rejected by signer remove challenge code header */}
                    {(props.transition && props.minutes === 0 && props.seconds === 0) ||
                    props.signingError ? (
                      <div></div>
                    ) : (
                      <div>{props.challengeCodeHeaders}</div>
                    )}

                    <div style={{ paddingTop: '10px' }}>
                      {/*if transitioned happened but QR code has expired */}
                      {props.transition && props.minutes === 0 && props.seconds === 0 ? (
                        <div>{requestExpiredMessage}</div>
                      ) : (
                        <div>
                          {/*if qrcode scanned and qr code is not expired then proceed with transition*/}
                          {props.transition && (props.minutes || props.seconds) ? (
                            <div>{props.transition}</div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      )}
                      {/* Refresh qr code logic */}

                      {(props.minutes === 0 && props.seconds === 0 && props.qrData) ||
                      props.signingError ? (
                        //TODO: refresh
                        <div>{props.refreshQRButton}</div>
                      ) : (
                        <div>
                          {props.transition || props.loading ? (
                            <div>{props.transitionMessage}</div>
                          ) : (
                            <div>{props.qrCodeMessage}</div>
                          )}
                        </div>
                      )}
                      {/* QR code expire overlay */}
                      {props.minutes === 0 && props.seconds === 0 && !props.transition ? (
                        <div className="qr-content">{props.expiredQR}</div>
                      ) : (
                        //TODO:refresh
                        <div className="qr-content">{props.qrContent}</div>
                      )}
                      {/* if loading in progress */}
                      {props.loading && (props.minutes || props.seconds) ? (
                        <div>{props.loading}</div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ) : /* if not qr code or challenge code then show spinner */
                props.signingError ? (
                  <>{props.errorMessage}</>
                ) : (
                  <div>{props.showNDISpinner}</div>
                )}
              </div>

              {/* qr countdown timer with cancel button */}

              {props.qrData && props.challenge && !props.signingError ? (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '20px',

                    fontSize: '25px',
                    fontFamily: 'Lato',
                    color: '#504E56',
                  }}
                >
                  The {props.transition ? <>signing session</> : <>QR Code</>} will expire in{' '}
                  {props.minutes} minutes {props.seconds} seconds
                </div>
              ) : props.signingError ? (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '20px',
                    fontSize: '25px',
                    fontFamily: 'Lato',
                    color: '#504E56',
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '20px',
                    fontSize: '25px',
                    fontFamily: 'Lato',
                    color: '#504E56',
                  }}
                >
                  Generating QR code and Challenge Code
                </div>
              )}

              <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
                <button
                  style={{
                    width: '393px',
                    height: '40px',
                    fontSize: '15px',
                    fontFamily: 'Lato',
                    color: '#504E56',
                  }}
                  onClick={() => {
                    close();
                    props.setOpen(false);
                    props.onReset();
                  }}
                >
                  CANCEL
                </button>{' '}
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default QrCodePopUp;
