import React, { useCallback, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';

const prefix = (value) => `${value < 10 ? '0' : ''}${value}`;

const Timer = ({ handleExpiredTime, roomDetail }) => {
  const { t } = useTranslation();
  const time = roomDetail?.dateCreated ? new Date(roomDetail?.dateCreated) : new Date();
  time.setSeconds(time.getSeconds() + 60 * 60);

  const [expiredTimes, setExpiredTimes] = useState(1);
  const { seconds, minutes, restart, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => onExpire(),
  });

  const getTimeInfo = useCallback(
    (expiredTimes) => {
      switch (expiredTimes) {
        case 0:
          return t('TimerRemainingSession');
        case 1:
          return t('TimerExtendingSession');
        case 2:
          return t('TimerEndedSession');
      }
    },
    [t],
  );

  useEffect(() => {
    if (minutes === 10 && seconds === 0) {
      handleExpiredTime(getTimeInfo(0));
    }
  }, [getTimeInfo, handleExpiredTime, minutes, seconds]);

  const onExpire = () => {
    const timeMessage = getTimeInfo(expiredTimes);

    if (expiredTimes === 2) {
      restart(0);
      handleExpiredTime(timeMessage);
      return;
    }

    const thirtyMins = 30 * 60;
    const time = new Date();
    time.setSeconds(time.getSeconds() + thirtyMins);
    restart(time);
    handleExpiredTime(timeMessage);
    setExpiredTimes(2);
  };

  return (
    <span>{`${prefix(isRunning && minutes === 0 && seconds === 0 ? 60 : minutes)}:${prefix(
      seconds,
    )} min`}</span>
  );
};

export default Timer;
