import { Box, Button, Container, Grid } from '@mui/material';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../UI/Header';
import HeaderMobile from '../UI/HeaderMobile';
import './DocumentVerificationSuccess.css';
import { getCompanyLogo, getPublicWhiteLabelDetails } from '../../lib/api';

export default function DocumentVerificationSuccess(props) {
  const { t } = useTranslation();
  const PdfViewerStates = useSelector((state) => state.PdfViewerStates);
  const upperPanel = document.getElementsByClassName('upper-panel');
  const lowerPannel = document.getElementsByClassName('pdf');
  const pdfPage = document.getElementsByClassName('react-pdf__Page__canvas');
  const { action } = queryString.parse(props.location.search);
  const { id, public: publicVariable } = useParams();
  const isOpenApi = publicVariable === 'public';
  const [whiteLabelDetails, setWhiteLabelDetails] = useState({});

  const reRouteDedoco = () => {
    window.location = 'https://dedoco.com/';
  };

  const handleResize = useCallback(() => {
    if (pdfPage.length > 0) {
      upperPanel[0].style.height = pdfPage[0].offsetHeight + 'px';
      upperPanel[0].style.width = pdfPage[0].offsetWidth + 'px';
      if (pdfPage[0].offsetWidth > lowerPannel[0].offsetWidth) {
        upperPanel[0].style.left = 63 + 'px';
      } else {
        upperPanel[0].style.left = (lowerPannel[0].offsetWidth - pdfPage[0].offsetWidth) / 2 + 'px';
        // setchanged(true);
      }
    }
  }, [lowerPannel, pdfPage, upperPanel]);

  useEffect(() => {
    const loadWhiteLabelDetails = async () => {
      if (isOpenApi) {
        const details = await getPublicWhiteLabelDetails(id);
        setWhiteLabelDetails(details);
      } else {
        const logoDetails = await getCompanyLogo(id);
        setWhiteLabelDetails(logoDetails);
      }
    };

    loadWhiteLabelDetails();
  }, [id, isOpenApi]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    //for zoom functionality
    handleResize();
  }, [PdfViewerStates.totalPages, PdfViewerStates.zoom, handleResize]);

  function getCardHeader() {
    switch (action) {
      case 'approve':
        return t('DocumentApprovalSuccessCardMainHeader');
      case 'decline':
        return t('DocumentDeclineSuccessCardMainHeader');
      default:
        return t('DocumentVerificationSuccessCardMainHeader');
    }
  }

  function getCardSubHeader() {
    if (isOpenApi) return '';

    switch (action) {
      case 'approve':
        return t('DocumentApprovalSuccessCardMainSubHeader');
      case 'decline':
        return t('DocumentDeclineSuccessCardMainSubHeader');
      default:
        return t('DocumentVerificationSuccessCardMainSubHeader');
    }
  }

  return (
    <Box
      justifyContent="center"
      style={{
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        height: 'calc(100vh - 64px)',
      }}
    >
      <Header whiteLabelDetails={whiteLabelDetails} />
      <HeaderMobile showOtherOptions={false} whiteLabelDetails={whiteLabelDetails} />
      <Grid
        container
        style={{
          background: 'white',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Container style={{ backgroundColor: 'white' }}>
            <div className="card-container">
              <Grid className="top-card" container spacing={3}>
                <Grid container item xs={12} md={4} className="justify-center">
                  <img className="card-1-image" src="/Signing Done.png" alt="Signing Done" />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item>
                    <div className="card-1-header">{getCardHeader()}</div>
                  </Grid>
                  <Grid item>
                    <div className="card-1-content">{getCardSubHeader()}</div>
                  </Grid>
                </Grid>
              </Grid>
              {!isOpenApi && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={3}
                  className="bottom-card"
                >
                  <Grid item>
                    <div className="card-2-content" style={{ margin: '0 8px' }}>
                      {t('DocumentVerificationSuccessCardSubHeader')}
                    </div>
                    <div className="card-2-content">
                      {t('DocumentVerificationSuccessCardSubSubHeader')}
                    </div>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{
                        backgroundColor: '#FBE470',
                        borderRadius: '20px',
                        fontSize: 15,
                      }}
                      variant="contained"
                      onClick={reRouteDedoco}
                    >
                      {t('DocumentVerificationSuccessTryDedocoButton')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
