import { Grid, styled } from '@mui/material';

const PREFIX = 'SidebarWrapperContainer';

export const classes = {
  actionButtonGroup: `${PREFIX}-action-button-group`,
  backButton: `${PREFIX}-back-button`,
  title: `${PREFIX}-title`,
};

export const Root = styled(Grid)(({ theme }) => ({
  textAlign: 'left',
  height: '100%',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflowY: 'auto',
  gap: theme.spacing(4),
  width: '100%',

  [`& .${classes.actionButtonGroup}`]: {
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.backButton}`]: {
    padding: 0,
    color: theme.palette.background.base,
    display: 'flex',
    [`&:hover`]: {
      backgroundColor: 'transparent',
    },
  },
  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));
