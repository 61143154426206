const PREFIX = 'signing-app';

export const CypressIds = {
  tncSubmitButton: `${PREFIX}-tnc-submit-button`,
  otpProceedButton: `${PREFIX}-otp-proceed-button`,
  pdfWebViewer: `${PREFIX}-pdf-web-viewer`,
  fieldItemBox: (type, index) => `${PREFIX}-field-item-box-${type}-${index}`,
  sidebarBackButton: `${PREFIX}-sidebar-back-button`,
  drawSignatureCanvas: `${PREFIX}-draw-signature-canvas`,
  saveDrawSignatureButton: `${PREFIX}-save-draw-signature-button`,
  signerSaveAndConfirmButton: `${PREFIX}-signer-save-and-confirm-button`,
  rejectDocumentButton: `${PREFIX}-reject-document-button`,
  approverApproveDocumentButton: `${PREFIX}-approver-approve-document-button`,
  downloadPdfButton: `${PREFIX}-download-pdf-button`,
  startRecordingButton: `${PREFIX}-start-recording-button`,
  cancelRecordingButton: `${PREFIX}-cancel-recording-button`,
  selectSignerButton: (index) => `${PREFIX}-select-signer-button-${index}`,
  confirmSignerButton: `${PREFIX}-confirm-signer-button`,
  shareScreenButton: `${PREFIX}-share-screen-button`,
  notShareScreenButton: `${PREFIX}-not-share-screen-button`,
  continueCallButton: `${PREFIX}-continue-call-button`,
  leaveCallButton: `${PREFIX}-leave-call-button`,
};
