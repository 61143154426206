import { styled } from '@mui/material';

export const ChallengeCodeRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

export const ChallengeCodeHeader = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  padding: theme.spacing(0, 12),
}));

export const ChallengeCodeDisplay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  color: '#FFFFFF',
}));

export const ChallengeCodeDigit = styled('div')(({ theme }) => ({
  textAlign: 'center',
  width: '60.25px',
  display: 'inline-block',
  backgroundColor: '#504E56',
  fontSize: '52px',
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    fontSize: '42px',
  },
}));
