import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useWebexContext } from '../../contexts/useWebexContext';
import { WebexSteps } from './constants';
import WebexContainer from './WebexContainer';

const WaitingPage = (props) => {
  const { t } = useTranslation();
  const { setStep } = props;
  const { userIdentitySelected: userIdentity, setUserIdentity } = useWebexContext();

  const renderDescriptionText = () => {
    if (userIdentity.role === 'observer') {
      return t('WebexWaitingPageObserverText');
    }

    if (userIdentity.role === 'approver') {
      return t('WebexWaitingPageApproverText');
    }

    return t('WebexWaitingPageSignerText');
  };

  return (
    <WebexContainer
      body={
        <Grid
          container
          justifyContent="space-between"
          wrap="nowrap"
          flexDirection="column"
          sx={{ textAlign: 'start', gap: '16px' }}
        >
          <Grid item>
            <Typography variant="title_large">{`Welcome, ${userIdentity.name}!`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body_medium">{renderDescriptionText()}</Typography>
          </Grid>
          <Grid item className="text-align-center">
            <Typography variant="label_medium">
              {t('WebexConfirmIdentityChooseOtherRole1')}{' '}
              <Typography
                component={'span'}
                variant="label_medium"
                onClick={() => {
                  setStep(WebexSteps.CLAIM_ROLE);
                  setUserIdentity(null);
                }}
              >
                {t('WebexConfirmIdentityChooseOtherRole2')}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export default WaitingPage;
