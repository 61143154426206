import { useContext, useRef } from 'react';
import { DVideoSignEvents } from './constants';
import { VideoSigningStoreContext } from './VideoSigningStore';
class DedocoVideoSigningStore {
  /* IMPORTANT
   * ---------
   * All messages emitted to the backend need to have the userId.
   * The userId is needed for the backend to create/acquire the lock for the meeting session to prevent multiple concurrent session for the same user.
   * Currently userId is used to identify participants.
   * If userId is replaced by another ID, make the changes accordingly.
   */
  constructor({ socket }) {
    this.socket = socket;
    this.eventListeners = {};
    this.room = { __v: -1 };
    this.componentUpdaters = [];
    this.isSigningSessionComplete = false;
    this.heartbeatInterval = null;
  }

  connectHandler = () => {
    if (!this.socket) return;
    this.socket.connect();
    this.socket.on('connect', this.onConnect);
    this.socket.on('reconnect', this.onReconnect);
    this.socket.on('connect_error', this.onConnectError);

    return () => {
      this.socket.off('connect');
      this.socket.off('reconnect');
      this.socket.off('connect_error');
    };
  };

  registerComponent = (updater) => {
    this.componentUpdaters.push(updater);
    return () => {
      this.componentUpdaters = this.componentUpdaters.filter((item) => item !== updater);
    };
  };

  onConnect = () => {
    this.componentUpdaters.forEach((updater) => updater());
  };

  onReconnect = () => {
    console.log('onReconnect', this);
  };
  onConnectError = (err) => {
    console.log('onConnectError', err);
  };

  registerEvent = (name, listener) => {
    if (this.eventListeners[name]) this.eventListeners[name].push(listener);
    else this.eventListeners[name] = [listener];

    this.socket.on(name, listener);

    return () => {
      this.socket.off(name, listener);
      this.eventListeners[name] = this.eventListeners[name].filter((item) => item !== listener);
    };
  };

  registerExistingSocketEvents = () => {
    const unregisterExistingEvents = [];
    Object.keys(this.eventListeners).forEach((name) => {
      this.eventListeners[name].forEach((listener) => {
        this.socket.on(name, listener);
        unregisterExistingEvents.push(() => this.socket.off(name, listener));
      });
    });
    return () => {
      unregisterExistingEvents.forEach((unregister) => unregister());
    };
  };

  registerLifeCycleEvents = () => {
    const unregisterEvents = [];
    unregisterEvents.push(this.registerEvent(DVideoSignEvents.JOINED_ROOM, this.onJoinRoom));
    unregisterEvents.push(
      this.registerEvent(DVideoSignEvents.MEETING_STARTED, this.onMeetingStart),
    );
    unregisterEvents.push(this.registerEvent(DVideoSignEvents.NEXT_ACTION, this.onNextAction));
    unregisterEvents.push(
      this.registerEvent(DVideoSignEvents.CHOOSE_NEXT_ACTION, this.onChooseNextAction),
    );
    unregisterEvents.push(this.registerEvent(DVideoSignEvents.LEFT_CALL, this.onLeaveCall));
    unregisterEvents.push(this.registerEvent(DVideoSignEvents.CHANGE_UPDATE, this.onChangeUpdate));
    unregisterEvents.push(
      this.registerEvent(DVideoSignEvents.COMPLETED_SESSION, this.onCompleteSigning),
    );
    unregisterEvents.push(this.registerEvent(DVideoSignEvents.RECORD_SCREEN, this.onRecordScreen));
    return () => {
      unregisterEvents.forEach((unregister) => unregister());
    };
  };

  joinRoom = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.JOIN_ROOM, { documentId, userId });
  };
  onJoinRoom = (room) => {
    this.setRoom(room);
  };

  emitHeartbeat = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.ATTENDEE_HEARTBEAT, { documentId, userId });
  };

  startHeartbeat = (documentId, userId) => {
    clearInterval(this.heartbeatInterval);
    this.emitHeartbeat(documentId, userId);
    this.heartbeatInterval = setInterval(() => {
      this.emitHeartbeat(documentId, userId);
    }, 10000);
  };

  stopHeartbeat = () => {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = null;
  };

  nextAction = (documentId, userId, targetUserId) => {
    this.socket.emit(DVideoSignEvents.NEXT_ACTION, { documentId, userId, targetUserId });
  };
  onNextAction = (nextActor) => {
    this.setRoom({ nextActor }, true);
  };

  meetingStart = (documentId, userId) => {
    this.setRoom({ meetingStartAt: undefined }, true);
    this.socket.emit(DVideoSignEvents.MEETING_START, { documentId, userId });
  };
  onMeetingStart = (room) => {
    this.setRoom(room);
  };

  onChooseNextAction = ({ room }) => {
    this.setRoom(room);
  };

  leaveCall = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.LEAVE_CALL, { documentId, userId });
    this.stopHeartbeat();
  };

  onLeaveCall = ({ room }) => {
    this.setRoom(room);
  };

  endCall = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.END_CALL, { documentId, userId });
  };

  completeAction = (documentId, userId, action, dssKey) => {
    this.socket.emit(DVideoSignEvents.COMPLETE_ACTION, { documentId, userId, action, dssKey });
  };

  onChangeUpdate = ({ room }) => {
    this.setRoom(room);
  };
  onCompleteSigning = ({ room }) => {
    this.isSigningSessionComplete = true;
    this.setRoom(room);
  };

  getRoom = () => {
    return this.room || {};
  };

  getIsSigningSessionComplete = () => {
    return this.isSigningSessionComplete;
  };

  getActingOrder = () => {
    return {
      byActingOrder: this.room?.byActingOrder || {},
      actingOrder: this.room?.actingOrder || [],
    };
  };

  getAttendees = () => {
    return this.room?.attendees || [];
  };

  setRoom = (room, ignoreVersion = false) => {
    if (!ignoreVersion && this.room.__v >= room.__v) return;

    this.room = {
      ...this.room,
      ...room,
    };
    const { actingOrder } = room;
    if (actingOrder) {
      this.room.byActingOrder = {};
      actingOrder.forEach((item) => {
        this.room.byActingOrder[item.userId] = item;
      });
    }
    this.componentUpdaters.forEach((updater) => updater());
  };

  onRecordScreen = (room) => {
    this.setRoom(room);
  };

  recordScreen = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.RECORD_SCREEN, { documentId, userId });
  };

  stopRecordScreen = (documentId, userId) => {
    this.socket.emit(DVideoSignEvents.STOP_RECORD_SCREEN, { documentId, userId });
  };

  getStore = () => {
    return {
      registerEvent: this.registerEvent,
      registerExistingSocketEvents: this.registerExistingSocketEvents,
      registerLifeCycleEvents: this.registerLifeCycleEvents,
      joinRoom: this.joinRoom,
      startHeartbeat: this.startHeartbeat,
      stopHeartbeat: this.stopHeartbeat,
      nextAction: this.nextAction,
      meetingStart: this.meetingStart,
      leaveCall: this.leaveCall,
      completeAction: this.completeAction,
      connectHandler: this.connectHandler,
      socket: this.socket,
      getRoom: this.getRoom,
      getActingOrder: this.getActingOrder,
      getAttendees: this.getAttendees,
      registerComponent: this.registerComponent,
      endCall: this.endCall,
      isSigningSessionComplete: this.getIsSigningSessionComplete,
      recordScreen: this.recordScreen,
      stopRecordScreen: this.stopRecordScreen,
    };
  };
}

function useDedocoVideoSigningStore({ socket, store }) {
  const ref = useRef();

  const context = useContext(VideoSigningStoreContext);
  if (context && !socket && !store) return context;

  if (!ref.current) {
    if (store) {
      ref.current = store;
    } else {
      const store = new DedocoVideoSigningStore({ socket });
      ref.current = store.getStore();
    }
  }

  return ref.current;
}

export default useDedocoVideoSigningStore;
