import {
  ACCEPT_TNC,
  APPROVE_DOCUMENT,
  APPROVE_DOCUMENT_FAILED,
  APPROVE_DOCUMENT_SUCCESS,
  DECLINE_DOCUMENT,
  DECLINE_DOCUMENT_FAILED,
  DECLINE_DOCUMENT_SUCCESS,
  DOCUMENT_TO_SIGN_FAIL,
  FILEURL,
  GETTING_DOCUMENT_TO_SIGN,
  GOT_DOCUMENT_TO_SIGN,
  JOIN_MEETING,
  JOIN_MEETING_FAILED,
  JOIN_MEETING_SUCCESS,
  LEAVE_MEETING,
  MARK_DOCUMENT_AS_SIGNED,
  SAVEMYSIGNATURE,
  SAVE_PDF_STRING_BASE64,
  SAVE_SIGNATURE_SUCCESS,
  SEND_VERIFY_APPROVER_OTP,
  SEND_VERIFY_APPROVER_OTP_FAILED,
  SEND_VERIFY_APPROVER_OTP_SUCCESS,
  SEND_VERIFY_OBSERVER_OTP,
  SEND_VERIFY_OBSERVER_OTP_FAILED,
  SEND_VERIFY_OBSERVER_OTP_SUCCESS,
  SEND_VERIFY_SIGNING_OTP,
  SEND_VERIFY_SIGNING_OTP_FAILED,
  SEND_VERIFY_SIGNING_OTP_SUCCESS,
  SET_IS_OPEN_API_V2,
  SET_IS_VALID_DOC_HASH,
  SIGN_DOCUMENT,
  SIGN_DOCUMENT_FAILED,
  SIGN_DOCUMENT_SUCCESS,
  START_MEETING,
  START_MEETING_FAILED,
  START_MEETING_SUCCESS,
  UPDATE_SAVED_SIGNATURES,
  UPDATE_SIGNATURE_SECRET,
  VERIFY_APPROVER_OTP,
  VERIFY_APPROVER_OTP_FAILED,
  VERIFY_APPROVER_OTP_SUCCESS,
  VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_DHC,
  VERIFY_OBSERVER_OTP,
  VERIFY_OBSERVER_OTP_FAILED,
  VERIFY_OBSERVER_OTP_SUCCESS,
  VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_SIGNING_OTP,
  VERIFY_SIGNING_OTP_FAILED,
  VERIFY_SIGNING_OTP_SUCCESS,
} from '../actions/actiontypes';

const initialstate = {
  isloaded: false,
  isLoading: true,
  isVerified: false,
  isApprovingDocument: false,
  mysign: [],
  document: [
    {
      message: '',
      requirements: [],
    },
  ],
  message: '',
  fileName: '',
  fileUrl: '',
  data: {},
  isStartingMeeting: false,
  isJoiningMeeting: false,
  meetingToken: null,
  isJoinedMeeting: false,
  hasSigned: false,
  room: null,
  pdfBase64String: '',
  tncAccepted: false,
  isSubmittingDocument: false,
  submitDocumentCompleted: false,
  isDocumentRejected: false,
  isDocumentApproved: false,
  isDHCVerified: false,
  organization: null,
  isValidDocHash: false,
};

const documenttosign = (state = initialstate, action) => {
  switch (action.type) {
    case SET_IS_OPEN_API_V2:
      return {
        ...state,
        isOpenApiV2: action.payload,
      };
    case SET_IS_VALID_DOC_HASH:
      return {
        ...state,
        isValidDocHash: action.payload,
      };
    case SAVEMYSIGNATURE:
      return {
        ...state,
        mysign: action.payload,
      };
    case GETTING_DOCUMENT_TO_SIGN:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS:
    case VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS:
    case VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS:
    case GOT_DOCUMENT_TO_SIGN:
      return {
        ...state,
        data: action.payload,
        folder_name: action.payload.folder_name ? action.payload.folder_name : '',
        document: action.payload.business_process,
        message: '',
        document_hashes: action.payload.document_hashes,
        //Gary removed appended file type
        fileName: action.payload.document_name, // + '.' + action.payload.document_file_type,
        isloaded: true,
        isLoading: false,
        organization: action.payload.organisation,
      };
    case MARK_DOCUMENT_AS_SIGNED:
      return {
        ...state,
        hasSigned: action.payload,
      };
    case VERIFY_SIGNING_OTP:
    case VERIFY_OBSERVER_OTP:
    case VERIFY_APPROVER_OTP:
      return {
        ...state,
        isVerifyingOTP: true,
      };
    case VERIFY_SIGNING_OTP_SUCCESS:
    case VERIFY_OBSERVER_OTP_SUCCESS:
    case VERIFY_APPROVER_OTP_SUCCESS:
      return {
        ...state,
        isVerifyingOTP: false,
        data: action.payload,
        folder_name: action.payload.folder_name ? action.payload.folder_name : '',
        document: action.payload.business_process,
        message: '',
        document_hashes: action.payload.document_hashes,
        fileName: action.payload.document_name, // + '.' + action.payload.document_file_type,
        isloaded: true,
        isLoading: false,
      };
    case VERIFY_SIGNING_OTP_FAILED:
    case VERIFY_OBSERVER_OTP_FAILED:
    case VERIFY_APPROVER_OTP_FAILED:
      return {
        ...state,
        isVerifyingOTP: false,
      };
    case SEND_VERIFY_SIGNING_OTP:
    case SEND_VERIFY_OBSERVER_OTP:
    case SEND_VERIFY_APPROVER_OTP:
      return {
        ...state,
        isRequestingOTP: true,
      };
    case SEND_VERIFY_SIGNING_OTP_SUCCESS:
    case SEND_VERIFY_SIGNING_OTP_FAILED:
    case SEND_VERIFY_OBSERVER_OTP_SUCCESS:
    case SEND_VERIFY_OBSERVER_OTP_FAILED:
    case SEND_VERIFY_APPROVER_OTP_SUCCESS:
    case SEND_VERIFY_APPROVER_OTP_FAILED:
      return {
        ...state,
        isRequestingOTP: false,
      };
    case DOCUMENT_TO_SIGN_FAIL:
      return {
        ...state,
        message: action.message,
        errorCode: action.errorCode,
        isloaded: true,
        isLoading: false,
      };
    case FILEURL:
      return {
        ...state,
        fileUrl: action.payload,
      };
    case APPROVE_DOCUMENT:
    case DECLINE_DOCUMENT:
      return {
        ...state,
        isApprovingDocument: true,
        documentUpdatedApprovement: false,
      };
    case APPROVE_DOCUMENT_FAILED:
      return {
        ...state,
        isApprovingDocument: false,
      };
    case DECLINE_DOCUMENT_FAILED:
      return {
        ...state,
        isApprovingDocument: false,
      };
    case APPROVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentUpdatedApprovement: true,
        isApprovingDocument: false,
        isDocumentApproved: true,
      };
    case DECLINE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentUpdatedApprovement: true,
        isApprovingDocument: false,
        isDocumentRejected: true,
      };
    case START_MEETING:
      return {
        ...state,
        isStartingMeeting: true,
        meetingToken: null,
        isJoinedMeeting: false,
      };
    case START_MEETING_SUCCESS: {
      state.data.meeting.isActive = true;
      return {
        ...state,
        isStartingMeeting: false,
        meetingToken: action.payload.roomToken,
        isJoinedMeeting: true,
      };
    }
    case START_MEETING_FAILED:
      return {
        ...state,
        isStartingMeeting: false,
      };
    case JOIN_MEETING:
      return {
        ...state,
        isJoiningMeeting: true,
        meetingToken: null,
        isJoinedMeeting: false,
      };
    case JOIN_MEETING_SUCCESS:
      return {
        ...state,
        isJoiningMeeting: false,
        meetingToken: action.payload.roomToken,
        isJoinedMeeting: true,
        room: action.payload.room,
      };
    case JOIN_MEETING_FAILED:
      return {
        ...state,
        isJoiningMeeting: false,
      };
    case LEAVE_MEETING:
      return {
        ...state,
        isJoinedMeeting: false,
      };
    case SAVE_SIGNATURE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          savingSignatureSecret: action.payload.savingSignatureSecret,
        },
      };
    case SAVE_PDF_STRING_BASE64:
      return {
        ...state,
        pdfBase64String: action.payload,
      };
    case ACCEPT_TNC:
      return {
        ...state,
        tncAccepted: true,
      };
    case SIGN_DOCUMENT:
      return {
        ...state,
        isSubmittingDocument: true,
      };
    case SIGN_DOCUMENT_SUCCESS:
      return {
        ...state,
        isSubmittingDocument: false,
        submitDocumentCompleted: true,
        nextSigner: action.payload,
      };
    case SIGN_DOCUMENT_FAILED:
      return {
        ...state,
        isSubmittingDocument: false,
      };
    case VERIFY_DHC:
      return {
        ...state,
        isDHCVerified: action.payload,
      };
    case UPDATE_SAVED_SIGNATURES:
      return {
        ...state,
        data: {
          ...state.data,
          savedSignatures: action.payload,
        },
      };
    case UPDATE_SIGNATURE_SECRET:
      return {
        ...state,
        data: {
          ...state.data,
          savingSignatureSecret: action.payload,
        },
      };
    default:
      return state;
  }
};

export default documenttosign;
