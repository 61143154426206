import { Button, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import CustomTextPad from '../CustomTextPad';
import { classes, Root } from './SigningPad.style';

const PEN_COLOR_PALETTE = ['#1A1A34', '#135CCA', '#CA1313'];

const SigningPad = ({ handleValueChange, type, textColor, setTextColor }) => {
  const { t } = useTranslation();

  const signPad = useRef();
  const textPad = useRef();

  const handleChangePenColor = (color) => {
    setTextColor(color);
  };
  const handleClear = useCallback(() => {
    signPad.current?.clear();
    if (textPad.current) {
      textPad.current.value = '';
    }
    handleValueChange?.('');
  }, [handleValueChange]);

  const handleSignatureDrawn = useCallback(() => {
    handleValueChange?.(signPad.current.getCanvas().toDataURL('image/png'));
  }, [handleValueChange]);

  return (
    <Root container>
      <Grid item xs={12}>
        {type === 'draw' && (
          <SignatureCanvas
            penColor={textColor}
            ref={signPad}
            onEnd={handleSignatureDrawn}
            canvasProps={{
              'data-testid': 'form-sign-input-signature-canvas',
              className: classes.signatureCanvas,
              role: 'signing-pad',
            }}
          />
        )}
        {type === 'type' && (
          <CustomTextPad
            handleValueChange={handleValueChange}
            penColor={textColor}
            style={{
              ['textarea']: {
                color: `${textColor} !important`,
                fontSize: '2em !important',
                lineHeight: '2em !important',
              },
            }}
            maxRows={1}
            inputRef={textPad}
          />
        )}
      </Grid>
      <Grid container item>
        <Divider />
      </Grid>
      <Grid container item className={classes.colorPickerClearContainer}>
        <Grid item xs={6}>
          <Grid container className={classes.colorPickerBox}>
            {PEN_COLOR_PALETTE.map((color) => {
              return (
                <div
                  key={color}
                  className={`${textColor === color ? classes.colorPickerActive : ''} ${
                    classes.colorPickerOuter
                  }`}
                  role="color-picker"
                >
                  <div
                    onClick={() => handleChangePenColor(color)}
                    style={{ backgroundColor: color }}
                    className={classes.colorPicker}
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleClear} className={classes.clearButton}>
            <Typography variant="label_medium">
              {t('SignatureInputSigningPadClearButton')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};

export default SigningPad;
