import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { usePdfTronContext } from '../../../../../../contexts/usePdfTronContext';
import useGetRequiredDedocoSigning from '../../../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import useRecipientRole from '../../../../../../hooks/useRecipientRole';
import { classes, Root } from './FieldItem.styles';
import { CypressIds } from '../../../../../../constant/cypressIds';

const FieldItem = ({ useNewDedocoSigning = false, item, type, index }) => {
  const { t } = useTranslation();

  const { setCurrentField, signerInputs } = useGetRequiredDedocoSigning(useNewDedocoSigning);
  const { jumpTo } = usePdfTronContext();
  const { isSigner } = useRecipientRole();

  const isFilled = (() => {
    const fieldItem = signerInputs.find((field) => field.name === item.fieldId);
    if (type === 'confirmation') {
      return Boolean(fieldItem?.value === 'On');
    }
    return Boolean(fieldItem);
  })();

  const handleFieldItemClick = () => {
    if (isSigner && !isFilled) {
      const typesThatDoNotUseSidebar = ['confirmation', 'digi_signature'];
      const usingSidebar = !typesThatDoNotUseSidebar.includes(type);
      setCurrentField(item.fieldId, true, usingSidebar);
    }
    jumpTo(item.fieldId);
  };

  return (
    <Root
      data-testid={`sidebar-field-item-(${type})-${item.fieldId}`}
      className={classes.fieldItem}
      data-cypress={CypressIds.fieldItemBox(type, index)}
      onClick={handleFieldItemClick}
    >
      <Checkbox
        checked={isFilled}
        sx={{
          '&.Mui-checked': {
            color: 'success.primary',
          },
          ':hover': {
            backgroundColor: 'transparent',
          },
          '.MuiTouchRipple-root': {
            display: 'none !important',
          },
        }}
      />
      <Typography className={classes.detailsText} variant="label_small">
        {t('SigningRequestDrawerPage')} {item.pageNumber} {item.label}
      </Typography>
    </Root>
  );
};

export default FieldItem;
