import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '../../../../utils/domUtil';
import axios from 'axios';

import { base64ToBytes } from '../../../../utils/base64';
import { process_env } from '../../../../utils/envData';

// Read more about the Javascript SDK API at https://docs.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/save-file?view=odsp-graph-online
const OneDriveSaver = (props) => {
  const ONEDRIVE_SDK_URL = '/scripts/oneDrive.js';
  const scriptLoadingStarted = useRef(false);
  const CLIENT_ID = process_env('REACT_APP_ONE_DRIVE_CLIENT_ID');

  const {
    children,
    onClick,
    action,
    sourceInputElementId,
    openInNewWindow,
    advanced,
    progress,
    cancel,
    disabled,
  } = props;

  useEffect(() => {
    if (!isOneDriveReady() && !scriptLoadingStarted.current) {
      scriptLoadingStarted.current = true;
      loadScript(ONEDRIVE_SDK_URL);
    }
  }, []);

  const isOneDriveReady = () => {
    return !!window.OneDrive;
  };

  const onSave = async () => {
    if (!isOneDriveReady() || disabled) {
      return null;
    }

    if (action === 'query') {
      try {
        const uploadInfo = await getUploadInfo();
        if (uploadInfo) {
          const uploadToOneDriveByApiPartial = (fileInfo, successCallback, errorCallback) => {
            uploadToOneDriveByApi(
              uploadInfo.apiEndpoint,
              uploadInfo.accessToken,
              uploadInfo.destinationInfo,
              fileInfo,
              successCallback,
              errorCallback,
            );
          };
          onClick(uploadToOneDriveByApiPartial);
        }
      } catch (e) {
        console.log(e);
      }
    } else if (action === 'save') {
      onClick(uploadToOneDriveByPicker);
    }
  };

  const getUploadInfo = async () => {
    return new Promise((resolve, reject) => {
      window.OneDrive.save({
        clientId: CLIENT_ID,
        action: 'query',
        advanced,
        success: function (data) {
          resolve({
            accessToken: data.accessToken,
            apiEndpoint: data.apiEndpoint,
            destinationInfo: data.value[0],
          });
        },
        cancel: function () {
          resolve(null);
        },
        error: function (err) {
          reject(err);
        },
      });
    });
  };

  const uploadToOneDriveByApi = (
    apiEndpoint,
    accessToken,
    destinationInfo,
    fileInfo,
    successCallback,
    errorCallback,
  ) => {
    const url = `${apiEndpoint}me/drive/items/${destinationInfo.id}:/${fileInfo.filename}:/content`;

    const fileData = fileInfo.data;
    const mimeType = fileData.substring(fileData.indexOf(':') + 1, fileData.indexOf(';'));
    const fileBase64 = fileData.substring(fileData.indexOf(',') + 1);
    const fileBlob = new Blob([base64ToBytes(fileBase64)], { type: mimeType });

    const options = {
      url: url,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        '@microsoft.graph.conflictBehavior': 'rename',
      },
      data: fileBlob,
    };

    axios(options).then((response) => {
      if (response.status === 201) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  };

  const uploadToOneDriveByPicker = (
    fileInfo,
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    window.OneDrive.save({
      clientId: CLIENT_ID,
      sourceUri: fileInfo.data,
      fileName: fileInfo.filename,
      error: errorCallback,
      success: successCallback,
      action,
      sourceInputElementId,
      openInNewWindow,
      advanced,
      progress,
      cancel,
    });
  };

  return <div onClick={onSave}>{children}</div>;
};

OneDriveSaver.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  action: PropTypes.oneOf(['save', 'query']).isRequired,
  sourceInputElementId: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  advanced: PropTypes.object,
  progress: PropTypes.func,
  cancel: PropTypes.func,
  disabled: PropTypes.bool,
};

export default OneDriveSaver;
