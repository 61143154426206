import { styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = styled(MuiAccordion)(() => ({
  border: 'none',
  background: 'transparent',
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: '8px',
          strokeWidth: 2,
          stroke: (theme) => theme.palette.grey[500],
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  border: 'none',
  background: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  border: 'none',
  background: 'transparent',
  textAlign: 'left',
}));

export const StyledNumberList = styled('ol')(({ theme }) => ({
  paddingLeft: 0,
  listStylePosition: 'inside',
  ['& > li']: {
    padding: theme.spacing(1, 0),
  },
}));
