import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useGetRequiredDedocoSigning from '../../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { classes, Root } from './SavedSignature.styles';

const SavedSignature = ({ handleValueChange, isNotMainTab, newDedocoSigning = false, ...rest }) => {
  const { documentToSign } = useGetRequiredDedocoSigning(newDedocoSigning);
  const { t } = useTranslation();

  const savedSignatures = documentToSign?.data?.savedSignatures;

  const handleSignatureClick = useCallback((signature) => {
    handleValueChange?.({ value: signature.toString(), type: 'image' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root container spacing={2} {...rest}>
      {!isNotMainTab && savedSignatures?.length === 4 && (
        <Typography variant="label_medium" sx={{ fontWeight: 400, margin: 'auto' }}>
          {t('MaximumSignatures')}
        </Typography>
      )}

      {savedSignatures?.map((signature, index) => (
        <Grid key={`saved-signature-${index}`} item xs={6}>
          <div
            tabIndex="0"
            className={`${classes.savedSignatureItem}`}
            onClick={() => handleSignatureClick(signature)}
          >
            <img className={classes.signatureImage} src={signature} />
          </div>
        </Grid>
      ))}
    </Root>
  );
};

export default SavedSignature;
