import _loadScript from 'load-script';
import { sleep } from './time';

// Credit: https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const scrollKeys = {
  37: 1,
  38: 1,
  39: 1,
  40: 1,
  32: 1,
  33: 1,
  34: 1,
  35: 1,
  36: 1,
};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (scrollKeys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

export function getWheelEvent(windowObject) {
  return 'onwheel' in windowObject.document.createElement('div') ? 'wheel' : 'mousewheel';
}

export function disableScroll(windowObject) {
  const wheelOpt = { passive: false };
  windowObject.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  windowObject.addEventListener(getWheelEvent(windowObject), preventDefault, wheelOpt); // modern desktop
  windowObject.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  windowObject.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll(windowObject) {
  const wheelOpt = { passive: false };
  windowObject.removeEventListener('DOMMouseScroll', preventDefault, false);
  windowObject.removeEventListener(getWheelEvent(windowObject), preventDefault, wheelOpt);
  windowObject.removeEventListener('touchmove', preventDefault, wheelOpt);
  windowObject.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

export function loadScript(src, options, callback) {
  if (typeof options === 'function') {
    callback = options;
    options = {};
  }

  // Allows custom window object to be used. E.g. The window object of an iframe
  const windowObj = options?.windowObject || window;

  if (
    !options?.allowMultipleLoads &&
    windowObj.document.querySelectorAll(`script[src="${src}"]`)?.length > 0
  ) {
    return;
  }

  _loadScript(src, options, callback);
}

export async function getElementWithRetries(
  selectorFunc,
  options = { retryInterval: 200, retryCount: 20 },
) {
  let element = selectorFunc();

  for (let i = 0; i < options.retryCount; i++) {
    if (element) break;
    await sleep(options.retryInterval);
    element = selectorFunc();
  }

  return element;
}

export async function waitForElementToConnectToDOM(
  element,
  options = { retryInterval: 200, retryCount: 20 },
) {
  let connected = element.isConnected;

  for (let i = 0; i < options.retryCount; i++) {
    if (connected) break;
    await sleep(options.retryInterval);
    connected = element.isConnected;
  }

  return connected;
}
