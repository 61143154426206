import React, { useEffect, useMemo } from 'react';
import SignerAttachments from '../SignerAttachments';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import useRecipientRole from '../../../../hooks/useRecipientRole';
import { classes } from '../Sidebar.style';
import DeMailTouchPointBox from '../InfoSidebar/DeMailTouchPoint';
import VerifyBox from '../VerifyBox';
import FieldList from '../FieldList';
import { CheckCircle, Info } from '@mui/icons-material';
import ActionButtons from '../ActionButtons';
import { Trans, useTranslation } from 'react-i18next';
import { Tabs, Tab, TabPanel } from '../../UI/Tab';

const SignerTabs = ({
  variant = 'standard',
  noOfRemainingFields,
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isObserver, currentUser } = useRecipientRole();
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [tooltipTriggered, setTooltipTriggered] = React.useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const { attachments } = useSelector((state) => state.attachment);
  const handleTabChange = (_event, newValue) => {
    setTabIndex(newValue);
  };

  const getRemainingItemsMessage = (fieldsCount, attachmentsCount, optionalAttachmentCount) => {
    const messageParts = [];

    if (fieldsCount > 0) {
      messageParts.push(`${fieldsCount} field${fieldsCount > 1 ? 's' : ''}`);
    }

    if (attachmentsCount > 0) {
      messageParts.push(`${attachmentsCount} attachment${attachmentsCount > 1 ? 's' : ''}`);
    }

    if (optionalAttachmentCount > 0) {
      messageParts.push(
        `${optionalAttachmentCount} optional attachment${optionalAttachmentCount > 1 ? 's' : ''}`,
      );
    }

    if (messageParts.length > 0) {
      const lastPart = messageParts.pop();
      return (
        (messageParts.length > 0 ? `${messageParts.join(', ')} and ` : '') + `${lastPart} remaining`
      );
    }

    return '';
  };

  const { remainingAttachments, remainingOptionalAttachments } = useMemo(() => {
    return attachments.reduce(
      (counts, a) => {
        if (!a.fileName) {
          if (a.required) {
            counts.remainingAttachments++;
          } else {
            counts.remainingOptionalAttachments++;
          }
        }
        return counts;
      },
      { remainingAttachments: 0, remainingOptionalAttachments: 0 },
    );
  }, [attachments]);

  const remainingItemMessage = useMemo(() => {
    const msg = getRemainingItemsMessage(
      noOfRemainingFields,
      remainingAttachments,
      remainingOptionalAttachments,
    );
    return (
      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ textAlign: 'start' }}>
        {msg && (
          <>
            <Info sx={{ color: '#3D7BF7' }} size={20} />
            <Typography variant="body2">{msg}</Typography>
          </>
        )}
        {!msg && (
          <>
            <CheckCircle sx={{ color: '#0EA470' }} size={20} />
            <Typography variant="body2">
              <Trans i18nKey="reviewBeforeSaving" />
            </Typography>
          </>
        )}
      </Stack>
    );
  }, [noOfRemainingFields, remainingAttachments, remainingOptionalAttachments]);

  const actionContent = useMemo(() => {
    return (
      <Box sx={{ paddingTop: 3 }}>
        {remainingItemMessage}
        <ActionButtons
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          isDisabled={disableSubmitButton || remainingAttachments > 0}
          handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
        />
      </Box>
    );
  }, [
    currentUser,
    remainingItemMessage,
    remainingAttachments,
    handleRejectDoc,
    disableSubmitButton,
    handleSignDoc,
    handleApproveDoc,
    handleDownloadPdf,
  ]);

  useEffect(() => {
    if (noOfRemainingFields === 0 && remainingAttachments > 0 && tabIndex === 0) {
      if (!tooltipTriggered) {
        // Delay opening the tooltip to avoid position discrepancy caused by slide-up transition
        setTimeout(() => {
          setTooltipTriggered(true);
          handleTooltipOpen();
        }, 200);
      }
    } else if (openTooltip) {
      handleTooltipClose();
    }
  }, [remainingAttachments, tabIndex, tooltipTriggered, openTooltip, noOfRemainingFields, variant]);

  const tabPanelStyles = {
    height: '95%',
    overflow: 'auto',
    webkitOverflowScrolling: 'touch',
  };
  return (
    <>
      <Tabs value={tabIndex} onChange={handleTabChange} variant={variant}>
        <Tab label={t('Fields')} />
        {!!attachments?.length && (
          <Tab
            label={
              <Tooltip
                title={t('attachmentsTooltip')}
                open={openTooltip}
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                disableHoverListener
                arrow
              >
                <span>{t('attachments')}</span>
              </Tooltip>
            }
          />
        )}
      </Tabs>
      <TabPanel value={tabIndex} index={0} style={tabPanelStyles}>
        <Stack sx={{ overflow: 'auto', height: '100%' }} justifyContent={'space-between'}>
          <Grid
            container
            item
            flexDirection="column"
            className={classes.verifyRequiredFieldsContainer}
          >
            {isObserver && (
              <>
                <Grid item>
                  <DeMailTouchPointBox backgroundColor="none" compactMode />
                </Grid>
                <Divider light className={classes.divider} />
              </>
            )}
            <Grid item className={classes.verifyWrapper}>
              <VerifyBox />
            </Grid>
            <Grid item>
              <FieldList />
            </Grid>
          </Grid>
          {actionContent}
        </Stack>
      </TabPanel>
      {!!attachments?.length && (
        <TabPanel value={tabIndex} index={1} style={tabPanelStyles}>
          <Stack sx={{ overflow: 'auto', height: '100%' }} justifyContent={'space-between'}>
            <SignerAttachments variant={variant} />
            {actionContent}
          </Stack>
        </TabPanel>
      )}
    </>
  );
};

export default SignerTabs;
