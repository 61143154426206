import { Button, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useTranslation } from 'react-i18next';
import { formatBytes } from '../../../../utils/formatBytes';
import { FilePlusIcon } from '../../Icons/FilePlusIcon';
import { classes, Root } from './FileUploadWithPreview.style';

export const FileUploadWithPreview = (props) => {
  const { onFilesUploaded, accept, innerText, onRemoveFile, maxFileSize } = props;
  const { t } = useTranslation();

  const fileInputRef = useRef();
  const [file, setFile] = useState(null);

  const getFileDataUrl = (file) => {
    if (!file) return;

    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (file) => {
    file.dataUrl = await getFileDataUrl(file);
    setFile(file);
    onFilesUploaded?.(file);
  };

  const handleTargetClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
    setFile(null);
    onRemoveFile?.();
  };

  const inner = file ? (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4} height="100%">
      <img className={classes.imagePreview} src={file.dataUrl} />
      <Button className={classes.chooseAnotherButton} variant="tinted" onClick={handleRemoveFile}>
        {t('UploadFileChooseAnotherImage')}
      </Button>
    </Stack>
  ) : (
    <Stack direction="column" spacing={4} alignItems="center" justifyContent="center" height="100%">
      <Typography variant="label_large" color="primary.main">
        {innerText}
      </Typography>
      <Typography className={classes.fileDropOr} fontSize="8px" color="darkGrey.light">
        {t('UploadFileOr')}
      </Typography>
      <Button
        startIcon={<FilePlusIcon strokeWidth="0.1px" />}
        className={classes.fileDropButton}
        onClick={handleTargetClick}
      >
        <Typography variant="label_large" color="text.primary">
          {t('UploadFileChooseFiles')}
        </Typography>
      </Button>
      <Typography variant="label_medium" color="grey.700">
        {t('UploadFileMaximumSize', { fileSize: formatBytes(maxFileSize, 0) })}
      </Typography>
    </Stack>
  );

  return (
    <Root>
      <FileDrop
        className={classes.fileDrop}
        targetClassName={classes.innerFileDrop}
        onDrop={(files, _event) => handleFileUpload(files[0])}
      >
        <input
          onChange={(e) => handleFileUpload(e.target.files[0])}
          ref={fileInputRef}
          type="file"
          className={classes.fileInput}
          accept={accept}
        />
        {inner}
      </FileDrop>
    </Root>
  );
};
