import React, { useEffect } from 'react';
import { Modal, IconButton } from '@mui/material';
import './DownloadPdf.css';
import { Close as CancelIcon } from '@mui/icons-material';
import signerContract from '../../../assets/signer_contract.png';
import { useTranslation } from 'react-i18next';
import DownloadOptionsButton from '../../Buttons/DownloadOptionsButton/DownloadOptionsButton';

const DownloadPdf = (props) => {
  const [open, setOpen] = React.useState(props.open);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      className="modal-download-pdf"
      open={open}
      onClose={() => {
        setOpen(false);
        props.setOpenModal(false);
      }}
      disableEnforceFocus
    >
      <div className="content-modal">
        <IconButton
          className="close-modal-download-pdf-button"
          onClick={() => {
            setOpen(false);
            props.setOpenModal(false);
          }}
          style={{ color: '#3B3B3B' }}
          size="large"
        >
          <CancelIcon fontSize="large" />
        </IconButton>
        <h1 className="download-pdf-title">{t('ModalDownloadPdfTitle')}</h1>
        <div className="download-pdf-description">{t('ModalDownloadPdfDescription')}</div>
        <div className="download-pdf-image">
          <img src={signerContract} alt="signerContract" />
        </div>
        <DownloadOptionsButton
          groupClassName="download-pdf-button-group"
          buttonClassName="download-options-button"
          toggleClassName="download-options-toggle"
          handleDownloadPdf={props.handleDownloadPdf}
        >
          {t('ModalDownloadPdfButton')}
        </DownloadOptionsButton>
      </div>
    </Modal>
  );
};

export default DownloadPdf;
