import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Root = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    backgroundColor: theme.palette.background.main,
    boxShadow: 'none',
    borderRadius: '8px',
    height: 'auto',
    width: '400px',
    gap: theme.spacing(2),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'visible',
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
      height: 'auto',
      padding: theme.spacing(6, 4, 4, 4),
    },
  },
}));

export const Header = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.headline_small,
  color: theme.palette.text.primary,
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.title_large,
  },
}));

export const Content = styled(DialogContent)(({ theme }) => ({
  ...theme.typography.body_medium,
  color: theme.palette.text.secondary,
  padding: 0,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body_medium,
  },
}));

export const Actions = styled(DialogActions)(() => ({
  width: '100%',
  padding: 0,
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '8px',
}));

export const SignButton = styled('img')(() => ({
  cursor: 'pointer',
  height: '48px',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  zIndex: 9999,
  borderRadius: '100px',
  backgroundColor: theme.palette.supporting.main,
  position: 'absolute',
  top: 0,
  right: '-46px',
  [`&:hover`]: {
    backgroundColor: theme.palette.supporting.dark,
  },
  [theme.breakpoints.down('sm')]: {
    right: '-42px',
  },
}));

export const CloseButtonIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));
