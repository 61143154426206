import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  recordApproverTermsAndConditionsConsent,
  recordObserverTermsAndConditionsConsent,
  recordSignerTermsAndConditionsConsent,
} from '../../lib/api';
import { useDedocoSigning } from '../useDedocoSigning/useDedocoSigning.new';
import useRecipientRole from '../useRecipientRole';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  acceptTnc,
  getDocumentToSignSelector,
} from '../../redux/slices/dVideoSignSlice/dVideoSignSlice';

function useTnC() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentParticipant } = useDedocoSigning();
  const { data = {} } = useSelector(getDocumentToSignSelector);
  const { business_process: businessProcess } = data;
  const { RecipientRoles } = useRecipientRole();
  const { uid } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSubmitTnC = useCallback(
    async (id) => {
      let success = false;
      if (!id) {
        id = businessProcess?.id;
      }
      if (currentParticipant?.role === RecipientRoles.SIGNER) {
        success = await recordSignerTermsAndConditionsConsent(id, uid);
      } else if (currentParticipant?.role === RecipientRoles.OBSERVER) {
        success = await recordObserverTermsAndConditionsConsent(id, uid);
      } else if (currentParticipant?.role === RecipientRoles.APPROVER) {
        success = await recordApproverTermsAndConditionsConsent(id, uid);
      }
      if (success) {
        dispatch(acceptTnc());
      } else {
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 3000);
      }
    },
    [
      RecipientRoles.APPROVER,
      RecipientRoles.OBSERVER,
      RecipientRoles.SIGNER,
      businessProcess,
      currentParticipant?.role,
      dispatch,
      uid,
    ],
  );

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
  }, []);

  const TncSnackbar = useCallback(() => {
    return (
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {t('RecordTermsAndConditionsConsentFailure')}
        </Alert>
      </Snackbar>
    );
  }, [handleCloseSnackbar, openSnackbar, t]);

  return { TncSnackbar, handleSubmitTnC };
}

export { useTnC };
