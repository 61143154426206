import { dedocoAxios } from '../../lib/api/axios';
import { SAVE_CURRENT_SIGNATURE, SAVE_SIGNATURE, SAVE_SIGNATURE_FAILED } from './actiontypes';
import { updateSavedSignature, updateSignatureSecret } from './documentfunctions';

export function saveSignature(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SAVE_SIGNATURE,
      });
      dedocoAxios
        .post('/users/signatures', data)
        .then((res) => {
          dispatch(updateSignatureSecret(res?.data?.savingSignatureSecret));
          dispatch(setSaveCurrentSignature(false));
          resolve(res);
        })
        .catch((errData) => {
          dispatch({
            type: SAVE_SIGNATURE_FAILED,
          });
          reject(errData.response);
        });
    });
  };
}

export function deleteSignature(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post('/users/remove-signature', data)
        .then((res) => {
          dispatch(updateSavedSignature(res?.data?.signatures));
          dispatch(updateSignatureSecret(res?.data?.savingSignatureSecret));
          resolve(res);
        })
        .catch((errData) => {
          reject(errData.response);
        });
    });
  };
}

export function setSaveCurrentSignature(status) {
  return (dispatch) => {
    dispatch({
      type: SAVE_CURRENT_SIGNATURE,
      payload: status,
    });
  };
}
