import { Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Participant from '../Participant';
import { classes, RoomRoot } from './Room.style';
import ScreenTrack from './ScreenTrack';
import { useParams } from 'react-router-dom';

const getParticipantUserIdRegx = /\$\(([\s\S]+)\) /;

const getParticipantUserId = (participant) => {
  return participant.identity?.match(getParticipantUserIdRegx)[1];
};

const Room = ({ room, isAudioMuted, isVideoMuted, screenTrack, attendees = {} }) => {
  const { t } = useTranslation();
  const { uid } = useParams();

  const [participants, setParticipants] = useState({});

  useEffect(() => {
    if (!room) return;
    const participantConnected = (participant) => {
      const userId = getParticipantUserId(participant);
      if (userId) {
        setParticipants((prevParticipants) => ({ ...prevParticipants, [userId]: participant }));
      }
    };

    const participantDisconnected = (participant) => {
      const removeUserId = getParticipantUserId(participant);
      setParticipants((prevParticipants) => {
        const newParticipants = {};
        Object.keys(prevParticipants).forEach((userId) => {
          if (userId !== removeUserId) {
            newParticipants[userId] = prevParticipants[userId];
          }
        });
        return newParticipants;
      });
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  // Handles mute and unmute
  useEffect(() => {
    if (!room || !room.localParticipant) return;

    if (isAudioMuted) {
      room.localParticipant.audioTracks?.forEach((publication) => publication.track.disable());
    } else {
      room.localParticipant.audioTracks?.forEach((publication) => publication.track.enable());
    }
  }, [isAudioMuted, room]);

  // Handles video on and off
  useEffect(() => {
    if (!room || !room.localParticipant) return;

    if (isVideoMuted) {
      room.localParticipant.videoTracks?.forEach((publication) => publication.track.disable());
    } else {
      room.localParticipant.videoTracks?.forEach((publication) => publication.track.enable());
    }
  }, [isVideoMuted, room]);

  const remoteParticipants = Object.keys(attendees).map((userId) => {
    const participant = participants[userId];
    if (participant) {
      return (
        <Participant
          key={participant.identity}
          participant={participant}
          twilioConnected
          name={participant.identity?.split(' $(')[0]}
        />
      );
    }
    return attendees[userId] && <Participant key={userId} name={attendees[userId].name} />;
  });

  if (!room) return null;

  return (
    <RoomRoot>
      <Participant
        key={room.localParticipant.sid}
        participant={room.localParticipant}
        isMainParticipant
        isVideoMuted={isVideoMuted}
        twilioConnected
        name={room.localParticipant.identity?.split(' $(')[0]}
      />
      <Stack direction={'column'} spacing={2}>
        {Object.keys(screenTrack).map((participantId) => {
          // screen sharer should display when participants detected and this participants is in the meeting
          // as when the participants is sharing screen and leave meeting by directly close window there is a delay
          // the participant subscription, while the socket room attendees is updated immediately,
          if (!attendees[participantId] && participantId !== uid) return null;
          const screenSharer = participants[participantId] ?? room?.localParticipant;
          return (
            <ScreenTrack
              key={participantId}
              participantId={participantId}
              room={room}
              screen={screenTrack[participantId]}
              name={screenSharer.identity?.split(' $(')[0]}
            />
          );
        })}
      </Stack>
      <Divider />
      <Typography
        gutterBottom
        align="left"
        sx={(theme) => ({
          color: '#1A1A3466',
          margin: theme.spacing(4, 0),
          ...theme.typography.title_small,
        })}
      >
        {Object.keys(attendees).length} {t('Participants')}
      </Typography>
      <div className={classes.remoteParticipants}>{remoteParticipants}</div>
    </RoomRoot>
  );
};

export default Room;
