import download from 'downloadjs';

const saveToLocalStorage = (fileInfo, successCallback, errorCallback) => {
  try {
    download(fileInfo.data, fileInfo.filename);
    successCallback?.();
  } catch (e) {
    errorCallback?.();
  }
};

export default saveToLocalStorage;
