import { Stack, Typography } from '@mui/material';
import { InfoContainer, classes } from './Sidebar.style';

export default function WaitingInfo({ title, content }) {
  return (
    <InfoContainer>
      <Stack>
        <Typography className={classes.title} variant="label_medium" align="left" gutterBottom>
          {title}
        </Typography>
        <span className={classes.content}>{content}</span>
      </Stack>
    </InfoContainer>
  );
}
