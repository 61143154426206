import { RecipientRoles } from '../../../hooks/useRecipientRole';
import { dedocoAxios } from '../../../lib/api/axios';

export const verifyOTPApi = ({ id, uid, otp, isPublic, role }) => {
  let path = '/businessprocess/signature/getRequest';
  if (role !== RecipientRoles.APPROVER && isPublic) {
    path = '/public/business-processes/signature/getRequest';
  }
  const params = {
    otp: otp,
  };
  handleOtpParams(params, { id, uid, isPublic, role });
  return dedocoAxios.get(path, {
    params,
  });
};

export const sendVerifyOTPApi = ({ id, uid, isPublic, role }) => {
  let path = '/businessprocess/signature/getRequest/send-otp';
  if (role !== RecipientRoles.APPROVER && isPublic) {
    path = '/public/business-processes/signature/getRequest/send-otp';
  }
  const params = {};
  handleOtpParams(params, { id, uid, isPublic, role });
  return dedocoAxios.get(path, {
    params,
  });
};

function handleOtpParams(params, { id, uid, isPublic, role }) {
  switch (role) {
    case RecipientRoles.SIGNER:
      params.signer_id = uid;
      if (isPublic) {
        params.business_process_id = id;
      } else {
        params.document_id = id;
      }
      break;
    case RecipientRoles.OBSERVER:
      params.observer_id = uid;
      if (isPublic) {
        params.business_process_id = id;
      } else {
        params.document_id = id;
      }
      break;
    case RecipientRoles.APPROVER:
      params.approver_id = uid;
      params.document_id = id;
      break;
    default:
      break;
  }
}
