import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '../../assets/download.svg';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useSelector } from 'react-redux';
import { getLogoFromCompanyName } from '../../utils/logo';

const PREFIX = 'HeaderMobile';

const classes = {
  appBar: `${PREFIX}-appBar`,
  messageHeader: `${PREFIX}-messageHeader`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginRight: drawerWidth,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.messageHeader}`]: {
    backgroundColor: '#fff2cc',
    color: '#3b3b3b',
    fontSize: '11px',
    padding: '2px',
  },
}));

const drawerWidth = '25%';

const HeaderMobile = (props) => {
  const {
    isObserver,
    isApprovingDocument,
    setOpenDownloadPdfModal,
    handleDrawerToggle,
    showOtherOptions = true,
    observer,
    whiteLabelDetails,
  } = props;

  const [showDownloadButton, setShowDownloadButton] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (navigator.userAgent.match('CriOS') || isObserver) {
      setShowDownloadButton(true);
    }
    if (isObserver) {
      setShowDownloadButton(!!observer?.is_downloadable);
      if (!observer?.is_downloadable) {
        setTimeout(() => {
          props.setSnackBarOpen(true);
          props.setSeverity('warning');
          props.setSnackBarMessage(t('HeaderUndownloadableWarning'));
        }, 4000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const company = useSelector((state) => state.documenttosign?.data?.company);

  const logo =
    whiteLabelDetails?.logo ||
    company?.logo ||
    getLogoFromCompanyName(process.env.REACT_APP_COMPANY_NAME);

  return (
    <StyledAppBar position="fixed" className={classes.appBar}>
      <Toolbar style={{ backgroundColor: 'aliceblue' }}>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item xs={4} display="flex" alignItems="center">
            <img src={logo} style={{ height: '48px' }} alt="logo" />
          </Grid>
          {showOtherOptions && (
            <Grid
              container
              item
              wrap="nowrap"
              style={{ justifyContent: 'flex-end' }}
              alignItems="center"
              xs={8}
              spacing={1}
            >
              <Grid item>
                {showDownloadButton && (
                  <div style={{ display: 'flex' }}>
                    <div
                      onClick={setOpenDownloadPdfModal}
                      style={{
                        backgroundColor: '#3565E3',
                        color: 'white',
                        display: 'flex',
                      }}
                      className={`${
                        isApprovingDocument ? 'disabled-btn' : 'yellow-btn'
                      } header-btn`}
                    >
                      <img src={DownloadIcon} alt="download" style={{ marginRight: 4 }} />
                      <span>{t('HeaderDownloadBtn')}</span>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item>
                <LanguageSelector showLanguageName={false} />
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  style={{ padding: 0 }}
                  size="large"
                >
                  <MenuIcon style={{ float: 'right' }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Typography variant="h6" noWrap></Typography>
      </Toolbar>
    </StyledAppBar>
  );
};

export default HeaderMobile;
