export const compare = (a, b) => {
  if (parseInt(a.index) < parseInt(b.index)) return -1;
  if (parseInt(a.index) > parseInt(b.index)) return 1;
  return 0;
};

export const isRequestError = (code) => {
  return code === 400 || code === 404;
};

export const isKeyValueObject = (value) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
