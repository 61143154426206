import { createContext, useContext } from 'react';
import useWebexSigning from '../hooks/useWebexSigning/useWebexSigning';
import { webexSocket } from '../utils/socket';

const WebexContext = createContext({});

export const WebexProvider = ({ children }) => {
  const webex = useWebexSigning(webexSocket);

  return <WebexContext.Provider value={{ ...webex }}>{children}</WebexContext.Provider>;
};

export function useWebexContext() {
  return useContext(WebexContext);
}
