import { useEffect, useCallback, useState } from 'react';
import { isKeyValueObject } from '../../../../utils/common';
import { waitForElementToConnectToDOM } from '../../../../utils/domUtil';
import { ClickAwayBackdrop, Root } from './FloatingPopup.style';

export function FloatingPopup({ open, anchorDomElement, options, children, onClickAway }) {
  const [selfDomRef, setSelfDomSef] = useState(null);
  const [selfStyle, setSelfStyle] = useState({ visibility: 'hidden' });

  const updatePosition = useCallback(async () => {
    if (!selfDomRef || !anchorDomElement) {
      return;
    }

    // Anchor DOM might not be connected to DOM yet.
    if (!(await waitForElementToConnectToDOM(anchorDomElement))) {
      return;
    }

    let boundaries = {};

    if (options?.boundary === 'viewport') {
      boundaries.top = 0;
      boundaries.right = window.innerWidth;
      boundaries.bottom = window.innerHeight;
      boundaries.left = 0;
    } else if (isKeyValueObject(options?.boundary)) {
      boundaries = options.boundary;
    }

    const offset = {
      x: 0,
      y: 0,
      ...options?.offset,
    };

    const anchorRect = anchorDomElement.getBoundingClientRect();
    const selfDomRect = selfDomRef.getBoundingClientRect();
    const anchorPosition = options?.anchorPosition;

    // anchorPosition defaults to bottom left
    let newSelfX = anchorRect.x + offset.x;
    let newSelfY = anchorRect.y + anchorRect.height + offset.y;
    const wrapOffset = {
      x: options?.wrapX ? selfDomRect.width - anchorRect.width : null,
      y: options?.wrapY ? selfDomRect.height + anchorRect.height : null,
    };

    if (anchorPosition === 'bottomRight') {
      newSelfX = anchorRect.x + selfDomRect.width - anchorRect.width + offset.x;
      newSelfY = anchorRect.y + anchorRect.height + offset.y;
    } else if (anchorPosition === 'topLeft') {
      newSelfX = anchorRect.x + offset.x;
      newSelfY = anchorRect.y + selfDomRect.height + offset.y;
    } else if (anchorPosition === 'topRight') {
      newSelfX = anchorRect.x + anchorRect.width + offset.x;
      newSelfY = anchorRect.y + selfDomRect.height + offset.y;
    }

    //Take care of wrapping and boundaries
    newSelfX = adjustAxisPosition(
      boundaries.left,
      boundaries.right,
      newSelfX,
      wrapOffset.x,
      selfDomRect.width,
    );
    newSelfY = adjustAxisPosition(
      boundaries.top,
      boundaries.bottom,
      newSelfY,
      wrapOffset.y,
      selfDomRect.height,
    );

    setSelfStyle({
      visibility: 'visible',
      left: `${newSelfX}px`,
      top: `${newSelfY}px`,
    });
  }, [options, anchorDomElement, selfDomRef]);

  useEffect(() => {
    if (!selfDomRef) {
      return;
    }

    if (open) {
      updatePosition();
      window.addEventListener('resize', updatePosition);
    } else {
      setSelfStyle((prevStyle) => ({
        ...prevStyle,
        visibility: 'hidden',
      }));
      window.removeEventListener('resize', updatePosition);
    }
  }, [open, selfDomRef, updatePosition]);

  const updateSelfDomRef = useCallback((ref) => {
    if (ref !== null) {
      setSelfDomSef(ref);
    }
  }, []);

  // When the <Root/> component is first rendered, its position will not be updated since selfDomRef is not set before render.
  // Therefore, to hide the popup first, the visibility of the <Root/> component is set to 'hidden' by default, and reset to 'hidden' again when closed.
  // Once selfDomRef is available, the position is then updated and visibility is set to 'visible'.
  // 'display' of <Root/> should not be set to 'none' as it causes values from getBoundClientRect to be all 0.
  return (
    open && (
      <>
        <ClickAwayBackdrop onClick={onClickAway} open={!!onClickAway} />
        <Root sx={selfStyle} ref={updateSelfDomRef}>
          {children}
        </Root>
      </>
    )
  );
}

const adjustAxisPosition = (
  axisLowerBound,
  axisUpperBound,
  axisPosition,
  axisPivotOffset,
  elementLengthOnAxis,
) => {
  if (typeof axisUpperBound === 'number') {
    if (
      typeof axisPivotOffset === 'number' &&
      axisPosition + elementLengthOnAxis > axisUpperBound
    ) {
      // No space in the upper range, swing element to the lower range
      axisPosition = axisPosition - axisPivotOffset;
    } else {
      // If pivot not set, element will not swing to the other side.
      // The element will slide along the axis until it is within the boundaries
      axisPosition = Math.min(axisUpperBound - elementLengthOnAxis, axisPosition);
    }
  }
  if (typeof axisLowerBound === 'number') {
    if (typeof axisPivotOffset === 'number' && axisPosition < axisLowerBound) {
      // No space in the lower range, swing element to the upper range
      axisPosition = axisPosition + axisPivotOffset;
    } else {
      axisPosition = Math.max(axisLowerBound, axisPosition);
    }
  }

  // If both upper and lower bounds are set, make sure the element is within the boundaries.
  if (typeof axisLowerBound === 'number' && typeof axisUpperBound === 'number') {
    axisPosition = Math.max(
      axisLowerBound,
      Math.min(axisUpperBound - elementLengthOnAxis, axisPosition),
    );
  }

  return axisPosition;
};
