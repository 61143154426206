export const FilePlusIcon = ({ color = '#6333FF', ...svgProps }) => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...svgProps}
    >
      <path
        d="M10.8531 3.50938L7.49063 0.146875C7.39688 0.053125 7.27031 0 7.1375 0H0.5C0.223437 0 0 0.223437 0 0.5V13.5C0 13.7766 0.223437 14 0.5 14H10.5C10.7766 14 11 13.7766 11 13.5V3.86406C11 3.73125 10.9469 3.60313 10.8531 3.50938ZM9.84688 4.09375H6.90625V1.15313L9.84688 4.09375ZM9.875 12.875H1.125V1.125H5.84375V4.5C5.84375 4.67405 5.91289 4.84097 6.03596 4.96404C6.15903 5.08711 6.32595 5.15625 6.5 5.15625H9.875V12.875ZM6 6.375C6 6.30625 5.94375 6.25 5.875 6.25H5.125C5.05625 6.25 5 6.30625 5 6.375V8.0625H3.3125C3.24375 8.0625 3.1875 8.11875 3.1875 8.1875V8.9375C3.1875 9.00625 3.24375 9.0625 3.3125 9.0625H5V10.75C5 10.8188 5.05625 10.875 5.125 10.875H5.875C5.94375 10.875 6 10.8188 6 10.75V9.0625H7.6875C7.75625 9.0625 7.8125 9.00625 7.8125 8.9375V8.1875C7.8125 8.11875 7.75625 8.0625 7.6875 8.0625H6V6.375Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
