import { createSlice } from '@reduxjs/toolkit';
import { FormFieldTypes } from '../../../components/DedocoDifference/Form/constants';
import { FormSliceName } from './constants';
import { reducers, asyncReducers } from './reducers';

const initialState = {
  formFiller: {
    agreedToTnC: false,
    name: '',
    email: '',
  },
  form: {
    id: '',
    name: '',
    type: '',
    fields: {
      [FormFieldTypes.esignatures]: [],
      [FormFieldTypes.digiSignatures]: [],
      [FormFieldTypes.initials]: [],
      [FormFieldTypes.stamps]: [],
      [FormFieldTypes.checkboxes]: [],
      [FormFieldTypes.dates]: [],
      [FormFieldTypes.inputs]: [],
      [FormFieldTypes.dropdownLists]: [],
      [FormFieldTypes.fileUploads]: [],
    },
    elements: {
      texts: [],
      images: [],
      attachments: [],
    },
    base64: '',
  },
  fieldIdToTypeMap: {},
  formFillerInputs: {
    [FormFieldTypes.esignatures]: [],
    [FormFieldTypes.digiSignatures]: [],
    [FormFieldTypes.initials]: [],
    [FormFieldTypes.stamps]: [],
    [FormFieldTypes.checkboxes]: [],
    [FormFieldTypes.dates]: [],
    [FormFieldTypes.inputs]: [],
    [FormFieldTypes.dropdownLists]: [],
    [FormFieldTypes.fileUploads]: [],
  },
  selectedFieldId: '',
  isAllRequiredFieldsFilled: false,
  fetchFormFailed: false,
  fetchFormSuccess: false,
  isFetchingForm: false,
  isLoadingFields: true,
  isSubmittingForm: false,
  isFormSubmitted: false,
  formSubmissionError: null,
  submittedFormDssKey: '',
  sidebarFieldItemClickForceUpdate: true,
};

const formSlice = createSlice({
  name: FormSliceName,
  initialState,
  reducers,
  extraReducers: (builder) => {
    asyncReducers.fetchForm.reducer(builder);
    asyncReducers.submitForm.reducer(builder);
  },
});

// Exporting actions
export const {
  updateFormFiller,
  updateFormDetails,
  updateFormFillerInput,
  deleteFormFillerInput,
  updateSelectedFormFieldId,
  updateFieldsLoadingFlag,
  updateIsAllRequiredFieldsFilled,
  sidebarFieldItemClick,
} = formSlice.actions;

export const fetchForm = asyncReducers.fetchForm.action;
export const submitForm = asyncReducers.submitForm.action;

export const formStateSelector = (state) => state.formState;
export default formSlice.reducer;
