import {
  ZOOMIN,
  ZOOMOUT,
  NEXTPAGE,
  PREVPAGE,
  TOTALPAGES,
  SETPAGE,
  SETFULLZOOM,
  SETZOOM,
} from '../actions/actiontypes';
const initialstate = {
  totalPages: 0,
  currentPage: 1,
  zoom: 1.3,
};
const PdfViewerStates = (state = initialstate, action) => {
  switch (action.type) {
    case TOTALPAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    case PREVPAGE:
      if (state.currentPage > 1)
        return {
          ...state,
          currentPage: state.currentPage - 1,
        };
      else {
        return state;
      }
    case NEXTPAGE:
      if (state.currentPage < state.totalPages)
        return {
          ...state,
          currentPage: state.currentPage + 1,
        };
      else {
        return state;
      }
    case ZOOMIN:
      if (state.zoom < 1.3)
        return {
          ...state,
          zoom: state.zoom + 0.1,
        };
      else {
        return state;
      }

    case ZOOMOUT:
      if (state.zoom > 0.8)
        return {
          ...state,
          zoom: state.zoom - 0.1,
        };
      else {
        return state;
      }
    case SETPAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SETFULLZOOM:
      return {
        ...state,
        zoom: 1.3,
      };
    case SETZOOM:
      return {
        ...state,
        zoom: action.payload,
      };
    default:
      return state;
  }
};

export default PdfViewerStates;
