import * as React from 'react';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';

const StyledButton = styled('div')(({ theme }) => ({
  ...theme.typography.body_medium,
  boxSizing: 'border-box',
  height: '40px',
  width: '100%',
  padding: theme.spacing(3),
  borderRadius: '8px',
  textAlign: 'left',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.stroke.dark}`,
  color: theme.palette.primary.main,
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '120ms',

  ['&:hover']: {
    borderColor: theme.palette.primary.light,
  },

  [`&.${selectUnstyledClasses.focusVisible}`]: {
    borderColor: theme.palette.primary.light,
  },

  [`&.${selectUnstyledClasses.expanded}`]: {
    ['&::after']: {
      content: '"▴"',
    },
  },

  ['&::after']: {
    content: '"▾"',
    float: 'right',
  },
}));

const StyledListbox = styled('ul')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  margin: theme.spacing(3, 0),
  width: '100%',
  borderRadius: '8px',
  overflow: 'auto',
  outline: '0px',
  background: theme.palette.background.main,
  border: 'none',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.12)',
}));

export const CustomOption = styled(OptionUnstyled)(({ theme }) => ({
  ...theme.typography.label_large,
  color: theme.palette.text.secondary,
  listStyle: 'none',
  textAlign: 'left',
  padding: theme.spacing(2, 2, 2, 9),
  borderRadius: '4px',
  cursor: 'pointer',

  ['&:last-of-type']: {
    borderBottom: 'none',
  },

  [`&.${optionUnstyledClasses.selected}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    paddingLeft: 0,

    ['&:before']: {
      content: '"✓"',
      fontWeight: 'bold',
      width: '20px',
      height: '20px',
      margin: theme.spacing(0, 2, 0, 2),
      color: theme.palette.primary.main,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },

  [`&.${optionUnstyledClasses.highlighted}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  ['&:hover']: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledPopper = styled(PopperUnstyled)(() => ({
  zIndex: 1,
  width: '90%',
}));

export const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const theme = useTheme();
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  const renderValue = (option) => {
    if (!option) {
      return <span style={{ color: theme.palette.text.secondary }}>{props.placeholder}</span>;
    }

    return <span>{option.label}</span>;
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} renderValue={renderValue} />;
});
