import { Grid, styled } from '@mui/material';

const PREFIX = 'InfoSidebar';

export const classes = {
  sideBox: `${PREFIX}-side-box`,
  createAccountButton: `${PREFIX}-create-account-button`,
  downloadButton: `${PREFIX}-download-button`,
  successTitle: `${PREFIX}-success-title`,
  tryTitle: `${PREFIX}-try-title`,
  demailTitle: `${PREFIX}-demail-title`,
};

export const Root = styled(Grid)(({ theme, isMobileOnly }) => ({
  gap: theme.spacing(6),
  textAlign: 'left',
  flexDirection: isMobileOnly ? 'column' : 'row',

  [theme.breakpoints.down('md')]: {
    [`& .${classes.sideBox}:nth-child(2)`]: {
      backgroundColor: theme.palette.background.paper,
    },
    [`& .${classes.downloadButton}`]: {
      display: 'none',
    },
    [`& .${classes.createAccountButton}`]: {
      width: '100%',
    },
    [`& .${classes.successTitle}`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [`& .${classes.tryTitle}`]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18px',
    },
    [`& .${classes.demailTitle}`]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  [`& .${classes.sideBox}`]: {
    backgroundColor: theme.palette.background.main,
    borderRadius: '8px',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  [`& .${classes.sideBox} .MuiGrid-item:nth-child(2)`]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  [`& .${classes.createAccountButton}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.downloadButton}`]: {
    marginBottom: theme.spacing(5),
  },
}));
