import { getCustomTextIcon } from '../assets/colorable-svgs';
import { getAnnotInfoFromFieldId } from './pdftron';
import {
  createWidgetLayout,
  createFormWidgetLayout,
  createTextDisplayAnnotation,
} from './pdftronWidgetCommon';

export const createTextWidgetAnnotation = (
  fieldId,
  text,
  name,
  descriptor,
  color,
  fieldFlags,
  writable,
  pdfTronInstance,
  type,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    tooltipName: descriptor,
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, null);
  const textWidgetLayout = createWidgetLayout(iframeWindow, {
    iconSrc: getCustomTextIcon(color, type),
    title: descriptor || 'Custom Text',
    color,
    tagText: name,
  });

  if (!writable) {
    if (text) {
      const textAnnot = createTextDisplayAnnotation(text, Annotations);
      widgetAnnot.setAssociatedSignatureAnnotation(textAnnot);
      textWidgetLayout.hideLabel();
    }
    widgetAnnot.createInnerElement = () => {
      return textWidgetLayout;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      return textWidgetLayout;
    };
  }

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const createFormTextInputWidgetAnnotation = (
  fieldId,
  fieldFlags,
  textInput,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });

  const textWidgetLayout = createFormWidgetLayout(iframeWindow, {
    labelText: textInput.label,
    isRequired: textInput.isRequired,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, null);
  widgetAnnot.createInnerElement = () => {
    return textWidgetLayout;
  };

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const numberValidation = (value, type, fieldId, projectSigners, t) => {
  const annotationInfo = getAnnotInfoFromFieldId(fieldId);
  const signer = projectSigners[annotationInfo.signerIndex];
  const customTexts = signer.customTexts[annotationInfo.index];
  const isError = type === 'error';

  if (isNaN(value)) {
    return isError ? t('EnterValidNumber') : false; // Not a valid number
  }

  const minValue = customTexts?.validation?.min;
  const maxValue = customTexts?.validation?.max;
  const numericValue = Number(value);

  if (minValue !== undefined && numericValue < Number(minValue)) {
    return isError ? t('NumberMustBeAtLeast', { minValue }) : false; // Value is less than the minimum
  }

  if (maxValue !== undefined && numericValue > Number(maxValue)) {
    return isError ? t('NumberMustBeAtMost', { maxValue }) : false; // Value is greater than the maximum
  }

  return isError ? t('EnterValidNumber') : true; // Value is within the valid range
};

export const isCustomText = (id) => typeof id === 'string' && id?.startsWith('text');
export const isUrl = (id) => typeof id === 'string' && id?.startsWith('url');
export const isAlphanumeric = (id) => typeof id === 'string' && id?.startsWith('alphanumeric');
export const isNumber = (id) => typeof id === 'string' && id?.startsWith('number');
