import { IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '100px',
  backgroundColor: theme.palette.supporting.main,
  boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.12)',
  position: 'absolute',
  top: 0,
  right: '-43px',
  [`&:hover`]: {
    backgroundColor: theme.palette.supporting.dark,
  },
  [theme.breakpoints.down('sm')]: {
    right: '-42px',
  },
  zIndex: 9999,
}));

export const CloseButtonIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}));
