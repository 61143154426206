import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormFieldTypes } from '../../../components/DedocoDifference/Form/constants';
import { downloadFileFromDSS } from '../../../lib/api/dss';
import { dedocoAxios } from '../../../lib/api/axios';
import { getFormDetails } from '../../../lib/api/forms';
import { encryptAndUploadFileToDSS } from '../../../utils/dss';
import { getBase64FileHash } from '../../../utils/hash';
import { FormErrorType, FormSliceName } from './constants';

// Normal reducers
const updateFormFiller = (state, action) => {
  state.formFiller = action.payload;
};

const updateFormDetails = (state, action) => {
  const fieldIdToTypeMap = {};
  const newFields = action.payload.fields;
  for (const type in newFields) {
    for (const field of newFields[type]) {
      fieldIdToTypeMap[field.fieldId] = type;
    }
  }
  state.form = action.payload;
  state.fieldIdToTypeMap = fieldIdToTypeMap;
};

const updateFormFillerInput = (state, action) => {
  const payload = action.payload;
  const fieldIdToUpdate = payload.fieldId;
  const fieldType = state.fieldIdToTypeMap[fieldIdToUpdate];
  const existingInputs = state.formFillerInputs[fieldType];

  const targetInput = existingInputs.find((input) => input.fieldId === fieldIdToUpdate);
  if (targetInput) {
    targetInput.value = payload.value;
    targetInput.shouldConsiderForFurtherChange = !!payload.shouldConsiderForFurtherChange;
  } else {
    existingInputs.push({
      fieldId: fieldIdToUpdate,
      value: payload.value,
      shouldConsiderForFurtherChange: !!payload.shouldConsiderForFurtherChange,
    });
  }

  updateIsAllRequiredFieldsFilled(state);
};

const deleteFormFillerInput = (state, action) => {
  const fieldIdToDelete = action.payload.fieldId;
  const fieldType = state.fieldIdToTypeMap[fieldIdToDelete];
  const existingInputs = state.formFillerInputs[fieldType];

  state.formFillerInputs[fieldType] = existingInputs.filter(
    (input) => input.fieldId !== fieldIdToDelete,
  );

  updateIsAllRequiredFieldsFilled(state);
};

const updateSelectedFormFieldId = (state, action) => {
  state.selectedFieldId = action.payload;
};

const updateFieldsLoadingFlag = (state, action) => {
  state.isLoadingFields = action.payload;
};

const updateIsAllRequiredFieldsFilled = (state) => {
  state.isAllRequiredFieldsFilled = checkIfAllRequiredFieldsFilled(
    state.form,
    state.formFillerInputs,
  );
};

const sidebarFieldItemClick = (state) => {
  state.sidebarFieldItemClickForceUpdate = !state.sidebarFieldItemClickForceUpdate;
};

// Async thunks
const fetchForm = {
  action: createAsyncThunk('form/fetch', async (formId) => {
    const formDetails = await getFormDetails(formId);
    const formBase64 = await downloadFileFromDSS(formDetails.dssKey);

    return {
      id: formId,
      name: formDetails.name,
      type: formDetails.type,
      fields: formDetails.primarySigner.fields,
      elements: formDetails.primarySigner.elements,
      base64: formBase64,
    };
  }),
  reducer: (builder) => {
    builder
      .addCase(fetchForm.action.pending, (state) => {
        state.fetchFormFailed = false;
        state.fetchFormSuccess = false;
        state.isFetchingForm = true;
      })
      .addCase(fetchForm.action.fulfilled, (state, action) => {
        updateFormDetails(state, action);
        state.fetchFormSuccess = true;
        state.isFetchingForm = false;
      })
      .addCase(fetchForm.action.rejected, (state) => {
        state.fetchFormFailed = true;
        state.isFetchingForm = false;
      });
  },
};

const submitForm = {
  action: createAsyncThunk(
    'form/submit',
    async ({ recaptchaToken, formId, formBase64 }, thunkAPI) => {
      const { form, formFiller, formFillerInputs } = thunkAPI.getState()[FormSliceName];

      if (!checkIfAllRequiredFieldsFilled(form, formFillerInputs)) {
        throw new Error('All required fields must be filled before submitting the form.');
      }

      const dssKey = await encryptAndUploadFileToDSS(formBase64, {}, { expiresAt: 0 });
      const formHash = getBase64FileHash(formBase64);

      const content = {};
      for (const type in formFillerInputs) {
        content[type] = formFillerInputs[type].map((input) => {
          const finalInput = { fieldId: input.fieldId, data: input.value.toString() };
          if (type === FormFieldTypes.digiSignatures) {
            finalInput.signatureType = 'ndi';
          }
          return finalInput;
        });
      }

      const submissionPayload = {
        docHash: formHash,
        dssKey,
        filler: {
          name: formFiller.name,
          email: formFiller.email,
        },
        content,
      };
      try {
        await dedocoAxios.post(`/forms/${formId}/submit`, submissionPayload, {
          headers: {
            recaptcha: recaptchaToken,
          },
        });
      } catch (err) {
        if (!err.response) {
          throw err;
        }

        return thunkAPI.rejectWithValue(err.response.data);
      }

      return { dssKey, formBase64 };
    },
  ),
  reducer: (builder) => {
    builder
      .addCase(submitForm.action.pending, (state) => {
        state.formSubmissionError = null;
        state.isFormSubmitted = false;
        state.isSubmittingForm = true;
      })
      .addCase(submitForm.action.fulfilled, (state, action) => {
        state.isFormSubmitted = true;
        state.isSubmittingForm = false;
        state.submittedFormDssKey = action.payload.dssKey;
        state.form.base64 = action.payload.formBase64;
      })
      .addCase(submitForm.action.rejected, (state, action) => {
        const error = action.payload;
        state.formSubmissionError = errorGenerator(error);
        state.isSubmittingForm = false;
      });
  },
};

export const reducers = {
  updateFormFiller,
  updateFormDetails,
  updateFormFillerInput,
  deleteFormFillerInput,
  updateSelectedFormFieldId,
  updateFieldsLoadingFlag,
  updateIsAllRequiredFieldsFilled,
  sidebarFieldItemClick,
};

export const asyncReducers = {
  fetchForm,
  submitForm,
};

// Other functions
const checkIfAllRequiredFieldsFilled = (form, formFillerInputs) => {
  const formFields = form.fields;
  for (const type in formFields) {
    const fieldsFilled = formFillerInputs[type].reduce(
      (set, input) => set.add(input.fieldId),
      new Set(),
    );
    for (const field of formFields[type]) {
      if (
        type !== FormFieldTypes.digiSignatures &&
        field.isRequired &&
        !fieldsFilled.has(field.fieldId)
      ) {
        return false;
      }
    }
  }

  return true;
};

const errorGenerator = (errorPayload) => {
  if ('errorCodes' in errorPayload) {
    // Parse recaptcha error
    const errorCode = errorPayload.errorCodes?.[0];
    if (errorCode === 'low-score') {
      return { type: FormErrorType.LowRecaptchaScore };
    }
  }

  return { type: FormErrorType.Generic };
};
