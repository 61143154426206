import { useFlag, useVariant } from '@unleash/proxy-client-react';
import { process_env } from '../utils/envData';

const featureToggleEnvVarPrefix = 'REACT_APP_FEATURE_TOGGLE';

export const featureNameMap = {
  webex: {
    lighthouse: 'Webex',
    env: `${featureToggleEnvVarPrefix}_WEBEX`,
  },
  signingAppDedocoDifference: {
    lighthouse: 'signing_app_dedoco_difference',
    env: `${featureToggleEnvVarPrefix}_NEW_THEME`,
  },
  downloadOptionsGoogleDrive: {
    lighthouse: 'download_options_google_drive',
    env: `${featureToggleEnvVarPrefix}_GOOGLE_DRIVE_DOWNLOAD`,
  },
  downloadOptionsOneDrive: {
    lighthouse: 'download_options_one_drive',
    env: `${featureToggleEnvVarPrefix}_ONE_DRIVE_DOWNLOAD`,
  },
  downloadOptionsDropbox: {
    lighthouse: 'download_options_dropbox',
    env: `${featureToggleEnvVarPrefix}_DROPBOX_DOWNLOAD`,
  },
  openApiVideoSign: {
    lighthouse: 'openapi_videosign',
    env: `${featureToggleEnvVarPrefix}_OPENAPI_VIDEOSIGN`,
  },
  showStageWatermark: {
    lighthouse: 'show_stage_watermark',
    env: `${featureToggleEnvVarPrefix}_SHOW_STAGE_WATERMARK`,
  },
  signAllESignature: {
    lighthouse: 'sign_all_esignature',
    env: `${featureToggleEnvVarPrefix}_ESIGN_ALL`,
  },
  rejectDocument: {
    lighthouse: 'reject_document',
    env: `${featureToggleEnvVarPrefix}_REJECT_DOCUMENT`,
  },
  termsAndConditionsOverlay: {
    lighthouse: 'terms_and_conditions_overlay',
    env: `${featureToggleEnvVarPrefix}_TNC_OVERLAY`,
  },
  singPassSigningMaintenance: {
    lighthouse: 'singpass_signing_maintenance',
    env: `${featureToggleEnvVarPrefix}_SINGPASS_SIGNING_MAINTENANCE`,
  },
  dVideoSign: {
    lighthouse: 'd_video_sign',
    env: `${featureToggleEnvVarPrefix}_D_VIDEO_SIGN`,
  },
  platformMaintenanceBanner: {
    lighthouse: 'platform_maintenance_banner',
    env: '',
  },
  adminPortal: {
    lighthouse: 'builder_app_admin_portal',
    env: `${featureToggleEnvVarPrefix}_ADMIN_PORTAL`,
  },
  adminPortalSetting: {
    lighthouse: 'builder_app_admin_portal_setting',
    env: `${featureToggleEnvVarPrefix}_ADMIN_PORTAL_SETTING`,
  },
  adminPortalSettingBranding: {
    lighthouse: 'builder_app_admin_portal_setting_branding',
    env: `${featureToggleEnvVarPrefix}_ADMIN_PORTAL_SETTING_BRANDING`,
  },
  docHashCheckBeforeLoad: {
    lighthouse: 'signing-app-doc-hash-check-before-load',
    env: `${featureToggleEnvVarPrefix}_DOC_HASH_CHECK_BEFORE_LOAD`,
  },
  assumeAllBadSigningIsBadPdf: {
    lighthouse: 'temp_assume_all_sign_error_is_bad_pdf',
    env: `${featureToggleEnvVarPrefix}_ASSUME_ALL_SIGN_ERROR_IS_BAD_PDF`,
  },
};

export const useFeatureFlag = (featureName, options) => {
  // If Unleash's FlagProvider is not available, useFlag will error out.
  // This indicates that the use of lighthouse is intentionally disabled.
  try {
    return useHookSelector(options?.useVariant)(featureName.lighthouse);
  } catch (e) {
    return process_env(featureName.env) === 'true';
  }
};

const useHookSelector = (useVariantHook) => {
  // This hook is used to return the hook to use.
  // This allows hooks to be executed conditionally without violating the react hook rules
  // Please do not abuse this pattern too much to prevent unexpected behaviors
  return useVariantHook ? useVariant : useFlag;
};
