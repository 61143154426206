import { useTheme } from '@emotion/react';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import SidebarWrapperContainer from '../SidebarWrapperContainer';
import EmailPad from './EmailPad';
import { validateEmail } from '../../../../utils/validation';
import { useDedocoSigning } from '../../../../hooks/useDedocoSigning';

function EmailBox({ newDedocoSigning = false }) {
  // hook
  const { t } = useTranslation();
  const theme = useTheme();

  const { currentField } = useGetRequiredDedocoSigning(newDedocoSigning);
  const ct = useDedocoSigning().currentParticipant.customTexts;
  const label = ct[currentField.fieldId?.split('-')[1]]?.descriptor || 'Email';

  const { instance } = usePdfTronContext();

  // state
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const handleOnChange = (value) => {
    setValue(value);

    const isValidEmail = validateEmail(value);
    setError(!isValidEmail);
  };

  const handleSave = useCallback(() => {
    setSignatureWidgetText(value, currentField.fieldId, instance);
  }, [currentField, value, instance]);

  const disableButton = !value || error;

  return (
    <SidebarWrapperContainer
      newDedocoSigning={newDedocoSigning}
      title={t('EmailTitle', { label })}
      content={
        <EmailPad
          handleValueChange={handleOnChange}
          error={error}
          style={{ padding: theme.spacing(4) }}
        />
      }
      footer={
        <Button
          data-testid="sidebar-save-email-button"
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={disableButton}
          fullWidth
        >
          {t('EmailButton')}
        </Button>
      }
    />
  );
}

export default EmailBox;
