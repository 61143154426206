import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Paper } from '@mui/material';

export const Root = styled(Paper)(({ theme }) => ({
  width: '800px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  padding: theme.spacing(6),
  margin: theme.spacing(0, 'auto'),
  border: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(7),
  paddingBottom: theme.spacing(8),
}));

export const Logo = styled('img')(() => ({
  width: '170px',
}));

export const Caption = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));

export const LoadingCaption = styled('div')(({ theme }) => ({
  fontSize: '20px',
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

export const LoadingBar = styled(LinearProgress)(() => ({
  width: '70%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'red',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#FFEAE9',
  },
}));
