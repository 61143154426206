import React, { useEffect, useState } from 'react';
import { getDownloadVideoUrl } from '../../lib/api';
import './DownloadVideo.css';

import downloadGif from '../../assets/download.gif';
import doneLogo from '../../assets/signingDone.png';

import { Button } from '@mui/material';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../Layout';

function DownloadVideo(props) {
  const { t } = useTranslation();
  const { compositionSid, secret } = queryString.parse(props.location.search);
  const [isLoaded, setIsLoaded] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    getDownloadVideoUrl(compositionSid, secret)
      .then((url) => {
        setIsLoaded(true);
        if (url) {
          setDownloadUrl(url);
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(() => {
        setIsLoaded(true);
      });
  }, [compositionSid, secret]);

  const rerouteDedoco = async () => {
    window.location = 'https://dedoco.com/';
  };

  if (isLoaded) {
    if (downloadUrl) {
      return (
        <PageLayout>
          <div className="download-video">
            <img alt="logo" src={downloadGif} className="download-video-logo" />
            <div className="download-video-header">{t('DownloadVideoDownloading')}</div>
            <div className="download-video-text">{t('DownloadVideoDownloadingSubtitle')}</div>
          </div>
        </PageLayout>
      );
    } else
      return (
        <PageLayout>
          <header>
            <img alt="logo" src={doneLogo} className="download-video-logo" />
            <div className="download-video-header">{t('DownloadVideoNotAvailableHeader')}</div>
            <div className="download-video-text">{t('DownloadVideoNotAvailableSubtitle')}</div>

            <div style={{ paddingBottom: '1vw', paddingTop: '20px' }}>
              <Button
                sx={{
                  fontSize: '1vw',
                  width: '20vw',
                  position: 'relative',
                  right: '1%',
                  height: '2.5vw',
                  backgroundColor: '#ecb32d',
                  color: 'black',
                  border: 'none',
                }}
                color="primary"
                onClick={rerouteDedoco}
              >
                {t('TryDedoco')}
              </Button>
            </div>
          </header>
        </PageLayout>
      );
  } else {
    return null;
  }
}

export default DownloadVideo;
