import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs as MuiTabs } from '@mui/material';
import { Tab as MuiTab } from '@mui/material';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#6333FF',
  },
  marginBottom: theme.spacing(3),
  height: '32px',
  minHeight: '32px',
}));

export const Tab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: theme.palette.grey[500],
  '&:hover': {
    color: theme.palette.primary.light,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.extraLight,
  },
  paddingTop: 0,
}));

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
