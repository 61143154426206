import { createTheme, adaptV4Theme } from '@mui/material/styles';

const oldTheme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: [
        'HCo Gotham',
        'Open Sans',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      button: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '-0.01em',
        padding: '16px',
      },
    },
    palette: {
      primary: {
        main: '#0D41BF',
        light: '#0D41BF',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          padding: 16,
        },
      },
    },
  }),
);

export { oldTheme };
