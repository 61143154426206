import React, { useEffect, useState, useRef } from 'react';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';

import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isMobile } from 'react-device-detect';

import 'flag-icon-css/css/flag-icon.min.css';
import { Button, Menu } from '@mui/material';
const PREFIX = 'LanguageSelector';

const classes = {
  paper: `${PREFIX}-paper`,
  list: `${PREFIX}-list`,
  container: `${PREFIX}-container`,
  listItem: `${PREFIX}-listItem`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: '0.5rem',
    paddingRight: 0,
    bottom: '30px',
    left: '20px',
    background: '#f1f6ff',
    '&:hover, &:focus': {
      opacity: '1.0',
    },
    [theme.breakpoints.down('1024')]: {
      bottom: '-0.5rem',
      left: '-0.5rem',
    },
  },

  [`& .${classes.listItem}`]: {
    minHeight: 0,
    lineHeight: '20px',
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

const StyledMenu = (props) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
);

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'EN', country_code: 'gb' },
    { code: 'jp', name: 'JP', country_code: 'jp' },
  ];

  const currentLanguageCode = cookie.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.title = t('appTitle');
  }, [currentLanguage, t]);

  const [anchorEl, setAnchorEl] = useState(null);
  const wrapperRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    const value = e.target.dataset.value;
    i18n.changeLanguage(value);
    setAnchorEl(null);
  };

  return (
    <Root className={classes.container} ref={wrapperRef}>
      <Button
        style={{ fontSize: 16, padding: 4, minWidth: 50 }}
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {!isMobile && currentLanguage.name}
        <span
          style={{ marginRight: 8, marginLeft: isMobile ? 0 : 8 }}
          className={`flag-icon flag-icon-${currentLanguage.country_code}`}
        ></span>
        <KeyboardArrowDownIcon style={{ fontSize: 12 }} />
      </Button>
      <StyledMenu
        onClose={() => setAnchorEl(null)}
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
      >
        {languages.map(({ code, name, country_code }) => (
          <MenuItem
            classes={{ root: classes.listItem }}
            onClick={(e) => handleClose(e)}
            data-value={code}
            key={`${code}${name}${country_code}`}
          >
            {name}
            <span
              onClick={(e) => handleClose(e)}
              data-value={code}
              className={`flag-icon flag-icon-${country_code}`}
            ></span>
          </MenuItem>
        ))}
      </StyledMenu>
    </Root>
  );
};

export default LanguageSelector;
