import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import DropboxSaver from './lib/DropboxSaver';
import OneDriveSaver from './lib/OneDriveSaver';
import GoogleDriveSaver from './lib/GoogleDriveSaver';
import { DownloadOptions } from './DownloadOptions.js';
import './DownloadOptionsButton.css';

import DropboxIcon from '../../../assets/dropbox.svg';
import GoogleIcon from '../../../assets/google.svg';
import MicrosoftIcon from '../../../assets/microsoft.svg';

import { useFeatureFlag, featureNameMap } from '../../../hooks/useFeatureFlag';
import { saveToLocalStorage } from './fileSaver';

const DownloadOptionsButton = (props) => {
  let { groupClassName, buttonClassName, toggleClassName, children, handleDownloadPdf } = props;

  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);

  const enableGoogleDriveOption = useFeatureFlag(featureNameMap.downloadOptionsGoogleDrive);
  const enableOneDriveOption = useFeatureFlag(featureNameMap.downloadOptionsOneDrive);
  const enableDropboxOption = useFeatureFlag(featureNameMap.downloadOptionsDropbox);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const DownloadToLocalButton = (config) => (
    <Button
      onClick={() => {
        handleDownloadPdf({
          downloadOption: DownloadOptions.LOCAL,
          downloadFunction: saveToLocalStorage,
        });
      }}
      className={config.className}
    >
      {children}
    </Button>
  );

  const buttonWithOptions = (
    <>
      <ButtonGroup
        variant="contained"
        className={`download-button-group ${groupClassName}`}
        ref={anchorRef}
        aria-label="split button"
      >
        <DownloadToLocalButton className={`download-local-btn ${buttonClassName}`}>
          {children}
        </DownloadToLocalButton>
        <Button
          size="small"
          aria-controls={openMenu ? 'split-button-menu' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-label="choose download destination"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={`download-options-toggle ${toggleClassName}`}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={openMenu}
        className="menu-popup"
        anchorEl={anchorRef.current}
        placement="bottom-end"
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
            },
          },
        ]}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {enableDropboxOption && (
                    <MenuItem
                      key="dropbox-option"
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <DropboxSaver
                        onClick={(saveFile) => {
                          handleDownloadPdf({
                            downloadOption: DownloadOptions.DROPBOX,
                            downloadFunction: saveFile,
                          });
                        }}
                        disabled={!enableDropboxOption}
                      >
                        <div className="dropdown-options-content">
                          <button className="option-logo-section">
                            <img alt="dropbox" src={DropboxIcon} />
                          </button>
                          <Typography align="center" className="option-name-section">
                            Save to Dropbox
                          </Typography>
                        </div>
                      </DropboxSaver>
                    </MenuItem>
                  )}

                  {enableDropboxOption && (enableGoogleDriveOption || enableOneDriveOption) && (
                    <Divider variant="middle" />
                  )}

                  {enableOneDriveOption && (
                    <MenuItem
                      key="onedrive-option"
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <OneDriveSaver
                        action="query"
                        advanced={{
                          redirectUri: window.location.origin,
                        }}
                        onClick={(saveFile) => {
                          handleDownloadPdf({
                            downloadOption: DownloadOptions.ONE_DRIVE,
                            downloadFunction: saveFile,
                          });
                        }}
                        disabled={!enableOneDriveOption}
                      >
                        <div className="dropdown-options-content">
                          <button className="option-logo-section">
                            <img alt="microsoft" src={MicrosoftIcon} />
                          </button>
                          <Typography align="center" className="option-name-section">
                            Save to OneDrive
                          </Typography>
                        </div>
                      </OneDriveSaver>
                    </MenuItem>
                  )}

                  {enableOneDriveOption && enableGoogleDriveOption && <Divider variant="middle" />}

                  {enableGoogleDriveOption && (
                    <MenuItem
                      key="google-drive-option"
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <GoogleDriveSaver
                        onClick={(saveFile) => {
                          handleDownloadPdf({
                            downloadOption: DownloadOptions.GOOGLE_DRIVE,
                            downloadFunction: saveFile,
                          });
                        }}
                      >
                        <div className="dropdown-options-content">
                          <button className="option-logo-section">
                            <img alt="google" src={GoogleIcon} />
                          </button>
                          <Typography align="center" className="option-name-section">
                            Save to Google Drive
                          </Typography>
                        </div>
                      </GoogleDriveSaver>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const buttonWithoutOptions = (
    <DownloadToLocalButton
      className={`download-local-btn download-button-group ${groupClassName}  ${buttonClassName}`}
    >
      {children}
    </DownloadToLocalButton>
  );

  return !enableDropboxOption && !enableOneDriveOption && !enableGoogleDriveOption
    ? buttonWithoutOptions
    : buttonWithOptions;
};

export default DownloadOptionsButton;
