import { Root, classes } from './TermsAndCondition.style';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTnC } from '../../../../hooks/useTnC/useTnC.new';
import { useReplaceStringWithComponent } from '../../../../hooks/useReplaceStringWithComponent';
import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { CypressIds } from '../../../../constant/cypressIds';

function TnCALink({ value }) {
  return (
    <a href="https://www.dedoco.com/terms-of-use" target="_blank" rel="noreferrer">
      {value}
    </a>
  );
}

export function TermsAndConditionBody(props) {
  const { t } = useTranslation();

  const tnc = useReplaceStringWithComponent(
    t('TermsAndConditionBody'),
    t('PrivacyPolicy&Terms'),
    TnCALink,
  );
  return (
    <div className={props.containerStyle}>
      <Typography className={props.contentStyle} variant="body_medium" align="left" gutterBottom>
        {tnc}
      </Typography>
    </div>
  );
}

function TermsAndCondition({
  hideButton = false,
  noTitle = false,
  fullWidth = true,
  title,
  buttonText,
  onProceed = () => {},
  isLoading = false,
}) {
  const { t } = useTranslation();

  const { TncSnackbar, handleSubmitTnC } = useTnC();

  const onSubmit = useCallback(() => {
    handleSubmitTnC();
    onProceed();
  }, [handleSubmitTnC, onProceed]);

  return (
    <Root fullWidth={fullWidth}>
      <TncSnackbar />
      {!noTitle && (
        <Typography variant="label_medium" align="left" gutterBottom>
          {title || t('TermsAndConditionTitle')}
        </Typography>
      )}
      <TermsAndConditionBody
        containerStyle={classes.termsConditionContainer}
        contentStyle={classes.termsConditionContent}
      />
      {!hideButton && (
        <LoadingButton
          data-testid="tnc-submit-button"
          data-cypress={CypressIds.tncSubmitButton}
          onClick={onSubmit}
          loading={isLoading}
          variant="contained"
          className={classes.proceedButton}
        >
          {buttonText || t('ProceedButton')}
        </LoadingButton>
      )}
    </Root>
  );
}

export default TermsAndCondition;
