import { Typography, useTheme } from '@mui/material';
import { FloatingCloseButton } from '../FloatingCloseButton';
import { Root, NextButton } from './MediumInputBase.style';

export const RequiredAsterick = () => {
  return <span style={{ color: 'red', fontSize: '15px', marginLeft: '5px' }}>*</span>;
};

export function MediumInputBase({
  onConfirmInput,
  onClose,
  title,
  description,
  submitButtonText,
  isRequired,
  children,
}) {
  const theme = useTheme();
  return (
    <Root>
      <FloatingCloseButton onClick={onClose} />
      <Typography variant="title_medium" align="left">
        {title}
        {isRequired && <RequiredAsterick />}
      </Typography>
      {description && (
        <Typography
          sx={{ marginTop: -2, color: theme.palette.text.secondary }}
          variant="body_medium"
          align="left"
        >
          {description}
        </Typography>
      )}
      {children}
      <NextButton variant="contained" color="primary" onClick={onConfirmInput}>
        {submitButtonText}
      </NextButton>
    </Root>
  );
}
