import { dedocoAxios } from '../../lib/api/axios';
import {
  APPROVE_DOCUMENT,
  APPROVE_DOCUMENT_FAILED,
  APPROVE_DOCUMENT_SUCCESS,
  DECLINE_DOCUMENT,
  DECLINE_DOCUMENT_FAILED,
  DECLINE_DOCUMENT_SUCCESS,
  DOCUMENT_TO_SIGN_FAIL,
  FILEURL,
  GETTING_DOCUMENT_TO_SIGN,
  GETTING_ORGANIZATION_LOGO,
  GET_ORGANIZATION_LOGO_FAIL,
  GOT_DOCUMENT_TO_SIGN,
  GOT_ORGANIZATION_LOGO,
  SAVE_PDF_STRING_BASE64,
  SEND_VERIFY_APPROVER_OTP,
  SEND_VERIFY_APPROVER_OTP_FAILED,
  SEND_VERIFY_APPROVER_OTP_SUCCESS,
  SEND_VERIFY_OBSERVER_OTP,
  SEND_VERIFY_OBSERVER_OTP_FAILED,
  SEND_VERIFY_OBSERVER_OTP_SUCCESS,
  SEND_VERIFY_SIGNING_OTP,
  SEND_VERIFY_SIGNING_OTP_FAILED,
  SEND_VERIFY_SIGNING_OTP_SUCCESS,
  SET_IS_VALID_DOC_HASH,
  SIGN_DOCUMENT,
  SIGN_DOCUMENT_FAILED,
  SIGN_DOCUMENT_SUCCESS,
  UPDATE_SAVED_SIGNATURES,
  UPDATE_SIGNATURE_SECRET,
  VERIFY_APPROVER_OTP,
  VERIFY_APPROVER_OTP_FAILED,
  VERIFY_APPROVER_OTP_SUCCESS,
  VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_DHC,
  VERIFY_OBSERVER_OTP,
  VERIFY_OBSERVER_OTP_FAILED,
  VERIFY_OBSERVER_OTP_SUCCESS,
  VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_SIGNING_OTP,
  VERIFY_SIGNING_OTP_FAILED,
  VERIFY_SIGNING_OTP_SUCCESS,
} from './actiontypes';

export const OPEN_API_V2_URL_PREFIX = '/openapi';

export const newSignDocument = (data, id, uid, isOpenApiV2 = false) => {
  return (dispatch) => {
    dispatch({
      type: SIGN_DOCUMENT,
    });
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post(
          `${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/${id}/signers/${uid}/sign`,
          data,
        )
        .then(({ data }) => {
          resolve(data);
          dispatch({
            type: SIGN_DOCUMENT_SUCCESS,
            payload: data,
          });
        })
        .catch((errData) => {
          reject(errData.response);
          dispatch({
            type: SIGN_DOCUMENT_FAILED,
          });
        });
    });
  };
};

export const signDocument = (data, id, uid) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .post(`/businessprocess/${id}/signers/${uid}/sign`, data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

export const _approveDocument = (data, id, uid, isOpenApiV2 = false) => {
  return (dispatch) => {
    dispatch({
      type: APPROVE_DOCUMENT,
    });
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post(
          `${
            isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
          }/businessprocess/${id}/approvers/${uid}/approve`,
          {
            ...data,
            approve: true,
          },
        )
        .then(({ data }) => {
          dispatch({
            type: APPROVE_DOCUMENT_SUCCESS,
          });
          resolve(data);
        })
        .catch((errData) => {
          dispatch({
            type: APPROVE_DOCUMENT_FAILED,
          });
          reject(errData.response);
        });
    });
  };
};

export const declineDocument = (data, id, uid, isOpenApiV2 = false) => {
  return (dispatch) => {
    dispatch({
      type: DECLINE_DOCUMENT,
    });
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post(
          `${
            isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
          }/businessprocess/${id}/approvers/${uid}/approve`,
          {
            ...data,
            approve: false,
          },
        )
        .then(({ data }) => {
          dispatch({
            type: DECLINE_DOCUMENT_SUCCESS,
          });
          resolve(data);
        })
        .catch((errData) => {
          dispatch({
            type: DECLINE_DOCUMENT_FAILED,
          });
          reject(errData.response);
        });
    });
  };
};

export function getDocumentToSign(id, uid, secret, isOpenApiV2 = false) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dedocoAxios
      .get(`${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`, {
        params: {
          document_id: id,
          signer_id: uid,
          secret,
        },
      })
      .then(function (response) {
        dispatch({
          type: GOT_DOCUMENT_TO_SIGN,
          payload: response.data,
        });
      })
      .catch(function (error) {
        const res = error.response;
        dispatch({
          type: DOCUMENT_TO_SIGN_FAIL,
          errorCode: res?.status,
          message: res?.data?.error_description,
        });
      });
  };
}

export function getOrganizationLogo(organizationId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_ORGANIZATION_LOGO,
    });
    dedocoAxios
      .get(`/organisations/${organizationId}/logo`)
      .then(function (response) {
        dispatch({
          type: GOT_ORGANIZATION_LOGO,
          payload: response.data,
        });
      })
      .catch(function (error) {
        const res = error.response;
        dispatch({
          type: GET_ORGANIZATION_LOGO_FAIL,
          errorCode: res?.status,
          message: res?.data?.error_description,
        });
      });
  };
}

export function getPublicDocumentToSign(id, uid, secret) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dedocoAxios
      .get(`/public/business-processes/signature/getRequest`, {
        params: {
          business_process_id: id,
          signer_id: uid,
          secret,
        },
      })
      .then(function (response) {
        dispatch({
          type: GOT_DOCUMENT_TO_SIGN,
          payload: response.data,
        });
      })
      .catch(function (_error) {
        dispatch({
          type: DOCUMENT_TO_SIGN_FAIL,
        });
      });
  };
}

export function getWebexSigningDetails(data) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dispatch({
      type: GOT_DOCUMENT_TO_SIGN,
      payload: data,
    });
  };
}

export function getPublicObserverDetails(id, uid, secret) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dedocoAxios
      .get(`public/business-processes/signature/getRequest`, {
        params: {
          business_process_id: id,
          observer_id: uid,
          secret,
        },
      })
      .then(function (response) {
        dispatch({
          type: GOT_DOCUMENT_TO_SIGN,
          payload: response.data,
        });
      })
      .catch(function (_error) {
        dispatch({
          type: DOCUMENT_TO_SIGN_FAIL,
        });
      });
  };
}

export function getObserverDetails(id, uid, secret, isOpenApiV2 = false) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dedocoAxios
      .get(`${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`, {
        params: {
          document_id: id,
          observer_id: uid,
          secret,
        },
      })
      .then(function (response) {
        dispatch({
          type: GOT_DOCUMENT_TO_SIGN,
          payload: response.data,
        });
      })
      .catch(function (error) {
        const res = error.response;
        dispatch({
          type: DOCUMENT_TO_SIGN_FAIL,
          errorCode: res?.status,
        });
      });
  };
}

export function getApproverDetails(id, uid, secret, isOpenApiV2 = false) {
  return (dispatch) => {
    dispatch({
      type: GETTING_DOCUMENT_TO_SIGN,
    });
    dedocoAxios
      .get(`${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`, {
        params: {
          document_id: id,
          approver_id: uid,
          secret,
        },
      })
      .then(function (response) {
        dispatch({
          type: GOT_DOCUMENT_TO_SIGN,
          payload: response.data,
        });
      })
      .catch(function (error) {
        const res = error.response;
        dispatch({
          type: DOCUMENT_TO_SIGN_FAIL,
          errorCode: res?.status,
        });
      });
  };
}

export function sendVerifyOTP(userIdentity) {
  return dedocoAxios.get('webex/send-otp', {
    params: {
      actor_name: userIdentity.name,
      actor_email: userIdentity.email,
      role: userIdentity.role,
    },
  });
}

export function verifyWebexOTP(userIdentity, otp) {
  return dedocoAxios.get('webex/verify-otp', {
    params: {
      actor_email: userIdentity.email,
      role: userIdentity.role,
      otp,
    },
  });
}

export function verifySigningOTP(id, uid, otp, isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VERIFY_SIGNING_OTP,
      });
      const path = isPublic
        ? '/public/business-processes/signature/getRequest'
        : `${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`;
      dedocoAxios
        .get(path, {
          params: {
            ...(isPublic ? { business_process_id: id } : { document_id: id }),
            signer_id: uid,
            otp: otp,
          },
        })
        .then((res) => {
          dispatch({
            type: VERIFY_SIGNING_OTP_SUCCESS,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          dispatch({
            type: VERIFY_SIGNING_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function verifyObserverOTP(id, uid, otp, isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VERIFY_OBSERVER_OTP,
      });
      const path = isPublic
        ? '/public/business-processes/signature/getRequest'
        : `${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`;
      dedocoAxios
        .get(path, {
          params: {
            ...(isPublic ? { business_process_id: id } : { document_id: id }),
            observer_id: uid,
            otp: otp,
          },
        })
        .then((res) => {
          dispatch({
            type: VERIFY_OBSERVER_OTP_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: VERIFY_OBSERVER_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function verifyApproverOTP(id, uid, otp, _isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: VERIFY_APPROVER_OTP,
      });

      dedocoAxios
        .get(`${isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''}/businessprocess/signature/getRequest`, {
          params: {
            document_id: id,
            approver_id: uid,
            otp: otp,
          },
        })
        .then((res) => {
          dispatch({
            type: VERIFY_APPROVER_OTP_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: VERIFY_APPROVER_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function sendVerifySigningOTP(id, uid, isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SEND_VERIFY_SIGNING_OTP,
      });
      const path = isPublic
        ? '/public/business-processes/signature/getRequest/send-otp'
        : `${
            isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
          }/businessprocess/signature/getRequest/send-otp`;
      dedocoAxios
        .get(path, {
          params: {
            ...(isPublic ? { business_process_id: id } : { document_id: id }),
            signer_id: uid,
          },
        })
        .then(() => {
          dispatch({
            type: SEND_VERIFY_SIGNING_OTP_SUCCESS,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: SEND_VERIFY_SIGNING_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function sendVerifyObserverOTP(id, uid, isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SEND_VERIFY_OBSERVER_OTP,
      });
      const path = isPublic
        ? '/public/business-processes/signature/getRequest/send-otp'
        : `${
            isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
          }/businessprocess/signature/getRequest/send-otp`;
      dedocoAxios
        .get(path, {
          params: {
            ...(isPublic ? { business_process_id: id } : { document_id: id }),
            observer_id: uid,
          },
        })
        .then(() => {
          dispatch({
            type: SEND_VERIFY_OBSERVER_OTP_SUCCESS,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: SEND_VERIFY_OBSERVER_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function sendVerifyApproverOTP(id, uid, _isPublic, isOpenApiV2 = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SEND_VERIFY_APPROVER_OTP,
      });
      dedocoAxios
        .get(
          `${
            isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
          }/businessprocess/signature/getRequest/send-otp`,
          {
            params: {
              document_id: id,
              approver_id: uid,
            },
          },
        )
        .then(() => {
          dispatch({
            type: SEND_VERIFY_APPROVER_OTP_SUCCESS,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: SEND_VERIFY_APPROVER_OTP_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function verifySignerSingPassAuthCode(documentId, signerId, authCode) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post('/businessprocess/ndi/auth', {
          documentId,
          signerId,
          authCode,
        })
        .then((res) => {
          dispatch({
            type: VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function verifyObserverSingPassAuthCode(documentId, observerId, authCode) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post('/businessprocess/ndi/auth', {
          documentId,
          observerId,
          authCode,
        })
        .then((res) => {
          dispatch({
            type: VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function verifyApproverSingPassAuthCode(documentId, approverId, authCode) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dedocoAxios
        .post('/businessprocess/ndi/auth', {
          documentId,
          approverId,
          authCode,
        })
        .then((res) => {
          dispatch({
            type: VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function savePdfBase64String(data) {
  return (dispatch) => {
    dispatch({
      type: SAVE_PDF_STRING_BASE64,
      payload: data,
    });
  };
}

export function setIsValidDocHash(isValid) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_VALID_DOC_HASH,
      payload: isValid,
    });
  };
}

export function updateFileUrl(fileUrl) {
  return (dispatch) => {
    dispatch({
      type: FILEURL,
      payload: fileUrl,
    });
  };
}

export function verifyDedocoHashCheck(status) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_DHC,
      payload: status,
    });
  };
}

export function updateSavedSignature(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SAVED_SIGNATURES,
      payload: data,
    });
  };
}

export function updateSignatureSecret(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SIGNATURE_SECRET,
      payload: data,
    });
  };
}
