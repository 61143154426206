import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { getStringHash } from '../../utils/hash';
import checkboxIcon from '../../assets/check.png';

export function publicRenderPDF(src, allSignerData, currentSignerData) {
  // eSignData and placement are for text
  //console.log('DATA', allSignerData);
  //console.log('PLACEMENT', currentSignerData)
  const renderFunction = async (src, data, digiSignData) => {
    //const today = new Date();
    //const dd = String(today.getDate()).padStart(2, '0');
    //const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //const yyyy = String(today.getFullYear());

    const pdfDoc = await PDFDocument.load(src);
    const pages = pdfDoc.getPages();
    const signPage = pages[0];
    const { width, height } = signPage.getSize();
    const arialFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    //const isLandscape = width / height;
    //Gary to refactor hack
    //if not electronic signature
    if (data.digi_signatures.length > 0) {
      if (data.custom_texts.length > 0) {
        const customTextData = data.custom_texts;
        for (let i = 0; i < customTextData.length; i++) {
          let xCorText = parseFloat(customTextData[i].placement.x);
          let yCorText = parseFloat(customTextData[i].placement.y);
          let dimensionsHeight = parseFloat(customTextData[i].dimensions.height) * height;
          let dimensionsWidth = dimensionsHeight * 4;
          const customTextDimensionWidth = parseFloat(dimensionsWidth);

          if (
            customTextData[i].type === 'actual-date' ||
            customTextData[i].type === 'custom-date'
          ) {
            const logoBytes = await fetch('/date.png').then((res) => res.arrayBuffer());
            const logoImage = await pdfDoc.embedPng(logoBytes);
            const logoDims = logoImage.scale(0.35);
            const pageToSign = pages[customTextData[i].placement.page - 1];
            const logoSizeRatio = logoDims.width / logoDims.height;
            const dd = digiSignData.custom_texts[i].split('/')[0];
            const mm = digiSignData.custom_texts[i].split('/')[1];
            const yyyy = digiSignData.custom_texts[i].split('/')[2];

            const customTextDimensionHeight = customTextDimensionWidth / logoSizeRatio;
            const logoPositionX = xCorText * width;
            const logoPositionY = height - height * yCorText - customTextDimensionHeight;
            //TODO: to change y-coordinate of custom text depending on requirments
            pageToSign.drawImage(logoImage, {
              x: logoPositionX,
              y: logoPositionY,
              width: customTextDimensionWidth,
              height: customTextDimensionHeight,
            });

            //if descriptor is date then..
            pageToSign.drawText(dd, {
              x: logoPositionX + customTextDimensionWidth / 2.96,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });

            pageToSign.drawText(mm, {
              x: logoPositionX + customTextDimensionWidth / 1.862,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });

            pageToSign.drawText(yyyy, {
              x: logoPositionX + customTextDimensionWidth / 1.3793,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });
          } else {
            const pageToSign = pages[customTextData[i].placement.page - 1];
            const customTextDimensionHeight =
              parseFloat(customTextData[i].dimensions.height) * height;
            pageToSign.drawText(digiSignData.custom_texts[i], {
              x: xCorText * width,
              //TODO: to change dimensions and height position depending on custom text design
              y: height - height * yCorText - customTextDimensionHeight * 0.7,
              font: arialFont,
              size: customTextDimensionHeight,
              color: rgb(0, 0, 0),
            });
          }
        }
      }
    }
    if (data?.confirmations?.length) {
      const confirmationData = data?.confirmations;

      const checkboxImageByte = await fetch(checkboxIcon).then((res) => res.arrayBuffer());
      const checkboxImage = await pdfDoc.embedPng(checkboxImageByte);

      confirmationData.forEach((confirmation, idx) => {
        let xCor = parseFloat(confirmation.placement.x.split('%')[0]) / 100;
        let yCor = parseFloat(confirmation.placement.y.split('%')[0]) / 100;
        const checkboxDimensionHeight = parseInt(confirmation.dimensions.height.split('px')[0]);
        let logoPositionX = xCor * width + checkboxDimensionHeight * 0.15;
        let logoPositionY =
          height - height * yCor - checkboxDimensionHeight + checkboxDimensionHeight * 0.45;
        const pageToSign = pages[confirmation.placement.page - 1];

        digiSignData?.confirmations[idx]?.isChecked &&
          pageToSign &&
          pageToSign.drawImage(checkboxImage, {
            x: logoPositionX,
            y: logoPositionY,
            width: checkboxDimensionHeight * 0.7,
            height: checkboxDimensionHeight * 0.5,
          });
      });
    } else {
      //Esignature
      let eSignData = data.esignatures;
      let customTextData = data.custom_texts;
      let confirmationData = data.confirmations;

      // Confirmations render
      if (confirmationData?.length) {
        const checkboxImageByte = await fetch(checkboxIcon).then((res) => res.arrayBuffer());
        const checkboxImage = await pdfDoc.embedPng(checkboxImageByte);
        confirmationData.forEach((confirmation, idx) => {
          let xCor = parseFloat(confirmation.placement.x.split('%')[0]) / 100;
          let yCor = parseFloat(confirmation.placement.y.split('%')[0]) / 100;
          const checkboxDimensionHeight = parseInt(confirmation.dimensions.height.split('px')[0]);
          let logoPositionX = xCor * width + checkboxDimensionHeight * 0.15;
          let logoPositionY =
            height - height * yCor - checkboxDimensionHeight + checkboxDimensionHeight * 0.45;
          const pageToSign = pages[confirmation.placement.page - 1];

          digiSignData?.confirmations[idx]?.isChecked &&
            pageToSign &&
            pageToSign.drawImage(checkboxImage, {
              x: logoPositionX,
              y: logoPositionY,
              width: checkboxDimensionHeight * 0.7,
              height: checkboxDimensionHeight * 0.5,
            });
        });
      }

      if (eSignData.length) {
        for (let i = 0; i < eSignData.length; i++) {
          let xCor = parseFloat(eSignData[i].placement.x);
          let yCor = parseFloat(eSignData[i].placement.y);
          let dimensionsHeight = parseFloat(eSignData[i].dimensions.height) * height;
          let dimensionsWidth = dimensionsHeight * 2;
          /*
            if (isLandscape > 1) {
              yCor = parseFloat(eSignData[i].placement.x.split('%')[0]) / 100;
              xCor = parseFloat(eSignData[i].placement.y.split('%')[0]) / 100;
            } else {
              xCor = parseFloat(eSignData[i].placement.x.split('%')[0]) / 100;
              yCor = parseFloat(eSignData[i].placement.y.split('%')[0]) / 100;
            }
            */

          if (digiSignData.electronic_signatures[i].signature) {
            //shifted sig-box.png to public for ease of access
            const logoBytes = await fetch('/sig-box.png').then((res) => res.arrayBuffer());

            const logoImage = await pdfDoc.embedPng(logoBytes);
            //const pngImage = await pdfDoc.embedPng(eSignData[i].signature);
            //const jpgImage = await pdfDoc.embedJpg(eSignData[i].signature);
            const imageData =
              digiSignData.electronic_signatures[i].signature.split(';')[0] === 'data:image/jpeg'
                ? await pdfDoc.embedJpg(digiSignData.electronic_signatures[i].signature)
                : await pdfDoc.embedPng(digiSignData.electronic_signatures[i].signature);
            const imageHeight = imageData.height;
            const imageWidth = imageData.width;
            const imageRatio = imageWidth / imageHeight;

            //const pngDims = imageData.scale(0.5);
            //const logoDims = logoImage.scale(0.35);
            const pageToSign = pages[eSignData[i].placement.page - 1];
            //const logoSizeRatio = logoDims.width / logoDims.height;

            const eSignDimensionWidth = dimensionsWidth;
            const eSignDimensionHeight = dimensionsHeight;
            const logoPositionX = xCor * width;
            const logoPositionY = height - height * yCor - eSignDimensionHeight;
            const correctionMultiplier =
              eSignDimensionHeight * imageRatio * 0.7 - eSignDimensionWidth < 0
                ? 1
                : (eSignDimensionHeight * imageRatio * 0.7) / eSignDimensionWidth;

            pageToSign.drawImage(logoImage, {
              x: logoPositionX,
              y: logoPositionY,
              width: eSignDimensionWidth,
              height: eSignDimensionHeight,
            });

            if (digiSignData.electronic_signatures[i].type === 'drawing') {
              pageToSign.drawImage(imageData, {
                x: logoPositionX + eSignDimensionWidth / 4.0075,
                y: logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.346,
                width: eSignDimensionWidth / 2.4,
                //height to be matched with image height
                height: eSignDimensionWidth / 2.4 / 1.53,
              });
            } else if (digiSignData.electronic_signatures[i].type === 'upload') {
              pageToSign.drawImage(imageData, {
                x:
                  logoPositionX +
                  eSignDimensionWidth / 1.8 -
                  (eSignDimensionHeight * imageRatio * 0.75) / 2 / correctionMultiplier,
                y: logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.346,
                width: (eSignDimensionHeight * imageRatio * 0.7) / correctionMultiplier,
                //height to be matched with image height
                height: (eSignDimensionHeight * 0.7) / correctionMultiplier,
              });
            } else if (digiSignData.electronic_signatures[i].type === 'text') {
              pageToSign.drawImage(imageData, {
                x: logoPositionX + eSignDimensionWidth / 20,
                y: logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.7,
                width: eSignDimensionWidth * 0.9,
                height: eSignDimensionWidth / 7,
              });
            }
          }
        }
      }

      // Confirmations render
      if (customTextData?.length) {
        for (let i = 0; i < customTextData.length; i++) {
          let xCorText = parseFloat(customTextData[i].placement.x);
          let yCorText = parseFloat(customTextData[i].placement.y);
          let dimensionsHeight = parseFloat(customTextData[i].dimensions.height) * height;
          let dimensionsWidth = dimensionsHeight * 4;
          const pageToSign = pages[customTextData[i].placement.page - 1];
          const customTextDimensionWidth = parseFloat(dimensionsWidth);

          if (
            customTextData[i].type === 'actual-date' ||
            customTextData[i].type === 'custom-date'
          ) {
            const logoBytes = await fetch('/date.png').then((res) => res.arrayBuffer());
            const logoImage = await pdfDoc.embedPng(logoBytes);
            const logoDims = logoImage.scale(0.35);
            const pageToSign = pages[customTextData[i].placement.page - 1];
            const logoSizeRatio = logoDims.width / logoDims.height;
            const dd = digiSignData.custom_texts[i].split('/')[0];
            const mm = digiSignData.custom_texts[i].split('/')[1];
            const yyyy = digiSignData.custom_texts[i].split('/')[2];

            const customTextDimensionHeight = customTextDimensionWidth / logoSizeRatio;
            const logoPositionX = xCorText * width;
            const logoPositionY = height - height * yCorText - customTextDimensionHeight;
            //TODO: to change y-coordinate of custom text depending on requirments
            pageToSign.drawImage(logoImage, {
              x: logoPositionX,
              y: logoPositionY,
              width: customTextDimensionWidth,
              height: customTextDimensionHeight,
            });

            //if descriptor is date then..
            pageToSign.drawText(dd, {
              x: logoPositionX + customTextDimensionWidth / 2.96,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });

            pageToSign.drawText(mm, {
              x: logoPositionX + customTextDimensionWidth / 1.862,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });

            pageToSign.drawText(yyyy, {
              x: logoPositionX + customTextDimensionWidth / 1.3793,
              y: logoPositionY + customTextDimensionHeight - customTextDimensionHeight / 1.6852,
              size: customTextDimensionWidth / 10.953,
              color: rgb(0, 0, 0),
            });
          } else if (customTextData[i].type === 'initials') {
            const xCor = parseFloat(customTextData[i].placement.x);
            const yCor = parseFloat(customTextData[i].placement.y);
            const dimensionsHeight = parseFloat(customTextData[i].dimensions.height) * height;
            const dimensionsWidth = dimensionsHeight * 2;
            const x = xCor * width;
            const y = height - height * yCor - dimensionsHeight;

            const imageData =
              digiSignData.custom_texts[i].split(';')[0] === 'data:image/jpeg'
                ? await pdfDoc.embedJpg(digiSignData.custom_texts[i])
                : await pdfDoc.embedPng(digiSignData.custom_texts[i]);

            pageToSign.drawImage(imageData, {
              x: x + dimensionsWidth / 4.0075,
              y: y + dimensionsHeight - dimensionsHeight / 1.346,
              width: dimensionsWidth / 2.4,
              height: dimensionsWidth / 2.4 / 1.53,
            });
          } else {
            const customTextDimensionHeight =
              parseFloat(customTextData[i].dimensions.height) * height;
            pageToSign.drawText(digiSignData.custom_texts[i], {
              x: xCorText * width,
              //TODO: to change dimensions and height position depending on custom text design
              y: height - height * yCorText - customTextDimensionHeight * 0.7,
              font: arialFont,
              size: customTextDimensionHeight,
              color: rgb(0, 0, 0),
            });
          }
        }
      }
    }

    const pdfBytes = await pdfDoc.save();
    //const reader = new FileReader();
    const buff = new Buffer(pdfBytes);
    const base64 = buff.toString('base64');
    const pdfHash = getStringHash(base64);
    return pdfHash.then((hash) => {
      return { base64, hash, pdfBytes };
    });
  };

  return renderFunction(src, currentSignerData, allSignerData);
}
