import { useState, useEffect } from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { classes, PdfTronToolbarRoot } from './Toolbar.style';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useWebexContext } from '../../../../contexts/useWebexContext';

function Toolbar({ documentViewer, fileName }) {
  const { isWebexSigning } = useWebexContext();
  const { t } = useTranslation();

  const [zoomLevel, setZoomLevel] = useState();
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();

  const zoomOut = () => {
    setZoomLevel(zoomLevel - 10);
  };

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 10);
  };

  useEffect(() => {
    if (!documentViewer) return;

    let defaultZoomLevel = zoomLevel;
    if (!defaultZoomLevel) {
      defaultZoomLevel = 100;
      if (isWebexSigning) {
        defaultZoomLevel = 50;
      }
      setZoomLevel(defaultZoomLevel);
    }

    documentViewer?.zoomTo(defaultZoomLevel / 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentViewer, zoomLevel, documentViewer?.getZoomLevel(), isWebexSigning]);

  useEffect(() => {
    if (!documentViewer) return;
    documentViewer.addEventListener('zoomUpdated', (zoom) => {
      setZoomLevel(zoom * 100);
    });

    return () => {
      documentViewer.removeEventListener('zoomUpdated');
    };
  }, [documentViewer]);

  useEffect(() => {
    if (!documentViewer) return;
    documentViewer.addEventListener('documentLoaded', () => {
      setPageCount(documentViewer?.getPageCount());
      setCurrentPage(documentViewer?.getCurrentPage());
    });

    documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
      setCurrentPage(pageNumber);
    });

    return () => {
      documentViewer.removeEventListener('documentLoaded');
      documentViewer.removeEventListener('pageNumberUpdated');
    };
  }, [documentViewer]);

  return (
    <PdfTronToolbarRoot>
      <div className={classes.fileNameContainer}>
        <Tooltip title={fileName} placement="bottom">
          <Typography sx={{ overflow: 'auto' }} variant="title_medium">
            {fileName}
          </Typography>
        </Tooltip>
        <Typography variant="title_medium" className={classes.pageNumber}>
          {t('ToolbarPageNumber', { currentPage, pageCount })}
        </Typography>
      </div>
      <div className={classes.zoomContainer}>
        <div
          onClick={zoomOut}
          className={clsx(classes.zoomIconContainer, classes.leftBorderRadius)}
        >
          <RemoveIcon className={classes.icon} />
        </div>
        <div className={classes.zoomPercentage}>
          <Typography variant="title_medium" sx={{ fontWeight: 600 }}>
            {zoomLevel?.toFixed(0)}%
          </Typography>
        </div>
        <div
          onClick={zoomIn}
          className={clsx(classes.zoomIconContainer, classes.rightBorderRadius)}
        >
          <AddIcon className={classes.icon} />
        </div>
      </div>
    </PdfTronToolbarRoot>
  );
}

export default Toolbar;
