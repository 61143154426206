import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useWebexContext } from '../../contexts/useWebexContext';
import { downloadFileFromDSS } from '../../lib/api/dss';
import { ACCEPT_TNC } from '../../redux/actions/actiontypes';
import { getIp } from '../../redux/actions/authfunctions';
import {
  declineDocument,
  getApproverDetails,
  getDocumentToSign,
  getObserverDetails,
  newSignDocument,
  savePdfBase64String,
  _approveDocument,
  setIsValidDocHash,
} from '../../redux/actions/documentfunctions';
import { generateFileShareKey } from '../../utils/fileShareKey';
import { getBase64FileHash } from '../../utils/hash';
import { convertObjectKeyToCamelCase } from '../../utils/lodash';
import {
  getAnnotInfoFromFieldId,
  getPdfWithAnnotsAsDataUrl,
  prepareSigningObjectForSigning,
} from '../../utils/pdftron';
import useRecipientRole from '../useRecipientRole';
import useWindowSize from '../useWindowSize';

import { useTranslation } from 'react-i18next';
import { encryptAndUploadFileToDSS, getDSSExpirationFromBpExpiration } from '../../utils/dss';
import { blobToBase64 } from '../../utils/base64';
import { isCheckbox } from '../../utils/checkboxWidget';
import { BlackTick } from '../../assets/black-tick';
import { addFieldsToPdf } from '../../lib/api';
import { featureNameMap, useFeatureFlag } from '../useFeatureFlag';
import { initialiseAttachment } from '../../redux/slices/attachmentSlice';

const DedocoSigningContext = createContext({});

const DedocoSigningProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { id, uid, key } = useParams();
  const { hash } = useLocation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { isSigner, isObserver, isApprover, RecipientRoles } = useRecipientRole();
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  const fileShareKey = useMemo(() => generateFileShareKey(key, hash.slice(1)), [key, hash]);

  const [initialDocHash, setInitialDocHash] = useState();
  const [isUsingUpdatedDocument, setIsUsingUpdatedDocument] = useState(false);
  const [signerInputs, setSignerInputs] = useState([]);

  // TEMPORARY FIX, REMOVE AFTER BUILDER APP CHECK IS IMPLEMENTED
  const assumeAllBadSigningIsBadPdf = useFeatureFlag(featureNameMap.assumeAllBadSigningIsBadPdf);

  const documentToSign = useSelector((state) => state.documenttosign);
  const signatureToSave = useSelector((state) => state.signatureToSave);
  const businessProcess = useMemo(
    () => convertObjectKeyToCamelCase(documentToSign?.data?.business_process),
    [documentToSign?.data?.business_process],
  );

  const allSigners = useMemo(
    () => businessProcess?.signers?.map((signer) => convertObjectKeyToCamelCase(signer)),
    [businessProcess?.signers],
  );

  const allObservers = useMemo(
    () => businessProcess?.observers?.map((observer) => convertObjectKeyToCamelCase(observer)),
    [businessProcess?.observers],
  );

  const allApprovers = useMemo(
    () => businessProcess?.approvers?.map((approver) => convertObjectKeyToCamelCase(approver)),
    [businessProcess?.approvers],
  );

  const auth = useSelector((state) => state.Auth);
  const { isMeetingHost: isWebexMeetingHost, isWebexSigning, completeSigning } = useWebexContext();
  const isDocHashCheckBeforeLoadEnabled = useFeatureFlag(featureNameMap.docHashCheckBeforeLoad);

  const [currentParticipant, setCurrentParticipant] = useState();
  const [projectSigners, setProjectSigners] = useState([]);
  const [projectObservers, setProjectObservers] = useState([]);
  const [projectApprovers, setProjectApprovers] = useState([]);
  const [currentField, _setCurrentField] = useState({
    isFilling: false,
    fieldId: null,
    isUsingSidebar: true,
  });

  const [annotsLoaded, setAnnotsLoaded] = useState(false);
  const [isDigitalSigning, setIsDigitalSigning] = useState(false);

  useEffect(() => {
    if (documentToSign.isOpenApiV2 && documentToSign.data.dss_key && !key) {
      // when open api v2 and got dss key, redirect to v2/openapi/signer/bpid/userId/dsskey

      let currentPathname = url;
      if (currentPathname.endsWith('/')) {
        currentPathname = currentPathname.substring(0, currentPathname.length - 1);
      }

      history.push(`${currentPathname}/${documentToSign.data.dss_key}`);
    }
  }, [documentToSign.data.dss_key, documentToSign.isOpenApiV2, history, key, url]);

  useEffect(() => {
    dispatch(getIp());

    if (isWebexMeetingHost && isObserver) return;

    // openapi v2 alr get docuement to sign data
    if (
      documentToSign.isOpenApiV2 &&
      documentToSign.data.dss_key &&
      location.pathname.startsWith('/v2')
    )
      return;

    if (!documentToSign.isOpenApiV2 && documentToSign?.data?.document_name) return;

    // get document to sign for Dedoco Signing
    if (id && uid) {
      if (isSigner) {
        dispatch(getDocumentToSign(id, uid, undefined, documentToSign.isOpenApiV2));
      } else if (isObserver) {
        dispatch(getObserverDetails(id, uid, undefined, documentToSign.isOpenApiV2));
      } else if (isApprover) {
        dispatch(getApproverDetails(id, uid, undefined, documentToSign.isOpenApiV2));
      }
    }
  }, [
    dispatch,
    documentToSign?.data?.document_name,
    documentToSign.data.dss_key,
    documentToSign.isOpenApiV2,
    id,
    isApprover,
    isObserver,
    isSigner,
    isWebexMeetingHost,
    uid,
  ]);

  useEffect(() => {
    let signerOrApprover = documentToSign?.document?.signers?.find((signer) => signer.id === uid);
    if (!signerOrApprover)
      signerOrApprover = documentToSign?.document?.approvers?.find(
        (approver) => approver.approver_id === uid,
      );
    if (!signerOrApprover) return;
    dispatch(initialiseAttachment(signerOrApprover.attachments));
  }, [documentToSign?.document?.signers, documentToSign?.document?.approvers, uid, dispatch]);

  useEffect(() => {
    if (documentToSign?.document && documentToSign?.isloaded === true) {
      setProjectSigners(allSigners);
      setProjectObservers(allObservers);
      setProjectApprovers(allApprovers);
    }
  }, [documentToSign, allSigners, allObservers, allApprovers]);

  useEffect(() => {
    // When there is no signers, default to digital signing to prevent unexpected PDF flattening
    setIsDigitalSigning(documentToSign.data?.isEmbeddingDigSig || allSigners?.length === 0);
  }, [documentToSign.data?.isEmbeddingDigSig, allSigners?.length]);

  useEffect(() => {
    let participant;
    if (isSigner) {
      participant = {
        ...projectSigners?.find((signer) => signer.id === uid),
        role: RecipientRoles.SIGNER,
      };
    } else if (isObserver) {
      participant = {
        ...projectObservers?.find((observer) => observer.id === uid),
        role: RecipientRoles.OBSERVER,
      };
    } else if (isApprover) {
      participant = {
        ...projectApprovers?.find((approver) => approver.approverId === uid),
        role: RecipientRoles.APPROVER,
      };
    }
    setCurrentParticipant(convertObjectKeyToCamelCase(participant));
  }, [
    uid,
    isSigner,
    isApprover,
    isObserver,
    projectSigners,
    projectApprovers,
    projectObservers,
    RecipientRoles.SIGNER,
    RecipientRoles.APPROVER,
    RecipientRoles.OBSERVER,
  ]);

  useEffect(() => {
    if (currentParticipant?.termsAndConditionsConsentTimestamp) dispatch({ type: ACCEPT_TNC });
  }, [dispatch, currentParticipant?.termsAndConditionsConsentTimestamp]);

  const loadDocument = useCallback(async () => {
    let final = '';
    if (
      documentToSign?.document?.document_share_method === 'manual' &&
      documentToSign?.isDHCVerified
    ) {
      final = documentToSign.fileUrl;
      dispatch(savePdfBase64String(final.replace('data:application/pdf;base64,', '')));
    } else {
      final = await downloadFileFromDSS(fileShareKey);
      dispatch(savePdfBase64String(final));
      final = `data:application/pdf;base64,${final}`;
    }
    const fileURL = final;
    const existingDocHashes = documentToSign.document_hashes;
    const hashedDocument = getBase64FileHash(fileURL);

    setIsUsingUpdatedDocument(existingDocHashes[0] !== hashedDocument);
    dispatch(
      setIsValidDocHash(
        existingDocHashes[existingDocHashes.length - 1] === hashedDocument ||
          !isDocHashCheckBeforeLoadEnabled,
      ),
    );
    setInitialDocHash(hashedDocument);
  }, [
    dispatch,
    fileShareKey,
    documentToSign.isDHCVerified,
    documentToSign?.document?.document_share_method,
    documentToSign.fileUrl,
    isDocHashCheckBeforeLoadEnabled,
    documentToSign.document_hashes,
  ]);

  const setCurrentField = useCallback((fieldId, isFilling, isUsingSidebar = true) => {
    _setCurrentField({ fieldId, isFilling, isUsingSidebar });
  }, []);

  const resetCurrentField = useCallback(() => {
    setCurrentField(null, false, true);
  }, [setCurrentField]);

  const updateSignerInputs = useCallback(({ dataName, value, annotMetadata }) => {
    setSignerInputs((prevData) => {
      const copy = [...prevData];
      const i = copy.findIndex((data) => data.name === dataName);
      if (i !== -1) {
        copy[i].value = value;
      } else {
        copy.push({
          index: getAnnotInfoFromFieldId(dataName).index,
          name: dataName,
          value,
          annotMetadata,
        });
      }
      return copy;
    });
  }, []);

  const deleteSignerInputs = useCallback((dataName) => {
    setSignerInputs((prevData) => prevData.filter((x) => x.name !== dataName));
  }, []);

  const approveDocument = useCallback(
    async (instance) => {
      let finalDocHash = initialDocHash;
      let dssKey = fileShareKey; // to be passed to approve call later

      // Approving does not change the content of the document
      // Digital signature projects documents have signer inputs embedded into the document, therefore, dssKey and docHash will not change during approval.
      // For non-digital signing projects, the document does not have the signer's input, therefore, the document needs to be extracted from WebViewer, hence, dssKey and finalDocHash should change.
      if (!isDigitalSigning) {
        const pdfDataUrl = await getPdfWithAnnotsAsDataUrl(instance);

        if (documentToSign?.data?.isLastActor) {
          const pdfBase64 = pdfDataUrl.split(',')[1];
          finalDocHash = getBase64FileHash(pdfBase64);
          dssKey = await encryptAndUploadFileToDSS(
            pdfBase64,
            {
              genericErrorHandler: (err) => {
                console.error(err);
              },
            },
            { expiresAt: getDSSExpirationFromBpExpiration(businessProcess.expirationTime) },
          );
        }
      }

      try {
        await dispatch(
          _approveDocument(
            {
              initialDocHash,
              dssKey,
              finalDocHash,
            },
            businessProcess?.id,
            uid,
            documentToSign.isOpenApiV2,
          ),
        );

        if (isWebexSigning) {
          completeSigning(dssKey);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      initialDocHash,
      fileShareKey,
      isDigitalSigning,
      documentToSign?.data?.isLastActor,
      documentToSign.isOpenApiV2,
      businessProcess.expirationTime,
      businessProcess?.id,
      dispatch,
      uid,
      isWebexSigning,
      completeSigning,
    ],
  );

  const rejectDocument = useCallback(
    async (remark) => {
      dispatch(
        declineDocument(
          {
            initialDocHash,
            dssKey: fileShareKey,
            finalDocHash: initialDocHash,
            remark: remark,
          },
          businessProcess.id,
          uid,
          documentToSign.isOpenApiV2,
        ),
      );
    },
    [businessProcess.id, dispatch, documentToSign.isOpenApiV2, fileShareKey, initialDocHash, uid],
  );

  const saveSigningDetails = useCallback(
    async (finalDocHash, dssKey, ndiSignature) => {
      const { signatures, customTexts, confirmations } = await prepareSigningObjectForSigning({
        ndiSignature,
        signData: currentParticipant,
        signerInputs,
      });

      try {
        await dispatch(
          newSignDocument(
            {
              initialDocHash,
              finalDocHash,
              dssKey,
              signatures,
              customTexts,
              confirmations,
              signerId: currentParticipant.id,
              businessProcessId: businessProcess.id,
            },
            businessProcess.id,
            uid,
            documentToSign.isOpenApiV2,
          ),
        );
      } catch (err) {
        if (err.status === 401) {
          throw t('SessionExpired');
        }
        throw t('SigningError');
      }
    },
    [
      businessProcess.id,
      currentParticipant,
      dispatch,
      documentToSign.isOpenApiV2,
      initialDocHash,
      signerInputs,
      t,
      uid,
    ],
  );

  const getDocumentWithFields = useCallback(
    async (...args) => {
      return blobToBase64(
        await addFieldsToPdf(...args, {
          businessProcessId: businessProcess.id,
          signerId: currentParticipant.id,
        }),
      );
    },
    [businessProcess?.id, currentParticipant?.id],
  );

  const embedDocWithDigitalSignatures = useCallback(
    async (instance, isUsingEsig) => {
      const pdfBeforeSigning = documentToSign.pdfBase64String;
      const fieldDetails = [];
      signerInputs.forEach((details) => {
        if (!details.value || typeof details.value !== 'string') return;
        const { type, ...metadata } = details.annotMetadata;
        if (type === 'image') {
          // Extract base64 from data url
          if (isCheckbox(details.name)) {
            if (details.value === 'On') {
              metadata.image = BlackTick;
            } else {
              return;
            }
          } else {
            const parts = details.value.split(',');
            metadata.image = parts[parts.length - 1];
          }
        } else if (type === 'text') {
          metadata.text = details.value;
        }

        fieldDetails.push(metadata);
      });

      if (fieldDetails.length === 0) {
        return pdfBeforeSigning;
      } else {
        try {
          return await getDocumentWithFields(pdfBeforeSigning, fieldDetails, isUsingEsig);
        } catch (e) {
          // Catch 4xx errors only to prevent unnecessary flattening when other error occurs.
          // The assumption here is that all 4xx errors are due to PDF errors.
          if (assumeAllBadSigningIsBadPdf && e?.status === 400) {
            const flattenedUnsignedDocRaw = await instance.Core.documentViewer
              .getDocument()
              .getFileData({ flatten: true });
            const blob = new Blob([new Uint8Array(flattenedUnsignedDocRaw)], {
              type: 'application/pdf',
            });
            const flattenedUnsignedDoc = await blobToBase64(blob);

            return await getDocumentWithFields(flattenedUnsignedDoc, fieldDetails, isUsingEsig);
          } else {
            throw e;
          }
        }
      }
    },
    [
      documentToSign.pdfBase64String,
      signerInputs,
      assumeAllBadSigningIsBadPdf,
      getDocumentWithFields,
    ],
  );

  const signDocumentDigitally = useCallback(
    async (instance) => {
      const signedDocument = await embedDocWithDigitalSignatures(instance, true); // Esig flow

      const finalDocHash = getBase64FileHash(signedDocument);

      const shareKey = await encryptAndUploadFileToDSS(
        signedDocument,
        {
          timeoutErrorHandler: () => {
            throw t('errSignatureRequestErrorUploadSmallerSize');
          },
          genericErrorHandler: () => {
            throw t('errSignatureRequestRefreshContactSupport');
          },
        },
        { expiresAt: getDSSExpirationFromBpExpiration(businessProcess.expirationTime) },
      );

      await saveSigningDetails(finalDocHash, shareKey);

      dispatch(savePdfBase64String(signedDocument));

      return { shareKey };
    },
    [
      dispatch,
      saveSigningDetails,
      embedDocWithDigitalSignatures,
      t,
      businessProcess.expirationTime,
    ],
  );

  const signDocument = useCallback(
    async (instance) => {
      const pdfDataUrl = await getPdfWithAnnotsAsDataUrl(instance);
      const pdfBase64 = pdfDataUrl.split(',')[1];
      let finalDocHash = initialDocHash;
      let shareKey = fileShareKey;

      if (documentToSign?.data?.isLastActor) {
        // PDF in DSS must have the same hash as the final PDF hash recorded in DB
        finalDocHash = getBase64FileHash(pdfBase64);
        shareKey = await encryptAndUploadFileToDSS(
          pdfBase64,
          {
            timeoutErrorHandler: () => {
              throw t('errSignatureRequestErrorUploadSmallerSize');
            },
            genericErrorHandler: () => {
              throw t('errSignatureRequestRefreshContactSupport');
            },
          },
          { expiresAt: getDSSExpirationFromBpExpiration(businessProcess.expirationTime) },
        );
      }

      await saveSigningDetails(finalDocHash, shareKey);

      if (isWebexSigning) {
        completeSigning(shareKey);
      } else {
        dispatch(savePdfBase64String(pdfBase64));
      }
      return { shareKey };
    },
    [
      completeSigning,
      dispatch,
      documentToSign?.data?.isLastActor,
      fileShareKey,
      initialDocHash,
      isWebexSigning,
      saveSigningDetails,
      t,
      businessProcess.expirationTime,
    ],
  );

  const signDocumentWithNdi = useCallback(
    async (ndiSignature, pdfDataUrl) => {
      const pdfBase64 = pdfDataUrl.split(',')[1];
      const finalDocHash = getBase64FileHash(pdfBase64);
      const dssKey = await encryptAndUploadFileToDSS(pdfBase64, null, {
        expiresAt: businessProcess.expirationTime,
      });

      await saveSigningDetails(finalDocHash, dssKey, ndiSignature);

      dispatch(savePdfBase64String(pdfBase64));

      return { dssKey };
    },
    [dispatch, saveSigningDetails, businessProcess.expirationTime],
  );

  const getCurrentSignerIndex = useCallback(
    () => projectSigners.findIndex((signer) => signer.id === currentParticipant.id),
    [projectSigners, currentParticipant],
  );

  const contextValue = useMemo(
    () => ({
      uid,
      documentToSign,
      businessProcess,
      auth,
      currentParticipant,
      setCurrentParticipant,
      projectSigners,
      projectApprovers,
      projectObservers,
      getCurrentSignerIndex,
      currentField,
      setCurrentField,
      resetCurrentField,
      signerInputs,
      fileShareKey,
      approveDocument,
      rejectDocument,
      loadDocument,
      initialDocHash,
      signatureToSave,
      windowSize,
      updateSignerInputs,
      deleteSignerInputs,
      signDocument,
      embedDocWithDigitalSignatures,
      signDocumentDigitally,
      signDocumentWithNdi,
      setAnnotsLoaded,
      annotsLoaded,
      isDigitalSigning,
      isUsingUpdatedDocument,
    }),
    [
      approveDocument,
      auth,
      businessProcess,
      currentField,
      setCurrentField,
      resetCurrentField,
      currentParticipant,
      documentToSign,
      fileShareKey,
      initialDocHash,
      loadDocument,
      projectApprovers,
      projectObservers,
      projectSigners,
      getCurrentSignerIndex,
      rejectDocument,
      updateSignerInputs,
      deleteSignerInputs,
      signDocument,
      embedDocWithDigitalSignatures,
      signDocumentDigitally,
      signDocumentWithNdi,
      signatureToSave,
      signerInputs,
      uid,
      windowSize,
      setAnnotsLoaded,
      annotsLoaded,
      isDigitalSigning,
      isUsingUpdatedDocument,
    ],
  );

  return (
    <DedocoSigningContext.Provider value={contextValue}>{children}</DedocoSigningContext.Provider>
  );
};

function useDedocoSigning() {
  return useContext(DedocoSigningContext);
}

// eslint-disable-next-line react/display-name
const withDedocoSigning = (WrappedComponent) => (props) => {
  return (
    <DedocoSigningProvider>
      <WrappedComponent {...props} />
    </DedocoSigningProvider>
  );
};

export { DedocoSigningProvider, useDedocoSigning, withDedocoSigning };
