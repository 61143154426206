import { dedocoAxios } from '../../../lib/api/axios';

export const getDocumentToSignApi = ({ id, uid, secret }) => {
  return dedocoAxios.get(`/businessprocess/signature/getRequest`, {
    params: {
      document_id: id,
      signer_id: uid,
      secret,
    },
  });
};

export const getObserverDetailsApi = ({ id, uid, secret }) => {
  return dedocoAxios.get(`/businessprocess/signature/getRequest`, {
    params: {
      document_id: id,
      observer_id: uid,
      secret,
    },
  });
};

export const getApproverDetailsApi = ({ id, uid, secret }) => {
  return dedocoAxios.get(`/businessprocess/signature/getRequest`, {
    params: {
      document_id: id,
      approver_id: uid,
      secret,
    },
  });
};

export const approveDocumentApi = ({ data, id, uid }) => {
  return dedocoAxios.post(`/businessprocess/${id}/approvers/${uid}/approve`, {
    ...data,
    approve: true,
  });
};

export const declineDocumentApi = ({ data, id, uid }) => {
  return dedocoAxios.post(`/businessprocess/${id}/approvers/${uid}/approve`, {
    ...data,
    approve: false,
  });
};

export const signDocumentApi = ({ data, id, uid }) => {
  return dedocoAxios.post(`/businessprocess/${id}/signers/${uid}/sign`, data);
};

export const getFolderNameApi = (id) => {
  return dedocoAxios.get(`/folder/${id}/name`);
};
