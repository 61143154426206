import { useRef } from 'react';

class TaskNode {
  constructor(taskFunc) {
    if (typeof taskFunc !== 'function') {
      throw new Error('taskFunc must be a function');
    }
    this.taskFunc = taskFunc;
    this.metadata = {};
    this.next = null;
  }
}

class TaskQueue {
  #currTask;
  #lastTask;
  #taskIterator;

  constructor() {
    this.#currTask = null;
    this.#lastTask = null;
    this.#taskIterator = null;
  }

  get currTask() {
    return this.#currTask;
  }

  get lastTask() {
    return this.#lastTask;
  }

  async executeTask(taskFunc, options) {
    if (this.#currTask) {
      this.#lastTask.next = new TaskNode(taskFunc);
      this.#lastTask = this.#lastTask.next;
    } else {
      this.#currTask = new TaskNode(taskFunc);
      this.#lastTask = this.#currTask;
      this.#taskIterator = this.#executeTasks();
      this.#taskIterator.next();
    }

    await new Promise((resolve) => {
      this.#lastTask.metadata = {
        waitUntilQueueClear: options?.waitUntilQueueClear,
        promiseResolve: resolve,
      };
    });
  }

  *#executeTasks() {
    const queueClearPromiseResolves = [];

    while (this.#currTask) {
      /* Push task execution and loop continuation calls to the macrotask queue to allow tasks in the microtasks queue to execute first.
       * This is important because we want the code execution in the parent scope of this class' instance to continue
       *		right after the promise that blocks "executeTask" resolves, before the next task is executed.
       *
       * After the promise that blocks "executeTask" resolves,
       * 		the series of callbacks that leads to the continuation of code execution in the parent scope of this class' instance is added to the microtask queue.
       * Therefore, by adding the execution of next task into the macrotask queue, we can guarantee that the parent scope's code will continue first.
       *
       * The actual order of execution for each task in not important because this task queue is meant to be async.
       * Caller can choose to await on tasks to guarantee order of execution if required.
       */
      yield setTimeout(async () => {
        await this.#currTask.taskFunc();
        this.#taskIterator.next();
      });

      const taskMetadata = this.#currTask.metadata;

      if (!taskMetadata.waitUntilQueueClear) {
        taskMetadata.promiseResolve();
      } else {
        queueClearPromiseResolves.push(taskMetadata.promiseResolve);
      }

      this.#currTask = this.#currTask.next;
    }

    this.#lastTask = null;
    queueClearPromiseResolves.forEach((resolve) => resolve());
  }
}

export function useTaskQueue() {
  const queueRef = useRef();

  if (!queueRef.current) {
    queueRef.current = new TaskQueue();
  }

  return queueRef.current;
}
