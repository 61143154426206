import { Backdrop, styled } from '@mui/material';

export const Root = styled('div')(() => ({
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
  overflow: 'visible',
  backgroundColor: 'transparent',
  height: 'auto',
  width: 'fit-content',
  position: 'fixed',
  visibility: 'hidden',
  zIndex: 9999,
}));

export const ClickAwayBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'transparent',
  zIndex: 9997,
}));
