import parsePhoneNumber from 'libphonenumber-js';

export const ellipsis = (s) => {
  if (!s) return '';

  // email
  if (/^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+$/.test(s)) {
    const emailTwoParts = s.split('@');
    const domainParts = emailTwoParts[1].split('.');

    return `${ellipsis(emailTwoParts[0])}@${ellipsis(domainParts[0])}.${domainParts[1]}`;
  }

  // phone number
  if (/^\+\d+$/.test(s)) {
    const phoneNumber = parsePhoneNumber(s);
    const lastFour = s.substring(s.length - 4, s.length);
    return `(+${phoneNumber.countryCallingCode})****${lastFour}`;
  }

  return `${s.substring(0, 1)}****${s.substring(s.length - 1)}`;
};
