import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classes, EmailBoxRoot } from './EmailBox.style';
import errorIcon from '../../../../assets/error-icon.svg';

const EmailPad = ({ value, handleValueChange, error, style, inputRef }) => {
  const { t } = useTranslation();

  const handleOnChange = (e) => {
    handleValueChange?.(e.target.value);
  };

  return (
    <EmailBoxRoot sx={style}>
      <Grid className={error ? classes.innerError : classes.inner}>
        <TextField
          className={classes.textField}
          id="email-input"
          label=""
          value={value}
          variant="standard"
          onChange={handleOnChange}
          // Set attribute for the inner <input/> or <textarea/> element
          inputProps={{
            'data-testid': 'sidebar-email-input',
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={t('EmailPlaceholder')}
          maxRows={4}
          multiline
          inputRef={inputRef}
          error={error}
        />
      </Grid>
      {error && (
        <div className={classes.errorMessage}>
          <img src={errorIcon} alt="Error Icon" className={classes.errorIcon} />
          <Typography className={classes.errorText}>{t('InvalidEmailError')}</Typography>
        </div>
      )}
    </EmailBoxRoot>
  );
};

export default EmailPad;
