import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingCloseButton } from '../FloatingCloseButton';
import { CustomTab, CustomTabPanel, CustomTabs, NextButton, Root } from './SignatureInput.style';
import SigningPad from './SigningPad';
import UploadPad from './UploadPad';

export const SignatureInputTypes = Object.freeze({
  draw: 'draw',
  type: 'type',
  upload: 'upload',
});

function SignatureInputComponent(
  {
    inputId,
    onConfirmInput,
    onClose,
    tabsToShow,
    showApplyToAllCheckbox,
    title,
    submitButtonText,
    maxFileSize = 26214400,
  },
  ref,
) {
  const { t } = useTranslation();
  const SIGNATURE_TAB_TITLE = {
    [SignatureInputTypes.draw]: t('SignatureInputDrawTabTitle'),
    [SignatureInputTypes.upload]: t('SignatureInputUploadTabTitle'),
    [SignatureInputTypes.type]: t('SignatureInputTypeTabTitle'),
  };
  const PEN_COLOR_PALETTE = ['#1A1A34', '#135CCA', '#CA1313'];

  const [value, setValue] = useState('');
  const [applySignatureToAll, setApplySignatureToAll] = useState(false);
  const [textColor, setTextColor] = useState(PEN_COLOR_PALETTE[0]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTab(0);
  }, []);

  const handleValueChange = useCallback((value) => {
    setValue(value);
  }, []);

  const confirmInput = useCallback(() => {
    onConfirmInput(inputId, value, {
      applySignatureToAll,
      isFile: tabsToShow[tab] !== SignatureInputTypes.type,
      isSignature: true,
      textColor,
    });
  }, [inputId, value, onConfirmInput, applySignatureToAll, tab, tabsToShow, textColor]);

  const closeInput = useCallback(() => {
    onClose(inputId);
    setTab(0);
    setApplySignatureToAll(false);
    confirmInput();
  }, [inputId, onClose, confirmInput]);

  const handleTabChange = useCallback((_, newValue) => {
    setTab(newValue);
    setValue('');
  }, []);

  const applyToAll = useCallback((event) => {
    setApplySignatureToAll(event.target.checked);
  }, []);

  useImperativeHandle(ref, () => ({ closeInput }), [closeInput]);

  return (
    <Root>
      <FloatingCloseButton onClick={closeInput} />
      <Stack gap={2} width="100%">
        <Typography variant="title_medium" align="left">
          {title}
        </Typography>
        <Stack width="100%" height="100%" alignItems="center">
          <Box width="100%">
            <CustomTabs
              value={tab}
              onChange={handleTabChange}
              aria-label="form signing method tabs"
            >
              {tabsToShow?.map((type) => (
                <CustomTab
                  key={`tab-${type}`}
                  label={SIGNATURE_TAB_TITLE[type]}
                  aria-controls={`sign-method-${type}`}
                />
              ))}
            </CustomTabs>
          </Box>
          {tabsToShow?.map((type, index) => (
            <CustomTabPanel key={`sign-method-${type}`} value={tab} index={index}>
              {type === SignatureInputTypes.draw && (
                <SigningPad
                  handleValueChange={handleValueChange}
                  textColor={textColor}
                  setTextColor={setTextColor}
                  type={type}
                />
              )}
              {type === SignatureInputTypes.upload && (
                <UploadPad handleValueChange={handleValueChange} maxFileSize={maxFileSize} />
              )}
              {type === SignatureInputTypes.type && (
                <SigningPad
                  handleValueChange={handleValueChange}
                  type={type}
                  textColor={textColor}
                  setTextColor={setTextColor}
                />
              )}
            </CustomTabPanel>
          ))}
        </Stack>
        {showApplyToAllCheckbox && (
          <Box display="flex" direction="row" alignItems="center">
            <Checkbox checked={applySignatureToAll} onChange={applyToAll} />
            <Typography variant="body_medium">
              {t('SignatureInputApplyESignatureToAllLabel')}
            </Typography>
          </Box>
        )}
        <NextButton variant="contained" color="primary" onClick={confirmInput}>
          {submitButtonText}
        </NextButton>
      </Stack>
    </Root>
  );
}

export const SignatureInput = forwardRef(SignatureInputComponent);
