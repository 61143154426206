export const TIMEOUT_ERROR_ERROR = new Error('Timeout');

export default function retry(func, maxAttempts, timeout = 5000, currentAttempt = 1) {
  // Create a promise that resolves after the timeout duration
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(TIMEOUT_ERROR_ERROR);
    }, timeout);
  });

  return Promise.race([func(), timeoutPromise]).catch((error) => {
    // If the maximum number of attempts has been reached, reject with the last error
    if (currentAttempt > maxAttempts) {
      throw error;
    }
    console.log('retry', currentAttempt);
    // Retry connection with incremented attempt count
    return retry(func, maxAttempts, timeout, currentAttempt + 1);
  });
}
