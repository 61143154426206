import { getDataURLFileSizeInBytes } from './base64';
import { getFontSize } from './pdftronWidgetCommon';

export function rotateImageClockwise(src, rotateDegree) {
  return new Promise((resolve, reject) => {
    try {
      const img = document.createElement('img');
      img.src = src;
      img.onload = function () {
        if (rotateDegree < 0) {
          rotateDegree += 360;
        }
        const sideways = rotateDegree === 90 || rotateDegree === 270;
        const width = sideways ? img.naturalHeight : img.naturalWidth;
        const height = sideways ? img.naturalWidth : img.naturalHeight;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.style.position = 'absolute';
        const ctx = canvas.getContext('2d');
        ctx.translate(width / 2, height / 2);
        ctx.rotate(rotateDegree * (Math.PI / 180));
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        resolve(canvas.toDataURL());
      };
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export const getImageDimensions = (file) => {
  return new Promise(function (resolved, _rejected) {
    const i = new Image();
    i.onload = function () {
      resolved({ width: i.width, height: i.height });
    };
    i.src = file;
  });
};

export const resizeImage = (imgDataUrl, targetImgSize) => {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = function () {
        /* Explanation for resizing
         * ========================
         * Basics
         * ======
         * aspectRatio = imageWidth / imageHeight
         *
         * numOfPixels
         * = imageWidth * imageHeight
         * = aspectRatio * imageHeight * imageHeight
         * = aspectRatio * imageHeight^2
         *
         * bitPerPixel = n where n is a constant
         *
         * imageFileSize (in bit)
         * = numOfPixels * bitPerPixel
         * = aspectRatio * imageHeight^2 * bitPerPixel
         *
         * Getting new dimensions while retaining aspect ratio of the image
         * ================================================================
         *
         * newImageFileSize    aspectRatio * newImageHeight^2 * bitPerPixel
         * ----------------- = -------------------------------------------
         * oldImageFileSize    aspectRatio * oldImageHeight^2 * bitPerPixel
         *
         * aspectRatio and bitPerPixel are not affected by the resizing.
         *
         * Therefore,
         *
         * newImageFileSize    newImageHeight^2
         * ----------------- = ----------------
         * oldImageFileSize    oldImageHeight^2
         *
         * From this, solve for newImageHeight and calculate newImageWidth using the aspectRatio
         * Note that units of file size does not matter in this case.
         */

        const imgType = imgDataUrl.substring(imgDataUrl.indexOf(':') + 1, imgDataUrl.indexOf(';'));
        const estimatedImgSize = getDataURLFileSizeInBytes(imgDataUrl);
        const aspectRatio = this.naturalWidth / this.naturalHeight;
        const newImgHeight = Math.sqrt(
          (targetImgSize / estimatedImgSize) * Math.pow(this.naturalHeight, 2),
        );
        const newImgWidth = newImgHeight * aspectRatio;

        // Resizing using canvas does not guarantee the target size.
        // New image file size may exceed target image size.
        const canvas = document.createElement('canvas');
        canvas.height = newImgHeight;
        canvas.width = newImgWidth;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(this, 0, 0, newImgWidth, newImgHeight);
        resolve(canvas.toDataURL(imgType));
      };
      img.src = imgDataUrl;
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

export const convertTextToImage = async (text, annot) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const fontSize = getFontSize(text.length);
  const fontFamily = 'HCo Gotham';
  const color = 'black';
  const textAlign = 'center';
  const textBaseline = 'top';

  canvas.width = annot.Width;
  canvas.height = annot.Height;

  const x = canvas.width / 2;
  const y = canvas.height / 3;
  ctx.moveTo(x, y);
  ctx.beginPath();
  ctx.font = fontSize + ' ' + fontFamily;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.fillStyle = color;
  ctx.fillText(text, x, y);
  ctx.stroke();
  const dataUrl = canvas.toDataURL();
  return dataUrl;
};
