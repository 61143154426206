import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Modal,
  Checkbox,
  AppBar,
  Drawer,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  Snackbar,
  Badge,
  Radio,
  Tooltip,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { isIOS, isMobile, isMobileOnly } from 'react-device-detect';
import {
  Close as CancelIcon,
  Check as CheckIcon,
  InfoOutlined,
  CheckBoxOutlined,
} from '@mui/icons-material';
import MuiAlert from '@mui/lab/Alert';
import clsx from 'clsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FileDrop } from 'react-file-drop';
import SignatureCanvas from 'react-signature-canvas';
import PdfViewer from '../DocumentViewer/DocumentViewer';
import DatePicker from 'react-datepicker';
import Popup from 'reactjs-popup';
import { useTimer } from 'react-timer-hook';
import { QRCode } from 'react-qrcode-logo';
import { renderPDF } from '../RenderPDF';
import { renderCurrentPDF } from '../RenderPDF/renderCurrentPdf';
import { autoScale } from '../../utils/signatureplacementFuncitons';
import { setZoom } from '../../redux/actions/pdfviewerfunctions';
import MenuIcon from '@mui/icons-material/Menu';
import pen from '../../assets/pen-tool.png';
import Signature from '../../assets/signature_en.png';
import SignatureJp from '../../assets/signature_jp.png';
import StampIcon from '../../assets/stamp2.png';
import tick from '../../assets/tick.png';
import sigbox from '../../assets/signature-box-without-username.png';
import Textt from '../../assets/textt.png';
import File from '../../assets/file.png';
import Calendar from '../../assets/calendar.png';
import { getStringHash, getBase64FileHash } from '../../utils/hash';
import { setPage } from '../../redux/actions/pdfviewerfunctions';
import { uploadFileToDSS } from '../../lib/api/dss';
import {
  getPublicSignRef,
  getPublicChallengeCode,
  getPublicNdiSignature,
  publicSendDocHash,
  prepareITextPDF,
  completeITextPDF,
  getPublicNdiSessId,
  publicUpdateDocHashNdi,
  getNDISessId,
  publicSignDoc,
} from '../../lib/api';
import {
  signingErrorMessage,
  transitionMessage,
  showNDISpinner,
  iTextErrorMessage,
  fileShareErrorMessage,
  nricMismatchErrorMessage,
} from '../Signingrequest/ndiSigningFunctions';
import { generateFileShareKey } from '../../utils/fileShareKey';
import '../../Responsive.css';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../Signingrequest/SigningRequest.css';
import {
  signDocument,
  _approveDocument as approveDocument,
  declineDocument,
} from '../../redux/actions/documentfunctions';
import {
  SAVEMYSIGNATURE,
  LEAVE_MEETING,
  MARK_DOCUMENT_AS_SIGNED,
} from '../../redux/actions/actiontypes';
import download from 'downloadjs';
import InitialPopUp from '../DigitalSignPopup/InitialPopUp';
import QrCodePopUp from '../DigitalSignPopup/QrCodePopUp';
import SuccessPopUp from '../DigitalSignPopup/SuccessPopUp';
import singPassFrame from '../../assets/Singpass QR frame.svg';
import transitionImage from '../../assets/Singpass App illustration.svg';
import useUnsavedChangesWarning from '../../utils/unsavedChanges';
import { publicRenderPDF } from '../PublicRenderPDF';
import observerIcon from '../../assets/observer.png';
import { saveSignature } from '../../redux/actions/signature';
import CompareDocument from '../Signingrequest/CompareDocument';
import LoadingSpinner from '../Helpers/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import CustomCheckBox from '../Signingrequest/Checkbox';
import DownloadIcon from '../../assets/download.svg';
import { useFeatureFlag, featureNameMap } from '../../hooks/useFeatureFlag';

import { getInitals } from '../../utils/GetInititals';
import { animateCSS } from '../../utils/animateCSS';

import DeclineDocumentDialog from '../Modal/DeclineDocument/DeclineDocument';
import DownloadPdfDialog from '../Modal/DownloadPdf/DownloadPdf';
import { ChallengeCodeHeaders, RefreshQRButton } from '../../hooks/useNdiSigning/components';
import { useWindowResize } from '../../hooks/useWindowResize';

import { PUBLIC_SIGNER_SIGNED } from '../../utils/GlobalConstant';
import { startRecording, stopRecording } from '../../redux/actions/meetingFunctions';
import JoinMeeting from '../Signingrequest/Meeting/JoinMeeting';
import Room from '../Signingrequest/Meeting/Room';
import { TermsAndConditionsCheckbox } from '../TermsAndConditionsInputs/TermsAndConditionsCheckbox';
import { useHistory } from 'react-router-dom';

const PREFIX = 'SigningRequestOpenApi';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  arrow: `${PREFIX}-arrow`,
  paper: `${PREFIX}-paper`,
  paper2: `${PREFIX}-paper2`,
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerMobile: `${PREFIX}-drawerMobile`,
  content: `${PREFIX}-content`,
  tooltipArrow: `${PREFIX}-tooltipArrow`,
  approveCheckbox: `${PREFIX}-approveCheckbox`,
  meetingLobby: `${PREFIX}-meetingLobby`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    width: '470px',
    borderRadius: '4px',
  },

  [`& .${classes.paper2}`]: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    width: '470px',
    height: '250px',
    borderRadius: '4px',
  },

  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('md')]: {
      width: '25%',
      flexShrink: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: '0%',
      flexShrink: 0,
    },
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginRight: drawerWidth,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.menuButton}`]: {
    position: 'absolute',
    right: '5%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.drawerPaper}`]: {
    width: '25%',
    textAlign: 'left',
    position: 'absolute',
    height: '93.5vh',
    top: '80px',
    zIndex: 998,
  },

  [`& .${classes.drawerMobile}`]: {
    top: '20%',
    textAlign: 'left',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  [`& .${classes.tooltipArrow}`]: {
    color: '#FBE470 !important',
  },

  [`& .${classes.approveCheckbox}`]: {
    [theme.breakpoints.down('xl')]: {
      color: '#19703e',
    },
  },

  [`& .${classes.meetingLobby}`]: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const MobileViewLogo = styled('img')(() => ({
  height: '48px',
}));

const sjcl = require('sjcl');
const drawerWidth = '25%';
const PDF_VIEWER_PADDING_TOP_ON_SMALL_PX = 56;
const MOBILE_VIEW_PLACEMENT_OFFSET_FACTOR = 0.15;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const YellowTooltip = Tooltip;

//(callback for when window size is changed)

function trimCanvas(ctx) {
  var canvas = ctx.canvas;
  if (canvas.width === 0 || canvas.height === 0) {
    return false;
  }
  let copy = document.createElement('canvas').getContext('2d');
  copy.id = 'copy';
  let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let l = pixels.data.length;
  let i;

  let bound = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };
  let x;
  let y;

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % canvas.width;
      y = ~~(i / 4 / canvas.width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }
  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
  ctx.font = " 40px  'Satisfy', cursive";
  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);
  let url = copy.canvas.toDataURL();
  // Return trimmed can
  return url;
}

export default function SigningRequest(props) {
  // Flags
  const openApiVideoSignEnabled = useFeatureFlag(featureNameMap.openApiVideoSign);

  const { bp, uid, documentId } = props;
  const bpId = bp?.id;

  const TnCMessage = bp?.TnC?.text ?? '';

  const { handleDownloadPdf } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const sigpad = useRef({});
  const initialsPad = useRef({});
  const canvaspad = useRef({});
  const initialsCanvasPad = useRef({});
  const leftrack = useRef(null);
  const leftContainer = useRef(null);
  const dataRef = useRef(null);

  const lowerPannel = document.getElementsByClassName('pdf');
  const pdfPage = document.getElementsByClassName('react-pdf__Page__canvas');
  const upperPanel = document.getElementsByClassName('upper-panel');

  const PdfViewerStates = useSelector((state) => state.PdfViewerStates);
  const [_, setPrompt, setPromptNull] = useUnsavedChangesWarning();
  const [affect, affectTo] = useState([]);
  const [sigs, setEsigatures] = useState([]);
  const [ctext, setCustomTexts] = useState([]);
  const [initialsType, setInitialsType] = useState([]);
  const [open, setOpen] = useState(false); // set 'false' when commit
  const [initialsPopup, setInitialsPopUp] = useState(false);
  const [stampPopUp, setStampPopUp] = useState(false);
  const [modalStatus, setModal] = useState('fileupload');
  const [mySignature, setSignature] = useState({ value: '', sigtype: '' });
  const [initials, setInitials] = useState({ value: '', type: '' });
  const [stamp, setStamp] = useState({ value: '', type: '' });
  const [abled, setAbled] = useState(false);
  const [typingSignature, setTypingSignature] = useState('');
  const [typingInitials, setTypingInitials] = useState('');
  const [fileUrl, setUrl] = useState('');
  const [initialsFileUrl, setInitialsFileUrl] = useState('');
  const [stampFileUrl, setStampFileUrl] = useState('');
  const [pdfHeight, setPdfHeight] = useState(0);
  const [pdfWidth, setPdfWidth] = useState(0);
  const [pdfLeft, setPdfLeft] = useState(0);
  const [pdfHeaderHeight, setPdfHeaderHeight] = useState(0);
  //Gary (added digi sigs state)
  const [lenghts, setLength] = useState({
    ctext: 0,
    esigs: 0,
    date: 0,
    digisigs: 0,
    confirmations: 0,
  });
  //Gary added digisigs
  // eslint-disable-next-line no-unused-vars
  const [openTooltipSaveSignature, setOpenTooltipSaveSignature] = useState(false);
  const [savedSignatures, setSavedSignatures] = useState(props.savedSignatures);

  let numSavedSignatures = savedSignatures?.length || 0;

  const [savingSignatureSecret, setSavingSignatureSecret] = useState(props.savingSignatureSecret);
  // eslint-disable-next-line no-unused-vars
  const [checkedSaveSignature, setCheckedSaveSignature] = useState(false);
  const [viewSavedSignature, setViewSaveSignature] = useState(false); // set 'false' when commit
  const [selectedSavedSignature, setSelectedSavedSignature] = useState(null);
  const [checked, setchecked] = useState({
    esigs: false,
    ctext: false,
    digisigs: false,
    isApproved: false,
    confirmations: false,
  });
  const [confirmationPopUp, setConfirmationPopUp] = useState('');
  const [signingConfirmation, setsigningConfirmation] = useState('');
  const [qrData, setQrData] = useState('');
  const [ndiSessionId, setNDISessionId] = useState('');
  const [challenge, setChallengeCode1] = useState('');
  const [qrContent, setQrContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [signingError, setSigningError] = useState(false);
  const [ndiCardPopUp, setNdiCardPopUp] = useState('');
  const [transition, setTransition] = useState('');
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [loading, setLoading] = useState('');
  const [iTextId, setITextId] = useState('');
  const [userCert, setUserCert] = useState('');
  const [ndiDocHash, setNDIDocHash] = useState('');
  const [manualFinalPdf, setManualFinalPdf] = useState('');
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [agreedToTnC, setAgreedToTnC] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitApprove, setIsSubmitApprove] = useState(false);
  const [isSubmitApproveSuccess, setIsSubmitApproveSuccess] = useState(false);
  const [isSubmitDeclineSuccess, setIsSubmitDeclineSuccess] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarDuration, setSnackBarDuration] = useState(3000);
  const [confirmations, setConfirmations] = useState([]);
  const [tab, setTab] = useState({
    value: 'drawing',
    element: '',
  });
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);
  const qrIntervalRef = useRef(null);
  const isUsingNDI = !!props.signatureplacements?.digi_signatures?.length;

  const { seconds, minutes, restart } = useTimer({
    onExpire: () => console.warn('onExpire called'),
  });
  const [highlightComponent, setHighlightComponent] = useState('');

  const remainingSigner = useSelector((state) => state?.documenttosign?.document?.signers).filter(
    (signer) => !signer?.has_signed,
  );

  const { dimensions } = useWindowResize();
  const history = useHistory();
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  // Decline and download document
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [openDownloadPdfModal, setOpenDownloadPdfModal] = useState(false);

  useEffect(() => {
    setPrompt();
  }, [setPrompt]);

  useEffect(() => {
    if (props.isSignCompleted) {
      const donePageUrl = `/public/done/${props.id}/${props.uid}`;
      let option = '';

      if (isSubmitApproveSuccess) {
        option = '?action=approve';
      } else if (isSubmitDeclineSuccess) {
        option = '?action=decline';
      }
      if (!props.data.allow_download) {
        history.push(`${donePageUrl}${option}`);
      }
    }
  }, [
    history,
    props.isSignCompleted,
    isSubmitApproveSuccess,
    isSubmitDeclineSuccess,
    props.data.allow_download,
    props.id,
    props.uid,
  ]);

  const onTnCAgreementError = () => {
    setSnackBarOpen(true);
    setSeverity('error');
    setSnackBarMessage(t('OpenAPIFailedToAgreeToTnC'));
  };

  const submitDecline = async () => {
    dispatch(
      declineDocument(
        {
          initialDocHash: props?.initialHash,
          dssKey: props.fileShareKey,
          finalDocHash: props?.initialHash,
        },
        props?.data?.business_process_id,
        props?.data?.recipient_id,
      ),
    )
      .then(() => {
        setOpenDeclineModal(false);
        setSnackBarOpen(true);
        setIsSubmitDeclineSuccess(true);
      })
      .catch(() => {
        setOpenDeclineModal(false);
        setSeverity('error');
        setSnackBarMessage(t('SignatureRequestErrorDeclining'));
        setSnackBarOpen(true);
      });
  };

  const submitApprove = async () => {
    setIsSubmitApprove(true);
    setIsSubmitted(true);
    setSnackBarDuration(100000);
    setSnackBarOpen(true);
    setSeverity('info');
    setSnackBarMessage(t('SignatureRequestMessageSubmitting'));

    let pdfData;
    if (props.isLastActor) {
      // prepare e-signature request for all signers in pdf
      // to prepare it for uploading as last-action
      const signersData = {
        signers: [...props.allSignersData.signers],
      };
      signersData.signers.forEach((signer) => {
        signer.esignatures.forEach((esign) => {
          esign.type = 'upload';
        });
      });
      pdfData = await renderPDF(props.pdf, signersData, null, initialsType);
    } else {
      pdfData = await renderCurrentPDF(props.pdf, props.allSignersData, true, props.uid, null);
    }

    // render pdf?
    let base64data = `data:application/pdf;base64,${pdfData.base64}`;
    props.setPdf(base64data);
    setManualFinalPdf(base64data);

    let keyUrl = props.fileShareKey; // to be passed to approve call later
    if (props.isLastActor) {
      // prep data to upload to dss-api
      const key = await sjcl.codec.base64.fromBits(sjcl.random.randomWords(8, 10), 0);
      const encryptedFile = await sjcl.encrypt(key, pdfData.base64);
      const parseEncrypted = await JSON.parse(encryptedFile);

      // upload pdf with new signer data to dss-api
      try {
        const { id } = await uploadFileToDSS(JSON.stringify(parseEncrypted));
        keyUrl = generateFileShareKey(id, key);
      } catch (error) {
        setSnackBarOpen(true);
        setSnackBarDuration(3000);
        setSeverity('error');
        setSnackBarMessage(t('SignatureRequestErrorGeneratingDedocoKey'));
        return;
      }
    }

    try {
      // approve document with new uploaded pdf's dss-key if any
      // else use existing dss-key
      const hashedFinalPdf = getBase64FileHash(pdfData.base64);

      dispatch(
        approveDocument(
          {
            initialDocHash: props?.initialHash,
            dssKey: keyUrl,
            finalDocHash: props.isLastActor
              ? hashedFinalPdf
              : dataRef.current.initial_document_hash,
          },
          props?.data?.business_process_id,
          props?.data?.recipient_id,
        ),
      )
        .then(() => {
          setIsSubmitApprove(false);
          setSnackBarDuration(0);
          props.setIsSignCompleted(true);
          setIsCompleted(true);
          setLoading('');
          setSuccessPopUp(true);
          setPromptNull();
          setIsSubmitApproveSuccess(true);
        })
        .catch(() => {
          setIsSubmitApprove(false);
          setSnackBarOpen(true);
          setSnackBarDuration(3000);
          setSeverity('error');
          setSnackBarMessage(t('SignatureRequestErrorApproving'));
        });
    } catch (error) {
      setSnackBarOpen(true);
      setSnackBarDuration(3000);
      setSeverity('error');
      setSnackBarMessage(t('SignatureRequestErrorGeneratingDedocoKey'));
    }
  };

  const onClickStartRecording = useCallback(() => {
    dispatch(
      startRecording(documentId, (error) => {
        if (error) {
          props.setIsRecording(false);
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props]);

  const onClickStopRecording = useCallback(() => {
    dispatch(stopRecording(documentId));
  }, [dispatch, documentId]);

  const onClickLeaveMeeting = useCallback(() => {
    dispatch({
      type: LEAVE_MEETING,
    });
  }, [dispatch]);

  const submit = async () => {
    setSubmitDisabled(true);
    setSnackBarDuration(100000);
    setSnackBarOpen(true);
    setSeverity('info');
    setSnackBarMessage(t('SignatureRequestMessageSubmitting'));
    setIsSubmitted(true);

    if (props.publicVariable) {
      publicRenderPDF(props.pdf, dataRef.current, props.signatureplacements).then(async (data) => {
        let base64data = `data:application/pdf;base64,${data.base64}`;
        dataRef.current['final_document_hash'] = getBase64FileHash(base64data);
        const signatures =
          props.data.electronic_signatures?.length > 0
            ? props.data.electronic_signatures.map((esig) => {
                return { type: 'electronic', subType: esig.type, value: esig.signature };
              })
            : props.data.digital_signatures.map((dsig) => {
                return { type: 'digital', subType: dsig.type, value: dsig.signature };
              });
        publicSignDoc(
          props.data['business_process_id'],
          props.data['recipient_id'],
          signatures,
          props.data['custom_texts'],
          props.data['initial_document_hash'],
          data.base64,
        )
          .then((_data) => {
            if (openApiVideoSignEnabled && props.meeting?.enable && props.conversation)
              props.conversation.sendMessage(PUBLIC_SIGNER_SIGNED);
            setSnackBarDuration(0);
            setPromptNull();
            dispatch({
              type: MARK_DOCUMENT_AS_SIGNED,
              payload: true,
            });
            //TODO: to add option to download or not
            props.setPdf(base64data);
            setManualFinalPdf(base64data);
            setIsCompleted(true);
            setLoading('');
            props.setIsSignCompleted(true);
            setNdiCardPopUp(false);
            setSuccessPopUp(true);
          })
          .catch((_err) => {
            setSnackBarDuration(3000);
            setSeverity('error');
            setSnackBarMessage(t('SignatureRequestErrorRefresh'));
            setSnackBarOpen(true);
          });
      });
    } else {
      const renderFinishedPDF = props.isLastActor
        ? () => {
            const signersData = {
              signers: [...props.allSignersData.signers],
            };
            signersData.signers.forEach((signer) => {
              if (signer.id === dataRef.current.signer_id) {
                signer.esignatures.forEach((esign, index) => {
                  esign.signature = dataRef.current.electronic_signatures[index].signature;
                  esign.type = dataRef.current.electronic_signatures[index].type;
                });
                signer.custom_texts.forEach((ct, index) => {
                  ct.text = dataRef.current.custom_texts[index];
                });
                signer.confirmations = [...dataRef.current.confirmations];
              } else {
                signer.esignatures.forEach((esign) => {
                  esign.type = 'upload';
                });
              }
            });
            return renderPDF(props.pdf, signersData, null, initialsType);
          }
        : () => renderCurrentPDF(props.pdf, props.allSignersData, true, props.uid, dataRef.current);
      if (props.fileShareKey) {
        renderFinishedPDF().then(async (data) => {
          let base64data = `data:application/pdf;base64,${data.base64}`;
          const hashedFinalPdf = getBase64FileHash(data.base64);
          const key = await sjcl.codec.base64.fromBits(sjcl.random.randomWords(8, 10), 0);
          const encryptedFile = await sjcl.encrypt(key, data.base64);
          const parseEncrypted = await JSON.parse(encryptedFile);

          try {
            const uploadRes = props.isLastActor
              ? await uploadFileToDSS(JSON.stringify(parseEncrypted))
              : null;
            props.setPdf(base64data);
            setManualFinalPdf(base64data);
            if (uploadRes === 'Timeout') {
              setSnackBarOpen(true);
              setSeverity('error');
              setSnackBarMessage(t('errSignatureRequestErrorUploadSmallerSize'));
            } else if (uploadRes === 'Failed') {
              setSnackBarDuration(10000);
              setSeverity('error');
              setSnackBarMessage(t('errSignatureRequestRefreshContactSupport'));
              setSnackBarOpen(true);
            } else {
              let keyUrl = props.fileShareKey;
              if (uploadRes) {
                const { id } = uploadRes;
                keyUrl = generateFileShareKey(id, key);
              }
              const requestPayload = {
                ...dataRef.current,
              };
              const eSigns = requestPayload.electronic_signatures
                ? requestPayload.electronic_signatures.map((s) => ({
                    value: s.signature,
                    subType: s.type,
                    type: 'electronic',
                  }))
                : [];
              const signatures = [...eSigns];
              signDocument(
                {
                  initialDocHash: dataRef.current.initial_document_hash,
                  finalDocHash: props.isLastActor
                    ? hashedFinalPdf
                    : dataRef.current.initial_document_hash,
                  dssKey: keyUrl,
                  signatures,
                  customTexts: dataRef.current.custom_texts,
                  confirmations: dataRef.current.confirmations,
                  signerId: dataRef.current.signer_id,
                  businessProcessId: dataRef.current.business_process_id,
                },
                bpId,
                props.uid,
              )
                .then(() => {
                  dispatch({ type: SAVEMYSIGNATURE, payload: data });
                  setSnackBarDuration(0);
                  setIsCompleted(true);
                  props.setIsSignCompleted(true);
                  setLoading('');
                  setSuccessPopUp(true);
                  setPromptNull();
                })
                .catch((_err) => {
                  setSnackBarDuration(3000);
                  setSeverity('error');
                  setSnackBarMessage(t('SignatureRequestErrorRefresh'));
                  setSnackBarOpen(true);
                });
            }
          } catch (error) {
            setSnackBarDuration(3000);
            setSeverity('error');
            setSnackBarMessage(t('SignatureRequestErrorGeneratingDedocoKey'));
            setSnackBarOpen(true);
          }
        });
      } else {
        renderFinishedPDF().then(async (data) => {
          try {
            const base64data = `data:application/pdf;base64,${data.base64}`;
            const hashedFinalPdf = getBase64FileHash(data.base64);
            props.setPdf(base64data);
            setManualFinalPdf(base64data);
            const requestPayload = {
              ...dataRef.current,
            };
            const eSigns = requestPayload.electronic_signatures
              ? requestPayload.electronic_signatures.map((s) => ({
                  value: s.signature,
                  subType: s.type,
                  type: 'electronic',
                }))
              : [];
            const signatures = [...eSigns];
            signDocument(
              {
                initialDocHash: dataRef.current.initial_document_hash,
                finalDocHash: props.isLastActor
                  ? hashedFinalPdf
                  : dataRef.current.initial_document_hash,
                signatures,
                customTexts: dataRef.current.custom_texts,
                confirmations: dataRef.current.confirmations,
                signerId: dataRef.current.signer_id,
                businessProcessId: dataRef.current.business_process_id,
              },
              bpId,
              props.uid,
            )
              .then(() => {
                dispatch({ type: SAVEMYSIGNATURE, payload: data });
                download(data.pdfBytes, props.FileName, 'application/pdf');
                setPromptNull();
                setSuccessPopUp(true);
                setIsCompleted(true);
                props.setIsSignCompleted(true);
              })
              .catch((_err) => {
                setSnackBarDuration(3000);
                setSeverity('error');
                setSnackBarMessage(t('SignatureRequestErrorRefresh'));
                setSnackBarOpen(true);
              });
          } catch (e) {
            setSnackBarDuration(3000);
            setSeverity('error');
            setSnackBarMessage(t('SignatureRequestErrorGeneratingDedocoKey'));
            setSnackBarOpen(true);
          }
        });
      }
    }
  };

  //******************Gary generate QR code for NDI signing*******************//

  const resetDigitalData = () => {
    setIsCompleted(false);
    props.setIsSignCompleted(false);
    setSigningError(false);
    setsigningConfirmation('');
    setQrData('');
    //setNDISessionId('');
    setNDIDocHash('');
    setITextId('');
    setUserCert('');
    clearInterval(intervalRef.current);
    clearInterval(qrIntervalRef.current);
    clearTimeout(timeoutRef.current);
    setTransition('');
    setLoading('');
  };

  const onQRPopUp = () => {
    setQrData('');
    generateQR(ndiSessionId);
    setNdiCardPopUp(true);
    setConfirmationPopUp(false);
  };

  const generateQR = (ndiSessionId) => {
    setTimeout(() => {
      if (ndiSessionId) {
        getPublicSignRef(ndiSessionId)
          .then((data) => {
            const time = new Date();
            time.setSeconds(time.getSeconds() + 120);
            restart(time);
            setQrData(data.ndi_qr_code.qr_code.payload);
            setChallengeCode1(data.ndi_challenge_code);

            setQrContent(
              <Root className="qr-Border" style={{ textAlign: 'center' }}>
                <a href={data.ndi_qr_code.qr_code.payload}>
                  <img className="frame" src={singPassFrame} alt="frame" />
                  <div className="qr-Code">
                    <QRCode
                      size={210}
                      value={data.ndi_qr_code.qr_code.payload}
                      logoImage="/Singpass app icon.svg"
                      logoWidth={48}
                    />
                  </div>
                </a>
              </Root>,
            );
          })
          .catch((_err) => {
            setErrorMessage(signingErrorMessage);
            setSigningError(true);
          });
      }
    }, 2000);
  };

  const savingFormData = () => {
    timeoutRef.current = setTimeout(() => {
      setsigningConfirmation('true');
    }, 6000);
  };

  const generateNDISessId = async () => {
    let res = '';
    //TODO: add logic for open API digisig
    if (props.publicVariable) {
      await getPublicNdiSessId(props.id, props.uid)
        .then((data) => {
          res = data.ndiSessId.toString();
        })
        .catch((_error) => {
          //to add error handling
        });
    } else {
      await getNDISessId(props.data.business_process_id, props.uid)
        .then((data) => {
          res = data.ndiSessId.toString();
        })
        .catch((_error) => {
          /* TODO:Types of error code to be implemented
          if (error.data.error_description === 'Signer has already signed.') {
            setErrorMessage(userAlreadySignedErrorMessage);
            setSigningError(true);
          } else if (
            error.data.error_description ===
            'Current Document hash is different from latest Document hash in database.'
          ) {
            setErrorMessage(outdatedPDFErrorMessage);
            setSigningError(true);
          } else if (error.data.error_description === 'Another Signer is signing at the moment.') {
            setErrorMessage(anotherSignerSigningErrorMessage);
            setSigningError(true);
          } else {
            setErrorMessage(signingErrorMessage);
            setSigningError(true);
          }*/
        });
    }
    setNDISessionId(res);
    return res;
  };

  useEffect(() => {
    let sigChecker = true;
    let ctextCheckers = true;
    let digiChecker = true;
    if (props.signatureplacements?.digi_signatures.length > 0) {
      props.signatureplacements.digi_signatures.map((digisig, index) => {
        if (sigs[index] === '' || sigs[index] === ' ' || sigs[index] === undefined) {
          digiChecker = false;
        }
        return '';
      });
    } else {
      digiChecker = false;
    }

    if (props.signatureplacements?.esignatures?.length > 0) {
      props.signatureplacements.esignatures.map((esig, index) => {
        if (sigs[index] === '' || sigs[index] === ' ' || sigs[index] === undefined) {
          sigChecker = false;
        }
        return '';
      });
    } else {
      sigChecker = false;
    }

    if (props.signatureplacements?.custom_texts?.length > 0) {
      props.signatureplacements.custom_texts.map((textdata, index) => {
        if (ctext[index] === '' || ctext[index] === ' ' || ctext[index] === undefined) {
          ctextCheckers = false;
        }
        return '';
      });
    } else {
      ctextCheckers = false;
    }

    const confirmChecker = !confirmations.some(
      (confirmation) => confirmation.isRequired && !confirmation.isChecked,
    );

    setchecked({
      esigs: sigChecker,
      ctext: ctextCheckers,
      digisigs: digiChecker,
      confirmations: confirmChecker,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sigs, ctext, confirmations]);

  //prepare PDF for itext Gary
  useEffect(() => {
    if (props.pdf && userCert) {
      let placementX = props.signatureplacements?.digi_signatures[0].placement.x.includes('%')
        ? parseFloat(props.signatureplacements?.digi_signatures[0].placement.x) / 100
        : props.signatureplacements?.digi_signatures[0].placement.x;
      let placementY = props.signatureplacements?.digi_signatures[0].placement.y.includes('%')
        ? parseFloat(props.signatureplacements?.digi_signatures[0].placement.y) / 100
        : props.signatureplacements?.digi_signatures[0].placement.y;

      if (props.signatureplacements?.custom_texts?.length > 0) {
        if (props.publicVariable) {
          publicRenderPDF(props.pdf, dataRef.current, props.signatureplacements).then((data) => {
            prepareITextPDF(
              data.base64,
              userCert,
              //Gary to change to multple fields
              placementX,
              placementY,
              props.signatureplacements?.digi_signatures[0].placement.page,
              'small',
            )
              .then((data) => {
                setITextId(data.id);
                setNDIDocHash(data.hash);
              })
              .catch((_err) => {
                setErrorMessage(iTextErrorMessage);
                setSigningError(true);
              });
          });
        } else {
          renderPDF(props.pdf, dataRef.current, props.signatureplacements, initialsType).then(
            (data) => {
              prepareITextPDF(
                data.base64,
                userCert,
                //Gary to change to multple fields
                placementX,
                placementY,
                props.signatureplacements?.digi_signatures[0].placement.page,
                'small',
              )
                .then((data) => {
                  setITextId(data.id);
                  setNDIDocHash(data.hash);
                })
                .catch((_err) => {
                  setErrorMessage(iTextErrorMessage);
                  setSigningError(true);
                });
            },
          );
        }
      } else {
        prepareITextPDF(
          props.pdf.split(',')[1],
          userCert,
          //Gary to change to multple fields
          placementX,
          placementY,
          props.signatureplacements?.digi_signatures[0].placement.page,
          'small',
        )
          .then((data) => {
            setITextId(data.id);
            setNDIDocHash(data.hash);
          })
          .catch((_err) => {
            setErrorMessage(iTextErrorMessage);
            setSigningError(true);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCert]);

  useEffect(() => {
    if (transition && iTextId) {
      queryFinal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition, iTextId]);

  useEffect(() => {
    if (ndiSessionId && qrData) {
      querySigning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ndiSessionId, qrData]);
  /**************************NDI use effect END*************************** */
  useEffect(() => {
    let ctextlength = 0;
    let initialsLength = 0;
    let stampLength = 0;
    let datelenght = 0;
    if (props.signatureplacements?.esignatures?.length > 0) {
      props.signatureplacements.esignatures.map(async (esignature, index) => {
        await setEsigatures({ ...sigs, [index]: ' ' });
      });
    }
    //added logic for digi signatures
    if (props.signatureplacements?.digi_signatures.length > 0) {
      props.signatureplacements.digi_signatures.map(async (digisignature, index) => {
        await setEsigatures({ ...sigs, [index]: ' ' });
      });
    }
    if (props.signatureplacements?.custom_texts?.length > 0) {
      props.signatureplacements.custom_texts.map(async (custom_text) => {
        if (custom_text.type === 'actual-date' || custom_text.type === 'custom-date') {
          datelenght += 1;
        } else if (custom_text.type === 'initials') {
          initialsLength += 1;
        } else if (custom_text.type === 'stamp') {
          stampLength += 1;
        } else {
          ctextlength += 1;
        }
        custom_text.type === 'actual-date'
          ? setCustomTexts((ctext) => [...ctext, getLocaleDateString(Date.now())])
          : setCustomTexts((ctext) => [...ctext, '']);
      });
    }
    //added digi sig length
    setLength({
      esigs: props.signatureplacements?.esignatures?.length,
      initials: initialsLength,
      stamp: stampLength,
      ctext: ctextlength,
      date: datelenght,
      digisigs: props.signatureplacements?.digi_signatures.length,
      confirmations: (props.signatureplacements?.confirmations || []).length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //trigger second leg with sending of dochash to api
  useEffect(() => {
    if (ndiDocHash) {
      publicSendDocHash(ndiSessionId, ndiDocHash)
        .then((_data) => {
          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          restart(time);
          setLoading('');
          setQrContent('');
          setTransition(
            <div style={{ textAlign: 'center' }}>
              <img alt="singpass transition" id="slider" src={transitionImage} />
            </div>,
          );
        })
        .catch((_err) => {
          setErrorMessage(signingErrorMessage);
          setSigningError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ndiDocHash]);

  useEffect(() => {
    if (open === true) {
      dispatch(setZoom(PdfViewerStates.zoom + 0.001));
    } else {
      dispatch(setZoom(PdfViewerStates.zoom - 0.001));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (pdfPage.length > 0) {
      upperPanel[0].style.height = pdfHeight + 'px';
      upperPanel[0].style.width = pdfWidth + 'px';
      upperPanel[0].style.left = pdfLeft + 'px';
      upperPanel[0].style.top = props.isObserver ? 68 : 0 + pdfHeaderHeight + 'px';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PdfViewerStates.currentPage, PdfViewerStates.zoom, window.innerWidth, props.isObserver]);

  useEffect(() => {
    //for zoom functionality
    upperPanel[0].style.height = pdfHeight + 'px';
    upperPanel[0].style.width = pdfWidth + 'px';
    upperPanel[0].style.left = pdfLeft + 'px';
    upperPanel[0].style.top = (props.isObserver ? 68 : 0) + pdfHeaderHeight + 'px';
  });

  useEffect(() => {
    function handleResize() {
      if (pdfPage.length > 0) {
        let width = pdfPage[0].offsetWidth;
        if (width > lowerPannel[0].offsetWidth) {
          upperPanel[0].style.left = 0 + 'px';
        } else {
          upperPanel[0].style.left = (lowerPannel[0].offsetWidth - width) / 2 + 'px';
        }
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [lowerPannel, pdfPage, upperPanel]);

  useEffect(() => {
    if (props.signatureplacements?.confirmations) {
      const localConfirmations = props.signatureplacements.confirmations?.map((confirmation) => ({
        ...confirmation,
        isChecked: confirmation.isDefault,
      }));

      setConfirmations(localConfirmations);
    }
  }, [props.signatureplacements]);

  const handleConfirmation = useCallback(
    (index) => {
      const newConfirmations = [...confirmations];

      newConfirmations[index].isChecked = !newConfirmations[index].isChecked;

      setConfirmations(newConfirmations);
    },
    [confirmations],
  );

  //TODO: refresh
  const challengeCodeHeaders = <ChallengeCodeHeaders challenge={challenge} />;

  //expired qr code object
  const expiredQR = (
    <div>
      <div className="expiredQR">{t('SigningRequestExpiredQRHeader')}</div>
      <div style={{ opacity: '5%' }}>{qrContent}</div>
    </div>
  );

  const refreshQRButton = (
    <RefreshQRButton
      transition={transition}
      seconds={seconds}
      minutes={minutes}
      resetDigitalData={resetDigitalData}
      generateQR={async () => {
        const sessionId = await generateNDISessId();
        generateQR(sessionId);
      }}
    />
  );

  //qrcode message
  const qrCodeMessage = (
    <div style={{ paddingTop: '0px' }}>
      <div className="qr-code-message">
        {dimensions.width < 760 ? (
          <>{t('SigningRequestQRCodeMessageHeader')}</>
        ) : (
          <>{t('SigningRequestQRCodeMessageHeaderAlt')}</>
        )}
      </div>
      <div className="qr-code-message-1">{t('SigningRequestQRCodeMessageHeaderText')}</div>
    </div>
  );

  //(window resize effects)

  //query for user signature
  const querySigning = () => {
    qrIntervalRef.current = setInterval(() => {
      getPublicChallengeCode(ndiSessionId)
        .then((data) => {
          if (data.status === 'pending-signature') {
            clearInterval(qrIntervalRef.current);
            setLoading(true);

            getPublicNdiSignature(ndiSessionId)
              .then((data) => {
                setUserCert(data.ndi_signer_cert);
              })
              .catch((_err) => {
                setErrorMessage(signingErrorMessage);
                setSigningError(true);
                clearInterval(qrIntervalRef.current);
              });
          }
        })
        .catch((_err) => {
          if (_err.status === 403) setErrorMessage(nricMismatchErrorMessage);
          else setErrorMessage(signingErrorMessage);
          setSigningError(true);
          clearInterval(qrIntervalRef.current);
        });
    }, 1000);
  };

  //query for second leg
  const queryFinal = () => {
    intervalRef.current = setInterval(() => {
      getPublicNdiSignature(ndiSessionId)
        .then((data1) => {
          if (data1.status === 'second-leg-failed') {
            setErrorMessage(signingErrorMessage);
            setSigningError(true);
            clearInterval(intervalRef.current);
          }

          if (data1.status === 'completed') {
            clearInterval(intervalRef.current);
            setLoading(true);
            setSnackBarDuration(100000);
            setSnackBarOpen(true);
            setSeverity('info');
            setSnackBarMessage(t('SignatureRequestMessageSubmitting'));
            getPublicNdiSignature(ndiSessionId)
              .then((data2) => {
                completeITextPDF(iTextId, userCert, data2.ndi_signature)
                  .then(async (data3) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(data3);

                    reader.onloadend = async function () {
                      const key = await sjcl.codec.base64.fromBits(
                        sjcl.random.randomWords(8, 10),
                        0,
                      );
                      const base64data = reader.result;
                      const base64result = base64data.split(',')[1];
                      const newHash = getBase64FileHash(base64result);
                      const encryptedFile = await sjcl.encrypt(key, base64result);
                      const parseEncrypted = await JSON.parse(encryptedFile);
                      props.setPdf(base64data);
                      setManualFinalPdf(base64data);
                      //add secure share logic
                      if (props.fileShareKey && !props.publicVariable) {
                        try {
                          const uploadRes = await uploadFileToDSS(JSON.stringify(parseEncrypted));
                          if (uploadRes === 'Timeout') {
                            setErrorMessage(signingErrorMessage);
                            setSigningError(true);
                          } else if (uploadRes === 'Failed') {
                            setErrorMessage(signingErrorMessage);
                            setSigningError(true);
                          } else {
                            const { id } = uploadRes;
                            const keyUrl = generateFileShareKey(id, key);
                            const dSigns = props?.signatureplacements?.digi_signatures
                              ? props.signatureplacements.digi_signatures.map((s) => ({
                                  value: data2.ndi_signature,
                                  subType: s.type,
                                  type: 'digital',
                                }))
                              : [];
                            const signatures = [...dSigns];
                            signDocument(
                              {
                                initialDocHash: dataRef.current.initial_document_hash,
                                finalDocHash: newHash,
                                dssKey: keyUrl,
                                signatures,
                                customTexts: dataRef.current.custom_texts,
                                confirmations: dataRef.current.confirmations,
                                signerId: dataRef.current.signer_id,
                                businessProcessId: dataRef.current.business_process_id,
                              },
                              bpId,
                              props.uid,
                            )
                              .then(() => {
                                setIsCompleted(true);
                                props.setIsSignCompleted(true);
                                setNdiCardPopUp(false);
                                setSuccessPopUp(true);
                                setLoading('');
                                setSigningError(false);
                                setsigningConfirmation('');
                                setTransition('');
                                setQrData('');
                                setSnackBarDuration(0);
                                setPromptNull();
                              })
                              .catch(() => {
                                setErrorMessage(fileShareErrorMessage);
                                setSigningError(true);
                              });
                          }
                        } catch (error) {
                          setErrorMessage(fileShareErrorMessage);
                          setSigningError(true);
                        }
                      } else if (!props.publicVariable) {
                        props.setPdf(base64data);
                        setManualFinalPdf(base64data);
                        const dSigns = props?.signatureplacements?.digi_signatures
                          ? props.signatureplacements.digi_signatures.map((s) => ({
                              value: data2.ndi_signature,
                              subType: s.type,
                              type: 'digital',
                            }))
                          : [];
                        const signatures = [...dSigns];
                        signDocument(
                          {
                            initialDocHash: dataRef.current.initial_document_hash,
                            finalDocHash: newHash,
                            signatures,
                            customTexts: dataRef.current.custom_texts,
                            confirmations: dataRef.current.confirmations,
                            signerId: dataRef.current.signer_id,
                            businessProcessId: dataRef.current.business_process_id,
                          },
                          bpId,
                          props.uid,
                        )
                          .then(() => {
                            setIsCompleted(true);
                            props.setIsSignCompleted(true);
                            download(base64data, props.FileName, 'application/pdf');
                            setLoading('');
                            setNdiCardPopUp(false);
                            setSuccessPopUp(true);
                            setSigningError(false);
                            setsigningConfirmation('');
                            setTransition('');
                            setQrData('');
                            setErrorMessage(signingErrorMessage);
                            setSigningError(true);
                            setSnackBarDuration(0);
                            setPromptNull();
                          })
                          .catch((_err) => {
                            setSigningError(true);
                          });
                      } else {
                        //TODO: public api logic
                        publicUpdateDocHashNdi(
                          props.id,
                          props.uid,
                          base64data,
                          data2.ndi_signature,
                          props.data.custom_texts,
                        ).then((_data) => {
                          //TODO: to add option to download or not

                          if (props.data.allow_download) {
                            props.setPdf(base64data);
                            setManualFinalPdf(base64data);
                            setIsCompleted(true);
                            props.setIsSignCompleted(true);
                            setLoading('');
                            setNdiCardPopUp(false);
                            setSuccessPopUp(true);
                            setSigningError(false);
                            setsigningConfirmation('');
                            setTransition('');
                            setQrData('');
                            setSnackBarDuration(0);
                            setPromptNull();
                          } else {
                            history.push(`/public/done/${props.id}/${props.uid}`);
                          }
                        });
                      }
                    };
                  })
                  .catch((_err3) => {
                    setErrorMessage(signingErrorMessage);
                    setSigningError(true);
                  });
              })
              .catch((_err2) => {
                setErrorMessage(signingErrorMessage);
                setSigningError(true);
              });
          }
        })
        .catch((_err) => {
          setErrorMessage(signingErrorMessage);
          setSigningError(true);
        });
    }, 1000);
  };

  /*****************************END************************************/

  const getLocaleDateString = (date) => {
    if (typeof date === 'number') {
      return new Date(date).toLocaleDateString('en-GB');
    } else {
      return date;
    }
  };

  function scroller(type, index) {
    // return false;

    let currentelement = {};
    let elementy = 0;
    let perc = 0;
    let scrolly = leftContainer.current.scrollTop;
    let strArray;
    switch (type) {
      case 'esignature':
        currentelement = props.signatureplacements?.esignatures[index].placement.y;
        strArray = currentelement.split('%');
        perc = parseInt(strArray[0]);
        elementy = (perc * 700) / 100;

        break;

      //Gary added digital signature case
      case 'digisignature':
        currentelement = props.signatureplacements?.digi_signatures[index].placement.y;
        strArray = currentelement.split('%');
        perc = parseInt(strArray[0]);
        elementy = (perc * 700) / 100;

        break;
      case 'custom_text':
        currentelement = props.signatureplacements?.custom_texts[index].placement.y;
        strArray = currentelement.split('%');
        perc = parseInt(strArray[0]);
        elementy = (perc * 700) / 100;
        break;
      case 'confirmation':
        currentelement = props.signatureplacements?.confirmations[index].placement.y;
        strArray = currentelement.split('%');
        perc = parseInt(strArray[0]);
        elementy = (perc * 700) / 100;
        break;
      default:
        break;
    }

    if (elementy < scrolly) {
      leftContainer.current.scrollTo(0, elementy - 100);
    } else if (elementy > scrolly) {
      leftContainer.current.scrollTo(0, elementy - 100);
    }
  }

  //Added checker for digital signature
  function checksign() {
    let checkers = 0;
    let digisigncheckers = 0;
    if (props.signatureplacements?.esignatures?.length > 0) {
      props.signatureplacements.esignatures.map((esig, index) => {
        if (sigs[index] === '' || sigs[index] === ' ' || sigs[index] === undefined) {
          checkers = 1;
        }
        return '';
      });
    }

    if (props.signatureplacements?.digi_signatures?.length > 0) {
      props.signatureplacements.digi_signatures.map((esig, index) => {
        if (sigs[index] === '' || sigs[index] === ' ' || sigs[index] === undefined) {
          digisigncheckers = 1;
        }
        return '';
      });
    }

    if (checkers === 0) {
      setchecked({
        ...checked,
        esigs: true,
      });
    } else if (digisigncheckers === 0) {
      setchecked({ ...checked, digisigs: true });
    }
  }

  async function resetsigs() {
    await setEsigatures({});
    await setCustomTexts({});
    setchecked({ esigs: false, ctext: false, digisigs: false });
  }

  const savesignature = async () => {
    props.setFinalPdf('');
    if (affect === -1) {
      //replaceallsignatures
      if (props.signatureplacements?.esignatures?.length > 0) {
        props.signatureplacements.esignatures.map(async (esig, index) => {
          await savesigs(index);
          //Gary fix to a bug? more testing needed TODO
        });
      }
    } else {
      await savesigs(affect);
    }
    await finalsubmit();
    await checksign();
  };

  const saveInitials = async () => {
    let imagesrc;
    let initialsTypeCopy = [...initialsType];
    if (initials.type === 'text') {
      let canvas = document.getElementById('my-canvas-initials');
      let ctx = canvas.getContext('2d');
      imagesrc = trimCanvas(ctx);
    }
    if (props.signatureplacements?.custom_texts?.length > 0) {
      if (affect === -1) {
        props.signatureplacements.custom_texts.forEach((customTexts, index) => {
          if (customTexts.type === 'initials') {
            if (initials.type === 'text') {
              ctext[index] = imagesrc;
              initialsTypeCopy[index] = 'text';
            } else {
              if (initials.type === 'drawing') {
                initialsTypeCopy[index] = 'drawing';
              } else {
                initialsTypeCopy[index] = 'upload';
              }
              ctext[index] = initials.value;
            }
          }
        });
      } else {
        props.signatureplacements.custom_texts.forEach((customTexts, index) => {
          if (customTexts.type === 'initials' && affect === index) {
            if (initials.type === 'text') {
              ctext[index] = imagesrc;
              initialsTypeCopy[index] = 'text';
            } else {
              if (initials.type === 'drawing') {
                initialsTypeCopy[index] = 'drawing';
              } else {
                initialsTypeCopy[index] = 'upload';
              }
              ctext[index] = initials.value;
            }
          }
        });
      }
    }

    let cTextArray = [...ctext];
    setCustomTexts(cTextArray);
    setInitialsType(initialsTypeCopy);
    props.setData((prevState) => ({
      ...prevState,
      custom_texts: cTextArray,
    }));
  };

  useEffect(() => {
    if (tab.element === 'initials') {
      tab.value === 'type' && typingInitials !== '' && onTypeChangeInitials(typingInitials);
    } else if (tab.element === 'signature') {
      tab.value === 'type' && typingSignature !== '' && onTypeChange(typingSignature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const saveStamp = async () => {
    if (props.signatureplacements?.custom_texts?.length > 0) {
      if (affect === -1) {
        props.signatureplacements.custom_texts.forEach((customTexts, index) => {
          if (customTexts.type === 'stamp') {
            if (stamp.type === 'upload') {
              ctext[index] = stamp.value;
            }
          }
        });
      } else {
        props.signatureplacements.custom_texts.forEach((customTexts, index) => {
          if (customTexts.type === 'stamp' && affect === index) {
            if (stamp.type === 'upload') {
              ctext[index] = stamp.value;
            }
          }
        });
      }
    }

    let cTextArray = [...ctext];
    setCustomTexts(cTextArray);
  };

  const savesigs = async (index) => {
    switch (mySignature.sigtype) {
      //Gary changed type to 'drawing' for ease of integration with api which only allows text/drawing type
      case 'drawing':
      case 'upload':
        sigs[index] = mySignature.value;
        if (checkedSaveSignature) {
          dispatch(
            saveSignature({
              signature: mySignature.value,
              email: props.signatureplacements.email,
              savingSignatureSecret: savingSignatureSecret,
            }),
          ).then((res) => {
            setSavedSignatures([mySignature.value]);
            numSavedSignatures = savedSignatures.length;
            setSavingSignatureSecret(res.savingSignatureSecret);
          });
        }
        checksign();
        break;
      case 'text': {
        let canvas = document.getElementById('my-canvas');
        let ctx = canvas.getContext('2d');
        const imagesrc = await trimCanvas(ctx);
        sigs[index] = imagesrc;
        if (imagesrc !== ' ' || imagesrc !== '') {
          setSignature({ value: imagesrc, sigtype: 'text' });
          if (checkedSaveSignature) {
            dispatch(
              saveSignature({
                signature: imagesrc,
                email: props.signatureplacements.email,
                savingSignatureSecret: savingSignatureSecret,
              }),
            ).then((res) => {
              setSavedSignatures([imagesrc]);
              numSavedSignatures = savedSignatures.length;
              setSavingSignatureSecret(res.savingSignatureSecret);
            });
          }
          checksign();
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    dataRef.current = props.data;
  }, [props.data]);

  const finalsubmit = async () => {
    // validate confirmation if any
    let signaturearray = [];
    let ctextarray = [];
    props.setData((prevState) => ({
      ...prevState,
      confirmations,
    }));
    let ctextData = props.signatureplacements?.custom_texts.map((custom_text, index) => {
      //customtext and ctext are 2 different entities
      if (ctext[index] !== '') {
        if (typeof ctext[index] === 'number') {
          ctextarray.push(new Date(ctext[index]).toLocaleDateString('en-GB'));
        } else {
          ctextarray.push(ctext[index]);
        }

        props.setData((prevState) => ({
          ...prevState,
          custom_texts: ctextarray,
        }));
      }
    });

    let signatureData = await Promise.all(
      props.signatureplacements?.esignatures.map(async (esig, index) => {
        if (sigs[index] !== '') {
          let sighash = await getStringHash(sigs[index]);

          signaturearray.push({
            type: mySignature.sigtype,
            signature: sigs[index],
            signature_hash: sighash,
          });
          props.setData((prevState) => ({
            ...prevState,
            electronic_signatures: signaturearray,
          }));
        } else {
          return false;
        }
      }),
    );

    return [ctextData, signatureData];
  };

  const fileupload = (file) => {
    if (
      file.type.match('image.png') ||
      file.type.match('image.jpg') ||
      file.type.match('image.jpeg')
    ) {
      var oFReader = new FileReader();
      oFReader.readAsDataURL(file);
      oFReader.onload = function (oFREvent) {
        setUrl(oFREvent.target.result);
        setSignature({
          value: oFREvent.target.result,
          sigtype: 'upload',
        });
        setAbled(true);
      };
    } else {
      alert(t('SignatureRequestErrorFileTypeNotSupported'));
    }
  };

  const stampFileUpload = (file) => {
    if (
      file.type.match('image.png') ||
      file.type.match('image.jpg') ||
      file.type.match('image.jpeg')
    ) {
      var oFReader = new FileReader();
      oFReader.readAsDataURL(file);
      oFReader.onload = function (oFREvent) {
        setStampFileUrl(oFREvent.target.result);
        setStamp({
          value: oFREvent.target.result,
          type: 'upload',
        });
        setAbled(true);
        // savesignature()
      };
    } else {
      alert(t('SignatureRequestErrorFileTypeNotSupported'));
    }
  };

  const initialsFileUpload = (file) => {
    if (
      file.type.match('image.png') ||
      file.type.match('image.jpg') ||
      file.type.match('image.jpeg')
    ) {
      var oFReader = new FileReader();
      oFReader.readAsDataURL(file);
      oFReader.onload = function (oFREvent) {
        setInitialsFileUrl(oFREvent.target.result);
        setInitials({
          value: oFREvent.target.result,
          type: 'upload',
        });
        setAbled(true);
        // savesignature()
      };
    } else {
      alert(t('SignatureRequestErrorFileTypeNotSupported'));
    }
  };

  const changetext = async (index, value, type) => {
    if (type === 'text') {
      if (value.trim() === '') {
        value = value.trim();
        setchecked({
          ...checked,
          ctext: false,
        });
      }
    }

    let cTextArray = [...ctext];
    cTextArray[index] = value;
    setCustomTexts(cTextArray);
    await finalsubmit();
  };

  function fillTexts(chunks, ctx, x, y) {
    chunks.forEach((chunk, index) => {
      ctx.fillText(chunk, x, y + index * 40);
    });
  }

  const onTypeChangeInitials = async (value) => {
    value = value.trim();
    setTypingInitials(value);
    setInitials({ value: value, type: 'text' });
    let canvas = document.getElementById('my-canvas-initials');
    if (canvas) {
      let ctx = canvas.getContext('2d');
      ctx.textAlign = 'start';
      ctx.font = " 40px  'Satisfy', cursive";
      ctx.clearRect(0, 0, 370, 170);
      if (value.length === 0) {
        setAbled(false);
      }
      if (value.length > 0) {
        setAbled(true);
      }
      if (value.length > 20) {
        var chunks = [];

        for (var i = 0, charsLength = value.length; i < charsLength; i += 20) {
          chunks.push(value.substring(i, i + 20));
        }
        fillTexts(chunks, ctx, 10, 50);
      } else {
        ctx.fillText(value, 10, 50);
      }
    }
  };

  //Gary changed sigtype to 'text'
  const onTypeChange = async (value) => {
    value = value.trim();
    setTypingSignature(value);
    setSignature({ value: value, sigtype: 'text' });
    let canvas = document.getElementById('my-canvas');
    if (canvas) {
      let ctx = canvas.getContext('2d');
      ctx.textAlign = 'start';
      ctx.font = " 40px  'Satisfy', cursive";
      ctx.clearRect(0, 0, 370, 170);
      if (value.length === 0) {
        setAbled(false);
      }
      if (value.length > 0) {
        setAbled(true);
      }
      if (value.length > 20) {
        var chunks = [];

        for (var i = 0, charsLength = value.length; i < charsLength; i += 20) {
          chunks.push(value.substring(i, i + 20));
        }
        fillTexts(chunks, ctx, 10, 50);
      } else {
        ctx.fillText(value, 10, 50);
      }

      checksign();
    }
  };
  //Gary (Added conditional popup for Digital Signing and close drawer when clicking on sign document)
  const handleOpen = () => {
    if (isUsingNDI) {
      setMobileOpen(false);
      savingFormData();
      setConfirmationPopUp(true);
      generateNDISessId();
    } else {
      setMobileOpen(false);
      setOpen(true);
      setTab({
        value: 'drawing',
        element: 'signature',
      });
      setAbled(false);
    }
  };

  const clearDrawSignature = () => {
    if (sigpad.current) {
      sigpad.current.clear();
      setAbled(false);
    }
  };
  const clearStamp = () => {
    setStampFileUrl('');
    setAbled(false);
  };

  const clearInitials = () => {
    setAbled(false);
    if (initialsPad.current) {
      initialsPad.current.clear();
    }
    setInitialsFileUrl('');
    onTypeChangeInitials('');
  };

  const clearUploadSignature = () => {
    if (fileUrl) {
      setAbled(false);
      setUrl('');
    }
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const goTo = (page) => {
    if (parseInt(page) !== parseInt(PdfViewerStates.currentPage)) {
      dispatch(setPage(page));
    }
  };

  const handleSubmit = () => {
    const invalidConfirmations = confirmations.filter(
      (confirmation) => confirmation.isRequired && !confirmation.isChecked,
    );

    if (invalidConfirmations.length > 0) {
      setSnackBarDuration(3000);
      setSnackBarOpen(true);
      setSeverity('error');
      setSnackBarMessage(t('SignatureRequestConfirmation'));
      return;
    }

    submit();
  };

  const drawer = ({ showCloseButton = false } = {}) => (
    <Grid container className="h-100 flex-column" justifyContent="space-between" wrap="nowrap">
      <Grid item className="p2">
        {!props.isObserver && (
          <>
            <p className="document-action-header">
              {props.isSigner && t('SignDocumentHeader')}
              {props.isApprover && t('ApproveDocumentHeader')}
            </p>
            <CompareDocument />
          </>
        )}
        <div className={classes.toolbar + 'text-align-left pl-2'} />
        {props.isObserver && <h4>{t('SigningRequestDrawerHeader')}</h4>}
        {props.isSigner && (
          <span className="require-fields-text">
            {t('SigningRequestRequiredFieldsHeader')} (
            {lenghts.ctext + lenghts.esigs + lenghts.digisigs + lenghts.date})
          </span>
        )}

        {/* Custom textx tickbox*/}
        <div className="items">
          {props.isApprover && (
            <Grid
              container
              className={`item-strips-container${checked.isApproved ? ' field-checked' : ''}`}
              justifyContent="space-between"
            >
              <Grid container alignItems="center" item md={12} sm={12} xs={12}>
                <Grid item>
                  <div className={checked.ctext === true ? 'scircle-2 outlined' : 'scircle-2'}>
                    <img
                      src={checked.ctext === true ? tick : File}
                      className="text"
                      alt="checked"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <span className="field-name ml1">
                    <span className="mr1">{t('SignatureRequestDrawerApproval')}</span>
                    <Tooltip
                      arrow={true}
                      placement="top"
                      title={
                        <span style={{ fontSize: '0.75rem' }}>
                          {t('SignatureRequestDrawerApprovalTooltip')}
                        </span>
                      }
                    >
                      <InfoOutlined />
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                className={`item-strip${checked.ctext ? ' field-checked' : ''} approve-mobile ${
                  checked.isApproved ? 'approve-mobile-checked' : ''
                }`}
              >
                <Grid item className="field-checkbox-wrapper">
                  <Checkbox
                    className="field-checkbox"
                    disabled={
                      props.documentUpdatedApprovement ||
                      isSubmitApproveSuccess ||
                      props.isApprovingDocument
                    }
                    onChange={(e) =>
                      setchecked({
                        ...checked,
                        isApproved: e.target.checked,
                      })
                    }
                    checkedIcon={
                      isMobile ? (
                        <CheckBoxOutlined
                          style={{
                            color: '#19703E',
                          }}
                        />
                      ) : (
                        <CheckIcon
                          style={{
                            color: '#27AE60',
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  alignItems="center"
                  className={`field-name ml1 ${checked.isApproved ? classes.approveCheckbox : ''}`}
                >
                  {t('SignatureRequestDrawerApproveDocument')}
                </Grid>
              </Grid>
            </Grid>
          )}
          {lenghts.ctext > 0 ? (
            <Grid
              container
              spacing={0}
              className={`${
                props?.signatureplacements?.custom_texts?.length > 0
                  ? 'item-strips-container'
                  : 'hidden'
              }${checked.ctext ? ' field-checked' : ''}`}
              justifyContent="space-between"
            >
              <Grid container alignItems="center" item md={8} sm={8} xs={8}>
                <Grid item>
                  <div className={checked.ctext === true ? 'scircle-2 outlined' : 'scircle-2'}>
                    <img
                      src={checked.ctext === true ? tick : Textt}
                      className="text"
                      alt="checked"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <span className="field-name ml1">
                    {props.isObserver
                      ? t('SignatureRequestDrawerText')
                      : `${t('SignatureRequestDrawerText')} (${lenghts.ctext})`}
                  </span>
                </Grid>
              </Grid>
              <Grid item md={4}></Grid>
              {props.signatureplacements?.custom_texts &&
                !props.isObserver &&
                props.signatureplacements?.custom_texts?.map((custom_text, index) => (
                  <Fragment key={index}>
                    {custom_text.type === 'text' && lenghts.ctext > 0 ? (
                      <Grid
                        key={index}
                        container
                        spacing={0}
                        className={`item-strip${checked.ctext ? ' field-checked' : ''}`}
                        onClick={() => {
                          goTo(custom_text.placement.page);
                          scroller('custom_text', index);
                          setHighlightComponent(`custom_text${index}`);
                          animateCSS(`.custom-text-flash-${index}`, 'border-blink');
                        }}
                        justifyContent="space-between"
                      >
                        <Grid item container md={8} xs={8}>
                          <Grid item className="field-checkbox-wrapper">
                            <Checkbox
                              className="field-checkbox"
                              checked={!!ctext[index]}
                              checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                            />
                          </Grid>
                          <Grid item alignItems="center" className="field-name ml1">
                            {custom_text.descriptor && custom_text.descriptor.length > 13
                              ? custom_text.descriptor.toUpperCase().slice(0, 13) + '...'
                              : custom_text.descriptor.toUpperCase().slice(0, 13)}
                          </Grid>
                        </Grid>
                        <Grid item md={4} className="field-page">
                          {t('pageReverse') === 'yes'
                            ? `${custom_text.placement.page} ${t('SigningRequestDrawerPage')}`
                            : `${t('SigningRequestDrawerPage')} ${custom_text.placement.page}`}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))}

              {props.isObserver &&
                props.observeData?.map((signers) =>
                  signers?.custom_texts?.map((text, index) => {
                    return (
                      text.type === 'text' && (
                        <Grid
                          container
                          key={index}
                          spacing={0}
                          className="item-strip"
                          onClick={() => {
                            goTo(text.placement.page);
                            scroller('custom_text', index);
                            setMobileOpen(false);
                            animateCSS(`.custom-text-${index}`, 'border-blink');
                          }}
                        >
                          <Grid item md={3}>
                            <Button
                              style={{
                                color: 'blue',
                                backgroundColor: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              <img
                                className="observer-image"
                                src={observerIcon}
                                alt="observer"
                              ></img>
                            </Button>
                          </Grid>
                          <Grid item md={5} alignItems="center" className="field-name ml1">
                            {text.descriptor.length > 13
                              ? text.descriptor.toUpperCase().slice(0, 13) + '...'
                              : text.descriptor.toUpperCase().slice(0, 13)}
                          </Grid>
                          <Grid item md={3} className="field-page">
                            {t('pageReverse') === 'yes'
                              ? `${text.placement.page} ${t('SigningRequestDrawerPage')}`
                              : `${t('SigningRequestDrawerPage')} ${text.placement.page}`}
                          </Grid>
                        </Grid>
                      )
                    );
                  }),
                )}
            </Grid>
          ) : (
            <></>
          )}
          {/* Initials checkboxes */}
          {lenghts.initials > 0 ? (
            <Grid
              container
              spacing={0}
              className={`${
                props.signatureplacements?.custom_texts &&
                props.signatureplacements?.custom_texts?.length > 0
                  ? 'item-strips-container'
                  : 'hidden'
              }${checked.ctext ? ' field-checked' : ''}`}
              justifyContent="space-between"
            >
              <Grid container alignItems="center" item md={8} sm={8} xs={8}>
                <Grid item>
                  <div className={checked.ctext === true ? 'scircle-2 outlined' : 'scircle-2'}>
                    <img src={checked.ctext === true ? tick : pen} className="text" alt="checked" />
                  </div>
                </Grid>
                <Grid item>
                  <span className="field-name ml1">
                    {props.isObserver
                      ? t('SignatureRequestDrawerInitials')
                      : `${t('SignatureRequestDrawerInitials')} (${lenghts.initials})`}
                  </span>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                className="check-all"
                onClick={() => {
                  affectTo(-1);
                  setModal('fileupload');
                  setInitialsPopUp(true);
                  setTab({
                    value: 'drawing',
                    element: 'initials',
                  });
                }}
              >
                <u style={{ visibility: isPdfLoading || props.isObserver ? 'hidden' : 'visible' }}>
                  {t('SignatureRequestDrawerApplyAll')}
                </u>
              </Grid>
              <Grid item md={4}></Grid>
              {props.signatureplacements?.custom_texts &&
                !props.isObserver &&
                props.signatureplacements?.custom_texts?.map((custom_text, index) => (
                  <Fragment key={index}>
                    {custom_text.type === 'initials' && (
                      <Grid
                        key={index}
                        container
                        spacing={0}
                        className={`item-strip${checked.ctext ? ' field-checked' : ''}`}
                        onClick={() => {
                          goTo(custom_text.placement.page);
                          scroller('custom_text', index);
                          setHighlightComponent(`custom_text${index}`);
                          animateCSS(`.custom-text-flash-${index}`, 'border-blink');
                        }}
                        justifyContent="space-between"
                      >
                        <Grid item container md={8} xs={8}>
                          <Grid item className="field-checkbox-wrapper">
                            <Checkbox
                              className="field-checkbox"
                              checked={!!ctext[index]}
                              checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                            />
                          </Grid>
                          <Grid item alignItems="center" className="field-name ml1">
                            {t('SignatureRequestDrawerInitials')}
                          </Grid>
                        </Grid>
                        <Grid item md={4} className="field-page">
                          {t('pageReverse') === 'yes'
                            ? `${custom_text.placement.page} ${t('SigningRequestDrawerPage')}`
                            : `${t('SigningRequestDrawerPage')} ${custom_text.placement.page}`}
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                ))}
              {/*Observer ctext nav data*/}
              {props.isObserver &&
                props.observeData?.map((signers) =>
                  signers?.custom_texts?.map((ctext, index) => {
                    return (
                      ctext.type === 'initials' && (
                        <Grid
                          container
                          key={index}
                          spacing={0}
                          className="item-strip"
                          onClick={() => {
                            goTo(ctext.placement.page);
                            scroller('custom_text', index);
                            setMobileOpen(false);
                            animateCSS(`.custom-text-${index}`, 'border-blink');
                          }}
                        >
                          <Grid item md={3}>
                            <Button
                              style={{
                                color: 'blue',
                                backgroundColor: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              <img
                                className="observer-image"
                                src={observerIcon}
                                alt="observer"
                              ></img>
                            </Button>
                          </Grid>
                          <Grid item md={5} className="field-name ml1">
                            {ctext.type.toUpperCase()}
                          </Grid>
                          <Grid item md={3} className="field-page">
                            {t('pageReverse') === 'yes'
                              ? `${ctext.placement.page} ${t('SigningRequestDrawerPage')}`
                              : `${t('SigningRequestDrawerPage')} ${ctext.placement.page}`}
                          </Grid>
                        </Grid>
                      )
                    );
                  }),
                )}
            </Grid>
          ) : (
            <></>
          )}
          {/* Stamp checkboxes */}
          {lenghts.stamp > 0 ? (
            <Grid
              container
              spacing={0}
              className={`${
                props.signatureplacements?.custom_texts &&
                props.signatureplacements?.custom_texts?.length > 0
                  ? 'item-strips-container'
                  : 'hidden'
              }${checked.ctext ? ' field-checked' : ''}`}
              justifyContent="space-between"
            >
              <Grid container alignItems="center" item md={8} sm={8} xs={8}>
                <Grid item>
                  <div className={checked.ctext === true ? 'scircle-2 outlined' : 'scircle-2'}>
                    <img
                      src={checked.ctext === true ? tick : StampIcon}
                      className="stamp-icon"
                      alt="checked"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <span className="field-name ml1">
                    {props.isObserver
                      ? t('SignatureRequestDrawerStamp')
                      : `${t('SignatureRequestDrawerStamp')} (${lenghts.stamp})`}
                  </span>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                className="check-all"
                onClick={() => {
                  affectTo(-1);
                  setModal('fileupload');
                  setStampPopUp(true);
                }}
              >
                <u style={{ visibility: isPdfLoading || props.isObserver ? 'hidden' : 'visible' }}>
                  {t('SignatureRequestDrawerApplyAll')}
                </u>
              </Grid>
              <Grid item md={4}></Grid>
              {props.signatureplacements?.custom_texts &&
              !props.isObserver &&
              props.signatureplacements?.custom_texts?.length > 0 ? (
                props.signatureplacements?.custom_texts.map((custom_text, index) => (
                  <Fragment key={index}>
                    {custom_text.type === 'stamp' ? (
                      <Grid
                        key={index}
                        container
                        spacing={0}
                        className={`item-strip${checked.ctext ? ' field-checked' : ''}`}
                        onClick={() => {
                          goTo(custom_text.placement.page);
                          scroller('custom_text', index);
                          setHighlightComponent(`custom_text${index}`);
                          animateCSS(`.custom-text-flash-${index}`, 'border-blink');
                        }}
                        justifyContent="space-between"
                      >
                        <Grid item container md={8} xs={8}>
                          <Grid item className="field-checkbox-wrapper">
                            <Checkbox
                              className="field-checkbox"
                              checked={!!ctext[index]}
                              checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                            />
                          </Grid>
                          <Grid item alignItems="center" className="field-name ml1">
                            {t('SignatureRequestDrawerStamp')}
                          </Grid>
                        </Grid>
                        <Grid item md={4} className="field-page">
                          {t('pageReverse') === 'yes'
                            ? `${custom_text.placement.page} ${t('SigningRequestDrawerPage')}`
                            : `${t('SigningRequestDrawerPage')} ${custom_text.placement.page}`}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))
              ) : (
                <></>
              )}
              {/*Observer ctext nav data*/}
              {props.isObserver &&
                props.observeData.map((signers) =>
                  signers?.custom_texts.map((ctext, index) => {
                    return (
                      ctext.type === 'stamp' && (
                        <Grid
                          container
                          key={index}
                          spacing={0}
                          className="item-strip"
                          onClick={() => {
                            goTo(ctext.placement.page);
                            scroller('custom_text', index);
                            setMobileOpen(false);
                            animateCSS(`.custom-text-${index}`, 'border-blink');
                          }}
                        >
                          <Grid item md={3}>
                            <Button
                              style={{
                                color: 'blue',
                                backgroundColor: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              <img
                                className="observer-image"
                                src={observerIcon}
                                alt="observer"
                              ></img>
                            </Button>
                          </Grid>
                          <Grid item md={5} className="field-name ml1">
                            {ctext.type.toUpperCase()}
                          </Grid>
                          <Grid item md={3} className="field-page">
                            {t('pageReverse') === 'yes'
                              ? `${ctext.placement.page} ${t('SigningRequestDrawerPage')}`
                              : `${t('SigningRequestDrawerPage')} ${ctext.placement.page}`}
                          </Grid>
                        </Grid>
                      )
                    );
                  }),
                )}
            </Grid>
          ) : (
            <></>
          )}
          {/*Date custom text checbox */}
          {lenghts?.date > 0 ? (
            <Grid
              container
              spacing={0}
              className={`${
                props.signatureplacements?.custom_texts?.length > 0
                  ? 'item-strips-container'
                  : 'hidden'
              }${checked.ctext ? ' field-checked' : ''}`}
              justifyContent="space-between"
            >
              <Grid container alignItems="center" item md={8} sm={8} xs={8}>
                <Grid item>
                  <div className={checked.ctext === true ? 'scircle-2 outlined' : 'scircle-2'}>
                    <img
                      src={checked.ctext === true ? tick : Calendar}
                      className="text"
                      alt="checked"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <span className="field-name ml1">
                    {props.isObserver
                      ? t('SignatureRequestDrawerDate')
                      : `${t('SignatureRequestDrawerDate')} (${lenghts.date})`}
                  </span>
                </Grid>
              </Grid>
              <Grid item md={4}></Grid>
              {!props.isObserver && props.signatureplacements?.custom_texts?.length > 0 ? (
                props.signatureplacements?.custom_texts.map((custom_text, index) => (
                  <Fragment key={index}>
                    {custom_text.type === 'actual-date' || custom_text.type === 'custom-date' ? (
                      <Grid
                        key={index}
                        container
                        spacing={0}
                        className={`item-strip${checked.ctext ? ' field-checked' : ''}`}
                        onClick={() => {
                          goTo(custom_text.placement.page);
                          scroller('custom_text', index);
                          setHighlightComponent(`custom_text${index}`);
                          animateCSS(`.custom-text-flash-${index}`, 'border-blink');
                        }}
                        justifyContent="space-between"
                      >
                        <Grid item container md={8} xs={8}>
                          <Grid item className="field-checkbox-wrapper">
                            <Checkbox
                              className="field-checkbox"
                              checked={!!ctext[index]}
                              checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                            />
                          </Grid>
                          <Grid item alignItems="center" className="field-name ml1">
                            {custom_text.type === 'actual-date'
                              ? t('SignatureRequestDrawerActualDate')
                              : custom_text.descriptor.toUpperCase()}
                          </Grid>
                        </Grid>
                        <Grid item md={4} className="field-page">
                          {t('pageReverse') === 'yes'
                            ? `${custom_text.placement.page} ${t('SigningRequestDrawerPage')}`
                            : `${t('SigningRequestDrawerPage')} ${custom_text.placement.page}`}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))
              ) : (
                <></>
              )}
              {/*Observer ctext nav data*/}
              {props.isObserver &&
                props.observeData?.map((signers) =>
                  signers?.custom_texts?.map((ctext, index) => {
                    return (
                      (ctext.type === 'actual-date' || ctext.type === 'custom-date') && (
                        <Grid
                          container
                          key={index}
                          spacing={0}
                          className="item-strip"
                          onClick={() => {
                            goTo(ctext.placement.page);
                            scroller('custom_text', index);
                            setMobileOpen(false);
                            animateCSS(`.custom-text-${index}`, 'border-blink');
                          }}
                        >
                          <Grid item md={3}>
                            <Button
                              style={{
                                color: 'blue',
                                backgroundColor: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              <img
                                className="observer-image"
                                src={observerIcon}
                                alt="observer"
                              ></img>
                            </Button>
                          </Grid>
                          <Grid item md={5} className="field-name ml1">
                            {ctext.descriptor.toUpperCase()}
                          </Grid>
                          <Grid item md={3} className="field-page">
                            {t('pageReverse') === 'yes'
                              ? `${ctext.placement.page} ${t('SigningRequestDrawerPage')}`
                              : `${t('SigningRequestDrawerPage')} ${ctext.placement.page}`}
                          </Grid>
                        </Grid>
                      )
                    );
                  }),
                )}
            </Grid>
          ) : (
            <></>
          )}

          {/*Confirmations tickbox*/}
          <Grid
            container
            spacing={0}
            className={`${
              props.signatureplacements?.confirmations &&
              props.signatureplacements?.confirmations?.length > 0
                ? 'item-strips-container'
                : 'hidden'
            }${checked.confirmations ? ' field-checked' : ''}`}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container alignItems="center" item md={8} sm={8} xs={8}>
              <Grid item>
                <div
                  className={checked.confirmations === true ? 'scircle-2 outlined' : 'scircle-2'}
                >
                  <img
                    src={checked.confirmations === true ? tick : pen}
                    className={checked.confirmations === true ? 'text' : 'pen'}
                    alt="ticked"
                  />
                </div>
              </Grid>
              <Grid item>
                <span className="field-name ml1">
                  {props.isObserver
                    ? `${t('SignatureRequestDrawerCheckbox')}`
                    : `${t('SignatureRequestDrawerCheckbox')} (${lenghts.confirmations})`}
                </span>
              </Grid>
            </Grid>
            {/* <Grid item md={4} className="check-all" onClick={() => {}}>
              <u style={{ visibility: isPdfLoading || props.isObserver ? 'hidden' : 'visible' }}>
                {t('SignatureRequestDrawerApplyAll')}
              </u>
            </Grid> */}
            {props.signatureplacements?.confirmations &&
              !props.isObserver &&
              props.signatureplacements?.confirmations?.map((confirmation, index) => (
                <Grid
                  container
                  key={index}
                  spacing={0}
                  className={`item-strip${checked.confirmations ? ' field-checked' : ''}`}
                  onClick={() => {
                    goTo(confirmation.placement.page);
                    scroller('confirmation', index);
                    setHighlightComponent(`checkbox${index}`);
                    highlightComponent === `checkbox${index}` &&
                      animateCSS(`.checkbox-flash-${index}`, 'background-blink');
                  }}
                  justifyContent="space-between"
                >
                  <Grid item container md={8} xs={8}>
                    <Grid item className="field-checkbox-wrapper">
                      <Checkbox
                        className="field-checkbox"
                        checked={confirmations[index]?.isChecked}
                        checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                      />
                    </Grid>
                    <Grid item alignItems="center" className="field-name ml1">
                      {t('SignatureRequestDrawerCheckbox')}
                    </Grid>
                  </Grid>
                  <Grid item md={4} className="field-page">
                    {t('pageReverse') === 'yes'
                      ? `${confirmation.placement.page} ${t('SigningRequestDrawerPage')}`
                      : `${t('SigningRequestDrawerPage')} ${confirmation.placement.page}`}
                  </Grid>
                </Grid>
              ))}
            {props.isObserver &&
              props.observeData?.map((signers) =>
                signers?.confirmations?.map((confirmation, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={0}
                    className="item-strip"
                    onClick={() => {
                      goTo(confirmation.placement.page);
                      scroller('confirmation', index);
                      setMobileOpen(false);
                      highlightComponent === `checkbox${index}` &&
                        animateCSS(`.checkbox-flash-${index}`, 'background-blink');
                    }}
                  >
                    <Grid item md={3}>
                      <Button
                        style={{ color: 'blue', backgroundColor: 'white', fontWeight: 'bolder' }}
                      >
                        <img className="observer-image" alt="observer" src={observerIcon}></img>
                      </Button>
                    </Grid>
                    <Grid item md={5} className="field-name ml1">
                      {t('SignatureRequestDrawerCheckbox')}
                    </Grid>
                    <Grid item md={3} className="field-page">
                      {t('pageReverse') === 'yes'
                        ? `${confirmation.placement.page} ${t('SigningRequestDrawerPage')}`
                        : `${t('SigningRequestDrawerPage')} ${confirmation.placement.page}`}
                    </Grid>
                  </Grid>
                )),
              )}
          </Grid>

          {/*Electronic signature tickbox */}
          <Grid
            container
            spacing={0}
            className={`${
              props.signatureplacements?.esignatures &&
              props.signatureplacements?.esignatures?.length > 0
                ? 'item-strips-container'
                : 'hidden'
            }${checked.esigs ? ' field-checked' : ''}`}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container alignItems="center" item md={8} sm={8} xs={8}>
              <Grid item>
                <div className={checked.esigs === true ? 'scircle-2 outlined' : 'scircle-2'}>
                  <img
                    src={checked.esigs === true ? tick : pen}
                    className={checked.esigs === true ? 'text' : 'pen'}
                    alt="ticked"
                  />
                </div>
              </Grid>
              <Grid item>
                <span className="field-name ml1">
                  {props.isObserver
                    ? t('SignatureRequestDrawerSignature')
                    : `${t('SignatureRequestDrawerSignature')} (${lenghts.esigs})`}
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              className="check-all"
              onClick={() => {
                affectTo(-1);
                setModal('fileupload');
                handleOpen();
              }}
            >
              <u style={{ visibility: isPdfLoading || props.isObserver ? 'hidden' : 'visible' }}>
                {t('SignatureRequestDrawerApplyAll')}
              </u>
            </Grid>
            {/*Esign tickbox */}
            {props.signatureplacements?.esignatures &&
              !props.isObserver &&
              props.signatureplacements?.esignatures?.map((esignature, index) => (
                <Grid
                  container
                  key={index}
                  spacing={0}
                  className={`item-strip${checked.esigs ? ' field-checked' : ''}`}
                  onClick={() => {
                    goTo(esignature.placement.page);
                    scroller('esignature', index);
                    setHighlightComponent(`esignature${index}`);
                    animateCSS(`.esignature-flash-${index}`, 'border-blink');
                  }}
                  justifyContent="space-between"
                >
                  <Grid item container md={8} xs={8}>
                    <Grid item className="field-checkbox-wrapper">
                      <Checkbox
                        className="field-checkbox"
                        checked={sigs[index] !== ' ' && !!sigs[index]}
                        checkedIcon={<CheckIcon style={{ color: '#27AE60' }} />}
                      />
                    </Grid>
                    <Grid item alignItems="center" className="field-name ml1">
                      {t('SignatureRequestDrawerSignature')}
                    </Grid>
                  </Grid>
                  <Grid item md={4} className="field-page">
                    {t('pageReverse') === 'yes'
                      ? `${esignature.placement.page} ${t('SigningRequestDrawerPage')}`
                      : `${t('SigningRequestDrawerPage')} ${esignature.placement.page}`}
                  </Grid>
                </Grid>
              ))}
            {/*Observer esign data navigation */}
            {props.isObserver &&
              props.observeData?.map((signers) =>
                signers?.esignatures?.map((esign, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={0}
                    className="item-strip"
                    onClick={() => {
                      goTo(esign.placement.page);
                      scroller('esignature', index);
                      setMobileOpen(false);
                      highlightComponent === `checkbox${index}` &&
                        animateCSS(`.esignature-${index}`, 'background-blink');
                    }}
                  >
                    <Grid item md={3}>
                      <Button
                        style={{ color: 'blue', backgroundColor: 'white', fontWeight: 'bolder' }}
                      >
                        <img className="observer-image" src={observerIcon} alt="observer"></img>
                      </Button>
                    </Grid>
                    <Grid item md={5} className="field-name ml1">
                      {t('SignatureRequestDrawerESignature')}
                    </Grid>
                    <Grid item md={3} className="field-page">
                      {t('pageReverse') === 'yes'
                        ? `${esign.placement.page} ${t('SigningRequestDrawerPage')}`
                        : `${t('SigningRequestDrawerPage')} ${esign.placement.page}`}
                    </Grid>
                  </Grid>
                )),
              )}
          </Grid>

          {/*Gary added digital signature tickbox */}
          <Grid
            container
            spacing={0}
            className={`${
              props.signatureplacements?.digi_signatures &&
              props.signatureplacements?.digi_signatures.length > 0
                ? 'item-strips-container'
                : 'hidden'
            }${checked.digisigs ? ' field-checked' : ''}`}
          >
            <Grid container alignItems="center" item md={8} sm={8} xs={8}>
              <Grid item>
                <div
                  className={
                    checked.digisigs === true || isCompleted ? 'scircle-2 outlined' : 'scircle-2'
                  }
                >
                  <img
                    src={checked.digisigs === true || isCompleted ? tick : pen}
                    className={checked.digisigs === true || isCompleted ? 'text' : 'pen'}
                    alt="ticked"
                  />
                </div>
              </Grid>
              <Grid item>
                <span className="field-name ml1">
                  {props.isObserver ? (
                    t('SignatureRequestDrawerSignature')
                  ) : (
                    <>
                      {t('SignatureRequestDrawerSignature')} ({lenghts.digisigs}){' '}
                    </>
                  )}
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              className="check-all"
              onClick={() => {
                if (
                  (checked.ctext && checked.confirmations) ||
                  (!props.signatureplacements?.custom_texts?.length &&
                    !props.signatureplacements?.confirmations?.length)
                ) {
                  affectTo(-1);
                  setModal('fileupload');
                  handleOpen();
                } else {
                  setSnackBarOpen(true);
                  setSeverity('warning');
                  setSnackBarMessage(t('SignatureRequestFillAllFieldsBeforeSigning'));
                }
              }}
            >
              <u
                style={{
                  visibility:
                    isPdfLoading || isCompleted || props.isObserver ? 'hidden' : 'visible',
                }}
              >
                {t('SignatureRequestDrawerApplyAll')}
              </u>
            </Grid>
            {!props.isObserver &&
              props.signatureplacements?.digi_signatures?.map((digisignature, index) => (
                <Grid
                  container
                  key={index}
                  spacing={0}
                  className={`item-strip${checked.digisigs ? ' field-checked' : ''}`}
                  onClick={() => {
                    if (
                      (checked.ctext && checked.confirmations) ||
                      (!props.signatureplacements?.custom_texts?.length &&
                        !props.signatureplacements?.confirmations?.length)
                    ) {
                      goTo(digisignature.placement.page);
                      scroller('digisignature', index);
                      setHighlightComponent(`digisignature${index}`);
                      animateCSS(`.digisignature-flash-${index}`, 'border-blink');
                    } else {
                      setSnackBarOpen(true);
                      setSeverity('warning');
                      setSnackBarMessage(t('SignatureRequestFillAllFieldsBeforeSigning'));
                    }
                  }}
                >
                  <Grid item container md={8} xs={8}>
                    <Grid item className="field-checkbox-wrapper">
                      <Checkbox
                        checked={(sigs[index] !== ' ' && !!sigs[index]) || isCompleted}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </Grid>
                    <Grid item alignItems="center" className="field-name ml1">
                      {t('SignatureRequestDrawerSignature')}
                    </Grid>
                  </Grid>
                  <Grid item md={4} className="field-page">
                    {t('pageReverse') === 'yes'
                      ? `${digisignature.placement.page} ${t('SigningRequestDrawerPage')}`
                      : `${t('SigningRequestDrawerPage')} ${digisignature.placement.page}`}
                  </Grid>
                </Grid>
              ))}
            {/*Observer digi sign nav data */}
            {props.isObserver &&
              props.observeData?.map((signers) =>
                signers?.digi_signatures?.map((digisign, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={0}
                    className="item-strip"
                    onClick={() => {
                      goTo(digisign.placement.page);
                      scroller('digisignature', index);
                      setMobileOpen(false);
                      highlightComponent === `checkbox${index}` &&
                        animateCSS(`.digisignature-${index}`, 'border-blink');
                    }}
                  >
                    <Grid item md={3}>
                      <Button
                        style={{ color: 'blue', backgroundColor: 'white', fontWeight: 'bolder' }}
                      >
                        <img className="observer-image" src={observerIcon} alt="" />
                      </Button>
                    </Grid>
                    <Grid item md={5} className="field-name ml1">
                      {t('SignatureRequestDrawerDigiSign')}
                    </Grid>
                    <Grid item md={3} className="field-page">
                      {t('pageReverse') === 'yes'
                        ? `${digisign.placement.page} ${t('SigningRequestDrawerPage')}`
                        : `${t('SigningRequestDrawerPage')} ${digisign.placement.page}`}
                    </Grid>
                  </Grid>
                )),
              )}
          </Grid>
        </div>
      </Grid>

      {props.isApprover && !isSubmitApproveSuccess && !props.documentUpdatedApprovement && (
        <Grid container className="p2 top-border">
          {!isMdDown && (
            <Grid container spacing={0}>
              <Grid item md={12} sm={12}>
                <p className="signing-terms m0">
                  {t('SignatureRequestDisclaimerStamps2')}{' '}
                  <a
                    href="https://dedoco.com/terms-of-use/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('SignatureRequestDisclaimerTermsOfUse')}
                  </a>{' '}
                  {t('SignatureRequestAnd')}{' '}
                  <a
                    href="https://dedoco.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('SignatureRequestDisclaimerPrivacyPolicy')}
                  </a>
                  .
                </p>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} className="mt1">
            {/* <Grid item xs={4}>
              <Button variant="outlined" className="title-text dark-btn w-100" onClick={() => {}}>
                {t('SignatureRequestCancelButton')}
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className="title-text h-100 w-100"
                disabled={!checked.isApproved || isSubmitApprove || props.isApprovingDocument}
                onClick={() => {
                  submitApprove();
                }}
              >
                {t('SignatureRequestSaveButton')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {props.isApprover && (isSubmitApproveSuccess || props.documentUpdatedApprovement) && (
        <Grid item xs={12} className="p2">
          <Button
            variant="outlined"
            className="exit w-100"
            onClick={() => {
              setTimeout(() => {
                if (props.publicVariable) {
                  history.push(`/public/done/${props.id}/${props.uid}`);
                } else {
                  history.push('/done/' + props.id + '/' + props.uid);
                }
              }, 1000);
            }}
          >
            {t('SignatureRequestDrawerExitButton')}
          </Button>
        </Grid>
      )}

      {props.isSigner && (
        <Grid container spacing={0} className="p2">
          <Grid item md={12} sm={12} xs={12}>
            {/*Gary added logic if custom texts is not added */}
            {!isCompleted ? (
              <>
                {TnCMessage && (
                  <TermsAndConditionsCheckbox
                    isPublic
                    onError={onTnCAgreementError}
                    onChange={(isChecked) => setAgreedToTnC(isChecked)}
                    label={TnCMessage}
                    participant={props.participant}
                    businessProcessId={props.id}
                  />
                )}
                <Button
                  disabled={
                    (TnCMessage && !agreedToTnC) ||
                    submitDisabled ||
                    isPdfLoading ||
                    (props.publicVariable && isCompleted) ||
                    !(
                      (checked.ctext === true ||
                        props.signatureplacements?.custom_texts?.length === 0) &&
                      checked.confirmations &&
                      (checked.esigs === true || (props.publicVariable && isUsingNDI)) &&
                      !isCompleted
                    )
                  }
                  variant="contained"
                  className="blue w-100 mt2"
                  onClick={handleSubmit}
                >
                  <span className="save-finish">
                    {t('SignatureRequestDrawerSaveNFinishButton')}
                  </span>
                </Button>
                <div style={{ height: '100px' }}></div>
                {showCloseButton && (
                  <Button
                    variant="contained"
                    className="w-100 mt05"
                    sx={{
                      backgroundColor: '#f0f0f0',
                    }}
                    onClick={() => {
                      setMobileOpen(false);
                    }}
                  >
                    <span className="save-finish" style={{ color: 'grey' }}>
                      Close
                    </span>
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="blue w-100"
                onClick={() => {
                  //Gary added condition for future custom texts filling
                  download(manualFinalPdf, props.FileName, 'application/pdf');

                  setTimeout(() => {
                    history.push('/done/' + props.id + '/' + props.uid);
                  }, 3000);
                }}
              >
                {t('SignatureRequestDownloadPDFButton')}
              </Button>
            )}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {isCompleted && (
              <Button
                variant="outlined"
                className="exit mt05 w-100"
                onClick={() => {
                  setTimeout(() => {
                    if (props.publicVariable) {
                      history.push(`/public/done/${props.id}/${props.uid}`);
                    } else {
                      history.push('/done/' + props.id + '/' + props.uid);
                    }
                  }, 1000);
                }}
              >
                {t('SignatureRequestDrawerExitButton')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <br />
    </Grid>
  );

  return (
    <Root id="signing-request" className={classes.root}>
      <CssBaseline />
      {openApiVideoSignEnabled && props.meeting?.enable && (
        <div
          id="meeting-lobby"
          className={classes.meetingLobby}
          style={{ alignItems: props.isJoinedMeeting ? 'flex-start' : 'center' }}
        >
          {props.isJoinedMeeting ? (
            <Room
              documentId={documentId}
              recipientId={uid}
              meetingToken={props.meetingToken}
              meeting={props.meeting}
              isMeetingHost={props.isMeetingHost}
              onLeave={onClickLeaveMeeting}
              setIsRecording={props.setIsRecording}
              startRecording={onClickStartRecording}
              stopRecording={onClickStopRecording}
              isRecording={props.isRecording}
              localTracks={props.localTracks}
              hasDownload={
                props?.isSigner &&
                props?.document?.document_share_method === 'dss' &&
                !props?.isSignCompleted &&
                props.isDownloadable
              }
              showVSNotification={props.showVSNotification}
              conversation={props.conversation}
            />
          ) : (
            <JoinMeeting
              localTracks={props.localTracks}
              meeting={props.meeting}
              participant={props.participant}
              documentId={documentId}
              conversation={props.conversation}
              isMeetingHost={props.isMeetingHost}
              bpId={bpId}
              isOpenApi={true}
            />
          )}
        </div>
      )}
      <AppBar position="fixed" className={classes.appBar} enableColorOnDark>
        <Toolbar style={{ backgroundColor: 'aliceblue' }}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item xs>
              <div style={{ textAlign: 'left' }}>
                <MobileViewLogo src={props.whiteLabelDetails.logo} alt="logo" />
              </div>
            </Grid>
            <Grid item>
              <div style={{ display: 'flex' }}>
                {props.isApprover && (
                  <div
                    onClick={() => setOpenDeclineModal(true)}
                    className={`${props.isApprovingDocument ? 'disabled-btn' : 'yellow-btn'}`}
                    style={{
                      padding: '0.5em 1em',
                      marginRight: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {t('SignatureRequestDrawerDecline')}
                  </div>
                )}

                {((props?.isSigner &&
                  props?.document?.document_share_method === 'dss' &&
                  !props?.isSignCompleted &&
                  props.isDownloadable) ||
                  (props.isObserver && props?.observer?.is_downloadable)) && (
                  <div
                    onClick={() => setOpenDownloadPdfModal(true)}
                    style={{
                      backgroundColor: '#3565E3',
                      color: 'white',
                      display: 'flex',
                      padding: '0.8em 0.5em',
                    }}
                    className={`${props.isApprovingDocument ? 'disabled-btn' : 'yellow-btn'}`}
                  >
                    <img src={DownloadIcon} alt="download" style={{ marginRight: 8 }} />
                    <span>{t('HeaderDownloadBtn')}</span>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="open drawer"
                // edge="start"
                // className={classes.menuButton + ' menu-iconn'}
                onClick={handleDrawerToggle}
                size="large"
              >
                <Badge
                  badgeContent={
                    props.isSigner
                      ? lenghts.ctext + lenghts.esigs + lenghts.digisigs + lenghts.date
                      : null
                  }
                  className={'require-fields-badge'}
                >
                  <MenuIcon style={{ float: 'right' }} />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h6" noWrap></Typography>
        </Toolbar>
      </AppBar>
      <main
        style={{
          marginTop: dimensions.width < 600 ? PDF_VIEWER_PADDING_TOP_ON_SMALL_PX : 0,
          ...(props.isObserver ? { width: '100%', height: '100%' } : {}),
        }}
        className={'main-open-api'}
        ref={leftContainer}
      >
        <div className="leftrack" ref={leftrack}>
          {/*Observer banner*/}
          {props.isObserver && (
            <Grid
              container
              spacing={0}
              style={{
                backgroundColor: 'lightblue',
                color: 'black',
                height: 100,
              }}
              className="doc-view-header"
            >
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={6} className="empty">
                <b>{t('DocumentViewerObserver')}</b>
              </Grid>
              <Grid item xs={6} md={4} className="empty">
                <Grid container spacing={0}>
                  <Grid item xs={6} sm={3} md={2}></Grid>
                  <Grid item xs={3} sm={9} md={10} className="text-align-left"></Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <PdfViewer
            isObserver={props.isObserver}
            setIsPdfLoading={setIsPdfLoading}
            pdf={props.pdf}
            zoom={0.8}
            FileName={props.FileName}
            className="pdf"
            getDimensions={true}
            setPdfHeight={setPdfHeight}
            setPdfWidth={setPdfWidth}
            setPdfLeft={setPdfLeft}
            setPdfHeaderHeight={setPdfHeaderHeight}
          />

          <div className="upper-panel">
            {!props.isObserver &&
            !isSubmitted &&
            props.signatureplacements?.esignatures &&
            props.signatureplacements?.esignatures?.length > 0 ? (
              props.signatureplacements?.esignatures.map((esignature, index) => {
                let placementX = esignature.placement.x.includes('%')
                  ? esignature.placement.x
                  : parseFloat(esignature.placement.x) * 100 + '%';
                let placementY = esignature.placement.y.includes('%')
                  ? esignature.placement.y
                  : parseFloat(esignature.placement.y) * 100 + '%';
                let signatureHeight = esignature.dimensions.height.includes('px')
                  ? autoScale(esignature.dimensions.height, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * esignature.dimensions.height + 'px',
                      PdfViewerStates.zoom,
                    );
                let signatureWidth = esignature.dimensions.width.includes('px')
                  ? autoScale(esignature.dimensions.width, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * esignature.dimensions.height * 2 + 'px',
                      PdfViewerStates.zoom,
                    );

                return esignature.placement.page === PdfViewerStates.currentPage &&
                  PdfViewerStates.totalPages ? (
                  <YellowTooltip
                    open={
                      (sigs[index] === ' ' || !sigs[index]) &&
                      `esignature${index}` === highlightComponent
                    }
                    title={t('SignatureSignerComponentGuideLabel')}
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <div
                      className={`
                        signer-component
                        esignature-flash-${index}
                        ${`esignature${index}` === highlightComponent && 'highlight-component'}
                      `}
                      key={index}
                      style={{
                        zIndex: index + 20,
                        top: placementY,
                        left: placementX,
                        height: signatureHeight,
                        width: signatureWidth,
                      }}
                    >
                      <img
                        className={
                          sigs[index] === '' || sigs[index] === undefined || sigs[index] === ' '
                            ? 'Signature-bg'
                            : 'Signature-bgcom'
                        }
                        src={
                          sigs[index] === '' || sigs[index] === undefined || sigs[index] === ' '
                            ? i18n.language === 'en'
                              ? Signature
                              : SignatureJp
                            : sigbox
                        }
                        onClick={() => {
                          affectTo(index);
                          setModal('fileupload');
                          handleOpen();
                        }}
                        alt="signature"
                      />
                      <img
                        style={{
                          visibility:
                            sigs[index] === '' || sigs[index] === undefined || sigs[index] === ' '
                              ? 'hidden'
                              : 'visible',
                          bottom: signatureHeight + 5,
                        }}
                        className={
                          sigs[index] === '' || sigs[index] === undefined || sigs[index] === ' '
                            ? 'sign-block'
                            : isMobile
                            ? 'sign-block-completed-iphone'
                            : 'sign-block-completed'
                        }
                        src={
                          sigs[index] === '' || sigs[index] === undefined || sigs[index] === ' '
                            ? ' '
                            : sigs[index]
                        }
                        onClick={() => {
                          affectTo(index);
                          setModal('fileupload');
                          handleOpen();
                        }}
                        alt="signature"
                      />
                    </div>
                  </YellowTooltip>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}

            {/* Signer confirmation checkbox*/}
            {!props.isObserver && !isSubmitted && confirmations && confirmations?.length > 0 ? (
              confirmations.map((confirmation, index) => {
                let placementX = confirmation?.placement?.x.includes('%')
                  ? confirmation?.placement?.x
                  : parseFloat(confirmation?.placement?.x) * 100 + '%';
                let placementY = confirmation?.placement?.y.includes('%')
                  ? confirmation?.placement?.y
                  : parseFloat(confirmation?.placement?.y) * 100 + '%';
                let confirmationHeight = confirmation?.dimensions?.height.includes('px')
                  ? autoScale(confirmation?.dimensions?.height, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * confirmation?.dimensions?.height + 'px',
                      PdfViewerStates.zoom,
                    );
                let confirmationWidth = confirmation?.dimensions?.width.includes('px')
                  ? autoScale(confirmation?.dimensions?.width, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * confirmation?.dimensions?.height * 2 + 'px',
                      PdfViewerStates.zoom,
                    );

                return confirmation?.placement?.page === PdfViewerStates?.currentPage &&
                  PdfViewerStates?.totalPages ? (
                  <YellowTooltip
                    open={
                      confirmation?.isRequired &&
                      !confirmations[index]?.isChecked &&
                      `checkbox${index}` === highlightComponent
                    }
                    title={t('SignatureSignerComponentGuideLabel')}
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <div
                      className={`${
                        `checkbox${index}` === highlightComponent && 'highlight-checkbox'
                      }
                      confirmation-component`}
                      key={index}
                      style={{
                        position: 'absolute',
                        zIndex: index + 20,
                        top: placementY,
                        left: placementX,
                        height: confirmationHeight,
                        width: confirmationWidth,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CustomCheckBox
                        color={props.signatureplacements?.color}
                        style={{
                          width: '70%',
                          height: '70%',
                        }}
                        onChange={(_e) => {
                          setHighlightComponent('');
                          handleConfirmation(index);
                        }}
                        checked={confirmation?.isChecked}
                      />
                      {confirmation?.isRequired ? <span className="required-mark">*</span> : null}
                      <div
                        id="top"
                        className={`checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: props.signatureplacements?.color,
                          width: '100%',
                          height: 2,
                          top: -1,
                        }}
                      ></div>
                      <div
                        id="bottom"
                        className={`checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: props.signatureplacements?.color,
                          width: '100%',
                          height: 2,
                          bottom: -1,
                        }}
                      ></div>
                      <div
                        id="left"
                        className={`checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: props.signatureplacements?.color,
                          width: 2,
                          height: '100%',
                          left: -1,
                        }}
                      ></div>
                      <div
                        id="right"
                        className={`checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: props.signatureplacements?.color,
                          width: 2,
                          height: '100%',
                          right: -1,
                        }}
                      ></div>
                      <div
                        id="top-right"
                        className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          right: -3,
                          top: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="top-left"
                        className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          left: -3,
                          top: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="bottom-right"
                        className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          right: -3,
                          bottom: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="bottom-left"
                        className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          left: -3,
                          bottom: -3,
                          borderRadius: '50%',
                        }}
                      ></div>

                      {/* </div> */}
                    </div>
                  </YellowTooltip>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}

            {/*Observer confirmation checkbox*/}
            {(props.isObserver || props.isApprover) &&
              !isSubmitted &&
              props.observeData?.map((signer) => {
                return signer?.confirmations?.map((confirmation, index) => {
                  let placementX = confirmation?.placement?.x.includes('%')
                    ? confirmation?.placement?.x
                    : parseFloat(confirmation?.placement?.x) * 100 + '%';
                  let placementY = confirmation?.placement?.y.includes('%')
                    ? confirmation?.placement?.y
                    : parseFloat(confirmation?.placement?.y) * 100 + '%';
                  let confirmationHeight = confirmation?.dimensions?.height?.includes('px')
                    ? autoScale(confirmation?.dimensions?.height, PdfViewerStates?.zoom)
                    : autoScale(
                        pdfHeight * confirmation?.dimensions?.height + 'px',
                        PdfViewerStates.zoom,
                      );
                  let confirmationWidth = confirmation?.dimensions?.width?.includes('px')
                    ? autoScale(confirmation?.dimensions?.width, PdfViewerStates?.zoom)
                    : autoScale(
                        pdfHeight * confirmation?.dimensions?.height * 2 + 'px',
                        PdfViewerStates?.zoom,
                      );

                  return confirmation?.placement?.page === PdfViewerStates?.currentPage &&
                    PdfViewerStates?.totalPages &&
                    !isCompleted ? (
                    <div
                      className="confirmation-component"
                      key={index}
                      style={{
                        position: 'absolute',
                        zIndex: index + 20,
                        top: placementY,
                        left: placementX,
                        height: confirmationHeight,
                        width: confirmationWidth,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'transparent',
                      }}
                    >
                      <CustomCheckBox
                        color={signer.color}
                        style={{
                          width: '70%',
                          height: '70%',
                        }}
                        checked={confirmation?.isChecked}
                      />
                      {confirmation?.isRequired ? <span className="required-mark">*</span> : null}
                      <div
                        id="top"
                        style={{
                          position: 'absolute',
                          backgroundColor: signer?.color,
                          width: '100%',
                          height: 2,
                          top: -1,
                        }}
                      ></div>
                      <div
                        id="bottom"
                        style={{
                          position: 'absolute',
                          backgroundColor: signer?.color,
                          width: '100%',
                          height: 2,
                          bottom: -1,
                        }}
                      ></div>
                      <div
                        id="left"
                        style={{
                          position: 'absolute',
                          backgroundColor: signer?.color,
                          width: 2,
                          height: '100%',
                          left: -1,
                        }}
                      ></div>
                      <div
                        id="right"
                        style={{
                          position: 'absolute',
                          backgroundColor: signer?.color,
                          width: 2,
                          height: '100%',
                          right: -1,
                        }}
                      ></div>
                      <div
                        id="top-right"
                        className="checkbox-edge"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          right: -3,
                          top: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="top-left"
                        className="checkbox-edge"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          left: -3,
                          top: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="bottom-right"
                        className="checkbox-edge"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          right: -3,
                          bottom: -3,
                          borderRadius: '50%',
                        }}
                      ></div>
                      <div
                        id="bottom-left"
                        className="checkbox-edge"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#4F4F4F',
                          width: 6,
                          height: 6,
                          left: -3,
                          bottom: -3,
                          borderRadius: '50%',
                        }}
                      ></div>

                      {/* </div> */}
                    </div>
                  ) : (
                    <></>
                  );
                });
              })}
            {/*Observer electronic signature*/}
            {!isUsingNDI &&
              props.isObserver &&
              props.observeData?.map((signer) => {
                // eslint-disable-next-line array-callback-return
                return signer?.esignatures?.map((esign, index) => {
                  if (
                    esign.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages
                  ) {
                    let placementX = esign.placement.x.includes('%')
                      ? esign.placement.x
                      : parseFloat(esign.placement.x) * 100 + '%';
                    let placementY = esign.placement.y.includes('%')
                      ? esign.placement.y
                      : parseFloat(esign.placement.y) * 100 + '%';
                    if (dimensions.width < 600) {
                      // Account for the 56px padding added to PdfViewer
                      const additionalPercent =
                        (PDF_VIEWER_PADDING_TOP_ON_SMALL_PX / dimensions.height) *
                        100 *
                        MOBILE_VIEW_PLACEMENT_OFFSET_FACTOR;
                      placementY = `${
                        parseFloat(placementY.slice(0, placementY.length - 1)) + additionalPercent
                      }%`;
                    }
                    let signatureHeight = esign.dimensions.height.includes('px')
                      ? autoScale(esign.dimensions.height, PdfViewerStates.zoom)
                      : autoScale(pdfHeight * esign.dimensions.height + 'px', PdfViewerStates.zoom);
                    let signatureWidth = esign.dimensions.height.includes('px')
                      ? autoScale(esign.dimensions.width, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * esign.dimensions.height * 2 + 'px',
                          PdfViewerStates.zoom,
                        );
                    let signerFont = esign.dimensions.height.includes('px')
                      ? esign.dimensions.height
                      : pdfHeight * esign.dimensions.height + 'px';
                    if (signer.has_signed) {
                      return (
                        <div
                          className="signer-component"
                          key={index}
                          style={{
                            zIndex: index + 20,
                            top: placementY,
                            left: placementX,
                            height: signatureHeight,
                            width: signatureWidth,
                          }}
                        >
                          <img
                            style={{
                              height: signatureHeight,
                              width: signatureWidth,
                            }}
                            className={'text-filled'}
                            src={sigbox}
                            alt="text-filled"
                          />
                          <img
                            className={'sign-block-completed'}
                            src={esign.signature}
                            style={{
                              fontSize: autoScale(
                                signerFont.split('px')[0] * 0.2 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              top: '20%',
                              position: 'absolute',
                            }}
                            alt="sign-block"
                          />
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled text-block-filled-signature-completed'
                                : 'text-block-filled-mobile text-block-filled-mobile-signature-completed'
                            }
                            style={{
                              fontSize: autoScale(
                                signerFont.split('px')[0] * 0.1 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              bottom: '-35%',
                              position: 'absolute',
                              right: '0%',
                              color: 'white',
                              backgroundColor: signer.color,
                            }}
                          >
                            {/*signer_name changed to name */}
                            {t('SignatureRequestSigner')}{' '}
                            {signer.signer_name ? signer.signer_name : signer.name}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="signer-component"
                          key={index}
                          style={{
                            zIndex: index + 20,
                            top: placementY,
                            left: placementX,
                            height: signatureHeight,
                            width: signatureWidth,
                          }}
                        >
                          <img
                            style={{
                              height: signatureHeight,
                              width: signatureWidth,
                            }}
                            className={'text-filled'}
                            src={i18n.language === 'en' ? Signature : SignatureJp}
                            alt="text-filled"
                          />
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled text-block-filled-signature'
                                : 'text-block-filled-mobile text-block-filled-mobile-signature'
                            }
                            style={{
                              fontSize: autoScale(
                                signerFont.split('px')[0] * 0.2 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              bottom: '30%',
                              position: 'absolute',
                              backgroundColor: signer.color,
                            }}
                          >
                            {/*signer_name changed to name */}
                            {t('SignatureRequestSigner')}{' '}
                            {signer.signer_name ? signer.signer_name : signer.name}
                          </div>
                        </div>
                      );
                    }
                  }
                });
              })}

            {/*Observer's custom texts*/}
            {(props.isObserver || props.isApprover) &&
              !isSubmitted &&
              props.observeData?.map((signer) => {
                // eslint-disable-next-line array-callback-return
                return signer?.custom_texts?.map((custom_text, index) => {
                  if (
                    custom_text.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages
                  ) {
                    let placementX = custom_text.placement.x.includes('%')
                      ? custom_text.placement.x
                      : parseFloat(custom_text.placement.x) * 100 + '%';
                    let placementY = custom_text.placement.y.includes('%')
                      ? custom_text.placement.y
                      : parseFloat(custom_text.placement.y) * 100 + '%';
                    if (dimensions.width < 600) {
                      // Account for the 56px padding added to PdfViewer
                      const additionalPercent =
                        (PDF_VIEWER_PADDING_TOP_ON_SMALL_PX / dimensions.height) *
                        100 *
                        MOBILE_VIEW_PLACEMENT_OFFSET_FACTOR;
                      placementY = `${
                        parseFloat(placementY.slice(0, placementY.length - 1)) + additionalPercent
                      }%`;
                    }
                    let customTextHeight = custom_text.dimensions.height.includes('px')
                      ? autoScale(custom_text.dimensions.height, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * custom_text.dimensions.height + 'px',
                          PdfViewerStates.zoom,
                        );
                    let customTextWidth = custom_text.dimensions.width.includes('px')
                      ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                      : autoScale(
                          pdfWidth * custom_text.dimensions.width + 'px',
                          PdfViewerStates.zoom,
                        );
                    let customDateWidth = custom_text.dimensions.width.includes('px')
                      ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * custom_text.dimensions.height * 4 + 'px',
                          PdfViewerStates.zoom,
                        );
                    let customTextFont = custom_text.dimensions.height.includes('px')
                      ? custom_text.dimensions.height
                      : pdfHeight * custom_text.dimensions.height + 'px';
                    return (
                      <div
                        id={`custom_text`}
                        className="signer-component"
                        key={index}
                        style={{
                          zIndex: custom_text.type === 'custom-date' ? index + 30 : index + 20,
                          top: placementY,
                          left: placementX,
                          height: customTextHeight,
                          width:
                            custom_text.type === 'custom-date' || custom_text.type === 'actual-date'
                              ? customDateWidth
                              : customTextWidth,
                          borderRadius: custom_text.type === 'stamp' ? '50%' : 6,
                        }}
                      >
                        {custom_text.type === 'actual-date' ||
                        custom_text.type === 'custom-date' ||
                        custom_text.type === 'initials' ||
                        custom_text.type === 'stamp' ? (
                          <>
                            {(custom_text.type === 'actual-date' ||
                              custom_text.type === 'custom-date') && (
                              <img
                                className={
                                  (ctext[index] === '' ||
                                    ctext[index] === undefined ||
                                    ctext[index] === ' ') &&
                                  !custom_text.descriptor
                                    ? 'Signature-bg'
                                    : 'text-fillled'
                                }
                                src={
                                  (ctext[index] === '' ||
                                    ctext[index] === undefined ||
                                    ctext[index] === ' ') &&
                                  !custom_text.descriptor
                                    ? i18n.language === 'en'
                                      ? Signature
                                      : SignatureJp
                                    : sigbox
                                }
                                alt="signature"
                              />
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {/*Modified scaling issue with custom text */}
                        {custom_text.type === 'actual-date' ? (
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled'
                                : 'text-block-filled-mobile'
                            }
                            style={{
                              fontSize: autoScale(
                                customTextFont.split('px')[0] * 0.3 + 'px',
                                PdfViewerStates.zoom,
                              ),
                            }}
                          >
                            {signer.has_signed ? custom_text.text : 'Actual Date'}

                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled text-block-filled-actual-date'
                                  : 'text-block-filled-mobile text-block-filled-mobile-actual-date'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.15 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: '-50%',
                                position: 'absolute',
                                color: 'white',
                                right: '0%',
                                backgroundColor: signer.color,
                              }}
                            >
                              {/*signer_name changed to name */}
                              {t('SignatureRequestSigner')}{' '}
                              {signer.signer_name ? signer.signer_name : signer.name}
                            </div>
                          </div>
                        ) : custom_text.type === 'custom-date' ? (
                          <>
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.3 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: 0,
                                position: 'absolute',
                              }}
                            >
                              {signer.has_signed ? custom_text.text : 'Custom Date'}
                              <div
                                className={
                                  dimensions.width > 960
                                    ? 'text-block-filled text-block-filled-custom-date'
                                    : 'text-block-filled-mobile text-block-filled-mobile-custom-date'
                                }
                                style={{
                                  fontSize: autoScale(
                                    customTextFont.split('px')[0] * 0.15 + 'px',
                                    PdfViewerStates.zoom,
                                  ),
                                  top: '-100%',
                                  position: 'absolute',
                                  color: 'white',
                                  right: '0%',
                                  backgroundColor: signer.color,
                                }}
                              >
                                {/*signer_name changed to name */}
                                {t('SignatureRequestSigner')}{' '}
                                {signer.signer_name ? signer.signer_name : signer.name}
                              </div>
                            </div>
                          </>
                        ) : custom_text.type === 'initials' ? (
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled'
                                : 'text-block-filled-mobile'
                            }
                            style={{
                              fontSize: autoScale(
                                customTextFont.split('px')[0] * 0.3 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              bottom: '25%',
                              position: 'absolute',
                            }}
                          >
                            {signer.has_signed ? (
                              <img
                                className={
                                  (ctext[index] === '' ||
                                    ctext[index] === undefined ||
                                    ctext[index] === ' ') &&
                                  !custom_text.descriptor
                                    ? 'Signature-bg'
                                    : 'text-fillled'
                                }
                                src={custom_text.text}
                                alt="signature"
                              />
                            ) : (
                              'Initials'
                            )}
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled text-block-filled-initials'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.15 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: '-50%',
                                position: 'absolute',
                                color: 'white',
                                right: '0%',
                                backgroundColor: signer.color,
                              }}
                            >
                              {/*signer_name changed to name */}
                              {t('SignatureRequestSigner')}{' '}
                              {signer.signer_name
                                ? getInitals(signer.signer_name)
                                : getInitals(signer.name)}
                            </div>
                          </div>
                        ) : custom_text.type === 'stamp' ? (
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled'
                                : 'text-block-filled-mobile'
                            }
                            style={{
                              fontSize: autoScale(
                                customTextFont.split('px')[0] * 0.2 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              bottom: '30%',
                              position: 'absolute',
                            }}
                          >
                            {signer.has_signed ? (
                              <img
                                className={
                                  (ctext[index] === '' ||
                                    ctext[index] === undefined ||
                                    ctext[index] === ' ') &&
                                  !custom_text.descriptor
                                    ? 'Signature-bg'
                                    : 'text-fillled'
                                }
                                src={custom_text.text}
                                alt="signature"
                              />
                            ) : (
                              'Stamp'
                            )}
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled text-block-filled-stamp'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.1 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: '-60%',
                                position: 'absolute',
                                color: 'white',
                                right: '0%',
                                backgroundColor: signer.color,
                              }}
                            >
                              {/*signer_name changed to name */}
                              {t('SignatureRequestSigner')}{' '}
                              {signer.signer_name
                                ? getInitals(signer.signer_name)
                                : getInitals(signer.name)}
                            </div>
                          </div>
                        ) : custom_text.descriptor ? (
                          <input
                            disabled
                            type="text"
                            placeholder={
                              custom_text.descriptor
                                ? custom_text.descriptor.toUpperCase()
                                : 'Custom Text'
                            }
                            className={'text-block-filled-input'}
                            style={{
                              fontSize:
                                autoScale(
                                  customTextFont.split('px')[0] * 0.4 + 'px',
                                  PdfViewerStates.zoom,
                                ) - 4,
                              textAlign: 'left',
                              padding: 10,
                            }}
                            defaultValue={
                              signer.has_signed
                                ? custom_text.text
                                : custom_text.descriptor.toUpperCase()
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }
                });
              })}
            {/*Observer for digital signature */}
            {isUsingNDI &&
              (props.isObserver || props.isApprover) &&
              !isSubmitted &&
              props.observeData?.map((signer) => {
                // eslint-disable-next-line array-callback-return
                return signer?.digi_signatures?.map((digisign, index) => {
                  if (
                    digisign.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages
                  ) {
                    let placementX = digisign.placement.x.includes('%')
                      ? digisign.placement.x
                      : parseFloat(digisign.placement.x) * 100 + '%';
                    let placementY = digisign.placement.y.includes('%')
                      ? digisign.placement.y
                      : parseFloat(digisign.placement.y) * 100 + '%';
                    return (
                      <div
                        className="signer-component"
                        key={index}
                        style={{
                          zIndex: index + 20,
                          top: placementY,
                          left: placementX,
                          height: autoScale('40px', PdfViewerStates.zoom),
                          width: autoScale('80px', PdfViewerStates.zoom),
                        }}
                      >
                        <img
                          style={{
                            height: autoScale('40px', PdfViewerStates.zoom),
                            width: autoScale('80px', PdfViewerStates.zoom),
                          }}
                          className={'text-filled'}
                          src={sigbox}
                          alt="text-filled"
                        />
                        <div
                          className={
                            dimensions.width > 960
                              ? 'text-block-filled'
                              : 'text-block-filled-mobile'
                          }
                          style={{
                            fontSize: autoScale(40 * 0.2 + 'px', PdfViewerStates.zoom),
                            bottom: '35%',
                            position: 'absolute',
                          }}
                        >
                          {signer.has_signed
                            ? `${signer.signer_name ? signer.signer_name : signer.name} has signed`
                            : `${t('SignatureRequestSigner')} ${
                                signer.signer_name ? signer.signer_name : signer.name
                              }`}
                        </div>
                      </div>
                    );
                  }
                });
              })}

            {/*Display other signer's electronic signature placeholder*/}
            {!isSubmitted &&
              props.allSigners?.map((signers) =>
                signers?.esignatures?.map((esign, index) => {
                  if (
                    esign.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages &&
                    ((!signers.has_signed && props.publicVariable) || !props.publicVariable)
                  ) {
                    let placementX = esign.placement.x.includes('%')
                      ? esign.placement.x
                      : parseFloat(esign.placement.x) * 100 + '%';
                    let placementY = esign.placement.y.includes('%')
                      ? esign.placement.y
                      : parseFloat(esign.placement.y) * 100 + '%';
                    let signatureHeight = esign.dimensions.height.includes('px')
                      ? autoScale(esign.dimensions.height, PdfViewerStates.zoom)
                      : autoScale(pdfHeight * esign.dimensions.height + 'px', PdfViewerStates.zoom);
                    let signatureWidth = esign.dimensions.height.includes('px')
                      ? autoScale(esign.dimensions.width, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * esign.dimensions.height * 2 + 'px',
                          PdfViewerStates.zoom,
                        );
                    let signerFont = esign.dimensions.height.includes('px')
                      ? esign.dimensions.height
                      : pdfHeight * esign.dimensions.height + 'px';

                    return (
                      <div
                        className="signer-component"
                        key={index}
                        style={{
                          zIndex: index + 20,
                          top: placementY,
                          left: placementX,
                          height: signatureHeight,
                          width: signatureWidth,
                        }}
                      >
                        {!esign.signature && (
                          <img
                            style={{
                              height: signatureHeight,
                              width: signatureWidth,
                            }}
                            className={'text-filled'}
                            src={i18n.language === 'en' ? Signature : SignatureJp}
                            alt="text-filled"
                          />
                        )}
                        <div
                          className={
                            dimensions.width > 960
                              ? 'text-block-filled'
                              : 'text-block-filled-mobile'
                          }
                          style={{
                            fontSize: autoScale(
                              signerFont.split('px')[0] * 0.2 + 'px',
                              PdfViewerStates.zoom,
                            ),
                            bottom: 'auto',
                            position: 'absolute',
                            background: signers?.color,
                            padding: '0px 4%',
                            top: '-14%',
                            left: '10%',
                            color: '#fff',
                            borderRadius: 4,
                            width: 'auto',
                            height: 'auto',
                          }}
                        >
                          {/*signer_name changed to name */}
                          {t('SignatureRequestSigner')}
                          {signers.signer_name ? signers.signer_name : signers.name}
                        </div>
                        {esign.signature && (
                          <img
                            className={'sign-block-completed'}
                            src={esign.signature}
                            style={{
                              fontSize: autoScale(
                                signerFont.split('px')[0] * 0.2 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              top: '20%',
                              position: 'absolute',
                            }}
                            alt="sign-block"
                          />
                        )}
                      </div>
                    );
                  }
                  return '';
                }),
              )}
            {/*Gary digisig logic*/}
            {!props.isObserver &&
            props.signatureplacements?.digi_signatures?.length > 0 &&
            !isCompleted ? (
              props.signatureplacements?.digi_signatures?.map((digisignature, index) =>
                //Gary added additional condition to load the elements after pages are loaded
                {
                  let placementX = digisignature.placement.x.includes('%')
                    ? digisignature.placement.x
                    : parseFloat(digisignature.placement.x) * 100 + '%';
                  let placementY = digisignature.placement.y.includes('%')
                    ? digisignature.placement.y
                    : parseFloat(digisignature.placement.y) * 100 + '%';
                  return digisignature.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages ? (
                    <YellowTooltip
                      open={
                        (sigs[index] === ' ' || !sigs[index]) &&
                        `digisignature${index}` === highlightComponent
                      }
                      title={t('SignatureSignerComponentGuideLabel')}
                      arrow
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                    >
                      <div
                        className="signer-component"
                        key={index}
                        style={{
                          zIndex: index + 20,
                          top: placementY,
                          left: placementX,
                          height: autoScale('40px', PdfViewerStates.zoom),
                          width: autoScale('80px', PdfViewerStates.zoom),
                        }}
                      >
                        <img
                          // fixed size for digital signature
                          style={{
                            top: 0,
                            height: autoScale('40px', PdfViewerStates.zoom),
                            width: autoScale('80px', PdfViewerStates.zoom),
                          }}
                          src="/Signature area on PDF.svg"
                          onClick={async () => {
                            if (
                              (checked.ctext && checked.confirmations) ||
                              (!props.signatureplacements?.custom_texts?.length &&
                                !props.signatureplacements?.confirmations?.length)
                            ) {
                              await finalsubmit();
                              affectTo(index);
                              setModal('fileupload');
                              handleOpen();
                            } else {
                              setSnackBarOpen(true);
                              setSeverity('warning');
                              setSnackBarMessage(t('SignatureRequestFillAllFieldsBeforeSigning'));
                            }
                          }}
                          alt="sign area"
                        />
                      </div>
                    </YellowTooltip>
                  ) : (
                    <></>
                  );
                },
              )
            ) : (
              <></>
            )}
            {/*Display other signer's digital signature placeholder*/}
            {!isSubmitted &&
              props.allSigners?.map((signers) =>
                signers?.digi_signatures?.map((digisign, index) => {
                  if (
                    digisign.placement.page === PdfViewerStates.currentPage &&
                    PdfViewerStates.totalPages &&
                    !signers.has_signed
                  ) {
                    let placementX = digisign.placement.x.includes('%')
                      ? digisign.placement.x
                      : parseFloat(digisign.placement.x) * 100 + '%';
                    let placementY = digisign.placement.y.includes('%')
                      ? digisign.placement.y
                      : parseFloat(digisign.placement.y) * 100 + '%';
                    return (
                      <div
                        className="signer-component"
                        key={index}
                        style={{
                          zIndex: index + 20,
                          top: placementY,
                          left: placementX,
                          height: autoScale('40px', PdfViewerStates.zoom),
                          width: autoScale('80px', PdfViewerStates.zoom),
                        }}
                      >
                        <img
                          style={{
                            height: autoScale('40px', PdfViewerStates.zoom),
                            width: autoScale('80px', PdfViewerStates.zoom),
                          }}
                          className={'text-filled'}
                          src={sigbox}
                          alt="text-filled"
                        />
                        <div
                          className={
                            dimensions.width > 960
                              ? 'text-block-filled'
                              : 'text-block-filled-mobile'
                          }
                          style={{
                            fontSize: autoScale(40 * 0.2 + 'px', PdfViewerStates.zoom),
                            bottom: '20%',
                            position: 'absolute',
                          }}
                        >
                          {t('SignatureRequestSigner')}{' '}
                          {signers.signer_name ? signers.signer_name : signers.name}
                        </div>
                      </div>
                    );
                  }
                  return '';
                }),
              )}
            {/* Signer's custom texts */}
            {!props.isObserver &&
            !isSubmitted &&
            props.signatureplacements?.custom_texts?.length > 0 ? (
              props.signatureplacements?.custom_texts?.map((custom_text, index) => {
                let placementX = custom_text.placement.x.includes('%')
                  ? custom_text.placement.x
                  : parseFloat(custom_text.placement.x) * 100 + '%';
                let placementY = custom_text.placement.y.includes('%')
                  ? custom_text.placement.y
                  : parseFloat(custom_text.placement.y) * 100 + '%';
                if (dimensions.width < 600) {
                  // Account for the 56px padding added to PdfVf* 200iewer
                  const additionalPercent =
                    (PDF_VIEWER_PADDING_TOP_ON_SMALL_PX / dimensions.height) *
                    100 *
                    MOBILE_VIEW_PLACEMENT_OFFSET_FACTOR;
                  placementY = `${
                    parseFloat(placementY.slice(0, placementY.length - 1)) + additionalPercent
                  }%`;
                }
                let customTextHeight = custom_text.dimensions.height.includes('px')
                  ? autoScale(custom_text.dimensions.height, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * custom_text.dimensions.height + 'px',
                      PdfViewerStates.zoom,
                    );
                let customTextWidth = custom_text.dimensions.width.includes('px')
                  ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                  : autoScale(pdfWidth * custom_text.dimensions.width + 'px', PdfViewerStates.zoom);
                let customDateWidth = custom_text.dimensions.width.includes('px')
                  ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                  : autoScale(
                      pdfHeight * custom_text.dimensions.height * 4 + 'px',
                      PdfViewerStates.zoom,
                    );
                let customTextFont = custom_text.dimensions.height.includes('px')
                  ? custom_text.dimensions.height
                  : pdfHeight * custom_text.dimensions.height + 'px';

                return custom_text.placement.page === PdfViewerStates.currentPage &&
                  PdfViewerStates.totalPages ? (
                  <YellowTooltip
                    placement={custom_text.type === 'custom-date' ? 'left' : 'bottom'}
                    open={
                      (ctext[index] === ' ' || !ctext[index]) &&
                      `custom_text${index}` === highlightComponent
                    }
                    title={t('SignatureSignerComponentGuideLabel')}
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <div
                      className={`
                        ${`custom_text${index}` === highlightComponent && 'highlight-component'}
                        ${custom_text.type === 'stamp' && 'stamp-border-radius'}
                        ${custom_text.type === 'initials' && 'initials-border-radius'}
                        custom-text-flash-${index}
                        signer-component
                      `}
                      key={index}
                      style={{
                        zIndex: custom_text.descriptor === 'Custom Date' ? index + 30 : index + 20,
                        top: placementY,
                        left: placementX,
                        height: customTextHeight,
                        width:
                          custom_text.type === 'custom-date' || custom_text.type === 'actual-date'
                            ? customDateWidth
                            : customTextWidth,
                      }}
                    >
                      {custom_text.type === 'actual-date' || custom_text.type === 'custom-date' ? (
                        <img
                          className={
                            (ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' ') &&
                            !custom_text.descriptor
                              ? 'Signature-bg'
                              : 'text-fillled'
                          }
                          src={
                            (ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' ') &&
                            !custom_text.descriptor
                              ? i18n.language === 'en'
                                ? Signature
                                : SignatureJp
                              : sigbox
                          }
                          alt="signature"
                        />
                      ) : (
                        <></>
                      )}

                      {/*Modified scaling issue with custom text */}
                      {custom_text.type === 'actual-date' ? (
                        <div
                          className={
                            dimensions.width > 960
                              ? 'text-block-filled'
                              : 'text-block-filled-mobile'
                          }
                          style={{
                            fontSize: autoScale(
                              customTextFont.split('px')[0] * 0.3 + 'px',
                              PdfViewerStates.zoom,
                            ),
                          }}
                        >
                          {getLocaleDateString(ctext[index])}
                        </div>
                      ) : custom_text.type === 'custom-date' ? (
                        <>
                          <div
                            className={
                              dimensions.width > 960
                                ? 'text-block-filled'
                                : 'text-block-filled-mobile'
                            }
                            style={{
                              fontSize: autoScale(
                                customTextFont.split('px')[0] * 0.3 + 'px',
                                PdfViewerStates.zoom,
                              ),
                              bottom: 0,
                              position: 'absolute',
                            }}
                          >
                            {ctext[index]
                              ? new Date(ctext[index]).toLocaleDateString('en-GB')
                              : t('SignatureRequestDrawerDate')}
                          </div>
                          <DatePicker
                            wrapperClassName="date-block-com"
                            selected={ctext[index]}
                            onChange={(date) => {
                              changetext(index, new Date(date).getTime(), 'date');
                              setHighlightComponent('');
                            }}
                            value={ctext[index]}
                            placeholderText="Date"
                          />
                        </>
                      ) : custom_text.type === 'initials' ? (
                        <>
                          <div
                            className="initials-box"
                            style={{
                              fontSize: autoScale(
                                customTextFont.split('px')[0] * 0.3 + 'px',
                                PdfViewerStates.zoom,
                              ),
                            }}
                            onClick={() => {
                              affectTo(index);
                              setModal('fileupload');
                              setInitialsPopUp(true);
                              setTab({
                                value: 'drawing',
                                element: 'initials',
                              });
                            }}
                          >
                            {(ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' ') && <p className="initials-text">Initials</p>}
                          </div>

                          <img
                            style={{
                              visibility:
                                ctext[index] === '' ||
                                ctext[index] === undefined ||
                                ctext[index] === ' '
                                  ? 'hidden'
                                  : 'visible',
                            }}
                            className={
                              ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' '
                                ? 'sign-block'
                                : 'initials-block-completed'
                            }
                            src={
                              ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' '
                                ? ''
                                : ctext[index]
                            }
                            onClick={() => {
                              affectTo(index);
                              setModal('fileupload');
                              setInitialsPopUp(true);
                              setTab({
                                value: 'drawing',
                                element: 'initials',
                              });
                            }}
                            alt="signature"
                          />
                        </>
                      ) : custom_text.type === 'stamp' ? (
                        <>
                          <div
                            className="stamp-box"
                            onClick={() => {
                              affectTo(index);
                              clearStamp();
                              setModal('fileupload');
                              setStampPopUp(true);
                              clearStamp();
                            }}
                          >
                            {(ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' ') && <p className="stamp-text">Stamp</p>}
                          </div>
                          <img
                            style={{
                              visibility:
                                ctext[index] === '' ||
                                ctext[index] === undefined ||
                                ctext[index] === ' '
                                  ? 'hidden'
                                  : 'visible',
                              bottom: 5,
                            }}
                            className={
                              ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' '
                                ? 'sign-block'
                                : isMobileOnly
                                ? 'stamp-block-completed-iphone'
                                : 'stamp-block-completed'
                            }
                            src={
                              ctext[index] === '' ||
                              ctext[index] === undefined ||
                              ctext[index] === ' '
                                ? ' '
                                : ctext[index]
                            }
                            onClick={() => {
                              affectTo(index);
                              setModal('fileupload');
                              setStampPopUp(true);
                              clearStamp();
                            }}
                            alt="signature"
                          />
                        </>
                      ) : (
                        custom_text.descriptor && (
                          <input
                            type="text"
                            placeholder={
                              custom_text.descriptor
                                ? custom_text.descriptor.toUpperCase()
                                : 'Custom Text'
                            }
                            className={
                              isMobile && isIOS
                                ? 'text-block-filled-input mobile-input-ctext'
                                : 'text-block-filled-input'
                            }
                            style={{
                              fontSize:
                                autoScale(
                                  customTextFont.split('px')[0] * 0.4 + 'px',
                                  PdfViewerStates.zoom,
                                ) - 4,
                              textAlign: 'left',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(e) => {
                              changetext(index, e.target.value, 'text');
                            }}
                            defaultValue={ctext[index]}
                          />
                        )
                      )}
                    </div>
                  </YellowTooltip>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}

            {/* Previous Signer's Custom Texts */}
            {props.isSigner &&
              !isSubmitted &&
              props.isSequentialSigning &&
              props.allSigners
                ?.filter((signer) => signer.has_signed)
                ?.map((signer) => {
                  // eslint-disable-next-line array-callback-return
                  return signer?.custom_texts?.map((custom_text, index) => {
                    if (
                      custom_text.placement.page === PdfViewerStates.currentPage &&
                      PdfViewerStates.totalPages
                    ) {
                      let placementX = custom_text.placement.x.includes('%')
                        ? custom_text.placement.x
                        : parseFloat(custom_text.placement.x) * 100 + '%';
                      let placementY = custom_text.placement.y.includes('%')
                        ? custom_text.placement.y
                        : parseFloat(custom_text.placement.y) * 100 + '%';
                      let customTextHeight = custom_text.dimensions.height.includes('px')
                        ? autoScale(custom_text.dimensions.height, PdfViewerStates.zoom)
                        : autoScale(
                            pdfHeight * custom_text.dimensions.height + 'px',
                            PdfViewerStates.zoom,
                          );
                      let customTextWidth = custom_text.dimensions.width.includes('px')
                        ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                        : autoScale(
                            pdfWidth * custom_text.dimensions.width + 'px',
                            PdfViewerStates.zoom,
                          );
                      let customDateWidth = custom_text.dimensions.width.includes('px')
                        ? autoScale(custom_text.dimensions.width, PdfViewerStates.zoom)
                        : autoScale(
                            pdfHeight * custom_text.dimensions.height * 4 + 'px',
                            PdfViewerStates.zoom,
                          );
                      let customTextFont = custom_text.dimensions.height.includes('px')
                        ? custom_text.dimensions.height
                        : pdfHeight * custom_text.dimensions.height + 'px';
                      return (
                        <div
                          id={`custom_text`}
                          className="signer-component"
                          key={index}
                          style={{
                            zIndex: custom_text.type === 'custom-date' ? index + 30 : index + 20,
                            top: placementY,
                            left: placementX,
                            height: customTextHeight,
                            width:
                              custom_text.type === 'custom-date' ||
                              custom_text.type === 'actual-date' ||
                              custom_text.type === 'initials' ||
                              custom_text.type === 'stamp'
                                ? customDateWidth
                                : customTextWidth,
                            borderRadius: custom_text.type === 'stamp' ? '50%' : 6,
                          }}
                        >
                          {custom_text.type === 'actual-date' ||
                          custom_text.type === 'custom-date' ||
                          custom_text.type === 'initials' ||
                          custom_text.type === 'stamp' ? (
                            <>
                              {(custom_text.type === 'actual-date' ||
                                custom_text.type === 'custom-date') && (
                                <img
                                  className={
                                    (ctext[index] === '' ||
                                      ctext[index] === undefined ||
                                      ctext[index] === ' ') &&
                                    !custom_text.descriptor
                                      ? 'Signature-bg'
                                      : 'text-fillled'
                                  }
                                  src={
                                    (ctext[index] === '' ||
                                      ctext[index] === undefined ||
                                      ctext[index] === ' ') &&
                                    !custom_text.descriptor
                                      ? i18n.language === 'en'
                                        ? Signature
                                        : SignatureJp
                                      : sigbox
                                  }
                                  alt="signature"
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/*Modified scaling issue with custom text */}
                          {custom_text.type === 'actual-date' ? (
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.3 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                              }}
                            >
                              {signer.has_signed ? custom_text.text : 'Actual Date'}

                              <div
                                className={
                                  dimensions.width > 960
                                    ? 'text-block-filled text-block-filled-actual-date'
                                    : 'text-block-filled-mobile text-block-filled-mobile-actual-date'
                                }
                                style={{
                                  fontSize: autoScale(
                                    customTextFont.split('px')[0] * 0.15 + 'px',
                                    PdfViewerStates.zoom,
                                  ),
                                  bottom: '-50%',
                                  position: 'absolute',
                                  color: 'white',
                                  right: '0%',
                                  backgroundColor: signer.color,
                                }}
                              >
                                {/*signer_name changed to name */}
                                {t('SignatureRequestSigner')}{' '}
                                {signer.signer_name ? signer.signer_name : signer.name}
                              </div>
                            </div>
                          ) : custom_text.type === 'custom-date' ? (
                            <>
                              <div
                                className={
                                  dimensions.width > 960
                                    ? 'text-block-filled'
                                    : 'text-block-filled-mobile'
                                }
                                style={{
                                  fontSize: autoScale(
                                    customTextFont.split('px')[0] * 0.3 + 'px',
                                    PdfViewerStates.zoom,
                                  ),
                                  bottom: 0,
                                  position: 'absolute',
                                }}
                              >
                                {signer.has_signed ? custom_text.text : 'Custom Date'}
                                <div
                                  className={
                                    dimensions.width > 960
                                      ? 'text-block-filled text-block-filled-custom-date'
                                      : 'text-block-filled-mobile text-block-filled-mobile-custom-date'
                                  }
                                  style={{
                                    fontSize: autoScale(
                                      customTextFont.split('px')[0] * 0.15 + 'px',
                                      PdfViewerStates.zoom,
                                    ),
                                    top: '-100%',
                                    position: 'absolute',
                                    color: 'white',
                                    right: '0%',
                                    backgroundColor: signer.color,
                                  }}
                                >
                                  {/*signer_name changed to name */}
                                  {t('SignatureRequestSigner')}{' '}
                                  {signer.signer_name ? signer.signer_name : signer.name}
                                </div>
                              </div>
                            </>
                          ) : custom_text.type === 'initials' ? (
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.3 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: '25%',
                                position: 'absolute',
                              }}
                            >
                              {signer.has_signed ? (
                                <img
                                  className={
                                    (ctext[index] === '' ||
                                      ctext[index] === undefined ||
                                      ctext[index] === ' ') &&
                                    !custom_text.descriptor
                                      ? 'Signature-bg'
                                      : 'text-fillled'
                                  }
                                  src={custom_text.text}
                                  alt="signature"
                                />
                              ) : (
                                'Initials'
                              )}
                              <div
                                className={
                                  dimensions.width > 960
                                    ? 'text-block-filled text-block-filled-initials'
                                    : 'text-block-filled-mobile'
                                }
                                style={{
                                  fontSize: autoScale(
                                    customTextFont.split('px')[0] * 0.15 + 'px',
                                    PdfViewerStates.zoom,
                                  ),
                                  bottom: '-50%',
                                  position: 'absolute',
                                  color: 'white',
                                  right: '0%',
                                  backgroundColor: signer.color,
                                }}
                              >
                                {/*signer_name changed to name */}
                                {t('SignatureRequestSigner')}{' '}
                                {signer.signer_name
                                  ? getInitals(signer.signer_name)
                                  : getInitals(signer.name)}
                              </div>
                            </div>
                          ) : custom_text.type === 'stamp' ? (
                            <div
                              className={
                                dimensions.width > 960
                                  ? 'text-block-filled'
                                  : 'text-block-filled-mobile'
                              }
                              style={{
                                fontSize: autoScale(
                                  customTextFont.split('px')[0] * 0.2 + 'px',
                                  PdfViewerStates.zoom,
                                ),
                                bottom: '30%',
                                position: 'absolute',
                              }}
                            >
                              {signer.has_signed ? (
                                <img
                                  className={
                                    (ctext[index] === '' ||
                                      ctext[index] === undefined ||
                                      ctext[index] === ' ') &&
                                    !custom_text.descriptor
                                      ? 'Signature-bg'
                                      : 'text-fillled'
                                  }
                                  src={custom_text.text}
                                  alt="signature"
                                />
                              ) : (
                                'Stamp'
                              )}
                              <div
                                className={
                                  dimensions.width > 960
                                    ? 'text-block-filled text-block-filled-stamp'
                                    : 'text-block-filled-mobile'
                                }
                                style={{
                                  fontSize: autoScale(
                                    customTextFont.split('px')[0] * 0.1 + 'px',
                                    PdfViewerStates.zoom,
                                  ),
                                  bottom: '-60%',
                                  position: 'absolute',
                                  color: 'white',
                                  right: '0%',
                                  backgroundColor: signer.color,
                                }}
                              >
                                {/*signer_name changed to name */}
                                {t('SignatureRequestSigner')}{' '}
                                {signer.signer_name
                                  ? getInitals(signer.signer_name)
                                  : getInitals(signer.name)}
                              </div>
                            </div>
                          ) : custom_text.descriptor ? (
                            <input
                              disabled
                              type="text"
                              placeholder={
                                custom_text.descriptor
                                  ? custom_text.descriptor.toUpperCase()
                                  : 'Custom Text'
                              }
                              className={'text-block-filled-input'}
                              style={{
                                fontSize:
                                  autoScale(
                                    customTextFont.split('px')[0] * 1 + 'px',
                                    PdfViewerStates.zoom,
                                  ) - 4,
                                textAlign: 'left',
                                padding: 10,
                              }}
                              defaultValue={
                                signer.has_signed
                                  ? custom_text.text
                                  : custom_text.descriptor.toUpperCase()
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }
                  });
                })}
            {/* Previous Signer's Confirmations */}
            {props.isSigner &&
              !isSubmitted &&
              props.isSequentialSigning &&
              props.allSigners
                ?.filter((signer) => signer.has_signed)
                ?.map((signer) => {
                  return signer?.confirmations?.map((confirmation, index) => {
                    let placementX = confirmation?.placement?.x.includes('%')
                      ? confirmation?.placement?.x
                      : parseFloat(confirmation?.placement?.x) * 100 + '%';
                    let placementY = confirmation?.placement?.y.includes('%')
                      ? confirmation?.placement?.y
                      : parseFloat(confirmation?.placement?.y) * 100 + '%';
                    let confirmationHeight = confirmation?.dimensions?.height.includes('px')
                      ? autoScale(confirmation?.dimensions?.height, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * confirmation?.dimensions?.height + 'px',
                          PdfViewerStates.zoom,
                        );
                    let confirmationWidth = confirmation?.dimensions?.width.includes('px')
                      ? autoScale(confirmation?.dimensions?.width, PdfViewerStates.zoom)
                      : autoScale(
                          pdfHeight * confirmation?.dimensions?.height * 2 + 'px',
                          PdfViewerStates.zoom,
                        );

                    return confirmation?.placement?.page === PdfViewerStates?.currentPage &&
                      PdfViewerStates?.totalPages ? (
                      <div
                        className={`${
                          `checkbox${index}` === highlightComponent && 'highlight-checkbox'
                        }
                      confirmation-component`}
                        key={index}
                        style={{
                          position: 'absolute',
                          zIndex: index + 20,
                          top: placementY,
                          left: placementX,
                          height: confirmationHeight,
                          width: confirmationWidth,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CustomCheckBox
                          color={signer?.color}
                          style={{
                            width: '70%',
                            height: '70%',
                          }}
                          checked={confirmation?.isChecked}
                        />
                        {confirmation?.isRequired ? <span className="required-mark">*</span> : null}
                        <div
                          id="top"
                          className={`checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: signer?.color,
                            width: '100%',
                            height: 2,
                            top: -1,
                          }}
                        ></div>
                        <div
                          id="bottom"
                          className={`checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: signer?.color,
                            width: '100%',
                            height: 2,
                            bottom: -1,
                          }}
                        ></div>
                        <div
                          id="left"
                          className={`checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: signer?.color,
                            width: 2,
                            height: '100%',
                            left: -1,
                          }}
                        ></div>
                        <div
                          id="right"
                          className={`checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: signer?.color,
                            width: 2,
                            height: '100%',
                            right: -1,
                          }}
                        ></div>
                        <div
                          id="top-right"
                          className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: '#4F4F4F',
                            width: 6,
                            height: 6,
                            right: -3,
                            top: -3,
                            borderRadius: '50%',
                          }}
                        ></div>
                        <div
                          id="top-left"
                          className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: '#4F4F4F',
                            width: 6,
                            height: 6,
                            left: -3,
                            top: -3,
                            borderRadius: '50%',
                          }}
                        ></div>
                        <div
                          id="bottom-right"
                          className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: '#4F4F4F',
                            width: 6,
                            height: 6,
                            right: -3,
                            bottom: -3,
                            borderRadius: '50%',
                          }}
                        ></div>
                        <div
                          id="bottom-left"
                          className={`checkbox-edge checkbox-border checkbox-flash-${index}`}
                          style={{
                            position: 'absolute',
                            backgroundColor: '#4F4F4F',
                            width: 6,
                            height: 6,
                            left: -3,
                            bottom: -3,
                            borderRadius: '50%',
                          }}
                        ></div>
                      </div>
                    ) : null;
                  });
                })}
          </div>
        </div>
      </main>
      {props.isSigner && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {isMdDown && (
            <Drawer
              variant="temporary"
              anchor="bottom"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerMobile,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer({ showCloseButton: true })}
            </Drawer>
          )}
          {isMdUp && (
            <Drawer
              anchor="right"
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer()}
            </Drawer>
          )}
        </nav>
      )}
      {/* Initials popup Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={initialsPopup}
        onClose={() => {
          setInitialsPopUp(false);
        }}
        closeAfterTransition
      >
        {modalStatus === 'fileupload' ? (
          <Paper
            className={clsx(classes.paper, 'modal-content')}
            sx={{
              pt: 2,
              px: 4,
              pb: 3,
              outline: 'none',
              width: '470px',
              borderRadius: '4px',
            }}
          >
            {/*Gary added image for signing pop up and remove circle*/}
            <Button
              className="close-button-2"
              onClick={() => {
                setInitialsPopUp(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className={viewSavedSignature ? 'hidden' : ''}>
              {!isMdDown && (
                <div id="transition-modal-title" className="signing-modal-title mt1">
                  {t('SignatureRequestDrawSubHeaderInitial')}
                </div>
              )}
              {!isMdUp && (
                <span id="transition-modal-title" className="signing-modal-title">
                  {t('SignatureRequestDrawSubHeader')}
                </span>
              )}
              <Tabs>
                <TabList className="signing-tabs">
                  <Tab
                    onClick={() => {
                      setAbled(false);
                      setTab({
                        value: 'drawing',
                        element: 'initials',
                      });
                    }}
                  >
                    {t('SignatureRequestDrawTab')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      setTab({
                        value: 'type',
                        element: 'initials',
                      });
                    }}
                  >
                    {t('SignatureRequestTypeTab')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      setAbled(false);
                      setTab({
                        value: 'upload',
                        element: 'initials',
                      });
                    }}
                  >
                    {t('SignatureRequestUploadTab')}
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="sig-canvas-wrapper">
                    <SignatureCanvas
                      penColor="black"
                      ref={initialsPad}
                      canvasProps={{
                        width: dimensions.width < 500 ? dimensions.width * 0.72 : 365,
                        height: 152,
                        className: 'sign-canvas',
                      }}
                      onBegin={() => setInitials({ value: '', type: 'drawing' })}
                      onEnd={() => {
                        setInitials({
                          value: initialsPad.current.toDataURL('image/png'),
                          type: 'drawing',
                        });
                        setAbled(true);
                      }}
                    />
                    <div className="draw-sign-desc line">{t('SignatureRequestInitialsHeader')}</div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    <TextField
                      inputProps={{ maxLength: 18 }}
                      id="outlined-basic"
                      label={t('SignatureRequestTypeTab')}
                      size="small"
                      value={typingInitials}
                      onChange={(e) => onTypeChangeInitials(e.target.value)}
                    />
                  </div>
                  <div className="canvas">
                    <canvas
                      id="my-canvas-initials"
                      width="370"
                      height="150"
                      ref={initialsCanvasPad}
                    ></canvas>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="m-0 mb-2 p-0 row drophere">
                    {abled === true && initials.type === 'upload' ? (
                      <center className="w-100">
                        <img className="selected-img" src={initialsFileUrl} alt="selected" />
                      </center>
                    ) : (
                      <FileDrop
                        className="Dropzone2"
                        onDrop={(files, _event) => {
                          const _file = files[0];

                          initialsFileUpload(_file);
                        }}
                      >
                        <center>
                          <h3 style={{ textAlign: 'center' }}>
                            {t('SignatureRequestUploadHeader')}
                          </h3>
                          <p>{t('SignatureRequestUploadSubHeader')}</p>
                          <div className="image-upload">
                            <input
                              accept="image/png, image/jpg, image/jpeg"
                              style={{ display: 'none' }}
                              id="initials-button-file"
                              onChange={(e) => initialsFileUpload(e.target.files[0])}
                              type="file"
                            />

                            <label htmlFor="initials-button-file">
                              <Button variant="contained" color="primary" component="span">
                                {t('SignatureRequestUploadChooseFileButton')}
                              </Button>
                            </label>
                          </div>
                        </center>
                      </FileDrop>
                    )}
                  </div>
                </TabPanel>
              </Tabs>

              <Grid container wrap={'nowrap'} spacing={1} className="mt1">
                <Grid item container wrap={'nowrap'} md={6} xs={6}></Grid>
              </Grid>

              <Grid container spacing={2} className="mt1">
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    className="title-text dark-btn w-100"
                    onClick={() => {
                      clearInitials();
                    }}
                  >
                    {t('SignatureRequestClearButton')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="title-text h-100 w-100"
                    onClick={() => {
                      saveInitials();
                      setInitialsPopUp(false);
                      setHighlightComponent('');
                      setAbled(false);
                    }}
                    disabled={!abled}
                  >
                    {t('SignatureRequestNextButton')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        ) : (
          <div className={classes.paper2}>
            <Button
              className="close-button-2"
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className="cancel-pop">
              <Grid container spacing={0}>
                <Grid item sm={12}>
                  <h2>{t('SignatureRequestSavedClosePromptHeader')} </h2>
                  <p>{t('SignatureRequestSavedClosePromptSubHeader')}</p>
                </Grid>
                <Grid item sm={6}>
                  {' '}
                </Grid>
                <Grid item sm={2}>
                  {' '}
                  <Button variant="outlined" className=" m-05" onClick={() => setOpen(false)}>
                    {t('SignatureRequestSavedClosePromptNoButton')}{' '}
                  </Button>
                </Grid>
                <Grid item sm={4}>
                  <a href="https://dedoco.com/">
                    <Button
                      variant="contained"
                      color="primary"
                      className="w-100 m-05"
                      onClick={async () => {
                        await resetsigs();
                        setOpen(false);
                      }}
                    >
                      {t('SignatureRequestSavedClosePromptYesButton')}
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Modal>
      {/* Stamp popup modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={stampPopUp}
        onClose={() => {
          setStampPopUp(false);
        }}
        closeAfterTransition
      >
        {modalStatus === 'fileupload' ? (
          <div className={classes.paper}>
            {/*Gary added image for signing pop up and remove circle*/}
            <Button
              className="close-button-2"
              onClick={() => {
                setStampPopUp(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className={viewSavedSignature ? 'hidden' : ''}>
              {!isMdDown && (
                <div id="transition-modal-title" className="signing-modal-title mt1">
                  {t('SignatureRequestDrawSubHeaderStamp')}
                </div>
              )}
              <Tabs>
                <TabList className="signing-tabs">
                  <Tab onClick={() => setAbled(false)}>{t('SignatureRequestUploadTab')}</Tab>
                </TabList>

                <TabPanel>
                  <div className="m-0 mb-2 p-0 row drophere">
                    {abled === true && stamp.type === 'upload' ? (
                      <center className="w-100">
                        <img className="selected-img" src={stampFileUrl} alt="selected" />
                      </center>
                    ) : (
                      <FileDrop
                        className="Dropzone2"
                        onDrop={(files, _event) => {
                          const _file = files[0];

                          stampFileUpload(_file);
                        }}
                      >
                        <center>
                          <h3 style={{ textAlign: 'center' }}>
                            {t('SignatureRequestUploadHeader')}
                          </h3>
                          <p>{t('SignatureRequestUploadSubHeader')}</p>
                          <div className="image-upload">
                            <input
                              accept="image/png, image/jpg, image/jpeg"
                              style={{ display: 'none' }}
                              id="stamp-button-file"
                              onChange={(e) => stampFileUpload(e.target.files[0])}
                              type="file"
                            />

                            <label htmlFor="stamp-button-file">
                              <Button variant="contained" color="primary" component="span">
                                {t('SignatureRequestUploadChooseFileButton')}
                              </Button>
                            </label>
                          </div>
                        </center>
                      </FileDrop>
                    )}
                  </div>
                </TabPanel>
              </Tabs>

              <Grid container wrap={'nowrap'} spacing={1} className="mt1">
                <Grid item container wrap={'nowrap'} md={6} xs={6}></Grid>
              </Grid>

              {!isMdDown && (
                <Grid container spacing={0}>
                  <Grid item md={12} sm={12}>
                    <p className="signing-terms">
                      {t('SignatureRequestDisclaimerStamps')}{' '}
                      <a
                        href="https://dedoco.com/terms-of-use/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('SignatureRequestDisclaimerTermsOfUse')}
                      </a>{' '}
                      &{' '}
                      <a
                        href="https://dedoco.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('SignatureRequestDisclaimerPrivacyPolicy')}
                      </a>
                      .
                    </p>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} className="mt1">
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    className="title-text dark-btn w-100"
                    onClick={() => {
                      clearStamp();
                    }}
                  >
                    {t('SignatureRequestClearButton')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="title-text h-100 w-100"
                    onClick={() => {
                      saveStamp();
                      setStampPopUp(false);
                      setHighlightComponent('');
                    }}
                    disabled={!abled}
                  >
                    {t('SignatureRequestNextButton')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <div className={classes.paper2}>
            <Button
              className="close-button-2"
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className="cancel-pop">
              <Grid container spacing={0}>
                <Grid item sm={12}>
                  {' '}
                  <h2>{t('SignatureRequestSavedClosePromptHeader')} </h2>
                  <p>{t('SignatureRequestSavedClosePromptSubHeader')}</p>
                </Grid>
                <Grid item sm={6}>
                  {' '}
                </Grid>
                <Grid item sm={2}>
                  {' '}
                  <Button variant="outlined" className=" m-05" onClick={() => setOpen(false)}>
                    {t('SignatureRequestSavedClosePromptNoButton')}{' '}
                  </Button>
                </Grid>
                <Grid item sm={4}>
                  {' '}
                  <a href="https://dedoco.com/">
                    <Button
                      variant="contained"
                      color="primary"
                      className="w-100 m-05"
                      onClick={async () => {
                        await resetsigs();
                        setOpen(false);
                      }}
                    >
                      {t('SignatureRequestSavedClosePromptYesButton')}
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Modal>

      {/* Signature popup modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        {modalStatus === 'fileupload' ? (
          <Paper
            className={clsx(classes.paper, 'modal-content')}
            sx={{
              pt: 2,
              px: 4,
              pb: 3,
              outline: 'none',
              width: '470px',
              borderRadius: '4px',
            }}
          >
            {!isMdDown && (
              <img
                style={{
                  width: '130px',
                  height: '100px',
                  transform: 'translate(150px,0)',
                }}
                src="/Signing Option.png"
                alt="signing"
                className="signing-image"
              />
            )}
            <Button
              className="close-button-2"
              onClick={() => {
                setViewSaveSignature(false);
                setSelectedSavedSignature(null);
                setOpen(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className={viewSavedSignature ? 'hidden' : ''}>
              {!isMdDown && (
                <div id="transition-modal-title" className="signing-modal-title mt1">
                  {t('SignatureRequestDrawHeader')}
                </div>
              )}
              {!isMdUp && (
                <span id="transition-modal-title" className="signing-modal-title">
                  {t('SignatureRequestDrawSubHeader')}
                </span>
              )}
              <Tabs>
                <TabList className="signing-tabs">
                  <Tab
                    onClick={() => {
                      setAbled(false);
                      setTab({
                        value: 'drawing',
                        element: 'signature',
                      });
                    }}
                  >
                    {t('SignatureRequestDrawTab')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      setTab({
                        value: 'type',
                        element: 'signature',
                      });
                    }}
                  >
                    {t('SignatureRequestTypeTab')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      setAbled(false);
                      setTab({
                        value: 'upload',
                        element: 'signature',
                      });
                    }}
                  >
                    {t('SignatureRequestUploadTab')}
                  </Tab>
                </TabList>
                {savedSignatures?.length > 0 && (
                  <div className="mt1 mb2 noti-message">
                    <InfoOutlined />{' '}
                    {t('SignatureRequestSavedSignaturePromptHeader', { numSavedSignatures })}
                  </div>
                )}
                <TabPanel>
                  <div className="sig-canvas-wrapper">
                    <SignatureCanvas
                      penColor="black"
                      ref={sigpad}
                      canvasProps={{
                        width: dimensions.width < 500 ? dimensions.width * 0.72 : 365,
                        height: 152,
                        className: 'sign-canvas',
                      }}
                      onBegin={() => setSignature({ value: '', sigtype: 'drawing' })}
                      onEnd={() => {
                        setSignature({
                          value: sigpad.current.toDataURL('image/png'),
                          sigtype: 'drawing',
                        });
                        setAbled(true);
                      }}
                    />
                    <div className="draw-sign-desc line">
                      {t('SignatureRequestSignatureHeader')}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    <TextField
                      inputProps={{ maxLength: 18 }}
                      id="outlined-basic"
                      label={t('SignatureRequestTypeTab')}
                      size="small"
                      value={typingSignature}
                      onChange={(e) => onTypeChange(e.target.value)}
                    />
                  </div>
                  <div className="canvas">
                    <canvas id="my-canvas" width="370" height="150" ref={canvaspad}></canvas>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="m-0 mb-2 p-0 row drophere">
                    <Button
                      className="clear-button"
                      onClick={() => clearUploadSignature()}
                      style={{ display: abled === true ? 'block' : 'none' }}
                    >
                      {t('SignatureRequestClearButton')}
                    </Button>
                    {abled === true && mySignature.sigtype === 'upload' ? (
                      <center className="w-100">
                        <img className="selected-img" src={fileUrl} alt="selected" />
                      </center>
                    ) : (
                      <FileDrop
                        className="Dropzone2"
                        onDrop={(files, _event) => {
                          const _file = files[0];

                          fileupload(_file);
                        }}
                      >
                        <center>
                          <h3 style={{ textAlign: 'center' }}>
                            {t('SignatureRequestUploadHeader')}
                          </h3>
                          <p>{t('SignatureRequestUploadSubHeader')}</p>
                          <div className="image-upload">
                            <input
                              accept="image/png, image/jpg, image/jpeg"
                              style={{ display: 'none' }}
                              id="contained-button-file"
                              onChange={(e) => fileupload(e.target.files[0])}
                              type="file"
                            />

                            <label htmlFor="contained-button-file">
                              <Button variant="contained" color="primary" component="span">
                                {t('SignatureRequestUploadChooseFileButton')}
                              </Button>
                            </label>
                          </div>
                        </center>
                      </FileDrop>
                    )}
                  </div>
                </TabPanel>
              </Tabs>

              {/* <Grid container wrap={'nowrap'} spacing={1} className="mt1">
                <Grid item container wrap={'nowrap'} md={6} xs={6}>
                  <Grid item className="field-checkbox-wrapper">
                    <Checkbox
                      checked={checkedSaveSignature}
                      onChange={() => setCheckedSaveSignature(!checkedSaveSignature)}
                      color="primary"
                    />
                  </Grid>
                  <Grid item alignItems="center" className="field-name ml1 xs-text">
                    {t('SignatureRequestSaveSignatureCheckBox')}{' '}
                  </Grid>
                  <Grid item alignItems="center">
                    <ClickAwayListener onClickAway={() => setOpenTooltipSaveSignature(false)}>
                      <Tooltip
                        open={openTooltipSaveSignature}
                        onClose={() => setOpenTooltipSaveSignature(false)}
                        onOpen={() => setOpenTooltipSaveSignature(true)}
                        arrow={true}
                        placement="top"
                        title={
                          <span style={{ fontSize: '0.75rem' }}>
                            {t('SignatureRequestSaveSignatureHint')}
                          </span>
                        }
                      >
                        <div
                          className="tooltip-button"
                          onClick={() => setOpenTooltipSaveSignature(!openTooltipSaveSignature)}
                        >
                          <InfoOutlined />
                        </div>
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="title-text h-100 w-100 xs-text"
                    onClick={() => {
                      setViewSaveSignature(true);
                    }}
                    disabled={savedSignatures?.length <= 0}
                  >
                    {t('SignatureRequestSavedSingatureButton')}
                  </Button>
                </Grid>
              </Grid> */}

              <Grid container spacing={2} className="mt1">
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    className="title-text dark-btn w-100"
                    onClick={() => {
                      clearDrawSignature();
                      clearUploadSignature();
                      onTypeChange('');
                    }}
                  >
                    {t('SignatureRequestClearButton')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="title-text h-100 w-100"
                    onClick={() => {
                      savesignature();
                      setOpen(false);
                      setHighlightComponent('');
                    }}
                    disabled={!abled}
                  >
                    {t('SignatureRequestNextButton')}
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={!viewSavedSignature ? 'hidden' : ''}>
              <div id="transition-modal-title" className="signing-modal-title">
                {t('SignatureRequestSavedSignatureHeader')}
              </div>
              <div className="saved-signature-wrapper">
                <div className="amount-saved">
                  {savedSignatures?.length} {t('SignatureRequestSavedSignatureSubHeader')}
                </div>
                <div className="signatures-wrapper">
                  {savedSignatures && (
                    <div
                      className="signature-item"
                      onClick={() => {
                        setSelectedSavedSignature(savedSignatures[0]);
                        setSignature({ value: savedSignatures[0], sigtype: 'upload' });
                      }}
                    >
                      <div className="radio-button-wrapper">
                        <Radio checked={!!selectedSavedSignature} />
                      </div>
                      <img src={savedSignatures[0]} alt="saved-signature" />
                    </div>
                  )}
                </div>
              </div>
              <Grid container spacing={2} className="mt1">
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    className="title-text dark-btn w-100"
                    onClick={() => {
                      setSelectedSavedSignature(null);
                      setViewSaveSignature(false);
                    }}
                  >
                    {t('SignatureRequestSavedSignatureBackButton')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="title-text h-100 w-100"
                    onClick={async () => {
                      savesignature();
                      setViewSaveSignature(false);
                      setSelectedSavedSignature(null);
                      setOpen(false);
                    }}
                    disabled={!selectedSavedSignature}
                  >
                    {t('SignatureRequestSavedSignatureSelectNApplybutton')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        ) : (
          <div className={classes.paper2}>
            <Button
              className="close-button-2"
              onClick={() => {
                setOpen(false);
              }}
              startIcon={<CancelIcon />}
            ></Button>
            <div className="cancel-pop">
              <Grid container spacing={0}>
                <Grid item sm={12}>
                  {' '}
                  <h2>{t('SignatureRequestSavedClosePromptHeader')} </h2>
                  <p>{t('SignatureRequestSavedClosePromptSubHeader')}</p>
                </Grid>
                <Grid item sm={6}>
                  {' '}
                </Grid>
                <Grid item sm={2}>
                  {' '}
                  <Button variant="outlined" className=" m-05" onClick={() => setOpen(false)}>
                    {t('SignatureRequestSavedClosePromptNoButton')}{' '}
                  </Button>
                </Grid>
                <Grid item sm={4}>
                  {' '}
                  <a href="https://dedoco.com/">
                    <Button
                      variant="contained"
                      color="primary"
                      className="w-100 m-05"
                      onClick={async () => {
                        await resetsigs();
                        setOpen(false);
                      }}
                    >
                      {t('SignatureRequestSavedClosePromptYesButton')}
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Modal>

      {/*********Gary(Popups for digital signatures) **********/}
      <>
        <InitialPopUp
          onOpen={confirmationPopUp}
          onConfirm={signingConfirmation}
          setOpen={setConfirmationPopUp}
          onReset={resetDigitalData}
          onQRPopUp={onQRPopUp}
        />
      </>
      {/*******************ndiCardPopUp for QR code**********************/}
      <div>
        <QrCodePopUp
          onOpen={ndiCardPopUp}
          setOpen={setNdiCardPopUp}
          qrData={qrData}
          challenge={challenge}
          loading={loading}
          signingError={signingError}
          transition={transition}
          minutes={minutes}
          seconds={seconds}
          challengeCodeHeaders={challengeCodeHeaders}
          qrContent={qrContent}
          expiredQR={expiredQR}
          qrCodeMessage={qrCodeMessage}
          transitionMessage={transitionMessage}
          refreshQRButton={refreshQRButton}
          errorMessage={errorMessage}
          showNDISpinner={showNDISpinner}
          onReset={resetDigitalData}
        />
      </div>

      {/*successPopUp for Success modal*/}
      <SuccessPopUp />
      <Popup className="my-popup3" open={successPopUp} modal nested closeOnDocumentClick={false}>
        {(close) => (
          <div className="modal3">
            <button
              style={{ visibility: 'visible' }}
              className="close"
              onClick={() => {
                close();
                setNdiCardPopUp(false);
                setSuccessPopUp(false);
              }}
            >
              &times;
            </button>
            <div
              style={{
                textAlign: 'center',
                padding: '30px',
                fontWeight: 'bold',
                fontSize: '30px',
                fontFamily: 'Lato',
                color: '#504E56',
              }}
            >
              {t('SignatureRequestCompletedHeader')}
            </div>
            <div
              style={{
                height: '380px',
                width: '600px',
                textAlign: 'center',
                paddingLeft: '80px',
                fontSize: '18px',
                fontFamily: 'Lato',
                color: '#504E56',
              }}
            >
              {t('SignatureRequestCompletedSubHeader')}{' '}
              {props.publicVariable ? '' : t('SignatureRequestCompletedHeaderText')}
            </div>
            <div style={{ textAlign: 'center' }}>
              <img
                alt="success!"
                style={{
                  position: 'absolute',
                  top: '205px',
                  left: '210px',
                  width: '240px',
                }}
                src="/signsuccess.png"
              />
            </div>
            {props.isSigner && remainingSigner?.length > 1 && (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  padding: '0 120px 28px',
                  fontSize: 14,
                  fontWeight: '600',
                }}
              >
                <p className="m0" style={{ whiteSpace: 'pre-wrap', color: '#4f4f4f' }}>
                  {t('SigningRequestDownloadNote')}
                </p>
              </div>
            )}

            <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  download(manualFinalPdf, props.FileName, 'application/pdf');
                }}
                sx={{
                  marginTop: '50px',
                  height: '40px',
                  width: '400px',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {t('SignatureRequestDownloadPDFButton')}
              </Button>
            </div>
          </div>
        )}
      </Popup>
      <Snackbar
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
        autoHideDuration={snackBarDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert iconMapping={{ info: <LoadingSpinner /> }} severity={severity}>
            {snackBarMessage}
          </Alert>
        </div>
      </Snackbar>

      {/* DECLINE DOCUMENT */}
      <DeclineDocumentDialog
        isApprovingDocument={props.isApprovingDocument}
        open={openDeclineModal}
        handleClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDeclineModal(false);
          }
        }}
        handleSubmit={submitDecline}
      />

      {/* DOWNLOAD DOCUMENT */}

      <DownloadPdfDialog
        open={openDownloadPdfModal}
        setOpenModal={setOpenDownloadPdfModal}
        handleDownloadPdf={handleDownloadPdf}
      />

      {/********************************END************************************* */}
    </Root>
  );
}
