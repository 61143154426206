import { GOTIP } from './actiontypes';
import axios from 'axios';

export function getIp() {
  return (dispatch) => {
    axios
      .get('https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3')
      .then(function (response) {
        dispatch({
          type: GOTIP,
          payload: response.data.IPv4,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}
