import { renewRecipientSession } from '../../lib/api';
import {
  GOTIP,
  GOT_DOCUMENT_TO_SIGN,
  VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS,
  VERIFY_OBSERVER_OTP_SUCCESS,
  VERIFY_SIGNING_OTP_SUCCESS,
  VERIFY_APPROVER_OTP_SUCCESS,
} from '../actions/actiontypes';

import {
  DVIDEO_SIGN_GET_DOCUMENT_TO_SIGN,
  DVIDEO_SIGN_GET_OBSERVER_DETAILS,
  DVIDEO_SIGN_GET_APPROVER_DETAILS,
} from '../slices/dVideoSignSlice/dVideoSignSlice';
import { NEW_VERIFY_OTP } from '../slices/otpSlice/otpSlice';

const EARLY_SESSION_RENEW_FACTOR = 0.9;
const MAX_EARLY_SESSION_RENEW_OFFSET = 5 * 60000;
let sessionRenewTimeout = null;
const refreshRecipientSession = (timeoutMs) => {
  if (sessionRenewTimeout) {
    clearTimeout(sessionRenewTimeout);
  }
  sessionRenewTimeout = setTimeout(async () => {
    try {
      const { token, expiresAfter } = await renewRecipientSession();
      sessionStorage.setItem('sessionToken', token);
      refreshRecipientSession(
        Math.max(
          expiresAfter * EARLY_SESSION_RENEW_FACTOR,
          expiresAfter - MAX_EARLY_SESSION_RENEW_OFFSET,
        ),
      );
    } catch {
      // Token cannot be renewed, there is no need to handle this error.
      // In the worst case, the user have to refresh the webapp.
    }
  }, timeoutMs);
};
const initialstate = {
  isLogedIn: false,
  name: 'Alice Tan',
  email: 'alicetan@gmail.com',
  id: '5f71ab4db34ba9001bc2b87f',
  myDetails: [],
  ip: '',
};
const Auth = (state = initialstate, action) => {
  switch (action.type) {
    case GOTIP:
      return {
        ...state,
        ip: action.payload,
      };
    case VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS:
    case VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS:
    case VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS:
    case VERIFY_OBSERVER_OTP_SUCCESS:
    case VERIFY_SIGNING_OTP_SUCCESS:
    case VERIFY_APPROVER_OTP_SUCCESS:
    case `${NEW_VERIFY_OTP}/fulfilled`:
    case DVIDEO_SIGN_GET_DOCUMENT_TO_SIGN:
    case DVIDEO_SIGN_GET_OBSERVER_DETAILS:
    case DVIDEO_SIGN_GET_APPROVER_DETAILS:
    case GOT_DOCUMENT_TO_SIGN: {
      // cannot store session token in redux as when using session token we have to import store
      // into axios client file which will lead unit fail for redux, https://redux.js.org/faq/store-setup#can-or-should-i-create-multiple-stores-can-i-import-my-store-directly-and-use-it-in-components-myself
      const { token, expiresAfter } = action.payload.recipientSession ?? {};
      sessionStorage.setItem('sessionToken', token);
      // Don't need to stop this interval since it should last until the user close the browser.
      if (expiresAfter) {
        refreshRecipientSession(
          Math.max(
            expiresAfter * EARLY_SESSION_RENEW_FACTOR,
            expiresAfter - MAX_EARLY_SESSION_RENEW_OFFSET,
          ),
        );
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default Auth;
