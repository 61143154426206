import {
  Root,
  ContentWrapper,
  Logo,
  Caption,
  LoadingBar,
  LoadingCaption,
} from './SingPassShared.styles';
import SingPassLogo from '../../../assets/singpass-logo-fullcolours.svg';
import { useHistory } from 'react-router-dom';

export default function SingPassRedirectPage() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  let paramsValid = true;
  const history = useHistory();
  try {
    const code = params.get('code');
    const state = window.atob(params.get('state'));
    paramsValid = code && state;
    const signingDetails = JSON.parse(state);
    const signingPath = `/${signingDetails.a}/${signingDetails.id}/${signingDetails.uid}/${signingDetails.f}`;
    history.push(signingPath, { singPassAuthCode: code });
  } catch (e) {
    paramsValid = false;
  }

  return paramsValid ? (
    <PageTemplate>
      <LoadingCaption>Redirecting...</LoadingCaption>
      <LoadingBar />
    </PageTemplate>
  ) : (
    <PageTemplate>
      <Caption>Invalid Parameters</Caption>
    </PageTemplate>
  );
}

const PageTemplate = ({ children }) => {
  return (
    <Root>
      <ContentWrapper>
        <Logo src={SingPassLogo} />
        {children}
      </ContentWrapper>
    </Root>
  );
};
