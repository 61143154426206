import styled from '@emotion/styled';

const PREFIX = 'ActionButtons';

export const classes = {
  submitButton: `${PREFIX}-submitButton`,
  rejectButton: `${PREFIX}-rejectButton`,
  buttonText: `${PREFIX}-buttonText`,
  approveButton: `${PREFIX}-approveButton`,
};

export const ActionButtonsRoot = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
  gap: theme.spacing(4),

  [`& .${classes.submitButton}`]: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },

  [`& .Mui-disabled`]: {
    backgroundColor: 'rgba(101, 49, 255, 0.4)',
    color: theme.palette.primary.contrastText,
  },

  [`& .${classes.rejectButton}`]: {
    backgroundColor: theme.palette.supporting.main,
    color: theme.palette.primary.main,
    borderRadius: 8,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.supporting.dark}`,
    height: 40,
    padding: theme.spacing(2.5),
  },

  [`& .${classes.buttonText}`]: {
    fontWeight: 600,
  },

  [`& .${classes.approveButton}`]: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: 8,
    boxShadow: 'none',
    height: 40,
  },
}));
