import { useDispatch, useSelector } from 'react-redux';
import helpIcon from '../../../assets/help.svg';
import newDedocoLogo from '../../../assets/new-dedoco-logo.svg';
import { useWebexContext } from '../../../contexts/useWebexContext';
import Header from '../Header';
import SideMenu from '../SideMenu';
import { classes, Root } from './DedocoLayout.style';
import { useEffect, useMemo } from 'react';
import { getOrganizationLogo } from '../../../redux/actions/documentfunctions';
import { featureNameMap, useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { process_env } from '../../../utils/envData';
import { getLogoFromCompanyName } from '../../../utils/logo';

function DedocoLayout({
  children,
  headerComponent,
  disableSideMenu,
  handleDownloadPdf,
  actionIcon,
  actionIconAlt,
  tailComponent,
  headerStyle = {},
  mainStyle = {},
}) {
  const dispatch = useDispatch();
  const { isWebexSigning } = useWebexContext();
  const { organization } = useSelector((state) => state.documenttosign);
  const { shorthand } = useSelector((state) => state.organizationLogo);

  const isAdminPortalEnabled = useFeatureFlag(featureNameMap.adminPortal);
  const isAdminPortalSettingEnabled = useFeatureFlag(featureNameMap.adminPortalSetting);
  const isAdminPortalSettingBrandingEnabled = useFeatureFlag(
    featureNameMap.adminPortalSettingBranding,
  );

  const logo = useMemo(() => {
    // Temporary. Will be here until minIO in JTC is set up.
    const companyName = process_env('REACT_APP_COMPANY_NAME');
    if (companyName.toLowerCase() === 'jtc') {
      return getLogoFromCompanyName(companyName);
    }

    if (
      isAdminPortalEnabled &&
      isAdminPortalSettingEnabled &&
      isAdminPortalSettingBrandingEnabled
    ) {
      return shorthand || newDedocoLogo;
    }

    return newDedocoLogo;
  }, [
    isAdminPortalEnabled,
    isAdminPortalSettingBrandingEnabled,
    isAdminPortalSettingEnabled,
    shorthand,
  ]);

  const openHelp = () => {
    window.open('https://www.dedoco.com/faq', '_blank', 'noopener');
  };

  const rerouteDedoco = () => {
    window.location = 'https://dedoco.com/';
  };

  useEffect(() => {
    if (organization) {
      dispatch(getOrganizationLogo(organization));
    }
  }, [dispatch, organization]);

  return (
    <Root isWebexSigning={isWebexSigning}>
      <nav className={classes.navbar}>
        <div className={classes.navbarIcons}>
          <img className={classes.logo} alt="new-dedoco-logo" src={logo} onClick={rerouteDedoco} />
          <div className={classes.iconWrapper}>
            <img className={classes.actionIcon} alt={actionIconAlt} src={actionIcon} />
          </div>
        </div>
        <div className={classes.innerIconWrapper} onClick={openHelp}>
          <img className={classes.helpIcon} src={helpIcon} alt="help" />
        </div>
        {!disableSideMenu && <SideMenu handleDownloadPdf={handleDownloadPdf} />}
      </nav>
      <main className={classes.main} style={mainStyle}>
        <div className={classes.header} style={headerStyle}>
          <Header component={headerComponent} tailComponent={tailComponent} />
        </div>
        <div className={classes.content}>{children}</div>
      </main>
    </Root>
  );
}

export default DedocoLayout;
