import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePdfTronContext } from '../../../../../contexts/usePdfTronContext';
import {
  classes,
  FieldAccordion,
  FieldAccordionDetails,
  FieldAccordionSummary,
} from './Fields.style';
import FieldItem from './FieldItem';
import useGetRequiredDedocoSigning from '../../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';

function Field({ useNewDedocoSigning = false, title, type, items, allowSignAll = false }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();
  const { instance } = usePdfTronContext();
  const { t } = useTranslation();

  // state
  const { currentField, setCurrentField } = useGetRequiredDedocoSigning(useNewDedocoSigning);

  let annotationManager;
  if (instance) {
    ({ annotationManager } = instance.Core);
  }

  const toggleAccordion = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const handleSignAllButton = () => {
    toggleAccordion();

    const annots = annotationManager
      ?.getAnnotationsList()
      .filter((annot) => annot.Id.startsWith('esignature'));

    const currentFieldIds = annots.map((annot) => annot.Id);

    setCurrentField(currentFieldIds, true);
  };

  const isFocused = !!(Array.isArray(currentField.fieldId)
    ? currentField.fieldId?.some((fieldId) => fieldId.startsWith(type))
    : currentField.fieldId?.startsWith(type));
  const getBorder = () => {
    if (isFocused) {
      return `1px solid ${theme.palette.primary.main} !important`;
    } else {
      return `1px solid ${theme.palette.background.paper} !important`;
    }
  };

  const getCircleBackground = () => {
    if (isFocused) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.supporting.main;
    }
  };

  const getCircleColor = () => {
    if (isFocused) {
      return theme.palette.primary.contrastText;
    } else {
      return theme.palette.supporting.contrastText;
    }
  };

  if (items.length === 0) return null;

  return (
    <FieldAccordion
      expanded={isExpanded}
      sx={{ boxShadow: 'none', border: getBorder() }}
      elevation={0}
      disableGutters
    >
      <FieldAccordionSummary
        expandIcon={!allowSignAll && <ExpandMoreIcon />}
        onClick={toggleAccordion}
      >
        <Typography className={classes.fieldLabel} variant="label_medium">
          {title}
          <div
            className={classes.numberOfFields}
            style={{ backgroundColor: getCircleBackground(), color: getCircleColor() }}
          >
            {items.length}
          </div>
        </Typography>
        {allowSignAll && (
          <Button
            onClick={handleSignAllButton}
            className={classes.signAllButton}
            variant="contained"
          >
            <Typography className={classes.signAllLabel} variant="label_small">
              {t('SignAll')}
            </Typography>
          </Button>
        )}
      </FieldAccordionSummary>
      <FieldAccordionDetails>
        {items.map((item, index) => (
          <FieldItem
            useNewDedocoSigning={useNewDedocoSigning}
            key={item.fieldId}
            item={item}
            index={index}
            type={type}
          />
        ))}
      </FieldAccordionDetails>
    </FieldAccordion>
  );
}

export default Field;
