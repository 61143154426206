import { Button, Card, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useWebexContext } from '../../contexts/useWebexContext';
import RecipientCard from './RecipientCard';
import './Webex.css';
import WebexContainer from './WebexContainer';

const ClaimRole = (props) => {
  const { hasSelected, setHasSelected, handleClaimRole, userIdentitySelected } = props;
  const { t } = useTranslation();
  const { recipients, isMeetingHost, hostEmail } = useWebexContext();

  return (
    <Stack justifyContent="space-between" height="100%">
      <WebexContainer
        body={
          <Grid item className="shared-app-main overflow-auto">
            <p className="text-align-left webex-title m0">{t('WebexClaimRoleTitle')}</p>
            <p className="text-align-left m0" style={{ fontSize: 10 }}>
              {t('WebexClaimRoleSubTitle')}
            </p>
            {recipients?.signers?.map((signer, index) => (
              <RecipientCard
                key={`signer-${index}`}
                index={index}
                recipient={signer}
                hasSelected={hasSelected}
                setHasSelected={setHasSelected}
                role="signer"
                hostEmail={hostEmail}
                isMeetingHost={isMeetingHost}
              />
            ))}
            {recipients?.approvers?.map((approver, index) => (
              <RecipientCard
                key={`approver-${index}`}
                index={index}
                recipient={approver}
                hasSelected={hasSelected}
                setHasSelected={setHasSelected}
                role="approver"
                hostEmail={hostEmail}
                isMeetingHost={isMeetingHost}
              />
            ))}
            {recipients?.observers?.map((observer, index) => (
              <RecipientCard
                key={`observer-${index}`}
                index={index}
                recipient={observer}
                hasSelected={hasSelected}
                setHasSelected={setHasSelected}
                role="observer"
                hostEmail={hostEmail}
                isMeetingHost={isMeetingHost}
              />
            ))}
            <ul id="console" />
          </Grid>
        }
        footer={
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!hasSelected || userIdentitySelected?.hasConfirmed}
              onClick={handleClaimRole}
            >
              {t('WebexClaimRoleConfirmButton')}
            </Button>
          </Grid>
        }
      />
      {isMeetingHost && <OpenWebexAppForAllNotice />}
    </Stack>
  );
};

const OpenWebexAppForAllNotice = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" spacing={2}>
      <Card
        sx={{
          margin: (theme) => theme.spacing(0, 3),
        }}
        variant="outlined"
      >
        <CardContent>
          <Typography variant="body_small">{t('WebexClaimRoleSubTitleForHost')}</Typography>
        </CardContent>
      </Card>
      <OpenWebexAppForAllTooltip />
    </Stack>
  );
};

const OpenWebexAppForAllTooltip = () => {
  const { t } = useTranslation();

  return (
    <Grid item paddingTop={12}>
      <Tooltip
        id="webex-claim-role-tooltip"
        componentsProps={{
          tooltip: {
            sx: (theme) => ({ ...theme.typography.label_small }),
          },
        }}
        open={true}
        arrow={true}
        placement="top"
        title={t('WebexOpenForAllIndicator')}
      >
        <div />
      </Tooltip>
    </Grid>
  );
};

export default ClaimRole;
