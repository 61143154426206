import { Alert, Button, Grid, Snackbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { downloadFileFromDSS } from '../../../lib/api/dss';
import { dedocoTheme } from '../../../themes/dedocoTheme';
import { generateFileShareKey } from '../../../utils/fileShareKey';
import DedocoLayout from '../DedocoLayout';
import { classes, DownloadDocumentRoot } from './DownloadDocument.style';
import DownloadButton from '../Buttons/DownloadButton';
import signIcon from '../../../assets/sign.svg';

export function DownloadDocument() {
  const { t } = useTranslation();
  const { key, filename } = useParams();
  const location = useLocation();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const snackBarDuration = 3000;
  const fileName = getFileNameFromBase64(filename);
  // Normal function is used to hoist its declaration upwards.
  function getFileNameFromBase64(b64FileName) {
    b64FileName = b64FileName.replace(/_/g, '/').replace(/-/g, '+');
    return Buffer.from(b64FileName, 'base64').toString();
  }

  const downloadDecryptedFinalDoc = async () => {
    const fileShareKey = generateFileShareKey(key, location?.hash?.slice(1));
    return downloadFileFromDSS(fileShareKey);
  };

  const handleDownloadPdf = async (downloadOptionDetails) => {
    setSnackBarOpen(true);
    setSeverity('info');
    setSnackBarMessage(t('DownloadPageMessageFetchingPDF'));

    let pdfData;
    try {
      pdfData = await downloadDecryptedFinalDoc();
      if (!pdfData) throw new Error('File not found');
      if (!pdfData.startsWith('data:application/pdf;base64,'))
        pdfData = 'data:application/pdf;base64,' + pdfData;
    } catch (err) {
      setSnackBarOpen(true);
      setSeverity('error');
      setSnackBarMessage(t('DownloadPageErrorFailToFetchPDF'), 10);
      return;
    }

    const fileInfo = {
      data: pdfData,
      filename: fileName,
    };

    const saveFile = downloadOptionDetails.downloadFunction;
    saveFile(
      fileInfo,
      () => {
        setSnackBarOpen(true);
        setSeverity('success');
        setSnackBarMessage(t('DownloadPageMessageFileDownloaded'));
      },
      () => {
        setSnackBarOpen(true);
        setSeverity('error');
        setSnackBarMessage(t('DownloadPageErrorFailToFetchPDF'));
      },
    );
  };

  const rerouteDedoco = async (_e) => {
    window.location = 'https://dedoco.com/';
  };

  return (
    <ThemeProvider theme={dedocoTheme}>
      <Snackbar
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
        autoHideDuration={snackBarDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert severity={severity}>{snackBarMessage}</Alert>
        </div>
      </Snackbar>
      <DedocoLayout
        actionIcon={signIcon}
        actionIconAlt={'sign'}
        mainStyle={{ background: 'white' }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <DownloadDocumentRoot>
              <div className={classes.container}>
                <Typography variant="headline_small">{t('DownloadPageHeader')}</Typography>
                <div className={classes.subtext}>
                  <Typography>{t('DownloadPageSubHeader')}</Typography>
                  <br />
                  <br />
                  <Typography>{t('DownloadPageSubHeaderText')}</Typography>
                </div>

                <div
                  style={{
                    paddingBottom: '1vw',
                    paddingTop: '20px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  <Button className={classes.visitDedoco} variant="tinted" onClick={rerouteDedoco}>
                    {t('VisitDedocoHomepage')}
                  </Button>
                  <DownloadButton handleDownloadPdf={handleDownloadPdf} isDownloadPage />
                </div>
              </div>
            </DownloadDocumentRoot>
          </Grid>
        </Grid>
      </DedocoLayout>
    </ThemeProvider>
  );
}
