import { Root, classes, OtpContainer } from './OTPCheck.style';
import { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import TermsAndCondition from '../TermsAndCondition';
import useOTP from '../../../../hooks/useOTP';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import { useTnC } from '../../../../hooks/useTnC';
import { CypressIds } from '../../../../constant/cypressIds';
import SignerInfo from '../../../SignerInfo';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const OTP_LENGTH = 7;

function OTPCheck({ otpTarget }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { sendOTP, verifyOTP } = useOTP();
  const { TncSnackbar, handleSubmitTnC } = useTnC();
  const [countDownTime, setCountDownTime] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [currentOtp, setCurrentOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [availableVerifyAttempt, setAvailableVerifyAttempt] = useState(5);
  const signer = useSelector((state) => state?.documenttosign?.data);

  const isProceedButtonDisabled = useMemo(() => {
    return currentOtp.length < OTP_LENGTH;
  }, [currentOtp.length]);

  const handleSendOtp = () => {
    function sendSuccessCallback() {
      setCountDownTime(60);
      setResendDisabled(true);
    }

    function sendErrorCallback(err) {
      if (err?.request?.status === 429) {
        setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
        setAvailableVerifyAttempt(0);
      } else {
        setErrorMessage(t('OTPVerifyErrorOTPDidNotMatch'));
      }
    }

    if (availableVerifyAttempt > 0) {
      sendOTP(sendSuccessCallback, sendErrorCallback);
      setAvailableVerifyAttempt((availableVerifyAttempt) => availableVerifyAttempt - 1);
    }
  };

  const handleResend = () => {
    setCountDownTime(60);
    setResendDisabled(true);
    handleSendOtp();
  };

  const handleProceed = () => {
    function verifySuccessCallback() {
      setErrorMessage('');
      setAvailableVerifyAttempt(5);
    }

    function verifyErrorCallback(err) {
      if (err?.request?.status === 429) {
        setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
        setAvailableVerifyAttempt(0);
      } else {
        setErrorMessage(t('OTPVerifyErrorOTPDidNotMatch'));
        setAvailableVerifyAttempt(availableVerifyAttempt - 1);
      }
    }

    verifyOTP(currentOtp, verifySuccessCallback, verifyErrorCallback).then((documentToSign) => {
      handleSubmitTnC(documentToSign?.business_process?.id);
    });
  };

  useEffect(() => {
    let otpTimeout;
    if (countDownTime > 0) {
      otpTimeout = setTimeout(() => setCountDownTime(countDownTime - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => {
      clearTimeout(otpTimeout);
    };
  }, [countDownTime]);

  useEffect(() => {
    handleSendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!otpTarget) return null;

  return (
    <OtpContainer>
      <TncSnackbar />
      <Root>
        <Box sx={{ paddingBottom: 2 }}>
          <SignerInfo name={signer?.recipient_name} email={signer?.recipient_email} />
        </Box>
        <Typography variant="label_medium" align="left" gutterBottom>
          {t('OTPVerifyTitle')}
        </Typography>
        <Typography variant="body_medium" align="left" gutterBottom>
          {t('InputOtpText')}
          <b>{otpTarget}</b>
        </Typography>
        <OtpInput
          value={currentOtp}
          onChange={(otp) => {
            setCurrentOtp(otp);
          }}
          numInputs={OTP_LENGTH}
          inputStyle={classes.otpInput}
          separator={<span>&nbsp;&nbsp;</span>}
        />
        <div className={classes.childContainer}>
          <Typography
            className={`${classes.resendButton} ${resendDisabled && classes.disableResend}`}
            variant="body_medium"
            align="left"
            gutterBottom
            onClick={handleResend}
          >
            {t('OTPVerifyResendCode')}
          </Typography>
          {resendDisabled && (
            <Typography
              className={classes.timeCountDown}
              variant="body_medium"
              align="left"
              gutterBottom
            >
              {t('OTPVerifyResendCounterText')} {countDownTime}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              className={classes.invalidOtp}
              variant="body_medium"
              align="left"
              gutterBottom
            >
              {errorMessage}
            </Typography>
          )}
        </div>
      </Root>
      <TermsAndCondition noTitle={true} hideButton={true} fullWidth={false} />
      <Button
        variant="contained"
        onClick={handleProceed}
        data-cypress={CypressIds.otpProceedButton}
        disabled={isProceedButtonDisabled}
        sx={{
          width: `calc(100% - 64px)`,
          marginBottom: `${theme.spacing(8)}`,
        }}
      >
        {t('ProceedButton')}
      </Button>
    </OtpContainer>
  );
}

export default OTPCheck;
