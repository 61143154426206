import {
  SomethingWrongErrorRoot,
  SomethingWrongErrorIcon,
  SomethingWrongErrorHeader,
  SomethingWrongErrorMessage,
} from './SomethingWrongError.styles';
import { useTranslation } from 'react-i18next';
import AlertIcon from '../../../../../assets/AlertIcon.svg';

export const SomethingWrongError = () => {
  const { t } = useTranslation();
  return (
    <SomethingWrongErrorRoot>
      <SomethingWrongErrorIcon src={AlertIcon} />
      <SomethingWrongErrorHeader>{t('SomethingWrongErrorHeader')}</SomethingWrongErrorHeader>
      <SomethingWrongErrorMessage>{t('SomethingWrongErrorExplanation')}</SomethingWrongErrorMessage>
      <SomethingWrongErrorMessage>
        {t('SomethingWrongErrorContactSupport')}
      </SomethingWrongErrorMessage>
    </SomethingWrongErrorRoot>
  );
};
