import { ThemeProvider } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWebexContext } from '../../contexts/useWebexContext';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { WebexSteps } from './constants';
import Content from './Content';
import initializeWebexApp from './Webex';
import './Webex.css';

const SharedApp = () => {
  const [step, setStep] = useState(WebexSteps.CLAIM_ROLE);

  const { connect, setIsWebexSigning, joinRoom } = useWebexContext();
  const { documentid } = useParams();
  const [isWebexInitialized, setIsWebexInitialized] = useState(false);

  const initWebex = useCallback(async () => {
    await initializeWebexApp();
    setIsWebexInitialized(true);
  }, []);

  useEffect(() => {
    initWebex();
  }, [initWebex]);

  useEffect(() => {
    if (!isWebexInitialized) return;

    connect();
    setIsWebexSigning(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebexInitialized]);

  useEffect(() => {
    if (!isWebexInitialized) return;

    joinRoom(documentid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebexInitialized]);

  return (
    <ThemeProvider theme={dedocoTheme}>
      <Content step={step} setStep={setStep} />
    </ThemeProvider>
  );
};

export default SharedApp;
