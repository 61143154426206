import './RenderPDF.css';
import { PDFDocument, rgb, StandardFonts, degrees } from 'pdf-lib';
import { getStringHash } from '../../utils/hash';
import checkboxIcon from '../../assets/check.png';

export function renderCurrentPDF(src, allSignerData, showOtherSignElement, uid, currentSignerData) {
  const renderFunction = async (src, data, showOtherSignElement, uid, currentSignerData) => {
    const pdfDoc = await PDFDocument.load(src);
    const pages = pdfDoc.getPages();
    const signPage = pages[0];
    const { width, height } = signPage.getSize();
    const rotateAngle = signPage.getRotation().angle;

    let angleCorrection = 0;
    if (rotateAngle > 0) {
      angleCorrection = rotateAngle - 360;
    }
    const arialFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // draw watermark
    if (window.location.href.includes('sign.stage.dedoco.com')) {
      pages.forEach((page) => {
        page.drawText('DEDOCOSTAGE', {
          x: width / 8,
          y: height / 5,
          size: 85,
          font: arialFont,
          color: rgb(0, 0, 0),
          opacity: 0.1,
          rotate: degrees(rotateAngle + 45),
        });
      });
    }

    if (data) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].has_signed) {
          const eSignData = data[i]?.esignatures;
          const customTextData = data[i]?.custom_texts;
          const confirmationData = data[i]?.confirmations;

          // Signature
          if (eSignData?.length > 0) {
            for (let i = 0; i < eSignData.length; i++) {
              let xCor = parseFloat(eSignData[i]?.placement?.x?.split('%')[0]) / 100;
              let yCor = parseFloat(eSignData[i]?.placement?.y?.split('%')[0]) / 100;

              if (eSignData[i]?.signature) {
                const imageData =
                  eSignData[i]?.signature.split(';')[0] === 'data:image/jpeg'
                    ? await pdfDoc.embedJpg(eSignData[i]?.signature)
                    : await pdfDoc.embedPng(eSignData[i]?.signature);
                let imageHeight = imageData?.height;
                let imageWidth = imageData?.width;
                const imageRatio = imageWidth / imageHeight;

                const pageToSign = pages[eSignData[i]?.placement?.page - 1];

                let eSignDimensionWidth =
                  parseInt(eSignData[i]?.dimensions.width.split('px')[0]) * 0.77;
                let eSignDimensionHeight =
                  parseInt(eSignData[i]?.dimensions.height.split('px')[0]) * 0.77;
                let logoPositionX = xCor * width;
                let logoPositionY = height - height * yCor - eSignDimensionHeight;
                const correctedLogoPositionX = width - yCor * width - eSignDimensionHeight;
                const correctedLogoPositionY = height - height * xCor;
                let widthCorrection = eSignDimensionWidth / imageWidth;
                let heightCorrection = eSignDimensionHeight / imageHeight;
                if (imageHeight > eSignDimensionHeight || imageWidth > eSignDimensionWidth) {
                  let finalCorrection = Math.min(widthCorrection, heightCorrection);
                  imageWidth = imageWidth * finalCorrection * 0.675;
                  imageHeight = imageHeight * finalCorrection * 0.675;
                } else {
                  imageWidth = imageWidth * 0.675;
                  imageHeight = imageHeight * 0.675;
                }

                const correctionMultiplier =
                  eSignDimensionHeight * imageRatio * 0.7 - eSignDimensionWidth < 0
                    ? 1
                    : (eSignDimensionHeight * imageRatio * 0.7) / eSignDimensionWidth;

                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                }

                pageToSign.drawImage(imageData, {
                  x:
                    Math.abs(angleCorrection) > 0
                      ? logoPositionX + (eSignDimensionHeight - imageHeight) / 1.5
                      : logoPositionX + (eSignDimensionWidth - imageWidth) / 2,
                  y:
                    Math.abs(angleCorrection) > 0
                      ? logoPositionY - (eSignDimensionWidth - imageWidth) / 2
                      : logoPositionY + (eSignDimensionHeight - imageHeight) / 1.5,
                  width: eSignDimensionWidth / 2.4,
                  //height to be matched with image height
                  height: eSignDimensionWidth / 2.4 / 1.53,
                  rotate: degrees(angleCorrection),
                });
                if (eSignData[i]?.type === 'upload') {
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + eSignDimensionHeight - eSignDimensionHeight / 1.346
                        : logoPositionX +
                          eSignDimensionWidth / 1.8 -
                          (eSignDimensionHeight * imageRatio * 0.75) / 2 / correctionMultiplier,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY -
                          eSignDimensionWidth / 1.8 +
                          (eSignDimensionHeight * imageRatio * 0.75) / 2 / correctionMultiplier
                        : logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.346,
                    width: (eSignDimensionHeight * imageRatio * 0.7) / correctionMultiplier,
                    //height to be matched with image height
                    height: (eSignDimensionHeight * 0.7) / correctionMultiplier,
                    rotate: degrees(angleCorrection),
                  });
                } else if (eSignData[i]?.type === 'text') {
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + eSignDimensionHeight - eSignDimensionHeight / 1.7
                        : logoPositionX + eSignDimensionWidth / 20,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY - +eSignDimensionWidth / 20
                        : logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.7,
                    width: eSignDimensionWidth * 0.9,
                    height: eSignDimensionWidth / 7,
                    rotate: degrees(angleCorrection),
                  });
                }
              }
            }
          }

          if (showOtherSignElement) {
            // Confirmation
            if (confirmationData?.length > 0) {
              const checkboxImageByte = await fetch(checkboxIcon).then((res) => res.arrayBuffer());
              const checkboxImage = await pdfDoc.embedPng(checkboxImageByte);

              confirmationData.forEach((confirmation) => {
                let xCor = parseFloat(confirmation?.placement?.x?.split('%')[0]) / 100;
                let yCor = parseFloat(confirmation?.placement?.y?.split('%')[0]) / 100;
                const checkboxDimensionHeight = parseInt(
                  confirmation?.dimensions?.height?.split('px')[0],
                );
                let logoPositionX = xCor * width + checkboxDimensionHeight * 0.15;
                let logoPositionY =
                  height - height * yCor - checkboxDimensionHeight + checkboxDimensionHeight * 0.45;
                const correctedLogoPositionX = width - yCor * width;
                const correctedLogoPositionY = height - height * xCor;
                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                }
                const pageToSign = pages[confirmation?.placement?.page - 1];

                confirmation?.isChecked &&
                  pageToSign &&
                  pageToSign.drawImage(checkboxImage, {
                    x: logoPositionX,
                    y: logoPositionY,
                    width: checkboxDimensionHeight * 0.7,
                    height: checkboxDimensionHeight * 0.5,
                  });
              });
            }
            // Custom text
            if (customTextData?.length > 0) {
              for (let i = 0; i < customTextData.length; i++) {
                let xCorText = parseFloat(customTextData[i]?.placement?.x?.split('%')[0]) / 100;
                let yCorText = parseFloat(customTextData[i]?.placement?.y?.split('%')[0]) / 100;
                const customTextDimensionWidth = parseInt(
                  customTextData[i]?.dimensions?.width?.split('px')[0] * 0.78,
                );
                if (
                  customTextData[i]?.type === 'actual-date' ||
                  customTextData[i]?.type === 'custom-date'
                ) {
                  const logoBytes = await fetch('/date.png').then((res) => res.arrayBuffer());
                  const logoImage = await pdfDoc.embedPng(logoBytes);
                  const logoDims = logoImage.scale(0.35);
                  const pageToSign = pages[customTextData[i]?.placement.page - 1];
                  const logoSizeRatio = logoDims.width / logoDims.height;
                  const dd = customTextData[i]?.text?.split('/')[0];
                  const mm = customTextData[i]?.text?.split('/')[1];
                  const yyyy = customTextData[i]?.text?.split('/')[2];

                  const customTextDimensionHeight = customTextDimensionWidth / logoSizeRatio;
                  let logoPositionX = xCorText * width;
                  let logoPositionY = height - height * yCorText - customTextDimensionHeight;
                  const correctedLogoPositionX =
                    width - yCorText * width - customTextDimensionHeight;
                  const correctedLogoPositionY = height - height * xCorText;
                  let dateCorrectionX = customTextDimensionWidth / 2.96;
                  let monthCorrectionX = customTextDimensionWidth / 1.862;
                  let yearCorrectionX = customTextDimensionWidth / 1.3793;
                  let dateCorrectionY = customTextDimensionHeight - customTextDimensionHeight / 2.5;
                  let monthCorrectionY = dateCorrectionY;
                  let yearCorrectionY = dateCorrectionY;

                  if (Math.abs(angleCorrection) > 0) {
                    logoPositionX = correctedLogoPositionX;
                    logoPositionY = correctedLogoPositionY;
                    dateCorrectionY = dateCorrectionX * 1.72;
                    monthCorrectionY = monthCorrectionX * 1.48;
                    yearCorrectionY = yearCorrectionX * 1.35;
                    dateCorrectionX =
                      customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                    monthCorrectionX =
                      customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                    yearCorrectionX =
                      customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                  }

                  pageToSign.drawImage(logoImage, {
                    x: logoPositionX,
                    y: logoPositionY,
                    width: customTextDimensionWidth,
                    height: customTextDimensionHeight,
                    rotate: degrees(angleCorrection),
                  });

                  //if descriptor is date then..
                  dd &&
                    pageToSign.drawText(dd, {
                      x: logoPositionX + dateCorrectionX,
                      y: logoPositionY + customTextDimensionHeight - dateCorrectionY,
                      size: customTextDimensionWidth / 10.953,
                      color: rgb(0, 0, 0),
                      rotate: degrees(angleCorrection),
                    });

                  mm &&
                    pageToSign.drawText(mm, {
                      x: logoPositionX + monthCorrectionX,
                      y: logoPositionY + customTextDimensionHeight - monthCorrectionY,
                      size: customTextDimensionWidth / 10.953,
                      color: rgb(0, 0, 0),
                      rotate: degrees(angleCorrection),
                    });

                  yyyy &&
                    pageToSign.drawText(yyyy, {
                      x: logoPositionX + yearCorrectionX,
                      y: logoPositionY + customTextDimensionHeight - yearCorrectionY,
                      size: customTextDimensionWidth / 10.953,
                      color: rgb(0, 0, 0),
                      rotate: degrees(angleCorrection),
                    });
                } else if (
                  customTextData[i]?.type === 'initials' ||
                  customTextData[i]?.type === 'stamp'
                ) {
                  const pageToSign = pages[customTextData[i]?.placement?.page - 1];
                  const customTextDimensionHeight = parseInt(
                    customTextData[i]?.dimensions?.height?.split('px')[0],
                  );
                  const imageData =
                    customTextData[i]?.text?.split(';')[0] === 'data:image/jpeg'
                      ? await pdfDoc.embedJpg(customTextData[i]?.text)
                      : await pdfDoc.embedPng(customTextData[i]?.text);
                  let imageHeight = imageData?.height;
                  let imageWidth = imageData?.width;
                  let logoPositionX = xCorText * width;
                  let logoPositionY = height - height * yCorText - customTextDimensionHeight * 0.8;
                  const correctedLogoPositionX =
                    width - yCorText * width - customTextDimensionHeight;
                  const correctedLogoPositionY = height - height * xCorText;
                  let widthCorrection = customTextDimensionWidth / imageWidth;
                  let heightCorrection = customTextDimensionHeight / imageHeight;
                  if (
                    imageHeight > customTextDimensionHeight ||
                    imageWidth > customTextDimensionWidth
                  ) {
                    let finalCorrection = Math.min(widthCorrection, heightCorrection);
                    imageWidth = imageWidth * finalCorrection * 0.675;
                    imageHeight = imageHeight * finalCorrection * 0.675;
                  } else {
                    imageWidth = imageWidth * 0.675;
                    imageHeight = imageHeight * 0.675;
                  }

                  if (Math.abs(angleCorrection) > 0) {
                    logoPositionX = correctedLogoPositionX;
                    logoPositionY = correctedLogoPositionY;
                  }
                  let finalWidth = imageWidth;
                  let finalHeight = imageHeight;
                  imageData &&
                    pageToSign.drawImage(imageData, {
                      x:
                        Math.abs(angleCorrection) > 0
                          ? logoPositionX + (customTextDimensionHeight - imageHeight) / 2.1
                          : logoPositionX + (customTextDimensionWidth - imageWidth) / 2,
                      y:
                        Math.abs(angleCorrection) > 0
                          ? logoPositionY - (customTextDimensionWidth - imageWidth) / 2
                          : logoPositionY + (customTextDimensionHeight - imageHeight) / 2.1,
                      width: finalWidth,
                      height: finalHeight,
                      rotate: degrees(angleCorrection),
                    });
                } else {
                  const pageToSign = pages[customTextData[i]?.placement?.page - 1];
                  const customTextDimensionHeight = parseInt(
                    customTextData[i]?.dimensions.height.split('px')[0],
                  );

                  let logoPositionX = xCorText * width;
                  let logoPositionY = height - height * yCorText - customTextDimensionHeight * 0.7;
                  const correctedLogoPositionX = width - yCorText * width;
                  const correctedLogoPositionY = height - height * xCorText;
                  if (Math.abs(angleCorrection) > 0) {
                    logoPositionX = correctedLogoPositionX;
                    logoPositionY = correctedLogoPositionY;
                  }

                  customTextData[i]?.text &&
                    pageToSign.drawText(customTextData[i]?.text, {
                      x: logoPositionX,
                      y: logoPositionY,
                      size: customTextDimensionHeight * 0.8,
                      font: arialFont,
                      color: rgb(0, 0, 0),
                      rotate: degrees(angleCorrection),
                    });
                }
              }
            }
          }
        }

        if (data[i]?.id && data[i]?.id === uid && currentSignerData) {
          const eSignData = data[i]?.esignatures;
          const customTextData = data[i]?.custom_texts;
          const confirmationData = data[i]?.confirmations;

          // Signature
          if (eSignData?.length > 0) {
            for (let i = 0; i < eSignData.length; i++) {
              let xCor = parseFloat(eSignData[i]?.placement?.x?.split('%')[0]) / 100;
              let yCor = parseFloat(eSignData[i]?.placement?.y?.split('%')[0]) / 100;

              if (currentSignerData?.electronic_signatures[i]?.signature) {
                const imageData =
                  currentSignerData?.electronic_signatures[i]?.signature.split(';')[0] ===
                  'data:image/jpeg'
                    ? await pdfDoc.embedJpg(currentSignerData?.electronic_signatures[i]?.signature)
                    : await pdfDoc.embedPng(currentSignerData?.electronic_signatures[i]?.signature);
                let imageHeight = imageData.height;
                let imageWidth = imageData.width;
                const imageRatio = imageWidth / imageHeight;

                const pageToSign = pages[eSignData[i]?.placement?.page - 1];

                let eSignDimensionWidth =
                  parseInt(eSignData[i]?.dimensions?.width?.split('px')[0]) * 0.77;
                let eSignDimensionHeight =
                  parseInt(eSignData[i]?.dimensions?.height?.split('px')[0]) * 0.77;
                let logoPositionX = xCor * width;
                let logoPositionY = height - height * yCor - eSignDimensionHeight;
                const correctedLogoPositionX = width - yCor * width - eSignDimensionHeight;
                const correctedLogoPositionY = height - height * xCor;
                let widthCorrection = eSignDimensionWidth / imageWidth;
                let heightCorrection = eSignDimensionHeight / imageHeight;
                if (imageHeight > eSignDimensionHeight || imageWidth > eSignDimensionWidth) {
                  let finalCorrection = Math.min(widthCorrection, heightCorrection);
                  imageWidth = imageWidth * finalCorrection * 0.675;
                  imageHeight = imageHeight * finalCorrection * 0.675;
                } else {
                  imageWidth = imageWidth * 0.675;
                  imageHeight = imageHeight * 0.675;
                }

                const correctionMultiplier =
                  eSignDimensionHeight * imageRatio * 0.7 - eSignDimensionWidth < 0
                    ? 1
                    : (eSignDimensionHeight * imageRatio * 0.7) / eSignDimensionWidth;

                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                }

                if (currentSignerData?.electronic_signatures[i]?.type === 'drawing') {
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + (eSignDimensionHeight - imageHeight) / 1.5
                        : logoPositionX + (eSignDimensionWidth - imageWidth) / 2,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY - (eSignDimensionWidth - imageWidth) / 2
                        : logoPositionY + (eSignDimensionHeight - imageHeight) / 1.5,
                    width: imageWidth,
                    height: imageHeight,
                    rotate: degrees(angleCorrection),
                  });
                } else if (currentSignerData?.electronic_signatures[i]?.type === 'upload') {
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + eSignDimensionHeight - eSignDimensionHeight / 1.346
                        : logoPositionX +
                          eSignDimensionWidth / 1.8 -
                          (eSignDimensionHeight * imageRatio * 0.75) / 2 / correctionMultiplier,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY -
                          eSignDimensionWidth / 1.8 +
                          (eSignDimensionHeight * imageRatio * 0.75) / 2 / correctionMultiplier
                        : logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.346,
                    width: (eSignDimensionHeight * imageRatio * 0.7) / correctionMultiplier,
                    //height to be matched with image height
                    height: (eSignDimensionHeight * 0.7) / correctionMultiplier,
                    rotate: degrees(angleCorrection),
                  });
                } else if (currentSignerData?.electronic_signatures[i]?.type === 'text') {
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + eSignDimensionHeight - eSignDimensionHeight / 1.7
                        : logoPositionX + eSignDimensionWidth / 20,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY - +eSignDimensionWidth / 20
                        : logoPositionY + eSignDimensionHeight - eSignDimensionHeight / 1.7,
                    width: eSignDimensionWidth * 0.9,
                    height: eSignDimensionWidth / 7,
                    rotate: degrees(angleCorrection),
                  });
                }
              }
            }
          }
          // Confirmation
          if (confirmationData?.length > 0) {
            const checkboxImageByte = await fetch(checkboxIcon).then((res) => res.arrayBuffer());
            const checkboxImage = await pdfDoc.embedPng(checkboxImageByte);

            confirmationData.forEach((confirmation, idx) => {
              let xCor = parseFloat(confirmation?.placement?.x?.split('%')[0]) / 100;
              let yCor = parseFloat(confirmation?.placement?.y?.split('%')[0]) / 100;
              const checkboxDimensionHeight = parseInt(
                confirmation?.dimensions?.height.split('px')[0],
              );
              let logoPositionX = xCor * width + checkboxDimensionHeight * 0.15;
              let logoPositionY =
                height - height * yCor - checkboxDimensionHeight + checkboxDimensionHeight * 0.45;
              const correctedLogoPositionX = width - yCor * width;
              const correctedLogoPositionY = height - height * xCor;
              if (Math.abs(angleCorrection) > 0) {
                logoPositionX = correctedLogoPositionX;
                logoPositionY = correctedLogoPositionY;
              }
              const pageToSign = pages[confirmation?.placement?.page - 1];

              currentSignerData.confirmations[idx]?.isChecked &&
                pageToSign &&
                pageToSign.drawImage(checkboxImage, {
                  x: logoPositionX,
                  y: logoPositionY,
                  width: checkboxDimensionHeight * 0.7,
                  height: checkboxDimensionHeight * 0.5,
                });
            });
          }
          // Custom text
          if (customTextData?.length > 0) {
            for (let i = 0; i < customTextData.length; i++) {
              let xCorText = parseFloat(customTextData[i]?.placement?.x?.split('%')[0]) / 100;
              let yCorText = parseFloat(customTextData[i]?.placement?.y?.split('%')[0]) / 100;
              const customTextDimensionWidth = parseInt(
                customTextData[i]?.dimensions?.width?.split('px')[0] * 0.78,
              );
              if (
                customTextData[i]?.type === 'actual-date' ||
                customTextData[i]?.type === 'custom-date'
              ) {
                const logoBytes = await fetch('/date.png').then((res) => res.arrayBuffer());
                const logoImage = await pdfDoc.embedPng(logoBytes);
                const logoDims = logoImage.scale(0.35);
                const pageToSign = pages[customTextData[i]?.placement.page - 1];
                const logoSizeRatio = logoDims.width / logoDims.height;
                const dd = currentSignerData?.custom_texts[i]?.split('/')[0];
                const mm = currentSignerData?.custom_texts[i]?.split('/')[1];
                const yyyy = currentSignerData?.custom_texts[i]?.split('/')[2];

                const customTextDimensionHeight = customTextDimensionWidth / logoSizeRatio;
                let logoPositionX = xCorText * width;
                let logoPositionY = height - height * yCorText - customTextDimensionHeight;
                const correctedLogoPositionX = width - yCorText * width - customTextDimensionHeight;
                const correctedLogoPositionY = height - height * xCorText;
                let dateCorrectionX = customTextDimensionWidth / 2.96;
                let monthCorrectionX = customTextDimensionWidth / 1.862;
                let yearCorrectionX = customTextDimensionWidth / 1.3793;
                let dateCorrectionY = customTextDimensionHeight - customTextDimensionHeight / 2.5;
                let monthCorrectionY = dateCorrectionY;
                let yearCorrectionY = dateCorrectionY;

                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                  dateCorrectionY = dateCorrectionX * 1.72;
                  monthCorrectionY = monthCorrectionX * 1.48;
                  yearCorrectionY = yearCorrectionX * 1.35;
                  dateCorrectionX = customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                  monthCorrectionX = customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                  yearCorrectionX = customTextDimensionHeight - customTextDimensionHeight / 1.6852;
                }

                pageToSign.drawImage(logoImage, {
                  x: logoPositionX,
                  y: logoPositionY,
                  width: customTextDimensionWidth,
                  height: customTextDimensionHeight,
                  rotate: degrees(angleCorrection),
                });

                //if descriptor is date then..
                dd &&
                  pageToSign.drawText(dd, {
                    x: logoPositionX + dateCorrectionX,
                    y: logoPositionY + customTextDimensionHeight - dateCorrectionY,
                    size: customTextDimensionWidth / 10.953,
                    color: rgb(0, 0, 0),
                    rotate: degrees(angleCorrection),
                  });

                mm &&
                  pageToSign.drawText(mm, {
                    x: logoPositionX + monthCorrectionX,
                    y: logoPositionY + customTextDimensionHeight - monthCorrectionY,
                    size: customTextDimensionWidth / 10.953,
                    color: rgb(0, 0, 0),
                    rotate: degrees(angleCorrection),
                  });

                yyyy &&
                  pageToSign.drawText(yyyy, {
                    x: logoPositionX + yearCorrectionX,
                    y: logoPositionY + customTextDimensionHeight - yearCorrectionY,
                    size: customTextDimensionWidth / 10.953,
                    color: rgb(0, 0, 0),
                    rotate: degrees(angleCorrection),
                  });
              } else if (
                customTextData[i].type === 'initials' ||
                customTextData[i].type === 'stamp'
              ) {
                const pageToSign = pages[customTextData[i].placement.page - 1];
                const customTextDimensionHeight = parseInt(
                  customTextData[i].dimensions.height.split('px')[0],
                );
                const imageData =
                  currentSignerData?.custom_texts[i].split(';')[0] === 'data:image/jpeg'
                    ? await pdfDoc.embedJpg(currentSignerData?.custom_texts[i])
                    : await pdfDoc.embedPng(currentSignerData?.custom_texts[i]);
                let imageHeight = imageData.height;
                let imageWidth = imageData.width;
                let logoPositionX = xCorText * width;
                let logoPositionY = height - height * yCorText - customTextDimensionHeight * 0.8;
                const correctedLogoPositionX = width - yCorText * width - customTextDimensionHeight;
                const correctedLogoPositionY = height - height * xCorText;
                let widthCorrection = customTextDimensionWidth / imageWidth;
                let heightCorrection = customTextDimensionHeight / imageHeight;
                if (
                  imageHeight > customTextDimensionHeight ||
                  imageWidth > customTextDimensionWidth
                ) {
                  let finalCorrection = Math.min(widthCorrection, heightCorrection);
                  imageWidth = imageWidth * finalCorrection * 0.675;
                  imageHeight = imageHeight * finalCorrection * 0.675;
                } else {
                  imageWidth = imageWidth * 0.675;
                  imageHeight = imageHeight * 0.675;
                }

                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                }
                let finalWidth = imageWidth;
                let finalHeight = imageHeight;
                imageData &&
                  pageToSign.drawImage(imageData, {
                    x:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionX + (customTextDimensionHeight - imageHeight) / 2.1
                        : logoPositionX + (customTextDimensionWidth - imageWidth) / 2,
                    y:
                      Math.abs(angleCorrection) > 0
                        ? logoPositionY - (customTextDimensionWidth - imageWidth) / 2
                        : logoPositionY + (customTextDimensionHeight - imageHeight) / 2.1,
                    width: finalWidth,
                    height: finalHeight,
                    rotate: degrees(angleCorrection),
                  });
              } else {
                const pageToSign = pages[customTextData[i].placement.page - 1];
                const customTextDimensionHeight = parseInt(
                  customTextData[i].dimensions.height.split('px')[0],
                );

                let logoPositionX = xCorText * width;
                let logoPositionY = height - height * yCorText - customTextDimensionHeight * 0.7;
                const correctedLogoPositionX = width - yCorText * width;
                const correctedLogoPositionY = height - height * xCorText;
                if (Math.abs(angleCorrection) > 0) {
                  logoPositionX = correctedLogoPositionX;
                  logoPositionY = correctedLogoPositionY;
                }

                currentSignerData?.custom_texts[i] &&
                  pageToSign.drawText(currentSignerData?.custom_texts[i], {
                    x: logoPositionX,
                    y: logoPositionY,
                    size: customTextDimensionHeight * 0.8,
                    font: arialFont,
                    color: rgb(0, 0, 0),
                    rotate: degrees(angleCorrection),
                  });
              }
            }
          }
        }
      }
    }

    const pdfBytes = await pdfDoc.save();
    const buff = new Buffer(pdfBytes);
    const base64 = buff.toString('base64');
    const pdfHash = getStringHash(base64);
    return pdfHash.then((hash) => {
      return { base64, hash, pdfBytes };
    });
  };

  return renderFunction(src, allSignerData, showOtherSignElement, uid, currentSignerData);
}
