import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '../../../../utils/domUtil';

import { process_env } from '../../../../utils/envData';

// Read more about the Javascript SDK API at https://www.dropbox.com/developers/saver
// Inspiration from https://www.npmjs.com/package/react-dropbox-chooser
const DropboxSaver = (props) => {
  const DROPBOX_SDK_URL = '/scripts/dropbox.js';
  const SCRIPT_ID = 'dropboxjs';
  const scriptLoadingStarted = useRef(false);
  const APP_KEY = process_env('REACT_APP_DROPBOX_CLIENT_ID');

  const { children, cancel, progress, onClick, disabled } = props;

  useEffect(() => {
    if (!isDropboxReady() && !scriptLoadingStarted.current) {
      scriptLoadingStarted.current = true;
      loadScript(DROPBOX_SDK_URL, {
        attrs: {
          id: SCRIPT_ID,
          'data-app-key': APP_KEY,
        },
      });
    }
  }, [APP_KEY]);

  const isDropboxReady = () => {
    return !!window.Dropbox;
  };

  const onSave = () => {
    if (!isDropboxReady() || disabled) {
      return null;
    }

    onClick(uploadToDropbox);
  };

  const uploadToDropbox = (fileInfo, successCallback = () => {}, errorCallback = () => {}) => {
    window.Dropbox.save({
      appKey: APP_KEY,
      success: successCallback,
      error: errorCallback,
      files: [{ url: fileInfo.data, filename: fileInfo.filename }],
      cancel,
      progress,
    });
  };

  return <div onClick={onSave}>{children}</div>;
};

DropboxSaver.propTypes = {
  children: PropTypes.node,
  cancel: PropTypes.func,
  progress: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DropboxSaver;
