import { dedocoAxios } from '../../../lib/api/axios';

export const startMeetingApi = (data) => {
  return dedocoAxios.post(`/businessprocess/meeting/start`, data);
};

export const joinMeetingApi = (data) => {
  return dedocoAxios.post(`/businessprocess/meeting/join`, data);
};

export const startRecordingApi = (data) => {
  return dedocoAxios.post(`/twilio/recording/start`, data);
};

export const stopRecordingApi = (data) => {
  return dedocoAxios.post(`/twilio/recording/stop`, data);
};
