import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
const useUnsavedChangesWarning = (
  message = 'The process is not completed, please DO NOT close the window',
) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload =
      isActive &&
      ((_e) => {
        return message;
      });

    return () => {
      window.onbeforeunload = null;
    };
  }, [isActive, message]);

  const routerPrompt = <Prompt when={isActive} message={message} />;

  return [routerPrompt, () => setIsActive(true), () => setIsActive(false)];
};

export default useUnsavedChangesWarning;
