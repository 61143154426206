import InputUnstyled from '@mui/base/InputUnstyled';
import { Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formStateSelector, updateFormFiller } from '../../../../../redux/slices/formSlice';
import { regexEmail } from '../../../../../utils/regex';
import { TermsAndConditionBody } from '../../../Sidebar/TermsAndCondition';
import { RecaptchaLabel } from '../../../UI/RecaptchaLabel';
import { classes, Root } from './FillerDetails.style';
import { CypressIds } from '../../../../../constant/cypressIds';

function FillerDetails() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { form } = useSelector(formStateSelector);

  const onSubmit = (data) => {
    dispatch(
      updateFormFiller({
        name: data.name,
        email: data.email,
        agreedToTnC: true,
      }),
    );
  };

  return (
    <Root>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formRoot} data-testid="filler-details">
          <Typography variant="title_large">{form?.name}</Typography>
          <Typography
            variant="body_medium"
            align="left"
            className={classes.recipientDetailsDescription}
          >
            {t('FormsContactDetailsDescription')}
          </Typography>
          <div className={classes.contactDetailsContainer}>
            <Typography variant="title_medium" className={classes.contactDetailsTitle}>
              {t('FormsContactDetails')}
            </Typography>
            <Typography variant="label_large" className={classes.textFieldLabel}>
              {t('FormsName')}
            </Typography>
            <InputUnstyled
              className={classes.textField}
              placeholder="Name"
              {...register('name', { required: t('NameRequired') })}
            />
            {errors.name && (
              <Typography className={classes.errorCaption} variant="caption">
                {errors.name?.message}
              </Typography>
            )}
            <Typography variant="label_large" className={classes.textFieldLabel}>
              {t('FormsEmail')}
            </Typography>
            <InputUnstyled
              className={classes.textField}
              placeholder="example@email.com"
              {...register('email', { required: t('EmailRequired'), pattern: regexEmail })}
            />
            {errors.email && (
              <Typography className={classes.errorCaption} variant="caption">
                {errors.email?.message}
              </Typography>
            )}
            {errors?.email?.type === 'pattern' && (
              <Typography className={classes.errorCaption} variant="caption">
                {t('ValidEmail')}
              </Typography>
            )}

            <TermsAndConditionBody
              containerStyle={classes.termsConditionContainer}
              contentStyle={classes.termsConditionContent}
            />

            <Button
              data-testid="tnc-submit-button"
              data-cypress={CypressIds.tncSubmitButton}
              type="submit"
              className={classes.proceedButton}
            >
              {t('FormsStartFilling')}
            </Button>
          </div>
        </div>
      </form>
      <RecaptchaLabel show />
    </Root>
  );
}

export default FillerDetails;
