import React, { useState, useEffect, useRef } from 'react';
import UserIcon from '../../../assets/user.png';
import MicIcon from '../../../assets/microphone.png';
import MicOffIcon from '../../../assets/microphone-slash.png';
import DesktopIcon from '../../../assets/desktop.png';

const Participant = ({
  participant,
  onScreenTrackSubscribed,
  isMute,
  isTurnOnCamera,
  isRemote,
  isHostParticipant,
  isSharingScreen,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [remoteTurnOnCamera, setRemoteTurnOnCamera] = useState(false);
  const [remoteTurnOnAudio, setRemoteTurnOnAudio] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    if (participant) {
      const trackSubscribed = (track) => {
        if (track.kind === 'video') {
          track.once('started', () => {
            if (track.name.startsWith('participant-screen')) {
              onScreenTrackSubscribed(track);
            } else {
              setVideoTracks([track]);
            }
          });
        } else {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };
      const trackUnsubscribed = (track) => {
        if (track.kind === 'video') {
          setVideoTracks((videoTracks) => [...videoTracks.filter((v) => v !== track)]);
        } else {
          setAudioTracks((audioTracks) => [...audioTracks.filter((a) => a !== track)]);
        }
      };
      setVideoTracks(trackpubsToTracks(participant?.videoTracks));
      setAudioTracks(trackpubsToTracks(participant?.audioTracks));

      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);
      participant.on('trackDisabled', (track) => {
        if (track.kind === 'video') {
          setRemoteTurnOnCamera(false);
        } else {
          setRemoteTurnOnAudio(false);
        }
      });
      participant.on('trackEnabled', (track) => {
        if (track.kind === 'video') {
          setRemoteTurnOnCamera(true);
        } else {
          setRemoteTurnOnAudio(true);
        }
      });
      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  const zoomTrack = (trackElement) => {
    if (!trackElement.classList.contains('video-zoomed')) {
      // zoom in
      trackElement.classList.add('video-zoomed');
    } else {
      // zoom out
      trackElement.classList.remove('video-zoomed');
    }
  };

  useEffect(() => {
    const videoTrack = videoTracks[0];
    const audioTrack = audioTracks[0];
    if (videoTrack || audioTrack) {
      const videoElement = videoTrack ? videoTrack.attach(videoRef.current) : null;
      videoElement &&
        videoElement.addEventListener('click', () => {
          zoomTrack(videoElement);
        });
      audioTrack && audioTrack.attach(audioRef.current);
      return () => {
        videoTrack && videoTrack.detach();
        audioTrack && audioTrack.detach();
      };
    }
  }, [videoTracks, audioTracks]);

  const isCameraEnabled =
    (!isRemote && isTurnOnCamera) ||
    (videoTracks && videoTracks[0] && videoTracks[0].isEnabled) ||
    (isRemote && remoteTurnOnCamera);

  const isAudioEnabled =
    (!isRemote && !isMute) ||
    (audioTracks && audioTracks[0] && audioTracks[0].isEnabled) ||
    (isRemote && remoteTurnOnAudio);

  return (
    <div
      id={'N_' + participant.sid}
      className={`participant-wrapper ${!isHostParticipant ? 'remote-participant' : ''}`}
    >
      <div className={`participant ${!isCameraEnabled ? 'participant-off' : ''}`}>
        <video
          className={isCameraEnabled ? '' : 'visibility-hidden'}
          style={{ width: '100%' }}
          ref={videoRef}
          autoPlay={true}
        />
        <div className={isCameraEnabled ? 'hidden' : 'avatar'}>
          <img src={UserIcon} alt="participant" />
        </div>
        <div
          className="mic-icon"
          style={{
            backgroundImage: `url(${isAudioEnabled ? MicIcon : MicOffIcon})`,
          }}
        ></div>
        <audio ref={audioRef} autoPlay={true} />
      </div>
      <div className="identity">
        {isSharingScreen && (
          <div className="sharing-screen-icon">
            <img src={DesktopIcon} alt="desktop" />
          </div>
        )}
        {participant?.identity}
      </div>
    </div>
  );
};

export default Participant;
