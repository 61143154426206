import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { Provider } from 'react-redux';
import FlagProvider from '@unleash/proxy-client-react';
import ConditionalParent from './components/Helpers/ConditionalParent/ConditionalParent';
import './i18n';

import { process_env } from './utils/envData';

const lighthouseConfig = {
  url: process_env('REACT_APP_LIGHTHOUSE_PROXY_URL'),
  clientKey: process_env('REACT_APP_LIGHTHOUSE_PROXY_CLIENT_KEY'),
  refreshInterval: 15,
  appName: process_env('REACT_APP_LIGHTHOUSE_APP_NAME') || 'signing-app',
  environment: process_env('REACT_APP_LIGHTHOUSE_ENV') || 'default',
};

let loadingPage = <div></div>;

const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingPage}>
    <ConditionalParent
      adopter={(children) => {
        return <FlagProvider config={lighthouseConfig}>{children}</FlagProvider>;
      }}
      shouldAdopt={
        process_env('REACT_APP_USE_LIGHTHOUSE') === 'true' ||
        process_env('REACT_APP_USE_LIGHTHOUSE') === true
      }
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConditionalParent>
  </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
