import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeScreenTrack, setScreenTrack } from '../../../redux/slices/twilioSlice/twilioSlice';
import { classes, ParticipantRoot } from './Participant.style';

const Participant = ({
  participant,
  isMainParticipant = false,
  isVideoMuted = false,
  twilioConnected = false,
  name = '',
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isRemoteVideoMute, setRemoteVideoMute] = useState(false);
  const dispatch = useDispatch();

  const videoRef = useRef();
  const audioRef = useRef();

  const nameAbbreviation = useMemo(() => {
    return name.split(' ').map((str) => str.replaceAll(/[\W]/g, '')[0]);
  }, [name]);

  const showPlaceholderLoading = useMemo(() => {
    return participant?.state !== 'connected' || !twilioConnected;
  }, [participant?.state, twilioConnected]);

  const showPlaceholder = useMemo(() => {
    return showPlaceholderLoading || (isMainParticipant ? isVideoMuted : isRemoteVideoMute);
  }, [isMainParticipant, isRemoteVideoMute, isVideoMuted, showPlaceholderLoading]);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    if (!twilioConnected) return;
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
        if (track.name.startsWith('participant-screen')) {
          dispatch(setScreenTrack(track));
        }
        if (!track.isEnabled) {
          // remote turn off cam
          setRemoteVideoMute(true);
        }
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        if (track.name.startsWith('participant-screen')) {
          const participantId = track.name.split('participant-screen-')[1];
          dispatch(removeScreenTrack(participantId));
        }
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const trackDisabled = (track) => {
      // if track.track exist, means this is remote track event
      if (track.kind === 'video' && !!track.track) {
        setRemoteVideoMute(true);
      }
    };

    const trackEnabled = (track) => {
      if (track.kind === 'video' && !!track.track) {
        setRemoteVideoMute(false);
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', trackDisabled);
    participant.on('trackEnabled', trackEnabled);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [dispatch, participant, twilioConnected]);

  useEffect(() => {
    if (!twilioConnected) return;
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [twilioConnected, videoTracks]);

  useEffect(() => {
    if (!twilioConnected) return;
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, twilioConnected]);

  return (
    <ParticipantRoot isMainParticipant={isMainParticipant}>
      {/* can only set its display to be none, if unmount the video when show placeholder,
          when the video mount again, will lost the video stream
      */}
      <video
        style={{ display: showPlaceholder ? 'none' : 'block' }}
        className={classes.video}
        ref={videoRef}
        autoPlay={true}
      />

      {showPlaceholder && (
        <Stack alignItems="center" justifyContent="center" className={classes.videoPlaceholder}>
          {showPlaceholderLoading ? <CircularProgress /> : nameAbbreviation}
        </Stack>
      )}
      <audio ref={audioRef} autoPlay={true} />
      <Typography
        className={classes.name}
        align="center"
        variant="body_small"
        sx={{ textOverflow: 'wrap' }}
      >
        {name}
      </Typography>
    </ParticipantRoot>
  );
};

export default Participant;
