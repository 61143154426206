import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Box, classes } from './DocumentStatusPanel.style';

export default function DocumentStatusPanel({ title, content, bottomButton, sx }) {
  return (
    <Box sx={sx}>
      <Stack>
        <Typography className={classes.title} variant="label_medium" align="left" gutterBottom>
          {title}
        </Typography>
        {Array.isArray(content) ? (
          content.map((text, id) => (
            <Typography
              key={id}
              className={classes.content}
              sx={(theme) => ({ margin: theme.spacing(1, 0) })}
              variant="subtitle1"
              align="left"
              gutterBottom
            >
              {text}
            </Typography>
          ))
        ) : (
          <Typography className={classes.content} variant="subtitle1" align="left" gutterBottom>
            {content}
          </Typography>
        )}

        {bottomButton}
      </Stack>
    </Box>
  );
}
