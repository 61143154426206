export const DVideoSignEvents = Object.freeze({
  JOIN_ROOM: 'dVideoSign-join-room',
  JOINED_ROOM: 'dVideoSign-joined-room',
  MEETING_START: 'dVideoSign-meeting-start',
  MEETING_STARTED: 'dVideoSign-meeting-started',
  NEXT_ACTION: 'dVideoSign-next-action',
  CHOOSE_NEXT_ACTION: 'dVideoSign-choose-next-action',
  LEAVE_CALL: 'dVideoSign-leave-call',
  LEFT_CALL: 'dVideoSign-left-call',
  COMPLETE_ACTION: 'dVideoSign-complete-action',
  CHANGE_UPDATE: 'dVideoSign-change-update',
  COMPLETED_SESSION: 'dVideoSign-completed-session',
  ERROR: 'dVideoSign-error',
  END_CALL: 'dVideoSign-end-call',
  SHARE_SCREEN: 'dVideoSign-share-screen',
  RECORD_SCREEN: 'dVideoSign-record-screen',
  STOP_RECORD_SCREEN: 'dVideoSign-stop-record-screen',
  ATTENDEE_HEARTBEAT: 'dVideoSign-attendee-heartbeat',
});

export const DVideoSignErrors = Object.freeze({
  NOT_READY: 'not-ready',
  JOIN_ROOM_FAILED: 'join-room-failed',
  UNABLE_TO_ACQUIRE_LOCK: 'unable-to-acquire-lock',
  LEAVE_CALL_FAILED: 'leave-call-failed',
  START_MEETING_FAILED: 'start-meeting-failed',
  NEXT_ACTION_FAILED: 'next-action-failed',
  COMPLETE_ACTION_FAILED: 'complete-action-failed',
  END_CALL_FAILED: 'end-call-failed',
  RECORD_SCREEN_FAILED: 'record-screen-failed',
  STOP_RECORD_SCREEN_FAILED: 'stop-record-screen-failed',
});

export const DVideoSignAction = Object.freeze({
  SIGN: 'sign',
  APPROVE: 'approve',
  REJECT: 'reject',
});
