import MuiAlert from '@mui/lab/Alert';
import { Box, Button, CircularProgress, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Client as ConversationsClient } from '@twilio/conversations';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Video from 'twilio-video';
import errorLogo from '../../assets/invalid-document.svg';
import { downloadFileFromDSS } from '../../lib/api/dss';
import { getCompanyLogo, getPublicWhiteLabelDetails } from '../../lib/api';
import { getIp } from '../../redux/actions/authfunctions';
import {
  getApproverDetails,
  getDocumentToSign,
  getObserverDetails,
  getPublicDocumentToSign,
  getPublicObserverDetails,
  signDocument,
  verifyApproverSingPassAuthCode,
  verifyObserverSingPassAuthCode,
  verifySignerSingPassAuthCode,
} from '../../redux/actions/documentfunctions';
import { generateFileShareKey } from '../../utils/fileShareKey';
import {
  DEDOCO_SUPPORT_EMAIL,
  PUBLIC_SIGNER_SIGNED,
  ROOM_STARTED,
  SIGNER_SIGNED,
  VS_NOTIFY_AT_50,
  VS_NOTIFY_AT_60,
  VS_NOTIFY_AT_80,
  VS_NOTIFY_AT_89,
} from '../../utils/GlobalConstant';
import SigningRequestOpenApi from '../OpenApi/SigningRequest';
import Header from '../UI/Header';
import MeetingNotStarted from './Meeting/MeetingNotStarted';
import RecipientAuthentication from './RecipientAuthentication';
import './SigningRequest.css';
import VerifyDocument from './VerifyDocument';

import { useTranslation } from 'react-i18next';
import useOldPdfTron from '../../hooks/useOldPdfTron';
import useRecipientRole from '../../hooks/useRecipientRole';
import { getPdfBlob } from '../../utils/oldPdftron';

import { blobToBase64DataUrl } from '../../utils/base64';

import { useWebexContext } from '../../contexts/useWebexContext';
import { useFeatureFlag, featureNameMap } from '../../hooks/useFeatureFlag';
import useForceUpdate from '../../hooks/useForceUpdate';
import { LoadingOverlay } from '../Helpers/LoadingOverlay';

const PREFIX = 'Main';

const classes = {
  vsAlert: `${PREFIX}-vsAlert`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.vsAlert}`]: {
    backgroundColor: '#FFF2CC',
    color: '#3B3B3B',
    borderRadius: '3px',
  },
}));

export default function Main(props) {
  // Flags
  const openApiVideoSignEnabled = useFeatureFlag(featureNameMap.openApiVideoSign);

  const { t } = useTranslation();

  const { hash, state: locationState } = useLocation();
  const dispatch = useDispatch();
  const documenttosign = useSelector((state) => state.documenttosign);
  const singPassAuthCode = locationState?.singPassAuthCode;
  const [singPassLoginFailed, setSingPassLoginFailed] = useState(false);

  const recipientSecret = useSelector((state) => state.documenttosign?.data?.recipientSecret);
  const Auth = useSelector((state) => state.Auth);

  const isVideoSigning = documenttosign?.data?.meeting?.enable;
  const { id, uid, public: publicVariable, url: b64Url, key } = useParams();
  const {
    isWebexSigning,
    isMeetingHost: isWebexMeetingHost,
    isWebexSigningSessionComplete,
  } = useWebexContext();
  const { isObserver, isApprover, isSigner, RecipientRoles } = useRecipientRole();

  const [whiteLabelDetails, setWhiteLabelDetails] = useState({});

  useEffect(() => {
    const loadWhiteLabelDetails = async () => {
      if (publicVariable) {
        const details = await getPublicWhiteLabelDetails(id);
        setWhiteLabelDetails(details);
      } else {
        const logoDetails = await getCompanyLogo(id);
        setWhiteLabelDetails(logoDetails);
      }
    };

    loadWhiteLabelDetails();
  }, [id, publicVariable]);

  // NOTE: currently handling DSSv2 id:key and legacy Lufi id-key keys
  // hash seperated key#decryptionKey needs to be recreated here
  // be careful if using # for other purposes
  const isUsingDss = hash !== '#' && hash !== '';
  const fileShareKey = isUsingDss ? generateFileShareKey(key, hash.slice(1)) : key;

  const {
    instance,
    setInstance,
    startDocument,
    signatureList,
    setSignatureList,
    jumpToAnnotation,
    handleBackStepSignature,
    handleNextStepSignature,
    handleStartDocument,
    dataFromPdf,
    setDataFromPdf,
    checkedFields,
    setCheckedFields,
  } = useOldPdfTron();

  const [downloadFileShareKey, setDownloadFileShareKey] = useState(fileShareKey);
  const [data, setData] = useState({});
  const [recipientVerifySetting, setRecipientVerifySetting] = useState(null);
  const [fileUrl, setfileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [finalPdf, setFinalPdf] = useState('');
  const [sendLink, setSendLink] = useState(true);
  const [initialHash, setInitialHash] = useState('');
  const [show, setShow] = useState(0);
  const [document, setDocument] = useState({
    signer: {},
    approver: {},
    observer: {},
    available: false,
    isLoading: true,
  });
  const [participant, setParticipant] = useState({
    name: '',
    email: '',
    role: '',
  });
  const [allSigners, setAllSigners] = useState([]);
  const [allApprovers, setAllApprovers] = useState([]);
  const [observeData, setObserveData] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarVSOpen, setSnackBarVSOpen] = useState(false);
  const [severityVS, setSeverityVS] = useState('');
  const [snackBarVSMessage, setSnackBarVSMessage] = useState('');
  const [conversation, setConversation] = useState(null);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [localTracks, setLocalTracks] = useState(null);
  const [isSignCompleted, setIsSignCompleted] = useState(false);
  const [isLoadingObserver, setIsLoadingObserver] = useState(false);
  const [isLoadingSigner, setIsLoadingSigner] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);

  const isUsingNDI = !!document.signer?.digi_signatures?.length;

  const [pdfBase64String, setPdfBase64String] = useState('');
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    // documenttosign.isLoading should not be used to directly hide/show the loading overlay.
    // This is to make sure that the loading overlay is hidden only after the view is updated with the new documenttosign data.
    // 		i.e. Hide loading overlay on the next render after documenttosign is loaded.
    // This prevents users from seeing the outdated UI.
    setShowLoadingOverlay(documenttosign.isLoading);
  }, [documenttosign.isLoading]);

  useEffect(() => {
    if (!singPassAuthCode) {
      return;
    }

    // Remove singPassAuthCode from location state
    const { singPassAuthCode: _, ...rest } = locationState;
    window.history.replaceState(rest, '');

    if (isObserver) {
      dispatch(verifyObserverSingPassAuthCode(id, uid, singPassAuthCode))
        .then(() => {
          forceUpdate();
        })
        .catch(() => {
          setSingPassLoginFailed(true);
        });
    } else if (isApprover) {
      dispatch(verifyApproverSingPassAuthCode(id, uid, singPassAuthCode))
        .then(() => {
          forceUpdate();
        })
        .catch(() => {
          setSingPassLoginFailed(true);
        });
    } else {
      dispatch(verifySignerSingPassAuthCode(id, uid, singPassAuthCode))
        .then(() => {
          forceUpdate();
        })
        .catch(() => {
          setSingPassLoginFailed(true);
        });
    }
  }, [isObserver, isApprover, id, uid, singPassAuthCode, dispatch, forceUpdate, locationState]);

  useEffect(() => {
    dispatch(getIp());

    if (isWebexMeetingHost && isObserver) return;

    if (publicVariable === 'public') {
      if (isSigner) {
        dispatch(getPublicDocumentToSign(id, uid));
      } else dispatch(getPublicObserverDetails(id, uid));
    } else if (isObserver) {
      dispatch(getObserverDetails(id, uid));
    } else if (isApprover) {
      dispatch(getApproverDetails(id, uid));
    } else {
      dispatch(getDocumentToSign(id, uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: to add logic for open api documents
  useEffect(() => {
    if (
      documenttosign.isloaded === true &&
      (documenttosign.data.business_process?.signers?.length > 0 ||
        documenttosign.data.business_process?.approvers?.length > 0)
    ) {
      setData({
        ...data,
        business_process_id: documenttosign.data.business_process.id,
        date_signed: Math.round(Date.now() / 1000),
        ip_address: Auth.ip,
        recipient_id: uid,
        digital_signatures: [],
        electronic_signatures: [],
        custom_texts: [],
        confirmations: [],
        allow_download: documenttosign.document.allow_download,
        //Added initial doc hash data as per API
        initial_document_hash: initialHash,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    if (data.download_link && sendLink) {
      setSendLink(false);
      dispatch(signDocument(data, id, uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.download_link]);
  //Gary added condition if business_process exists
  useEffect(() => {
    if (
      documenttosign.data.business_process &&
      documenttosign.isloaded === true &&
      (documenttosign.data.business_process.signers?.length > 0 ||
        documenttosign.data.business_process.approvers?.length > 0)
    ) {
      setObserveData(documenttosign.data.business_process.signers);
      documenttosign.data.business_process.signers.map((signer) => {
        //TODO: updated signer_id to id
        if ((signer.id ? signer.id : signer.signer_id === uid) || isObserver) {
          setDocument({ signer, available: true, isLoading: false });
          setParticipant({
            id: uid,
            name: signer.name,
            email: signer.email,
            role: RecipientRoles.SIGNER,
            termsAndConditionsConsentTimestamp: signer.terms_and_conditions_consent_timestamp,
          });
        } else if (allSigners.length === 0) {
          setAllSigners((allSigners) => [...allSigners, signer]);
        }
        return '';
      });
      if (isApprover) {
        documenttosign.data.business_process.approvers.map((approver) => {
          //TODO: updated approver_id to id
          if (approver.id ? approver.id : approver.approver_id === uid) {
            setDocument({ approver, available: true, isLoading: false });
            setParticipant({
              id: uid,
              name: documenttosign?.data?.recipientName,
              email: documenttosign?.data?.recipientEmail,
              role: RecipientRoles.APPROVER,
              termsAndConditionsConsentTimestamp: approver.terms_and_conditions_consent_timestamp,
            });
          } else if (allApprovers.length === 0) {
            setAllApprovers((allApprovers) => [...allApprovers, approver]);
          }
          return '';
        });
      }
      if (isObserver) {
        documenttosign.data.business_process?.observers?.some((observer) => {
          if (observer.id ? observer.id : observer.observer_id === uid) {
            setDocument((prevDoc) => ({
              ...prevDoc,
              observer,
            }));
            setParticipant({
              id: uid,
              name: documenttosign?.data?.recipientName,
              email: documenttosign?.data?.recipientEmail,
              role: RecipientRoles.OBSERVER,
              termsAndConditionsConsentTimestamp: observer.terms_and_conditions_consent_timestamp,
            });
            return true;
          }
        });
      }
    } else if (documenttosign.isloaded && !document.available) {
      setDocument({ ...document, isLoading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttosign]);

  useEffect(() => {
    if (allSigners.length > 0) {
      setAllSigners([]);
      documenttosign.data.business_process.signers.map((signer) => {
        if (signer.id ? signer.id : signer.signer_id !== uid || !isObserver) {
          setAllSigners((allSigners) => [...allSigners, signer]);
        }
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttosign?.document?.signers]);

  useEffect(() => {
    if (documenttosign.isloaded) {
      if (documenttosign?.data?.require_authentication) {
        setRecipientVerifySetting(documenttosign?.data);
      } else {
        setRecipientVerifySetting(null);
      }
    }
  }, [documenttosign.data, documenttosign.isloaded]);

  useEffect(() => {
    if (!conversation && isVideoSigning && documenttosign?.data?.chatToken) {
      ConversationsClient.create(documenttosign.data.chatToken).then((cClient) => {
        cClient
          .getConversationByUniqueName(documenttosign.data.business_process.id)
          .then((conv) => {
            conv.on('messageAdded', (m) => {
              if (m.author !== documenttosign?.data?.identity) {
                switch (m.body) {
                  case ROOM_STARTED:
                    setMeetingStarted(true);
                    break;
                  case SIGNER_SIGNED:
                    reloadSignature();
                    break;
                  case PUBLIC_SIGNER_SIGNED:
                    if (openApiVideoSignEnabled) reloadPublicSignature();
                    break;
                  case VS_NOTIFY_AT_50:
                    showVSNotification(t('VSNotifyLast10Mins'));
                    break;
                  case VS_NOTIFY_AT_60:
                    showVSNotification(t('VSNotifyExtended30Mins'));
                    break;
                  case VS_NOTIFY_AT_80:
                    showVSNotification(t('VSNotifyLast10Mins'));
                    break;
                  case VS_NOTIFY_AT_89:
                    showVSNotification(t('VSNotifyEndIn1Min'));
                    break;
                  default:
                    break;
                }
              }
            });
            setConversation(conv);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttosign]);

  useEffect(() => {
    if (
      !documenttosign?.data?.isMeetingHost &&
      isVideoSigning &&
      documenttosign?.data?.meeting?.isActive
    ) {
      setMeetingStarted(true);
    }
  }, [
    isVideoSigning,
    documenttosign?.data?.meeting?.isActive,
    documenttosign?.data?.isMeetingHost,
  ]);

  const reloadSignature = () => {
    if (isSigner) {
      dispatch(getDocumentToSign(id, uid, recipientSecret));
    } else if (isObserver) {
      setIsLoadingObserver(true);
      dispatch(getObserverDetails(id, uid, recipientSecret));
    }
  };

  const reloadPublicSignature = () => {
    if (isSigner) {
      dispatch(getPublicDocumentToSign(id, uid, recipientSecret));
    } else if (isObserver) {
      setIsLoadingObserver(true);
      dispatch(getPublicObserverDetails(id, uid, recipientSecret));
    }
  };

  const getPdfFromDocViewer = async () => {
    if (!instance) {
      return '';
    }
    const { documentViewer, annotationManager } = instance.Core;
    const pdfBlob = await getPdfBlob(documentViewer, annotationManager, true);
    return blobToBase64DataUrl(pdfBlob);
  };

  const getPdfDataToDownload = async () => {
    let pdfData = '';
    if ((documenttosign.data?.isLastActor && isSignCompleted) || isWebexSigningSessionComplete) {
      try {
        pdfData = await downloadFileFromDSS(downloadFileShareKey);
      } catch (err) {
        setSeverity('error');
        setSnackBarOpen(true);
        setSnackBarMessage(t('DownloadPageErrorFailToFetchPDF'));
      }
    } else {
      pdfData = await getPdfFromDocViewer();
    }

    return `${
      pdfData.startsWith('data:application/pdf;base64,')
        ? pdfData
        : 'data:application/pdf;base64,' + pdfData
    }`;
  };

  //Download handler for DownloadOptionsButton
  const handleDownloadPdf = async (downloadOptionDetails) => {
    const saveFile = downloadOptionDetails.downloadFunction;
    const pdfData = await getPdfDataToDownload();

    const fileInfo = {
      data: pdfData,
      filename: fileName,
    };

    saveFile(
      fileInfo,
      () => {
        setSnackBarOpen(true);
        setSeverity('success');
        setSnackBarMessage(t('UploadPDFToRemoteStorageSuccess'));
        !isWebexSigning && redirectAfterDownload(2000);
      },
      () => {
        setSnackBarOpen(true);
        setSeverity('error');
        setSnackBarMessage(t('UploadPDFToRemoteStorageFailure'));
      },
    );
  };

  const redirectAfterDownload = (waitTimeMs) => {
    if (isSignCompleted) {
      setTimeout(() => {
        if (publicVariable) {
          history.push(`/public/done/${id}/${uid}`);
        } else {
          history.push(`/done/${id}/${uid}`);
        }
      }, waitTimeMs);
    }
  };

  //Gary (reroute function)
  const rerouteDedoco = () => {
    window.location = 'https://dedoco.com/';
  };

  const createLocalTracks = async () => {
    try {
      const tracks = await Video.createLocalTracks({
        video: {
          width: 640,
          height: 480,
          name: `participant-video-${uid}`,
        },
        audio: {
          name: `participant-audio-${uid}`,
        },
      });
      if (tracks) {
        tracks.some((track) => {
          if (track.kind === 'video') {
            const videoProcessor = {
              processFrame: async (inputFrame, outputFrame) => {
                const ctx = outputFrame.getContext('2d');
                ctx.save();
                ctx.scale(-1, 1);
                ctx.drawImage(inputFrame, 0, 0, -inputFrame.width, inputFrame.height);
                ctx.restore();
                // Watermark
                ctx.font = '32px Arial';
                const text = 'Powered by Dedoco';
                const sz = ctx.measureText(text);
                ctx.fillStyle = 'black';
                ctx.fillText(text, 2 + outputFrame.width - sz.width - 10, outputFrame.height - 10);
                ctx.fillStyle = 'white';
                ctx.fillText(text, outputFrame.width - sz.width - 10, outputFrame.height - 10 - 2);
              },
            };
            track.addProcessor(videoProcessor);
            setLocalTracks(tracks);
            return true;
          } else return false;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isVideoSigning && !localTracks) {
      createLocalTracks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenttosign]);

  const allowDownload = () => {
    const bp = documenttosign?.data?.business_process || {};
    let dataField = 'approvers';
    let idField = 'id';
    if (isApprover) {
      idField = 'approver_id';
    }
    if (isSigner) {
      dataField = 'signers';
      idField = 'id';
    }
    if (isObserver) {
      dataField = 'observers';
      idField = 'id';
    }
    return (bp?.[dataField] || []).find((item) => item?.[idField] === uid)?.is_downloadable;
  };

  const isDownloadable =
    allowDownload() && (!isVideoSigning || documenttosign?.data?.isMeetingHost || joinMeeting);

  const showVSNotification = (vsMessage) => {
    setSeverityVS('info');
    setSnackBarVSOpen(true);
    setSnackBarVSMessage(vsMessage);
  };

  return (
    <StyledBox className="w-100 h-100 flex column">
      <LoadingOverlay show={showLoadingOverlay} />
      <Snackbar
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert severity={severity}> {snackBarMessage} </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={snackBarVSOpen}
        onClose={() => {
          setSnackBarVSOpen(false);
        }}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert className={classes.vsAlert} severity={severityVS}>
            {snackBarVSMessage}
          </Alert>
        </div>
      </Snackbar>
      <Header
        handleDownloadPdf={isDownloadable && handleDownloadPdf}
        pdf={documenttosign.fileUrl}
        isSignCompleted={isSignCompleted}
        fileName={fileName}
        document={documenttosign.document}
        isSequentialSigning={documenttosign.data.isSequential}
        id={id}
        uid={uid}
        isSigner={isSigner}
        isApprover={isApprover}
        isObserver={isObserver}
        fileShareKey={fileShareKey}
        data={data}
        initialHash={initialHash}
        isApprovingDocument={documenttosign.isApprovingDocument}
        documentUpdatedApprovement={documenttosign.documentUpdatedApprovement}
        isDocumentShowed={show}
        documentAvailable={document.available || recipientVerifySetting}
        isDownloadable={isDownloadable}
        observer={document?.observer}
        publicVariable={publicVariable}
        whiteLabelDetails={whiteLabelDetails}
      />
      {documenttosign.isloaded ? (
        (!(
          !documenttosign.isJoinedMeeting &&
          !isObserver &&
          (document?.signer?.has_signed ||
            document?.approver?.has_approved ||
            document?.approver?.has_declined)
        ) ||
          documenttosign.isJoinedMeeting ||
          isWebexSigning) &&
        (document.available || recipientVerifySetting) &&
        (!isVideoSigning ||
          (isVideoSigning && (documenttosign?.data?.isMeetingHost || joinMeeting))) ? (
          show === 0 && documenttosign?.data?.business_process ? (
            //Gary (added props for setting initial hash and file share key)
            <VerifyDocument
              isUsingNDI={isUsingNDI}
              b64Url={b64Url}
              publicVariable={publicVariable}
              setSeverity={setSeverity}
              setSnackBarOpen={setSnackBarOpen}
              setSnackBarMessage={setSnackBarMessage}
              setHash={setInitialHash}
              fileShareKey={fileShareKey}
              setShow={setShow}
              setfileUrl={setfileUrl}
              setPdfBase64String={setPdfBase64String}
              fileName={fileName}
              setFileName={setFileName}
              document={document}
              id={id}
              uid={uid}
              setData={setData}
              data={data}
              isObserver={isObserver}
              isApprover={isApprover}
            />
          ) : recipientVerifySetting ? (
            <RecipientAuthentication
              publicVariable={publicVariable}
              isVerifyingOTP={documenttosign.isVerifyingOTP}
              otpVerifyPopUp={true}
              recipientVerifySetting={recipientVerifySetting}
              recipientId={uid}
              documentId={id}
              isObserver={isObserver}
              isApprover={isApprover}
              fileShareKey={fileShareKey}
              singPassAuthCode={singPassAuthCode}
              singPassLoginFailed={singPassLoginFailed}
            />
          ) : props?.isOpenApi ? (
            <SigningRequestOpenApi
              className="signing-page"
              setIsSignCompleted={setIsSignCompleted}
              isSignCompleted={isSignCompleted}
              publicVariable={publicVariable}
              fileShareKey={fileShareKey}
              initialHash={initialHash}
              finalPdf={finalPdf}
              setFinalPdf={setFinalPdf}
              pdfBase64String={pdfBase64String}
              pdf={fileUrl}
              setPdf={setfileUrl}
              data={data}
              setData={setData}
              isApprovingDocument={documenttosign.isApprovingDocument}
              documentUpdatedApprovement={documenttosign.documentUpdatedApprovement}
              meeting={documenttosign.data.meeting}
              meetingToken={documenttosign.meetingToken}
              isJoinedMeeting={documenttosign.isJoinedMeeting}
              participant={participant}
              conversation={conversation}
              isRecording={isRecording}
              setIsRecording={setIsRecording}
              isMeetingHost={documenttosign.data.isMeetingHost}
              localTracks={localTracks}
              handleDownloadPdf={handleDownloadPdf}
              setDownloadFileShareKey={setDownloadFileShareKey}
              instance={instance}
              setInstance={setInstance}
              isDownloadable={isDownloadable}
              dataFromPdf={dataFromPdf}
              setDataFromPdf={setDataFromPdf}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              jumpToAnnotation={jumpToAnnotation}
              handleBackStepSignature={handleBackStepSignature}
              handleNextStepSignature={handleNextStepSignature}
              handleStartDocument={handleStartDocument}
              startDocument={startDocument}
              signatureList={signatureList}
              setSignatureList={setSignatureList}
              isLoadingObserver={isLoadingObserver}
              setIsLoadingObserver={setIsLoadingObserver}
              isLoadingSigner={isLoadingSigner}
              setIsLoadingSigner={setIsLoadingSigner}
              showVSNotification={showVSNotification}
              isSequentialSigning={documenttosign.data.isSequential}
              allSignersData={documenttosign.document}
              isObserver={isObserver}
              isApprover={isApprover}
              isSigner={isSigner}
              observeData={observeData}
              signatureplacements={document.signer}
              allSigners={allSigners}
              FileName={fileName}
              id={id}
              uid={uid}
              savingSignatureSecret={documenttosign.data.savingSignatureSecret}
              savedSignatures={documenttosign.data.savedSignatures}
              bp={documenttosign.data?.business_process}
              isLastActor={documenttosign.data?.isLastActor}
              document={documenttosign.document}
              documentId={documenttosign.data?.business_process?.document_id}
              whiteLabelDetails={whiteLabelDetails}
            />
          ) : (
            // Old UI for dSign is deprecated
            <></>
          )
        ) : isVideoSigning && !documenttosign?.data?.isMeetingHost ? (
          <MeetingNotStarted
            documentId={id}
            meetingInfo={documenttosign?.data?.meeting}
            meetingStarted={meetingStarted}
            setJoinMeeting={setJoinMeeting}
            localTracks={localTracks}
          />
        ) : document.isLoading ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: '15vh' }}>
              <img src={errorLogo} alt="error" width="330vmin" height="auto" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                className="pt1 pb1"
                style={{
                  fontSize: 32,
                  fontFamily: 'Lato',
                  fontWeight: '800',
                  color: '#4f4f4f',
                  width: '70vw',
                }}
              >
                {t('MainHeader')}
              </div>
            </div>
            <div>
              <div
                className="pt1 pb1"
                style={{
                  width: '70vw',
                  margin: 'auto',
                  fontSize: 20,
                  color: '#4f4f4f',
                  whiteSpace: 'break-spaces',
                }}
              >
                {t('SubText', {
                  supportEmail: whiteLabelDetails.supportEmail || DEDOCO_SUPPORT_EMAIL,
                })}
              </div>
            </div>
            <div className="pt1 pb1">
              <Button
                onClick={rerouteDedoco}
                variant="contained"
                style={{
                  fontSize: 14,
                  lineHeight: 1,
                  padding: '17px 40px',
                  fontWeight: '600',
                  backgroundColor: '#3565E3',
                  color: 'white',
                }}
              >
                {t('MainExitButton')}
              </Button>
            </div>
          </>
        )
      ) : (
        documenttosign.isLoading && (
          <CircularProgress style={{ position: 'absolute', left: '46%', top: '50%' }} />
        )
      )}
    </StyledBox>
  );
}
