import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import './VideoChat.css';
import { Videocam, VideocamOff, Mic, MicOff, FiberManualRecord } from '@mui/icons-material';
import dayjs from 'dayjs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import {
  VS_NOTIFY_AT_50,
  VS_NOTIFY_AT_60,
  VS_NOTIFY_AT_80,
  VS_NOTIFY_AT_89,
} from '../../../utils/GlobalConstant';
import { isMobile } from 'react-device-detect';
import Timer from './Timer';
import Alert from '@mui/lab/Alert';
import InfoImg from '../../../assets/info.png';

const PREFIX = 'Room';

const classes = {
  warningAlert: `${PREFIX}-warningAlert`,
};

const Root = styled('div')(() => ({
  [`& .${classes.warningAlert}`]: {
    background: '#FFF2CC',
    color: '#3B3B3B',
    fontWeight: 600,
  },
}));

const Room = ({
  documentId,
  meetingToken,
  onLeave,
  isRecording,
  stopRecording,
  startRecording,
  setIsRecording,
  isMeetingHost,
  meeting,
  localTracks,
  hasDownload,
  showVSNotification,
  conversation,
  roomDetail,
}) => {
  const { t } = useTranslation();
  const [room, setRoom] = useState(null);
  const [shareScreen, setShareScreen] = useState(false);
  const [screenTrack, setScreenTrack] = useState(null);
  const [isTurnOnCamera, setIsTurnOnCamera] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [viewDocument, setViewDocument] = useState(true);
  const [viewSharedScreenClicked, setViewSharedScreenClicked] = useState(false);
  const [recordedAt, setRecordedAt] = useState(null);
  const [formattedRecordingTime, setFormattedRecordingTime] = useState(null);
  const [endCallDialog, setEndCallDialog] = useState(false);
  const [meetingEndedDialog, setMeetingEndedDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [hostParticipant, setHostParticipant] = useState(null);
  const [startSession, setStartSession] = useState(false);
  const lobbyPannel = document.getElementById('meeting-lobby');
  const headerPannel = document.getElementsByClassName('header');

  // snack bar states
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const onScreenTrackSubscribed = (track) => {
    const video = document.getElementById('share-screen-video');
    const participantId = track.name.split('participant-screen-')[1];
    track.participantId = participantId;
    setScreenTrack(track);
    const trackElement = track.attach();
    trackElement.addEventListener('click', () => {
      zoomTrack(trackElement);
    });
    video.appendChild(trackElement);
    track.mediaStreamTrack.onended = () => {
      setViewDocument(true);
      video.removeChild(video.childNodes[0]);
      setScreenTrack(null);
    };
  };

  const remoteParticipants = participants.map((participant) => {
    if (participant.identity !== `${meeting.host.name} (${meeting.host.email})`) {
      return (
        <Grid key={participant.sid} item md={6} xs={12}>
          <Participant
            participant={participant}
            onScreenTrackSubscribed={onScreenTrackSubscribed}
            isRemote={true}
            isSharingScreen={screenTrack?.participantId?.includes(participant.sid)}
          />
        </Grid>
      );
    }
  });

  const zoomTrack = (trackElement) => {
    if (!trackElement.classList.contains('video-zoomed')) {
      // zoom in
      trackElement.classList.add('video-zoomed');
    } else {
      // zoom out
      trackElement.classList.remove('video-zoomed');
    }
  };

  const handleShareScreen = async (e) => {
    e?.preventDefault();
    const video = document.getElementById('share-screen-video');
    try {
      if (!screenTrack) {
        const stream = await navigator.mediaDevices.getDisplayMedia();
        const track = new Video.LocalVideoTrack(stream.getTracks()[0], {
          name: `participant-screen-${room?.localParticipant?.sid}-${Date.now()}`,
        });
        const participantId = track.name.split('participant-screen-')[1];
        track.participantId = participantId;
        setScreenTrack(track);
        room?.localParticipant?.publishTrack(track);
        track.mediaStreamTrack.onended = () => {
          handleShareScreen();
        };
        const trackElement = track.attach();
        trackElement.addEventListener('click', () => {
          zoomTrack(trackElement);
        });
        video.appendChild(trackElement);
      } else {
        room?.localParticipant?.unpublishTrack(screenTrack);
        screenTrack.stop();
        screenTrack.detach();
        video.removeChild(video.childNodes[0]);
        setScreenTrack(null);
        setViewSharedScreenClicked(false);
      }
      setShareScreen(!shareScreen);
    } catch (error) {
      setScreenTrack(null);
      setShareScreen(false);
      setOpenErrorDialog(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!room) {
      const participantConnected = (participant) => {
        if (
          !isMeetingHost &&
          participant.identity === `${meeting.host.name} (${meeting.host.email})`
        ) {
          setHostParticipant(participant);
        }
        setParticipants((prevParticipants) => [...prevParticipants, participant]);
      };
      const participantDisconnected = (participant) => {
        if (participant.identity === `${meeting.host.name} (${meeting.host.email})`) {
          leaveRoom();
          setMeetingEndedDialog(true);
        } else {
          setParticipants((prevParticipants) => [
            ...prevParticipants.filter((p) => p !== participant),
          ]);
        }
      };
      let lastSpeakerSID = null;

      const setLabelColor = (label, color) => {
        if (label !== null) {
          label.style.border = `2px solid ${color}`;
          label.style.borderRadius = `4px`;
        }
      };

      const removeDominantSpeaker = () => {
        const speakerNameLabel = document.getElementById(lastSpeakerSID);
        setLabelColor(speakerNameLabel, '#fff');
      };

      const assignDominantSpeaker = (participant) => {
        lastSpeakerSID = 'N_' + participant.sid;
        const domimantSpeakerNameLabel = document.getElementById(lastSpeakerSID);
        setLabelColor(domimantSpeakerNameLabel, '#0D41BF');
      };

      const handleSpeakerChange = (participant) => {
        removeDominantSpeaker();
        if (participant !== null) assignDominantSpeaker(participant);
      };

      if (!localTracks || localTracks.length <= 0) {
        alert(t('RoomMediaDeviceNotFound'));
      } else {
        Video.connect(meetingToken, {
          name: documentId,
          tracks: localTracks,
          dominantSpeaker: true,
        })
          .then((room) => {
            setRoom(room);
            if (room.isRecording) {
              setIsRecording(true);
            }
            room.on('recordingStarted', () => {
              setIsRecording(true);
              setRecordedAt(dayjs());
            });
            room.on('recordingStopped', () => {
              setIsRecording(false);
              setRecordedAt(null);
            });
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
            room.on('dominantSpeakerChanged', (participant) => {
              handleSpeakerChange(participant);
            });
            const oneSecondInMs = 1000;
            const oneMinuteInMs = 60 * oneSecondInMs;
            if (isMeetingHost) {
              setStartSession(true);
              setTimeout(() => {
                showVSNotification(t('VSNotifyLast10Mins'));
                conversation?.sendMessage(VS_NOTIFY_AT_50);
              }, 50 * oneMinuteInMs);
              setTimeout(() => {
                showVSNotification(t('VSNotifyExtended30Mins'));
                conversation?.sendMessage(VS_NOTIFY_AT_60);
              }, 60 * oneMinuteInMs);
              setTimeout(() => {
                showVSNotification(t('VSNotifyLast10Mins'));
                conversation?.sendMessage(VS_NOTIFY_AT_80);
              }, 80 * oneMinuteInMs);
              setTimeout(() => {
                showVSNotification(t('VSNotifyEndIn1Min'));
                conversation?.sendMessage(VS_NOTIFY_AT_89);
              }, 89 * oneMinuteInMs);
              setTimeout(() => {
                setStartSession(false);
                leaveRoom();
              }, 90 * oneMinuteInMs);
            }
          })
          .catch();
      }

      return () => {
        setRoom((currentRoom) => {
          if (currentRoom && currentRoom.localParticipant.state === 'connected') {
            currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, meetingToken, localTracks]);

  const leaveRoom = () => {
    setRecordedAt(null);
    if (isMeetingHost) {
      stopRecording();
    }
    if (room && room?.localParticipant?.state === 'connected') {
      room?.localParticipant?.tracks.forEach(function (trackPublication) {
        trackPublication.track.stop();
      });
      room?.disconnect();
    }
    if (meetingEndedDialog || isMeetingHost || endCallDialog) {
      window.location.href = 'https://dedoco.com';
      onLeave();
    }
  };

  const endCallToggle = () => {
    setEndCallDialog(!endCallDialog);
  };

  const onMute = () => {
    if (room?.localParticipant?.audioTracks) {
      room?.localParticipant?.audioTracks?.forEach((track) => {
        if (isMute) {
          track?.track?.enable();
        } else {
          track?.track?.disable();
        }
      });
      setIsMute(!isMute);
    }
  };

  const onTurnOnCamera = () => {
    if (room?.localParticipant?.videoTracks) {
      room?.localParticipant?.videoTracks?.forEach((track) => {
        if (isTurnOnCamera) {
          track.on('disabled', () => track?.track?.stop());
          track?.track?.disable();
        } else {
          track.on('enabled', () => track?.track?.restart());
          track?.track?.enable();
        }
      });
      setIsTurnOnCamera(!isTurnOnCamera);
    }
  };

  const handleRecording = () => {
    if (isRecording) {
      setIsRecording(false);
      setRecordedAt(null);
      stopRecording();
    } else {
      setIsRecording(true);
      setRecordedAt(null);
      startRecording();
    }
  };

  useEffect(() => {
    let recordingTimer;
    if (recordedAt) {
      const newRecordingTimer = setInterval(() => {
        const current = dayjs();
        let hour = current.diff(recordedAt, 'h');
        hour = hour > 9 ? hour : '0' + hour;
        let minute = current.diff(recordedAt, 'm');
        minute = minute > 9 ? minute : '0' + minute;
        if (minute > 59) {
          minute = minute - Math.floor(minute / 60) * 60;
        }
        let second = current.diff(recordedAt, 's');
        if (second > 59) {
          second = second - Math.floor(second / 60) * 60;
        }
        second = second > 9 ? second : '0' + second;
        setFormattedRecordingTime(`${hour}:${minute}:${second}`);
      }, 1000);
      recordingTimer = newRecordingTimer;
    } else {
      setFormattedRecordingTime(null);
      clearInterval(recordingTimer);
    }
    return () => {
      if (recordingTimer) {
        clearInterval(recordingTimer);
      }
    };
  }, [recordedAt]);

  const handleExpiredSession = (message) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
  };

  return (
    <Root className="room">
      <Snackbar
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert
            className={classes.warningAlert}
            severity={'warning'}
            icon={<img src={InfoImg} width={24} />}
          >
            {snackBarMessage}
          </Alert>
        </div>
      </Snackbar>
      <div
        className={viewDocument ? 'hidden' : ''}
        id="share-screen-video"
        style={{
          width: headerPannel[0]?.clientWidth - lobbyPannel?.clientWidth,
          height: lobbyPannel?.clientHeight,
          left: lobbyPannel?.clientWidth,
          top: headerPannel[0]?.clientHeight,
        }}
      />
      <div className="participants" style={{ paddingTop: isMobile ? '8rem' : '8px' }}>
        <div className="local-participant">
          {room &&
            ((isMeetingHost && room.localParticipant) ||
              (!isMeetingHost && participants.length > 0)) && (
              <Participant
                participant={isMeetingHost ? room.localParticipant : hostParticipant}
                isMute={isMeetingHost ? isMute : null}
                isTurnOnCamera={isMeetingHost ? isTurnOnCamera : null}
                isRemote={!isMeetingHost}
                onScreenTrackSubscribed={isMeetingHost ? null : onScreenTrackSubscribed}
                isHostParticipant={true}
                isSharingScreen={screenTrack?.participantId?.includes(
                  isMeetingHost ? room.localParticipant.sid : hostParticipant?.sid,
                )}
              />
            )}
        </div>
        <div className="total-recipients">
          {t('RoomTotalParticipant', {
            total: remoteParticipants.length,
          })}
        </div>
        <Grid container spacing={2} className="remote-participants">
          {room && !isMeetingHost && (
            <Grid item md={6} xs={12}>
              <Participant
                participant={room.localParticipant}
                isMute={isMute}
                isTurnOnCamera={isTurnOnCamera}
                isSharingScreen={screenTrack?.participantId?.includes(room.localParticipant.sid)}
              />
            </Grid>
          )}
          {remoteParticipants}
        </Grid>
      </div>
      <div className="call-actions">
        {screenTrack && !shareScreen && (
          <button
            className={`call-action-btn ${!viewSharedScreenClicked ? 'glowing' : ''}`}
            onClick={() => {
              setViewSharedScreenClicked(true);
              setViewDocument(!viewDocument);
            }}
          >
            {viewDocument ? t('RoomViewSharedScreen') : t('RoomViewDocument')}
          </button>
        )}
        {(!screenTrack || (screenTrack && shareScreen)) && (
          <button className="call-action-btn" onClick={handleShareScreen}>
            {shareScreen && screenTrack
              ? t('RoomStopShareScreen')
              : screenTrack
              ? ''
              : t('RoomShareScreen')}
          </button>
        )}
        {isMeetingHost && (
          <button className="call-action-btn" onClick={handleRecording}>
            {isRecording ? t('RoomStopRecord') : t('RoomStartRecord')}
          </button>
        )}
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={12} md={6}>
            <button className="call-action-btn call-action-icon" onClick={onMute}>
              {isMute ? <MicOff /> : <Mic />} {t('RoomMic')}
            </button>
          </Grid>
          <Grid item xs={12} md={6}>
            <button className="call-action-btn call-action-icon" onClick={onTurnOnCamera}>
              {isTurnOnCamera ? <Videocam /> : <VideocamOff />} {t('RoomCam')}
            </button>
          </Grid>
        </Grid>
        <button className="end-call-btn" onClick={endCallToggle}>
          {t('RoomEnd')}
        </button>
        {startSession && (
          <div
            className={`countdown-notification ${
              hasDownload ? 'countdown-notification-right' : ''
            }`}
            style={{
              marginTop: isMobile ? '4rem' : '1rem',
              left: isMobile ? '0.5rem' : 'auto',
              right: isRecording ? '29rem' : '18rem',
              maxWidth: isMobile ? '26%' : 'auto',
              fontSize: isMobile ? '12px' : 'inherit',
              width: 'fit-content',
              background: '#F8E4DF',
            }}
          >
            {t('TimerStartSession')} &nbsp;
            <span className="countdown-notification-label">
              <Timer handleExpiredTime={handleExpiredSession} roomDetail={roomDetail} />
            </span>
          </div>
        )}
        {isRecording && (
          <div
            className={`recording-notification ${
              hasDownload ? 'recording-notification-right' : ''
            }`}
            style={{
              marginTop: isMobile ? '4rem' : '1rem',
              left: isMobile ? '0.5rem' : 'auto',
              maxWidth: isMobile ? '26%' : 'auto',
              fontSize: isMobile ? '12px' : 'inherit',
              width: 'fit-content',
            }}
          >
            <FiberManualRecord /> {t('RoomRecording')}
            {formattedRecordingTime ? `: ${formattedRecordingTime}` : null}
          </div>
        )}
      </div>
      <Dialog
        open={endCallDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setEndCallDialog(false);
          }
        }}
        className="end-call-dialog"
      >
        <DialogTitle className="title">
          {isMeetingHost ? t('RoomEndMeeting') : t('RoomLeaveMeeting')}
        </DialogTitle>
        <DialogContent className="content">
          <DialogContentText className="description">
            {isMeetingHost ? t('RoomEndMeetingDescription') : t('RoomLeaveMeetingDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <button className="back-button-security" onClick={() => setEndCallDialog(false)}>
            {t('RoomCancel')}
          </button>
          <button onClick={leaveRoom} className="submit-button-security" autoFocus>
            {t('RoomContinue')}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={meetingEndedDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setMeetingEndedDialog(false);
          }
        }}
        className="end-call-dialog"
      >
        <DialogTitle className="title">{t('RoomMeetingEndedTitle')}</DialogTitle>
        <DialogContent className="content">
          <DialogContentText className="description">
            {t('RoomMeetingEndedSubtitle')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <button onClick={leaveRoom} className="submit-button-security" autoFocus>
            {t('RoomExit')}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenErrorDialog(false)}
        open={openErrorDialog}
        className="end-call-dialog"
      >
        <DialogTitle className="title">
          <Box display="flex" alignItems="center" marginBottom="12px !important">
            <ErrorOutlineIcon color="error" />
            <Typography style={{ marginLeft: '5px' }} variant="h5">
              {t('RoomSharingScreenErrorTitle')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className="content">
          <Typography variant="body2">{t('RoomSharingScreenErrorSubtitle')}</Typography>
        </DialogContent>
        <DialogActions className="actions">
          <button
            onClick={() => setOpenErrorDialog(false)}
            className="submit-button-security"
            autoFocus
          >
            {t('RoomClose')}
          </button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default Room;
