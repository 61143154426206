import { styled } from '@mui/material';
import { Box as SidebarBox } from '../Sidebar.style';

const PREFIX = 'DocumentSubmitted';

export const classes = {
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

export const Box = styled(SidebarBox)(({ theme }) => ({
  [`.${classes.title}`]: {
    ...theme.typography.headline_small,
    color: '#6531FF',
  },
  [`.${classes.content}`]: {
    ...theme.typography.body_medium,
  },
}));
