import { dedocoAxios, itextAxios } from './axios';
import { base64ToBlob, base64decode } from '../../utils/base64';
import axios from 'axios';
import { OPEN_API_V2_URL_PREFIX } from '../../redux/actions/documentfunctions';

export const getDownloadVideoUrl = async (compositionSid, secret) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get(`/businessprocess/recordings`, {
        params: {
          compositionSid,
          secret,
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
};

/**
 * get NDI session ID
 *
 * @param {string} businessProcessId
 * @param {string} signerId
 */
export const getNDISessId = (businessProcessId, signerId) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .post('/businessprocess/ndi', {
        businessProcessId: businessProcessId,
        signerId: signerId,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

/**
 * get NDI session id for open API
 *
 * @param {string} businessProcessId
 * @param {string} signerId
 */
export const getPublicNdiSessId = (businessProcessId, signerId) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .post('/public/business-processes/ndi', {
        businessProcessId: businessProcessId,
        signerId: signerId,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const publicSignDoc = (
  bpId,
  signerId,
  signatures,
  customTexts,
  initialDocHash,
  finalDoc,
  pageCount,
) => {
  const reqBody = {
    signatures,
    customTexts,
    initialDocHash,
    finalDoc,
    pageCount,
  };
  return new Promise((resolve, reject) => {
    dedocoAxios
      .post(`/public/business-processes/${bpId}/signers/${signerId}/sign`, reqBody)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

/**
 * get NDI sign_ref
 * to start/restart first leg
 *
 * @param {string} id ndi session id
 */
export const getSignRef = (id) => {
  if (!id) return;
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get('/businessprocess/ndi/sign-ref', { params: { ndi_session_id: id } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

/**
 * get NDI sign_ref to start/restart first leg for open API
 *
 * @param {string} id ndi session id
 */
export const getPublicSignRef = (id) => {
  if (!id) return;
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get('/public/business-processes/ndi/sign-ref', { params: { ndi_session_id: id } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

/**
 * query if user has scanned QR code for open API
 *
 * @param {string} id ndi session id
 */
export const getPublicChallengeCode = (id) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get('/public/business-processes/ndi/challenge-code', {
        params: { ndi_session_id: id },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

/**
 * query if user has consented to sign
 *
 * @param {string} id ndi session id
 */
export const getNDISignature = (id) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get('/businessprocess/ndi/signature', { params: { ndi_session_id: id } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

/**
 * query if user has consented to sign for open API
 *
 * @param {string} id ndi session id
 */
export const getPublicNdiSignature = (id) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get('/public/business-processes/ndi/signature', { params: { ndi_session_id: id } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

/**
 * convert pdf string to file
 *
 * @param {string} src
 * @param {string} fileName
 * @param {string} mimeType
 */
const srcToFile = async (src, fileName, mimeType) => {
  const res = await fetch(src);
  const buf = await res.arrayBuffer();
  return new File([buf], fileName, { type: mimeType });
};

const blobToFile = (blob, fileName, mimeType) => {
  return new File([blob], fileName, { type: mimeType });
};

/**
 * prepare pdf for deferred signing
 *
 * @param {string} base64
 * @param {string} user_cert
 * @param {string} x
 * @param {string} y
 * @param {string} pageNum
 * @param {string} size
 */
export const prepareITextPDF = async (base64, user_cert, x, y, pageNum, size, metadata = {}) => {
  let bodyFormData = new FormData();
  const res = await srcToFile(
    `data:application/pdf;base64,${base64}`,
    'test.pdf',
    'application/pdf',
  );
  bodyFormData.append('file', res);
  bodyFormData.append('cert', user_cert);
  bodyFormData.append('x', x);
  bodyFormData.append('y', y);
  if (pageNum) {
    bodyFormData.append('page', pageNum);
  }
  if (size) {
    bodyFormData.append('size', size);
  }

  bodyFormData.append('metadataForLogs', JSON.stringify(metadata));

  return new Promise((resolve, reject) => {
    itextAxios
      .post('/ndi/prepare', bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

//embed signature to PDF (Itext)
export const completeITextPDF = (id, cert, sig, metadata = {}) => {
  return new Promise((resolve, reject) => {
    itextAxios
      .patch(
        '/ndi/complete',
        {
          id: id,
          sig: sig,
          cert: cert,
          metadata: JSON.stringify(metadata),
        },
        {
          //this response type fixed the pdf damaged error
          responseType: 'blob',
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

export const addFieldsToPdf = async (
  pdfBase64,
  fieldDetails,
  isUsingEsig = true,
  metadata = {},
) => {
  let i = 0;
  // Send signing in batches to prevent request timeout when there are a lot of fields.
  const batchSize = 120;
  let pdfToSign = base64ToBlob(pdfBase64);
  while (i < fieldDetails.length) {
    const bodyFormData = new FormData();
    const fileObj = blobToFile(
      pdfToSign,
      'test.pdf', // File name doesn't matter
      'application/pdf',
    );

    const fieldsToSign = fieldDetails.slice(i, i + batchSize);

    bodyFormData.append('file', fileObj);
    bodyFormData.append('fields', JSON.stringify(fieldsToSign));
    bodyFormData.append('metadataForLogs', JSON.stringify(metadata));

    const isFinalBatch = i + batchSize >= fieldDetails.length;
    const path = isFinalBatch && isUsingEsig ? '/efields/sign' : '/efields/add';

    try {
      pdfToSign = (
        await itextAxios.post(path, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'blob',
        })
      ).data;
    } catch (err) {
      throw err.response;
    }
    i += batchSize;
  }

  return pdfToSign;
};

export const sendDocHash = (ndiSessionId, docHash) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .patch(
        '/businessprocess/ndi/doc-hash',
        {},
        {
          params: {
            ndi_session_id: ndiSessionId,
            doc_hash: docHash,
          },
        },
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

export const publicSendDocHash = (ndiSessionId, docHash) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .patch(
        '/public/business-processes/ndi/doc-hash',
        {},
        {
          params: {
            ndi_session_id: ndiSessionId,
            doc_hash: docHash,
          },
        },
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

export const publicUpdateDocHashNdi = async (
  businessProcessId,
  signerId,
  pdfBase64,
  signature,
  custom_texts,
) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .patch('/public/business-processes/pdf-hash', {
        business_process_id: businessProcessId,
        signer_id: signerId,
        pdf: pdfBase64,
        ndi_signature: signature,
        custom_texts: custom_texts,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

export const getPublicForm = async (b64Url) => {
  b64Url = b64Url.replace(/_/g, '/');
  b64Url = b64Url.replace(/-/g, '+');
  if (b64Url.length % 4 === 3) {
    b64Url += '=';
  } else if (b64Url.length % 4 === 2) {
    b64Url += '==';
  } else if (b64Url.length % 4 === 1) {
    b64Url += '===';
  }
  const decodedUrl = await base64decode(b64Url);

  return new Promise((resolve, reject) => {
    axios
      .get(decodedUrl)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

/**
 * Record signer's consent to terms and conditions
 *
 * @param {string} businessProcessId
 * @param {string} signerId
 */
export const recordSignerTermsAndConditionsConsent = async (
  businessProcessId,
  signerId,
  isOpenApiV2 = false,
) => {
  try {
    const response = await dedocoAxios.post(
      `${
        isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
      }/businessprocess/${businessProcessId}/signers/${signerId}/terms-and-conditions-consent`,
    );

    return response && response.status === 200;
  } catch (error) {
    console.log(error);

    return false;
  }
};

/**
 * Record observer's consent to terms and conditions
 *
 * @param {string} businessProcessId
 * @param {string} observerId
 */
export const recordObserverTermsAndConditionsConsent = async (
  businessProcessId,
  observerId,
  isOpenApiV2 = false,
) => {
  try {
    const response = await dedocoAxios.post(
      `${
        isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
      }/businessprocess/${businessProcessId}/observers/${observerId}/terms-and-conditions-consent`,
    );

    return response && response.status === 200;
  } catch (error) {
    console.log(error);

    return false;
  }
};

/**
 * Record approver's consent to terms and conditions
 *
 * @param {string} businessProcessId
 * @param {string} approverId
 */
export const recordApproverTermsAndConditionsConsent = async (
  businessProcessId,
  approverId,
  isOpenApiV2 = false,
) => {
  try {
    const response = await dedocoAxios.post(
      `${
        isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
      }/businessprocess/${businessProcessId}/approvers/${approverId}/terms-and-conditions-consent`,
    );

    return response && response.status === 200;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const getPublicWhiteLabelDetails = async (businessProcessId) => {
  try {
    const whiteLabelDetails = (
      await dedocoAxios.get(`/public/business-processes/signature/white-label-details`, {
        params: {
          business_process_id: businessProcessId,
        },
      })
    ).data;

    return whiteLabelDetails;
  } catch (error) {
    return {};
  }
};

/**
 * Record signer's consent to terms and conditions for OpenAPI
 *
 * @param {string} businessProcessId
 * @param {string} signerId
 */
export const recordPublicSignerTermsAndConditionsConsent = async (businessProcessId, signerId) => {
  try {
    const response = await dedocoAxios.post(
      `/public/business-processes/${businessProcessId}/signer/${signerId}/terms-and-conditions-consent`,
    );

    return response && response.status === 201;
  } catch (error) {
    console.log(error);

    return false;
  }
};

/**
 * Record observer's consent to terms and conditions for OpenAPI
 *
 * @param {string} businessProcessId
 * @param {string} observerId
 */
export const recordPublicObserverTermsAndConditionsConsent = async (
  businessProcessId,
  observerId,
) => {
  try {
    const response = await dedocoAxios.post(
      `/public/business-processes/${businessProcessId}/observer/${observerId}/terms-and-conditions-consent`,
    );

    return response && response.status === 201;
  } catch (error) {
    console.log(error);

    return false;
  }
};

/**
 * Record approver's consent to terms and conditions for OpenAPI
 *
 * @param {string} businessProcessId
 * @param {string} approverId
 */
export const recordPublicApproverTermsAndConditionsConsent = async (
  businessProcessId,
  approverId,
) => {
  try {
    const response = await dedocoAxios.post(
      `/public/business-processes/${businessProcessId}/approver/${approverId}/terms-and-conditions-consent`,
    );

    return response && response.status === 201;
  } catch (error) {
    console.log(error);

    return false;
  }
};

/**
 * Get company's logo
 *
 * @param {string} businessProcessId
 */
export const getCompanyLogo = async (businessProcessId) => {
  try {
    const response = await dedocoAxios.get(`/businessprocess/${businessProcessId}/company`);

    return response && response?.data;
  } catch (error) {
    return false;
  }
};

export const renewRecipientSession = async () => {
  const response = await dedocoAxios.patch('/auth/recipient-session');
  return response?.data;
};
