import { Drawer, styled } from '@mui/material';

const PREFIX = 'BottomModal';

export const classes = {
  contentContainer: `${PREFIX}-content-container`,
  content: `${PREFIX}-content`,
  closeIcon: `${PREFIX}-close-icon`,
  getHelpButton: `${PREFIX}-get-help`,
  groupButton: `${PREFIX}-group-button`,
  rejectButton: `${PREFIX}-reject-button`,
  slideUp: `${PREFIX}-slide-up`,
  verifyWrapper: `${PREFIX}-verify-wrapper`,
  fieldListWrapper: `${PREFIX}-required-fields-wrapper`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .MuiButton-root`]: {
    padding: 0,
    width: '100%',
    height: '32px',
    color: theme.palette.background.main,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    minWidth: '32px',
    borderRadius: '8px',
  },
}));

export const BottomSlideUp = styled(Drawer)(({ theme }) => ({
  [`& .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paperAnchorBottom`]: {
    height: '90%',
  },
  [`& .MuiPaper-root`]: {
    borderRadius: '16px 16px 0px 0px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [`& .${classes.contentContainer}`]: {
    backgroundColor: theme.palette.background.main,
    height: '100%',
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  [`& .${classes.content}`]: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'nowrap',
    height: '80%',
    overflow: 'auto',
  },
  [`& .${classes.slideUp}`]: {
    backgroundColor: theme.palette.lightGrey.light,
    width: '124px',
    height: '6px',
    borderRadius: '11px',
    margin: 'auto',
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.verifyWrapper}`]: {
    padding: theme.spacing(4, 0, 0),
  },
  [`& .${classes.fieldListWrapper}`]: {
    overflow: 'scroll',
  },
  [`& .${classes.groupButton}`]: {
    height: '20%',
    gap: theme.spacing(3),
    textAlign: 'center',
    justifyContent: 'center',
    [`& .MuiButton-root`]: {
      justifyContent: 'center',
    },
  },
  [`& .${classes.rejectButton}`]: {
    backgroundColor: theme.palette.supporting.main,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.supporting.dark}`,
    boxShadow: 'none',
  },
}));
