import { Box, styled, Grid } from '@mui/material';

const PREFIX = 'WebexContainer';

export const classes = {
  header: `${PREFIX}-header`,
  body: `${PREFIX}-body`,
  footer: `${PREFIX}-footer`,
};

export const Body = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  height: '100%',
  padding: theme.spacing(4),
}));

export const Root = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  minHeight: 0,
  [`& .${classes.header}`]: {
    paddingLeft: theme.spacing(4),
    height: '50px',
    backgroundImage:
      'linear-gradient(91.16deg, #2D1C51 0%, #542B8A 69.48%, #944283 85.49%, #CF564B 100%);',
  },
  [`& .${classes.body}`]: {
    overflow: 'auto',
    padding: theme.spacing(3, 3, 0, 3),
  },

  [`& .${classes.footer}`]: {
    borderRadius: '0px 0px 8px 8px',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 3, 3, 3),
    padding: theme.spacing(4),
    height: '68px',
  },
}));
