import { TWO_FA_METHOD_EMAIL, TWO_FA_METHOD_SMS } from './GlobalConstant';

export const getOtpTarget = (documentToSign) => {
  if (documentToSign?.data?.verify_method === TWO_FA_METHOD_SMS) {
    return documentToSign?.data?.recipient_phone;
  } else if (documentToSign?.data?.verify_method === TWO_FA_METHOD_EMAIL) {
    return documentToSign?.data?.recipient_email;
  } else {
    return null;
  }
};
