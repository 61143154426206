import { dedocoAxios } from './axios';

/**
 *
 * get form details for filler
 *
 * @param {string} formId
 */
export const getFormDetails = (formId) => {
  return new Promise((resolve, reject) => {
    dedocoAxios
      .get(`/forms/${formId}/filler`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((errData) => {
        reject(errData.response);
      });
  });
};

/**
 *
 * Initiate NDI signing session for dForms
 *
 * @param {string} formId
 */
export const initFormNdiSigning = async (formId) => {
  const { data } = await dedocoAxios.post(`/forms/${formId}/ndi`);
  return data;
};

/**
 *
 * Get NDI Signing data for dForms
 *
 * @param {string} ndiSessionId
 */
export const getFormNdiSigningData = async (formId, ndiSessionId) => {
  const { data } = await dedocoAxios.get(`/forms/${formId}/ndi`, {
    params: { ndi_session_id: ndiSessionId },
  });
  return data;
};

/**
 *
 * Send doc hash to backend to update NDI signing session details.
 *
 * @param {string} ndiSessionId
 */
export const sendFormDocHash = async (formId, ndiSessionId, docHash) => {
  await dedocoAxios.put(
    `/forms/${formId}/ndi`,
    {},
    {
      params: { ndi_session_id: ndiSessionId, doc_hash: docHash },
    },
  );
};
