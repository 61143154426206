import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGetRequiredDedocoSigning from '../../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { setSaveCurrentSignature } from '../../../../../redux/actions/signature';

const SaveSignatureCheckbox = ({ newDedocoSigning = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkboxValue, setCheckboxValue] = useState(false);

  const { documentToSign, signatureToSave } = useGetRequiredDedocoSigning(newDedocoSigning);
  const disableSaveSignature = documentToSign?.data?.savedSignatures?.length === 4;

  useEffect(() => {
    setCheckboxValue(signatureToSave.saveCurrentSignature);
  }, [signatureToSave.saveCurrentSignature]);

  const onCheckboxChange = (event) => {
    dispatch(setSaveCurrentSignature(event.target.checked));
    setCheckboxValue(event.target.checked);
  };

  return (
    <Grid>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checkboxValue} onChange={onCheckboxChange} />}
          label={t('SignatureRequestSaveSignatureCheckBox')}
          disabled={disableSaveSignature}
        />
      </FormGroup>
    </Grid>
  );
};

export default SaveSignatureCheckbox;
