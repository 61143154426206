import { styled } from '@mui/material';

const PREFIX = 'DedocoLayout';

export const classes = {
  navbar: `${PREFIX}-navbar`,
  navbarIcons: `${PREFIX}-navbar-icons`,
  main: `${PREFIX}-main`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  logo: `${PREFIX}-logo`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  actionIcon: `${PREFIX}-actionIcon`,
  helpIcon: `${PREFIX}-helpIcon`,
  innerIconWrapper: `${PREFIX}-innerIconWrapper`,
};

export const Root = styled('div')(({ theme, isWebexSigning }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.base,
  width: '100vw',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.navbarIcons}`]: {
    display: 'flex',
    gap: theme.spacing(6),
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2),
      flexDirection: 'row',
    },
  },

  [`& .${classes.navbar}`]: {
    margin: theme.spacing(6),
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: isWebexSigning ? 'none' : 'flex',
      flexDirection: 'row',
      margin: theme.spacing(5, 6),
      marginBottom: 0,
    },
  },

  [`& .${classes.main}`]: {
    boxSizing: 'border-box',
    margin: theme.spacing(6),
    flex: 1,
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5, 4, 4, 4),
      overflowY: 'auto',
    },
  },

  [`& .${classes.innerIconWrapper}`]: {
    width: '36px',
    height: '36px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    boxShadow: '0px 0px 2px 4px rgba(255, 255, 255, 0.04)',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.logo}`]: {
    width: '44px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '32px',
    },
  },

  [`& .${classes.iconWrapper}`]: {
    width: '44px',
    height: '44px',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '32px',
      height: '32px',
    },
  },

  [`& .${classes.actionIcon}`]: {
    width: '24px',
    filter: 'invert(1)',
  },

  [`& .${classes.helpIcon}`]: {
    width: '16px',
  },

  [`& .${classes.header}`]: {
    height: '64px',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.content}`]: {
    height: `calc(100% - 90px)`,
    padding: theme.spacing(2),
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      height: '100%',
      borderRadius: '8px',
    },
  },
}));
