import { Button, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { classes, Root } from './InvalidForm.style';

function InvalidForm({ formTitle, message }) {
  const { t } = useTranslation();
  const rerouteDedoco = useCallback(async (_e) => {
    window.location = 'https://dedoco.com/';
  }, []);

  return (
    <Root data-testid="invalid-form">
      <Typography variant="title_large">{formTitle}</Typography>
      <Typography variant="body_medium" className={classes.messageContainer}>
        {message}
      </Typography>
      <Button variant="contained" onClick={rerouteDedoco}>
        <Typography variant="label_large">{t('VisitDedocoHomepage')}</Typography>
      </Button>
    </Root>
  );
}

export default InvalidForm;
