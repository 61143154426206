export function getTimeBreakdown(timeInSeconds) {
  let timeBreakdown;

  timeBreakdown = {
    days: Math.floor(timeInSeconds / (60 * 60 * 24)),
    hours: Math.floor((timeInSeconds / (60 * 60)) % 24),
    minutes: Math.floor((timeInSeconds / 60) % 60),
    seconds: Math.floor(timeInSeconds % 60),
  };

  return timeBreakdown;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
