export const DOCUMENT_TO_SIGN_FAIL = 'DOCUMENT_TO_SIGN_FAIL';
export const GETTING_DOCUMENT_TO_SIGN = 'GETTING_DOCUMENT_TO_SIGN';
export const GOT_DOCUMENT_TO_SIGN = 'GOT_DOCUMENT_TO_SIGN';
export const SET_IS_OPEN_API_V2 = 'SET_IS_OPEN_API_V2';
export const MARK_DOCUMENT_AS_SIGNED = 'MARK_DOCUMENT_AS_SIGNED';
export const SAVEMYSIGNATURE = 'SAVEMYSIGNATURE';
export const ZOOMIN = 'ZOOMIN';
export const ZOOMOUT = 'ZOOMOUT';
export const SETFULLZOOM = 'SETFULLZOOM';
export const NEXTPAGE = 'NEXTPAGE';
export const PREVPAGE = 'PREVPAGE';
export const TOTALPAGES = 'TOTALPAGES';
export const SETPAGE = 'SETPAGE';
export const GOTIP = 'GOTIP';
export const FILEURL = 'FILEURL';
export const SETZOOM = 'SETZOOM';
export const VERIFY_SIGNING_OTP = 'VERIFY_SIGNING_OTP';
export const VERIFY_SIGNING_OTP_SUCCESS = 'VERIFY_SIGNING_OTP_SUCCESS';
export const VERIFY_SIGNING_OTP_FAILED = 'VERIFY_SIGNING_OTP_FAILED';
export const VERIFY_OBSERVER_OTP = 'VERIFY_OBSERVER_OTP';
export const VERIFY_OBSERVER_OTP_SUCCESS = 'VERIFY_OBSERVER_OTP_SUCCESS';
export const VERIFY_OBSERVER_OTP_FAILED = 'VERIFY_OBSERVER_OTP_FAILED';
export const VERIFY_APPROVER_OTP = 'VERIFY_APPROVER_OTP';
export const VERIFY_APPROVER_OTP_SUCCESS = 'VERIFY_APPROVER_OTP_SUCCESS';
export const VERIFY_APPROVER_OTP_FAILED = 'VERIFY_APPROVER_OTP_FAILED';
export const SEND_VERIFY_SIGNING_OTP = 'SEND_VERIFY_SIGNING_OTP';
export const SEND_VERIFY_SIGNING_OTP_SUCCESS = 'SEND_VERIFY_SIGNING_OTP_SUCCESS';
export const SEND_VERIFY_SIGNING_OTP_FAILED = 'SEND_VERIFY_SIGNING_OTP_FAILED';
export const SEND_VERIFY_OBSERVER_OTP = 'SEND_VERIFY_OBSERVER_OTP';
export const SEND_VERIFY_OBSERVER_OTP_SUCCESS = 'SEND_VERIFY_OBSERVER_OTP_SUCCESS';
export const SEND_VERIFY_OBSERVER_OTP_FAILED = 'SEND_VERIFY_OBSERVER_OTP_FAILED';
export const SEND_VERIFY_APPROVER_OTP = 'SEND_VERIFY_APPROVER_OTP';
export const SEND_VERIFY_APPROVER_OTP_SUCCESS = 'SEND_VERIFY_APPROVER_OTP_SUCCESS';
export const SEND_VERIFY_APPROVER_OTP_FAILED = 'SEND_VERIFY_APPROVER_OTP_FAILED';
export const VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS = 'VERIFY_SIGNER_SINGPASS_AUTH_CODE_SUCCESS';
export const VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS =
  'VERIFY_OBSERVER_SINGPASS_AUTH_CODE_SUCCESS';
export const VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS =
  'VERIFY_APPROVER_SINGPASS_AUTH_CODE_SUCCESS';
export const SAVE_SIGNATURE = 'SAVE_SIGNATURE';
export const SAVE_SIGNATURE_SUCCESS = 'SAVE_SIGNATURE_SUCCESS';
export const SAVE_SIGNATURE_FAILED = 'SAVE_SIGNATURE_FAILED';
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS';
export const APPROVE_DOCUMENT = 'APPROVE_DOCUMENT';
export const APPROVE_DOCUMENT_SUCCESS = 'APPROVE_DOCUMENT_SUCCESS';
export const APPROVE_DOCUMENT_FAILED = 'APPROVE_DOCUMENT_FAILED';
export const DECLINE_DOCUMENT = 'DECLINE_DOCUMENT';
export const DECLINE_DOCUMENT_SUCCESS = 'DECLINE_DOCUMENT_SUCCESS';
export const DECLINE_DOCUMENT_FAILED = 'DECLINE_DOCUMENT_FAILED';
export const START_MEETING = 'START_MEETING';
export const START_MEETING_SUCCESS = 'START_MEETING_SUCCESS';
export const START_MEETING_FAILED = 'START_MEETING_FAILED';
export const JOIN_MEETING = 'JOIN_MEETING';
export const JOIN_MEETING_SUCCESS = 'JOIN_MEETING_SUCCESS';
export const JOIN_MEETING_FAILED = 'JOIN_MEETING_FAILED';
export const LEAVE_MEETING = 'LEAVE_MEETING';
export const START_RECORDING = 'START_RECORDING';
export const START_RECORDING_SUCCESS = 'START_RECORDING_SUCCESS';
export const START_RECORDING_FAILED = 'START_RECORDING_FAILED';
export const STOP_RECORDING = 'STOP_RECORDING';
export const STOP_RECORDING_SUCCESS = 'STOP_RECORDING_SUCCESS';
export const STOP_RECORDING_FAILED = 'STOP_RECORDING_FAILED';
export const SAVE_PDF_STRING_BASE64 = 'SAVE_PDF_STRING_BASE64';
export const ACCEPT_TNC = 'ACCEPT_TNC';
export const SIGN_DOCUMENT = 'SIGN_DOCUMENT';
export const SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_FAILED = 'SIGN_DOCUMENT_FAIL';
export const VERIFY_DHC = 'VERIFY_DHC';
export const SAVE_CURRENT_SIGNATURE = 'SAVE_CURRENT_SIGNATURE';
export const UPDATE_SAVED_SIGNATURES = 'UPDATE_SAVED_SIGNATURES';
export const UPDATE_SIGNATURE_SECRET = 'UPDATE_SIGNATURE_SECRET';
export const GETTING_ORGANIZATION_LOGO = 'GETTING_ORGANIZATION_LOGO';
export const GOT_ORGANIZATION_LOGO = 'GOT_ORGANIZATION_LOGO';
export const GET_ORGANIZATION_LOGO_FAIL = 'GET_ORGANIZATION_LOGO_FAIL';
export const SET_IS_VALID_DOC_HASH = 'SET_IS_VALID_DOC_HASH';
