import { styled } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import BaseModal from '../BaseModal';

export const ApprovalModalRoot = styled(BaseModal)(() => ({
  zIndex: 9999,
  [`& .MuiDialog-paper`]: {
    height: '144px',
    width: '600px',
  },
}));

export const ApprovalModalTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.headline_small,
  color: theme.palette.darkGrey.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.title_large,
  },
}));

export const ApprovalModalActions = styled(DialogActions)(() => ({
  padding: 0,
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,
}));

export const ApprovalCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.darkGrey.dark,
  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));

export const ApproveButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label_medium,
  backgroundColor: theme.palette.primary.main,
  height: '44px',
  width: '92px',
  borderRadius: '8px',
  color: theme.palette.text.contrast.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.label_small,
    width: '76px',
    height: '36px',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label_medium,
  backgroundColor: theme.palette.supporting.light,
  height: '44px',
  width: '92px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.error.extraLight}`,
  color: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.supporting.main,
  },
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.label_small,
    width: '76px',
    height: '36px',
  },
}));
