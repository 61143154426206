import {
  WaitingForSignatureRoot,
  WaitingForSignatureImage,
  WaitingForSignatureDescription,
} from './WaitingForSignature.styles';
import transitionImage from '../../../../../assets/Singpass App illustration.svg';
import { useTranslation } from 'react-i18next';

export const WaitingForSignature = () => {
  const { t } = useTranslation();
  return (
    <WaitingForSignatureRoot>
      <WaitingForSignatureImage src={transitionImage} />
      <WaitingForSignatureDescription>
        {t('WaitingForNdiSignatureDescription')}
      </WaitingForSignatureDescription>
    </WaitingForSignatureRoot>
  );
};
