import { useState } from 'react';
import { getElementWithRetries } from '../utils/domUtil';
import { useTaskQueue } from './useTaskQueue';
import { PDFTRON_ASSOCIATED_VALUE_PREFIX } from '../constant/common';

function usePdfTron() {
  // state
  const [instance, setInstance] = useState();
  const [currentJumpAnnotIndex, setCurrentJumpAnnotIndex] = useState(-1);
  const [sortedAnnotationsList, setSortedAnnotationsList] = useState([]);
  const [checkedFields, setCheckedFields] = useState({});
  const loadDocumentTaskQueue = useTaskQueue();

  const injectCustomImplementation = (instance) => {
    const SignatureWidgetAnnotation = instance.Core.Annotations.SignatureWidgetAnnotation;

    if (!SignatureWidgetAnnotation.prototype.originalSetAssociatedSignatureAnnotation) {
      SignatureWidgetAnnotation.prototype.originalSetAssociatedSignatureAnnotation =
        SignatureWidgetAnnotation.prototype.setAssociatedSignatureAnnotation;

      SignatureWidgetAnnotation.prototype.setAssociatedSignatureAnnotation = function (
        associatedSignatureAnnotation,
      ) {
        // Assign ID that can link value annotations to widgets
        associatedSignatureAnnotation.Id = PDFTRON_ASSOCIATED_VALUE_PREFIX + this.Id;
        this.originalSetAssociatedSignatureAnnotation(associatedSignatureAnnotation);
      };
    }
  };

  let annotationManager, iframeWindow;
  if (instance) {
    ({ annotationManager } = instance.Core);
    ({ iframeWindow } = instance.UI);
    injectCustomImplementation(instance);
  }

  const jumpTo = (annotId) => {
    if (!instance) return;

    const annots = annotationManager.getAnnotationsList();
    const annot = annots.find((x) => x.Id === annotId);
    annot && annotationManager.jumpToAnnotation(annot);

    // These methods should be called after the jump since the widget's DOM element won't be rendered before the jump.
    removeAllHighlight();
    highlightAnnot(annotId);
  };

  const moveBack = () => {
    const prevIndex = currentJumpAnnotIndex - 1;
    if (prevIndex > -1) {
      handleMove(prevIndex);
    } else {
      const lastSignatureList = sortedAnnotationsList.length - 1;
      handleMove(lastSignatureList);
    }
  };

  const moveNext = (options, callback) => {
    if (typeof options === 'function') {
      callback = options;
    }

    const fieldIdsToExclude = options?.exclude || [];
    let startIndex = options?.startFrom
      ? sortedAnnotationsList.findIndex((annot) => annot.id === options?.startFrom)
      : -1;
    startIndex = startIndex === -1 ? currentJumpAnnotIndex : startIndex;
    const sortedAnnotListLength = sortedAnnotationsList.length;
    let nextIndex = startIndex;
    let nextAnnot;
    do {
      // If sortedAnnotationsList.length is 0, then the mod operation will return NaN.
      // When that happens, default to 0
      nextIndex = (nextIndex + 1) % sortedAnnotListLength || 0;
      nextAnnot = sortedAnnotationsList[nextIndex];
    } while (
      (nextAnnot.isFilled || fieldIdsToExclude.indexOf(nextAnnot.id) !== -1) &&
      nextIndex !== startIndex
    );

    if (startIndex !== nextIndex) {
      // The while loop found the next annotation to jump to
      handleMove(nextIndex);
      callback?.(nextAnnot);
    } else {
      // If startIndex === nextIndex,
      //   it means that the while loop cannot find the next annotation that should be focused on even after iterating through the entire list.
      // If this is the case, then do not call the move function.
      callback?.(undefined);
    }
  };

  const handleMove = (index) => {
    if (currentJumpAnnotIndex > -1) {
      highlightAnnot(sortedAnnotationsList[currentJumpAnnotIndex]?.id, false);
    }
    jumpTo(sortedAnnotationsList[index].id);
    setCurrentJumpAnnotIndex(index);
  };

  const highlightAnnot = async (annotId, enabled = true) => {
    if (!instance) return;
    const element = await getElementWithRetries(
      iframeWindow.document.getElementById.bind(iframeWindow.document, annotId),
    );
    const tagName = element.getElementsByClassName('widget-tag');
    if (!tagName?.length) return;
    tagName[0].style.display = enabled ? 'block' : 'none';
  };

  const removeAllHighlight = () => {
    const nameTags = [...iframeWindow.document.getElementsByClassName('widget-tag')];
    nameTags.forEach((tag) => {
      tag.style.display = 'none';
    });
  };

  const removeHotKeys = () => {
    if (!instance) return;
    const hotkeys = instance.UI.hotkeys;
    hotkeys.off(hotkeys.Keys.P);
    hotkeys.off(hotkeys.Keys.A);
    hotkeys.off(hotkeys.Keys.C);
    hotkeys.off(hotkeys.Keys.E);
    hotkeys.off(hotkeys.Keys.F);
    hotkeys.off(hotkeys.Keys.I);
    hotkeys.off(hotkeys.Keys.L);
    hotkeys.off(hotkeys.Keys.N);
    hotkeys.off(hotkeys.Keys.O);
    hotkeys.off(hotkeys.Keys.R);
    hotkeys.off(hotkeys.Keys.Q);
    hotkeys.off(hotkeys.Keys.T);
    hotkeys.off(hotkeys.Keys.S);
    hotkeys.off(hotkeys.Keys.G);
    hotkeys.off(hotkeys.Keys.H);
    hotkeys.off(hotkeys.Keys.K);
    hotkeys.off(hotkeys.Keys.U);
    hotkeys.off(hotkeys.Keys.CTRL_SHIFT_EQUAL);
    hotkeys.off(hotkeys.Keys.COMMAND_SHIFT_EQUAL);
    hotkeys.off(hotkeys.Keys.CTRL_SHIFT_MINUS);
    hotkeys.off(hotkeys.Keys.COMMAND_SHIFT_MINUS);
    hotkeys.off(hotkeys.Keys.CTRL_C);
    hotkeys.off(hotkeys.Keys.COMMAND_C);
    hotkeys.off(hotkeys.Keys.CTRL_V);
    hotkeys.off(hotkeys.Keys.COMMAND_V);
    hotkeys.off(hotkeys.Keys.CTRL_Z);
    hotkeys.off(hotkeys.Keys.COMMAND_Z);
    hotkeys.off(hotkeys.Keys.CTRL_Y);
    hotkeys.off(hotkeys.Keys.COMMAND_SHIFT_Z);
    hotkeys.off(hotkeys.Keys.CTRL_O);
    hotkeys.off(hotkeys.Keys.COMMAND_O);
    hotkeys.off(hotkeys.Keys.CTRL_P);
    hotkeys.off(hotkeys.Keys.COMMAND_P);
    hotkeys.off(hotkeys.Keys.SPACE);
    hotkeys.off(hotkeys.Keys.ESCAPE);
  };

  const loadDocumentToWebViewer = (blob, options, callback) => {
    // This function enforces removal of listener after use.
    // This reduces the chance of bugs due to forgotten listener and make it easier to manage when to run the listener.

    if (typeof options === 'function') {
      callback = options;
      options = {};
    }

    const _loadDocument = () =>
      new Promise((resolve) => {
        if (!instance) {
          resolve();
          return;
        }

        const { documentViewer } = instance.Core;
        const { loadDocument } = instance.UI;

        // To keep code simple, async/await is used for all callbacks.
        // If callback is not a promise, it will be implicitly convert to a resolved promise.
        // Take note: This means that the documentLoadHandler will always be added to the microtask queue and processed by the event loop when called.
        //  This may or may not cause any bugs if the implementation of PDFTron documentViewer event handling changes in the future.
        const documentLoadHandler = async () => {
          await callback?.();
          documentViewer.removeEventListener('documentLoaded', documentLoadHandler);

          // This will be after the document is loaded and after the listener callback has finished its execution.
          resolve();
        };

        documentViewer.addEventListener('documentLoaded', documentLoadHandler);

        loadDocument(blob);
      });

    // Add to task queue to make sure async callbacks execute synchronously to prevent duplicated operations.
    return options?.sync ? loadDocumentTaskQueue.executeTask(_loadDocument) : _loadDocument();
  };

  const pdfTronSettings = {
    licenseKey:
      'Dedoco Pte Ltd(dedoco.com):OEM:DeDoco::B+:AMS(20221228):24DC05B3075AD4F38B313BC9B263192F4E6F2F9F839D638D041D595C5020FE4282B6F5C7',
    path: '/webviewer/lib',
    fullAPI: true,
    css: '/assets/css/pdfWebViewer.css',
    disabledElements: [
      'ribbons',
      'toggleNotesButton',
      'searchButton',
      'rubberStampToolGroupButton',
      'stampToolGroupButton',
      'toolsHeader',
      'linkButton',
      'annotationCommentButton',
      'downloadButton',
      'leftPanelButton',
      'moreButton',
      'toolsOverlay',
      'contextMenuPopup',
      'annotationStyleEditButton',
      'textPopup',
      'header',
      'richTextPopup',
      'signatureModal',
      'pageNavOverlay',
      'annotationGroupButton',
      'annotationUngroupButton',
    ],
  };

  return {
    instance,
    setInstance,
    sortedAnnotationsList,
    setSortedAnnotationsList,
    checkedFields,
    setCheckedFields,
    jumpTo,
    moveBack,
    moveNext,
    loadDocumentToWebViewer,
    pdfTronSettings,
    removeHotKeys,
  };
}

export default usePdfTron;
