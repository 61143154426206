import Typography from '@mui/material/Typography';
import { classes, FieldsContainer, FieldListSectionRoot } from './FieldListSection.style';

export default function FieldListSection({ title, children }) {
  return (
    <FieldListSectionRoot>
      <Typography className={classes.fieldListSectionTitle} variant="title_large">
        {title}
      </Typography>
      <FieldsContainer>{children}</FieldsContainer>
    </FieldListSectionRoot>
  );
}
