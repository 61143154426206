export const autoScale = (length, zoom) => {
  if (window.innerWidth > 960) {
    length = length.split('px');
    return length[0] * (zoom - 0.3);
  } else {
    let screenRatiofactor = window.innerWidth / 960;
    length = length.split('px');
    return length[0] * (zoom - 0.3) * screenRatiofactor;
  }
};
