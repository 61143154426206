import styled from '@emotion/styled';

const PREFIX = 'Room';

export const classes = {
  remoteParticipants: `${PREFIX}-remoteParticipants`,
};

export const RoomRoot = styled('div')(() => ({
  width: 200,
  borderRadius: '8px',

  '& #dvideosign-share-screen video': {
    maxWidth: '100%',
    borderRadius: '8px',
  },

  [`& .${classes.remoteParticipants}`]: {
    display: 'flex',
    flexFlow: `row wrap`,
    justifyContent: `flex-start`,
  },
}));
