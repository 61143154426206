import { createContext, useContext } from 'react';
import usePdfTron from '../hooks/usePdfTron';

const PdfTronContext = createContext({});

const PdfTronProvider = ({ children }) => {
  const value = usePdfTron();

  return <PdfTronContext.Provider value={value}>{children}</PdfTronContext.Provider>;
};

function usePdfTronContext() {
  return useContext(PdfTronContext);
}

// eslint-disable-next-line react/display-name
const withPdfTron = (WrappedComponent) => (props) => {
  return (
    <PdfTronProvider>
      <WrappedComponent {...props} />
    </PdfTronProvider>
  );
};

export { PdfTronProvider, usePdfTronContext, withPdfTron };
