import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Role,
  StyledListItem,
  classes,
  StyledChip,
  StyledSelectButton,
} from './ChooseNextActorItem.style';
import { CypressIds } from '../../../../constant/cypressIds';

export default function ChooseNextActorItem({
  name,
  role,
  selected = false,
  onSelect = () => {},
  index,
}) {
  const { t } = useTranslation();
  const label = useMemo(() => {
    switch (role) {
      case Role.Signer:
        return t('Signer');
      case Role.Approver:
        return t('Approver');
      case Role.Observer:
        return t('Observer');
      default:
        return '';
    }
  }, [role, t]);
  return (
    <StyledListItem
      secondaryAction={<SelectButton onClick={onSelect} selected={selected} index={index} />}
    >
      <span className={classes.text}>
        {name} <StyledChip size="small" sx={{ marginLeft: '8px' }} role={role} label={label} />
      </span>
    </StyledListItem>
  );
}

function SelectButton({ selected = false, onClick, index }) {
  const { t } = useTranslation();
  return (
    <StyledSelectButton
      className={selected ? classes.selectedButton : ''}
      onClick={onClick}
      variant="contained"
      data-cypress={CypressIds.selectSignerButton(index)}
    >
      {selected ? t('NextActorSelected') : t('NextActorSelect')}
    </StyledSelectButton>
  );
}
