import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { CustomSelect, CustomOption } from './DropdownInput.style';
import { MediumInputBase } from '../MediumInputBase';

export const RequiredAsterick = () => {
  return <span style={{ color: 'red', fontSize: '15px', marginLeft: '5px' }}>*</span>;
};

function DropdownInputComponent(
  {
    inputId,
    onConfirmInput,
    onClose,
    options,
    title,
    description,
    placeholder,
    submitButtonText,
    isRequired,
  },
  ref,
) {
  const [selectedValue, setSelectedValue] = useState();
  const closeInput = useCallback(() => {
    onClose(inputId);
  }, [inputId, onClose]);

  const onOptionChange = useCallback((_, value) => {
    setSelectedValue(value);
  }, []);

  const confirmInput = useCallback(() => {
    onConfirmInput(inputId, selectedValue);
  }, [inputId, onConfirmInput, selectedValue]);

  useImperativeHandle(ref, () => ({ closeInput }), [closeInput]);

  return (
    <MediumInputBase
      onConfirmInput={confirmInput}
      onClose={closeInput}
      title={title}
      description={description}
      submitButtonText={submitButtonText}
      isRequired={isRequired}
    >
      <CustomSelect placeholder={placeholder} onChange={onOptionChange}>
        {options.map((option) => {
          return (
            <CustomOption key={option.value} value={option.value}>
              {option.name}
            </CustomOption>
          );
        })}
      </CustomSelect>
    </MediumInputBase>
  );
}

export const DropdownInput = forwardRef(DropdownInputComponent);
