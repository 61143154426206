import { Button, useTheme } from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNdiSigningContext } from '../../../contexts/ndiSigningContexts/useNdiSigningContext';
import { usePdfTronContext } from '../../../contexts/usePdfTronContext';
import { useDedocoSigning } from '../../../hooks/useDedocoSigning/useDedocoSigning.new';
import useRecipientRole from '../../../hooks/useRecipientRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { base64ToBlob } from '../../../utils/base64';
import { appendTagName, isCheckbox } from '../../../utils/checkboxWidget';
import { isActualDate, isCustomDate } from '../../../utils/dateWidget';
import {
  drawConfirmations,
  drawCustomTexts,
  drawDigitalSignatures,
  drawESignatures,
  getAnnotationContentAsImage,
  getAnnotationTextAsImage,
  getAnnotInfoFromFieldId,
  getFieldIdFromAssociatedAnnotId,
  getRotatedDimensions,
  getSortedAnnotationsList,
  normalStyles,
} from '../../../utils/pdftron';
import { shouldRenderSigningPad } from '../../../utils/renderCondition';
import { isInitials, isSignature } from '../../../utils/signatureWidget';
import { isAlphanumeric, isCustomText, isNumber, isUrl } from '../../../utils/textWidget';
import CustomTextBox from '../Sidebar/CustomTextBox';
import CustomDatePicker from '../Sidebar/DatePicker';
import SignatureSigning from '../Sidebar/SignatureSigning';
import SlideUpPanel from '../SlideUpPanel';
import NextButton from './NextButton';
import { ActionButtonWrapper, WebViewerRoot, WebViewerWrapper } from './PdfWebViewer.style';
import Toolbar from './Toolbar';
import { VideoMeetingType } from '../../../constant/videoMeeting';
import { CypressIds } from '../../../constant/cypressIds';
import { isEmail } from '../../../utils/emailWidget';
import EmailBox from '../Sidebar/EmailBox';
import { validateAlphanumeric, validateNumber, validateUrl } from '../../../utils/validation';
import { getFontSize } from '../../../utils/pdftronWidgetCommon';
import useDedocoVideoSigning from '../../../hooks/useDedocoVideoSigning';

// pdf web viewer without webex
const PdfWebViewer = ({
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
  setHasModified,
  noOfRemainingFields,
}) => {
  const viewer = useRef(null);
  const {
    instance,
    setInstance,
    loadDocumentToWebViewer,
    pdfTronSettings,
    sortedAnnotationsList,
    setSortedAnnotationsList,
    removeHotKeys,
  } = usePdfTronContext();
  const {
    documentToSign,
    currentField,
    setCurrentField,
    resetCurrentField,
    loadDocument,
    projectSigners,
    uid,
    updateSignerInputs,
    deleteSignerInputs,
    currentParticipant,
    isUsingUpdatedDocument,
  } = useDedocoSigning();

  const { getRoom } = useDedocoVideoSigning();
  const room = getRoom();
  const { isSigner, isObserver, isApprover } = useRecipientRole();
  const { t } = useTranslation();
  const signerIndex = projectSigners?.findIndex((signer) => signer.id);

  const windowSize = useWindowSize();
  const theme = useTheme();

  // state
  const [openSlideUpPanel, setOpenSlideUpPanel] = useState(false);

  // Used to pass latest currentField into useEffect that doesn't have currentField as dependency
  const currentFieldRef = useRef();
  currentFieldRef.current = currentField;

  const isFillingUsingSidebar = currentField.isFilling && currentField.isUsingSidebar;

  const isVideoSigning =
    documentToSign?.data?.is_videosign ||
    documentToSign?.data?.meeting?.meetingType === VideoMeetingType.TWILIO;

  let documentViewer, Annotations, annotationManager, AnnotationChangedActions, iframeWindow;

  if (instance) {
    ({ documentViewer, Annotations, annotationManager } = instance.Core);
    ({ iframeWindow } = instance.UI);
    AnnotationChangedActions = instance.Core.AnnotationManager.AnnotationChangedActions;
  }

  useEffect(() => {
    // embed webviewer into html and get its instance
    !instance &&
      WebViewer(pdfTronSettings, viewer.current)
        .then((newInstance) => {
          setInstance(newInstance);
        })
        .catch((err) => {
          console.log('WebViewer err', err);
        });

    return () => {
      setInstance(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For video sign, after the initial load, subsequent PDF reloads are triggered by change in documentToSign
  // Therefore, the boolean value of documentToSign.pdfBase64String is used as dependency to stop it from triggering the useEffect after the first load.
  // This will prevent unexpected reload behavior caused by 2 different changes.
  const pdfBase64StringDep = isVideoSigning
    ? !!documentToSign?.pdfBase64String
    : documentToSign?.pdfBase64String;

  useEffect(() => {
    if (!instance) {
      return;
    }

    // Load document to Web Viewer
    if (documentToSign?.pdfBase64String) {
      loadDocumentToWebViewer(base64ToBlob(documentToSign?.pdfBase64String), async () => {
        await drawAllSignerAnnotations();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, documentToSign?.document?.signers, pdfBase64StringDep]);

  useEffect(() => {
    if (documentToSign?.tncAccepted) {
      // load doc base64 string to redux and useDedocoSigning state
      loadDocument(room.dssKey);
    }
  }, [documentToSign?.tncAccepted, loadDocument, room.dssKey]);

  useEffect(() => {
    if (!instance) {
      return;
    }

    removeHotKeys();

    const updateFieldInput = (field) => {
      const widgetAnnot = field.widgets[0];
      const pageInfo = documentViewer.getDocument().getPageInfo(widgetAnnot.PageNumber);
      const annotMetadata = {
        page: widgetAnnot.PageNumber,
        height: widgetAnnot.Height,
        width: widgetAnnot.Width,
        x: widgetAnnot.X / pageInfo.width,
        y: widgetAnnot.Y / pageInfo.height,
        signerName: currentParticipant.name,
      };
      if (isCheckbox(field.name)) {
        annotMetadata.type = 'image';
        updateSignerInputs({ dataName: field.name, value: field.value, annotMetadata });
      }
    };

    documentViewer.addEventListener('annotationsLoaded', () => {
      const sortedAnnotationsList = getSortedAnnotationsList(annotationManager);
      const filteredAnnotationsList = sortedAnnotationsList.filter((annot) => {
        if (isSigner) {
          return getAnnotInfoFromFieldId(annot.id).signerIndex === signerIndex;
        }
        return true;
      });

      setSortedAnnotationsList(filteredAnnotationsList);

      const annotsList = annotationManager?.getAnnotationsList();
      annotsList?.forEach((annot) => {
        const annotInfo = getAnnotInfoFromFieldId(annot.Id);
        const signerInfo = projectSigners[annotInfo.signerIndex];
        if (annotInfo.type.includes('checkbox')) {
          appendTagName(annot.Id, signerInfo.name, signerInfo.color, iframeWindow);
        }
      });

      const fieldManager = annotationManager?.getFieldManager();
      fieldManager.forEachField((field) => {
        updateFieldInput(field);
      });
    });

    annotationManager.addEventListener('fieldChanged', (field) => {
      updateFieldInput(field);
    });

    const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
    signatureTool.addEventListener('locationSelected', (_, signatureWidget) => {
      setCurrentField(signatureWidget.Id, true);
    });

    annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
      // Handle widget annotations that are added during document load.
      if (action === AnnotationChangedActions.ADD) {
        annotations.forEach((annot) => {
          if (!(annot instanceof Annotations.WidgetAnnotation)) return;

          // getCustomStyles function is called when page zoom changes.
          Annotations.WidgetAnnotation.getCustomStyles = normalStyles.bind(
            null,
            iframeWindow,
            documentViewer,
            Annotations,
          );
        });
      }
    });

    annotationManager.addEventListener('annotationChanged', async (annotations, action) => {
      // Handle annotations that are added as signer inputs.
      if (action === AnnotationChangedActions.ADD || action === AnnotationChangedActions.MODIFY) {
        annotations.forEach(async (annot) => {
          const currentFieldId = currentFieldRef.current.fieldId;

          const fieldId = getFieldIdFromAssociatedAnnotId(annot.Id);
          if (!fieldId) {
            // Means that annot is not a signer input
            return;
          }

          let selectedId = '';
          if (Array.isArray(currentFieldId)) {
            const index = currentFieldId.indexOf(fieldId);

            if (index !== -1) {
              [selectedId] = currentFieldId.splice(index, 1);
            }

            // If currentFieldId is an array, it means that multiple annotations need to be drawn at one go.
            // Therefore, we should not clear currentField until all the annotations are drawn.
            if (currentFieldId.length === 0) {
              resetCurrentField();
            }
          } else {
            selectedId = currentFieldId;
            resetCurrentField();
          }

          annot.disableRotationControl();
          annot.NoResize = true;
          annot.NoMove = true;

          // Actual date is inserted during initial load, so there will be no selected widget.
          if ((selectedId && selectedId === fieldId) || isActualDate(fieldId)) {
            setHasModified(true);
            const doc = documentViewer.getDocument();
            const pageInfo = doc.getPageInfo(annot.PageNumber);
            const rotation = doc.getPageRotation(annot.PageNumber);
            const rotatedDimensions = getRotatedDimensions(rotation, {
              height: annot.Height,
              width: annot.Width,
            });
            const annotMetadata = {
              page: annot.PageNumber,
              width: rotatedDimensions.width,
              height: rotatedDimensions.height,
              x: annot.X / pageInfo.width,
              y: annot.Y / pageInfo.height,
              signerName: currentParticipant.name,
            };
            if (annot.Subject === 'Stamp') {
              // Call getAnnotationContentAsImage
              // to draw the image to fit object's box
              const imageData = await getAnnotationContentAsImage(annot);
              annotMetadata.type = 'image';
              updateSignerInputs({
                dataName: fieldId,
                value: imageData,
                annotMetadata,
              });
            }

            if (annot.Subject === 'Free Text') {
              let contents;
              // If selected annot is signature, get contents as data urls,
              // otherwise get the contents as text.
              if (isSignature(selectedId) || isInitials(selectedId)) {
                contents = await getAnnotationTextAsImage(annot, documentViewer);
                annotMetadata.type = 'image';
              } else {
                contents = annot.getContents();
                annotMetadata.type = 'text';
                annotMetadata.fontSize = parseFloat(getFontSize(contents.length));
              }
              updateSignerInputs({ dataName: fieldId, value: contents, annotMetadata });
            }
          }
        });
      }
      if (action === AnnotationChangedActions.DELETE) {
        annotations.forEach((annot) =>
          deleteSignerInputs(getFieldIdFromAssociatedAnnotId(annot.Id)),
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(() => {
    if (currentField.isFilling) setOpenSlideUpPanel(false);
  }, [currentField.isFilling]);

  const drawAllSignerAnnotations = () => {
    const allSigners = documentToSign.data.business_process.signers;
    return Promise.all(
      allSigners.map((signer, index) => {
        return drawSignerAnnotations(signer, index);
      }),
    );
  };

  const { initiateNdiSigningRef } = useNdiSigningContext();

  const drawSignerAnnotations = (signer, signerIndex) => {
    const {
      id,
      custom_texts,
      esignatures,
      digi_signatures,
      confirmations,
      name,
      signer_name,
      color,
      email,
      signer_email,
      has_signed,
    } = signer;

    let isCurrentSigner = false;
    if (id === uid) {
      isCurrentSigner = true;
    }

    const signerDetails = {
      signerIndex,
      name: name || signer_name,
      email: email || signer_email,
      color,
      hasSigned: has_signed,
      isCurrentSigner,
    };

    drawConfirmations(
      confirmations,
      signerDetails,
      isObserver,
      isApprover,
      isUsingUpdatedDocument,
      instance,
    );

    return Promise.all([
      drawESignatures(esignatures, signerDetails, isUsingUpdatedDocument, instance),
      drawCustomTexts(
        custom_texts,
        signerDetails,
        isObserver,
        isApprover,
        isUsingUpdatedDocument,
        instance,
      ),
      drawDigitalSignatures(digi_signatures, signerDetails, initiateNdiSigningRef, instance),
    ]);
  };

  // Handle on signing object click
  const renderFillingComponent = useCallback(() => {
    if (!isFillingUsingSidebar || windowSize.width > 600) return <></>;
    return (
      <div
        style={{
          display: isFillingUsingSidebar ? 'block' : 'none',
          height: '100%',
          padding: theme.spacing(4),
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {shouldRenderSigningPad(currentField.fieldId) && <SignatureSigning />}
        {isCustomText(currentField.fieldId) && <CustomTextBox />}
        {isUrl(currentField.fieldId) && (
          <CustomTextBox validationFunc={validateUrl} errorMessage={t('EnterValidUrl')} />
        )}
        {isAlphanumeric(currentField.fieldId) && (
          <CustomTextBox
            validationFunc={validateAlphanumeric}
            errorMessage={t('NoSpecialCharacters')}
          />
        )}
        {isNumber(currentField.fieldId) && (
          <CustomTextBox validationFunc={validateNumber} errorMessage={t('EnterValidNumber')} />
        )}
        {isEmail(currentField.fieldId) && <EmailBox />}
        {isCustomDate(currentField.fieldId) && <CustomDatePicker />}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFillingUsingSidebar, currentField]);

  const handleClick = () => {
    setOpenSlideUpPanel(true);
  };

  const toHideWebViewer = useCallback(() => {
    const isMobileSize = windowSize.width <= 600;
    return isMobileSize && isFillingUsingSidebar;
  }, [isFillingUsingSidebar, windowSize.width]);

  const styles = useMemo(() => {
    if (toHideWebViewer()) {
      return { display: 'none' };
    }
    return { flex: 1 };
  }, [toHideWebViewer]);

  const buttonText = useMemo(() => {
    // if (isWebexSigning && isSigner) {
    //   return t('SaveAndConfirm');
    // }
    if (isSigner) return t('MobileSignDocumentButton');
    if (isObserver) return t('MobileMoreActionsButton');
    if (isApprover) return t('MobileApproveDocumentButton');
  }, [isApprover, isObserver, isSigner, t]);

  return (
    <>
      <WebViewerRoot sx={styles}>
        <Toolbar documentViewer={documentViewer} fileName={documentToSign?.fileName} />
        <WebViewerWrapper ref={viewer} data-cypress={CypressIds.pdfWebViewer}></WebViewerWrapper>
        <ActionButtonWrapper>
          <Button variant="contained" color="primary" fullWidth onClick={handleClick}>
            {buttonText}
          </Button>
        </ActionButtonWrapper>
        <SlideUpPanel
          open={openSlideUpPanel}
          setOpen={setOpenSlideUpPanel}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
          noOfRemainingFields={noOfRemainingFields}
        />
        {sortedAnnotationsList?.length > 1 && <NextButton />}
      </WebViewerRoot>
      {renderFillingComponent()}
    </>
  );
};

export default PdfWebViewer;
