import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SIGNATURE_TYPE } from '../../../../constant/common';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import { updateSavedSignature as oldUpdateSavedSignature } from '../../../../redux/actions/documentfunctions';
import { updateSavedSignature as newUpdateSavedSignature } from '../../../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import {
  deleteSignature,
  saveSignature,
  setSaveCurrentSignature,
} from '../../../../redux/actions/signature';
import { convertTextToImage } from '../../../../utils/image';
import { setContentToSignatureAnnot } from '../../../../utils/pdftron';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import { isSignAllSignature, isSignature } from '../../../../utils/signatureWidget';
import SidebarWrapperContainer from '../SidebarWrapperContainer';
import { PEN_COLOR_PALETTE, SIGNATURE_TAB_TITLE } from './constants';
import SavedSignature from './SavedSignature';
import {
  CustomTab,
  CustomTabPanel,
  CustomTabs,
  DeleteSignatureButton,
} from './SignatureSigning.styles';
import SigningPad from './SigningPad';
import UploadSignature from './UploadSignature';
import { getSigningConfigs } from './utils';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { CypressIds } from '../../../../constant/cypressIds';

const SignatureSigning = ({ styles, newDedocoSigning = false }) => {
  // other hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let updateSavedSignature;
  const { currentField, documentToSign, signatureToSave } =
    useGetRequiredDedocoSigning(newDedocoSigning);

  if (newDedocoSigning) {
    updateSavedSignature = newUpdateSavedSignature;
  } else {
    updateSavedSignature = oldUpdateSavedSignature;
  }
  const { instance } = usePdfTronContext();

  // state
  const [inputDetails, setInputDetails] = useState('');
  const [textColor, setTextColor] = useState(PEN_COLOR_PALETTE[0]);
  const [tab, setTab] = useState(0);

  const currentFieldId = currentField.fieldId;
  const disableButton = Boolean(!inputDetails);

  // effect
  useEffect(() => {
    if (currentFieldId) {
      setInputDetails(null);
    }
    setTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldId]);

  // variable
  let Annotations, annotationManager;
  let buttonText;
  if (instance) {
    ({ Annotations, annotationManager } = instance.Core);
  }
  const { types, saveSignatureOption } = getSigningConfigs(currentFieldId);
  switch (types[tab]?.value) {
    case SIGNATURE_TYPE.SAVED:
      buttonText = t('SignatureSigningSignatureSavedButton');
      break;
    case SIGNATURE_TYPE.UPLOAD:
      buttonText = t('SignatureSigningSignatureUploadButton');
      break;
    case SIGNATURE_TYPE.TYPE:
      buttonText = t('SignatureSigningSignatureDrawButton');
      break;
    case SIGNATURE_TYPE.DRAW:
    default:
      buttonText = t('SignatureSigningSignatureDrawButton');
      break;
  }

  // event handler
  const handleValueChange = useCallback((value) => {
    setInputDetails(value);
  }, []);

  const handleTabChange = useCallback((_, newValue) => {
    setTab(newValue);
  }, []);

  const handleOnClick = useCallback(async () => {
    if (signatureToSave.saveCurrentSignature) {
      let signature = inputDetails?.value;
      if (inputDetails?.type !== 'image') {
        const fieldId = Array.isArray(currentFieldId) ? currentFieldId[0] : currentFieldId;
        const selectedSignature = annotationManager
          .getAnnotationsList()
          .find(
            (annot) =>
              annot instanceof Annotations.SignatureWidgetAnnotation && annot.Id === fieldId,
          );
        signature = await convertTextToImage(inputDetails?.value, selectedSignature);
      }
      let newSignatureList = documentToSign.data.savedSignatures;
      if (newSignatureList.length === 4) {
        newSignatureList.shift();
      }
      newSignatureList = [...newSignatureList, signature];
      await dispatch(
        saveSignature({
          signature: signature,
          email: documentToSign?.data?.recipientEmail,
          savingSignatureSecret: documentToSign?.data?.savingSignatureSecret,
        }),
      ).then(() => {
        dispatch(setSaveCurrentSignature(false));
        dispatch(updateSavedSignature(newSignatureList));
      });
    }
    // handle sign all
    if (Array.isArray(currentFieldId)) {
      const annots = annotationManager?.getAnnotationsList();
      await Promise.all(
        annots.map(async (annot) => {
          if (
            annot instanceof Annotations.SignatureWidgetAnnotation &&
            annot.Id.startsWith('esignature')
          ) {
            if (inputDetails?.type === 'text') {
              setSignatureWidgetText(inputDetails?.value, annot.Id, instance, {
                textColor,
              });
            } else if (inputDetails?.type === 'image') {
              await setContentToSignatureAnnot(inputDetails?.value, annot.Id, instance);
            }
          }
        }),
      );
    } else {
      if (inputDetails?.type === 'text') {
        setSignatureWidgetText(inputDetails?.value, currentFieldId, instance, { textColor });
      } else if (inputDetails?.type === 'image') {
        await setContentToSignatureAnnot(inputDetails?.value, currentFieldId, instance);
      }
    }
  }, [
    signatureToSave.saveCurrentSignature,
    currentFieldId,
    inputDetails,
    documentToSign.data.savedSignatures,
    documentToSign.data?.recipientEmail,
    documentToSign.data?.savingSignatureSecret,
    dispatch,
    annotationManager,
    Annotations,
    updateSavedSignature,
    textColor,
    instance,
  ]);

  const handleDeleteSignature = useCallback(() => {
    dispatch(
      deleteSignature({
        email: documentToSign?.data?.recipientEmail,
        signature: inputDetails?.value,
        savingSignatureSecret: documentToSign?.data?.savingSignatureSecret,
      }),
    );
  }, [dispatch, inputDetails?.value, documentToSign]);

  // render
  const shouldRenderSavedSignatures = () => {
    const isSignatureField = isSignature(currentFieldId) || isSignAllSignature(currentFieldId);
    return types[tab]?.value !== SIGNATURE_TYPE.SAVED && isSignatureField;
  };

  const renderContent = () => (
    <Stack gap={4} width="100%">
      <Stack width="100%" alignItems="center">
        <Box width="100%">
          <CustomTabs value={tab} onChange={handleTabChange} aria-label="signing method tabs">
            {types.map((type) => (
              <CustomTab
                key={`tab-${type.value}`}
                label={SIGNATURE_TAB_TITLE[type.value.toUpperCase()]}
                aria-controls={`sign-method-${type.value}`}
              />
            ))}
          </CustomTabs>
        </Box>
        {types.map((type, index) => (
          <CustomTabPanel
            sx={{
              borderTopLeftRadius: tab === 0 ? '0px' : '8px',
              borderTopRightRadius: tab === index ? '0' : '8px',
            }}
            key={`sign-method-${type.value}`}
            value={tab}
            index={index}
          >
            {type.value === SIGNATURE_TYPE.DRAW && (
              <SigningPad
                newDedocoSigning={newDedocoSigning}
                refreshValue={currentFieldId}
                handleValueChange={handleValueChange}
                textColor={textColor}
                setTextColor={setTextColor}
                type={type.value}
                enableSaveSignatureCheckbox={saveSignatureOption}
              />
            )}
            {type.value === SIGNATURE_TYPE.UPLOAD && (
              <UploadSignature
                newDedocoSigning={newDedocoSigning}
                handleValueChange={handleValueChange}
                enableSaveSignatureCheckbox={saveSignatureOption}
              />
            )}
            {type.value === SIGNATURE_TYPE.TYPE && (
              <SigningPad
                newDedocoSigning={newDedocoSigning}
                value={inputDetails?.value}
                handleValueChange={handleValueChange}
                type={type.value}
                textColor={textColor}
                setTextColor={setTextColor}
                enableSaveSignatureCheckbox={saveSignatureOption}
              />
            )}
            {type.value === SIGNATURE_TYPE.SAVED && (
              <SavedSignature
                newDedocoSigning={newDedocoSigning}
                p={4}
                handleValueChange={handleValueChange}
              />
            )}
          </CustomTabPanel>
        ))}
      </Stack>
      {shouldRenderSavedSignatures() && (
        <Stack gap={4}>
          <Typography mt={1.5} variant="title_large" color="text.primary">
            {t('SignatureSigningSignatureSavedTitle')}
          </Typography>
          <SavedSignature
            newDedocoSigning={newDedocoSigning}
            handleValueChange={handleValueChange}
            isNotMainTab
          />
        </Stack>
      )}
    </Stack>
  );

  const getFooter = () => {
    if (types[tab]?.value === SIGNATURE_TYPE.SAVED) {
      return (
        <>
          <Button
            data-testid="sidebar-save-input-button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleOnClick}
            disabled={disableButton}
          >
            {buttonText}
          </Button>
          <DeleteSignatureButton
            data-testid="sidebar-delete-save-signature-button"
            onClick={handleDeleteSignature}
            disabled={disableButton}
            fullWidth
          >
            {t('SignatureSigningDeleteSavedSignatureButton')}
          </DeleteSignatureButton>
        </>
      );
    } else {
      return (
        <Button
          data-testid="sidebar-save-input-button"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleOnClick}
          disabled={disableButton}
          data-cypress={CypressIds.saveDrawSignatureButton}
        >
          {buttonText}
        </Button>
      );
    }
  };

  return (
    <SidebarWrapperContainer
      newDedocoSigning={newDedocoSigning}
      styles={styles}
      content={renderContent()}
      footer={getFooter()}
    />
  );
};

export default SignatureSigning;
