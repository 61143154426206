import { Root, classes, OtpContainer } from '../../Sidebar/OTPCheck/OTPCheck.style';
import { useEffect, useMemo, useState, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import TermsAndCondition from '../TermsAndCondition';
import useOTP from '../../../../hooks/useOTP/useOTP.new';
import { useTnC } from '../../../../hooks/useTnC/useTnC.new';
import { useTheme } from '@emotion/react';
import { useTimer } from 'react-timer-hook';
import { LoadingButton } from '@mui/lab';

const OTP_LENGTH = 7;

function OTPCheck({ otpTarget }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { sendOTP, verifyOTP, isLoading } = useOTP();
  const { TncSnackbar, handleSubmitTnC } = useTnC();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [currentOtp, setCurrentOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [availableVerifyAttempt, setAvailableVerifyAttempt] = useState(5);

  const isProceedButtonDisabled = useMemo(() => {
    return currentOtp.length < OTP_LENGTH;
  }, [currentOtp.length]);

  const getExpiryTimestamp = useCallback((s = 60) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + s);
    return time;
  }, []);

  const { seconds, minutes, restart, resume } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    autoStart: false,
    onExpire: () => {
      setResendDisabled(false);
    },
  });

  const handleSendOtp = useCallback(() => {
    if (availableVerifyAttempt > 0) {
      sendOTP()
        .then(() => {
          resume();
          setResendDisabled(true);
        })
        .catch((err) => {
          if (err?.request?.status === 429) {
            setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
            setAvailableVerifyAttempt(0);
          } else {
            setErrorMessage(t('OTPVerifyErrorOTPDidNotMatch'));
          }
        });
      setAvailableVerifyAttempt((availableVerifyAttempt) => availableVerifyAttempt - 1);
    }
  }, [availableVerifyAttempt, resume, sendOTP, t]);

  const handleResend = useCallback(() => {
    restart(getExpiryTimestamp());
    setResendDisabled(true);
    handleSendOtp();
  }, [getExpiryTimestamp, handleSendOtp, restart]);

  const handleProceed = useCallback(() => {
    verifyOTP(currentOtp)
      .then(({ business_process }) => {
        handleSubmitTnC(business_process.id);
        setErrorMessage('');
        setAvailableVerifyAttempt(5);
      })
      .catch((err) => {
        if (err?.request?.status === 429) {
          setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
          setAvailableVerifyAttempt(0);
        } else {
          setErrorMessage(t('OTPVerifyErrorOTPDidNotMatch'));
          setAvailableVerifyAttempt(availableVerifyAttempt - 1);
        }
      });
  }, [availableVerifyAttempt, currentOtp, handleSubmitTnC, t, verifyOTP]);

  useEffect(() => {
    handleSendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!otpTarget) return null;

  return (
    <OtpContainer>
      <TncSnackbar />
      <Root>
        <Typography variant="label_medium" align="left" gutterBottom>
          {t('OTPVerifyTitle')}
        </Typography>
        <Typography variant="body_medium" align="left" gutterBottom>
          {t('InputOtpText')}
          <b>{otpTarget}</b>
        </Typography>
        <OtpInput
          value={currentOtp}
          onChange={(otp) => {
            setCurrentOtp(otp);
          }}
          numInputs={OTP_LENGTH}
          inputStyle={classes.otpInput}
          separator={<span>&nbsp;&nbsp;</span>}
        />
        <div className={classes.childContainer}>
          <Typography
            className={`${classes.resendButton} ${resendDisabled && classes.disableResend}`}
            variant="body_medium"
            align="left"
            gutterBottom
            onClick={handleResend}
          >
            {t('OTPVerifyResendCode')}
          </Typography>
          {resendDisabled && (
            <Typography
              className={classes.timeCountDown}
              variant="body_medium"
              align="left"
              gutterBottom
            >
              {t('OTPVerifyResendCounterText')} {seconds + minutes * 60}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              className={classes.invalidOtp}
              variant="body_medium"
              align="left"
              gutterBottom
            >
              {errorMessage}
            </Typography>
          )}
        </div>
      </Root>
      <TermsAndCondition noTitle={true} hideButton={true} fullWidth={false} isLoading={isLoading} />
      <LoadingButton
        variant="contained"
        onClick={handleProceed}
        loading={isLoading}
        disabled={isProceedButtonDisabled}
        sx={{
          width: `calc(100% - 64px)`,
          marginBottom: `${theme.spacing(8)}`,
        }}
      >
        {t('ProceedButton')}
      </LoadingButton>
    </OtpContainer>
  );
}

export default OTPCheck;
