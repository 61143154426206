import styled from '@mui/material/styles/styled';

const PREFIX = 'FieldListSection';

export const classes = {
  fieldListSectionTitle: `${PREFIX}-fieldListSectionTitle`,
};

export const FieldListSectionRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.fieldListSectionTitle}`]: {
    color: theme.palette.darkGrey.main,
    margin: theme.spacing(4, 0),
    textAlign: 'left',
  },
}));

export const FieldsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
  gap: '16px',
}));
