import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Video from 'twilio-video';
import {
  getTwilioSelector,
  joinMeeting as _joinMeeting,
  removeRoom,
  setConnecting,
  setIsAudioMuted,
  setIsVideoMuted,
  setRoom,
  setScreenTrack,
  startRecording as _startRecording,
  stopRecording as _stopRecording,
  removeScreenTrack,
} from '../../redux/slices/twilioSlice/twilioSlice';
import Room from './Room';
import { hasCamera, hasMicrophone } from '../../utils/userMedia';
import retry, { TIMEOUT_ERROR_ERROR } from '../../utils/retry';

/**
 * Custom hook to encapsulate logic for using Twilio Programmable Video
  https://www.twilio.com/docs/video/javascript.

  Notes:
    - renderRoom has to be called in the component where the video is to be rendered.
    - the UI of room and participant can be modified in the Room and Participant components.
 */
export default function useTwilio() {
  const dispatch = useDispatch();
  const { room, isAudioMuted, isVideoMuted, screenTrack, isConnecting } =
    useSelector(getTwilioSelector);
  const { uid } = useParams();
  const [isCameraFound, setIsCameraFound] = useState(true);
  const [isMicrophoneFound, setIsMicrophoneFound] = useState(true);

  useEffect(() => {
    const checkMedia = async () => {
      return {
        cameraFound: await hasCamera(),
        microphoneFound: await hasMicrophone(),
      };
    };

    checkMedia()
      .then((results) => {
        const { cameraFound, microphoneFound } = results;

        setIsCameraFound(cameraFound);
        setIsMicrophoneFound(microphoneFound);
      })
      .catch(() => {
        // Do nothing.
      });
  }, []);

  const startOrJoinTwilioMeeting = useCallback(
    async (roomToken, documentId) => {
      if (!roomToken || !documentId) return;

      const createLocalTracks = async () => {
        try {
          const createLocalTracksOptions = {
            video: {
              width: 640,
              height: 480,
              name: `participant-video-${uid}`,
            },
            audio: {
              name: `participant-audio-${uid}`,
            },
          };

          if (!isCameraFound) {
            createLocalTracksOptions.video = false;
          }

          if (!isMicrophoneFound) {
            createLocalTracksOptions.audio = false;
          }

          const tracks = await Video.createLocalTracks(createLocalTracksOptions);
          if (tracks) {
            tracks.some((track) => {
              if (track.kind === 'video') {
                const videoProcessor = {
                  processFrame: async (inputFrame, outputFrame) => {
                    const ctx = outputFrame.getContext('2d');
                    ctx.save();
                    ctx.scale(-1, 1);
                    ctx.drawImage(inputFrame, 0, 0, -inputFrame.width, inputFrame.height);
                    ctx.restore();
                    // Watermark
                    ctx.font = '32px Arial';
                    const text = 'Powered by Dedoco';
                    const sz = ctx.measureText(text);
                    ctx.fillStyle = 'black';
                    ctx.fillText(
                      text,
                      2 + outputFrame.width - sz.width - 10,
                      outputFrame.height - 10,
                    );
                    ctx.fillStyle = 'white';
                    ctx.fillText(
                      text,
                      outputFrame.width - sz.width - 10,
                      outputFrame.height - 10 - 2,
                    );
                  },
                };
                track.addProcessor(videoProcessor);
                return true;
              } else return false;
            });
          }
          return tracks;
        } catch (error) {
          // Return empty array, equivalent to creating local tracks without video and audio.
          return [];
        }
      };

      try {
        dispatch(setConnecting(true));
        // retry connect when timeout, if retry 3 times than reload the screen
        // if the first connection established, rest connection will throw duplicate connect error
        // will not affect the existing connection
        const room = await retry(
          async () =>
            Video.connect(roomToken, {
              name: documentId,
              tracks: await createLocalTracks(),
              dominantSpeaker: true,
            }),
          3,
          5000,
        );
        dispatch(setRoom(room));
      } catch (err) {
        console.error('connect twilio err', err.message);
        if (err === TIMEOUT_ERROR_ERROR) {
          // when got timeout error, reload the screen
          location.reload();
        }
      } finally {
        dispatch(setConnecting(false));
      }
    },
    [dispatch, uid, isCameraFound, isMicrophoneFound],
  );

  const leaveTwilioMeeting = useCallback(() => {
    if (room) {
      room.disconnect();
      dispatch(removeRoom());
    }
  }, [dispatch, room]);

  const joinMeeting = useCallback(
    (documentId, name, email, userId) => {
      dispatch(
        _joinMeeting({
          document_id: documentId,
          name,
          email,
          userId,
        }),
      );
    },
    [dispatch],
  );

  const toggleAudioMute = () => {
    dispatch(setIsAudioMuted(!isAudioMuted));
  };

  const toggleVideoMute = () => {
    dispatch(setIsVideoMuted(!isVideoMuted));
  };

  const shareScreen = useCallback(async () => {
    return navigator.mediaDevices
      .getDisplayMedia()
      .then((stream) => {
        const newScreenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
          name: `participant-screen-${uid}`,
        });
        newScreenTrack.participantId = room?.localParticipant?.sid;
        dispatch(setScreenTrack(newScreenTrack));
        room?.localParticipant?.publishTrack(newScreenTrack);
      })
      .catch(() => {
        alert('Could not share the screen.');
      });
  }, [dispatch, room?.localParticipant, uid]);

  const stopShareScreen = useCallback(async () => {
    const screen = screenTrack[uid];
    if (!screen) return;
    screen.stop();
    dispatch(removeScreenTrack(uid));
    const localParticipant = room?.localParticipant;
    const screenPublication = localParticipant.unpublishTrack(screen);
    screenPublication.track.stop();
  }, [dispatch, room?.localParticipant, screenTrack, uid]);

  const renderRoom = useCallback(
    ({ attendees = {} } = {}) => {
      return (
        <Room
          room={room}
          attendees={attendees}
          screenTrack={screenTrack}
          isAudioMuted={isAudioMuted}
          isVideoMuted={isVideoMuted}
        />
      );
    },
    [room, isAudioMuted, isVideoMuted, screenTrack],
  );

  const startRecording = useCallback(
    ({ documentId }) => {
      return dispatch(_startRecording({ documentId })).unwrap();
    },
    [dispatch],
  );

  const stopRecording = useCallback(
    ({ documentId }) => {
      return dispatch(_stopRecording({ documentId })).unwrap();
    },
    [dispatch],
  );

  return {
    startOrJoinTwilioMeeting,
    joinMeeting,
    renderRoom,
    leaveTwilioMeeting,
    toggleAudioMute,
    toggleVideoMute,
    shareScreen,
    isConnecting,
    startRecording,
    stopRecording,
    stopShareScreen,
  };
}
