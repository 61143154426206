import styled from '@emotion/styled';

const PREFIX = 'PdfTronToolbar';

export const classes = {
  fileNameContainer: `${PREFIX}-fileNameContainer`,
  pageNumber: `${PREFIX}-pageNumber`,
  zoomContainer: `${PREFIX}-zoomContainer`,
  zoomIconContainer: `${PREFIX}-zoomIconContainer`,
  icon: `${PREFIX}-icon`,
  leftBorderRadius: `${PREFIX}-leftBorderRadius`,
  rightBorderRadius: `${PREFIX}-rightBorderRadius`,
  zoomPercentage: `${PREFIX}-zoomPercentage`,
};

export const PdfTronToolbarRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: 40,
  padding: theme.spacing(0, 6),
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '70%',
  margin: '0 auto',

  [`& .${classes.fileNameContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.pageNumber}`]: {
    color: 'rgba(26, 26, 52, 0.4)',
  },

  [`& .${classes.zoomContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.main,
  },

  [`& .${classes.zoomIconContainer}`]: {
    backgroundColor: `rgba(101, 49, 255, 0.06)`,
    width: 32,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  [`& .${classes.icon}`]: {
    margin: 'auto',
    display: 'block',
    fontSize: 14,
  },

  [`& .${classes.leftBorderRadius}`]: {
    borderRadius: '8px 0px 0px 8px',
  },

  [`& .${classes.rightBorderRadius}`]: {
    borderRadius: '0px 8px 8px 0px',
  },

  [`& .${classes.zoomPercentage}`]: {
    backgroundColor: `rgba(101, 49, 255, 0.06)`,
    width: 54,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5),
  },
}));
