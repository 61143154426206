import { styled } from '@mui/material';

export const RefreshQrButton = styled('button')(() => ({
  width: '200px',
  height: '52px',
  borderRadius: '4px',
  fontSize: '18px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  backgroundColor: '#cf0b15',
  cursor: 'pointer',
  zIndex: 999,
  border: 'none',
  color: 'white',
  [`&:hover`]: {
    backgroundColor: '#87070e',
  },
}));
