import { useTheme } from '@emotion/react';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import SidebarWrapperContainer from '../SidebarWrapperContainer';
import CustomTextPad from './CustomTextPad';

function CustomTextBox({
  newDedocoSigning = false,
  validationFunc = null,
  errorMessage: errorMessageProp = null,
  errorMessageFunc = null,
}) {
  // hook
  const { t } = useTranslation();
  const theme = useTheme();

  const { currentField } = useGetRequiredDedocoSigning(newDedocoSigning);
  const [errorMessage, setErrorMessage] = useState(errorMessageProp);
  const { instance } = usePdfTronContext();
  const [error, setError] = useState(false);

  // state
  const [value, setValue] = useState('');

  const handleOnChange = (value) => {
    if (validationFunc) {
      const isValid = validationFunc(value);
      setError(!isValid);
    }
    if (errorMessageFunc) {
      setErrorMessage(errorMessageFunc(value));
    }
    setValue(value);
  };

  const handleSave = useCallback(() => {
    setSignatureWidgetText(value, currentField.fieldId, instance);
  }, [currentField, value, instance]);

  const disableButton = useMemo(() => {
    return Boolean(!value) || error;
  }, [value, error]);

  return (
    <SidebarWrapperContainer
      newDedocoSigning={newDedocoSigning}
      title={t('CustomTextTitle')}
      content={
        <CustomTextPad
          handleValueChange={handleOnChange}
          style={{ padding: theme.spacing(4) }}
          error={error}
          errorMessage={errorMessage}
        />
      }
      footer={
        <Button
          data-testid="sidebar-save-custom-text-button"
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={disableButton}
          fullWidth
        >
          {t('CustomTextButton')}
        </Button>
      }
    />
  );
}

export default CustomTextBox;
