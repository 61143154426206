import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebexContext } from '../../contexts/useWebexContext';
import WebexContainer from './WebexContainer';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StyledNumberList,
} from './EnterSigningLink.styles';

const PREFIX = 'EnterSigningLink';

const classes = {
  input: `${PREFIX}-input`,
  errorText: `${PREFIX}-errorText`,
  wrapperButton: `${PREFIX}-wrapperButton`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  flex: '1 1 auto',
  [`& .${classes.input}`]: {
    padding: theme.spacing(2.125, 2.625),
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.main,
  },
  [`& .${classes.errorText}`]: {
    '& input': {
      color: '#EB5757',
    },
  },
  [`& .${classes.wrapperButton}`]: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
}));

const EnterSigningLink = (props) => {
  const { t } = useTranslation();
  const { handleConfirm, isEnteringProjectId } = props;
  const { error } = useWebexContext();

  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <WebexContainer
      body={
        <>
          <StyledGrid container direction="column">
            <Grid
              container
              item
              alignItems="flex-start"
              justifyContent="center"
              gap={1.5}
              direction="column"
            >
              <Grid item>
                {isEnteringProjectId ? (
                  <Typography variant="title_large">
                    {t('WebexEnterSigningLinkHostSubTitle')}
                  </Typography>
                ) : (
                  <Typography variant="title_large">
                    {t('WebexEnterSigningLinkRecipientSubTitle')}
                  </Typography>
                )}
              </Grid>
              {isEnteringProjectId && (
                <Grid item>
                  <Typography variant="body_medium" color="grey.700">
                    {t('WebexEnterSigningLinkHostCaption')}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item style={{ paddingTop: error ? 0 : 12 }} spacing={1}>
              {error && (
                <Grid item xs={12}>
                  <p className="m0 text-align-left error-text">
                    {isEnteringProjectId
                      ? t('WebexEnterSigningLinkHostErrorText')
                      : t('WebexEnterSigningLinkRecipientErrorText')}
                  </p>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  value={input}
                  inputProps={{ className: classes.input }}
                  onChange={handleInputChange}
                  error={!!error}
                  className={`input-field ${error ? classes.errorText : ''}`}
                />
              </Grid>
              <Grid className={classes.wrapperButton}>
                <Button
                  fullWidth
                  disabled={!input}
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm(input)}
                >
                  <Typography variant="label_large">
                    {t('WebexEnterSigningLinkButtonText')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </StyledGrid>
          {isEnteringProjectId && <EnterDocumentIDInstruction />}
        </>
      }
    />
  );
};

const EnterDocumentIDInstruction = () => {
  const { t } = useTranslation();
  return (
    <Accordion disableGutters elevation={0}>
      <AccordionSummary>
        <Typography variant="label_medium">
          {t('WebexEnterSigningLinkDocumentIDInstructionHeader')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body_medium" color="grey.700">
          <div>{t('WebexEnterSigningLinkDocumentIDInstructionTitle')}</div>
          <StyledNumberList>
            <li>{t('WebexEnterSigningLinkDocumentIDInstructionOption1')}</li>
            <li>{t('WebexEnterSigningLinkDocumentIDInstructionOption2')}</li>
          </StyledNumberList>
          <ul>
            <li>{t('WebexEnterSigningLinkDocumentIDInstructionStep1')}</li>
            <li>{t('WebexEnterSigningLinkDocumentIDInstructionStep2')}</li>
            <li>{t('WebexEnterSigningLinkDocumentIDInstructionStep3')}</li>
          </ul>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default EnterSigningLink;
