import { Grid, Typography } from '@mui/material';
import React from 'react';
import MeetingQueueImg from '../../../assets/meeting-queue.png';
import MeetingReadyImg from '../../../assets/meeting-ready.png';
import './MeetingNotStarted.css';

function MeetingNotStarted({ meetingStarted, setJoinMeeting, meetingInfo }) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="meeting-not-started-container"
    >
      <Grid item className="meeting-not-started-box">
        <img src={meetingStarted ? MeetingReadyImg : MeetingQueueImg} alt="meeting queue" />
        <p className="meeting-not-started-box-title">
          {meetingStarted ? 'The Meeting Room Is Ready' : `You're in the waiting room`}
        </p>
        {meetingStarted ? (
          <div style={{ marginBottom: '50px' }}>
            <button
              className="join-meeting-button"
              onClick={() => {
                setJoinMeeting(true);
              }}
            >
              JOIN MEETING
            </button>
          </div>
        ) : (
          <div style={{ color: '#4f4f4f' }}>
            <p className="meeting-not-started-box-description">
              The Host has not started the meeting.
            </p>
            <Typography variant="body2">Title: {meetingInfo.title}</Typography>
            <Typography variant="caption">Description: {meetingInfo.description}</Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default MeetingNotStarted;
