import { Root, UserAvatar } from './FormRecipientDisplay.style';
import { Typography } from '@mui/material';

const FormRecipientDisplay = ({ name, email }) => {
  const initials = name
    ?.split(' ')
    .slice(0, 2)
    .map((char) => char?.[0]?.toUpperCase())
    .join('');

  return (
    <Root>
      <UserAvatar>{initials}</UserAvatar>
      <Typography variant="label_large">{name}</Typography>
      <Typography variant="body_medium">{email}</Typography>
    </Root>
  );
};

export default FormRecipientDisplay;
