import { Box, styled } from '@mui/material';

const PREFIX = 'Sidebar';

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  verifyRequiredFieldsContainer: `${PREFIX}-verify-required-field-container`,
  divider: `${PREFIX}-divider`,
};

export const Root = styled('div')(({ theme, windowSize }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.main,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  margin: windowSize.height > 520 ? theme.spacing(2, 2, 4, 4) : 0,
  padding: windowSize.height > 520 ? theme.spacing(6) : 0,
  position: 'relative',

  [`& .${classes.wrapper}`]: {
    height: '100%',
  },
  [`& .${classes.verifyRequiredFieldsContainer}`]: {
    height: 'calc(100% - 72px)',
    overflow: 'scroll',
    flexWrap: 'nowrap',
  },
  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(4),
  },
}));

export const DownloadButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: `calc(100% - ${theme.spacing(8)})`,
  bottom: '5%',
}));
