import { Button, styled } from '@mui/material';

export const Root = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '93%',
  padding: theme.spacing(2, 4),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& .MuiButton-endIcon>*:nth-of-type(1)': {
    fontSize: '13px',
  },
}));
