import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import SidebarWrapperContainer from '../SidebarWrapperContainer';
import { classes, Root } from './DatePicker.style';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { getAnnotInfoFromFieldId } from '../../../../utils/pdftron';

function CustomDatePicker({ newDedocoSigning = false }) {
  const { t } = useTranslation();
  const { currentField, projectSigners } = useGetRequiredDedocoSigning(newDedocoSigning);
  const { instance } = usePdfTronContext();
  const [date, setDate] = useState();

  const info = getAnnotInfoFromFieldId(currentField.fieldId);

  const handleSave = useCallback(() => {
    const dateFormat =
      projectSigners[info.signerIndex].customTexts[info.index]?.validation?.format ?? 'DD/MM/YYYY';
    setSignatureWidgetText(date?.format(dateFormat), currentField.fieldId, instance);
  }, [projectSigners, info.signerIndex, info.index, date, currentField.fieldId, instance]);

  const disableSaveButton = Boolean(!date);

  return (
    <SidebarWrapperContainer
      title={t('CustomDatePickerTitle')}
      newDedocoSigning={newDedocoSigning}
      content={
        <Root>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CalendarPicker
              className={classes.calender}
              date={date}
              onChange={(newDate) => setDate(newDate)}
              minDate={projectSigners[info.signerIndex].customTexts[info.index]?.validation?.min}
              maxDate={projectSigners[info.signerIndex].customTexts[info.index]?.validation?.max}
            />
          </LocalizationProvider>
        </Root>
      }
      footer={
        <Button
          data-testid="sidebar-save-date-button"
          onClick={handleSave}
          variant="contained"
          color="primary"
          fullWidth
          disabled={disableSaveButton}
        >
          {t('CustomDateButton')}
        </Button>
      }
    />
  );
}

export default CustomDatePicker;
