import React from 'react';
import { Box, ButtonBase, Link, Stack, Typography, styled } from '@mui/material';
import DemailIcon from '../../../../../assets/demailIcon.svg';
import AppleStore from '../../../../../assets/appleStoreBtn.svg';
import GooglePlay from '../../../../../assets/googlePlayBtn.svg';
import { classes } from '../InfoSidebar.styles';
import { useTranslation } from 'react-i18next';
import { process_env } from '../../../../../utils/envData';

const imgButtons = [
  {
    bgImage: AppleStore,
    title: 'AppleStoreBtn',
    url: 'https://apps.apple.com/us/app/ping-by-dedoco/id6448515688',
  },
  {
    bgImage: GooglePlay,
    title: 'GooglePlayBtn',
    url: 'https://play.google.com/store/apps/details?id=com.dedoco.dedoco_verifier_poc&pli=1',
  },
];

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
});

const DeMailTouchPointBox = ({ backgroundColor = 'supporting.light', compactMode = false }) => {
  const { t } = useTranslation();

  if (process_env('REACT_APP_DISABLE_MARKETING') === 'true') {
    return null;
  }

  return (
    <Box
      sx={{
        padding: compactMode ? 2 : 5,
        backgroundColor: backgroundColor,
        alignContent: 'center',
        textAlign: 'center',
        borderRadius: '12px',
      }}
    >
      <Stack spacing={compactMode ? 1.5 : 3}>
        <div>
          <img
            alt="Ping By Dedoco"
            src={DemailIcon}
            width={compactMode ? 60 : 76}
            height={compactMode ? 60 : 76}
          />
        </div>
        <Stack direction={'row'} alignItems={'baseline'} justifyContent={'center'}>
          <Typography
            variant="headline_small"
            sx={{ fontSize: compactMode ? 16 : 20 }}
            className={classes.demailTitle}
          >
            {t('DeMailTouchPointTitle')}
          </Typography>
          <Box
            sx={{
              padding: compactMode ? '1px 4px' : '2px 4px',
              marginLeft: 2,
              backgroundColor: 'secondary.main',
              borderRadius: '4px',
              textAlign: 'center',
              maxHeight: '24px',
            }}
          >
            <Typography variant="label_medium" color={'white'}>
              {t('NEW')}
            </Typography>
          </Box>
        </Stack>
        <Typography variant="body_medium" sx={{ lineHeight: '20px' }}>
          {t('DeMailTouchPointDescription')}
        </Typography>

        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={3}
          sx={{
            padding: '7px 0',
          }}
        >
          {imgButtons.map((btn) => (
            <ButtonBase
              focusRipple
              key={btn.title}
              style={{
                height: 40,
                width: 140,
              }}
              onClick={() => {
                window.open(btn.url, '_blank', 'noopener');
              }}
            >
              <ImageSrc style={{ backgroundImage: `url(${btn.bgImage})` }} />
            </ButtonBase>
          ))}
        </Stack>

        <Link
          component="button"
          variant="body2"
          onClick={() => {
            window.open('https://www.dedoco.com/products/demail', '_blank', 'noopener');
          }}
          sx={{
            fontSize: 14,
            padding: '7px 0',
          }}
        >
          {t('DeMailLearnMore')}
        </Link>
      </Stack>
    </Box>
  );
};
export default DeMailTouchPointBox;
