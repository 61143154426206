import { styled } from '@mui/material';

const PREFIX = 'SingPassLogin';
export const classes = {
  loginCardMessage: `${PREFIX}-login-card-message`,
  loginButton: `${PREFIX}-login-button`,
  loginErrorNoticeIcon: `${PREFIX}-login-error-notice-icon`,
  loginErrorNoticeMessage: `${PREFIX}-login-error-notice-message`,
};

export const Root = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  alignItems: 'flex-start',
  textAlign: 'left',

  [`& .${classes.loginCardMessage}`]: {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: theme.palette.supporting.light,
    border: `1px solid ${theme.palette.supporting.main}`,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
  },

  [`& .${classes.loginButton}`]: {
    width: '100%',
    cursor: 'pointer',
  },
}));

export const LoginErrorNoticeRoot = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  borderRadius: '8px',
  border: `1px solid ${theme.palette.error.light}`,
  backgroundColor: theme.palette.error.extraLight,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  padding: theme.spacing(4),

  [`& .${classes.loginErrorNoticeIcon}`]: {
    display: 'block',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(-1.5),
    strokeWidth: '0.1px',
    height: '32px',
    width: 'auto',
  },

  [`& .${classes.loginErrorNoticeMessage}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    textAlign: 'left',
  },
}));
