import {
  createFormWidgetLayout,
  createTextDisplayAnnotation,
  createWidgetLayout,
} from './pdftronWidgetCommon';
import ArrowDownIcon from '../assets/arrow-down.svg';

export const createDropdownWidgetAnnotation = (
  fieldId,
  value,
  name,
  label,
  color,
  fieldFlags,
  writable,
  pdfTronInstance,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetLayout = createWidgetLayout(iframeWindow, {
    iconSrc: ArrowDownIcon,
    title: label,
    color,
    tagText: name,
    iconPosition: 'right',
    iconWidth: '1em',
    flushIconToSide: true,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field);
  widgetAnnot.Id = fieldId;

  if (!writable) {
    if (value) {
      const textAnnot = createTextDisplayAnnotation(value, Annotations);
      widgetAnnot.setAssociatedSignatureAnnotation(textAnnot);
      widgetLayout.hideLabel();
    }
    widgetAnnot.createInnerElement = () => {
      return widgetLayout;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      return widgetLayout;
    };
  }

  return { widgetAnnot, field };
};

export const createFormDropdownWidgetAnnotation = (
  fieldId,
  fieldFlags,
  dropdownInput,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });

  const dropdownWidgetLayout = createFormWidgetLayout(iframeWindow, {
    labelText: dropdownInput.label,
    isRequired: dropdownInput.isRequired,
    iconSrc: ArrowDownIcon,
    iconWidth: '0.6em',
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, null);
  widgetAnnot.createInnerElement = () => {
    return dropdownWidgetLayout;
  };

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const isDropdownList = (id) => {
  return typeof id === 'string' && id.startsWith('dropdown-list');
};
