import { sha3_256 } from 'js-sha3';
/**
 * get the hash of a selected file
 * can be used in conjunction with antd Upload
 */
export async function getFileHash(_file) {
  if (!_file) return '';
  const data = await readFileBufferAsync(_file);
  const hash = sha3_256.create();
  hash.update(data);
  _file['hash'] = hash.hex();
  return hash.hex();
}
export async function getStringHash(textdata) {
  if (!textdata) return '';
  const hash = sha3_256.create();
  hash.update(textdata);
  hash.hex();
  return hash.hex();
}

export function readFileBufferAsync(_file) {
  if (!_file) return Promise.reject();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;

    reader.readAsArrayBuffer(_file);
  });
}

//Gary b64 to file hash

export const getBase64FileHash = (base64File) => {
  let base64Str;

  if (base64File.startsWith('data:')) {
    base64Str = base64File.split(',')[1];
  } else {
    base64Str = base64File;
  }

  return sha3_256Hash(base64toUint8Arr(base64Str));
};

export const sha3_256Hash = (data) => {
  //return sha3_256(data);
  const hash = sha3_256.create();
  hash.update(data);
  return hash.hex();
};

function base64toUint8Arr(base64) {
  const bstr = atob(base64);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return u8arr;
}
