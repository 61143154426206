import { Alert, AlertTitle, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarDownloadButton from '../Sidebar/SidebarDownloadButton';
import DocumentStatusPanel from './DocumentStatusPanel';

import { StyledStack } from './Sidebar.style';
import { TryFreeSection } from './TryFreeSection';
import DeMailTouchPointBox from '../Sidebar/InfoSidebar/DeMailTouchPoint';
export default function SigningCompletionEndCall({
  handleDownloadPdf,
  windowSize,
  hasRecordedScreen,
  isMeetingHost,
}) {
  const { t } = useTranslation();
  return (
    <StyledStack windowSize={windowSize}>
      <Alert
        severity="info"
        sx={(theme) => ({
          textAlign: 'left',
          marginBottom: theme.spacing(6),
          padding: theme.spacing(1, 3),
          border: `1px solid ${theme.palette.info.main}`,
        })}
      >
        <AlertTitle>
          {isMeetingHost ? t('YouHaveEndedTheMeeting') : t('HostEndTheMeeting')}
        </AlertTitle>
        {hasRecordedScreen && t('EndCallCompletionRecordSend')}
      </Alert>
      <DocumentStatusPanel
        title={t('SigningProcessCompleteTitle')}
        content={[t('SigningProcessCompleteContentP1'), t('SigningProcessCompleteContentP2')]}
        bottomButton={
          <SidebarDownloadButton
            sx={(theme) => ({ width: '50%', marginTop: theme.spacing(6) })}
            handleDownloadPdf={handleDownloadPdf}
            useNewDedocoSigning
          />
        }
      />
      <Box
        sx={{
          paddingTop: 5,
        }}
      >
        <DeMailTouchPointBox backgroundColor="white" compactMode />
      </Box>
      <TryFreeSection sx={(theme) => ({ marginTop: theme.spacing(6) })} />
    </StyledStack>
  );
}
