import { createContext, forwardRef, useImperativeHandle } from 'react';
import useDedocoVideoSigningStore from './DedocoVideoSigningStore';

export const VideoSigningStoreContext = createContext();

function VideoSigningStore({ store, children }, ref) {
  const storeInstance = useDedocoVideoSigningStore({ store });

  useImperativeHandle(ref, () => storeInstance, [storeInstance]);

  return (
    <VideoSigningStoreContext.Provider value={storeInstance}>
      {children}
    </VideoSigningStoreContext.Provider>
  );
}

export default forwardRef(VideoSigningStore);
