import React from 'react';
import OTPVerify from './OTPAuth/OTPVerify';
import SingPassLogin from './SingPassAuth/SingPassLogin';
import {
  TWO_FA_METHOD_SINGPASS,
  TWO_FA_METHOD_SINGPASS_SKIP_NRIC_CHECK,
} from '../../utils/GlobalConstant';

export default function RecipientAuthentication({
  publicVariable,
  isVerifyingOTP,
  otpVerifyPopUp,
  recipientVerifySetting,
  recipientId,
  documentId,
  isObserver,
  isApprover,
  fileShareKey,
  singPassAuthCode,
  singPassLoginFailed,
}) {
  const isSingPassLogin =
    recipientVerifySetting.verify_method === TWO_FA_METHOD_SINGPASS ||
    recipientVerifySetting.verify_method === TWO_FA_METHOD_SINGPASS_SKIP_NRIC_CHECK;

  return isSingPassLogin ? (
    <SingPassLogin
      recipientId={recipientId}
      documentId={documentId}
      fileShareKey={fileShareKey}
      isObserver={isObserver}
      isApprover={isApprover}
      singPassAuthCode={singPassAuthCode}
      singPassLoginFailed={singPassLoginFailed}
    />
  ) : (
    <OTPVerify
      publicVariable={publicVariable}
      isVerifyingOTP={isVerifyingOTP}
      otpVerifyPopUp={otpVerifyPopUp}
      method={recipientVerifySetting.verify_method}
      email={recipientVerifySetting.recipient_email}
      phone={recipientVerifySetting.recipient_phone}
      recipientId={recipientId}
      documentId={documentId}
      isObserver={isObserver}
      isApprover={isApprover}
    />
  );
}
