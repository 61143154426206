import { useTranslation } from 'react-i18next';

const ChallengeCode = ({ challengeCode }) => {
  return (
    <div
      style={{
        width: '60.25px',
        display: 'inline-block',
        backgroundColor: '#504E56',
        marginRight: '8px',
        fontSize: '52px',
      }}
    >
      {challengeCode}
    </div>
  );
};

export const ChallengeCodeHeaders = ({ challenge }) => {
  const { t } = useTranslation();

  return (
    <div className="challenge-headers">
      <div
        style={{
          paddingTop: '16px',
          fontSize: '16px',
          color: '#504E56',
        }}
      >
        {t('SigningRequestChallengeCodeHeader')}
      </div>
      <div
        style={{
          fontSize: '16px',
          color: '#504E56',
        }}
      >
        {t('SigningRequestChallengeCodeHeaderText')}
      </div>
      <div className="challenge-codes" style={{ paddingTop: '16px' }}>
        <ChallengeCode challengeCode={challenge.slice(0, 1)} />
        <ChallengeCode challengeCode={challenge.slice(1, 2)} />
        <ChallengeCode challengeCode={challenge.slice(2, 3)} />
        <ChallengeCode challengeCode={challenge.slice(-1)} />
      </div>
    </div>
  );
};

export const ExpiredQR = ({ qrContent }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="expiredQR">{t('SigningRequestExpiredQRHeader')}</div>
      <div style={{ opacity: '5%' }}>{qrContent}</div>
    </div>
  );
};

export const RefreshQRButton = ({ transition, minutes, seconds, resetDigitalData, generateQR }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        transition && minutes === 0 && seconds === 0
          ? 'refresh-qr-container-expired'
          : 'refresh-qr-container'
      }
    >
      <button
        className="refreshQR"
        onClick={() => {
          resetDigitalData();
          generateQR();
        }}
      >
        <img alt="refresh icon" className="refresh-icon" src="/Refresh icon.svg" />
        <div className="refresh-message">
          {transition && minutes === 0 && seconds === 0
            ? t('SigningRequestGetNewQR')
            : t('SigningRequestRefreshQR')}
        </div>
      </button>{' '}
    </div>
  );
};

export const QrCodeMessage = ({ dimensions }) => {
  const { t } = useTranslation();

  return (
    <div style={{ paddingTop: '0px' }}>
      <div className="qr-code-message">
        {dimensions.width < 760 ? (
          <>{t('SigningRequestQRCodeMessageHeader')}</>
        ) : (
          <>{t('SigningRequestQRCodeMessageHeaderAlt')}</>
        )}
      </div>
      <div className="qr-code-message-1">{t('SigningRequestQRCodeMessageHeaderText')}</div>
    </div>
  );
};
