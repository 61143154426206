import Grid from '@mui/material/Unstable_Grid2';
import LanguageSelector from './LanguageSelector';

const Header = ({ component, tailComponent }) => {
  return (
    <Grid wrap="nowrap" container>
      <Grid xs={6} container spacing={4}>
        <Grid>{component}</Grid>
      </Grid>
      <Grid xs={6} container justifyContent="right" alignItems="center">
        {tailComponent}
        <LanguageSelector />
      </Grid>
    </Grid>
  );
};

export default Header;
