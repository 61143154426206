import React from 'react';
import './Layout.css';
import { getLogoFromCompanyName } from '../../utils/logo';
import { process_env } from '../../utils/envData';

function Header() {
  const logo = getLogoFromCompanyName(process_env('REACT_APP_COMPANY_NAME'));

  return (
    <div>
      <header className="Layout-header">
        <div className="Layout-header-logo">
          <img src={logo} alt="dedoco" />
        </div>

        <div className="title">
          <b></b>
        </div>
      </header>
    </div>
  );
}

export const PageLayout = ({ children }) => (
  <div className="Layout-background">
    <Header />
    <main className="Layout-content">{children}</main>
    <footer className="Layout-footer"></footer>
  </div>
);
