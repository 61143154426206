import { Box, Button, styled, Tab, Tabs } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`signature-method-tabpanel-${index}`}
      aria-labelledby={`signature-method-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const CustomTab = styled(Tab)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2, 3),
  fontSize: '0.75rem',
  lineHeight: '1rem',
  minWidth: 'unset',
  maxWidth: 'unset',
  textTransform: 'none',
  letterSpacing: '0.1px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px 8px 0px 0px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}));

export const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

export const CustomTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const DeleteSignatureButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label_medium,
  backgroundColor: theme.palette.error.extraLight,
  height: '44px',
  borderRadius: '8px',
  marginTop: theme.spacing(2),
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.contrast.primary,
  },
  '&:disabled': {
    ...theme.typography.label_medium,
    backgroundColor: theme.palette.error.extraLight,
    height: '44px',
    borderRadius: '8px',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.contrast.primary,
    },
  },
  [theme.breakpoints.down('md')]: {
    ...theme.typography.label_small,
    width: '76px',
    height: '36px',
  },
}));
