import { base64DataUrlToBlob, blobToBase64DataUrl, getDataURLFileSizeInBytes } from './base64';
import { getElementBuilder } from './domStyling';
import {
  createDropdownWidgetAnnotation,
  createFormDropdownWidgetAnnotation,
} from './dropdownListWidget';
import { getImageDimensions, resizeImage } from './image';

import {
  createActualDateWidgetAnnotation,
  createCustomDateWidgetAnnotation,
  createFormDateInputWidgetAnnotation,
  isActualDate,
} from './dateWidget';

import { createFormTextInputWidgetAnnotation, createTextWidgetAnnotation } from './textWidget';

import {
  createESignatureWidgetAnnotation,
  createFormESignatureWidgetAnnotation,
  createFormInitialsWidgetAnnotation,
  createFormSingPassWidgetAnnotation,
  createFormStampWidgetAnnotation,
  createInitialsWidgetAnnotation,
  createSingpassWidgetAnnotation,
  createStampWidgetAnnotation,
} from './signatureWidget';

import { downloadFileFromDSS } from '../lib/api/dss';
import { createCheckboxWidgetAnnotation } from './checkboxWidget';
import { compare } from './common';
import { MAX_ANNOTATION_IMAGE_SIZE_BYTES } from './GlobalConstant';
import { getFontSize } from './pdftronWidgetCommon';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { createEmailWidgetAnnotation } from './emailWidget';
import { pointToPixel } from './pixel';
import { PDFTRON_ASSOCIATED_VALUE_PREFIX } from '../constant/common';

dayjs.extend(buddhistEra);

export const setContentToSignatureAnnot = async (value, annotId, pdfTronInstance) => {
  const { Annotations, annotationManager, documentViewer } = pdfTronInstance.Core;
  const selectedSignature = annotationManager
    .getAnnotationsList()
    .find(
      (annot) => annot instanceof Annotations.SignatureWidgetAnnotation && annot.Id === annotId,
    );
  annotationManager.deleteAnnotation(selectedSignature.getAssociatedSignatureAnnotation());
  const stampAnnot = new Annotations.StampAnnotation();
  const keepAsSVG = false;
  const pageNumber = selectedSignature.PageNumber;
  const pageRotation = documentViewer.getDocument().getPageRotation(pageNumber);
  let imageValue = value;
  if (getDataURLFileSizeInBytes(imageValue) > MAX_ANNOTATION_IMAGE_SIZE_BYTES) {
    imageValue = await resizeImage(imageValue, MAX_ANNOTATION_IMAGE_SIZE_BYTES);
  }
  const contentImageDimensions = await getImageDimensions(imageValue);

  const adjustedStampDimensions = getChildDimensionsToFitParent(
    selectedSignature,
    contentImageDimensions,
  );
  const adjustedStampCoordinates = adjustWidgetChildCoordinatesToAlignCenter(
    selectedSignature,
    adjustedStampDimensions,
    pageRotation,
  );
  const rotatedStampDimensions = getRotatedDimensions(pageRotation, adjustedStampDimensions);

  stampAnnot.X = adjustedStampCoordinates.x;
  stampAnnot.Y = adjustedStampCoordinates.y;
  stampAnnot.Width = rotatedStampDimensions.width;
  stampAnnot.Height = rotatedStampDimensions.height;
  stampAnnot.PageNumber = pageNumber;
  stampAnnot.Rotation = pageRotation;
  stampAnnot.NoMove = true;
  stampAnnot.NoResize = true;

  await stampAnnot.setImageData(imageValue, keepAsSVG);
  selectedSignature.setAssociatedSignatureAnnotation(stampAnnot);
  annotationManager.addAnnotation(stampAnnot);
  annotationManager.redrawAnnotation(stampAnnot);
};

export const getRotatedDimensions = (rotation, dimensions) => {
  const isSideways = documentIsSideways(rotation);

  return {
    width: isSideways ? dimensions.height : dimensions.width,
    height: isSideways ? dimensions.width : dimensions.height,
  };
};

export const getChildDimensionsToFitParent = (parentWidget, childDimensions) => {
  const parentAspectRatio = parentWidget.getWidth() / parentWidget.getHeight();
  const childAspectRatio = childDimensions.width / childDimensions.height;
  const parentWider = parentAspectRatio > childAspectRatio;

  return {
    width: parentWider ? parentWidget.getHeight() * childAspectRatio : parentWidget.getWidth(),
    height: parentWider ? parentWidget.getHeight() : parentWidget.getWidth() / childAspectRatio,
  };
};

export const adjustWidgetChildCoordinatesToAlignCenter = (
  parentWidget,
  childDimensions,
  rotation,
) => {
  const isSideways = documentIsSideways(rotation);
  const widthOffset = (parentWidget.getWidth() - childDimensions.width) / 2;
  const heightOffset = (parentWidget.getHeight() - childDimensions.height) / 2;
  const xOffset = isSideways ? heightOffset : widthOffset;
  const yOffset = isSideways ? widthOffset : heightOffset;

  return {
    x: parentWidget.getX() + xOffset,
    y: parentWidget.getY() + yOffset,
  };
};

export const documentIsSideways = (rotation) => {
  return rotation === 90 || rotation === 270;
};

export const checkIfSignatureWidgetHasAssociatedAnnot = (
  annotId,
  annotationManager,
  Annotations,
) => {
  if (isActualDate(annotId)) {
    return true;
  }

  const selectedSignature = annotationManager
    .getAnnotationsList()
    .find(
      (annot) => annot instanceof Annotations.SignatureWidgetAnnotation && annot.Id === annotId,
    );
  const annot = selectedSignature?.getAssociatedSignatureAnnotation();
  return Boolean(annot);
};

export const getPdfBlob = async (documentViewer, annotationManager, isDownloading = false) => {
  if (!documentViewer || !annotationManager) return;

  const annotList = annotationManager.getAnnotationsList();
  const dateFormat = 'DD/MM/YYYY';
  let dateAnnots = [];
  if (isDownloading) {
    dateAnnots = annotList.filter((x) => x?.value === dateFormat);
    dateAnnots.forEach((a) => {
      const field = annotationManager.getFieldManager().getField(a.Id);
      field.setValue();
    });
  }
  const xfdfString = await annotationManager.exportAnnotations({
    annotList,
    links: false,
    widgets: true,
    fields: true,
  });
  // this is to remove default "DRAFT" stamp
  // const noDraftString = xfdfString.replace(/"Draft"/g, '');
  const data = await documentViewer.getDocument().getFileData({ xfdfString, flatten: true });

  if (isDownloading) {
    dateAnnots.forEach((a) => {
      const field = annotationManager.getFieldManager().getField(a.Id);
      field.setValue(dateFormat);
    });
  }

  const arr = new Uint8Array(data);
  return new Blob([arr], { type: 'application/pdf' });
};

export const getAnnotationTextAsImage = async (annotation, docViewer) => {
  const canvas = document.createElement('canvas');
  const rotation = docViewer.getDocument().getPageRotation(annotation.PageNumber);
  const ctx = canvas.getContext('2d');
  const rotatedDimensions = getRotatedDimensions(rotation, {
    width: annotation.getWidth(),
    height: annotation.getHeight(),
  });

  // Obtained through trial and error.
  // Makes sure that the image of the text will show the text with a font size similar to the expected font size.
  const dimensionScalingFactor = 1.3;
  const fontSizeToLineHeightFactor = 1.2;

  canvas.height = rotatedDimensions.height * dimensionScalingFactor;
  canvas.width = rotatedDimensions.width * dimensionScalingFactor;

  const fontSizeInPt = parseInt(annotation.FontSize);
  const fontSizeInPx = !isNaN(fontSizeInPt) ? pointToPixel(fontSizeInPt) : 0;

  const textContent = annotation.getContents();
  const textLines = textContent.split('\n');
  const lineHeight = fontSizeInPx * fontSizeToLineHeightFactor || 14;
  const totalTextHeight = lineHeight * textLines.length;

  const fontSize = getFontSize(textContent.length);
  const fontFamily = annotation.Font || 'HCo Gotham';
  const color = annotation.TextColor || 'black';
  // Centralise text by default
  let canvasTextAlign = 'center';
  let canvasTextBaseline = 'middle';
  let x = canvas.width / 2;
  // This is the starting y value to centralise the text.
  let y = (canvas.height - totalTextHeight) / 2 + lineHeight / 2;
  const annotTextHorizontalAlign = annotation.TextAlign;
  const annotTextVerticalAlign = annotation.TextVerticalAlign;

  if (annotTextHorizontalAlign === 'left') {
    x = 0;
    canvasTextAlign = 'left';
  } else if (annotTextHorizontalAlign === 'right') {
    x = canvas.width;
    canvasTextAlign = 'right';
  }

  if (annotTextVerticalAlign === 'top') {
    y = 0;
    canvasTextBaseline = 'top';
  } else if (annotTextVerticalAlign === 'bottom') {
    y = canvas.height - totalTextHeight + lineHeight;
    canvasTextBaseline = 'bottom';
  }

  ctx.beginPath();
  ctx.font = fontSize + ' ' + fontFamily;
  ctx.textAlign = canvasTextAlign;
  ctx.textBaseline = canvasTextBaseline;
  ctx.fillStyle = color;
  textLines.forEach((line, i) => {
    ctx.fillText(line, x, y + i * lineHeight);
  });
  ctx.stroke();

  return canvas.toDataURL();
};

export const getAnnotationContentAsImage = async (annotation, getBlob = false) => {
  const imageData = await annotation.getImageData();
  return getBlob ? base64DataUrlToBlob(imageData) : imageData;
};

export const normalStyles = (iframeWindow, documentViewer, Annotations, widget) => {
  // This function, when assigned to WidgetAnnotations.getCustomStyles, is called everything zoom changes.
  const { color, fieldName, PageNumber } = widget;
  const pageZoom = documentViewer.getPageZoom(PageNumber);
  const widgetField = widget.getField();
  let font = widgetField.font;

  if (!font || font.size === 0) {
    const widgetElement = iframeWindow.document.getElementById(fieldName);
    const fontName = widgetElement?.style.fontFamily || 'Lato';
    const cssFontSize = widgetElement?.style.fontSize.replace('px', '');
    const fontSizeNum = Number(cssFontSize);
    // Page zoom is taken into account to keep fontSize close to the default fontsize of the element at 100% zoom.
    // A constant factor is added to scale font to a good viewing size.
    const fontScalingFactor = 1.02 / pageZoom;
    const fontSize = isNaN(fontSizeNum) ? 12 : fontSizeNum * fontScalingFactor;

    font = new Annotations.Font({
      name: fontName,
      size: fontSize,
    });
  }

  if (
    widget instanceof Annotations.TextWidgetAnnotation ||
    widget instanceof Annotations.DatePickerWidgetAnnotation
  ) {
    widgetField.set({ font });
    return {
      overflow: 'visible',
      border: `0.17em solid ${color}`,
      color: 'inherit !important',
    };
  } else if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
    return {
      'border-radius': '4px',
      border: `2px solid ${color}`,
      backgroundColor: 'transparent',
      display: 'flex',
    };
  } else if (widget instanceof Annotations.ChoiceWidgetAnnotation) {
    widgetField.set({ font });
    return {
      border: `2px solid ${color}`,
      backgroundColor: 'transparent',
      textAlign: 'center',
      opacity: 1,
      display: 'flex',
    };
  } else {
    return {
      border: `2px solid ${color}`,
      display: 'flex',
    };
  }
};

// 0.76 is the ratio after calculating the difference ratio between box's size on builder and signing app
export const convertToOriginSize = (length) => {
  return length * 0.76;
};

export const adjustCoordinatesAfterAnnotationResizing = (
  originalCoordinates,
  rotation,
  originalAnnotationDimensions,
  resizedAnnotationDimensions,
) => {
  /* During annotation resizing the anchor point of the annotation is the corner that is closest to (x = 0, y = 0) of the page.
   *  i.e.
   *    When rotation is 0, anchor point is top left
   *    When rotation is 90, anchor point is top right
   *    When rotation is 180, anchor point is bottom right
   *    When rotation is 270, anchor point is bottom left
   * When filling up a signature or annotation, the anchor point is set to be top left.
   *  However, resizing the annotation on a rotated page can cause the drawn annotation to move away from top left as the anchor points are different.
   *  This shifts the content of the annotation away from where the user expects it to be.
   * This function attempts to correct this issue by flushing the content of the annotation to the top left regardless of page rotation.
   */
  const adjustedCoords = {
    x: originalCoordinates.x,
    y: originalCoordinates.y,
  };

  const widthDimensionDiff = originalAnnotationDimensions.width - resizedAnnotationDimensions.width;
  const heightDimensionDiff =
    originalAnnotationDimensions.height - resizedAnnotationDimensions.height;

  if (rotation === 90) {
    adjustedCoords.y += widthDimensionDiff;
  } else if (rotation === 180) {
    adjustedCoords.x += widthDimensionDiff;
    adjustedCoords.y += heightDimensionDiff;
  } else if (rotation === 270) {
    adjustedCoords.x += heightDimensionDiff;
  }

  return adjustedCoords;
};

export const convertPlacementPercentageToViewerCoordinates = (placement, pageInfo) => {
  return {
    x: (parseFloat(placement?.x) / 100) * pageInfo.width,
    y: (parseFloat(placement?.y) / 100) * pageInfo.height,
  };
};

export const drawDigitalSignatures = async (
  digitalSignatures,
  signerDetails,
  initiateNdiSigningRef,
  pdfTronInstance,
) => {
  const { name, color, isCurrentSigner, signerIndex, hasSigned } = signerDetails;
  const { WidgetFlags } = pdfTronInstance.Core.Annotations;

  return Promise.all(
    digitalSignatures.map(async (digitalSignature, index) => {
      const { placement, dimensions, signature } = digitalSignature;
      const flags = new WidgetFlags(null);
      flags.set(WidgetFlags.READ_ONLY);

      if (signature) {
        return;
      }

      const fieldId = generateFieldId('digi_signature', index, signerIndex);

      const { widgetAnnot, field } = await createSingpassWidgetAnnotation(
        fieldId,
        name,
        color,
        isCurrentSigner && !hasSigned,
        flags,
        dimensions,
        initiateNdiSigningRef,
        pdfTronInstance,
      );

      drawWidgetAnnotation(field, widgetAnnot, placement, dimensions, pdfTronInstance);
    }),
  );
};

export const drawConfirmations = (
  confirmations,
  signerDetails,
  isObserver,
  isApprover,
  isUsingUpdatedDocument,
  pdfTronInstance,
) => {
  const { signerIndex, color, isCurrentSigner, hasSigned } = signerDetails;
  const { WidgetFlags } = pdfTronInstance.Core.Annotations;

  confirmations.forEach((item, index) => {
    const { placement, dimensions, isDefault, isChecked } = item;

    let fieldId = null;
    const flags = new WidgetFlags(null);
    let shouldCheck = isDefault || isChecked;

    if (isCurrentSigner && !hasSigned) {
      fieldId = generateFieldId('checkbox', index, signerIndex);
    } else if (hasSigned || isObserver || isApprover) {
      fieldId = generateFieldId(`other-checkbox`, index, signerIndex);
      flags.set(WidgetFlags.READ_ONLY);

      if (isUsingUpdatedDocument) {
        // If an updated document is used for signing, and the checkboxes belong to the previous signers,
        //   it means that the existing ticks added by the previous signers, if any, will already be embedded in the document.
        // Therefore, the newly drawn checkboxes should not show any ticks to prevent it from covering the existing ticks.
        shouldCheck = false;
      }
    } else {
      return;
    }

    const { widgetAnnot, field } = createCheckboxWidgetAnnotation(
      fieldId,
      shouldCheck,
      color,
      flags,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, placement, dimensions, pdfTronInstance);
  });
};

export const drawESignatures = (
  esignatures,
  signerDetails,
  isUsingUpdatedDocument,
  pdfTronInstance,
) => {
  const { signerIndex, name, color, isCurrentSigner, hasSigned } = signerDetails;
  const { WidgetFlags } = pdfTronInstance.Core.Annotations;

  return Promise.all(
    esignatures.map(async (esignature, index) => {
      const { placement, dimensions, signature } = esignature;
      let signatureToShow = signature;
      const fieldFlags = new WidgetFlags(null);

      if (!isCurrentSigner || hasSigned) {
        fieldFlags.set(WidgetFlags.READ_ONLY);
        if (isUsingUpdatedDocument && signatureToShow) {
          // If an updated document is used for signing, and the esignatures belong to the previous signers,
          //   then the existing esignatures added by the previous signers, if any, will already be present in the document.
          // Therefore, the esignatures that are used to draw the widgets should be something transparent to prevent showing duplicated values.
          signatureToShow = '\n';
        }
      }
      const fieldIdLabel = isCurrentSigner && !hasSigned ? 'esignature' : 'other-esignature';
      const fieldId = generateFieldId(fieldIdLabel, index, signerIndex);

      const { widgetAnnot, field } = await createESignatureWidgetAnnotation(
        fieldId,
        fieldFlags,
        name,
        color,
        signatureToShow,
        isCurrentSigner && !hasSigned,
        pdfTronInstance,
      );
      drawWidgetAnnotation(field, widgetAnnot, placement, dimensions, pdfTronInstance);
    }),
  );
};

export const drawCustomTexts = (
  customTexts,
  signerDetails,
  isObserver,
  isApprover,
  isUsingUpdatedDocument,
  pdfTronInstance,
) => {
  const { signerIndex, name, color, isCurrentSigner, hasSigned } = signerDetails;
  const { WidgetFlags } = pdfTronInstance.Core.Annotations;

  return Promise.all(
    customTexts.map(async (item, index) => {
      const { descriptor, placement, dimensions, type, label } = item;

      let text = item.text;
      let field = null;
      let fieldId = null;
      let widgetAnnot = null;
      const flags = new WidgetFlags(null);

      if (isCurrentSigner && !hasSigned) {
        fieldId = generateFieldId(type, index, signerIndex);
      } else if (hasSigned || isObserver || isApprover) {
        fieldId = generateFieldId(`other-${type}`, index, signerIndex);
        flags.set(WidgetFlags.READ_ONLY);

        if (isUsingUpdatedDocument && text) {
          // If an updated document is used for signing, and the custom texts belong to the previous signers,
          //   then the existing texts/images added by the previous signers, if any, will already be present in the document.
          // Therefore, the values that are used to draw the widgets should be something transparent to prevent showing duplicated values.
          text = '\n';
        }
      } else {
        return;
      }

      switch (type) {
        case 'text':
        case 'url':
        case 'alphanumeric':
        case 'number':
          ({ widgetAnnot, field } = createTextWidgetAnnotation(
            fieldId,
            text,
            name,
            descriptor,
            color,
            flags,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
            type,
          ));
          break;
        case 'email':
          ({ widgetAnnot, field } = createEmailWidgetAnnotation(
            fieldId,
            text,
            name,
            descriptor,
            color,
            flags,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
          ));
          break;
        case 'actual-date': {
          flags.set(WidgetFlags.READ_ONLY);
          const YYYY = item.calendar === 'Buddhist' ? 'BBBB' : 'YYYY';
          const currentDateText = text || dayjs().format(`DD/MM/${YYYY}`);
          ({ widgetAnnot, field } = createActualDateWidgetAnnotation(
            fieldId,
            currentDateText,
            color,
            name,
            flags,
            pdfTronInstance,
          ));
          break;
        }
        case 'dropdown-list':
          ({ widgetAnnot, field } = createDropdownWidgetAnnotation(
            fieldId,
            text,
            name,
            label,
            color,
            flags,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
          ));
          break;
        case 'custom-date':
          ({ widgetAnnot, field } = createCustomDateWidgetAnnotation(
            fieldId,
            text,
            color,
            name,
            flags,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
          ));
          break;
        case 'initials':
          ({ widgetAnnot, field } = await createInitialsWidgetAnnotation(
            fieldId,
            flags,
            name,
            color,
            text,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
          ));
          break;
        case 'stamp':
          ({ widgetAnnot, field } = await createStampWidgetAnnotation(
            fieldId,
            flags,
            name,
            color,
            text,
            isCurrentSigner && !hasSigned,
            pdfTronInstance,
          ));
          break;
        default:
          break;
      }

      drawWidgetAnnotation(field, widgetAnnot, placement, dimensions, pdfTronInstance);
    }),
  );
};

export const createTextBox = (iframeWindow, color = '#000000', fontSize = '1em') => {
  const getStyledElement = getElementBuilder(iframeWindow);
  return getStyledElement('input', () => ({
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    fontWeight: 'normal',
    fontSize: fontSize,
    color: color,
    border: 'none',
  }));
};

export const createWrapperDiv = (name, color, background, align = 'center', iframeWindow) => {
  const div = iframeWindow.document.createElement('div');
  div.style.width = '100%';
  div.style.height = '100%';
  div.style.cursor = 'pointer';
  div.style.display = 'flex';
  div.style.justifyContent = align === 'center' ? 'center' : 'flex-start';
  div.style.alignItems = 'center';
  div.style.borderRadius = '4px';
  div.style.fontWeight = 'bold';
  div.style.color = color;
  div.style.border = `2px solid ${color}`;
  div.style.background = background || 'transparent';

  const signerDiv = iframeWindow.document.createElement('div');
  signerDiv.innerText = name; // signer name
  signerDiv.style.position = 'absolute';
  signerDiv.style.top = '-8px';
  signerDiv.style.left = '12px';
  signerDiv.style.background = `${color}`;
  signerDiv.style.borderRadius = '4px';
  signerDiv.style.fontWeight = 'bold';
  signerDiv.style.color = 'white';
  signerDiv.style.border = `2px solid ${color}`;
  signerDiv.style.padding = '2px 8px';

  div.appendChild(signerDiv);
  return div;
};

export const drawWidgetAnnotation = async (
  field,
  widgetAnnot,
  placement,
  dimensions,
  pdfTronInstance,
) => {
  if (!widgetAnnot || !field) {
    return;
  }

  const { documentViewer, annotationManager } = pdfTronInstance.Core;
  const fieldManager = annotationManager.getFieldManager();

  const pageInfo = documentViewer.getDocument().getPageInfo(placement?.page);
  const rotation = documentViewer.getDocument().getPageRotation(placement?.page);

  const originalAnnotationDimensions = {
    width: convertToOriginSize(parseInt(dimensions.width)),
    height: convertToOriginSize(parseInt(dimensions.height)),
  };

  const viewerCoords = convertPlacementPercentageToViewerCoordinates(placement, pageInfo);

  widgetAnnot.rotation = rotation;
  widgetAnnot.X = viewerCoords.x;
  widgetAnnot.Y = viewerCoords.y;
  widgetAnnot.PageNumber = placement?.page;
  widgetAnnot.Width = originalAnnotationDimensions.width;
  widgetAnnot.Height = originalAnnotationDimensions.height;
  widgetAnnot.NoResize = true;
  widgetAnnot.NoMove = true;
  widgetAnnot.RotationControlEnabled = false;

  annotationManager.addAnnotation(widgetAnnot);
  fieldManager.addField(field);
  //To make sure annotations are drawn properly
  await annotationManager.drawAnnotationsFromList([widgetAnnot]);

  if (widgetAnnot.getAssociatedSignatureAnnotation) {
    await drawAssociatedSignatureAnnotation(widgetAnnot, annotationManager);
  }
};

const drawAssociatedSignatureAnnotation = async (widgetAnnot, annotationManager) => {
  // Assume that all associated signature annotations are 'StampAnnotation's.
  const signatureAnnot = widgetAnnot.getAssociatedSignatureAnnotation();

  if (signatureAnnot) {
    const rotation = widgetAnnot.rotation;

    const adjustedDimensions = await adjustAssociatedSignatureDimensionsToFitWidgetBox(
      widgetAnnot,
      signatureAnnot,
    );
    const adjustedCoordinates = adjustWidgetChildCoordinatesToAlignCenter(
      widgetAnnot,
      adjustedDimensions,
      rotation,
    );
    const rotatedDimensions = getRotatedDimensions(rotation, adjustedDimensions);

    signatureAnnot.Rotation = rotation;
    signatureAnnot.setX(adjustedCoordinates.x);
    signatureAnnot.setY(adjustedCoordinates.y);
    signatureAnnot.setPageNumber(widgetAnnot.getPageNumber());
    signatureAnnot.setWidth(rotatedDimensions.width);
    signatureAnnot.setHeight(rotatedDimensions.height);
    annotationManager.addAnnotation(signatureAnnot);
    await annotationManager.drawAnnotationsFromList([signatureAnnot]);
  }
};

const adjustAssociatedSignatureDimensionsToFitWidgetBox = async (widgetAnnot, signatureAnnot) => {
  return new Promise((resolve, _reject) => {
    let signature = signatureAnnot.dataSource;

    if (signature) {
      const img = new Image();
      img.onload = function () {
        resolve(
          getChildDimensionsToFitParent(widgetAnnot, {
            width: this.width,
            height: this.height,
          }),
        );
      };
      img.src = signature;
    } else {
      resolve({
        width: widgetAnnot.getWidth(),
        height: widgetAnnot.getHeight(),
      });
    }
  });
};

export const prepareSigningObjectForSigning = async ({ ndiSignature, signData, signerInputs }) => {
  const { confirmations, esignatures, digiSignatures } = signData;

  let signatures = [];

  if (ndiSignature && digiSignatures?.length) {
    signatures = digiSignatures.map((s) => ({
      value: ndiSignature,
      subType: s.type,
      type: 'digital',
    }));
  } else if (esignatures?.length) {
    signatures = signerInputs
      .filter((item) => {
        return item.name.startsWith('esignature');
      })
      .map((sig) => ({ ...sig, subType: 'text', type: 'electronic' }))
      .sort(compare);
  }

  const dateFields = signerInputs.filter((item) => item.name.includes('date'));

  const stampFields = signerInputs.filter(
    (item) => item.name.startsWith('stamp') || item.name.startsWith('initials'),
  );

  const textFields = signerInputs.filter((item) => item.name.startsWith('text'));
  const urls = signerInputs.filter((item) => item.name.startsWith('url'));
  const alphanumerics = signerInputs.filter((item) => item.name.startsWith('alphanumeric'));
  const numbers = signerInputs.filter((item) => item.name.startsWith('number'));

  const dropdownList = signerInputs.filter((item) => item.name.startsWith('dropdown-list'));

  const emails = signerInputs.filter((item) => item.name.startsWith('email'));

  const customTexts = [
    ...dateFields,
    ...textFields,
    ...stampFields,
    ...dropdownList,
    ...emails,
    ...urls,
    ...alphanumerics,
    ...numbers,
  ]
    .sort(compare)
    .map((x) => x.value);

  const confirmationFields = signerInputs
    .filter((x) => x.name.startsWith('checkbox'))
    .sort(compare);
  const confirmationsSigned = confirmations
    .map((confirmation, index) => ({
      ...confirmation,
      isChecked: confirmationFields[index]?.value === 'On',
    }))
    .sort(compare);

  return {
    signatures,
    customTexts,
    confirmations: confirmationsSigned,
  };
};

export const getObjectedFilledChecklist = (signerInputs) => {
  let data = {};
  signerInputs.forEach((item) => {
    const { name, value } = item;
    let checked = true;
    if (name.startsWith('checkbox')) {
      checked = value === 'On';
    }
    if (name.startsWith('text') || name.startsWith('custom-date')) {
      checked = !!value;
    }
    data = {
      ...data,
      [name]: checked,
    };
  });
  return data;
};

export const checkIfUserCompletedFillingObjects = (obj, signData) => {
  let disabled = false;
  let totalFilledObjects = 0;

  const { customTexts, esignatures, confirmations } = signData || {};

  for (const key in obj) {
    const splitKey = key.split('-');
    const index = splitKey[splitKey.length - 2];
    if (Object.hasOwn(obj, key)) {
      if (
        !key.startsWith('checkbox') ||
        (key.startsWith('checkbox') && confirmations && confirmations[index]?.isRequired)
      )
        totalFilledObjects++;
    }
    if (!obj[key]) {
      if (
        !key.startsWith('checkbox') ||
        (key.startsWith('checkbox') && confirmations && confirmations[index]?.isRequired)
      )
        disabled = true;
    }
  }

  // no need to check dropdown list data
  const totalSigningObjects =
    customTexts?.length +
    esignatures?.length +
    confirmations?.filter((confirmation) => confirmation.isRequired).length;

  return {
    remainingFields: parseInt(totalSigningObjects - totalFilledObjects),
    documentHasCompleted: totalFilledObjects === totalSigningObjects && !disabled,
  };
};

export const getPdfWithAnnotsAsDataUrl = async (instance) => {
  const { documentViewer, annotationManager } = instance.Core;
  const pdfBlob = await getPdfBlob(documentViewer, annotationManager, true);
  return blobToBase64DataUrl(pdfBlob);
};

export const getPdfDataUrlsToDownload = async (
  documentData,
  pdfTronInstance,
  fileShareKey,
  isSigningSessionComplete,
  isDigitalSigning,
  errorCallBack,
) => {
  let pdfDataUrls = '';

  if (
    (documentData.data.isLastActor && documentData.submitDocumentCompleted) ||
    isDigitalSigning || // The updated PDF will always be in the DSS for digital signing.
    isSigningSessionComplete
  ) {
    try {
      pdfDataUrls = await downloadFileFromDSS(fileShareKey);
    } catch (err) {
      errorCallBack?.();
    }
  } else {
    pdfDataUrls = await getPdfWithAnnotsAsDataUrl(pdfTronInstance);
  }

  return `${
    pdfDataUrls.startsWith('data:application/pdf;base64,')
      ? pdfDataUrls
      : 'data:application/pdf;base64,' + pdfDataUrls
  }`;
};

export const getSortedAnnotationsList = (annotationManager) => {
  const widgetsList = annotationManager
    ?.getAnnotationsList()
    .filter((annot) => annot.Subject === 'Widget');

  if (!widgetsList?.length) return [];

  // Sort annotations by page by page, top to bottom and left to right
  const sortedAnnotations = widgetsList
    .sort((a, b) => {
      if (a.PageNumber === b.PageNumber) {
        const yPosOfA = parseFloat(a.Y);
        const yPosOfB = parseFloat(b.Y);
        const heightOfA = parseFloat(a.Height);
        const heightOfB = parseFloat(b.Height);
        const heightPlusYPosOfA = yPosOfA + heightOfA;
        const heightPlusYPosOfB = yPosOfB + heightOfB;
        const deviation = Math.abs(yPosOfA - yPosOfB);

        if (deviation < 10) {
          return parseFloat(a.X) - parseFloat(b.X);
        } else {
          const higherItem = heightOfA > heightOfB ? heightOfA : heightOfB;
          if (Math.abs(heightPlusYPosOfA - heightPlusYPosOfB) < higherItem) {
            return parseFloat(a.X) - parseFloat(b.X);
          }
        }
        return yPosOfA - yPosOfB;
      }

      return a.PageNumber - b.PageNumber;
    })
    .map((annot) => ({
      id: annot.Id,
      isFilled: false,
    }));

  return sortedAnnotations;
};

export const drawFormDigitalSignatures = async (digitalSignatures, pdfTronInstance) => {
  digitalSignatures.forEach(async (input) => {
    const fieldId = input.fieldId;
    const { WidgetFlags } = pdfTronInstance.Core.Annotations;
    const fieldFlags = new WidgetFlags(null);
    fieldFlags.set(WidgetFlags.READ_ONLY);

    const { widgetAnnot, field } = await createFormSingPassWidgetAnnotation(
      fieldId,
      fieldFlags,
      input.dimensions,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormESignatureInputs = (esignatureInputs, pdfTronInstance) => {
  esignatureInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormESignatureWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormInitialsInputs = (initialsInputs, pdfTronInstance) => {
  initialsInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormInitialsWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormStampInputs = (stampInputs, pdfTronInstance) => {
  stampInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormStampWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormDateInputs = (dateInputs, pdfTronInstance) => {
  dateInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormDateInputWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormTextInputs = (textInputs, pdfTronInstance) => {
  textInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormTextInputWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormCheckboxInputs = (checkboxInputs, color, pdfTronInstance) => {
  checkboxInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;
    const { widgetAnnot, field } = createCheckboxWidgetAnnotation(
      fieldId,
      false,
      color,
      fieldFlags,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const drawFormDropdownInputs = (dropdownInputs, pdfTronInstance) => {
  dropdownInputs.forEach((input) => {
    const fieldFlags = new pdfTronInstance.Core.Annotations.WidgetFlags(null);
    const fieldId = input.fieldId;

    const { widgetAnnot, field } = createFormDropdownWidgetAnnotation(
      fieldId,
      fieldFlags,
      input,
      pdfTronInstance,
    );

    drawWidgetAnnotation(field, widgetAnnot, input.placement, input.dimensions, pdfTronInstance);
  });
};

export const generateFieldId = (type, index, signerIndex) => {
  return `${type}-${index}-${signerIndex}`;
};

export const getAnnotInfoFromFieldId = (fieldId) => {
  const idSegments = fieldId?.split('-');
  const index = parseInt(idSegments?.[idSegments.length - 2]);
  const signerIndex = parseInt(idSegments?.[idSegments.length - 1]);

  /*
   * 'type' is the field type
   * 'index' is the position of the field within the same type, for the same signer (0-indexed)
   * 'signerIndex' is the signer's index in the business process (0-indexed)
   * E.g. 'dropdown-list-1-0' means that the field is the second 'dropdown-list' field for the first signer
   */
  return {
    type: idSegments?.slice(0, idSegments.length - 2).join('-'),
    index,
    signerIndex,
  };
};

export const getFieldIdFromAssociatedAnnotId = (annotId) => {
  const index = annotId.indexOf(PDFTRON_ASSOCIATED_VALUE_PREFIX);
  return index !== -1 ? annotId.slice(index + PDFTRON_ASSOCIATED_VALUE_PREFIX.length) : null;
};

export const safeGetAssociatedSignatureAnnotation = (widgetAnnot) => {
  return widgetAnnot?.getAssociatedSignatureAnnotation?.();
};
