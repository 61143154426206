import React from 'react';
import verified from '../../assets/docverified.png';
import notverfied from '../../assets/docnotverified.png';
import processing from '../../assets/verifingdocument.png';
import { Button } from '@mui/material';
import './SigningRequest.css';

import { useTranslation } from 'react-i18next';

export default function ModalData(props) {
  const { t } = useTranslation();

  switch (props.status) {
    case 0: {
      //Confirming
      return (
        <div className="modal-block center">
          <img src={processing} className="modal-image" alt="processing document" />
          <h3>{t('ModalDataCaseZeroHeader')}</h3>
          <span className="grey-t">{t('ModalDataCaseZeroSubHeader')}</span>
          <div className="bottom-right-buttons">
            <Button variant="outlined" onClick={props.close}>
              {t('ModalDataBackButton')}
            </Button>
          </div>
        </div>
      );
    }
    case 1: {
      //verified
      return (
        <div className="modal-block center">
          <img src={verified} className="modal-image" alt="verified" />
          <h3>{t('ModalDataCaseOneHeader')}</h3>
          <center className="grey-t">{t('ModalDataCaseOneSubHeader')}</center>
          <div className="bottom-right-buttons"></div>
        </div>
      );
    }
    case -1: {
      //failed
      return (
        <div className="modal-block center">
          <img src={notverfied} className="modal-image" alt="Not Verified" />
          <h3>{t('ModalDataCaseNegOneHeader')}</h3>
          <span className="grey-t">{t('ModalDataCaseNegOneSubHeader')}</span>
          <div className="bottom-right-buttons">
            <Button variant="outlined" onClick={props.close}>
              {t('ModalDataBackButton')}
            </Button>
          </div>
        </div>
      );
    }

    default:
      return;
  }
}
