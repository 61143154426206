import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import allreducers from './reducer/reducer_combiner';
import { createStore, applyMiddleware } from 'redux';

export const createNewStore = () => {
  const middleware = [thunk];
  return createStore(allreducers, composeWithDevTools(applyMiddleware(...middleware)));
};

const store = createNewStore();
export default store;
