import React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../UI/Header';
import './DocumentVerificationSuccess.css';

const RejectDocument = () => {
  const { t } = useTranslation();

  const reRouteDedoco = () => {
    window.location = 'https://dedoco.com/';
  };

  return (
    <Box
      justifyContent="center"
      style={{
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        height: 'calc(100vh - 64px)',
      }}
    >
      <Header />
      <Grid
        container
        style={{
          background: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Container style={{ backgroundColor: 'white' }}>
          <img src="/DocumentDecline.png" alt="Decline Done" />
          <h1 className="document-decline-title">{t('SignatureDocumentDecline')}</h1>
          <p className="document-decline-description">{t('SignatureDocumentDeclineDescription')}</p>
          <Grid item>
            <Button
              style={{
                backgroundColor: '#0D41BF',
                fontSize: 16,
                color: '#ffffff',
                padding: '18px 40px',
                marginTop: 24,
              }}
              variant="contained"
              onClick={reRouteDedoco}
            >
              {t('SignatureDocumentDeclineTryDedoco')}
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default RejectDocument;
