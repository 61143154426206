import {
  RejectModalRoot,
  RejectModalTitle,
  RejectModalContent,
  RejectModalContentTitle,
  RejectModalContentMessage,
  RejectModalReasonInput,
  RejectModalActions,
  RejectCloseIcon,
  CloseButton,
  RejectButton,
  CancelButton,
} from './RejectDocumentModal.styles';
import { useTranslation } from 'react-i18next';

const RejectDocumentModal = ({ open, onReject, onClose, documentName, onInputChange }) => {
  const { t } = useTranslation();

  return (
    <RejectModalRoot open={open}>
      <RejectModalTitle>
        {t('RejectDocumentModalHeaderPrefix')} {documentName}?
        <CloseButton onClick={onClose}>
          <RejectCloseIcon />
        </CloseButton>
      </RejectModalTitle>
      <RejectModalContent>
        <RejectModalContentTitle>{t('RejectDocumentModalWarning')}</RejectModalContentTitle>
        <RejectModalContentMessage>{t('RejectDocumentModalInstruction')}</RejectModalContentMessage>
        <RejectModalReasonInput
          multiline
          rows={4}
          variant="outlined"
          placeholder={t('RejectDocumentModalReasonPlaceholder')}
          onChange={onInputChange}
        />
      </RejectModalContent>
      <RejectModalActions>
        <RejectButton onClick={onReject}>{t('ConfirmRejectDocumentModalButton')}</RejectButton>
        <CancelButton onClick={onClose}>{t('RejectDocumentModalCancelButton')}</CancelButton>
      </RejectModalActions>
    </RejectModalRoot>
  );
};

export default RejectDocumentModal;
