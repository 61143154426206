import { useEffect, useState } from 'react';
import { useWebexContext } from '../../contexts/useWebexContext';
import useCountDown from '../../hooks/useCountDown';
import { WebexEvents } from '../../hooks/useWebexSigning/constants';
import ClaimRole from './ClaimRole';
import ConfirmIdentity from './ConfirmIdentity';
import { WebexSteps } from './constants';
import EnterSigningLink from './EnterSigningLink';
import LoadingPage from './LoadingPage';
import WaitingPage from './WaitingPage';
import './Webex.css';

const Content = (props) => {
  const { step, setStep, setDocumentId } = props;

  const {
    recipients,
    createRoom,
    startAction,
    confirmIdentity,
    isMeetingHost,
    userIdentitySelected,
    setUserIdentity,
    addEventListener,
  } = useWebexContext();
  const { countDownTime, setCountDownTime } = useCountDown();

  const [hasSelected, setHasSelected] = useState('');

  useEffect(() => {
    addEventListener(WebexEvents.NEXT_ACTION, (data, userIdentity) => {
      const confirmedSigners = data.confirmedIdentityActors;
      let hasConfirmed = false;
      if (confirmedSigners) {
        hasConfirmed = confirmedSigners.find((signer) => signer.email === userIdentity?.email);
      }
      if (data?.actor) {
        if (
          !userIdentity ||
          (userIdentity?.role !== 'observer' && userIdentity?.email !== data?.actor?.email)
        )
          return;
        setStep(WebexSteps.ENTER_SIGNING_LINK);
      } else {
        if (userIdentity?.role !== 'observer' && !hasConfirmed) return;
        setStep(WebexSteps.ENTER_SIGNING_LINK);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnterProjectProperty = (data) => {
    const handleEnterSigningLink = (link) => {
      startAction(link);
    };

    const handleEnterProjectId = (projectId) => {
      createRoom(projectId);
      setDocumentId(projectId);
    };

    return userIdentitySelected ? handleEnterSigningLink(data) : handleEnterProjectId(data);
  };

  const handleClaimRole = () => {
    if (!hasSelected) return;

    const [recipientRole, index] = hasSelected.split('-');

    let userIdentity;

    if (recipientRole === 'signer') {
      userIdentity = { ...recipients?.signers[index], role: recipientRole };
    } else if (recipientRole === 'approver') {
      userIdentity = { ...recipients?.approvers[index], role: recipientRole };
    } else if (recipientRole === 'observer') {
      userIdentity = { ...recipients?.observers[index], role: recipientRole };
    }

    if (!userIdentity) return;

    setUserIdentity(userIdentity);
    setStep(WebexSteps.CONFIRM_IDENTITY);
  };

  const handleConfirmIdentity = () => {
    confirmIdentity();
  };

  useEffect(() => {
    if (userIdentitySelected?.hasConfirmed) {
      setStep(WebexSteps.WAIT_FOR_SIGNING);
    }
  }, [userIdentitySelected, setStep]);

  switch (step) {
    case WebexSteps.LOADING_PAGE:
      return <LoadingPage />;
    case WebexSteps.CLAIM_ROLE:
      return (
        <ClaimRole
          hasSelected={hasSelected}
          setHasSelected={setHasSelected}
          handleClaimRole={handleClaimRole}
          userIdentitySelected={userIdentitySelected}
        />
      );
    case WebexSteps.CONFIRM_IDENTITY:
      return (
        <ConfirmIdentity
          handleConfirmIdentity={handleConfirmIdentity}
          setStep={setStep}
          countDownTime={countDownTime}
          setCountDownTime={setCountDownTime}
        />
      );
    case WebexSteps.WAIT_FOR_SIGNING:
      return <WaitingPage setStep={setStep} />;
    case WebexSteps.ENTER_SIGNING_LINK:
      return (
        <EnterSigningLink
          handleConfirm={handleEnterProjectProperty}
          isEnteringProjectId={Boolean(isMeetingHost && !userIdentitySelected)}
        />
      );
    default:
      return;
  }
};

export default Content;
