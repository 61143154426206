import { styled } from '@mui/material';

const PREFIX = 'DatePicker';

export const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-back-button`,
  backButtonText: `${PREFIX}-back-button-text`,
  customDateTitle: `${PREFIX}-custom-date-title`,
  calender: `${PREFIX}-calender`,
};

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(6, 4, 4, 4),
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
  height: 'auto',
  width: '350px',
  position: 'relative',
  boxSizing: 'border-box',
  display: 'grid',
  [`& .${classes.calender}`]: {
    width: '100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.supporting.extraLight,
    borderRadius: '6px',
    '& .MuiPickersCalendarHeader-root': {
      margin: 0,
    },
  },
}));
