import { Alert, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDedocoSigning } from '../../../hooks/useDedocoSigning/useDedocoSigning.new';
import useWindowSize from '../../../hooks/useWindowSize';
import { isCustomDate } from '../../../utils/dateWidget';
import { getOtpTarget } from '../../../utils/otp';
import {
  isAlphanumeric,
  isCustomText,
  isNumber,
  isUrl,
  numberValidation,
} from '../../../utils/textWidget';
import ActionButtons from '../Sidebar/ActionButtons';
import CustomTextBox from '../Sidebar/CustomTextBox';
import CustomDatePicker from '../Sidebar/DatePicker';
import DedocoHashVerifyBox from '../Sidebar/DedocoHashVerifyBox';
import FieldList from '../Sidebar/FieldList';
import InfoSidebar from '../Sidebar/InfoSidebar';
import OtpCheck from './OTPCheck';
import { classes, Root } from '../Sidebar/Sidebar.style';
import SignatureSigning from '../Sidebar/SignatureSigning';
import TermsAndCondition from './TermsAndCondition';
import VerifyBox from '../Sidebar/VerifyBox';
import { MAX_WIDTH, StyledStack } from './Sidebar.style';
import { shouldRenderSigningPad } from '../../../utils/renderCondition';
import useDedocoVideoSigning from '../../../hooks/useDedocoVideoSigning';
import * as utils from './utils';
import { useCallback, useEffect, useMemo } from 'react';
import WaitingInfo from './WaitingInfo';
import HostChooseNextActor from './HostChooseNextActor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFolderName } from '../../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import DocumentStatusPanel from './DocumentStatusPanel';
import TryFreeSection from './TryFreeSection';
import useRecipientRole from '../../../hooks/useRecipientRole';
import SidebarDownloadButton from '../Sidebar/SidebarDownloadButton';
import {
  getTwilioSelector,
  startMeeting as _startMeeting,
} from '../../../redux/slices/twilioSlice/twilioSlice';
import useTwilio from '../../../hooks/useTwilio';
import SigningCompletionEndCall from './SigningCompletionEndCall';
import { LoadingButton } from '@mui/lab';
import { isEmail } from '../../../utils/emailWidget';
import EmailBox from '../Sidebar/EmailBox';
import { validateAlphanumeric, validateUrl } from '../../../utils/validation';
import { isDropdownList } from '../../../utils/dropdownListWidget';
import { DropdownList } from '../Sidebar/DropdownList';
import { getAnnotInfoFromFieldId } from '../../../utils/pdftron';
import DeMailTouchPointBox from '../Sidebar/InfoSidebar/DeMailTouchPoint';

function Sidebar({
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
}) {
  const {
    isCallEndBeforeSigningComplete,
    isMeetingHost,
    meetingStart,
    isMeetingStarted,
    getRoom,
    isSigningSessionComplete,
  } = useDedocoVideoSigning();
  const { currentField, documentToSign, currentParticipant, projectSigners } = useDedocoSigning();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isApprover, isSigner, RecipientRoles, currentUser } = useRecipientRole(true);

  const { startOrJoinTwilioMeeting, isConnecting } = useTwilio();
  const { isLoading } = useSelector(getTwilioSelector);

  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const room = getRoom();

  const recipientId = documentToSign?.data?.recipientId;

  useEffect(() => {
    if (!documentToSign?.folderName) {
      dispatch(getFolderName(id));
    }
  }, [dispatch, documentToSign?.folderName, id]);

  const {
    shouldRenderTnC,
    shouldRenderOTP,
    shouldRenderCompletion,
    shouldRenderRejectedPage,
    shouldRenderExpiryPage,
    shouldRenderApproval,
  } = useMemo(() => {
    const utilsWithArgs = { ...utils };
    Object.keys(utils).forEach((key) => {
      utilsWithArgs[key] = utils[key].bind(null, documentToSign);
    });
    return utilsWithArgs;
  }, [documentToSign]);

  const nextActorIsMe = useMemo(() => {
    const { nextActor } = room;
    if (nextActor) {
      return nextActor.userId === recipientId;
    }
    return false;
  }, [recipientId, room]);

  const startMeeting = useCallback(() => {
    if (documentToSign?.data?.recipientEmail && recipientId) {
      const role = isSigner
        ? RecipientRoles.SIGNER
        : isApprover
        ? RecipientRoles.APPROVER
        : RecipientRoles.OBSERVER;
      dispatch(
        _startMeeting({
          documentId: id,
          name: documentToSign.data.recipientName,
          email: documentToSign.data.recipientEmail,
          userId: recipientId,
          role,
        }),
      )
        .unwrap()
        .then(({ roomToken }) => {
          // twilio start meeting
          startOrJoinTwilioMeeting(roomToken, id).then(() => {
            // emit websocket event
            meetingStart(id, recipientId);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    RecipientRoles.APPROVER,
    RecipientRoles.OBSERVER,
    RecipientRoles.SIGNER,
    dispatch,
    documentToSign?.data?.recipientEmail,
    documentToSign?.data?.recipientName,
    recipientId,
    id,
    isApprover,
    isSigner,
    meetingStart,
    startOrJoinTwilioMeeting,
  ]);

  const toShowSidebarCompletion = useCallback(() => {
    const completedProgress =
      shouldRenderCompletion() ||
      shouldRenderRejectedPage() ||
      shouldRenderExpiryPage() ||
      shouldRenderApproval();
    return completedProgress;
  }, [
    shouldRenderApproval,
    shouldRenderCompletion,
    shouldRenderExpiryPage,
    shouldRenderRejectedPage,
  ]);

  const styles = useMemo(() => {
    if (windowSize.width > 600) {
      if (toShowSidebarCompletion())
        return {
          width: '50%',
          backgroundColor: 'transparent',
        };
      return {
        width: MAX_WIDTH,
      };
    }

    return { display: 'none' };
  }, [toShowSidebarCompletion, windowSize.width]);

  const currentFieldId = currentField.fieldId;

  if (shouldRenderExpiryPage()) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <InfoSidebar
          title={t('DocumentExpiredTitle')}
          body={t('ExpiredDocumentBody')}
          handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
          showDownloadButton={false}
        />
      </Root>
    );
  }

  if (
    documentToSign?.document?.document_share_method === 'manual' &&
    !documentToSign.isDHCVerified
  ) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        {/* TODO change to use newDedocoSigning */}
        <DedocoHashVerifyBox />
      </Root>
    );
  }

  if (shouldRenderOTP()) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <OtpCheck otpTarget={getOtpTarget(documentToSign)} />
      </Root>
    );
  }

  if (shouldRenderTnC()) {
    if (isMeetingHost) {
      return (
        <Root sx={styles} windowSize={windowSize}>
          <TermsAndCondition
            fullWidth
            title={`${t('StartMeeting')}?`}
            buttonText={t('StartMeeting')}
            onProceed={startMeeting}
            isLoading={isLoading || isConnecting}
          />
        </Root>
      );
    }
    return (
      <Root sx={styles} windowSize={windowSize}>
        <TermsAndCondition />
      </Root>
    );
  }

  if (shouldRenderRejectedPage()) {
    return (
      <StyledStack windowSize={windowSize}>
        <DocumentStatusPanel
          title={t('RejectDocumentTitle')}
          content={t('RejectDocumentContent')}
        />
        <TryFreeSection sx={(theme) => ({ marginTop: theme.spacing(6) })} />
      </StyledStack>
    );
  }

  if (!isMeetingStarted) {
    if (isMeetingHost) {
      if (isCallEndBeforeSigningComplete) {
        return (
          <StyledStack windowSize={windowSize}>
            <Alert
              severity="info"
              sx={(theme) => ({
                marginBottom: theme.spacing(6),
                border: `1px solid ${theme.palette.info.main}`,
              })}
            >
              {documentToSign.isTimeoutEndMeeting
                ? t('MeetingHasBeenEnded')
                : t('YouHaveEndedTheMeeting')}
            </Alert>
            <DocumentStatusPanel
              title={t('SigningProcessIncompleteTitle')}
              content={[
                t('SigningProcessIncompleteContentP1'),
                t('SigningProcessIncompleteContentP2'),
                t('SigningProcessIncompleteContentP3'),
              ]}
              bottomButton={
                <LoadingButton
                  variant="contained"
                  onClick={startMeeting}
                  loading={isLoading || isConnecting}
                  sx={(theme) => ({ marginTop: theme.spacing(6) })}
                >
                  {t('StartMeetingNow')}
                </LoadingButton>
              }
            />
            <TryFreeSection sx={(theme) => ({ marginTop: theme.spacing(6) })} />
          </StyledStack>
        );
      } else if (isSigningSessionComplete()) {
        return (
          <SigningCompletionEndCall
            handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
            windowSize={windowSize}
            isMeetingHost={isMeetingHost}
          />
        );
      }

      return (
        <Root sx={styles} windowSize={windowSize}>
          <TermsAndCondition
            fullWidth
            title={`${t('StartMeeting')}?`}
            buttonText={t('StartMeeting')}
            onProceed={startMeeting}
            isLoading={isLoading || isConnecting}
          />
        </Root>
      );
    } else {
      if (isSigningSessionComplete()) {
        return (
          <SigningCompletionEndCall
            handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
            windowSize={windowSize}
            isMeetingHost={isMeetingHost}
          />
        );
      }
      return (
        <Root sx={styles} windowSize={windowSize}>
          <WaitingInfo title={documentToSign.folderName} content={t('WaitingHostStartMeeting')} />
        </Root>
      );
    }
  }

  if (shouldRenderCompletion() || shouldRenderApproval()) {
    return (
      <StyledStack windowSize={windowSize} spacing={6}>
        {isMeetingHost && <HostChooseNextActor />}
        <DocumentStatusPanel
          title={t('documentSubmitted')}
          sx={(theme) => ({ marginTop: theme.spacing(6) })}
          content={[
            t('CompletionSuccessTextReceiveEmail'),
            t('CompletionSuccessTextDownloadExpiry'),
            t('CompletionSuccessTextDownload'),
          ]}
          bottomButton={
            currentUser?.is_downloadable && (
              <SidebarDownloadButton
                useNewDedocoSigning
                sx={(theme) => ({ width: '50%', marginTop: theme.spacing(6) })}
                handleDownloadPdf={handleDownloadPdf}
              />
            )
          }
        />
        <DeMailTouchPointBox backgroundColor="white" compactMode />
        <TryFreeSection />
      </StyledStack>
    );
  }

  if (!nextActorIsMe) {
    if (isMeetingHost) {
      return (
        <StyledStack windowSize={windowSize}>
          <VerifyBox />
          <HostChooseNextActor />
          <TryFreeSection sx={(theme) => ({ marginTop: theme.spacing(6) })} />
        </StyledStack>
      );
    }
    if (currentParticipant.role === RecipientRoles.OBSERVER) {
      return (
        <StyledStack windowSize={windowSize} spacing={6}>
          <VerifyBox newDedocoSigning />
          <DeMailTouchPointBox backgroundColor="white" compactMode />
          <TryFreeSection />
        </StyledStack>
      );
    }
    return (
      <Root sx={styles} windowSize={windowSize}>
        <VerifyBox newDedocoSigning />
        <WaitingInfo
          title={t('dVideoSignWaitingTurnToSignTitle')}
          content={t('dVideoSignWaitingTurnToSignContent')}
        />
      </Root>
    );
  }

  if (!(currentField.isFilling && currentField.isUsingSidebar)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <Stack sx={{ overflow: 'auto' }}>
          <Grid
            container
            item
            flexDirection="column"
            className={classes.verifyRequiredFieldsContainer}
          >
            <Grid item>
              <VerifyBox newDedocoSigning />
            </Grid>
            <Grid item>
              <FieldList useNewDedocoSigning />
            </Grid>
          </Grid>
          <ActionButtons
            handleSignDoc={handleSignDoc}
            handleApproveDoc={handleApproveDoc}
            handleRejectDoc={handleRejectDoc}
            isDisabled={disableSubmitButton}
            handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
            newDedocoSigning
          />
        </Stack>
      </Root>
    );
  }

  if (shouldRenderSigningPad(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <SignatureSigning newDedocoSigning />
      </Root>
    );
  } else if (isCustomDate(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <CustomDatePicker newDedocoSigning />
      </Root>
    );
  } else if (isCustomText(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <CustomTextBox newDedocoSigning />
      </Root>
    );
  } else if (isEmail(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <EmailBox newDedocoSigning />
      </Root>
    );
  } else if (isUrl(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <CustomTextBox
          validationFunc={validateUrl}
          errorMessage={t('EnterValidUrl')}
          newDedocoSigning
        />
      </Root>
    );
  } else if (isAlphanumeric(currentFieldId)) {
    return (
      <Root sx={styles} windowSize={windowSize}>
        <CustomTextBox
          validationFunc={validateAlphanumeric}
          errorMessage={t('NoSpecialCharacters')}
          newDedocoSigning
        />
      </Root>
    );
  } else if (isNumber(currentFieldId)) {
    const numberValidationWrapper = (value, type) =>
      numberValidation(value, type, currentField.fieldId, projectSigners, t);

    return (
      <Root sx={styles} windowSize={windowSize}>
        <CustomTextBox
          validationFunc={(value) => numberValidationWrapper(value)}
          errorMessage={t('EnterValidNumber')}
          errorMessageFunc={(value) => numberValidationWrapper(value, 'error')}
          newDedocoSigning
        />
      </Root>
    );
  } else if (isDropdownList(currentFieldId)) {
    const annotInfo = getAnnotInfoFromFieldId(currentFieldId);
    const dropdownInfo = projectSigners[annotInfo.signerIndex].customTexts[annotInfo.index];
    return (
      <Root sx={styles} windowSize={windowSize}>
        <DropdownList label={dropdownInfo.label} options={dropdownInfo.options} newDedocoSigning />
      </Root>
    );
  }
}

export default Sidebar;
