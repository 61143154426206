import { useState } from 'react';
import useRecipientRole from './useRecipientRole';

/* Oct 2022: Deprecated, will remove when we have fully completed the UI revamp. */
function useOldPdfTron() {
  const [instance, setInstance] = useState();
  const [startDocument, setStartDocument] = useState(false);
  const [signatureList, setSignatureList] = useState([]);
  const [step, setStep] = useState(0);
  const [dataFromPdf, setDataFromPdf] = useState([]);
  const [checkedFields, setCheckedFields] = useState({});

  const { isSigner } = useRecipientRole();
  let createArrowTimeout = null;

  const jumpToAnnotation = (annotId) => {
    if (instance) {
      const { annotationManager, documentViewer } = instance.Core;
      const { iframeWindow } = instance.UI;
      const focusArrow = 'focusArrow';

      // begin document
      if (!startDocument) {
        setStartDocument(true);
      }

      // remove click component
      const elementFillAnnot = iframeWindow.document.getElementById(focusArrow);

      if (elementFillAnnot) {
        const parentFillAnnot = elementFillAnnot.parentElement;
        parentFillAnnot.style.zIndex = '35';
        elementFillAnnot.remove();
      }

      // create click component
      if (createArrowTimeout) {
        clearTimeout(createArrowTimeout);
      }

      createArrowTimeout = setTimeout(() => {
        //instance does not load with the document users are working with.
        //There might be cases where the document is not loaded yet.
        const workingDocument = documentViewer.getDocument();

        if (workingDocument) {
          const annotation = annotationManager.getAnnotationById(annotId);
          const rotation = workingDocument.getPageRotation(annotation.PageNumber);
          const wrapperDivWidth = 50;

          const elementAnnot = iframeWindow.document.getElementById(annotId);
          if (elementAnnot) {
            elementAnnot.style.zIndex = '1000';
            let cssSideToCheck = 'left';

            if (rotation === 90) {
              cssSideToCheck = 'bottom';
            } else if (rotation === 180) {
              cssSideToCheck = 'right';
            } else if (rotation === 270) {
              cssSideToCheck = 'top';
            }

            const spaceOnTheLeft = Number(elementAnnot['style'][cssSideToCheck].replace('px', ''));
            const changeArrow = spaceOnTheLeft < wrapperDivWidth;

            const wrapperDiv = document.createElement('div');
            wrapperDiv.id = focusArrow;
            wrapperDiv.style.position = 'absolute';
            wrapperDiv.style.width = `${wrapperDivWidth}px`;
            wrapperDiv.style.height = '26px';
            if (changeArrow) {
              wrapperDiv.style.right = `-${wrapperDivWidth}px`;
            } else {
              wrapperDiv.style.left = `-${wrapperDivWidth}px`;
            }
            wrapperDiv.style.top = '50%';
            wrapperDiv.style.display = 'flex';
            wrapperDiv.style.transform = 'translateY(-50%)';

            const textDiv = document.createElement('div');
            const clickText = document.createTextNode('CLICK');
            textDiv.appendChild(clickText);
            textDiv.style.fontSize = '10px';
            textDiv.style.fontWeight = '600';
            textDiv.style.color = '#4F4F4F';
            textDiv.style.width = '100%';
            textDiv.style.height = '100%';
            textDiv.style.display = 'flex';
            textDiv.style.justifyContent = 'center';
            textDiv.style.alignItems = 'center';
            textDiv.style.backgroundColor = '#F2D74C';

            const arrowDiv = document.createElement('div');
            arrowDiv.style.width = '15px';
            arrowDiv.style.borderTop = '13px solid transparent';
            arrowDiv.style.borderBottom = '13px solid transparent';

            if (changeArrow) {
              arrowDiv.style.borderRight = '13px solid #F2D74C';
              wrapperDiv.appendChild(arrowDiv);
              wrapperDiv.appendChild(textDiv);
            } else {
              arrowDiv.style.borderLeft = '13px solid #F2D74C';
              wrapperDiv.appendChild(textDiv);
              wrapperDiv.appendChild(arrowDiv);
            }

            // Create ResizeObserver object to resize Click component
            const resizeObserver = new ResizeObserver(() => {
              const zoomLevel = instance.UI.getZoomLevel();

              wrapperDiv.style.width = `${wrapperDivWidth * zoomLevel}px`;
              wrapperDiv.style.height = `${26 * zoomLevel}px`;

              if (changeArrow) {
                wrapperDiv.style.right = `${-Math.abs(wrapperDivWidth * zoomLevel)}px`;
              } else {
                wrapperDiv.style.left = `${-Math.abs(wrapperDivWidth * zoomLevel)}px`;
              }

              const borderSize = `${13 * zoomLevel}px`;
              arrowDiv.style.borderTop = `${borderSize} solid transparent`;
              arrowDiv.style.borderBottom = `${borderSize} solid transparent`;
              if (changeArrow) {
                arrowDiv.style.borderRight = `${borderSize} solid #F2D74C`;
              } else {
                arrowDiv.style.borderLeft = `${borderSize} solid #F2D74C`;
              }

              textDiv.style.fontSize = `${10 * zoomLevel}px`;
            });
            // Observe document element resizing
            resizeObserver.observe(iframeWindow.document.querySelector('.document'));

            elementAnnot.appendChild(wrapperDiv);
          }
        }
      }, 100);

      let annots = annotationManager.getAnnotationsList();
      let annot = annots.find((x) => x.Id === annotId);
      annot && annotationManager.jumpToAnnotation(annot);
    }
  };

  const handleBackStepSignature = () => {
    const prevStep = step - 1;
    if (prevStep > -1) {
      handleMoveStep(prevStep);
    } else {
      const lastSignatureList = signatureList.length - 1;
      handleMoveStep(lastSignatureList);
    }
  };

  const handleNextStepSignature = () => {
    const nextStep = step + 1;
    if (nextStep < signatureList.length) {
      handleMoveStep(nextStep);
    } else {
      handleMoveStep(0);
    }
  };

  const handleMoveStep = (indexStep) => {
    jumpToAnnotation(signatureList[indexStep].jump);
    setStep(indexStep);
  };

  const handleStartDocument = () => {
    if (instance) {
      setStartDocument(true);
      if (isSigner) {
        handleMoveStep(0);
      }
    }
  };

  const loadDocumentToWebViewer = (blob, callback) => {
    // This function enforces removal of listener after use.
    // This reduces the chance of bugs due to forgotten listener and make it easier to manage when to run the listener.
    if (!instance) {
      return;
    }

    const { documentViewer } = instance.Core;
    const { loadDocument } = instance.UI;

    // To keep code simple, async/await is used for all callbacks.
    // If callback is not a promise, it will be implicitly convert to a resolved promise.
    // Take note: This means that the documentLoadHandler will always be added to the microtask queue and processed by the event loop when called.
    //  This may or may not cause any bugs if the implementation of PDFTron documentViewer event handling changes in the future.
    const documentLoadHandler = async () => {
      await callback?.();
      documentViewer.removeEventListener('documentLoaded', documentLoadHandler);
    };

    documentViewer.addEventListener('documentLoaded', documentLoadHandler);

    return loadDocument(blob);
  };

  const getPdfTronInstance = () => {
    return {
      licenseKey:
        'Dedoco Pte Ltd(dedoco.com):OEM:DeDoco::B+:AMS(20221228):24DC05B3075AD4F38B313BC9B263192F4E6F2F9F839D638D041D595C5020FE4282B6F5C7',
      path: '/webviewer/lib',
      fullAPI: true,
      css: '/assets/css/pdfWebViewer.css',
      disabledElements: [
        'ribbons',
        'toggleNotesButton',
        'searchButton',
        'rubberStampToolGroupButton',
        'stampToolGroupButton',
        'toolsHeader',
        'linkButton',
        'annotationCommentButton',
        'downloadButton',
        'leftPanelButton',
        'moreButton',
        'toolsOverlay',
        'contextMenuPopup',
        'annotationStyleEditButton',
        'textPopup',
      ],
    };
  };

  return {
    instance,
    setInstance,
    startDocument,
    signatureList,
    dataFromPdf,
    setDataFromPdf,
    checkedFields,
    setCheckedFields,
    setSignatureList,
    jumpToAnnotation,
    handleBackStepSignature,
    handleNextStepSignature,
    handleStartDocument,
    loadDocumentToWebViewer,
    getPdfTronInstance,
  };
}

export default useOldPdfTron;
