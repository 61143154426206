import { useEffect, useState } from 'react';
import CheckboxIcon from '../../assets/check.svg';
import React from 'react';

const CustomCheckBox = (props) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(props.checked);
  }, [props]);

  const handleClick = () => {
    typeof props.onChange === 'function' && props.onChange();
  };

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: addAlpha(props.color, 0.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="checkbox"
      onClick={handleClick}
    >
      <div
        style={{
          ...props.style,
          cursor: 'pointer',
          borderRadius: 1,
          display: 'flex',
        }}
      >
        {checked && (
          <img style={{ width: '100%', height: '100%' }} src={CheckboxIcon} alt="check" />
        )}
      </div>
    </div>
  );
};

export default CustomCheckBox;
