const svgToMiniDataURI = require('mini-svg-data-uri');

export const getColoredPenToolIcon = (color) => {
  return svgToMiniDataURI(`
		<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" width="32" height="32" rx="16" fill="${color}" fill-opacity="0.2"/>
			<path d="M22.3333 16L16.5 10.1667L19 7.66667L24.8333 13.5L22.3333 16Z" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M17.3332 10.9997L11.0832 12.2497L8.1665 24.333L20.2498 21.4163L21.4998 15.1663L17.3332 10.9997Z" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8.1665 24.333L14.4882 18.0113" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M17.3333 16.8333C17.3333 15.9129 16.5871 15.1667 15.6667 15.1667C14.7462 15.1667 14 15.9129 14 16.8333C14 17.7538 14.7462 18.5 15.6667 18.5C16.5871 18.5 17.3333 17.7538 17.3333 16.8333Z" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	`);
};

export const getCustomTextIcon = (color, type) => {
  if (type === 'number') {
    return svgToMiniDataURI(`
	<svg width="32" height="32" viewBox="0 0 32 32" fill="${color}" xmlns="http://www.w3.org/2000/svg">
	<rect width="32" height="32" rx="16" fill="${color}" fill-opacity="0.2"/>
	<path d="M9.33337 13.5L22.6667 13.5M9.33337 18.5H22.6667M14.3334 8.5L12.6667 23.5M19.3334 8.5L17.6667 23.5" stroke="${color}" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	`);
  }

  return svgToMiniDataURI(`
		<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" width="32" height="32" rx="16" fill="${color}" fill-opacity="0.2"/>
		<path d="M9.8335 11.8335V9.3335H23.1668V11.8335M14.0002 22.6668H19.0002M16.5002 9.3335V22.6668" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	`);
};

export const getEmailIcon = (color) => {
  return svgToMiniDataURI(`
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="32" height="32" rx="16" fill="${color}" fill-opacity="0.2"/>
	<path d="M24.3334 10.9997C24.3334 10.083 23.5834 9.33301 22.6667 9.33301L9.33341 9.33301C8.41675 9.33301 7.66675 10.083 7.66675 10.9997M24.3334 10.9997L24.3334 20.9997C24.3334 21.9163 23.5834 22.6663 22.6667 22.6663H9.33341C8.41675 22.6663 7.66675 21.9163 7.66675 20.9997L7.66675 10.9997M24.3334 10.9997L16.0001 16.833L7.66675 10.9997" stroke="${color}" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	`);
};

export const getCalendarIcon = (color) => {
  return svgToMiniDataURI(`
		<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" width="32" height="32" rx="16" fill="${color}" fill-opacity="0.2"/>
		<path d="M21.1667 10.6666H11.8333C11.097 10.6666 10.5 11.2636 10.5 12V21.3333C10.5 22.0697 11.097 22.6666 11.8333 22.6666H21.1667C21.903 22.6666 22.5 22.0697 22.5 21.3333V12C22.5 11.2636 21.903 10.6666 21.1667 10.6666Z" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M19.167 9.33337V12" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M13.833 9.33337V12" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M10.5 14.6666H22.5" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	`);
};
