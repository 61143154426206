import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  joinMeeting,
  startMeeting,
  joinPublicMeeting,
  startPublicMeeting,
} from '../../../redux/actions/meetingFunctions';
import { ROOM_STARTED } from '../../../utils/GlobalConstant';
import './JoinMeeting.css';

function JoinMeeting({
  meeting,
  participant,
  documentId,
  conversation,
  isMeetingHost,
  bpId,
  isOpenApi,
}) {
  const { t } = useTranslation();
  const isStartingMeeting = useSelector((state) => state.documenttosign?.isStartingMeeting);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMeetingHost || meeting?.isActive) {
      if (isOpenApi)
        dispatch(
          joinPublicMeeting(bpId, {
            name: participant.name,
            email: participant.email,
          }),
        );
      else
        dispatch(
          joinMeeting({
            document_id: documentId,
            name: participant.name,
            email: participant.email,
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeetingHost, meeting]);

  const onJoinMeeting = () => {
    if (conversation) {
      if (meeting.isActive || !isMeetingHost) {
        if (isOpenApi)
          dispatch(
            joinPublicMeeting(bpId, {
              name: participant.name,
              email: participant.email,
            }),
          );
        else
          dispatch(
            joinMeeting({
              document_id: documentId,
              name: participant.name,
              email: participant.email,
            }),
          );
      } else {
        if (isOpenApi)
          dispatch(
            startPublicMeeting(bpId, {
              name: participant.name,
              email: participant.email,
              role: participant.role,
            }),
          )
            .then(() => {
              conversation.sendMessage(ROOM_STARTED);
            })
            .catch((e) => console.log(e));
        else
          dispatch(
            startMeeting({
              document_id: documentId,
              name: participant.name,
              email: participant.email,
              role: participant.role,
            }),
          )
            .then(() => {
              conversation.sendMessage(ROOM_STARTED);
            })
            .catch((e) => console.log(e));
      }
    }
  };

  if (isMeetingHost) {
    return (
      <div className="w-100 lobby-host">
        <p className="meeting-title">{meeting.title}</p>
        <p className="meeting-description">{meeting.description}</p>
        <div
          className={`start-meeting-btn ${
            isStartingMeeting || !conversation ? 'disabled-btn' : 'yellow-btn'
          }`}
          onClick={onJoinMeeting}
        >
          {meeting.isActive ? t('JoinMeeting') : t('StartMeeting')}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default JoinMeeting;
