import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendVerifyApproverOTP,
  sendVerifyObserverOTP,
  sendVerifySigningOTP,
  verifyApproverOTP,
  verifyObserverOTP,
  verifySigningOTP,
} from '../../redux/actions/documentfunctions';
import useRecipientRole from '../useRecipientRole';

function useOTP() {
  const dispatch = useDispatch();
  const { isObserver, isApprover, isSigner } = useRecipientRole();
  const documentToSign = useSelector((state) => state.documenttosign);

  const { id, uid, public: publicId } = useParams();

  const sendOTP = (successCallback, errorCallback) => {
    const sendOTPFunction = () => {
      if (isSigner) return sendVerifySigningOTP;
      else if (isApprover) return sendVerifyApproverOTP;
      else if (isObserver) return sendVerifyObserverOTP;
    };

    dispatch(sendOTPFunction()(id, uid, publicId, documentToSign.isOpenApiV2))
      .then(() => {
        successCallback();
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  const verifyOTP = (otp, successCallback, errorCallback) => {
    const verifyOTPFunction = () => {
      if (isSigner) return verifySigningOTP;
      else if (isApprover) return verifyApproverOTP;
      else if (isObserver) return verifyObserverOTP;
    };

    return dispatch(verifyOTPFunction()(id, uid, otp, publicId, documentToSign.isOpenApiV2))
      .then((data) => {
        successCallback();
        return data;
      })
      .catch((err) => {
        errorCallback(err);
      });
  };

  return { sendOTP, verifyOTP };
}

export default useOTP;
