import { getElementBuilder } from './domStyling';
import { getRotatedDimensions } from './pdftron';

export const createWidgetLayout = (
  iframeWindow,
  {
    color = '#000000',
    tagText = '',
    iconSrc = '',
    title = '',
    backgroundColor = 'transparent',
    iconPosition = 'left',
    iconWidth = 'auto',
    flushIconToSide = false,
  } = {},
) => {
  const getStyledElement = getElementBuilder(iframeWindow);

  const wrapperDiv = getStyledElement('div', () => ({
    width: '100%',
    height: '100%',
    zIndex: 9999,
  }));

  const widgetDiv = getStyledElement('div', () => ({
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    border: `0.17em solid ${color}`,
    borderRadius: '0.6em',
    width: '100%',
    height: '100%',
    overflow: 'visible',
    backgroundColor,
  }));

  const originalWidgetLabelDisplayType = 'flex';
  const widgetLabel = getStyledElement('div', () => ({
    width: '100%',
    height: '1.5em',
    display: originalWidgetLabelDisplayType,
    columnGap: '6%',
    flexDirection: iconPosition === 'right' ? 'row-reverse' : 'row',
    justifyContent: 'center',
  }));

  const iconFlexGrow = 0;
  const icon = getStyledElement('img', () => ({
    flexGrow: iconFlexGrow,
    width: iconWidth,
  }));

  const titleElement = getStyledElement('div', (theme) => ({
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.label_large.fontWeight,
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    opacity: 0.8,
    margin: 'auto 0',
    textAlign: 'center',
    flexGrow: iconFlexGrow + flushIconToSide ? 1 : 0,
  }));

  const body = getStyledElement('div', () => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: `100%`,
    padding: '6%',
    color: 'rgb(0, 0, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));

  icon.src = iconSrc;
  titleElement.textContent = title;

  widgetLabel.appendChild(icon);
  widgetLabel.appendChild(titleElement);
  body.appendChild(widgetLabel);
  widgetDiv.appendChild(body);
  wrapperDiv.appendChild(widgetDiv);

  if (tagText) {
    const tag = getStyledElement('div', () => ({
      position: 'absolute',
      left: '0.25em',
      top: '-2.9em',
      fontSize: '0.6em',
      borderRadius: '0.4em',
      background: color,
      color: 'white',
      padding: '0.6em',
      zIndex: 9999,
      fontWeight: '700',
      display: 'none',
    }));

    tag.textContent = tagText;
    tag.classList.add('widget-tag');
    wrapperDiv.appendChild(tag);
    wrapperDiv.addEventListener('mouseenter', () => {
      tag.style.display = 'block';
    });

    wrapperDiv.addEventListener('mouseleave', () => {
      tag.style.display = 'none';
    });
  }

  wrapperDiv.addBodyElement = (bodyElement, options) => {
    // widgetLabel appearance is expected to change on demand.
    // Set display to 'none' instead of .remove() to keep widgetLabel in DOM.
    // This is better for performance.
    if (options?.fillWidget === true) {
      widgetLabel.style.display = 'none';
      body.style.padding = 0;
    } else if (options.hideLabel === true) {
      widgetLabel.style.display = 'none';
    }

    // Make sure body element overlaps the label.
    bodyElement.style.position = 'absolute';
    bodyElement.style.top = 0;
    bodyElement.style.left = 0;
    bodyElement.style.padding = 'inherit';

    if (options?.centralise) {
      bodyElement.style.position = 'relative';
      bodyElement.style.top = 'auto';
      bodyElement.style.left = 'auto';
      bodyElement.style.alignSelf = 'center';
      bodyElement.style.justifySelf = 'center';
    }

    body.appendChild(bodyElement);

    // Focus on body content when widget is clicked.
    wrapperDiv.addEventListener('click', () => {
      bodyElement.focus();
    });
  };

  wrapperDiv.hideLabel = () => {
    widgetLabel.style.display = 'none';
  };

  wrapperDiv.showLabel = () => {
    widgetLabel.style.display = originalWidgetLabelDisplayType;
  };

  return wrapperDiv;
};

export const createFormWidgetLayout = (
  iframeWindow,
  { isRequired = false, widgetType = '', iconSrc = '', labelText = '', iconWidth = '1em' } = {},
) => {
  const getStyledElement = getElementBuilder(iframeWindow);

  const wrapperDiv = getStyledElement('div', () => ({
    width: '100%',
    height: '100%',
    zIndex: 9999,
  }));

  const widgetDiv = getStyledElement('div', (theme) => ({
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: `0.1em solid ${theme.palette.primary.light}`,
    borderRadius: '0.3em',
    width: '100%',
    height: '100%',
    overflow: 'visible',
    alignItems: 'center',
    padding: `0 ${isRequired ? '0.8em' : '0.5em'} 0 0.5em`, // Make space for astericks
    backgroundColor: 'rgba(99, 51, 255, 0.04)',
    hover: {
      borderColor: theme.palette.primary.main,
    },
  }));

  const defaultInnerElementOpacity = '0.7';
  const icon = getStyledElement('img', () => ({
    width: iconWidth,
    minWidth: iconWidth,
    opacity: defaultInnerElementOpacity,
  }));

  icon.src = iconSrc;

  const label = getStyledElement('div', (theme) => ({
    ...theme.typography.body_medium,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
    lineHeight: 'auto',
    color: theme.palette.primary.main,
    opacity: defaultInnerElementOpacity,
  }));

  label.textContent = labelText;

  if (widgetType === 'sign') {
    widgetDiv.style.justifyContent = 'center';
    widgetDiv.style.padding = '0 0.7em';
    widgetDiv.style.gap = '0.7em';
    label.style.fontWeight = '500';
    widgetDiv.appendChild(icon);
    widgetDiv.appendChild(label);
  } else {
    widgetDiv.style.justifyContent = 'space-between';
    widgetDiv.appendChild(label);
    widgetDiv.appendChild(icon);
  }

  if (isRequired) {
    const astericks = getStyledElement('span', (theme) => ({
      fontSize: '1em',
      color: theme.palette.error.main,
      position: 'absolute',
      right: '0.3em',
      top: '0.2em',
    }));
    astericks.textContent = '*';
    widgetDiv.appendChild(astericks);
  }

  widgetDiv.addEventListener('mouseenter', () => {
    label.style.opacity = '1';
    icon.style.opacity = '1';
  });

  widgetDiv.addEventListener('mouseleave', () => {
    label.style.opacity = defaultInnerElementOpacity;
    icon.style.opacity = defaultInnerElementOpacity;
  });

  wrapperDiv.hideLabel = () => {
    label.style.visibility = 'hidden';
  };

  wrapperDiv.showLabel = () => {
    label.style.visibility = 'visible';
  };

  wrapperDiv.hideIcon = () => {
    icon.style.visibility = 'hidden';
  };

  wrapperDiv.showIcon = () => {
    icon.style.visibility = 'visible';
  };

  wrapperDiv.appendChild(widgetDiv);

  return wrapperDiv;
};

export const createTextBox = (iframeWindow, color = '#000000', fontSize = '1em') => {
  const getStyledElement = getElementBuilder(iframeWindow);
  return getStyledElement('input', () => ({
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    fontWeight: 'normal',
    fontSize: fontSize,
    color: color,
    border: 'none',
  }));
};

export const isParentWiderThanChild = (parentDimensions, childDimensions) => {
  const parentAspectRatio = parentDimensions.width / parentDimensions.height;
  const childAspectRatio = childDimensions.width / childDimensions.height;
  return parentAspectRatio > childAspectRatio;
};

export const getFontSize = (textLength) => {
  if (textLength < 30) {
    return '12pt';
  } else if (textLength < 50) {
    return '10pt';
  } else {
    return '8pt';
  }
};

export const setSignatureWidgetText = (
  value,
  annotId,
  pdfTronInstance,
  {
    textColor = '#000000',
    textAlign = 'center',
    padding = { left: 8, right: 3, top: 3 },
    readOnly = false,
  } = {},
) => {
  const { Annotations, annotationManager } = pdfTronInstance.Core;

  const selectedSignature = annotationManager
    .getAnnotationsList()
    .find(
      (annot) => annot instanceof Annotations.SignatureWidgetAnnotation && annot.Id === annotId,
    );
  annotationManager.deleteAnnotation(selectedSignature.getAssociatedSignatureAnnotation());
  const rotation = selectedSignature.rotation;
  const rotatedTextAnnotDimensions = getRotatedDimensions(rotation, {
    width: selectedSignature.getWidth(),
    height: selectedSignature.getHeight(),
  });

  const textAnnot = new Annotations.FreeTextAnnotation(
    Annotations.FreeTextAnnotation.Intent.FreeText,
    {
      PageNumber: selectedSignature.PageNumber,
      X: selectedSignature.X,
      Y: selectedSignature.Y,
      Width: rotatedTextAnnotDimensions.width,
      Height: rotatedTextAnnotDimensions.height,
      Rotation: rotation,
      TextAlign: textAlign,
      TextVerticalAlign: 'center',
      TextColor: textColor ? new Annotations.Color(textColor) : new Annotations.Color(0, 0, 0, 1),
      StrokeThickness: 0,
      Font: 'HCo Gotham',
      NoMove: true,
      NoResize: true,
      ReadOnly: readOnly,
      LockedContents: true,
      FontSize: getFontSize(value.length),
    },
  );

  const paddingRect = new pdfTronInstance.Core.Math.Rect(
    padding.left || 0,
    padding.bottom || 0,
    padding.right || 0,
    padding.top || 0,
  );

  textAnnot.setPadding(paddingRect);

  textAnnot.setContents(value);
  selectedSignature.setAssociatedSignatureAnnotation(textAnnot);
  annotationManager.addAnnotation(textAnnot, { autoFocus: false });
  annotationManager.redrawAnnotation(textAnnot);

  return textAnnot;
};

export const getWidgetLayoutImageWithoutWarp = (
  src,
  widgetDimensions,
  iframeWindow,
  imageSize = '100%',
) => {
  return new Promise((resolve) => {
    const img = iframeWindow.document.createElement('img');
    img.src = src;

    img.onload = function () {
      const imgDimensions = {
        width: this.naturalWidth,
        height: this.naturalHeight,
      };

      const widgetBodyDimensions = {
        width: parseInt(widgetDimensions.width),
        height: parseInt(widgetDimensions.height),
      };

      if (isParentWiderThanChild(widgetBodyDimensions, imgDimensions)) {
        this.style.height = imageSize;
      } else {
        this.style.width = imageSize;
      }
      resolve(this);
    };
  });
};

export const createTextDisplayAnnotation = (text, Annotations) => {
  const textAnnot = new Annotations.FreeTextAnnotation(
    Annotations.FreeTextAnnotation.Intent.FreeText,
    {
      TextAlign: 'center',
      TextVerticalAlign: 'center',
      TextColor: new Annotations.Color(0, 0, 0, 1),
      StrokeThickness: 0,
      Font: 'HCo Gotham',
      NoDelete: true,
      NoMove: true,
      NoResize: true,
      ReadOnly: true,
      FontSize: getFontSize(text.length),
    },
  );
  textAnnot.setContents(text);

  return textAnnot;
};
