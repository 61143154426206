import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getWebexSigningDetails } from '../../redux/actions/documentfunctions';
import useReferredState from '../useReferredState';
import { WebexEvents } from './constants';
import { useHistory } from 'react-router-dom';

const useWebexSigning = (socket) => {
  const [isWebexSigning, setIsWebexSigning] = useState(false);
  const [connected, setConnected] = useReferredState(false);
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [recipients, setRecipients] = useReferredState();
  const [signingURL, setSigningUrl] = useReferredState();
  const [isMeetingHost, setIsMeetingHost] = useReferredState(false);
  const [userIdentitySelected, setUserIdentitySelected] = useReferredState(null);
  const [hostEmail, setHostEmail] = useReferredState('');
  const [error, setError] = useState('');
  const [isWebexSigningSessionComplete, setIsWebexSigningSessionComplete] = useReferredState(false);
  const [events, setEvents] = useReferredState({});
  const heartbeatInterval = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  // Register socket's event listeners
  useEffect(() => {
    if (!socket) return;
    socket.on('connect', () => {
      setConnected(true);
    });
    socket.on('reconnect', () => {});
    socket.on('connect_error', () => {});

    return () => {
      socket.off('connect');
      socket.off('reconnect');
      socket.off('connect_error');
    };
  });

  const createRoom = (documentId) => {
    if (hasJoinedRoom) {
      return;
    }
    socket.emit('create-room', {
      documentId,
    });
  };

  const connect = () => {
    socket.connect();
  };

  const joinRoom = (documentId) => {
    if (!documentId || hasJoinedRoom) return;
    socket.emit('join-room', {
      documentId,
    });
  };

  const emitHeartbeat = () => {
    if (!userIdentitySelected.current) return;
    socket.emit('attendee-heartbeat', { userId: userIdentitySelected.current.userId });
  };

  const confirmIdentity = () => {
    if (!userIdentitySelected.current) return;
    socket.emit('confirm-identity', {
      userEmail: userIdentitySelected.current.email,
      userId: userIdentitySelected.current.userId,
    });
  };

  const checkAttendance = () => {
    socket.emit('check-attendance', { userEmail: userIdentitySelected.current.email });
  };

  const startAction = (url) => {
    if (!url) {
      setError('Invalid URL.');
      return;
    }
    const data = url.split('/');
    socket.emit('enter-actor-link', {
      signingData: {
        role: data?.[0],
        documentId: data?.[1],
        actorId: data?.[2],
        dssKey: data?.[3],
      },
    });

    setError('');
    setSigningUrl(`/${url}`);
  };

  const completeSigning = (dssKey) => {
    socket.emit('complete-action', { dssKey });
  };

  const createdRoomHandler = (data) => {
    setHasJoinedRoom(true);
    setRecipients({
      signers: data.signers,
      approvers: data.approvers,
      observers: data.observers,
    });
    setHostEmail(data?.hostEmail);

    if (data?.documentData && !data.documentData.isSequential) {
      dispatch(getWebexSigningDetails(data.documentData));
    }

    if (typeof events?.current[WebexEvents.CREATED_ROOM] === 'function') {
      events.current[WebexEvents.CREATED_ROOM]();
    }

    setError('');
  };

  const joinedRoomHandler = (data) => {
    setHasJoinedRoom(true);
    setRecipients({
      signers: data.signers,
      approvers: data.approvers,
      observers: data.observers,
    });
    setHostEmail(data?.hostEmail);
  };

  const confirmedIdentityHandler = (data) => {
    if (!data) return;

    if (data.recipients) {
      setRecipients({ ...data.recipients });
    }
    if (userIdentitySelected?.current?.email === data.confirmedActorEmail) {
      setUserIdentitySelected({ ...userIdentitySelected.current, hasConfirmed: true });
      checkAttendance();
    }
    if (typeof events?.current[WebexEvents.CONFIRMED_IDENTITY] === 'function') {
      events.current[WebexEvents.CONFIRMED_IDENTITY](data);
    }
    setError('');

    clearInterval(heartbeatInterval.current);
    emitHeartbeat();
    heartbeatInterval.current = setInterval(() => {
      emitHeartbeat();
    }, 10000);
  };

  const actOnDocumentHandler = (data) => {
    if (isMeetingHost.current && data?.dssKey) {
      const hostIsRecipient = [
        ...recipients.current.signers,
        ...recipients.current.observers,
        ...recipients.current.approvers,
      ].find((recipient) => recipient.email === hostEmail.current);

      if (!hostIsRecipient) {
        history.push(`/webex-observe/${data.dssKey}`);
      }
    }
    if (signingURL?.current) {
      history.push(signingURL.current);
    }
    if (typeof events?.current[WebexEvents.ACT_ON_DOCUMENT] === 'function') {
      events.current[WebexEvents.ACT_ON_DOCUMENT](data);
    }
    setError('');
  };

  const nextActionHandler = (data) => {
    if (!data) return;

    const userId = userIdentitySelected.current;
    const isHost = isMeetingHost.current;

    if (data.documentData && (isHost || userId?.role === 'observer')) {
      dispatch(getWebexSigningDetails(data.documentData));
    }

    if (isHost && userId && data.actor?.email && userId.email === data?.actor?.email) {
      history.push('/initiator');
    }

    if (typeof events?.current[WebexEvents.NEXT_ACTION] === 'function') {
      events.current[WebexEvents.NEXT_ACTION](data, userIdentitySelected.current);
    }

    setError('');
  };

  const completeSigningHandler = (data) => {
    if (data) {
      dispatch(getWebexSigningDetails(data?.documentData));
    }
    setIsWebexSigningSessionComplete(true);
    if (typeof events?.current[WebexEvents.COMPLETED_SESSION] === 'function') {
      events.current[WebexEvents.COMPLETED_SESSION](data?.dssKey);
    }
  };

  const errorHandler = (data) => {
    setError(data.errorDescriptions);
    if (typeof events?.current[WebexEvents.ERROR] === 'function') {
      events.current[WebexEvents.ERROR](data);
    }
  };

  const addEventListener = useCallback(
    (eventName, handler) => {
      const newEvents = {
        ...events.current,
        [eventName]: handler,
      };

      setEvents(newEvents);
    },
    [events, setEvents],
  );

  // Register Webex's event listeners
  useEffect(() => {
    if (!connected) return;

    socket.on(WebexEvents.CREATED_ROOM, createdRoomHandler);
    socket.on(WebexEvents.JOINED_ROOM, joinedRoomHandler);
    socket.on(WebexEvents.CONFIRMED_IDENTITY, confirmedIdentityHandler);
    socket.on(WebexEvents.ACT_ON_DOCUMENT, actOnDocumentHandler);
    socket.on(WebexEvents.NEXT_ACTION, nextActionHandler);
    socket.on(WebexEvents.COMPLETED_SESSION, completeSigningHandler);
    socket.on(WebexEvents.ERROR, errorHandler);

    return () => {
      socket.off(WebexEvents.CREATED_ROOM, createdRoomHandler);
      socket.off(WebexEvents.JOINED_ROOM, joinedRoomHandler);
      socket.off(WebexEvents.CONFIRMED_IDENTITY, confirmedIdentityHandler);
      socket.off(WebexEvents.ACT_ON_DOCUMENT, actOnDocumentHandler);
      socket.off(WebexEvents.NEXT_ACTION, nextActionHandler);
      socket.off(WebexEvents.COMPLETED_SESSION, completeSigningHandler);
      socket.off(WebexEvents.ERROR, errorHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, events]);

  return {
    socket,
    connect,
    hasJoinedRoom,
    recipients: recipients.current,
    createRoom,
    joinRoom,
    startAction,
    setSigningUrl,
    completeSigning,
    confirmIdentity,
    isMeetingHost: isMeetingHost.current,
    setMeetingHost: setIsMeetingHost,
    error,
    setError,
    isWebexSigning,
    setIsWebexSigning,
    hostEmail: hostEmail.current,
    userIdentitySelected: userIdentitySelected.current,
    setUserIdentity: setUserIdentitySelected,
    isWebexSigningSessionComplete: isWebexSigningSessionComplete.current,
    addEventListener,
    emitHeartbeat,
  };
};

export default useWebexSigning;
