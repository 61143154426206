import { io } from 'socket.io-client';

import { process_env } from './envData';

const SOCKET_URL = process_env('REACT_APP_DEDOCO_API') || 'http://localhost:3000';

export const webexSocket = io(`${SOCKET_URL}/Webex`, {
  autoConnect: false,
  transports: ['websocket'],
});
export const dVideoSignSocket = io(`${SOCKET_URL}/VideoSign`, { transports: ['websocket'] });
