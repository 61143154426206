import { Button, Grid, Stack, Typography } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import { TryFreeSection } from '../../VideoSigningRequest/TryFreeSection';
import SidebarDownloadButton from '../SidebarDownloadButton';
import { classes, Root } from './InfoSidebar.styles';
import DeMailTouchPointBox from './DeMailTouchPoint';
import SignerInfo from '../../../SignerInfo';
import { useTranslation } from 'react-i18next';

const InfoSidebar = ({
  handleDownloadPdf,
  title,
  body,
  showDownloadButton = true,
  isForm = false,
  showDemailTouchPoint = false,
  compactMode = false,
  nextSigner,
}) => {
  const showNextSigner = Boolean(nextSigner);
  const { t } = useTranslation();

  return (
    <Root container isMobileOnly={isMobileOnly} sx={{ overflow: 'auto' }}>
      <Grid container className={classes.sideBox}>
        <Grid item>
          <Typography
            data-testid="sidebar-info-title"
            color="primary"
            variant="headline_small"
            className={classes.successTitle}
          >
            {title}
          </Typography>
        </Grid>
        {body && (
          <Grid item>
            <Typography variant="body_medium">{body}</Typography>
          </Grid>
        )}
        {showNextSigner && (
          <Stack spacing={3} sx={{ py: 3 }}>
            <Typography component="div" variant="title_large">
              {t('NextToSign')}
            </Typography>
            <SignerInfo email={nextSigner?.email} name={nextSigner?.email} />
            {nextSigner?.instantSignLink && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  window.location.replace(`${nextSigner.instantSignLink}`);
                }}
              >
                {t('StartSigning')}
              </Button>
            )}
          </Stack>
        )}
        {showDownloadButton && handleDownloadPdf && !showNextSigner && (
          <Grid item className={classes.downloadButton}>
            <SidebarDownloadButton handleDownloadPdf={handleDownloadPdf} isForm={isForm} />
          </Grid>
        )}

        <Grid item>
          {showDemailTouchPoint && <DeMailTouchPointBox compactMode={compactMode} />}
        </Grid>
      </Grid>

      <TryFreeSection />
    </Root>
  );
};

export default InfoSidebar;
