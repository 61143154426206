import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import documentIcon from '../../../../assets/document.svg';
import verifyErrorIcon from '../../../../assets/error-verified.svg';
import verifySuccessIcon from '../../../../assets/success-verified.svg';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { getFileHash } from '../../../../utils/hash';
import UploadFile from '../../UI/UploadFile';
import {
  classes,
  VerifyBoxAccordion,
  VerifyBoxAccordionDetails,
  VerifyBoxAccordionSummary,
  VerifyFailureBox,
  VerifySuccessBox,
} from './VerifyBox.style';

function VerifyBox({ newDedocoSigning = false }) {
  const { t } = useTranslation();
  const { documentToSign } = useGetRequiredDedocoSigning(newDedocoSigning);

  const [isVerified, setIsVerified] = useState(false);
  const [isFileDropEmpty, setIsFileDropEmpty] = useState(true);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const handleFileUploaded = async (file) => {
    const hash = await getFileHash(file);
    setIsVerified(hash === documentToSign?.document_hashes[0]);
    setIsFileDropEmpty(false);
  };

  const toggleAccordion = () => {
    setIsAccordionExpanded((isAccordionExpanded) => !isAccordionExpanded);
  };

  const handleTryAgain = () => {
    setIsFileDropEmpty(true);
    setIsAccordionExpanded(true);
  };

  if (isFileDropEmpty)
    return (
      <VerifyBoxAccordion onClick={toggleAccordion} expanded={isAccordionExpanded} disableGutters>
        <VerifyBoxAccordionSummary>
          <img src={documentIcon} className={classes.documentIcon} />
          <Typography variant="label_medium">{t('VerifyCopyVersion')}</Typography>
        </VerifyBoxAccordionSummary>
        <VerifyBoxAccordionDetails>
          <Typography variant="body_small" className={classes.verifyDescription}>
            {t('VerifyLoadDocument', { documentName: documentToSign?.fileName })}
          </Typography>
          <UploadFile
            onFilesUploaded={handleFileUploaded}
            accept="application/pdf"
            innerText={t('VerifyDropPdf')}
          />
        </VerifyBoxAccordionDetails>
      </VerifyBoxAccordion>
    );

  // when file is uploaded
  if (isVerified)
    return (
      <VerifySuccessBox>
        <Typography variant="label_small" className={classes.documentVerified}>
          <img src={verifySuccessIcon} />
          {t('VerifyDocumentVerified')}
        </Typography>
        <Typography variant="body_small">{t('VerifyDocumentIdentical')}</Typography>
      </VerifySuccessBox>
    );
  else {
    return (
      <VerifyFailureBox>
        <Typography variant="label_small" className={classes.documentVerifiedMismatch}>
          <img src={verifyErrorIcon} />
          {t('VerifyDocumentMismatch')}
        </Typography>
        <Typography variant="body_small">{t('VerifyDocumentNotIdentical')}</Typography>
        <Typography variant="body_small" className={classes.tryAgain} onClick={handleTryAgain}>
          {t('VerifyTryAgain')}
        </Typography>
      </VerifyFailureBox>
    );
  }
}

export default VerifyBox;
