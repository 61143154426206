/* October 2022: Deprecated, will remove once we have fully completed the UI revamp. */

import { base64DataUrlToBlob } from './base64';
import { rotateImageClockwise } from './image';
import moment from 'moment';
import { convertDegreeToRadian } from './math';

export const getPdfBlob = async (documentViewer, annotationManager, isDownloading = false) => {
  if (!documentViewer || !annotationManager) return;

  const annotList = annotationManager.getAnnotationsList();
  const dateFormat = 'DD/MM/YYYY';
  let dateAnnots = [];
  if (isDownloading) {
    dateAnnots = annotList.filter((x) => x?.value === dateFormat);
    dateAnnots.forEach((a) => {
      const field = annotationManager.getFieldManager().getField(a.Id);
      field.setValue();
    });
  }
  const xfdfString = await annotationManager.exportAnnotations({
    annotList,
    links: false,
    widgets: true,
    fields: true,
  });
  // this is to remove default "DRAFT" stamp
  // const noDraftString = xfdfString.replace(/"Draft"/g, '');
  const data = await documentViewer.getDocument().getFileData({ xfdfString, flatten: true });

  if (isDownloading) {
    dateAnnots.forEach((a) => {
      const field = annotationManager.getFieldManager().getField(a.Id);
      field.setValue(dateFormat);
    });
  }

  const arr = new Uint8Array(data);
  return new Blob([arr], { type: 'application/pdf' });
};

export const getAnnotationContentAsImage = async (annotation, docViewer, getBlob = false) => {
  const canvas = document.createElement('canvas');
  const pageMatrix = docViewer.getDocument().getPageMatrix(annotation.PageNumber);
  const rotation = docViewer.getDocument().getPageRotation(annotation.PageNumber);
  canvas.height = annotation.Height;
  canvas.width = annotation.Width;
  const ctx = canvas.getContext('2d');
  ctx.translate(-annotation.X, -annotation.Y);
  annotation.draw(ctx, pageMatrix);
  const dataUrl = canvas.toDataURL();

  const rotatedDataUrl = await rotateImageClockwise(dataUrl, rotation);
  return getBlob ? base64DataUrlToBlob(rotatedDataUrl) : rotatedDataUrl;
};

export const normalStyles = (iframeWindow, documentViewer, Annotations, widget) => {
  // This function, when assigned to WidgetAnnotations.getCustomStyles, is called everything zoom changes.
  const { color, fieldName, PageNumber } = widget;
  const pageZoom = documentViewer.getPageZoom(PageNumber);
  const isSigned = fieldName.includes('signed');
  const widgetField = widget.getField();
  let font = widgetField.font;

  if (!font || font.size === 0) {
    const widgetElement = iframeWindow.document.getElementById(fieldName);
    const fontName = widgetElement?.style.fontFamily || 'Lato';
    const cssFontSize = widgetElement?.style.fontSize.replace('px', '');
    const fontSizeNum = Number(cssFontSize);
    // Page zoom is taken into account to keep fontSize close to the default fontsize of the element at 100% zoom.
    // A constant factor is added to scale font to a good viewing size.
    const fontScalingFactor = 1.02 / pageZoom;
    const fontSize = isNaN(fontSizeNum) ? 12 : fontSizeNum * fontScalingFactor;

    font = new Annotations.Font({
      name: fontName,
      size: fontSize,
    });
  }

  if (
    widget instanceof Annotations.TextWidgetAnnotation ||
    widget instanceof Annotations.DatePickerWidgetAnnotation
  ) {
    widgetField.set({ font });
    return {
      'background-color': 'transparent',
      border: `2px solid ${color}`,
      display: 'flex',
    };
  } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
    // hidden other signature
    return {
      display: isSigned ? 'none' : 'flex',
      alignItems: 'center',
    };
  } else if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
    return {
      'border-radius': '4px',
      border: `2px solid ${color}`,
      backgroundColor: 'transparent',
      display: 'flex',
    };
  } else if (widget instanceof Annotations.ChoiceWidgetAnnotation) {
    widgetField.set({ font });
    return {
      border: `2px solid ${color}`,
      backgroundColor: 'transparent',
      opacity: 1,
      display: 'flex',
    };
  } else {
    return {
      border: `2px solid ${color}`,
      display: 'flex',
    };
  }
};

// 0.76 is the ratio after calculating the difference ratio between box's size on builder and signing app
export const convertToOriginSize = (length) => {
  return length * 0.76;
};

export const adjustCoordinatesAfterAnnotationResizing = (
  originalCoordinates,
  rotation,
  originalAnnotationDimensions,
  resizedAnnotationDimensions,
) => {
  /* During annotation resizing the anchor point of the annotation is the corner that is closest to (x = 0, y = 0) of the page.
   *  i.e.
   *    When rotation is 0, anchor point is top left
   *    When rotation is 90, anchor point is top right
   *    When rotation is 180, anchor point is bottom right
   *    When rotation is 270, anchor point is bottom left
   * When filling up a signature or annotation, the anchor point is set to be top left.
   *  However, resizing the annotation on a rotated page can cause the drawn annotation to move away from top left as the anchor points are different.
   *  This shifts the content of the annotation away from where the user expects it to be.
   * This function attempts to correct this issue by flushing the content of the annotation to the top left regardless of page rotation.
   */
  const adjustedCoords = {
    x: originalCoordinates.x,
    y: originalCoordinates.y,
  };

  const widthDimensionDiff = originalAnnotationDimensions.width - resizedAnnotationDimensions.width;
  const heightDimensionDiff =
    originalAnnotationDimensions.height - resizedAnnotationDimensions.height;

  if (rotation === 90) {
    adjustedCoords.y += widthDimensionDiff;
  } else if (rotation === 180) {
    adjustedCoords.x += widthDimensionDiff;
    adjustedCoords.y += heightDimensionDiff;
  } else if (rotation === 270) {
    adjustedCoords.x += heightDimensionDiff;
  }

  return adjustedCoords;
};

export const convertPlacementPercentageToViewerCoordinates = (placement, pageInfo) => {
  return {
    x: (parseFloat(placement?.x) / 100) * pageInfo.width,
    y: (parseFloat(placement?.y) / 100) * pageInfo.height,
  };
};

export const drawDigitalSignatures = (
  digitalSignatures,
  signerDetails,
  WidgetFlags,
  isUsingOriginalDocument,
  documentViewer,
  fieldManager,
  annotationManager,
  Annotations,
  iframeWindow,
  digitalSignatureRef,
  handleOpen,
) => {
  const { name, color, isCurrentSigner } = signerDetails;
  digitalSignatures.forEach((digitalSignature, index) => {
    const { placement, dimensions, signature } = digitalSignature;
    const flags = new WidgetFlags(null);
    flags.set(WidgetFlags.READ_ONLY);

    // Only continue drawing signed box if the document used is clean (i.e. is the original document uploaded for signing)
    // If document is not original, it implies that there are already some text/signature that are embedded in the PDF.
    if (signature && !isUsingOriginalDocument) {
      return;
    }

    const fieldId = `digisignature${isCurrentSigner ? '-signer' : ''}-${index}`;

    const { widgetAnnot, field } = createSingpassWidgetAnnotation(
      fieldId,
      name,
      color,
      isCurrentSigner,
      !!signature,
      flags,
      Annotations,
      iframeWindow,
      digitalSignatureRef,
      handleOpen,
    );

    drawWidgetAnnotation(
      field,
      widgetAnnot,
      placement,
      dimensions,
      documentViewer,
      fieldManager,
      annotationManager,
      Annotations,
    );
  });
};

export const createSingpassWidgetAnnotation = (
  fieldId,
  signerName,
  color,
  clickable,
  isSigned,
  fieldFlags,
  Annotations,
  iframeWindow,
  digitalSignatureRef,
  handleOpen,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.NoDelete = true;
  widgetAnnot.Id = fieldId;

  widgetAnnot.createSignHereElement = () => {
    const wrapperDiv = iframeWindow.document.createElement('div');
    wrapperDiv.style.width = '100%';
    wrapperDiv.style.height = '100%';

    // Shade the button darker if singpass sign is not clickable
    const backgroundColor = clickable ? '#FDDCDE' : '#EBDDDD';

    const div = createWrapperDiv(signerName, color, backgroundColor, iframeWindow);
    if (clickable) {
      div.onclick = () => {
        !digitalSignatureRef.current && handleOpen();
      };
    }

    const img = iframeWindow.document.createElement('img');
    img.src = isSigned
      ? '/singpass-signed-placeholder.svg'
      : '/Signature area on PDF no background.svg';
    img.style.width = '100%';
    img.style.height = '100%';
    div.appendChild(img);

    wrapperDiv.appendChild(div);
    return wrapperDiv;
  };

  return { widgetAnnot, field };
};

export const drawConfirmations = (
  confirmations,
  signerDetails,
  WidgetFlags,
  isObserver,
  isUsingOriginalDocument,
  documentViewer,
  fieldManager,
  annotationManager,
  setDataFromPdf,
  Annotations,
) => {
  const { signerIndex, color, name, isCurrentSigner, hasSigned } = signerDetails;
  confirmations.forEach((item, index) => {
    const { placement, dimensions, isDefault, isChecked } = item;

    let fieldId = null;
    const flags = new WidgetFlags(null);
    const shouldCheck = isDefault || isChecked;

    if (isCurrentSigner) {
      fieldId = `checkbox-${index}-${signerIndex}`;
    } else if ((hasSigned || isObserver) && isUsingOriginalDocument) {
      fieldId = `other-${name}-checkbox-${index}-${signerIndex}`;
      flags.set(WidgetFlags.READ_ONLY);
    } else {
      return;
    }

    const { widgetAnnot, field } = createCheckboxWidgetAnnotation(
      fieldId,
      shouldCheck,
      color,
      flags,
      Annotations,
    );

    if (isCurrentSigner) {
      updatePdfAnnotationData(null, fieldId, field.getValue(), setDataFromPdf, index);
    }

    drawWidgetAnnotation(
      field,
      widgetAnnot,
      placement,
      dimensions,
      documentViewer,
      fieldManager,
      annotationManager,
      Annotations,
    );
  });
};

export const createCheckboxWidgetAnnotation = (
  fieldId,
  isChecked,
  color,
  fieldFlags,
  Annotations,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Btn',
    value: isChecked ? 'On' : 'Off',
    flags: fieldFlags,
  });

  // create a widget annotation
  const widgetAnnot = new Annotations.CheckButtonWidgetAnnotation(field, {
    appearance: 'Off',
    appearances: {
      Off: {},
      On: {},
    },
  });

  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  return { widgetAnnot, field };
};

export const drawESignatures = (
  esignatures,
  signerDetails,
  options,
  WidgetFlags,
  Annotations,
  iframeWindow,
  documentViewer,
  enableElements,
  fieldManager,
  annotationManager,
) => {
  const { signerIndex, name, email, color, isCurrentSigner, hasSigned } = signerDetails;

  return Promise.all(
    esignatures.map(async (esignature, index) => {
      const { placement, dimensions, signature } = esignature;
      const fieldFlags = new WidgetFlags(null);

      if (!isCurrentSigner || hasSigned) {
        fieldFlags.set(WidgetFlags.READ_ONLY);
      }

      const fieldId = isCurrentSigner ? `esignature-${index}-${signerIndex}` : `esign-box-${email}`;

      const { widgetAnnot, field } = await createESignatureWidgetAnnotation(
        fieldId,
        fieldFlags,
        name,
        color,
        signature,
        isCurrentSigner && !hasSigned,
        Annotations,
        iframeWindow,
        enableElements,
      );
      drawWidgetAnnotation(
        field,
        widgetAnnot,
        placement,
        dimensions,
        documentViewer,
        fieldManager,
        annotationManager,
        Annotations,
      );
    }),
  );
};

export const drawCustomTexts = (
  customTexts,
  signerDetails,
  WidgetFlags,
  isObserver,
  isUsingOriginalDocument,
  Annotations,
  iframeWindow,
  setDataFromPdf,
  documentViewer,
  enableElements,
  disableElements,
  annotationManager,
  fieldManager,
) => {
  const { signerIndex, name, color, isCurrentSigner, hasSigned } = signerDetails;

  return Promise.all(
    customTexts.map(async (item, index) => {
      const { descriptor, placement, dimensions, type, text, options, label } = item;

      let field = null;
      let fieldId = null;
      let widgetAnnot = null;
      const flags = new WidgetFlags(null);

      if (isCurrentSigner) {
        fieldId = `${type}-${index}-${signerIndex}`;
      } else if ((hasSigned || isObserver) && isUsingOriginalDocument) {
        fieldId = `other-${name}-${type}-${index}-${signerIndex}`;
        flags.set(WidgetFlags.READ_ONLY);
      } else {
        return;
      }

      switch (type) {
        case 'text':
          ({ widgetAnnot, field } = createTextWidgetAnnotation(
            fieldId,
            text,
            descriptor,
            color,
            isCurrentSigner && !hasSigned,
            flags,
            Annotations,
            iframeWindow,
          ));
          break;
        case 'actual-date':
          flags.set(WidgetFlags.READ_ONLY);
          ({ widgetAnnot, field } = createActualDateWidgetAnnotation(
            fieldId,
            text,
            color,
            flags,
            Annotations,
          ));

          if (isCurrentSigner) {
            updatePdfAnnotationData(null, fieldId, field.getValue(), setDataFromPdf, index);
          }
          break;
        case 'dropdown-list':
          ({ widgetAnnot, field } = createDropdownWidgetAnnotation(
            fieldId,
            label,
            isCurrentSigner ? options : [text],
            color,
            flags,
            WidgetFlags,
            Annotations,
            iframeWindow,
          ));

          if (isCurrentSigner) {
            updatePdfAnnotationData(null, fieldId, field.getValue(), setDataFromPdf, index);
          }
          break;
        case 'custom-date':
          ({ widgetAnnot, field } = createCustomDateWidgetAnnotation(
            fieldId,
            text,
            color,
            flags,
            Annotations,
          ));
          break;
        case 'initials':
          ({ widgetAnnot, field } = await createInitialsWidgetAnnotation(
            fieldId,
            flags,
            name,
            color,
            text,
            isCurrentSigner && !hasSigned,
            Annotations,
            iframeWindow,
            enableElements,
            disableElements,
          ));
          break;
        case 'stamp':
          ({ widgetAnnot, field } = await createStampWidgetAnnotation(
            fieldId,
            flags,
            name,
            color,
            text,
            isCurrentSigner && !hasSigned,
            Annotations,
            iframeWindow,
            enableElements,
            disableElements,
          ));
          break;
        default:
          break;
      }

      drawWidgetAnnotation(
        field,
        widgetAnnot,
        placement,
        dimensions,
        documentViewer,
        fieldManager,
        annotationManager,
        Annotations,
      );
    }),
  );
};

export const createStampWidgetAnnotation = async (
  fieldId,
  fieldFlags,
  signerName,
  color,
  content,
  writable,
  Annotations,
  iframeWindow,
  enableElements,
  disableElements,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  const widgetBoxTitle = content ? '' : 'Stamp';
  const stampBoxDiv = createSignatureWidgetBoxDiv(signerName, color, widgetBoxTitle, iframeWindow);

  if (!writable) {
    if (content) {
      const stampImageAnnot = await createImageDisplayAnnotation(Annotations, content);
      widgetAnnot.setAssociatedSignatureAnnotation(stampImageAnnot);
    }

    widgetAnnot.createInnerElement = () => {
      return stampBoxDiv;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      stampBoxDiv.onclick = () => {
        enableElements(['imageSignaturePanelButton', 'imageSignaturePanel']);
        disableElements([
          'inkSignaturePanelButton',
          'inkSignaturePanel',
          'textSignaturePanelButton',
          'textSignaturePanel',
          'dedocoSavedSignaturePanelButton',
          'dedocoSavedSignaturePanel',
        ]);
        const imageSignaturePanelButton = iframeWindow.document.querySelector(
          "[data-element='imageSignaturePanelButton']",
        );
        iframeWindow.document
          .querySelector("[data-element='signatureModal']")
          .querySelector('.footer')
          ?.classList.add('hide-check-box');
        imageSignaturePanelButton.click();
      };

      return stampBoxDiv;
    };
  }

  return { widgetAnnot, field };
};

export const createInitialsWidgetAnnotation = async (
  fieldId,
  fieldFlags,
  signerName,
  color,
  content,
  writable,
  Annotations,
  iframeWindow,
  enableElements,
  disableElements,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  const widgetBoxTitle = content ? '' : 'Initials';
  const initialsBoxDiv = createSignatureWidgetBoxDiv(
    signerName,
    color,
    widgetBoxTitle,
    iframeWindow,
  );

  if (!writable) {
    if (content) {
      const initialsImageAnnot = await createImageDisplayAnnotation(Annotations, content);
      widgetAnnot.setAssociatedSignatureAnnotation(initialsImageAnnot);
    }

    widgetAnnot.createInnerElement = () => {
      return initialsBoxDiv;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      initialsBoxDiv.onclick = () => {
        enableElements([
          'inkSignaturePanelButton',
          'inkSignaturePanel',
          'textSignaturePanelButton',
          'textSignaturePanel',
        ]);
        disableElements([
          'imageSignaturePanelButton',
          'imageSignaturePanel',
          'dedocoSavedSignaturePanelButton',
          'dedocoSavedSignaturePanel',
        ]);
        const textSignaturePanelButton = iframeWindow.document.querySelector(
          "[data-element='textSignaturePanelButton']",
        );

        iframeWindow.document
          .querySelector("[data-element='signatureModal']")
          .querySelector('.footer')
          ?.classList.add('hide-check-box');

        textSignaturePanelButton.click();
      };
      return initialsBoxDiv;
    };
  }

  return { widgetAnnot, field };
};

export const createCustomDateWidgetAnnotation = (fieldId, text, color, fieldFlags, Annotations) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
    value: text,
    // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
    actions: {
      F: [
        {
          name: 'JavaScript',
          javascript: 'AFDate_FormatEx("DD/MM/YYYY");',
        },
      ],
      K: [
        {
          name: 'JavaScript',
          // You can customize the date format here between the two double-quotation marks
          // or leave this blank to use the default format
          javascript: 'AFDate_FormatEx("DD/MM/YYYY");',
        },
      ],
    },
  });
  const widgetAnnot = new Annotations.DatePickerWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  return { widgetAnnot, field };
};

export const createDropdownWidgetAnnotation = (
  fieldId,
  label,
  options,
  color,
  fieldFlags,
  WidgetFlags,
  Annotations,
  iframeWindow,
) => {
  fieldFlags.set(WidgetFlags.COMBO);
  const dropdownOptions = options.map((item) => {
    return {
      value: item,
      displayValue: item,
    };
  });

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Ch',
    value: dropdownOptions.length > 0 ? dropdownOptions[0].value : '',
    flags: fieldFlags,
    options: dropdownOptions,
  });

  const widgetAnnot = new Annotations.ChoiceWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  let retryCount = 0;
  const tryDrawLabel = setInterval(() => {
    if (label.length) {
      const wrapperDropdown = iframeWindow.document.getElementById(fieldId);

      if (!wrapperDropdown) {
        if (++retryCount > 100) {
          clearInterval(tryDrawLabel);
        }
        return;
      }

      clearInterval(tryDrawLabel);

      const div = iframeWindow.document.createElement('div');
      const formatLabel = label.charAt(0).toUpperCase() + label.slice(1);
      div.textContent += formatLabel;
      div.style.position = 'absolute';
      div.style.top = '-25px';
      div.style.backgroundColor = color;
      div.style.fontWeight = 'bold';
      div.style.padding = '5px';
      div.style.borderRadius = '5px';
      div.style.color = 'white';
      wrapperDropdown.appendChild(div);
    }
  }, 50);

  return { widgetAnnot, field };
};

export const updatePdfAnnotationData = (dataId, dataName, value, setDataFromPdf, index) => {
  setDataFromPdf((prevData) => {
    const arr = [...prevData];
    const dataIdx = arr.findIndex((x) => x.name === dataName);
    if (dataIdx !== -1) {
      arr[dataIdx].value = value;
    } else {
      let indexToStore = index;
      if (!index) {
        const splitDataName = dataName.split('-');
        indexToStore = splitDataName[splitDataName.length - 2];
      }

      arr.push({
        id: dataId,
        index: indexToStore,
        name: dataName,
        value,
      });
    }
    return arr;
  });
};

export const createActualDateWidgetAnnotation = (fieldId, text, color, fieldFlags, Annotations) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    value: text || moment().format('DD/MM/YYYY'),
    flags: fieldFlags,
  });
  const widgetAnnot = new Annotations.DatePickerWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  return { widgetAnnot, field };
};

export const createTextWidgetAnnotation = (
  fieldId,
  text,
  descriptor,
  color,
  writable,
  fieldFlags,
  Annotations,
  iframeWindow,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    value: text,
    tooltipName: descriptor,
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.TextWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  if (writable) {
    //Create an input element and allows users to update the field value.
    widgetAnnot.createInnerElement = () => {
      const wrapperDiv = iframeWindow.document.createElement('div');
      wrapperDiv.style.width = '100%';
      wrapperDiv.style.height = '100%';

      const input = createTextBox(descriptor, iframeWindow, color);
      input.onchange = (e) => {
        field.setValue(e.target.value);
      };
      wrapperDiv.appendChild(input);
      return wrapperDiv;
    };
  }

  return { widgetAnnot, field };
};

export const createTextBox = (placeholder, iframeWindow, color = '#000000', fontSize = '100%') => {
  const input = iframeWindow.document.createElement('input');
  input.placeholder = placeholder;
  input.style.width = '100%';
  input.style.height = '100%';
  input.style.cursor = 'pointer';
  input.style.display = 'flex';
  input.style.justifyContent = 'center';
  input.style.alignItems = 'center';
  input.style.background = 'transparent';
  input.style.fontWeight = 'normal';
  input.style.fontSize = fontSize;
  input.style.color = color;
  input.style.border = 'none';
  return input;
};

export const createImageDisplayAnnotation = async (Annotations, imageDataUrl) => {
  const stampAnnotation = new Annotations.StampAnnotation();
  await stampAnnotation.setImageData(imageDataUrl);

  stampAnnotation.dataSource = imageDataUrl;
  stampAnnotation.Id = 'VIEW-ONLY';
  stampAnnotation.ReadOnly = true;
  stampAnnotation.NoDelete = true;
  stampAnnotation.NoMove = true;
  stampAnnotation.NoResize = true;

  return stampAnnotation;
};

export const createESignatureWidgetAnnotation = async (
  fieldId,
  fieldFlags,
  signerName,
  color,
  signature,
  writable,
  Annotations,
  iframeWindow,
  enableElements,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  const widgetBoxTitle = signature ? '' : 'Signature';
  const signatureBoxDiv = createSignatureWidgetBoxDiv(
    signerName,
    color,
    widgetBoxTitle,
    iframeWindow,
  );

  if (!writable) {
    if (signature) {
      const signatureImageAnnot = await createImageDisplayAnnotation(Annotations, signature);
      widgetAnnot.setAssociatedSignatureAnnotation(signatureImageAnnot);
    }

    widgetAnnot.createInnerElement = () => {
      return signatureBoxDiv;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      signatureBoxDiv.onclick = () => {
        enableElements([
          'inkSignaturePanelButton',
          'inkSignaturePanel',
          'textSignaturePanelButton',
          'textSignaturePanel',
          'imageSignaturePanelButton',
          'imageSignaturePanel',
          'dedocoSavedSignaturePanelButton',
          'dedocoSavedSignaturePanel',
        ]);
        const inkSignaturePanelButton = iframeWindow.document.querySelector(
          "[data-element='inkSignaturePanelButton']",
        );

        iframeWindow.document
          .querySelector("[data-element='signatureModal']")
          .querySelector('.footer.hide-check-box')
          ?.classList.remove('hide-check-box');

        inkSignaturePanelButton.click();
      };
      return signatureBoxDiv;
    };
  }
  return { widgetAnnot, field };
};

export const createSignatureWidgetBoxDiv = (
  signerName,
  color,
  placeholder = '',
  iframeWindow,
  contentAlignment = 'center',
) => {
  const parentDiv = iframeWindow.document.createElement('div');
  parentDiv.style.height = '100%';
  parentDiv.style.width = '100%';
  parentDiv.classList.add('transparent-background');

  const div = createWrapperDiv(signerName, color, 'transparent', iframeWindow, contentAlignment);

  const textDiv = iframeWindow.document.createElement('div');
  textDiv.className = 'box-label';
  textDiv.innerText = placeholder;
  div.appendChild(textDiv);

  // parentDiv required to ensure styles are applied properly
  parentDiv.append(div);

  return parentDiv;
};

export const createWrapperDiv = (name, color, background, iframeWindow, align = 'center') => {
  const div = iframeWindow.document.createElement('div');
  div.style.width = '100%';
  div.style.height = '100%';
  div.style.cursor = 'pointer';
  div.style.display = 'flex';
  div.style.justifyContent = align === 'center' ? 'center' : 'flex-start';
  div.style.alignItems = 'center';
  div.style.borderRadius = '4px';
  div.style.fontWeight = 'bold';
  div.style.color = color;
  div.style.border = `2px solid ${color}`;
  div.style.background = background || 'transparent';

  const signerDiv = iframeWindow.document.createElement('div');
  signerDiv.innerText = name; // signer name
  signerDiv.style.position = 'absolute';
  signerDiv.style.top = '-8px';
  signerDiv.style.left = '12px';
  signerDiv.style.background = `${color}`;
  signerDiv.style.borderRadius = '4px';
  signerDiv.style.fontWeight = 'bold';
  signerDiv.style.color = 'white';
  signerDiv.style.border = `2px solid ${color}`;
  signerDiv.style.padding = '2px 8px';

  div.appendChild(signerDiv);
  return div;
};

export const createSignatureWidgetWithEmbeddedImage = async (
  fieldId,
  content,
  placement,
  fieldFlags,
  Annotations,
  documentViewer,
) => {
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  //The 'content' set as the 'data' property will not be drawn on WebViewer but it will be included in the PDF blob.
  let contentToEmbed = content;

  if (content) {
    const rotation = documentViewer.getDocument().getPageRotation(placement?.page);
    contentToEmbed = await rotateImageClockwise(content, -rotation);
  }

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: contentToEmbed,
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const drawWidgetAnnotation = (
  field,
  widgetAnnot,
  placement,
  dimensions,
  documentViewer,
  fieldManager,
  annotationManager,
  Annotations,
) => {
  if (!widgetAnnot || !field) {
    return;
  }

  const pageInfo = documentViewer.getDocument().getPageInfo(placement?.page);
  const rotation = documentViewer.getDocument().getPageRotation(placement?.page);

  const originalAnnotationDimensions = {
    width: convertToOriginSize(parseInt(dimensions.width)),
    height: convertToOriginSize(parseInt(dimensions.height)),
  };

  const viewerCoords = convertPlacementPercentageToViewerCoordinates(placement, pageInfo);

  widgetAnnot.rotation = rotation;
  widgetAnnot.X = viewerCoords.x;
  widgetAnnot.Y = viewerCoords.y;
  widgetAnnot.PageNumber = placement?.page;
  widgetAnnot.Width = originalAnnotationDimensions.width;
  widgetAnnot.Height = originalAnnotationDimensions.height;

  annotationManager.addAnnotation(widgetAnnot);
  fieldManager.addField(field);
  //To make sure annotations are drawn properly
  annotationManager.drawAnnotationsFromList([widgetAnnot]);

  if (widgetAnnot.getAssociatedSignatureAnnotation) {
    drawAssociatedSignatureAnnotation(widgetAnnot, annotationManager, Annotations);
  }
};

export const drawAssociatedSignatureAnnotation = async (
  widgetAnnot,
  annotationManager,
  Annotations,
) => {
  // Assume that all associated signature annotations are 'StampAnnotation's.
  const signatureAnnot = widgetAnnot.getAssociatedSignatureAnnotation();

  if (signatureAnnot) {
    const rotation = widgetAnnot.rotation;

    if (signatureAnnot instanceof Annotations.FreeHandAnnotation) {
      signatureAnnot.rotate(-convertDegreeToRadian(rotation));
    } else {
      signatureAnnot.Rotation = rotation;
    }

    const adjustedDimensions = await adjustAssociatedSignatureDimensionsToFitWidgetBox(
      widgetAnnot,
      signatureAnnot,
      Annotations,
    );
    const adjustedCoordinates = adjustWidgetChildCoordinatesToAlignCenter(
      widgetAnnot,
      adjustedDimensions,
      rotation,
    );
    const rotatedDimensions = getRotatedDimensions(rotation, adjustedDimensions);

    signatureAnnot.setX(adjustedCoordinates.x);
    signatureAnnot.setY(adjustedCoordinates.y);
    signatureAnnot.setPageNumber(widgetAnnot.getPageNumber());
    signatureAnnot.setWidth(rotatedDimensions.width);
    signatureAnnot.setHeight(rotatedDimensions.height);
    annotationManager.addAnnotation(signatureAnnot);
    annotationManager.drawAnnotationsFromList([signatureAnnot]);
  }
};

const adjustAssociatedSignatureDimensionsToFitWidgetBox = async (
  widgetAnnot,
  signatureAnnot,
  Annotations,
) => {
  let signature = signatureAnnot.dataSource;
  if (!signature && signatureAnnot instanceof Annotations.StampAnnotation) {
    signature = await signatureAnnot.getImageData();
  }

  if (signature) {
    return new Promise((resolve, _reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(
          getChildDimensionsToFitParent(widgetAnnot, {
            width: this.width,
            height: this.height,
          }),
        );
      };
      img.src = signature;
    });
  } else {
    return {
      width: widgetAnnot.getWidth(),
      height: widgetAnnot.getHeight(),
    };
  }
};

export const adjustWidgetChildCoordinatesToAlignCenter = (
  parentWidget,
  childDimensions,
  rotation,
) => {
  const isSideways = documentIsSideways(rotation);
  const widthOffset = (parentWidget.getWidth() - childDimensions.width) / 2;
  const heightOffset = (parentWidget.getHeight() - childDimensions.height) / 2;
  const xOffset = isSideways ? heightOffset : widthOffset;
  const yOffset = isSideways ? widthOffset : heightOffset;

  return {
    x: parentWidget.getX() + xOffset,
    y: parentWidget.getY() + yOffset,
  };
};

export const getRotatedDimensions = (rotation, dimensions) => {
  const isSideways = documentIsSideways(rotation);

  return {
    width: isSideways ? dimensions.height : dimensions.width,
    height: isSideways ? dimensions.width : dimensions.height,
  };
};

export const getChildDimensionsToFitParent = (parentWidget, childDimensions) => {
  const parentAspectRatio = parentWidget.getWidth() / parentWidget.getHeight();
  const childAspectRatio = childDimensions.width / childDimensions.height;
  const parentWider = parentAspectRatio > childAspectRatio;

  return {
    width: parentWider ? parentWidget.getHeight() * childAspectRatio : parentWidget.getWidth(),
    height: parentWider ? parentWidget.getHeight() : parentWidget.getWidth() / childAspectRatio,
  };
};

export const documentIsSideways = (rotation) => {
  return rotation === 90 || rotation === 270;
};
