import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Snackbar } from '@mui/material';
import './OTPVerify.css';
import {
  verifySigningOTP,
  sendVerifySigningOTP,
  verifyObserverOTP,
  sendVerifyObserverOTP,
  sendVerifyApproverOTP,
  verifyApproverOTP,
} from '../../../redux/actions/documentfunctions';
import loggedInIcon from '../../../assets/loggedInIcon.png';
import OtpInput from 'react-otp-input';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon, CancelOutlined as CancelIcon } from '@mui/icons-material';
import {
  TWO_FA_METHOD_SMS,
  TWO_FA_METHOD_EMAIL,
  OTP_INVALID,
  OTP_EXPIRED,
} from '../../../utils/GlobalConstant';
import { ellipsis } from '../../../utils/mask';
import { getTimeBreakdown } from '../../../utils/time';

import { useTranslation } from 'react-i18next';

export default function OTPVerify(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isRequestingOTP = useSelector((state) => state.documenttosign.isRequestingOTP);
  const [otp, setOTP] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSentOTPFirstTime, setIsSentOTPFirstTime] = useState(true);
  const [countDownTime, setCountDownTime] = useState(0);
  const [countdownObject, setCountdownObject] = useState();
  const [availableVerifyAttempt, setAvailableVerifyAttempt] = useState(5);
  const [disableButton, setDisableButton] = useState(true);
  const [disableGenerateButton, setDisableGenerateButton] = useState(false);

  const decrementAvailableVerifyAttempt = () => {
    setAvailableVerifyAttempt((prev) => prev - 1);
  };

  const resetOTP = () => {
    setOTP('');
  };

  const disableGenerateButtonOverOtpFailedCount = () => {
    setDisableGenerateButton(true);
    setCountDownTime(600);
  };

  const handleVerify = (e, currentOTP) => {
    e && e.preventDefault();
    resetOTP();
    if (currentOTP || otp) {
      if (props.isObserver) {
        dispatch(
          verifyObserverOTP(
            props.documentId,
            props.recipientId,
            currentOTP ? currentOTP.toUpperCase() : otp.toUpperCase(),
            props.publicVariable,
          ),
        )
          .then(() => {
            props.onClose && props.onClose();
          })
          .catch((err) => {
            handleVerifyError(err);
          });
      } else if (props.isApprover) {
        dispatch(
          verifyApproverOTP(
            props.documentId,
            props.recipientId,
            currentOTP ? currentOTP.toUpperCase() : otp.toUpperCase(),
          ),
        )
          .then(() => {
            props.onClose && props.onClose();
          })
          .catch((err) => {
            handleVerifyError(err);
          });
      } else {
        dispatch(
          verifySigningOTP(
            props.documentId,
            props.recipientId,
            currentOTP ? currentOTP.toUpperCase() : otp.toUpperCase(),
            props.publicVariable,
          ),
        )
          .then(() => {
            props.onClose && props.onClose();
          })
          .catch((err) => {
            handleVerifyError(err);
          });
      }
    } else {
      setIsError(true);
      setErrorMessage(t('OTPVerifyErrorOTPRequired'));
    }
  };

  const handleVerifyError = (err) => {
    setIsError(true);
    decrementAvailableVerifyAttempt();
    if (err.response?.data?.error_description === OTP_INVALID) {
      setErrorMessage(t('OTPVerifyErrorOTPDidNotMatch'));
    } else if (err.response?.data?.error_description === OTP_EXPIRED) {
      setErrorMessage(t('OTPVerifyErrorOTPExpired'));
    } else if (err?.request?.status === 429) {
      setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
      setAvailableVerifyAttempt(0);
    } else {
      setErrorMessage(t('OTPVerifyErrorConfirmation'));
      decrementAvailableVerifyAttempt();
    }
  };

  const handleSendOTP = () => {
    if (disableGenerateButton) {
      return;
    }

    resetOTP();
    if (props.isObserver) {
      dispatch(sendVerifyObserverOTP(props.documentId, props.recipientId, props.publicVariable))
        .then(() => {
          if (!isSentOTPFirstTime) {
            setIsSentOTPFirstTime(true);
          }
          setDisableGenerateButton(true);
          setCountDownTime(60);
        })
        .catch((err) => {
          setIsError(true);
          if (err?.request?.status === 429) {
            setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
            setAvailableVerifyAttempt(0);
          } else {
            setErrorMessage(t('OTPVerifyErrorSendingOTP'));
          }
        });
    } else if (props.isApprover) {
      dispatch(sendVerifyApproverOTP(props.documentId, props.recipientId))
        .then(() => {
          if (!isSentOTPFirstTime) {
            setIsSentOTPFirstTime(true);
          }
          setDisableGenerateButton(true);
          setCountDownTime(60);
        })
        .catch((err) => {
          setIsError(true);
          if (err?.request?.status === 429) {
            setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
            setAvailableVerifyAttempt(0);
          } else {
            setErrorMessage(t('OTPVerifyErrorSendingOTP'));
          }
        });
    } else {
      dispatch(sendVerifySigningOTP(props.documentId, props.recipientId, props.publicVariable))
        .then(() => {
          if (!isSentOTPFirstTime) {
            setIsSentOTPFirstTime(true);
          }
          setDisableGenerateButton(true);
          setCountDownTime(60);
        })
        .catch((err) => {
          setIsError(true);
          if (err?.request?.status === 429) {
            setErrorMessage(t('OTPVerifyErrorTooManyRequest'));
            setAvailableVerifyAttempt(0);
          } else {
            setErrorMessage(t('OTPVerifyErrorSendingOTP'));
          }
        });
    }
  };

  const getOTPAction = () => {
    if (props.isObserver) {
      return t('OTPActionObserve');
    } else if (props.isApprover) {
      return t('OTPActionApprove');
    } else {
      return t('OTPActionSign');
    }
  };

  const getCountdownString = () => {
    const minutes = countdownObject.minutes;
    const seconds = countdownObject.seconds;

    const minutesText = minutes > 0 ? `${minutes}${t('OTPVerifyMinutes')}` : '';
    const secondsText = seconds > 0 ? `${seconds}${t('OTPVerifySeconds')}` : '';

    return `${minutesText} ${secondsText}`;
  };

  useEffect(() => {
    setCountdownObject(getTimeBreakdown(countDownTime));
  }, [countDownTime]);

  useEffect(() => {
    if (!countDownTime) {
      setDisableGenerateButton(false);
    }

    const timer = countDownTime > 0 && setInterval(() => setCountDownTime(countDownTime - 1), 1000);
    return () => clearInterval(timer);
  }, [countDownTime]);

  useEffect(() => {
    const hasFullLengthOtp = () => otp.length === 7;
    setDisableButton(!hasFullLengthOtp());
  }, [otp]);

  useEffect(() => {
    if (availableVerifyAttempt <= 0) {
      setDisableButton(true);
      disableGenerateButtonOverOtpFailedCount();
    }
  }, [availableVerifyAttempt]);

  return (
    <div className="otp-verification-container">
      <div className="confirm-content">
        <div className="otp-verify-icon-wrapper">
          <img className="otp-verify-icon" src={loggedInIcon} alt="" />
        </div>
        <div className="security-header">
          {t('OTPVerifyHeaderConfirm')} {getOTPAction()} {t('OTPVerifyHeaderDocument')}
        </div>
        <div className="security-message">
          {props.method === TWO_FA_METHOD_EMAIL && (
            <>
              {t('OTPVerifySubHeaderEmail')}&nbsp;
              <br />
              {ellipsis(props.email)}
            </>
          )}
          {props.method === TWO_FA_METHOD_SMS && (
            <>
              {t('OTPVerifySubHeaderPhoneNumber')}&nbsp;
              <br />
              {ellipsis(props.phone)}
            </>
          )}
        </div>
      </div>
      <Grid item xs={12} md={12} className="mb2 mt2">
        <OtpInput
          isDisabled={!isSentOTPFirstTime || props.isVerifyingOTP}
          containerStyle={{
            justifyContent: 'center',
          }}
          className={'otp-input-item'}
          value={otp}
          onChange={(otp) => {
            setOTP(otp.toUpperCase());
            setIsError(false);
            if (otp.length === 7) {
              handleVerify(null, otp);
            }
          }}
          hasErrored={isError}
          errorStyle={{ border: '2px solid rgb(162, 45, 16)', borderRadius: '4px' }}
          numInputs={7}
        />
      </Grid>
      <Grid container className="back-security-container">
        <Grid container item md={12} sm={12} lg={12} justifyContent="center">
          <div className="resend-code">
            <span
              className={`${
                disableGenerateButton ? 'generate-code-text-disable' : 'generate-code-text'
              }`}
              onClick={handleSendOTP}
            >
              {t('OTPVerifySendCodeButton')}
            </span>
            <p className="resend-code-time">
              {!!countDownTime &&
                countDownTime <= 60 &&
                `${t('OTPVerifyResend')} ${countDownTime}${t('OTPVerifySeconds')}`}
            </p>
          </div>
        </Grid>
        <Grid
          container
          item
          md={12}
          sm={12}
          lg={12}
          justifyContent="center"
          className="mb2 attempts-left"
        >
          {availableVerifyAttempt < 5 && (
            <>
              ({availableVerifyAttempt} {t('OTPVerifyAttemptsLeft')})
              <br />
              {availableVerifyAttempt === 0 && `${t('OTPVerifyTryAgain')} ${getCountdownString()}`}
            </>
          )}
        </Grid>
        <Grid container item md={12} sm={12} lg={12} justifyContent="center" className="mb2">
          <button
            className={
              (disableButton && 'disable-button' + props.isVerifyingOTP) || isRequestingOTP
                ? 'submit-button-security submit-button-security-disabled'
                : 'submit-button-security'
            }
            onClick={(e) => {
              if (!props.isVerifyingOTP && !isRequestingOTP) {
                handleVerify(e);
              }
            }}
            disabled={disableButton}
          >
            {props.isVerifyingOTP ? (
              <>{t('OTPVerifyProcessingButton')}</>
            ) : (
              <>{t('OTPVerifyContinueButton')}</>
            )}
          </button>
        </Grid>

        <div className="group-link">
          <a
            className="link-info-introduction"
            href="https://www.dedoco.com/terms-of-use"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms & Conditions
          </a>
          <span className="dot-style"></span>
          <a
            className="link-info-introduction"
            href="https://www.dedoco.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </Grid>

      <Snackbar
        open={isError}
        onClose={() => {
          setIsError(false);
          setErrorMessage('');
        }}
        className="snackbar-popup snackbar-popup-error"
        message={
          <>
            <CancelIcon style={{ color: '#A22D10' }} /> {errorMessage}
          </>
        }
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setIsError(false);
                setErrorMessage('');
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
