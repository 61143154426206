import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  recordApproverTermsAndConditionsConsent,
  recordObserverTermsAndConditionsConsent,
  recordSignerTermsAndConditionsConsent,
} from '../../lib/api';
import { ACCEPT_TNC } from '../../redux/actions/actiontypes';
import { useDedocoSigning } from '../useDedocoSigning';
import useRecipientRole from '../useRecipientRole';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function useTnC() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentParticipant, businessProcess, documentToSign } = useDedocoSigning();
  const { RecipientRoles } = useRecipientRole();
  const { uid } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSubmitTnC = async (bpId) => {
    let success = false;
    if (currentParticipant.role === RecipientRoles.SIGNER) {
      success = await recordSignerTermsAndConditionsConsent(
        bpId ?? businessProcess?.id,
        uid,
        documentToSign.isOpenApiV2,
      );
    } else if (currentParticipant.role === RecipientRoles.OBSERVER) {
      success = await recordObserverTermsAndConditionsConsent(
        bpId ?? businessProcess?.id,
        uid,
        documentToSign.isOpenApiV2,
      );
    } else if (currentParticipant.role === RecipientRoles.APPROVER) {
      success = await recordApproverTermsAndConditionsConsent(
        bpId ?? businessProcess?.id,
        uid,
        documentToSign.isOpenApiV2,
      );
    }
    if (success) {
      dispatch({ type: ACCEPT_TNC });
    } else {
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const TncSnackbar = () => {
    return (
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {t('RecordTermsAndConditionsConsentFailure')}
        </Alert>
      </Snackbar>
    );
  };

  return { TncSnackbar, handleSubmitTnC };
}

export { useTnC };
