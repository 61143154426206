import { styled } from '@mui/material';

const PREFIX = 'TermsAndCondition';

export const classes = {
  termsConditionContainer: `${PREFIX}-container`,
  termsConditionContent: `${PREFIX}-content`,
  proceedButton: `${PREFIX}-procced-button`,
};

export const Root = styled('div')(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.stroke.light,
  marginInline: 'auto',
  padding: theme.spacing(4),
  margin: theme.spacing(4),
  borderRadius: '5px',
  display: 'grid',

  [`& .${classes.termsConditionContainer}`]: {
    backgroundColor: theme.palette.supporting.main,
    display: 'flex',
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    border: 'solid 2px',
    borderColor: theme.palette.lightGrey.border,
    padding: theme.spacing(3),
  },

  [`& .${classes.termsConditionContent}`]: {
    backgroundColor: theme.palette.supporting.main,
    flexGrow: 1,
    width: 0,
  },

  [`& .${classes.proceedButton}`]: {
    marginBlock: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    color: theme.palette.background.main,
    borderRadius: '8px',
  },

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    width: fullWidth ? '100%' : 'auto',
  },
}));
