import { ThemeProvider } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useWebexContext } from '../../contexts/useWebexContext';
import { WebexEvents } from '../../hooks/useWebexSigning/constants';
import { dedocoTheme } from '../../themes/dedocoTheme';
import { WebexSteps } from './constants';
import Content from './Content';
import initializeWebexApp from './Webex';

const Initiator = () => {
  const [shareAppUrl, setShareAppUrl] = useState(null);
  const { connect, setMeetingHost, setIsWebexSigning, userIdentitySelected, addEventListener } =
    useWebexContext();

  const [step, setStep] = useState(
    !userIdentitySelected ? WebexSteps.LOADING_PAGE : WebexSteps.ENTER_SIGNING_LINK,
  );
  const [documentId, setDocumentId] = useState();

  const getShareAppUrlFunc = useCallback(async () => {
    const { setSharedAppUrl: shareAppUrlFunc } = await initializeWebexApp(window.location.origin);
    setShareAppUrl(() => shareAppUrlFunc);
  }, []);

  useEffect(() => {
    getShareAppUrlFunc();
  }, [getShareAppUrlFunc]);

  useEffect(() => {
    if (!shareAppUrl) return;

    connect();
    setIsWebexSigning(true);
    setMeetingHost(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareAppUrl]);

  useEffect(() => {
    if (!documentId || !shareAppUrl) return;

    addEventListener(WebexEvents.CREATED_ROOM, () => {
      shareAppUrl(documentId);
      setStep(WebexSteps.CLAIM_ROLE);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, shareAppUrl]);

  useEffect(() => {
    setTimeout(() => {
      setStep(WebexSteps.ENTER_SIGNING_LINK);
    }, 2000);
  }, []);

  return (
    <ThemeProvider theme={dedocoTheme}>
      <Content step={step} setStep={setStep} setDocumentId={setDocumentId} />
    </ThemeProvider>
  );
};

export default Initiator;
