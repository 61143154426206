import JTCLogo from '../assets/jtc-logo.png';
import DedocoLogo from '../assets/dedoco-logo.png';

export const getLogoFromCompanyName = (companyName) => {
  const companyNameToLogoMap = {
    dedoco: DedocoLogo,
    jtc: JTCLogo,
  };

  return companyNameToLogoMap[companyName.toLowerCase()] || DedocoLogo;
};
