import { styled } from '@mui/material';

const PREFIX = 'DedocoSigningRequest';

export const classes = {
  root: `${PREFIX}-root`,
};

export const Root = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  height: '100%',
  position: 'relative',
}));
