import { PageLayout } from '../Layout';
import './MaintenancePage.css';
import MaintenanceIcon from './../../assets/maintenance.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button } from '@mui/material';

function MaintenancePage() {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = new Date();

  const rerouteDedoco = () => {
    window.location = 'https://dedoco.com/';
  };

  return (
    <PageLayout>
      <div className="main-container">
        <div className="maintenance-container">
          <img alt="icon" src={MaintenanceIcon} className="maintenance-icon" />
          <div className="maintenance-details-container">
            <h2>We will be back soon!</h2>
            <p>Digital Signature is currently under service maintenance during:</p>
            <p>
              <CalendarTodayIcon className="maintenance-icons-style" />
              <span>
                {' '}
                {weekday[day.getDay()]}, {day.getDate()} {months[day.getMonth()]}{' '}
                {day.getFullYear()}
              </span>
            </p>
            <p>
              <AccessTimeIcon className="maintenance-icons-style" /> 00:00 SGT - 08:00 SGT
            </p>
            <p>Other services are still functional. Thank you for your patience.</p>
            <Button className="homepage-button" onClick={rerouteDedoco}>
              Back to Homepage
            </Button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default MaintenancePage;
