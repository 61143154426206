import { useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePdfTronContext } from '../../../../../../../contexts/usePdfTronContext';
import {
  formStateSelector,
  sidebarFieldItemClick,
  updateSelectedFormFieldId,
} from '../../../../../../../redux/slices/formSlice';
import { FormFieldTypes } from '../../../../constants';
import { Root } from './FieldItem.styles';

const FieldStatus = Object.freeze({
  notFilled: 'notFilled',
  isFilled: 'isFilled',
  isDefaultValue: 'isDefaultValue',
});

export const RequiredAsterick = () => {
  return <span style={{ color: 'red', fontSize: '15px', marginLeft: '5px' }}>*</span>;
};

const FieldItem = ({ type, item }) => {
  const { fieldId, pageNumber, label, isRequired } = item;
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { formFillerInputs, selectedFieldId } = useSelector(formStateSelector);
  const { jumpTo } = usePdfTronContext();

  const inputDetails = formFillerInputs[type]?.find((field) => field.fieldId === fieldId);
  let status = FieldStatus.notFilled;

  if (inputDetails?.shouldConsiderForFurtherChange) {
    status = FieldStatus.isDefaultValue;
  } else if (inputDetails) {
    status = FieldStatus.isFilled;
  }

  const isChecked = status === FieldStatus.isDefaultValue || status === FieldStatus.isFilled;

  const isSelected = selectedFieldId === fieldId;
  const handleClick = useCallback(() => {
    dispatch(updateSelectedFormFieldId(fieldId));
    dispatch(sidebarFieldItemClick());
    jumpTo(fieldId);
  }, [dispatch, fieldId, jumpTo]);

  return (
    <Root
      data-testid={`form-sidebar-field-item-(${type})-${fieldId}`}
      onClick={handleClick}
      isSelected={isSelected}
    >
      <div>
        <Checkbox
          checked={isChecked}
          sx={{
            '&.Mui-checked': {
              color: status === FieldStatus.isFilled ? 'success.primary' : 'lightGrey.main',
            },
            ':hover': {
              backgroundColor: 'transparent',
            },
            '.MuiTouchRipple-root': {
              display: 'none !important',
            },
          }}
        />
        <Typography variant="label_small" color={isSelected ? 'primary.main' : 'lightGrey.primary'}>
          {label}
          {isRequired && type !== FormFieldTypes.digiSignatures && <RequiredAsterick />}
        </Typography>
      </div>
      <Typography variant="label_small" sx={{ padding: theme.spacing(4, 2) }}>
        {t('SigningRequestDrawerPage')} {pageNumber}
      </Typography>
    </Root>
  );
};

export default FieldItem;
