import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import Link from '@mui/material/Link';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionActions from '@mui/material/AccordionActions';

import { FileDrop } from 'react-file-drop';
import { Alert, AlertTitle } from '@mui/lab';

import { DescriptionOutlined, ExpandLess, Description } from '@mui/icons-material';
import Box from '@mui/material/Box';

import { getFileHash } from '../../utils/hash';
import DocsVerifySuccessIcon from '../../assets/verify-success.png';
import DocsVerifyErrorIcon from '../../assets/verify-error.png';

import { useTranslation } from 'react-i18next';

import '../../App.css';

const PREFIX = 'CompareDocument';

const classes = {
  root: `${PREFIX}-root`,
  expanded: `${PREFIX}-expanded`,
  root2: `${PREFIX}-root2`,
  content: `${PREFIX}-content`,
  expanded2: `${PREFIX}-expanded2`,
  root3: `${PREFIX}-root3`,
  root4: `${PREFIX}-root4`,
  root5: `${PREFIX}-root5`,
  alert: `${PREFIX}-alert`,
  fileDrop: `${PREFIX}-fileDrop`,
  h3: `${PREFIX}-h3`,
  margin: `${PREFIX}-margin`,
  bold: `${PREFIX}-bold`,
  colorDefault: `${PREFIX}-colorDefault`,
  colorSuccess: `${PREFIX}-colorSuccess`,
  colorError: `${PREFIX}-colorError`,
  inputFile: `${PREFIX}-inputFile`,
  emptyBox: `${PREFIX}-emptyBox`,
  cancel: `${PREFIX}-cancel`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },

  [`& .${classes.alert}`]: {
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    color: '#4F4F4F',
    backgroundColor: '#fff2cc',
    padding: '10px !important',
    justifyContent: 'space-around',
  },

  [`& .${classes.fileDrop}`]: {
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    margin: 20,
    padding: '50px 20px',
  },

  [`& .${classes.h3}`]: {
    fontSize: 'medium',
    fontWeight: 700,
    marginBottom: 30,
  },

  [`&.${classes.margin}`]: {
    margin: '24px 0px',
  },

  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.colorDefault}`]: {
    color: '#22354C !important',
  },

  [`& .${classes.colorSuccess}`]: {
    color: '#27AE60',
  },

  [`& .${classes.colorError}`]: {
    color: '#DB7555',
  },

  [`& .${classes.inputFile}`]: {
    display: 'none',
  },

  [`& .${classes.emptyBox}`]: {
    height: '24px',
    width: '24px',
  },

  [`& .${classes.root2}`]: {
    border: 'none',
    marginBottom: -1,
    minHeight: 56,
    padding: '0px',
    '&.Mui-expanded': {
      minHeight: 56,
    },
  },

  [`& .${classes.content}`]: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },

  [`& .${classes.root3}`]: {
    border: '2px solid #DCDEE0',
    borderTop: 'none',
    borderRadius: 4,
    display: 'block',
    padding: theme.spacing(2),
  },

  [`& .${classes.root4}`]: {
    justifyContent: 'center',
  },

  [`& .${classes.root5}`]: {
    color: '#ffffff',
    fontWeight: 700,
    backgroundColor: '#27AE60',
    '&:hover': {
      backgroundColor: '#2abd68',
    },
  },
  [`& .${classes.cancel}`]: {
    color: '#000',
    fontWeight: 700,
  },
}));

const Accordion = MuiAccordion;

const AccordionSummary = MuiAccordionSummary;

const AccordionDetails = MuiAccordionDetails;

const AccordionActions = MuiAccordionActions;

const Button = MuiButton;

const CompareDocument = () => {
  const { t } = useTranslation();

  const documentToSign = useSelector((state) => state.documenttosign);

  const [expanded, setExpanded] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const getFileUpload = async (file) => {
    const hash = await getFileHash(file);
    setIsEmpty(!isEmpty);
    setIsVerified(hash === documentToSign?.document_hashes[0]);
  };

  const getAlertIconClassName = () => {
    return isEmpty ? classes.colorDefault : isVerified ? classes.colorSuccess : classes.colorError;
  };

  return (
    <Root className={classes.margin}>
      <Accordion
        square
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        classes={{
          root: classes.root,
          expanded: classes.expanded,
        }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{
            root: classes.root2,
            content: classes.content,
            expanded: classes.expanded2,
          }}
        >
          <Box className={`${classes.alert} flex`}>
            <DescriptionOutlined className={getAlertIconClassName()} />
            <div className="alert-text">
              <p className="m0">{t('CompareDocumentSubHeader')}</p>
              <p
                className="m0"
                dangerouslySetInnerHTML={{ __html: t('CompareDocumentSubHeaderText') }}
              ></p>
            </div>
            {expanded ? <ExpandLess /> : <Box className={classes.emptyBox}></Box>}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.root3,
          }}
        >
          <Typography variant="subtitle1" align="center" gutterBottom>
            {t('CompareDocumentDescription')}
          </Typography>

          {isEmpty ? (
            <>
              <Alert severity="info" icon={<Description />}>
                {t('CompareDocumentDragNDrop')} <strong>{documentToSign.fileName}</strong>{' '}
                {t('CompareDocumentDragNDropText')}
              </Alert>

              <FileDrop className={classes.fileDrop} onDrop={(files) => getFileUpload(files[0])}>
                <center>
                  <div className={classes.h3}>{t('CompareDocumentDragNDropFile')}</div>
                  <div className="image-upload">
                    <input
                      className={classes.inputFile}
                      accept="application/pdf"
                      id="compare-document-file-upload"
                      onChange={(e) => getFileUpload(e.target.files[0])}
                      type="file"
                    />

                    <label htmlFor="compare-document-file-upload">
                      <Button
                        // variant="contained"
                        component="span"
                        classes={{
                          root: classes.root5,
                        }}
                        size="small"
                      >
                        {t('CompareDocumentChooseFileButton')}
                      </Button>
                    </label>
                  </div>
                </center>
              </FileDrop>
            </>
          ) : isVerified ? (
            <Alert
              severity="success"
              icon={
                <img
                  style={{ width: 36, height: 36, margin: 'auto' }}
                  src={DocsVerifySuccessIcon}
                  alt="verify-success"
                />
              }
            >
              <AlertTitle className={classes.bold}>
                {t('CompareDocumentDocumentVerified')}
              </AlertTitle>
              {t('CompareDocumentDocumentVerifiiedText')}
            </Alert>
          ) : (
            <Alert
              severity="error"
              icon={
                <img
                  style={{ width: 36, height: 36, margin: 'auto' }}
                  src={DocsVerifyErrorIcon}
                  alt="verify-success"
                />
              }
            >
              <AlertTitle className={classes.bold}>
                {t('CompareDocumentDocumentVerificationFails')}
              </AlertTitle>
              {t('CompareDocumentDocumentVerifiationFailsText')}
            </Alert>
          )}

          <AccordionActions
            classes={{
              root: classes.root4,
            }}
          >
            {isEmpty ? (
              <MuiButton
                className={classes.cancel}
                variant="outlined"
                size="small"
                onClick={() => setExpanded(!expanded)}
              >
                {t('CompareDocumentCancelButton')}
              </MuiButton>
            ) : (
              <Link component="button" color="inherit" onClick={() => setIsEmpty(!isEmpty)}>
                {t('CompareDocumentVerifyAnotherDocumentLink')}
              </Link>
            )}
          </AccordionActions>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

export default CompareDocument;
