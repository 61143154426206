import { Grid, styled } from '@mui/material';

const PREFIX = 'EmailBox';

export const classes = {
  inner: `${PREFIX}-inner`,
  innerError: `${PREFIX}-innerError`,
  textField: `${PREFIX}-textField`,
  errorMessage: `${PREFIX}-errorMessage`,
  errorIcon: `${PREFIX}-errorIcon`,
  errorText: `${PREFIX}-errorText`,
};

export const EmailBoxRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderRadius: `6px !important`,
  width: '100%',

  [`& .${classes.inner}`]: {
    border: `1px dashed ${theme.palette.primary.light}`,
    minHeight: '136px',
    background: theme.palette.background.main,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    boxSizing: 'border-box',
  },

  [`& .${classes.innerError}`]: {
    border: `1px dashed #B41818`,
    minHeight: '136px',
    background: theme.palette.background.main,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    boxSizing: 'border-box',
  },

  [`textarea`]: {
    fontWeight: `400 !important`,
    '::placeholder': {
      fontSize: '14px !important',
    },
  },

  [`& .${classes.textField}`]: {
    width: `80% !important`,
  },

  [`& .${classes.errorMessage}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: '#B41818',
  },

  [`& .${classes.errorIcon}`]: {
    width: '16px',
    height: '16px',
  },

  [`& .${classes.errorText}`]: {
    fontSize: '14px',
  },
}));
