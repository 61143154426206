import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTimer } from 'react-timer-hook';
import { usePdfTronContext } from '../../usePdfTronContext';

import { completeITextPDF, prepareITextPDF } from '../../../lib/api';
import { getFormNdiSigningData, initFormNdiSigning, sendFormDocHash } from '../../../lib/api/forms';

import NdiSigningConfirmationModal from '../components/NdiSigningConfirmationModal';
import QrAndChallengeCodeModal from '../components/QrAndChallengeCodeModal';
import { blobToBase64 } from '../../../utils/base64';
import { SigningStates } from '../shared';
import { getPdfWithAnnotsAsDataUrl } from '../../../utils/pdftron';
import { useDispatch, useSelector } from 'react-redux';
import {
  formStateSelector,
  submitForm,
  updateFormFillerInput,
} from '../../../redux/slices/formSlice';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag, featureNameMap } from '../../../hooks/useFeatureFlag';

const FormNdiSigningContext = createContext({});

const FormNdiSigningProvider = ({ children }) => {
  const { t } = useTranslation();
  const { form } = useSelector(formStateSelector);
  const dispatch = useDispatch();
  const { instance } = usePdfTronContext();
  const userCertQueryInterval = useRef();
  const signatureQueryInterval = useRef();
  const [ndiSigningSession, setNdiSigningSession] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [signingStep, setSigningStep] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [qrAndChallengeCodeModalOpen, setQrAndChallengeCodeModalOpen] = useState(false);
  const [userCert, setUserCert] = useState('');
  const [iTextId, setITextId] = useState('');
  const [docHash, setDocHash] = useState('');
  const [ndiSignature, setNdiSignature] = useState('');
  const [allowNdiSigning, setAllowNdiSigning] = useState(false);
  const [ndiSigningSnackbarState, setNdiSigningSnackbarState] = useState({
    open: false,
    severity: '',
    message: '',
    duration: null,
  });
  const history = useHistory();
  const isSingPassSigningMaintenance = useFeatureFlag(featureNameMap.singPassSigningMaintenance);

  const cleanIntervals = useCallback(() => {
    if (signatureQueryInterval.current) {
      clearInterval(signatureQueryInterval.current);
    }
    if (userCertQueryInterval.current) {
      clearInterval(userCertQueryInterval.current);
    }
  }, []);

  const {
    seconds: timerSeconds,
    minutes: timerMinutes,
    restart: restartTimer,
    pause: pauseTimer,
  } = useTimer({
    // This is a required param, but it is not used as the timer's expiration will be set using "restartTimer" function
    expiryTimestamp: new Date(),
    // Timer should not start immediately. It should be started when needed.
    autoStart: false,
    onExpire: cleanIntervals,
  });

  const cleanUpNdiSigningState = useCallback(
    (isError) => {
      setNdiSigningSession({});
      setUserCert('');
      setDocHash('');
      setITextId('');
      setSigningStep('');
      cleanIntervals();
      setConfirmationModalOpen(false);
      pauseTimer();
      if (!isError) {
        setIsLoading(true);
        setQrAndChallengeCodeModalOpen(false);
        setHasError(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cleanIntervals],
  );

  const onConfirmation = useCallback(() => {
    setSigningStep(SigningStates.QR_SCANNING);
    setQrAndChallengeCodeModalOpen(true);
    setConfirmationModalOpen(false);
    setIsLoading(true);
  }, []);

  const cleanUpNdiSigningStateNoArgs = useCallback(() => {
    cleanUpNdiSigningState();
  }, [cleanUpNdiSigningState]);

  const createNdiSigningSession = useCallback(async () => {
    try {
      const res = await initFormNdiSigning(form.id);
      setNdiSigningSession({
        id: res.ndi_session_id,
        signRef: res.ndi_sign_ref,
        qrCode: res.ndi_qr_code?.payload,
        signRefExpirationTime: res.ndi_sign_ref_exp,
        challengeCode: res.ndi_challenge_code,
      });
    } catch (error) {
      setHasError(true);
      setNdiSigningSnackbarState({
        open: true,
        severity: 'error',
        message: t('NdiSigningCreateSessionFailed'),
        duration: 6000,
        onClose: (_, reason) => {
          if (reason === 'timeout') {
            setNdiSigningSnackbarState({
              open: false,
            });
          }
        },
      });
    }
  }, [form.id, t]);

  const initiateNdiSigning = useCallback(async () => {
    if (isSingPassSigningMaintenance) {
      history.push('/maintenance');
    }

    if (allowNdiSigning) {
      cleanUpNdiSigningState();
      setConfirmationModalOpen(true);
    }
  }, [allowNdiSigning, cleanUpNdiSigningState, isSingPassSigningMaintenance, history]);

  const queryUserCert = useCallback(() => {
    if (userCertQueryInterval.current) {
      clearInterval(userCertQueryInterval.current);
    }

    userCertQueryInterval.current = setInterval(async () => {
      try {
        const { status, ndi_signer_cert } = await getFormNdiSigningData(
          form.id,
          ndiSigningSession.id,
        );

        if (status !== 'pending-signature') {
          return;
        }

        clearInterval(userCertQueryInterval.current);

        if (!ndi_signer_cert) {
          throw new Error('Empty NDI user certificate');
        }

        setUserCert(ndi_signer_cert);
      } catch (e) {
        setHasError(true);
      }
    }, 1000);
  }, [form.id, ndiSigningSession.id]);

  const getITextPdfOutput = useCallback(
    async (userCert) => {
      const pdfDataUrl = await getPdfWithAnnotsAsDataUrl(instance);
      const digiSignaturePlacement = form.fields.digiSignatures[0].placement;

      let placementX = digiSignaturePlacement.x.includes('%')
        ? parseFloat(digiSignaturePlacement.x) / 100
        : digiSignaturePlacement.x;
      let placementY = digiSignaturePlacement.y.includes('%')
        ? parseFloat(digiSignaturePlacement.y) / 100
        : digiSignaturePlacement.y;

      return prepareITextPDF(
        pdfDataUrl.split(',')[1],
        userCert,
        placementX,
        placementY,
        digiSignaturePlacement.page,
        'small',
      );
    },
    [form.fields.digiSignatures, instance],
  );

  const prepareDocWithIText = useCallback(async () => {
    try {
      const { hash, id } = await getITextPdfOutput(userCert);
      if (!hash || !id) {
        throw new Error('Empty document hash or iText ID');
      }

      setDocHash(hash);
      setITextId(id);
    } catch (e) {
      setHasError(true);
    }
  }, [getITextPdfOutput, userCert]);

  const sendDocHashToUser = useCallback(async () => {
    try {
      await sendFormDocHash(form.id, ndiSigningSession.id, docHash);
    } catch (e) {
      setHasError(true);
    }
  }, [docHash, form.id, ndiSigningSession.id]);

  const completeNdiSigning = useCallback(
    async (ndiSignature) => {
      const signedForm = await completeITextPDF(iTextId, userCert, ndiSignature);
      const formBase64 = await blobToBase64(signedForm);

      const digiSignatureFieldId = form.fields.digiSignatures[0].fieldId;
      dispatch(
        updateFormFillerInput({
          fieldId: digiSignatureFieldId,
          value: ndiSignature,
          shouldConsiderForFurtherChange: false,
        }),
      );

      await dispatch(
        submitForm({
          formId: form.id,
          formBase64,
        }),
      );

      cleanUpNdiSigningState();
    },
    [form.id, form.fields.digiSignatures, userCert, iTextId, dispatch, cleanUpNdiSigningState],
  );

  const queryDigitalSignature = useCallback(async () => {
    if (signatureQueryInterval.current) {
      clearInterval(signatureQueryInterval.current);
    }

    signatureQueryInterval.current = setInterval(async () => {
      try {
        const { status, ndi_signature } = await getFormNdiSigningData(
          form.id,
          ndiSigningSession.id,
        );
        if (status === 'second-leg-failed') {
          throw new Error('NDI signing failed');
        } else if (status === 'completed') {
          if (!ndi_signature) {
            throw new Error('Empty NDI signature');
          }

          setNdiSignature(ndi_signature);
          clearInterval(signatureQueryInterval.current);
        }
      } catch (e) {
        setHasError(true);
      }
    }, 1000);
  }, [form.id, ndiSigningSession.id]);

  useEffect(() => {
    if (hasError) {
      cleanUpNdiSigningState(true);
      pauseTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, cleanUpNdiSigningState]);

  // Flow of signing is described in this useEffect
  // Other functions update the states
  useEffect(() => {
    if (signingStep === SigningStates.QR_SCANNING) {
      if (userCert) {
        setIsLoading(true);
        setSigningStep(SigningStates.SIGNING);
      } else if (ndiSigningSession.signRef) {
        const timeout = new Date(ndiSigningSession.signRefExpirationTime * 1000);
        restartTimer(timeout);

        setIsLoading(false);
        queryUserCert();
      } else {
        createNdiSigningSession();
      }
    } else if (signingStep === SigningStates.SIGNING) {
      if (ndiSignature) {
        completeNdiSigning(ndiSignature);
        setIsLoading(true);
      } else if (iTextId && docHash) {
        const timeout = new Date();
        timeout.setSeconds(timeout.getSeconds() + 120);
        restartTimer(timeout);

        setIsLoading(false);
        sendDocHashToUser();
        queryDigitalSignature();
      } else {
        prepareDocWithIText();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signingStep,
    createNdiSigningSession,
    ndiSigningSession,
    queryUserCert,
    userCert,
    prepareDocWithIText,
    iTextId,
    docHash,
    sendDocHashToUser,
    queryDigitalSignature,
    ndiSignature,
    completeNdiSigning,
  ]);

  const contextValue = useMemo(
    () => ({
      initiateNdiSigning,
      setAllowNdiSigning,
      ndiSigningSnackbarState,
      setNdiSigningSnackbarState,
    }),
    [initiateNdiSigning, ndiSigningSnackbarState],
  );

  return (
    <FormNdiSigningContext.Provider value={contextValue}>
      <NdiSigningConfirmationModal
        open={confirmationModalOpen}
        onClose={cleanUpNdiSigningStateNoArgs}
        onConfirm={onConfirmation}
      />
      <QrAndChallengeCodeModal
        open={qrAndChallengeCodeModalOpen}
        onClose={cleanUpNdiSigningStateNoArgs}
        qrData={ndiSigningSession.qrCode}
        challengeCode={ndiSigningSession.challengeCode}
        timerMinutes={timerMinutes}
        timerSeconds={timerSeconds}
        signingStep={signingStep}
        hasError={hasError}
        isLoading={isLoading}
        onRefreshQr={initiateNdiSigning}
      />
      {children}
    </FormNdiSigningContext.Provider>
  );
};

function useFormNdiSigningContext() {
  return useContext(FormNdiSigningContext);
}

export { FormNdiSigningProvider, useFormNdiSigningContext };
