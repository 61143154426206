import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { classes, Root } from './SidebarWrapperContainer.styles';
import { CypressIds } from '../../../../constant/cypressIds';

const SidebarWrapperContainer = (props) => {
  const {
    title,
    titleDivider,
    showStickyTitle,
    rightAction,
    content,
    footer,
    styles,
    newDedocoSigning = false,
  } = props;
  const { t } = useTranslation();
  const { resetCurrentField } = useGetRequiredDedocoSigning(newDedocoSigning);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const displayStickyFooter = () => {
    const isDesktopScreen = isMdUp;
    return footer && isDesktopScreen;
  };

  return (
    <Root container sx={styles}>
      <Grid item container className={classes.actionButtonGroup}>
        <Grid item>
          <Button
            className={classes.backButton}
            disableRipple
            disableElevation
            startIcon={<ArrowBackIcon />}
            data-cypress={CypressIds.sidebarBackButton}
            onClick={resetCurrentField}
          >
            <Typography variant="title_large">{t('SidebarBackButtonText')}</Typography>
          </Button>
        </Grid>
        <Grid item>{rightAction}</Grid>
      </Grid>

      {showStickyTitle && <Title showDivider={titleDivider}>{title}</Title>}
      <Grid
        container
        item
        gap={theme.spacing(2)}
        overflow="auto"
        alignContent="flex-start"
        alignItems="flex-start"
        flex={1}
      >
        {!showStickyTitle && <Title showDivider={titleDivider}>{title}</Title>}
        {content}
        {!displayStickyFooter && (
          <Grid item width="100%">
            <Grid item>{footer}</Grid>
          </Grid>
        )}
      </Grid>
      {displayStickyFooter && <Grid item>{footer}</Grid>}
    </Root>
  );
};

const Title = ({ children, showDivider }) => {
  if (!children) return null;
  return (
    <Stack spacing={(theme) => theme.spacing(2)} width="100%">
      <Typography className={classes.title} variant="title_large">
        {children}
      </Typography>
      {showDivider && <Divider width="100%" flexItem />}
    </Stack>
  );
};

export default SidebarWrapperContainer;
