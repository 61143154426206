// eslint-disable-next-line no-unused-vars
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { verifyOTPApi, sendVerifyOTPApi } from './otpApi';

// can change its name by need
const SLICE_NAME = 'otp';
export const NEW_VERIFY_OTP = `${SLICE_NAME}/verifyOTP`;

function handleApiError(state, { payload = {} }) {
  console.error(payload.desc);
  // Set error state with erorr code
  state.error = payload.code;
  state.isLoading = false;
}

export const verifyOTP = createAsyncThunk(
  NEW_VERIFY_OTP,
  async ({ id, uid, otp, isPublic, role }, { rejectWithValue }) => {
    try {
      const response = await verifyOTPApi({ id, uid, otp, isPublic, role });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendVerifyOTP = createAsyncThunk(
  `${SLICE_NAME}/sendVerifyOTP`,
  async ({ id, uid, isPublic, role }, { rejectWithValue }) => {
    try {
      const response = await sendVerifyOTPApi({ id, uid, isPublic, role });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  isLoading: false,
  error: null,
};

export const otpSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //verifyOTP
    builder.addCase(verifyOTP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTP.rejected, handleApiError);
    builder.addCase(verifyOTP.fulfilled, (state) => {
      state.isLoading = false;
    });
    //sendVerifyOTP
    builder.addCase(sendVerifyOTP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendVerifyOTP.rejected, handleApiError);
    builder.addCase(sendVerifyOTP.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

const { reducer } = otpSlice;

export const getOtpSelector = (state) => state[SLICE_NAME];

export default reducer;
