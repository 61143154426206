import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import verifyErrorIcon from '../../../../assets/error-verified.svg';
import verifySuccessIcon from '../../../../assets/success-verified.svg';
import { useDedocoSigning } from '../../../../hooks/useDedocoSigning';
import { getFileHash } from '../../../../utils/hash';
import UploadFile from '../../UI/UploadFile';
import { useDispatch } from 'react-redux';
import TermsAndCondition from '../TermsAndCondition';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import { useTnC } from '../../../../hooks/useTnC';
import {
  classes,
  Root,
  VerifyBoxUpload,
  VerifyBoxSummary,
  VerifyFailureBox,
  VerifySuccessBox,
  VerifyTermsAndCondition,
  VerifySuccessBoxContainer,
} from './DedocoHashVerifyBox.style';

import { updateFileUrl, verifyDedocoHashCheck } from '../../../../redux/actions/documentfunctions';

function DedocoHashVerifyBox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { TncSnackbar, handleSubmitTnC } = useTnC();

  const { documentToSign } = useDedocoSigning();

  const [isVerified, setIsVerified] = useState(false);
  const [isFileDropEmpty, setIsFileDropEmpty] = useState(true);
  const [file, setFile] = useState({ selectedfile: '', hash: '' });

  const handleFileUploaded = async (file) => {
    setFile({ selectedfile: file, hash: await getFileHash(file) });
    setIsFileDropEmpty(false);
  };

  const onHandleProceed = () => {
    dispatch(verifyDedocoHashCheck(true));
    handleSubmitTnC();
  };

  useEffect(() => {
    if (file.hash === documentToSign?.document_hashes[0]) {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(file.selectedfile);
      oFReader.onload = function (oFREvent) {
        dispatch(updateFileUrl(oFREvent.target.result));
      };
    }
    setIsVerified(file.hash === documentToSign?.document_hashes[0]);
  }, [dispatch, file.hash, documentToSign?.document_hashes, file.selectedfile]);

  const handleTryAgain = () => {
    setIsFileDropEmpty(true);
  };

  if (isFileDropEmpty)
    return (
      <Root>
        <VerifyBoxSummary>
          <Typography variant="label_small" className={classes.verifyDocumentHeader}>
            {t('VerifyDocumentHeader')}
          </Typography>

          <Typography variant="body_small" className={classes.verifyDescription}>
            {t('DedocoHashCheckSummaryOne')}
          </Typography>
          <Typography variant="body_small" className={classes.verifyDescription}>
            {t('DedocoHashCheckSummaryTwoA')}
            <b>{documentToSign?.fileName}</b>
            {t('DedocoHashCheckSummaryTwoB')}
          </Typography>
        </VerifyBoxSummary>
        <VerifyBoxUpload>
          <UploadFile
            onFilesUploaded={handleFileUploaded}
            accept="application/pdf"
            innerText={t('VerifyDropPdf')}
          />
        </VerifyBoxUpload>
      </Root>
    );

  // when file is uploaded
  if (isVerified)
    return (
      <VerifySuccessBoxContainer>
        <TncSnackbar />
        <VerifySuccessBox>
          <Typography variant="label_small" className={classes.documentVerified}>
            <img src={verifySuccessIcon} />
            {t('VerifyDocumentVerified')}
          </Typography>
          <Typography variant="body_small">{t('VerifyDocumentIdentical')}</Typography>
        </VerifySuccessBox>

        <VerifyTermsAndCondition>
          <TermsAndCondition noTitle={true} hideButton={true} fullWidth={false} />
          <Button
            variant="contained"
            onClick={onHandleProceed}
            sx={{
              width: `calc(100% - 64px)`,
              marginBottom: `${theme.spacing(8)}`,
              marginInline: `auto`,
            }}
          >
            {t('ProceedButton')}
          </Button>
        </VerifyTermsAndCondition>
      </VerifySuccessBoxContainer>
    );
  else {
    return (
      <VerifyFailureBox>
        <Typography variant="label_small" className={classes.documentVerifiedMismatch}>
          <img src={verifyErrorIcon} />
          {t('VerifyDocumentMismatch')}
        </Typography>
        <Typography variant="body_small">{t('VerifyDocumentNotIdentical')}</Typography>
        <Typography variant="body_small" className={classes.tryAgain} onClick={handleTryAgain}>
          {t('VerifyTryAgain')}
        </Typography>
      </VerifyFailureBox>
    );
  }
}

export default DedocoHashVerifyBox;
