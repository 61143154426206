import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRecipientRole from '../../../../hooks/useRecipientRole';
import Field from './Fields';
import FieldListSection from './FieldListSection';
import { generateFieldId } from '../../../../utils/pdftron';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';

export default function FieldList({ useNewDedocoSigning = false }) {
  const { t } = useTranslation();
  const { currentParticipant, projectSigners, getCurrentSignerIndex } =
    useGetRequiredDedocoSigning(useNewDedocoSigning);
  const { isSigner } = useRecipientRole();
  const [numberOfRequiredFields, setNumberOfRequiredFields] = useState(0);
  const [numberOfOptionalFields, setNumberOfOptionalFields] = useState(0);

  useEffect(() => {
    if (!currentParticipant) return;
    // If approver or observer, all field must be shown.
    let requiredFieldCount = 0;
    let optionalFieldCount = 0;
    if (isSigner) {
      ({ requiredFieldCount, optionalFieldCount } = getParticipantFieldCount(currentParticipant));
    } else {
      projectSigners.forEach((signer) => {
        const count = getParticipantFieldCount(signer);
        requiredFieldCount += count.requiredFieldCount;
        optionalFieldCount += count.optionalFieldCount;
      });
    }
    setNumberOfRequiredFields(requiredFieldCount);
    setNumberOfOptionalFields(optionalFieldCount);
  }, [currentParticipant, projectSigners, isSigner]);

  const getParticipantFieldCount = (participant) => {
    let requiredConfirmationsCount = 0;
    let optionalConfirmationsCount = 0;

    participant?.confirmations?.forEach((confirmation) => {
      if (confirmation.isRequired) {
        requiredConfirmationsCount++;
      } else {
        optionalConfirmationsCount++;
      }
    });
    const requiredFieldCount =
      requiredConfirmationsCount +
      participant?.esignatures?.length +
      participant?.digiSignatures?.length +
      participant?.customTexts?.length;

    const optionalFieldCount = optionalConfirmationsCount;

    return {
      requiredFieldCount,
      optionalFieldCount,
    };
  };

  const isSignerButNotCurrentSigner = (signerIndex) => {
    return isSigner && signerIndex !== getCurrentSignerIndex();
  };

  const renderElectronicSignatures = () => {
    const electronicSignatures = [];

    projectSigners.forEach((signer, signerIndex) => {
      if (isSignerButNotCurrentSigner(signerIndex)) {
        return;
      }

      signer.esignatures?.forEach((signature, idx) => {
        const fieldIdPrefix = !isSigner ? 'other-' : '';
        electronicSignatures.push({
          fieldId: generateFieldId(`${fieldIdPrefix}esignature`, idx, signerIndex),
          pageNumber: signature.placement.page,
          label: !isSigner ? `- ${signer.name}` : '',
        });
      });
    });

    return (
      <Field
        useNewDedocoSigning={useNewDedocoSigning}
        type="esignature"
        title={t('SidebarSignatures')}
        items={electronicSignatures}
        allowSignAll={electronicSignatures?.length > 1 && isSigner}
      />
    );
  };

  const renderDigitalSignatures = () => {
    const digitalSignatures = [];

    projectSigners.forEach((signer, signerIndex) => {
      if (isSignerButNotCurrentSigner(signerIndex)) {
        return;
      }

      signer.digiSignatures?.forEach((signature, idx) => {
        digitalSignatures.push({
          fieldId: generateFieldId('digi_signature', idx, signerIndex),
          pageNumber: signature.placement.page,
          label: !isSigner ? `- ${signer.name}` : '',
        });
      });
    });

    return (
      <Field
        useNewDedocoSigning={useNewDedocoSigning}
        type="digi_signature"
        title={t('SidebarSignatures')}
        items={digitalSignatures}
      />
    );
  };

  const renderConfirmations = (isRequired) => {
    const confirmations = [];

    projectSigners.forEach((signer, signerIndex) => {
      if (isSignerButNotCurrentSigner(signerIndex)) {
        return;
      }

      signer.confirmations?.forEach((confirmation, idx) => {
        if (isRequired) {
          if (!confirmation.isRequired) {
            return;
          }
        } else if (confirmation.isRequired) {
          return;
        }
        const fieldIdPrefix = !isSigner ? 'other-' : '';
        confirmations.push({
          fieldId: generateFieldId(`${fieldIdPrefix}checkbox`, idx, signerIndex),
          pageNumber: confirmation.placement.page,
          label: !isSigner ? `- ${signer.name}` : '',
        });
      });
    });

    return (
      <Field
        useNewDedocoSigning={useNewDedocoSigning}
        title={t('SidebarConfirmations')}
        type="confirmation"
        items={confirmations}
      />
    );
  };

  // custom texts includes: actual date, custom date, initials,
  // stamps, custom texts, dropdown lists
  const renderCustomTexts = () => {
    const actualDates = [];
    const customDates = [];
    const initials = [];
    const stamps = [];
    const texts = [];
    const emails = [];
    const dropdownLists = [];

    projectSigners.forEach((signer, signerIndex) => {
      if (isSignerButNotCurrentSigner(signerIndex)) {
        return;
      }
      signer.customTexts?.forEach((text, idx) => {
        const label = !isSigner ? `- ${signer.name}` : '';
        const fieldIdPrefix = !isSigner ? 'other-' : '';
        switch (text.type) {
          case 'actual-date':
            actualDates.push({
              fieldId: generateFieldId(`${fieldIdPrefix}actual-date`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'custom-date':
            customDates.push({
              fieldId: generateFieldId(`${fieldIdPrefix}custom-date`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'initials':
            initials.push({
              fieldId: generateFieldId(`${fieldIdPrefix}initials`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'stamp':
            stamps.push({
              fieldId: generateFieldId(`${fieldIdPrefix}stamp`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'text':
            texts.push({
              fieldId: generateFieldId(`${fieldIdPrefix}text`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'url':
            texts.push({
              fieldId: generateFieldId(`${fieldIdPrefix}url`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'alphanumeric':
            texts.push({
              fieldId: generateFieldId(`${fieldIdPrefix}alphanumeric`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'number':
            texts.push({
              fieldId: generateFieldId(`${fieldIdPrefix}number`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'email':
            emails.push({
              fieldId: generateFieldId(`${fieldIdPrefix}email`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          case 'dropdown-list':
            dropdownLists.push({
              fieldId: generateFieldId(`${fieldIdPrefix}dropdown-list`, idx, signerIndex),
              pageNumber: text.placement.page,
              label,
            });
            break;
          default:
            break;
        }
      });
    });

    return [
      <>
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarActualDates')}
          type="actual-date"
          items={actualDates}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarCustomDates')}
          type="custom-date"
          items={customDates}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarInitials')}
          type="initials"
          items={initials}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarStamps')}
          type="stamp"
          items={stamps}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarTexts')}
          type="text"
          items={texts}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarEmails')}
          type="email"
          items={emails}
        />
        <Field
          useNewDedocoSigning={useNewDedocoSigning}
          title={t('SidebarDropdownLists')}
          type="dropdown-list"
          items={dropdownLists}
        />
      </>,
    ];
  };

  const renderRequiredFieldSection = () => {
    const section = (
      <FieldListSection
        title={`${t('SigningRequestRequiredFieldsHeader')} (${numberOfRequiredFields})`}
      >
        {renderElectronicSignatures()}
        {renderDigitalSignatures()}
        {renderConfirmations(true)}
        {renderCustomTexts()}
      </FieldListSection>
    );

    return numberOfRequiredFields > 0 ? section : '';
  };

  const renderOptionalFieldSection = () => {
    const section = (
      <FieldListSection
        title={`${t('SigningRequestOptionalFieldsHeader')} (${numberOfOptionalFields})`}
      >
        {renderConfirmations(false)}
      </FieldListSection>
    );

    return numberOfOptionalFields > 0 ? section : '';
  };

  return (
    <>
      {renderRequiredFieldSection()}
      {renderOptionalFieldSection()}
    </>
  );
}
