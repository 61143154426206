import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useReplaceStringWithComponent } from '../../../hooks/useReplaceStringWithComponent';
import { classes, MobileNotSupportedContainer } from './Form.style';

function ContactSupportLink({ value }) {
  return (
    <a href="https://www.dedoco.com/contact-us" target="_blank" rel="noreferrer">
      {value}
    </a>
  );
}

export function BrowserNotSupported() {
  const { t } = useTranslation();
  const visitDedoco = useCallback(async (_e) => {
    window.location = 'https://dedoco.com/';
  }, []);

  const contactSupport = useReplaceStringWithComponent(
    t('ArriveInError'),
    t('ContactSupport'),
    ContactSupportLink,
  );

  return (
    <>
      <MobileNotSupportedContainer>
        <Typography align="left" variant="title_large">
          {t('BrowserNotSupportedTitle')}
        </Typography>
        <div className={classes.mobileNotSupportedDescription}>
          <Typography align="left" variant="body_medium">
            {t('BrowserNotSupportedDescription')}
          </Typography>
          <Typography align="left" variant="body_medium">
            {t('FillFormRequirement')}
          </Typography>
          <Typography align="left" variant="body_medium">
            {t('ApologyToBringBetterExperience')}
          </Typography>
        </div>
        <Button variant="contained" onClick={visitDedoco}>
          <Typography align="left" variant="label_large">
            {t('VisitDedocoHomepage')}
          </Typography>
        </Button>
      </MobileNotSupportedContainer>

      <MobileNotSupportedContainer>
        <Typography align="left" variant="body_medium">
          {contactSupport}
        </Typography>
      </MobileNotSupportedContainer>
    </>
  );
}
