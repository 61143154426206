import { Snackbar, Typography } from '@mui/material';
import { SnackbarContent } from './Snackbar.styles';

import SubmittingIcon from '../../../../assets/submitting-icon.svg';
import ErrorIcon from '../../../../assets/error-icon.svg';
import { useMemo } from 'react';

export const CustomSnackbar = ({ open, duration, message, onClose, severity }) => {
  const startIcon = useMemo(() => {
    if (severity === 'info') return SubmittingIcon;
    if (severity === 'error') return ErrorIcon;
    return null;
  }, [severity]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      key="top-center"
      autoHideDuration={duration}
      onClose={onClose}
    >
      <SnackbarContent className={severity}>
        <img alt="submitting-document" src={startIcon}></img>
        <Typography variant="label_medium">{message}</Typography>
      </SnackbarContent>
    </Snackbar>
  );
};
