import { Button, Stack, Typography, useTheme } from '@mui/material';
import SidebarWrapperContainer from '../SidebarWrapperContainer';
import { useTranslation } from 'react-i18next';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { useState } from 'react';

export const DropdownList = ({ label, options, newDedocoSigning = false }) => {
  const { t } = useTranslation();

  const { currentField } = useGetRequiredDedocoSigning(newDedocoSigning);
  const { instance } = usePdfTronContext();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const handleSave = () => {
    setSignatureWidgetText(options[selectedItemIndex], currentField.fieldId, instance);
  };

  const renderDropdownList = () => (
    <Stack width="100%">
      {options.map((option, i) => (
        <ListItem
          key={i}
          onClick={() => setSelectedItemIndex(i)}
          selected={i === selectedItemIndex}
        >
          {option}
        </ListItem>
      ))}
    </Stack>
  );

  return (
    <SidebarWrapperContainer
      newDedocoSigning={newDedocoSigning}
      title={label}
      titleDivider
      showStickyTitle
      content={renderDropdownList()}
      footer={
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={selectedItemIndex === -1}
          fullWidth
        >
          {t('ConfirmDropdownOption')}
        </Button>
      }
    />
  );
};

const ListItem = ({ children, onClick, selected }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: '44px',
        width: '100%',
        padding: theme.spacing(0, 3),
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: selected ? theme.palette.supporting.light : theme.palette.background.main,
        ['&:hover']: {
          backgroundColor: selected ? theme.palette.supporting.main : theme.palette.grey[100],
        },
      }}
      onClick={onClick}
    >
      <Typography
        variant="body_medium"
        color={selected ? 'primary.dark' : 'text.primary'}
        fontWeight={selected ? 'bold' : 'normal'}
      >
        {children}
      </Typography>
    </Stack>
  );
};
