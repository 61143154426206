export const validateEmail = (email) => {
  const atIndex = email.indexOf('@');
  const localPart = email.slice(0, atIndex);
  const domainPart = email.slice(atIndex + 1);

  return (
    atIndex !== -1 &&
    atIndex === email.lastIndexOf('@') &&
    localPart.length > 0 &&
    domainPart.length > 0 &&
    domainPart.indexOf('.') !== -1
  );
};

export const validateUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const validateAlphanumeric = (string) => {
  const alphanumeric = /^[a-z0-9]+$/i;
  return alphanumeric.test(string);
};

export const validateNumber = (string) => {
  const number = /^[0-9]+$/i;
  return number.test(string);
};
