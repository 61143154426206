import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRecipientRole from '../../../../hooks/useRecipientRole';
import DownloadButton from '../SidebarDownloadButton';
import { ActionButtonsRoot, classes } from './ActionButtons.style';
import RejectDocumentModal from '../../Modals/RejectDocumentModal';
import ConfirmDocumentApprovalModal from '../../Modals/ConfirmDocumentApprovalModal';
import useGetRequiredDedocoSigning from '../../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';
import { CypressIds } from '../../../../constant/cypressIds';

function ActionButtons({
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  handleDownloadPdf,
  isDisabled = true,
  newDedocoSigning = false,
}) {
  const { isSigner, isObserver, isApprover, currentUser } = useRecipientRole(newDedocoSigning);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const { documentToSign } = useGetRequiredDedocoSigning(newDedocoSigning);

  const [declineReason, setDeclineReason] = useState('');
  const [disableApproveDeclineButton, setDisableApproveDeclineButton] = useState(false);

  const handleApprovalConfirmation = () => {
    setOpenApprovalModal(true);
  };

  const onApprove = () => {
    setOpenApprovalModal(false);
    setDisableApproveDeclineButton(true);
    handleApproveDoc();
  };

  const onApproveModalClose = () => {
    setOpenApprovalModal(false);
  };

  const handleRejectDocument = () => {
    setOpenRejectModal(true);
  };

  const onReject = () => {
    // Reject document
    setOpenRejectModal(false);
    setDisableApproveDeclineButton(true);
    handleRejectDoc(declineReason);
  };

  const onRejectModalClose = () => {
    setOpenRejectModal(false);
  };

  const onInputChange = (e) => {
    setDeclineReason(e.target.value);
  };

  const shouldRenderRejectButton = () => {
    return !isObserver;
  };

  return (
    <>
      <ActionButtonsRoot>
        {isSigner && <SaveAndSubmitButton isDisabled={isDisabled} handleSignDoc={handleSignDoc} />}
        {isObserver && handleDownloadPdf && currentUser.is_downloadable && (
          <DownloadButton handleDownloadPdf={handleDownloadPdf} />
        )}
        {isApprover && (
          <ApproveDocumentButton
            onClick={handleApprovalConfirmation}
            isDisabled={disableApproveDeclineButton}
          />
        )}
        {shouldRenderRejectButton() && (
          <RejectDocumentButton
            onClick={handleRejectDocument}
            isDisabled={disableApproveDeclineButton}
          />
        )}
      </ActionButtonsRoot>

      {/* Modals related to action buttons */}
      <ConfirmDocumentApprovalModal
        open={openApprovalModal}
        onApprove={onApprove}
        onClose={onApproveModalClose}
      />

      <RejectDocumentModal
        open={openRejectModal}
        onReject={onReject}
        onClose={onRejectModalClose}
        documentName={documentToSign?.fileName}
        onInputChange={onInputChange}
      />
    </>
  );
}

const SaveAndSubmitButton = ({ handleSignDoc, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="sidebar-save-and-submit-button"
      variant="contained"
      color="primary"
      className={classes.submitButton}
      disabled={isDisabled}
      data-cypress={CypressIds.signerSaveAndConfirmButton}
      onClick={handleSignDoc}
    >
      <Typography className={classes.buttonText} variant="label_medium">
        {t('SaveAndConfirm')}
      </Typography>
    </Button>
  );
};

const RejectDocumentButton = ({ onClick, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      variant="contained"
      data-cypress={CypressIds.rejectDocumentButton}
      className={classes.rejectButton}
    >
      <Typography className={classes.buttonText} variant="label_medium">
        {t('RejectDocument')}
      </Typography>
    </Button>
  );
};

const ApproveDocumentButton = ({ onClick, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="sidebar-approve-button"
      onClick={onClick}
      disabled={isDisabled}
      variant="contained"
      data-cypress={CypressIds.approverApproveDocumentButton}
      className={classes.approveButton}
    >
      <Typography className={classes.buttonText} variant="label_medium">
        {t('ApproveDocument')}
      </Typography>
    </Button>
  );
};

export default ActionButtons;
