import { isRequestError } from '../../../utils/common';

const shouldRenderTnC = (documentToSign) => {
  return !documentToSign?.tncAccepted;
};

const shouldRenderOTP = (documentToSign) => {
  return documentToSign?.data?.require_authentication;
};

const shouldRenderCompletion = (documentToSign) => {
  return documentToSign?.submitDocumentCompleted;
};

const shouldRenderRejectedPage = (documentToSign) => {
  return documentToSign.isDocumentRejected;
};

const shouldRenderExpiryPage = (documentToSign) => {
  return isRequestError(documentToSign?.errorCode);
};

const shouldRenderApproval = (documentToSign) => {
  return documentToSign?.isDocumentApproved;
};

export {
  shouldRenderTnC,
  shouldRenderOTP,
  shouldRenderCompletion,
  shouldRenderRejectedPage,
  shouldRenderExpiryPage,
  shouldRenderApproval,
};
