import { Box } from '@mui/material';
import UploadFile from '../../../UI/UploadFile';
import SaveSignatureCheckbox from '../SaveSignatureCheckbox';

const UploadSignature = ({
  handleValueChange,
  enableSaveSignatureCheckbox,
  newDedocoSigning = false,
}) => {
  const onFileUploaded = (file) => {
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      const result = fileReader.result;
      handleValueChange?.({ value: result.toString(), type: 'image' });
    };
  };

  const onRemoveFile = () => handleValueChange(null);

  return (
    <Box p={4}>
      <UploadFile
        onFilesUploaded={onFileUploaded}
        accept="image/jpeg, image/png"
        innerText="Drop .png or .jpg here"
        onRemoveFile={onRemoveFile}
      />

      {enableSaveSignatureCheckbox && <SaveSignatureCheckbox newDedocoSigning={newDedocoSigning} />}
    </Box>
  );
};

export default UploadSignature;
