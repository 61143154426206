import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import signIcon from '../../../assets/sign.svg';
import {
  useNdiSigningContext,
  withNdiSigning,
} from '../../../contexts/ndiSigningContexts/useNdiSigningContext';
import { usePdfTronContext, withPdfTron } from '../../../contexts/usePdfTronContext';
import {
  useDedocoSigning,
  withDedocoSigning,
} from '../../../hooks/useDedocoSigning/useDedocoSigning.new';
import useDedocoVideoSigning from '../../../hooks/useDedocoVideoSigning';
import {
  DVideoSignEvents,
  DVideoSignAction,
  DVideoSignErrors,
} from '../../../hooks/useDedocoVideoSigning/constants';
import useTwilio from '../../../hooks/useTwilio';
import {
  setMeetingActive,
  otherRejectDocument,
  changeUpdate,
} from '../../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import { withDedocoTheme } from '../../../themes/dedocoTheme';
import {
  checkIfUserCompletedFillingObjects,
  getObjectedFilledChecklist,
  getPdfDataUrlsToDownload,
} from '../../../utils/pdftron';
import useUnsavedChangesWarning from '../../../utils/unsavedChanges';
import BlurredPdf from '../BlurredPdf';
import DedocoLayout from '../DedocoLayout';
import PdfWebViewer from '../PdfWebViewer/PdfWebViewer.new';
import { Root } from '../SigningRequest/SigningRequest.style';
import { dVideoSignSocket } from '../../../utils/socket';
import { CustomSnackbar } from '../UI/Snackbar';
import Sidebar from './Sidebar';
import Timer from './Timer';
import {
  getTwilioSelector,
  joinMeeting,
  setHasLeftMeeting,
} from '../../../redux/slices/twilioSlice/twilioSlice';
import MeetingView from './MeetingView';
import VideoSigningStore from '../../../hooks/useDedocoVideoSigning/VideoSigningStore';
import { hasCamera, hasMicrophone } from '../../../utils/userMedia';
import SingleStatusDialog from './Dialog/SingleStatusDialog';

function VideoSigningRequest() {
  const dVideoSignStore = useDedocoVideoSigning({ socket: dVideoSignSocket });
  const {
    isSigningSessionComplete,
    isMeetingStarted,
    joinRoom,
    startHeartbeat,
    stopHeartbeat,
    socket: videoSocket,
    connectHandler,
    registerLifeCycleEvents,
    registerEvent,
    getRoom,
    getAttendees,
    completeAction,
  } = dVideoSignStore;
  const { roomToken, hasLeftMeeting } = useSelector(getTwilioSelector);

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  // state
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: '',
    message: '',
    duration: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [fileShareKeyForDownload, setFileShareKeyForDownload] = useState('');
  const [hasModified, setHasModified] = useState(false);
  const [noOfRemainingFields, setNoOfRemainingFields] = useState(0);
  const [activatePrompt, deactivatePrompt] = useUnsavedChangesWarning().slice(1);

  const { instance } = usePdfTronContext();
  const {
    currentParticipant,
    documentToSign,
    approveDocument,
    rejectDocument,
    signerInputs,
    signDocument,
    signDocumentWithNdi,
    loadDocument,
    isDigitalSigning,
    signDocumentDigitally,
  } = useDedocoSigning();

  const room = getRoom();
  const recipientHasJoinedRoom = getAttendees().includes(documentToSign?.data?.recipientId);

  const { startOrJoinTwilioMeeting, leaveTwilioMeeting } = useTwilio();
  const [hasRequiredMediaDevices, setHasRequiredMediaDevices] = useState(true);

  //ndi signing
  const {
    initiateNdiSigning,
    snackbarDigitalState,
    setSnackbarDigitalState,
    setNdiSigningCompletedCallback,
    setAllowNdiSigning,
  } = useNdiSigningContext();
  const isUsingNdi = currentParticipant?.esignatures?.length === 0;

  useEffect(() => {
    const checkMedia = async () => {
      return (await hasCamera()) && (await hasMicrophone());
    };

    checkMedia()
      .then((result) => {
        setHasRequiredMediaDevices(result);
      })
      .catch(() => {
        // Do nothing.
      });
  }, []);

  useEffect(() => {
    isUsingNdi &&
      setNdiSigningCompletedCallback(() => {
        return ({ dssKey }) => {
          setFileShareKeyForDownload(dssKey);
          completeAction(id, documentToSign?.data?.recipientId, DVideoSignAction.SIGN, dssKey);
        };
      });
  }, [
    signDocumentWithNdi,
    setNdiSigningCompletedCallback,
    isUsingNdi,
    completeAction,
    id,
    documentToSign?.data?.recipientId,
  ]);

  useEffect(() => {
    if (snackbarDigitalState.open) {
      setSnackbarState(snackbarDigitalState);
    } else {
      setSnackbarState({
        ...snackbarDigitalState,
        open: false,
        duration: null,
      });
    }
  }, [snackbarDigitalState.open, snackbarDigitalState]);

  useEffect(() => {
    // websocket initialization and lifecyle event listener registration
    const unconnect = connectHandler();
    const unregisterEvents = registerLifeCycleEvents();
    return () => {
      unconnect();
      unregisterEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unregisterErrorEvent = registerEvent(DVideoSignEvents.ERROR, (data) => {
      let message = t('VideoSignUnknownError');

      switch (data.error) {
        case DVideoSignErrors.NOT_READY:
          message = t('VideoSignNotReady');
          break;
        case DVideoSignErrors.JOIN_ROOM_FAILED:
          message = t('VideoSignJoinMeetingFailed');
          break;
        case DVideoSignErrors.LEAVE_CALL_FAILED:
          message = t('VideoSignLeaveCallFailed');
          break;
        case DVideoSignErrors.START_MEETING_FAILED:
          message = t('VideoSignStartMeetingFailed');
          break;
        case DVideoSignErrors.NEXT_ACTION_FAILED:
          message = t('VideoSignNextActionFailed');
          break;
        case DVideoSignErrors.COMPLETE_ACTION_FAILED:
          message = t('VideoSignCompleteActionFailed');
          break;
        case DVideoSignErrors.END_CALL_FAILED:
          message = t('VideoSignEndCallFailed');
          break;
        case DVideoSignErrors.RECORD_SCREEN_FAILED:
          message = t('VideoSignRecordScreenFailed');
          break;
        case DVideoSignErrors.STOP_RECORD_SCREEN_FAILED:
          message = t('VideoSignStopScreenRecordFailed');
          break;
      }
      setSnackbarState({
        open: true,
        severity: 'error',
        duration: 3000,
        message,
      });
    });

    return () => {
      unregisterErrorEvent();
    };
  }, [registerEvent, t]);

  useEffect(() => {
    const unregisterErrorEvent = registerEvent(DVideoSignEvents.ERROR, (data) => {
      if (data.error === DVideoSignErrors.UNABLE_TO_ACQUIRE_LOCK) {
        history.replace('/video-sign/unable-to-join');
      }
    });

    return () => {
      unregisterErrorEvent();
    };
  }, [history, registerEvent]);

  useEffect(() => {
    // register event we need separate logic
    const unregisterMeetingStarted = registerEvent(DVideoSignEvents.MEETING_STARTED, () => {
      dispatch(setMeetingActive());
      dispatch(setHasLeftMeeting(false));
    });
    return () => {
      unregisterMeetingStarted();
    };
  }, [
    dispatch,
    documentToSign?.data?.recipientId,
    id,
    registerEvent,
    startHeartbeat,
    stopHeartbeat,
  ]);

  useEffect(() => {
    if (videoSocket.connected && documentToSign?.data?.recipientId) {
      // once user go to video sign page, let him join the room
      joinRoom(id, documentToSign.data.recipientId);
    }
  }, [documentToSign?.data?.recipientId, id, joinRoom, videoSocket.connected]);

  useEffect(() => {
    if (recipientHasJoinedRoom && isMeetingStarted && !roomToken && !hasLeftMeeting) {
      // if meeting already started, call /businessprocess/meeting/join to get room token
      dispatch(
        joinMeeting({
          documentId: id,
          name: documentToSign?.data?.recipientName,
          email: documentToSign?.data?.recipientEmail,
          userId: documentToSign?.data?.recipientId,
        }),
      )
        .unwrap()
        .then(({ roomToken }) => {
          startOrJoinTwilioMeeting(roomToken, id);
        });

      return () => {
        leaveTwilioMeeting();
      };
    }
  }, [
    dispatch,
    documentToSign?.data?.recipientEmail,
    documentToSign?.data?.recipientName,
    documentToSign?.data?.recipientId,
    id,
    recipientHasJoinedRoom,
    isMeetingStarted,
    roomToken,
    startOrJoinTwilioMeeting,
    leaveTwilioMeeting,
    hasLeftMeeting,
  ]);

  useEffect(() => {
    if (!documentToSign?.data?.recipientId || !id || !recipientHasJoinedRoom) return;

    startHeartbeat(id, documentToSign.data.recipientId);

    return () => {
      stopHeartbeat();
    };
  }, [
    documentToSign?.data?.recipientId,
    id,
    recipientHasJoinedRoom,
    startHeartbeat,
    stopHeartbeat,
  ]);

  // snackbar handler
  useEffect(() => {
    // TODO fix the snackbar cannot disappear issue
    const unregisterChooNextAction = registerEvent(
      DVideoSignEvents.CHOOSE_NEXT_ACTION,
      ({ selectActorLeftCall }) => {
        if (selectActorLeftCall) {
          // once receive choose-next-action, check if the selectActorLeftCall exist
          // if yes, means the left call user is current signer, should show the message
          setSnackbarState({
            open: true,
            severity: 'error',
            duration: 3000,
            message: `${selectActorLeftCall} ${t('selectActorLeftCall')}`,
          });
        }
      },
    );

    const unregisterChangeUpdate = registerEvent(DVideoSignEvents.CHANGE_UPDATE, () => {
      setSnackbarState({
        open: true,
        severity: 'info',
        duration: 3000,
        message: t('oneNewChangeMade'),
      });
    });
    return () => {
      unregisterChooNextAction();
      unregisterChangeUpdate();
    };
  }, [registerEvent, room.nextActor?.email, t]);

  // update document handler
  useEffect(() => {
    const unregisterSessionComplete = registerEvent(
      DVideoSignEvents.COMPLETED_SESSION,
      ({ room }) => {
        setFileShareKeyForDownload(room.dssKey);
      },
    );
    const unregisterChangeUpdate = registerEvent(
      DVideoSignEvents.CHANGE_UPDATE,
      async ({ room, action, documentData }) => {
        if (DVideoSignAction.REJECT === action) {
          dispatch(otherRejectDocument());
        } else if (DVideoSignAction.SIGN === action) {
          // Make sure loadDocument updates the pdfBase64String before dispatching the action to update the documentToSign state.
          // This allows the latest PDF to be loaded when the documentToSign update triggers the webviewer reload
          // Only NDI signing requires new document to be loaded from the new DSS key
          // Non-video signing should always load document using the dssKey from the URL.
          await loadDocument(isDigitalSigning ? room.dssKey : undefined);
          dispatch(changeUpdate(documentData));
          setFileShareKeyForDownload(room.dssKey);
        }
      },
    );
    return () => {
      unregisterChangeUpdate();
      unregisterSessionComplete();
    };
  }, [dispatch, id, loadDocument, registerEvent, isDigitalSigning]);

  // handle end call event
  useEffect(() => {
    const unregister = registerEvent(DVideoSignEvents.END_CALL, () => {
      // unconnect twilio
      leaveTwilioMeeting();
      // set meeting inactive
      dispatch(setMeetingActive(false));
    });
    return () => {
      unregister();
    };
  }, [dispatch, registerEvent, leaveTwilioMeeting]);

  // ===================

  useEffect(() => {
    const {
      isApprovingDocument,
      isSubmittingDocument,
      documentUpdatedApprovement,
      submitDocumentCompleted,
    } = documentToSign;

    if (hasModified || isApprovingDocument || isSubmittingDocument) {
      activatePrompt();
    }

    if (!hasModified || submitDocumentCompleted || documentUpdatedApprovement) {
      deactivatePrompt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasModified,
    activatePrompt,
    deactivatePrompt,
    documentToSign.isApprovingDocument,
    documentToSign.isSubmittingDocument,
    documentToSign.documentUpdatedApprovement,
    documentToSign.submitDocumentCompleted,
  ]);

  const handleSignDoc = async () => {
    if (isUsingNdi) {
      initiateNdiSigning();
      return;
    }

    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });

    setDisableSubmitButton(true);

    try {
      let shareKey;

      if (isDigitalSigning) {
        ({ shareKey } = await signDocumentDigitally(instance));
      } else {
        ({ shareKey } = await signDocument(instance));
      }

      setFileShareKeyForDownload(shareKey);
      setSnackbarState({
        ...snackbarState,
        open: false,
      });
      completeAction(id, documentToSign?.data?.recipientId, DVideoSignAction.SIGN, shareKey);
    } catch (err) {
      setSnackbarState({
        open: true,
        severity: 'error',
        message: err,
        duration: 3000,
        onClose: (_, reason) => {
          if (reason === 'timeout') {
            setSnackbarState({
              open: false,
              duration: null,
            });
          }
        },
      });
    } finally {
      setDisableSubmitButton(false);
    }
  };

  const handleApproveDoc = async () => {
    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });
    await approveDocument(instance);
    completeAction(id, documentToSign?.data?.recipientId, DVideoSignAction.APPROVE);
  };

  const handleRejectDoc = async (reason) => {
    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });
    await rejectDocument(reason);
    completeAction(id, documentToSign?.data?.recipientId, DVideoSignAction.REJECT);
  };

  useEffect(() => {
    if (documentToSign?.isDocumentApproved || documentToSign?.isDocumentRejected) {
      setSnackbarState({
        ...snackbarState,
        open: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToSign?.isDocumentApproved, documentToSign?.isDocumentRejected]);

  const handleDownloadPdf = useCallback(
    async (downloadOptionDetails) => {
      const saveFile = downloadOptionDetails.downloadFunction;
      const pdfDataUrls = await getPdfDataUrlsToDownload(
        documentToSign,
        instance,
        fileShareKeyForDownload,
        isSigningSessionComplete(),
        isDigitalSigning,
      );

      const fileInfo = {
        data: pdfDataUrls,
        filename: documentToSign.fileName,
      };

      saveFile(fileInfo);
    },
    [documentToSign, instance, fileShareKeyForDownload, isSigningSessionComplete, isDigitalSigning],
  );

  useEffect(() => {
    const objectsFilledChecklist = getObjectedFilledChecklist(signerInputs);
    const { documentHasCompleted, remainingFields } = checkIfUserCompletedFillingObjects(
      objectsFilledChecklist,
      currentParticipant,
    );
    setNoOfRemainingFields(remainingFields);
    setAllowNdiSigning(documentHasCompleted);
    setDisableSubmitButton(!documentHasCompleted);
    // eslint-disable-next-line
  }, [signerInputs, currentParticipant]);

  const renderContent = () => {
    if (
      ((documentToSign?.tncAccepted ||
        (documentToSign?.document?.document_share_method === 'manual' &&
          documentToSign.isDHCVerified)) &&
        isMeetingStarted) ||
      isSigningSessionComplete()
    ) {
      return (
        <PdfWebViewer
          noOfRemainingFields={noOfRemainingFields}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
          setHasModified={setHasModified}
        />
      );
    }
    return <BlurredPdf />;
  };

  return (
    <VideoSigningStore store={dVideoSignStore}>
      <DedocoLayout
        actionIcon={signIcon}
        actionIconAlt={'sign'}
        tailComponent={
          documentToSign.data &&
          isMeetingStarted &&
          room.meetingStartAt &&
          !hasLeftMeeting && <Timer startAt={room.meetingStartAt} />
        }
      >
        {!hasRequiredMediaDevices ? (
          <MediaDeviceNotFoundWarning />
        ) : (
          <Root>
            {isMeetingStarted && documentToSign?.tncAccepted && !hasLeftMeeting && (
              <MeetingView store={dVideoSignStore} />
            )}
            <CustomSnackbar
              {...snackbarState}
              onClose={(_, reason) => {
                if (snackbarDigitalState.open && reason === 'timeout') {
                  setSnackbarDigitalState({
                    ...snackbarState,
                    open: false,
                    duration: null,
                  });
                } else if (snackbarState.open && reason === 'timeout') {
                  setSnackbarState({
                    ...snackbarState,
                    open: false,
                  });
                }
                if (reason === 'clickaway') return;
                setSnackbarState({
                  ...snackbarState,
                  open: true,
                });
              }}
            />
            {renderContent()}
            <Sidebar
              videoSigningStore={dVideoSignStore}
              handleSignDoc={handleSignDoc}
              handleApproveDoc={handleApproveDoc}
              handleRejectDoc={handleRejectDoc}
              disableSubmitButton={disableSubmitButton}
              handleDownloadPdf={handleDownloadPdf}
            />
          </Root>
        )}
      </DedocoLayout>
    </VideoSigningStore>
  );
}

const MediaDeviceNotFoundWarning = () => {
  const { t } = useTranslation();
  return (
    <SingleStatusDialog
      bringToFront={true}
      open={true}
      content={t('RequiredMediaDeviceNotFoundMessage')}
      title={t('RequiredMediaDeviceNotFound')}
    />
  );
};

export default withDedocoTheme(
  withDedocoSigning(withPdfTron(withNdiSigning(VideoSigningRequest, true))),
);
