import { CircularProgress, Stack } from '@mui/material';

export function LoadingOverlay({ show }) {
  return (
    show && (
      <Stack
        sx={{
          zIndex: 9999,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Stack>
    )
  );
}
