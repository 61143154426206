import { styled } from '@mui/material';

const PREFIX = 'FileUploadWithPreview';

export const classes = {
  fileDrop: `${PREFIX}-fileDrop`,
  innerFileDrop: `${PREFIX}-innerFileDrop`,
  fileDropOr: `${PREFIX}-fileDropOr`,
  fileDropButton: `${PREFIX}-fileDropButton`,
  fileInput: `${PREFIX}-fileInput`,
  imagePreview: `${PREFIX}-image-preview`,
  chooseAnotherButton: `${PREFIX}-choose-another-button`,
};

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',

  [`& .${classes.fileDrop}`]: {
    backgroundColor: theme.palette.supporting.extraLight,
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
  },

  [`& .${classes.innerFileDrop}`]: {
    border: `2px dashed ${theme.palette.primary.light}`,
    height: '100%',
    width: '100%',
    background: theme.palette.supporting.extraLight,
    borderRadius: '8px',
    boxSizing: 'border-box',
  },

  [`& .${classes.fileDropOr}:before, .${classes.fileDropOr}:after`]: {
    content: '""',
    width: '32px',
    height: '0.8px',
    background: theme.palette.lightGrey.dark,
    display: 'inline-block',
    position: 'relative',
    opacity: 0.3,
    flex: 1,
    margin: theme.spacing(0, 0.8, 0.5, 0.8),
  },

  [`& .${classes.fileDropButton}`]: {
    backgroundColor: theme.palette.background.main,
    border: `1px solid ${theme.palette.supporting.main}`,
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    width: '155px',
    gap: theme.spacing(1),
    padding: theme.spacing(3, 3),
  },

  [`& .${classes.fileInput}`]: {
    display: 'none',
  },

  [`& .${classes.imagePreview}`]: {
    objectFit: 'contain',
    maxHeight: '86px',
    border: `1px solid ${theme.palette.stroke.dark}`,
    borderRadius: '4px',
  },

  [`& .${classes.chooseAnotherButton}`]: {
    borderRadius: '8px',
  },
}));
