// 2FA methods
export const TWO_FA_METHOD_SMS = 'SMS';
export const TWO_FA_METHOD_EMAIL = 'EMAIL';
export const TWO_FA_METHOD_SINGPASS = 'SINGPASS';
export const TWO_FA_METHOD_SINGPASS_SKIP_NRIC_CHECK = 'SINGPASS_SKIP_NRIC_CHECK';
export const TWO_FA_METHOD_DEFAULT = 'NO_AUTHENTICATION';

export const OTP_INVALID = 'INVALID_OTP';
export const OTP_EXPIRED = 'EXPIRED_OTP';

// Video Signing
export const ROOM_STARTED = 'ROOM_STARTED';
export const SIGNER_SIGNED = 'SIGNER_SIGNED';
export const PUBLIC_SIGNER_SIGNED = 'PUBLIC_SIGNER_SIGNED';
export const START_RECORDING = 'START_RECORDING';
export const STOP_RECORDING = 'STOP_RECORDING';
export const VS_NOTIFY_AT_50 = 'VS_NOTIFY_AT_50';
export const VS_NOTIFY_AT_60 = 'VS_NOTIFY_AT_60';
export const VS_NOTIFY_AT_80 = 'VS_NOTIFY_AT_80';
export const VS_NOTIFY_AT_89 = 'VS_NOTIFY_AT_89';

// PDFTron
export const MAX_ANNOTATION_IMAGE_SIZE_BYTES = 25600;

export const BASE64_PDF_PREFIX = 'data:application/pdf;base64,';

// Dedoco URL
export const DEDOCO_SUPPORT_EMAIL = 'support@dedoco.com';

// DSS Expiration
export const DSS_EXPIRATION_OFFSET = 14 * 24 * 3600;
