import { Box } from '@mui/material';
import { useCallback } from 'react';
import { FileUploadWithPreview } from '../../FileUploadWithPreview';

const UploadPad = ({ handleValueChange, maxFileSize }) => {
  const onFileUploaded = useCallback(
    (file) => {
      handleValueChange?.(file.dataUrl);
    },
    [handleValueChange],
  );

  const onRemoveFile = useCallback(() => handleValueChange(''), [handleValueChange]);

  return (
    <Box height="220px">
      <FileUploadWithPreview
        onFilesUploaded={onFileUploaded}
        accept="image/jpeg, image/png"
        innerText="Drag and drop .PNG or .JPG"
        onRemoveFile={onRemoveFile}
        maxFileSize={maxFileSize}
      />
    </Box>
  );
};

export default UploadPad;
