import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebexContext } from '../../../contexts/useWebexContext';
import { useDedocoSigning } from '../../../hooks/useDedocoSigning';
import useWindowSize from '../../../hooks/useWindowSize';
import { isCustomDate } from '../../../utils/dateWidget';
import { getOtpTarget } from '../../../utils/otp';
import { shouldRenderSigningPad } from '../../../utils/renderCondition';
import {
  isAlphanumeric,
  isCustomText,
  isNumber,
  isUrl,
  numberValidation,
} from '../../../utils/textWidget';
import DownloadButton from '../Buttons/DownloadButton';
import { FieldsPlaceholder } from '../Form/Sidebar/Placeholder';
import CustomTextBox from './CustomTextBox';
import CustomDatePicker from './DatePicker';
import DedocoHashVerifyBox from './DedocoHashVerifyBox';
import InfoSidebar from './InfoSidebar';
import OtpCheck from './OTPCheck';
import { DownloadButtonWrapper, Root } from './Sidebar.style';
import SignatureSigning from './SignatureSigning';
import { MAX_WIDTH } from './SignatureSigning/constants';
import { SingPassLogin } from './SingPassLogin';
import TermsAndCondition from './TermsAndCondition';
import { isEmail } from '../../../utils/emailWidget';
import EmailBox from './EmailBox';
import useRecipientRole from '../../../hooks/useRecipientRole';
import { validateAlphanumeric, validateUrl } from '../../../utils/validation';
import { isDropdownList } from '../../../utils/dropdownListWidget';
import { DropdownList } from './DropdownList';
import { getAnnotInfoFromFieldId } from '../../../utils/pdftron';
import SignerTabs from './SignerTabs';

function Sidebar({
  showOTP,
  showSingPassLogin,
  showExpiration,
  showTnC,
  showDHC,
  handleSignDoc,
  handleApproveDoc,
  handleRejectDoc,
  disableSubmitButton,
  handleDownloadPdf,
  noOfRemainingFields,
}) {
  const { currentField, documentToSign, annotsLoaded, projectSigners } = useDedocoSigning();
  const {
    isWebexSigning,
    isMeetingHost: isWebexMeetingHost,
    isWebexSigningSessionComplete,
  } = useWebexContext();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { currentUser } = useRecipientRole();

  const shouldRenderCompletion = useCallback(() => {
    return !!(isWebexSigningSessionComplete || documentToSign?.submitDocumentCompleted);
  }, [documentToSign?.submitDocumentCompleted, isWebexSigningSessionComplete]);

  const shouldRenderRejectedPage = useCallback(() => {
    return documentToSign.isDocumentRejected;
  }, [documentToSign?.isDocumentRejected]);

  const shouldRenderApproval = useCallback(() => {
    return documentToSign?.isDocumentApproved;
  }, [documentToSign?.isDocumentApproved]);

  const hasCompletedWebexSigning = () =>
    !!(
      isWebexSigningSessionComplete ||
      (!isWebexMeetingHost && isWebexSigning && documentToSign?.submitDocumentCompleted)
    );

  const renderSidebar = () => {
    const currentFieldId = currentField.fieldId;
    const isInstantSign = Boolean(documentToSign?.data?.isInstantSign);
    const nextSigner = documentToSign?.nextSigner;
    if (showExpiration) {
      return (
        <InfoSidebar
          title={t('DocumentExpiredTitle')}
          body={t('ExpiredDocumentBody')}
          showDownloadButton={false}
        />
      );
    } else if (!documentToSign.isValidDocHash && !showOTP && !showSingPassLogin) {
      return (
        <InfoSidebar
          title={t('DocumentNotUpdatedTitle')}
          body={t('DocumentNotUpdatedBody')}
          showDownloadButton={false}
        />
      );
    } else if (showDHC) {
      return <DedocoHashVerifyBox />;
    } else if (showSingPassLogin) {
      return <SingPassLogin />;
    } else if (showOTP) {
      return <OtpCheck otpTarget={getOtpTarget(documentToSign)} />;
    } else if (showTnC) {
      return <TermsAndCondition isLoading={documentToSign?.isLoading} />;
    } else if (shouldRenderCompletion()) {
      let title = t('CompletionSuccessTitle');
      let body = t('CompletionSuccessText');
      if (isInstantSign) {
        title = t('InstantSignCompletionSuccessTitle', {
          name: documentToSign?.data?.recipientName ?? '',
          completionMsg: nextSigner ? '' : t('DocumentIsNowComplete'),
        });
        if (nextSigner) body = '';
      }
      return (
        <>
          <InfoSidebar
            title={title}
            body={body}
            handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
            showDownloadButton={!isWebexSigning}
            showDemailTouchPoint={true}
            nextSigner={nextSigner}
          />
          {hasCompletedWebexSigning() && (
            <DownloadButtonWrapper>
              <DownloadButton
                fullWidth={true}
                localDownloadOnly={true}
                handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
              />
            </DownloadButtonWrapper>
          )}
        </>
      );
    } else if (shouldRenderRejectedPage()) {
      return (
        <InfoSidebar
          title={t('RejectDocumentTitle')}
          body={t('ExpiredDocumentBody')}
          handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
          showDownloadButton={false}
          showDemailTouchPoint={true}
        />
      );
    } else if (shouldRenderApproval()) {
      return (
        <InfoSidebar
          title={t('ApproveDocumentTitle')}
          body={t('ApproveDocumentBody')}
          handleDownloadPdf={currentUser?.is_downloadable && handleDownloadPdf}
          showDemailTouchPoint={true}
        />
      );
    } else if (!annotsLoaded) {
      return <FieldsPlaceholder />;
    } else if (!(currentField.isFilling && currentField.isUsingSidebar)) {
      return (
        <SignerTabs
          noOfRemainingFields={noOfRemainingFields}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
        />
      );
    } else {
      if (shouldRenderSigningPad(currentFieldId)) {
        return <SignatureSigning />;
      } else if (isCustomDate(currentFieldId)) {
        return <CustomDatePicker />;
      } else if (isCustomText(currentFieldId)) {
        return <CustomTextBox />;
      } else if (isUrl(currentFieldId)) {
        return <CustomTextBox validationFunc={validateUrl} errorMessage={t('EnterValidUrl')} />;
      } else if (isAlphanumeric(currentFieldId)) {
        return (
          <CustomTextBox
            validationFunc={validateAlphanumeric}
            errorMessage={t('NoSpecialCharacters')}
          />
        );
      } else if (isNumber(currentFieldId)) {
        return renderNumberTextBox();
      } else if (isEmail(currentFieldId)) {
        return <EmailBox />;
      } else if (isDropdownList(currentFieldId)) {
        const annotInfo = getAnnotInfoFromFieldId(currentFieldId);
        const dropdownInfo = projectSigners[annotInfo.signerIndex].customTexts[annotInfo.index];
        return <DropdownList label={dropdownInfo.label} options={dropdownInfo.options} />;
      }
    }
  };

  const renderNumberTextBox = () => {
    const numberValidationWrapper = (value, type) =>
      numberValidation(value, type, currentField.fieldId, projectSigners, t);
    return (
      <CustomTextBox
        validationFunc={(value) => numberValidationWrapper(value)}
        errorMessage={t('EnterValidNumber')}
        errorMessageFunc={(value) => numberValidationWrapper(value, 'error')}
      />
    );
  };

  const toShowSidebarCompletion = () => {
    const completedProgress =
      (!isWebexMeetingHost && shouldRenderCompletion()) ||
      shouldRenderRejectedPage() ||
      showExpiration ||
      shouldRenderApproval();
    return completedProgress;
  };

  const renderContent = () => {
    if (isMdUp) {
      if (toShowSidebarCompletion())
        return (
          <Root
            sx={{
              width: '50%',
              backgroundColor: 'transparent',
            }}
            windowSize={windowSize}
          >
            {renderSidebar()}
          </Root>
        );
      return (
        <Root
          sx={{
            width: MAX_WIDTH,
          }}
          windowSize={windowSize}
        >
          {renderSidebar()}
        </Root>
      );
    }
    if (hasCompletedWebexSigning()) {
      return (
        <Root sx={{ display: 'flex', width: '100%' }} windowSize={windowSize}>
          {renderSidebar()}
        </Root>
      );
    }
    return null;
  };

  return renderContent();
}

export default Sidebar;
