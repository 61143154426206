import { getColoredPenToolIcon } from '../assets/colorable-svgs';
import SignWithSingpassWidgetButton from '../assets/singpass-signing-widget-button.svg';
import FormPenIcon from '../assets/form-pen-icon.svg';
import FormStampIcon from '../assets/form-stamp-icon.svg';
import SingPassLogoFullColor from '../assets/singpass-logo-fullcolours.svg';
import { getElementBuilder } from './domStyling';
import {
  createFormWidgetLayout,
  createWidgetLayout,
  getWidgetLayoutImageWithoutWarp,
} from './pdftronWidgetCommon';

export const createSignatureWidgetAnnotation = async (
  fieldId,
  fieldFlags,
  signerName,
  widgetTitle,
  color,
  content,
  writable,
  pdfTronInstance,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  const widgetBoxDiv = createWidgetLayout(iframeWindow, {
    iconSrc: getColoredPenToolIcon(color),
    title: widgetTitle,
    color,
    tagText: signerName,
  });

  if (!writable) {
    if (content) {
      const imageAnnot = await createImageDisplayAnnotation(Annotations, content);
      widgetAnnot.setAssociatedSignatureAnnotation(imageAnnot);
      widgetBoxDiv.hideLabel();
    }

    widgetAnnot.createInnerElement = () => {
      return widgetBoxDiv;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      return widgetBoxDiv;
    };
  }

  return { widgetAnnot, field };
};

export const createESignatureWidgetAnnotation = (
  fieldId,
  fieldFlags,
  signerName,
  color,
  signature,
  writable,
  pdfTronInstance,
) => {
  return createSignatureWidgetAnnotation(
    fieldId,
    fieldFlags,
    signerName,
    'Signature',
    color,
    signature,
    writable,
    pdfTronInstance,
  );
};

export const createStampWidgetAnnotation = (
  fieldId,
  fieldFlags,
  signerName,
  color,
  content,
  writable,
  pdfTronInstance,
) => {
  return createSignatureWidgetAnnotation(
    fieldId,
    fieldFlags,
    signerName,
    'Stamp',
    color,
    content,
    writable,
    pdfTronInstance,
  );
};

export const createInitialsWidgetAnnotation = (
  fieldId,
  fieldFlags,
  signerName,
  color,
  content,
  writable,
  pdfTronInstance,
) => {
  return createSignatureWidgetAnnotation(
    fieldId,
    fieldFlags,
    signerName,
    'Initials',
    color,
    content,
    writable,
    pdfTronInstance,
  );
};

export const createImageDisplayAnnotation = async (Annotations, imageDataUrl) => {
  const stampAnnotation = new Annotations.StampAnnotation();
  await stampAnnotation.setImageData(imageDataUrl);

  stampAnnotation.dataSource = imageDataUrl;
  stampAnnotation.ReadOnly = true;
  stampAnnotation.NoDelete = true;
  stampAnnotation.NoMove = true;
  stampAnnotation.NoResize = true;

  return stampAnnotation;
};

export const createSingpassWidgetAnnotation = async (
  fieldId,
  signerName,
  color,
  clickable,
  fieldFlags,
  dimensions,
  initiateNdiSigningRef,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.NoDelete = true;
  widgetAnnot.Id = fieldId;

  const singpassWidgetLayout = createWidgetLayout(iframeWindow, {
    color,
    tagText: signerName,
    backgroundColor: '#FDDCDE',
  });
  const getStyledElement = getElementBuilder(iframeWindow);
  const singpassImageContainer = getStyledElement('div', () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));
  const singpassWidgetImage = await getWidgetLayoutImageWithoutWarp(
    SignWithSingpassWidgetButton,
    dimensions,
    iframeWindow,
    '70%',
  );
  singpassImageContainer.appendChild(singpassWidgetImage);

  singpassWidgetLayout.addBodyElement(singpassImageContainer, {
    fillWidget: true,
    hideOverflow: true,
  });

  if (clickable) {
    singpassWidgetLayout.addEventListener('click', () => {
      initiateNdiSigningRef.current?.();
    });
  }

  widgetAnnot.createSignHereElement = () => {
    return singpassWidgetLayout;
  };

  return { widgetAnnot, field };
};

export const createFormSingPassWidgetAnnotation = async (
  fieldId,
  fieldFlags,
  dimensions,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, {
    appearance: '_DEFAULT',
    appearances: {
      _DEFAULT: {
        Normal: {
          offset: {
            x: 100,
            y: 100,
          },
        },
      },
    },
  });

  widgetAnnot.Id = fieldId;

  const singPassWidgetLayout = createWidgetLayout(iframeWindow, {
    color: '#F8D9DC',
    backgroundColor: '#FAE9EB',
  });
  const getStyledElement = getElementBuilder(iframeWindow);
  const singPassImageContainer = getStyledElement('div', () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));

  const singPassWidgetImage = await getWidgetLayoutImageWithoutWarp(
    SingPassLogoFullColor,
    dimensions,
    iframeWindow,
    '60%',
  );

  singPassImageContainer.appendChild(singPassWidgetImage);
  singPassWidgetLayout.addBodyElement(singPassImageContainer, {
    fillWidget: true,
    hideOverflow: true,
  });

  widgetAnnot.createInnerElement = () => {
    return singPassWidgetLayout;
  };

  return { widgetAnnot, field };
};

export const createFormSignatureInputWidgetAnnotation = (
  fieldId,
  fieldFlags,
  signatureInput,
  iconSrc,
  labelText,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;

  const field = new Forms.Field(fieldId, {
    type: 'Sig',
    flags: fieldFlags,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field);

  widgetAnnot.Id = fieldId;

  const widgetBoxDiv = createFormWidgetLayout(pdfTronInstance.UI.iframeWindow, {
    labelText,
    isRequired: signatureInput.isRequired,
    iconSrc,
    iconWidth: '1.2em',
    widgetType: 'sign',
  });
  widgetAnnot.createInnerElement = () => {
    return widgetBoxDiv;
  };

  return { widgetAnnot, field };
};

export const createFormESignatureWidgetAnnotation = (
  fieldId,
  fieldFlags,
  esignatureInput,
  pdfTronInstance,
) => {
  return createFormSignatureInputWidgetAnnotation(
    fieldId,
    fieldFlags,
    esignatureInput,
    FormPenIcon,
    'Please sign here',
    pdfTronInstance,
  );
};

export const createFormInitialsWidgetAnnotation = (
  fieldId,
  fieldFlags,
  initialsInput,
  pdfTronInstance,
) => {
  return createFormSignatureInputWidgetAnnotation(
    fieldId,
    fieldFlags,
    initialsInput,
    FormPenIcon,
    'Initials',
    pdfTronInstance,
  );
};

export const createFormStampWidgetAnnotation = (
  fieldId,
  fieldFlags,
  stampInput,
  pdfTronInstance,
) => {
  return createFormSignatureInputWidgetAnnotation(
    fieldId,
    fieldFlags,
    stampInput,
    FormStampIcon,
    'Stamp',
    pdfTronInstance,
  );
};

export const isSignature = (id) => typeof id === 'string' && id.startsWith('esignature');
export const isSignAllSignature = (currentField) =>
  Array.isArray(currentField) && currentField[0]?.startsWith('esignature');
export const isInitials = (id) => typeof id === 'string' && id.startsWith('initials');
export const isStamp = (id) => typeof id === 'string' && id.startsWith('stamp');
