import { Alert, Button, Grid, Snackbar, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useTwilio from '../../../../hooks/useTwilio';
import {
  getDocumentToSignSelector,
  setMeetingActive,
} from '../../../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import { Box } from '../Sidebar.style';
import MicIcon from '../../../../assets/Mic.svg';
import CamIcon from '../../../../assets/Cam.svg';
import MicOffIcon from '../../../../assets/MicOff.svg';
import CamOffIcon from '../../../../assets/CamOff.svg';
import ShareScreenIcon from '../../../../assets/ShareScreen.svg';
import RecordScreenIcon from '../../../../assets/RecordScreen.svg';
import EndCallIcon from '../../../../assets/EndCall.svg';
import useDedocoVideoSigning from '../../../../hooks/useDedocoVideoSigning';
import { useParams, useHistory } from 'react-router-dom';
import TwoStatusDialog from '../Dialog/TwoStatusDialog';
import { classes as videoSignDialogClasses } from '../Dialog/Dialog.style';
import SingleStatusDialog from '../Dialog/SingleStatusDialog';
import { DVideoSignEvents } from '../../../../hooks/useDedocoVideoSigning/constants';
import {
  getTwilioSelector,
  setSharingScreen,
} from '../../../../redux/slices/twilioSlice/twilioSlice';
import Previews from './Previews';
import { LoadingButton } from '@mui/lab';
import { CypressIds } from '../../../../constant/cypressIds';

const lightButtonSx = (theme) => ({
  background: theme.palette.supporting.dark,
  width: '100%',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.supporting.main,
  },
});

const redButtonSx = (theme) => ({
  background: theme.palette.error.main,
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
});

export default function MeetingView() {
  const {
    leaveCall,
    documentToSign,
    isMeetingHost,
    endCall: emitEndCall,
    isSigningSessionComplete,
    registerEvent,
    recordScreen: emitRecordScreen,
    stopRecordScreen: emitStopRecordScreen,
    getRoom,
  } = useDedocoVideoSigning();
  const {
    renderRoom,
    leaveTwilioMeeting,
    toggleAudioMute,
    toggleVideoMute,
    shareScreen: _shareScreen,
    isConnecting,
    startRecording,
    stopRecording,
    stopShareScreen: _stopShareScreen,
  } = useTwilio();
  const { t } = useTranslation();
  const history = useHistory();
  const { folderName } = useSelector(getDocumentToSignSelector);
  const { isLoading, isRecordScreenLoading, isSharingScreen } = useSelector(getTwilioSelector);

  const { id } = useParams();
  const dispatch = useDispatch();

  const room = getRoom();

  const [micOn, setMicOn] = useState(true);
  const [camOn, setCamOn] = useState(true);
  const [isLeavingCall, setIsLeavingCall] = useState(false);
  const [leaveCallDialogOpen, setLeaveCallDialogOpen] = useState(false);
  const [isEndingCall, setIsEndingCall] = useState(false);
  const [endCallDialogOpen, setEndCallDialogOpen] = useState(false);
  const [recordScreenDialogOpen, setRecordScreenDialogOpen] = useState(false);
  const [recordScreenNotifyDialogOpen, setRecordScreenNotifyDialogOpen] = useState(false);
  const [stopRecordScreenSnackbarOpen, setStopRecordScreenSnackbarOpen] = useState(false);
  const [isScreenRecording, setScreenRecording] = useState(false);
  const [turnToSignDialogOpen, setTurnToSignDialogOpen] = useState(false);
  const [hasShowRecordScreenNotify, setHasShowRecordScreenNotify] = useState(false);

  const recipientId = documentToSign?.data?.recipientId;

  useEffect(() => {
    if (room.hasRecordedScreen && !isMeetingHost && !hasShowRecordScreenNotify) {
      setRecordScreenNotifyDialogOpen(true);
      setHasShowRecordScreenNotify(true);
    }
  }, [hasShowRecordScreenNotify, isMeetingHost, room.hasRecordedScreen]);

  useEffect(() => {
    const unregisterRecordScreen = registerEvent(DVideoSignEvents.RECORD_SCREEN, () => {
      if (!isMeetingHost) {
        setRecordScreenNotifyDialogOpen(true);
      }
    });
    const unregisterStopRecordScreen = registerEvent(DVideoSignEvents.STOP_RECORD_SCREEN, () => {
      setStopRecordScreenSnackbarOpen(true);
    });
    const unregisterNextAction = registerEvent(DVideoSignEvents.NEXT_ACTION, (nextActor) => {
      if (nextActor.userId === recipientId) {
        // next actor is me, show turn to sign and share screen dialog
        setTurnToSignDialogOpen(true);
      }
    });
    return () => {
      unregisterRecordScreen();
      unregisterStopRecordScreen();
      unregisterNextAction();
    };
  }, [recipientId, hasShowRecordScreenNotify, isMeetingHost, registerEvent]);

  useEffect(() => {
    if (!isLoading && !isConnecting && isMeetingHost) {
      // when twilio connected, start record screen
      setRecordScreenDialogOpen(true);
    }
  }, [isConnecting, isLoading, isMeetingHost]);

  const toggleMic = useCallback(() => {
    toggleAudioMute();
    setMicOn((prev) => !prev);
  }, [toggleAudioMute]);

  const toggleCam = useCallback(() => {
    toggleVideoMute();
    setCamOn((prev) => !prev);
  }, [toggleVideoMute]);

  const shareScreen = useCallback(() => {
    if (isSharingScreen) {
      setTurnToSignDialogOpen(false);
      return;
    }
    _shareScreen().then(() => {
      setTurnToSignDialogOpen(false);
      dispatch(setSharingScreen(true));
    });
  }, [_shareScreen, dispatch, isSharingScreen]);

  const stopShareScreen = useCallback(() => {
    _stopShareScreen().then(() => {
      dispatch(setSharingScreen(false));
    });
  }, [_stopShareScreen, dispatch]);

  const stopRecordScreen = useCallback(async () => {
    try {
      await stopRecording({ documentId: id });
      setScreenRecording(false);
      emitStopRecordScreen(id, recipientId);
    } catch (err) {
      // TODO show error message
      console.error('stop recording err', err);
    }
  }, [emitStopRecordScreen, id, stopRecording, recipientId]);

  const recordScreen = useCallback(() => {
    setRecordScreenDialogOpen(true);
  }, []);

  const onRecordScreenDialogCancel = useCallback(() => {
    setRecordScreenDialogOpen(false);
  }, []);

  const onRecordScreenDialogOk = useCallback(async () => {
    try {
      await startRecording({ documentId: id });
      setScreenRecording(true);
      setRecordScreenDialogOpen(false);
      emitRecordScreen(id, recipientId);
    } catch (err) {
      // TODO show error message
      console.error('start recording err', err);
    }
  }, [emitRecordScreen, id, startRecording, recipientId]);

  const endCall = useCallback(() => {
    if (isMeetingHost) {
      setEndCallDialogOpen(true);
    } else {
      setLeaveCallDialogOpen(true);
    }
  }, [isMeetingHost]);

  const onLeaveCallDialogCancel = useCallback(() => {
    setLeaveCallDialogOpen(false);
  }, []);

  const onLeaveCallDialogOk = useCallback(() => {
    setIsLeavingCall(true);
    leaveCall(id, recipientId);
    _stopShareScreen();
    leaveTwilioMeeting();
    setIsLeavingCall(false);
    if (!documentToSign.submitDocumentCompleted) {
      // TODO create a leave call page to display
      history.replace('/');
    }
  }, [
    leaveCall,
    id,
    recipientId,
    leaveTwilioMeeting,
    _stopShareScreen,
    documentToSign.submitDocumentCompleted,
    history,
  ]);

  const onEndCallDialogCancel = useCallback(() => {
    setEndCallDialogOpen(false);
  }, []);

  const onEndCallDialogOk = useCallback(async () => {
    if (!recipientId) return;
    try {
      setIsEndingCall(true);
      await stopRecordScreen();
      dispatch(setMeetingActive(false));
      emitEndCall(id, recipientId);
      leaveTwilioMeeting();
    } catch (err) {
      console.error('end call error', err);
    } finally {
      setIsEndingCall(false);
    }
  }, [dispatch, emitEndCall, id, leaveTwilioMeeting, stopRecordScreen, recipientId]);

  const handleStopRecordScreenClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStopRecordScreenSnackbarOpen(false);
  }, []);

  const attendeesObj = useMemo(() => {
    const attendeesObj = {};

    room.attendees?.forEach((userId) => {
      if (userId !== recipientId) {
        if (room.byActingOrder[userId]) {
          attendeesObj[userId] = room.byActingOrder[userId];
        }

        const joinedObserver = (room.observers ?? []).find(
          (observer) => observer.userId === userId,
        );
        if (joinedObserver) {
          attendeesObj[userId] = joinedObserver;
        }
      }
    });

    return attendeesObj;
  }, [recipientId, room]);

  return (
    <>
      <Box sx={{ maxWidth: 240 }}>
        <Typography
          gutterBottom
          align="left"
          sx={(theme) => ({
            ...theme.typography.title_large,
          })}
        >
          {folderName}
        </Typography>
        {isLoading || isConnecting ? (
          <Previews />
        ) : (
          <>
            <div style={{ height: '60%', overflow: 'scroll' }}>
              {renderRoom({ attendees: attendeesObj })}
            </div>
            <Stack
              spacing={2}
              sx={(theme) => ({ padding: theme.spacing(4, 0), height: '30%' })}
              direction="column"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid container spacing={2} direction="row" justifyContent="space-between">
                <Grid item xs={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    onClick={toggleMic}
                    startIcon={<img src={micOn ? MicIcon : MicOffIcon} alt="mic" />}
                  >
                    {t('Mic')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    onClick={toggleCam}
                    startIcon={<img src={camOn ? CamIcon : CamOffIcon} alt="cam" />}
                  >
                    {t('Cam')}
                  </Button>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                sx={lightButtonSx}
                onClick={isSharingScreen ? stopShareScreen : shareScreen}
                startIcon={<img src={ShareScreenIcon} alt="ShareScreen" />}
              >
                {isSharingScreen ? t('StopShareScreen') : t('ShareScreen')}
              </Button>
              {isMeetingHost && (
                <LoadingButton
                  loading={isRecordScreenLoading}
                  variant="contained"
                  sx={lightButtonSx}
                  onClick={isScreenRecording ? stopRecordScreen : recordScreen}
                  startIcon={<img src={RecordScreenIcon} alt="RecordScreen" />}
                >
                  {isScreenRecording ? t('StopRecording') : t('RecordScreen')}
                </LoadingButton>
              )}
              <Button
                variant="contained"
                sx={redButtonSx}
                onClick={endCall}
                startIcon={<img src={EndCallIcon} alt="EndCall" />}
              >
                {t('EndCall')}
              </Button>
            </Stack>
          </>
        )}
      </Box>

      <TwoStatusDialog
        onClose={onLeaveCallDialogCancel}
        onOk={onLeaveCallDialogOk}
        open={leaveCallDialogOpen}
        isLoading={isLeavingCall}
        okButtonText={t('LeaveCall')}
        cancelButtonText={t('Cancel')}
        title={t('LeaveCallQuestion')}
        content={[
          {
            text: t('SigningProgressWillNotBeSaved'),
            class: videoSignDialogClasses.dialogContentRed,
            isRed: true,
          },
          {
            text: t('UseLinkToRejoinTheCall'),
            class: videoSignDialogClasses.dialogContent,
            isRed: true,
          },
          {
            text: t('UseLinkToRejoinTheCall'),
            class: videoSignDialogClasses.dialogContent,
            isRed: false,
          },
        ]}
        isRed={!documentToSign.submitDocumentCompleted}
      />

      <TwoStatusDialog
        onClose={onEndCallDialogCancel}
        onOk={onEndCallDialogOk}
        open={endCallDialogOpen}
        isLoading={isEndingCall}
        okButtonText={t('EndCallforAll')}
        cancelButtonText={t('Cancel')}
        title={t('EndCallQuestion')}
        content={[
          {
            text: t('EndCallBeforeCompleteContentNotBeSaved'),
            class: videoSignDialogClasses.dialogContentRed,
            isRed: true,
          },
          {
            text: t('EndCallBeforeCompleteContentStartMeeting'),
            class: videoSignDialogClasses.dialogContentRed,
            isRed: true,
          },
          {
            text: t('EndCallAfterCompleteContent'),
            class: videoSignDialogClasses.dialogContent,
            isRed: false,
          },
        ]}
        isRed={!isSigningSessionComplete() && !documentToSign.isDocumentRejected}
      />

      <SingleStatusDialog
        onClose={onRecordScreenDialogCancel}
        onOk={onRecordScreenDialogOk}
        open={recordScreenDialogOpen}
        okButtonText={t('StartRecording')}
        cancelButtonText={t('Cancel')}
        content={t('HostRecordScreenDialogContent')}
        title={t('RecordMeetingQuestion')}
        isLoading={isRecordScreenLoading}
        okButtonCypressId={CypressIds.startRecordingButton}
        cancelButtonCypressId={CypressIds.cancelRecordingButton}
      />

      <SingleStatusDialog
        onClose={onLeaveCallDialogOk}
        onOk={() => setRecordScreenNotifyDialogOpen(false)}
        open={recordScreenNotifyDialogOpen}
        okButtonText={t('Continue')}
        cancelButtonText={t('LeaveCall')}
        content={t('MeetingBeingRecordedContent')}
        title={t('MeetingBeingRecoredTitle')}
        okButtonCypressId={CypressIds.continueCallButton}
        cancelButtonCypressId={CypressIds.leaveCallButton}
      />

      <SingleStatusDialog
        onClose={() => setTurnToSignDialogOpen(false)}
        onOk={shareScreen}
        open={turnToSignDialogOpen}
        okButtonText={t('ShareScreen')}
        cancelButtonText={t('Cancel')}
        content={t('TurnToSignContent')}
        title={t('TurnToSignTitle')}
        okButtonCypressId={CypressIds.shareScreenButton}
        cancelButtonCypressId={CypressIds.notShareScreenButton}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={stopRecordScreenSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleStopRecordScreenClose}
      >
        <Alert
          onClose={handleStopRecordScreenClose}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.success.main}`,
            color: theme.palette.success.dark,
            borderRadius: '8px',
            marginTop: theme.spacing(5),
            ...theme.typography.title_medium,
          })}
          severity="success"
        >
          {t('ScreenRecordingEmailToYou')}
        </Alert>
      </Snackbar>
    </>
  );
}
