import { styled } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import BaseModal from '../BaseModal';

export const RejectModalRoot = styled(BaseModal)(() => ({
  zIndex: 9999,
  [`& .MuiDialog-paper`]: {
    height: '360px',
    width: '688px',
  },
}));

export const RejectModalTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.headline_small,
  color: theme.palette.darkGrey.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.title_large,
  },
}));

export const RejectModalContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.error.extraLight,
  borderRadius: '8px',
  margin: theme.spacing(4, 0),
  padding: `${theme.spacing(4)} !important`,
  [`& > *`]: {
    marginBottom: theme.spacing(4),
  },
}));

export const RejectModalContentTitle = styled('div')(({ theme }) => ({
  ...theme.typography.title_medium,
  color: theme.palette.error.main,
}));

export const RejectModalContentMessage = styled('div')(({ theme }) => ({
  ...theme.typography.body_medium,
  color: '#3B3B3B',
}));

export const RejectModalReasonInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderRadius: '8px',
  width: '100%',
  ['& .MuiInputBase-input']: {
    ...theme.typography.body_medium,
  },
}));

export const RejectModalActions = styled(DialogActions)(() => ({
  padding: 0,
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,
}));

export const RejectCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.darkGrey.dark,
  [theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));

export const RejectButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label_medium,
  backgroundColor: theme.palette.error.extraLight,
  height: '44px',
  width: '92px',
  borderRadius: '8px',
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.contrast.primary,
  },
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.label_small,
    width: '76px',
    height: '36px',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  ...theme.typography.label_medium,
  backgroundColor: theme.palette.primary.main,
  height: '44px',
  width: '92px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.error.extraLight}`,
  color: theme.palette.text.contrast.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.label_small,
    width: '76px',
    height: '36px',
  },
}));
