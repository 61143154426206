import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import RoundCheckIcon from '../../assets/round-check.png';
const PREFIX = 'RecipientCard';

const classes = {
  root: `${PREFIX}-root`,
  selectedBackground: `${PREFIX}-selectedBackground`,
  checkIcon: `${PREFIX}-checkIcon`,
  cardContent: `${PREFIX}-cardContent`,
  blankCircle: `${PREFIX}-blankCircle`,
  selectBtn: `${PREFIX}-selectBtn`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: 56,
    alignItems: 'center',
    margin: '8px auto',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.background.main,
  },

  [`&.${classes.selectedBackground}`]: {
    backgroundColor: '#D8EBF6',
  },

  [`&.${classes.checkIcon}`]: {
    color: '#082977',
  },

  [`& .${classes.cardContent}`]: {
    padding: '12px 16px',
    height: '100%',
    textAlign: 'start',
    '& .card-tail': {
      alignSelf: 'center',
      margin: 0,
      marginLeft: 8,
      fontSize: 13,
    },
    '& .card-head': {
      width: 16,
      height: 16,
      marginRight: 16,
    },
    '& .card-body p': {
      fontSize: 13,
      fontWeight: '600 !important',
      color: theme.palette.darkGrey.main,
      lineHeight: '16px',
      overflowWrap: 'break-word',
    },
    '&:last-child': {
      paddingBottom: 12,
    },
  },

  [`& .${classes.blankCircle}`]: {
    border: '1px solid #828282',
    borderRadius: '50%',
    height: 14,
    width: 14,
  },

  [`&.${classes.selectBtn}`]: {
    '&.MuiButton-containedPrimary': {
      backgroundColor: '#3565E3 !important',
    },
  },
}));

const RecipientCard = (props) => {
  const { t } = useTranslation();
  const { recipient, index, hasSelected, setHasSelected, role, hostEmail, isMeetingHost } = props;

  const isHostRole = hostEmail === recipient.email;
  const identitySelected = hasSelected === `${role}-${index}` || recipient?.hasConfirmed;

  const tagColor = (roleTag) => {
    if (roleTag === 'host')
      return {
        color: '#082977',
        backgroundColor: '#D8EBF6',
      };
    if (roleTag === 'signer')
      return {
        color: '#19703E',
        backgroundColor: '#DAEAEA',
      };
    if (roleTag === 'approver')
      return {
        color: '#DB7555',
        backgroundColor: '#FFF2CC',
      };
    return {
      color: '#3B3B3B',
      backgroundColor: '#DCDEE0',
    };
  };

  const handleSelectRole = () => {
    if ((isHostRole && !isMeetingHost) || (!isHostRole && isMeetingHost)) return;
    if (identitySelected) return;
    setHasSelected(`${role}-${index}`);
  };

  return (
    <StyledCard
      className={`${classes.root} ${identitySelected ? classes.selectedBackground : ''}`}
      onClick={handleSelectRole}
    >
      <CardContent className={classes.cardContent}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item className="card-head" style={{ flex: '0 0 auto' }}>
            {identitySelected ? (
              <img src={RoundCheckIcon} className={`${classes.checkIcon}`} />
            ) : (
              <div className={`${classes.blankCircle}`} />
            )}
          </Grid>
          <Grid item className="card-body" style={{ flex: '1 1 auto' }}>
            <p className="m0">
              <Typography variant="label_large">{recipient.name}</Typography>
            </p>
            <p className="m0 word-break-break-word">
              <Typography variant="label_medium" fontWeight={400}>
                {recipient.email}
              </Typography>
            </p>
            <div>
              {isHostRole && (
                <p
                  className="role-tag font-size-11 text-transform-capitalize"
                  style={{ ...tagColor('host') }}
                >
                  {t('WebexHostText')}
                </p>
              )}
              <p
                className="role-tag font-size-11 text-transform-capitalize"
                style={{ ...tagColor(role) }}
              >
                {role}
              </p>
            </div>
          </Grid>
          <Grid item style={{ flex: '0 0 auto' }} className="card-tail">
            {identitySelected ? (
              <p className="selected-btn font-weight-600 m0 selected font-size-13 letter-spacing-2em">
                {t('WebexRecipientCardSelectedText')}
              </p>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={Boolean(!setHasSelected)}
                className={`${classes.selectBtn} select-btn font-size-13 text-transform-capitalize`}
              >
                {t('WebexRecipientCardSelectText')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default RecipientCard;
