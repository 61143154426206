import { getCalendarIcon } from '../assets/colorable-svgs';
import FormCalendarIcon from '../assets/calendar-icon.svg';
import {
  createWidgetLayout,
  createFormWidgetLayout,
  createTextDisplayAnnotation,
} from './pdftronWidgetCommon';

export const createActualDateWidgetAnnotation = (
  fieldId,
  dateText,
  color,
  name,
  fieldFlags,
  pdfTronInstance,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;
  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });
  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;

  const actualDateWidgetLayout = createWidgetLayout(iframeWindow, {
    iconSrc: getCalendarIcon(color),
    title: 'Actual Date',
    color,
    tagText: name,
  });

  const dateTextAnnot = createTextDisplayAnnotation(dateText, Annotations);
  widgetAnnot.setAssociatedSignatureAnnotation(dateTextAnnot);
  actualDateWidgetLayout.hideLabel();

  widgetAnnot.createInnerElement = () => {
    return actualDateWidgetLayout;
  };

  return { widgetAnnot, field };
};

export const createCustomDateWidgetAnnotation = (
  fieldId,
  value,
  color,
  name,
  fieldFlags,
  writable,
  pdfTronInstance,
) => {
  const { Annotations } = pdfTronInstance.Core;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Annotations.Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });

  const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, null);
  widgetAnnot.Id = fieldId;

  const customDateWidgetLayout = createWidgetLayout(iframeWindow, {
    iconSrc: getCalendarIcon(color),
    title: 'Custom Date',
    color,
    tagText: name,
  });

  if (!writable) {
    if (value) {
      const dateTextAnnot = createTextDisplayAnnotation(value, Annotations);
      widgetAnnot.setAssociatedSignatureAnnotation(dateTextAnnot);
      customDateWidgetLayout.hideLabel();
    }
    widgetAnnot.createInnerElement = () => {
      return customDateWidgetLayout;
    };
  } else {
    widgetAnnot.createSignHereElement = () => {
      return customDateWidgetLayout;
    };
  }

  return { widgetAnnot, field };
};

export const createFormDateInputWidgetAnnotation = (
  fieldId,
  fieldFlags,
  dateInput,
  pdfTronInstance,
) => {
  const { Forms, SignatureWidgetAnnotation } = pdfTronInstance.Core.Annotations;
  const { iframeWindow } = pdfTronInstance.UI;

  const field = new Forms.Field(fieldId, {
    type: 'Tx',
    flags: fieldFlags,
  });

  const customDateWidgetLayout = createFormWidgetLayout(iframeWindow, {
    labelText: dateInput.label,
    isRequired: dateInput.isRequired,
    iconSrc: FormCalendarIcon,
  });

  const widgetAnnot = new SignatureWidgetAnnotation(field, null);

  widgetAnnot.createInnerElement = () => {
    return customDateWidgetLayout;
  };

  widgetAnnot.Id = fieldId;

  return { widgetAnnot, field };
};

export const isCustomDate = (id) => typeof id === 'string' && id.startsWith('custom-date');

export const isActualDate = (id) => typeof id === 'string' && id.startsWith('actual-date');
