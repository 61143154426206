import documenttosign from './documentreducer';
import Auth from './Authreducer';
import PdfViewerStates from './PDFviewer_Reducer';
// import PdfViewerStates from './PDFviewer_Reducer'
import { combineReducers } from 'redux';
import signatureToSave from './signatureReducer';
import formReducer from '../slices/formSlice';
import dVideoSign from '../slices/dVideoSignSlice/dVideoSignSlice';
import twilio from '../slices/twilioSlice/twilioSlice';
import otp from '../slices/otpSlice/otpSlice';
import attachment from '../slices/attachmentSlice/index';
import organizationLogo from './organizationLogoReducer';

const allreducers = combineReducers({
  documenttosign,
  Auth,
  PdfViewerStates,
  signatureToSave,
  formState: formReducer,
  dVideoSign,
  twilio,
  otp,
  organizationLogo,
  attachment,
});
export default allreducers;
