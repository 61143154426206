import { Root, ContentWrapper, Logo, Caption } from './SingPassLoginRedirectPage.style';
import SingPassLogo from '../../../assets/singpass-logo-fullcolours.svg';
import { useEffect, useMemo, useState } from 'react';

export function SingPassLoginRedirectPage() {
  const search = window.location.search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [isParamsInvalid, setIsParamsInvalid] = useState(false);

  useEffect(() => {
    try {
      const code = params.get('code');
      const state = window.atob(params.get('state'));
      const signingDetails = JSON.parse(state);
      localStorage.setItem('singPassLoginAuthCode', code);
      window.location.href = `/${signingDetails.a}/${signingDetails.id}/${signingDetails.uid}/${signingDetails.f}`;
    } catch (e) {
      setIsParamsInvalid(true);
    }
  }, [params]);

  return (
    isParamsInvalid && (
      <PageTemplate>
        <Caption>Invalid Parameters</Caption>
      </PageTemplate>
    )
  );
}

const PageTemplate = ({ children }) => {
  return (
    <Root>
      <ContentWrapper>
        <Logo src={SingPassLogo} />
        {children}
      </ContentWrapper>
    </Root>
  );
};
