import { getElementBuilder } from './domStyling';
import { getElementWithRetries } from './domUtil';

export const createCheckboxWidgetAnnotation = (
  fieldId,
  isChecked,
  color,
  fieldFlags,
  pdfTronInstance,
) => {
  const { Forms, CheckButtonWidgetAnnotation } = pdfTronInstance.Core.Annotations;

  const field = new Forms.Field(fieldId, {
    type: 'Btn',
    value: isChecked ? 'On' : 'Off',
    flags: fieldFlags,
  });

  const widgetAnnot = new CheckButtonWidgetAnnotation(field, {
    appearance: 'Off',
    appearances: {
      Off: {},
      On: {},
    },
  });

  widgetAnnot.Id = fieldId;
  widgetAnnot.color = color;

  return { widgetAnnot, field };
};

export const appendTagName = async (fieldId, signerName, color, iframeWindow) => {
  const getStyledElement = getElementBuilder(iframeWindow);

  const checkboxElement = await getElementWithRetries(
    iframeWindow.document.getElementById.bind(iframeWindow.document, fieldId),
    { retryInterval: 200, retryCount: 40 },
  );
  const nameTag = getStyledElement('div', () => ({
    position: 'absolute',
    left: '0.25em',
    top: '-2.9em',
    fontSize: '2.6em',
    borderRadius: '0.4em',
    background: color,
    color: 'white',
    padding: '0.6em',
    zIndex: 9999,
    fontWeight: '700',
    display: 'none',
    width: 'max-content',
  }));

  nameTag.textContent = signerName;
  nameTag.classList.add('widget-tag');

  checkboxElement?.appendChild(nameTag);
};

export const isCheckbox = (id) => {
  return typeof id === 'string' && id.startsWith('checkbox');
};
