import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import { Root } from './NextButton.styles';

const NextButton = () => {
  const { moveNext } = usePdfTronContext();
  const { t } = useTranslation();

  const handleClick = () => {
    moveNext();
  };

  return (
    <Root
      data-testid="pdf-webviewer-next-button"
      variant="contained-round"
      color="primary"
      endIcon={<ArrowDownwardIcon fontSize="small" />}
      onClick={handleClick}
    >
      {t('WebViewerNextButton')}
    </Root>
  );
};

export default NextButton;
