import React from 'react';

/**
 * This hook is implemented using `replaceStringWithComponent`.
 * See `replaceStringWithComponent` for more details.
 * @param {string|JSX.Element} source
 * @param {string} target
 * @param {JSX.Element} Component
 */
export function useReplaceStringWithComponent(source, target, Component) {
  return replaceStringWithComponent(source, target, Component);
}

/**
 * This function allows us to replace a substring in a string,
 * or in strings nested in a React component, with a React component.
 * E.g. We have a string "Accept T&C to Proceed", the function will replace
 * "T&C" with an `a` tag if "Accept T&C to Proceed" is the `source`, "T&C" is the `target` and
 * the `Component` is an `a` tag component that has the corresponding `href` that is provided by the user.
 * If `source` is a React component, all the strings nested in the component will undergo the replacement if the `target` is found in those strings.
 * @param {string|JSX.Element} source
 * @param {string} target
 * @param {JSX.Element} Component
 */
export function replaceStringWithComponent(source, target, Component) {
  if (typeof source === 'string') {
    const results = [];
    const splits = source.split(target);
    splits.forEach((st, index, array) => {
      if (st !== '') results.push(<React.Fragment key={index}>{st}</React.Fragment>);
      if (index === array.length - 1) return;
      results.push(<Component key={target + index} value={target} />);
    });
    return <>{results}</>;
  } else if (React.isValidElement(source)) {
    return React.cloneElement(
      source,
      {},
      React.Children.map(source.props.children, (childNode) =>
        replaceStringWithComponent(childNode, target, Component),
      ),
    );
  }
}

/**
 * This hook is implemented using `replaceSubstringsWithComponents`.
 * See `replaceSubstringsWithComponents` for more details.
 * @param {string|JSX.Element} source
 * @param {Object.<string, JSX.Element>} targetsToComponentsMap
 */
export function useReplaceSubstringsWithComponents(source, targetsToComponentsMap) {
  return replaceSubstringsWithComponents(source, targetsToComponentsMap);
}

/**
 * This function allows us to replace multiple substrings in a string,
 * or in strings nested in a React component, with React components.
 * @example
 * const source = 'Hello beautiful world"
 * const targetsToComponentsMap = {
 *  'Hello': HelloComponent,
 *  'world': WorldComponent
 * }
 *
 * const result = replaceSubstringsWithComponents(source, targetsToComponentsMap);
 * `results` will be:
 * <>
 *  <HelloComponent />
 *  <> beautiful </>
 *  <WorldComponent />
 * </>
 *
 * @param {string|JSX.Element} source
 * @param {Object.<string, JSX.Element>} targetsToComponentsMap
 */
export function replaceSubstringsWithComponents(source, targetsToComponentsMap) {
  // Make sure targets with longer length are replaced first.
  // This prevents unexpected replacements if a target is a substring of another target.
  // E.g. If targets are 'bob' and 'bobby' and 'bob' is replaced first,
  //  The 'bobby' substring that exists in `source` will be broken up and replaced by <Component for 'bob'> and 'by'
  //  This is not intuitive as the caller would expect 'bobby' to be replaced with the component for 'bobby'
  const sortedTargets = Object.keys(targetsToComponentsMap).sort((a, b) => {
    return b.length - a.length;
  });

  let result = source;
  for (const target of sortedTargets) {
    result = replaceStringWithComponent(result, target, targetsToComponentsMap[target]);
  }

  return result;
}
