import { Button, Chip, ListItem, styled } from '@mui/material';

const PREFIX = 'choose-next';

export const classes = {
  text: `${PREFIX}-name`,
  button: `${PREFIX}-button`,
  selectedButton: `${PREFIX}-selected-button`,
};

export const Role = {
  Signer: 'Signer',
  Approver: 'Approver',
  Observer: 'Observer',
};

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid #F6F6F7',
  borderRadius: '8px',
  padding: theme.spacing(3.5, 0, 3.5, 3),
  marginTop: theme.spacing(2),
  [`.${classes.text}`]: {
    ...theme.typography.label_medium,
  },
  [`.${classes.button}`]: {
    ...theme.typography.label_large,
  },
}));

export const StyledChip = styled(Chip)(({ theme, role }) => {
  let background = '',
    color = '';
  switch (role) {
    case Role.Signer:
      background = theme.palette.info.extraLight;
      color = theme.palette.info.main;
      break;
    case Role.Approver:
      background = '#FFFBF1';
      color = theme.palette.warning.main;
      break;
    case Role.Observer:
      background = theme.palette.lightGrey.light;
      color = theme.palette.darkGrey.light;
      break;
    default:
  }
  return {
    background,
    color,
  };
});

export const StyledSelectButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  boxShadow: 'none',
  [`&.${classes.selectedButton}`]: {
    background: theme.palette.supporting.main,
    color: theme.palette.primary.main,
  },
}));
