import { Grid } from '@mui/material';
import logo from '../../../assets/dedoco-logo-reversed.svg';
import { Body, classes, Root } from './WebexContainer.styles';

const WebexContainer = (props) => {
  const { body, footer } = props;

  return (
    <Root container direction="column" className="h-100 flex-no-wrap">
      <Grid
        item
        container
        className={classes.header}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <a href="https://www.dedoco.com/">
            <img alt="logo-banner" src={logo} />
          </a>
        </Grid>
      </Grid>
      {body && (
        <Grid item className={classes.body}>
          <Body>{body}</Body>
        </Grid>
      )}
      {footer && (
        <Grid item className={classes.footer}>
          {footer}
        </Grid>
      )}
    </Root>
  );
};

export default WebexContainer;
