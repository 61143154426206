import { Grid } from '@mui/material';
import LoadingLogo from '../../assets/dedoco-logo.svg';
import LoadingSpinner from '../../assets/loading-spinner.svg';

const LoadingPage = () => {
  return (
    <Grid container className="shared-app-main" direction="column" justifyContent="center">
      <Grid item>
        <img alt="loading-logo" src={LoadingLogo} />
      </Grid>
      <Grid>
        <img alt="loading-spinner" src={LoadingSpinner} />
      </Grid>
    </Grid>
  );
};

export default LoadingPage;
