import { Grid, styled } from '@mui/material';

const PREFIX = 'ConfirmIdentity';

export const classes = {
  root: `${PREFIX}-root`,
  otpInput: `${PREFIX}-input`,
  termsConditionContainer: `${PREFIX}-terms-condition-container`,
  termsConditionContent: `${PREFIX}-terms-condition-content`,
};

export const Root = styled(Grid)(({ theme, isInvalidOTP }) => ({
  gap: theme.spacing(4),
  [`& .${classes.otpInput}`]: {
    height: '64px',
    width: '100% !important',
    borderColor: isInvalidOTP ? theme.palette.error.main : theme.palette.lightGrey.border,
    backgroundColor: theme.palette.background.main,
    borderStyle: 'solid',
    borderRadius: '5px',
  },
  [`& .${classes.termsConditionContainer}`]: {
    backgroundColor: theme.palette.supporting.main,
    display: 'flex',
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    border: 'solid 2px',
    borderColor: theme.palette.lightGrey.border,
    padding: theme.spacing(3),
  },

  [`& .${classes.termsConditionContent}`]: {
    backgroundColor: theme.palette.supporting.main,
    flexGrow: 1,
    width: 0,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 0,
  },
}));
