import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, classes } from './Timer.style';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocumentToSignSelector,
  setMeetingActive,
  setTimeoutEndMeeting,
} from '../../../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import { useTimer } from 'react-timer-hook';
import MeetingTimeRemainingDialog from '../Dialog/MeetingTimeRemainingDialog';
import useDedocoVideoSigning from '../../../../hooks/useDedocoVideoSigning';
import { useParams } from 'react-router-dom';
import useTwilio from '../../../../hooks/useTwilio';

export default function Timer({ startAt }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = useSelector(getDocumentToSignSelector);
  const { endCall, isMeetingHost, documentToSign } = useDedocoVideoSigning();
  const { id } = useParams();
  const { leaveTwilioMeeting } = useTwilio();

  const recipientId = documentToSign?.data?.recipientId;

  const [dialogOpen, setDialogOpen] = useState(false);

  const expiryTimestamp = useMemo(() => {
    const time = new Date(startAt);
    const { hours = 0, minutes = 0 } = data?.meeting?.duration || {};
    time.setSeconds(time.getSeconds() + hours * 60 * 60 + minutes * 60);
    return time;
  }, [data?.meeting?.duration, startAt]);

  const { hours, seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      if (isMeetingHost && recipientId) {
        dispatch(setMeetingActive(false));
        dispatch(setTimeoutEndMeeting(true));
        endCall(id, recipientId);
        leaveTwilioMeeting();
      }
    },
  });

  useEffect(() => {
    if (hours === 0 && seconds === 0 && minutes === 10) {
      // show Remaining 10 mins alert
      setDialogOpen(true);
    }
  }, [hours, minutes, seconds]);

  const { min, sec } = useMemo(() => {
    let min = hours * 60 + minutes;
    min = min < 10 ? `0${min}` : min;
    let sec = seconds < 10 ? `0${seconds}` : seconds;
    return { min, sec };
  }, [hours, minutes, seconds]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <Container className={hours === 0 && minutes <= 10 ? classes.lessTimer : ''}>
        {t('TimerStartSession')}&nbsp;
        <span className={classes.timer}>{`${min}:${sec} mins`}</span>
      </Container>
      <MeetingTimeRemainingDialog open={dialogOpen} onClose={handleDialogClose} />
    </>
  );
}
