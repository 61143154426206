import { isInitials, isSignature, isStamp } from './signatureWidget';

export const shouldRenderSigningPad = (currentFieldId) => {
  let fieldId = currentFieldId;
  if (Array.isArray(currentFieldId)) {
    fieldId = currentFieldId?.[0];
  }
  const isEsignatureField = isSignature(fieldId);
  const isInitialsField = isInitials(fieldId);
  const isStampField = isStamp(fieldId);

  return isEsignatureField || isInitialsField || isStampField;
};
