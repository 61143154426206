import React, { useCallback, useEffect, useState } from 'react';
import {
  Root,
  ContentWrapper,
  Logo,
  Caption,
  LoadingBar,
  LoadingCaption,
} from './SingPassShared.styles';
import { LoginButton, Footer, FooterLink, FooterSeparator } from './SingPassLogin.styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoginWithSingPassLogo from '../../../assets/login-with-singpass-button.svg';
import SingPassLogo from '../../../assets/singpass-logo-fullcolours.svg';

import { process_env } from '../../../utils/envData';

export default function SingPassLogin({
  recipientId,
  documentId,
  fileShareKey,
  isObserver,
  isApprover,
  singPassAuthCode,
  loginFailed,
}) {
  const [openLoginFailedSnackbar, setOpenLoginFailedSnackbar] = useState(false);

  const handleCloseLoginFailedSnackbar = useCallback(() => {
    setOpenLoginFailedSnackbar(false);
  }, []);

  useEffect(() => {
    if (loginFailed) {
      setOpenLoginFailedSnackbar(true);
    }
  }, [loginFailed]);

  return (
    <>
      <ErrorSnackbar
        open={openLoginFailedSnackbar}
        handleClose={handleCloseLoginFailedSnackbar}
        message="Failed to login with SingPass. Please try again."
      />
      {singPassAuthCode && !loginFailed ? (
        <LoadingView />
      ) : (
        <LoginView
          recipientId={recipientId}
          documentId={documentId}
          fileShareKey={fileShareKey}
          isObserver={isObserver}
          isApprover={isApprover}
        />
      )}
    </>
  );
}

const PageTemplate = ({ children }) => {
  return (
    <Root>
      <ContentWrapper>{children}</ContentWrapper>
      <Footer>
        <FooterLink
          href="https://www.dedoco.com/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </FooterLink>
        <FooterSeparator />
        <FooterLink
          href="https://www.dedoco.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </FooterLink>
      </Footer>
    </Root>
  );
};

const LoginView = ({ recipientId, documentId, fileShareKey, isObserver, isApprover }) => {
  const loginWithSingPass = () => {
    const singpassLoginDomain = process_env('REACT_APP_SINGPASS_AUTH_ENDPOINT');
    const loginRedirectUrl = encodeURIComponent(
      process_env('REACT_APP_SINGPASS_AUTH_REDIRECT_URI'),
    );
    const clientId = encodeURIComponent(process_env('REACT_APP_SINGPASS_AUTH_CLIENT_ID'));
    let action = 'sign';
    if (isObserver) {
      action = 'observe';
    } else if (isApprover) {
      action = 'approve';
    }
    const nonce = encodeURIComponent(window.btoa(Date.now()));
    // Using JSON format instead of delimiters to identify values to make it less prone to parsing error.
    // Keep key name short as there is a limit to the number of characters for state.
    const state = encodeURIComponent(
      window.btoa(
        `{"a":"${action}","id":"${documentId}","uid":"${recipientId}","f":"${fileShareKey}"}`,
      ),
    );

    const loginUrl = `${singpassLoginDomain}?scope=openid&response_type=code&redirect_uri=${loginRedirectUrl}&client_id=${clientId}&state=${state}&nonce=${nonce}`;
    window.location.href = loginUrl;
  };

  return (
    <PageTemplate>
      <Logo src={SingPassLogo} />
      <Caption>Login with SingPass to sign document</Caption>
      <LoginButton src={LoginWithSingPassLogo} onClick={loginWithSingPass} />
    </PageTemplate>
  );
};

const LoadingView = () => {
  return (
    <PageTemplate>
      <LoadingCaption>Logging in...</LoadingCaption>
      <LoadingBar />
    </PageTemplate>
  );
};

const ErrorSnackbar = ({ open, handleClose, message }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
    >
      <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
