import { Stack, styled } from '@mui/material';

const PREFIX = 'VideoSigningInfo';

export const MAX_WIDTH = 370;

export const classes = {
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

export const InfoContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: theme.spacing(4),
  borderRadius: '8px',
  textAlign: 'left',
  padding: theme.spacing(4),

  [`.${classes.title}`]: {
    ...theme.typography.title_large,
  },

  [`.${classes.content}`]: {
    ...theme.typography.body_medium,
  },
}));

export const Box = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.main,
  borderRadius: 8,
  padding: theme.spacing(4),
}));

export const StyledStack = styled(Stack)(({ theme, windowSize }) => ({
  boxSizing: 'border-box',
  width: MAX_WIDTH,
  margin: windowSize.height > 520 ? theme.spacing(0, 4, 4, 4) : 0,
  height: '100%',
  overflow: 'auto',

  [`.${classes.title}`]: {
    ...theme.typography.title_large,
  },
}));
