import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classes, CustomTextBoxRoot } from './CustomTextBox.style';
import errorIcon from '../../../../assets/error-icon.svg';

const CustomTextPad = ({
  value,
  handleValueChange,
  style,
  inputRef,
  error,
  errorMessage = 'Invalid input',
}) => {
  const { t } = useTranslation();

  const handleOnChange = (e) => {
    handleValueChange?.(e.target.value);
  };

  return (
    <CustomTextBoxRoot sx={style}>
      <Grid className={error ? classes.innerError : classes.inner}>
        <TextField
          className={classes.textField}
          id="custom-text-input"
          label=""
          value={value}
          variant="standard"
          onChange={handleOnChange}
          // Set attribute for the inner <input/> or <textarea/> element
          inputProps={{
            'data-testid': 'sidebar-custom-text-input',
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={t('CustomTextPlaceholder')}
          maxRows={4}
          multiline
          inputRef={inputRef}
          error={error}
        />
      </Grid>
      {error && (
        <div className={classes.errorMessage}>
          <img src={errorIcon} alt="Error Icon" className={classes.errorIcon} />
          <Typography className={classes.errorText}>{errorMessage}</Typography>
        </div>
      )}
    </CustomTextBoxRoot>
  );
};

export default CustomTextPad;
