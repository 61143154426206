import { styled } from '@mui/material';

export const classes = {
  mobileNotSupportedDescription: 'mobileNotSupportedDescription',
};

export const Root = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  height: '100%',
  position: 'relative',
}));

export const MobileNotSupportedContainer = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  padding: theme.spacing(4),
  margin: theme.spacing(4),

  [`& .${classes.mobileNotSupportedDescription}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));
