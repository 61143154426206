import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteFileAttachmentApi, uploadFileAttachmentApi } from './attachmentApi';

const SLICE_NAME = 'attachment';
const initialState = {
  attachments: [],
  instructions: '',
};

export const uploadFileAttachment = createAsyncThunk(
  `${SLICE_NAME}/uploadFileAttachment`,
  async ({ bpId, signerId, attachmentId, fileBlob, isOpenApiV2 = false }, { rejectWithValue }) => {
    try {
      const response = await uploadFileAttachmentApi(
        bpId,
        signerId,
        attachmentId,
        fileBlob,
        isOpenApiV2,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteFileAttachment = createAsyncThunk(
  `${SLICE_NAME}/deleteFileAttachment`,
  async ({ bpId, signerId, attachmentId, isOpenApiV2 = false }, { rejectWithValue }) => {
    try {
      const response = await deleteFileAttachmentApi(bpId, signerId, attachmentId, isOpenApiV2);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);
export const attachmentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    initialiseAttachment: (state, action) => {
      const attachment = action.payload;
      if (!attachment) return;
      const { details, instructions } = attachment;
      state.attachments = details.map((attachment) => ({
        ...attachment,
        loading: false,
        error: null,
      }));
      state.instructions = instructions;
    },
    setAttachmentError: (state, action) => {
      const { id, error } = action.payload;
      const attachment = state.attachments.find((a) => a.id === id);
      attachment.error = error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFileAttachment.pending, handlePending);
    builder.addCase(uploadFileAttachment.rejected, handleRejection);
    builder.addCase(uploadFileAttachment.fulfilled, (state, action) => {
      const attachment = getAttachment(state, action);
      attachment.loading = false;
      attachment.error = null;
      const { fileName, sizeInBytes } = action.payload;
      attachment.fileName = fileName;
      attachment.sizeInBytes = sizeInBytes;
    });

    builder.addCase(deleteFileAttachment.pending, handlePending);
    builder.addCase(deleteFileAttachment.rejected, handleRejection);
    builder.addCase(deleteFileAttachment.fulfilled, (state, action) => {
      const attachment = getAttachment(state, action);
      attachment.loading = false;
      attachment.error = null;
      delete attachment.fileName;
      delete attachment.sizeInBytes;
    });
  },
});

function getAttachment(state, action) {
  // Linear search should be acceptable in this case since number of attachments is small.
  // Keeping attachments as an array helps to keep code simple in other places
  const attachmentIdToFind = action.meta.arg.attachmentId;
  const attachment = state.attachments.find((a) => a.id === attachmentIdToFind);
  if (!attachment) {
    throw new Error(`Attachment with ID ${attachmentIdToFind} doesn't not exist`);
  }
  return attachment;
}

function handlePending(state, action) {
  const attachment = getAttachment(state, action);
  attachment.loading = true;
  attachment.error = null;
}

function handleRejection(state, action) {
  const attachment = getAttachment(state, action);
  attachment.loading = false;
  attachment.error = action.payload.message;
}

export const { initialiseAttachment, setAttachmentError } = attachmentSlice.actions;

export default attachmentSlice.reducer;
