import { Grid, styled } from '@mui/material';

const PREFIX = 'SavedSignature';

export const classes = {
  savedSignatureItem: `${PREFIX}-saved-signature-item`,
  signatureImage: `${PREFIX}-signature-image`,
};

export const Root = styled(Grid)(({ theme }) => ({
  [`& .${classes.savedSignatureItem}`]: {
    backgroundColor: theme.palette.background.main,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.stroke.dark}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '107px',
    [`&:focus`]: {
      borderRadius: '4px',
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.background.paper,
    },
    [`& .${classes.signatureImage}`]: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));
