import { dedocoAxios } from '../../../lib/api/axios';

export const OPEN_API_V2_URL_PREFIX = '/openapi';

export function uploadFileAttachmentApi(bpId, signerId, attachmentId, fileBlob, isOpenApiV2) {
  const path = `${
    isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
  }/businessprocess/${bpId}/signers/${signerId}/attachments/${attachmentId}`;
  const formData = new FormData();
  formData.set('file', fileBlob);
  return dedocoAxios.post(path, formData);
}

export function deleteFileAttachmentApi(bpId, signerId, attachmentId, isOpenApiV2) {
  const path = `${
    isOpenApiV2 ? OPEN_API_V2_URL_PREFIX : ''
  }/businessprocess/${bpId}/signers/${signerId}/attachments/${attachmentId}`;
  return dedocoAxios.delete(path);
}
