import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TnCMarkdownParser, submitTnCAgreement } from './shared';
import { useCallback, useState } from 'react';

export const TermsAndConditionsCheckbox = ({
  label,
  businessProcessId,
  participant,
  isPublic,
  onChange,
  onError,
}) => {
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback(
    async (event) => {
      if (!event.target.checked) {
        onChange?.(false);
        setChecked(false);
        return;
      }

      if (
        !(await submitTnCAgreement(businessProcessId, participant.id, participant.role, isPublic))
      ) {
        onError?.();
      } else {
        onChange?.(true);
        setChecked(true);
      }
    },
    [businessProcessId, participant.id, participant.role, isPublic, onChange, onError],
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} disabled={checked} />}
        label={<TnCMarkdownParser>{label}</TnCMarkdownParser>}
      />
    </FormGroup>
  );
};
