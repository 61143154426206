import { Button, styled } from '@mui/material';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(6, 4, 4, 4),
  boxSizing: 'border-box',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.12)',
  display: 'grid',
  direction: 'column',
  gap: theme.spacing(4),
  borderRadius: '8px',
  height: 'auto',
  width: '300px',
  position: 'relative',
}));

export const NextButton = styled(Button)(() => ({
  width: 'fit-content',
  justifySelf: 'end',
}));
