import { SAVE_CURRENT_SIGNATURE } from '../actions/actiontypes';

const initialstate = {
  saveCurrentSignature: false,
};

const signatureToSave = (state = initialstate, action) => {
  switch (action.type) {
    case SAVE_CURRENT_SIGNATURE:
      return {
        ...state,
        saveCurrentSignature: action.payload,
      };
    default:
      return state;
  }
};

export default signatureToSave;
