import { styled } from '@mui/material';
import { RefreshQrButton } from '../Shared';

export const QrDisplayRoot = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
}));

export const QrCodeHeader = styled('div')(() => ({
  textAlign: 'center',
  fontSize: '20px',
  color: '#504e56',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
}));

export const QrCodeSubHeader = styled('div')(() => ({
  textAlign: 'center',
  fontSize: '16px',
  color: '#504e56',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
}));

export const QrCodeBorder = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '258px',
  height: '258px',
  margin: theme.spacing(2),
}));

export const QrSingPassFrame = styled('img')(() => ({
  width: '258px',
  height: '258px',
  position: 'absolute',
  top: '3px',
  left: 0,
}));

export const ExpiredQrRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '270px',
  height: '270px',
  marginTop: theme.spacing(12),
}));

export const ExpiredQrOverlay = styled('div')(() => ({
  zIndex: 998,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
}));

export const ExpiredQrCaption = styled('div')(() => ({
  zIndex: 999,
  top: '80px',
  textAlign: 'center',
  width: '100%',
  position: 'absolute',
  fontSize: '18px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
}));

export const ExpiredQrRefreshButton = styled(RefreshQrButton)(() => ({
  position: 'absolute',
  top: '120px',
  left: '36px',
}));
