import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import helpIcon from '../../../assets/help.svg';
import LanguageSelector from '../Header/LanguageSelector';
import DownloadButton from '../Buttons/DownloadButton';
import { classes, Root, Sidebar } from './SideMenu.styles';

export default function SideMenu({ handleDownloadPdf }) {
  const [state, setState] = React.useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const openHelp = () => {
    window.open('https://www.dedoco.com/faq', '_blank', 'noopener');
  };

  return (
    <Root>
      <React.Fragment key={'right'}>
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon />
        </Button>
        <Sidebar anchor={'right'} open={state} onClose={toggleDrawer(false)}>
          <Grid
            container
            flexDirection="column"
            role="presentation"
            className={classes.content}
            justifyContent="space-between"
          >
            <Grid container item alignItems="center" justifyContent="space-between">
              <Grid item>
                <LanguageSelector color="dark" />
              </Grid>
              <Grid item>
                <CloseIcon className={classes.closeIcon} onClick={toggleDrawer(false)} />
              </Grid>
            </Grid>
            <Grid container item flexDirection="column" className={classes.groupButton}>
              <Grid item>
                {handleDownloadPdf && (
                  <DownloadButton localDownloadOnly={true} handleDownloadPdf={handleDownloadPdf} />
                )}
              </Grid>
              <Grid item>
                <Button
                  className={classes.getHelpButton}
                  onClick={openHelp}
                  fullWidth
                  startIcon={<img className={classes.helpIcon} src={helpIcon} alt="help" />}
                >
                  {t('SideMenuGetHelp')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Sidebar>
      </React.Fragment>
    </Root>
  );
}
