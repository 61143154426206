import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import './Header.css';
import { Grid, Snackbar } from '@mui/material';
import { declineDocument } from '../../redux/actions/documentfunctions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@mui/lab/Alert';
import DownloadIcon from '../../assets/download.svg';
import DownloadPdf from '../Modal/DownloadPdf/DownloadPdf';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import LoadingSpinner from '../Helpers/LoadingSpinner/LoadingSpinner';
import DeclineDocumentDialog from '../Modal/DeclineDocument/DeclineDocument';
import { getLogoFromCompanyName } from '../../utils/logo';
import { useHistory } from 'react-router-dom';

const PREFIX = 'Header';

const classes = {
  downloadBtn: `${PREFIX}-downloadBtn`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.downloadBtn}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Header(props) {
  const { handleDownloadPdf, publicVariable, whiteLabelDetails } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);

  // Snackbar
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [snackbarMessage, setSnackBarMessage] = useState('');
  const [snackBarDuration, setSnackBarDuration] = useState(3000);
  const [openModalDownloadPdf, setOpenModalDownloadPdf] = useState(false);

  const company = useSelector((state) => state.documenttosign?.data?.company);

  const logo =
    whiteLabelDetails?.logo ||
    company?.logo ||
    getLogoFromCompanyName(process.env.REACT_APP_COMPANY_NAME);

  const history = useHistory();
  const submitDecline = async (remark) => {
    if (!showDeclineConfirmation) {
      setShowDeclineConfirmation(true);
    } else if (!props.isApprovingDocument) {
      setSnackBarOpen(true);
      setSeverity('info');
      setSnackBarMessage(t('SignatureRequestMessageSubmitting'));
      setSnackBarDuration(100000);
      dispatch(
        declineDocument(
          {
            initialDocHash: props?.initialHash,
            dssKey: props.fileShareKey,
            finalDocHash: props?.initialHash,
            remark: remark,
          },
          props?.data?.business_process_id,
          props?.data?.recipient_id,
        ),
      )
        .then(() => {
          closeDeclineConfirmation();
          setSnackBarOpen(false);
          if (publicVariable) {
            history.push(`/public/done/${props.id}/${props.uid}?action=decline`);
          } else {
            history.push(`/done/decline/${props.id}/${props.uid}?action=decline`);
          }
        })
        .catch(() => {
          closeDeclineConfirmation();
          setSeverity('error');
          setSnackBarMessage(t('SignatureRequestErrorDeclining'));
        });
    }
  };

  const closeDeclineConfirmation = () => {
    if (!props.isApprovingDocument) {
      setShowDeclineConfirmation(false);
    }
  };

  const closeDeclineConfirmationDisableBackdrop = (event, reason) => {
    if (!props.isApprovingDocument && reason !== 'disableBackdropClick') {
      setShowDeclineConfirmation(false);
    }
  };
  return (
    <StyledGrid container className="header">
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
        autoHideDuration={snackBarDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert iconMapping={{ info: <LoadingSpinner /> }} severity={severity}>
            {snackbarMessage}
          </Alert>
        </div>
      </Snackbar>
      <Grid item xs={6} sm={4} display="flex" alignItems="center">
        <img src={logo} className="logo2" alt="Logo" />
      </Grid>
      <Grid
        container
        style={{ justifyContent: 'flex-end', alignItems: 'center' }}
        item
        xs={6}
        sm={8}
      >
        {((props?.isSigner &&
          props?.document?.document_share_method === 'dss' &&
          !props?.isSignCompleted &&
          props.isDownloadable) ||
          (props.isObserver && props?.observer?.is_downloadable)) && (
          <Grid
            className={classes.downloadBtn}
            item
            container
            xs={false}
            sm={false}
            md={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            <div
              onClick={() => {
                setOpenModalDownloadPdf(true);
              }}
              style={{
                backgroundColor: '#3565E3',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
              }}
              className={`${
                props.isApprovingDocument ? 'disabled-btn' : 'yellow-btn'
              } mr2 wrapper-signer-download`}
            >
              <img src={DownloadIcon} alt="download" style={{ marginRight: 8 }} />
              <span>{t('HeaderDownloadBtn')}</span>
            </div>
          </Grid>
        )}
        {props.isObserver &&
          props.isDownloadable &&
          props.isObserver &&
          !props?.observer?.is_downloadable && (
            <Grid
              item
              container
              xs={false}
              sm={false}
              md={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <div className="mr2 undownloadable-warning">
                <span>{t('HeaderUndownloadableWarning')}</span>
              </div>
            </Grid>
          )}
        {props?.isApprover &&
          props?.data?.business_process_id &&
          !props.documentUpdatedApprovement && (
            <Grid
              className={classes.downloadBtn}
              justifyContent="flex-end"
              alignItems="center"
              container
              item
              sm={false}
              md={6}
              lg={false}
            >
              <div className="wrapper-approve-download">
                {props.isDownloadable && props?.document?.document_share_method === 'dss' && (
                  <Grid
                    item
                    container
                    xs={false}
                    sm={false}
                    md={6}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ display: 'flex' }}
                  >
                    <div
                      onClick={() => {
                        setOpenModalDownloadPdf(true);
                      }}
                      style={{ backgroundColor: '#3565E3', color: 'white', display: 'flex' }}
                      className={`${props.isApprovingDocument ? 'disabled-btn' : 'yellow-btn'} mr2`}
                    >
                      <img src={DownloadIcon} alt="download" style={{ marginRight: 8 }} />
                      <span>{t('HeaderDownloadBtn')}</span>
                    </div>
                  </Grid>
                )}

                {props.isDocumentShowed ? (
                  <Grid container item alignItems="center" width="max-content">
                    <div
                      onClick={submitDecline}
                      className={`${props.isApprovingDocument ? 'disabled-btn' : 'yellow-btn'} mr2`}
                    >
                      {t('SignatureRequestDrawerDeclineDocument')}
                    </div>
                  </Grid>
                ) : null}
              </div>

              <DeclineDocumentDialog
                isApprovingDocument={props.isApprovingDocument}
                open={showDeclineConfirmation}
                handleClose={closeDeclineConfirmationDisableBackdrop}
                handleSubmit={submitDecline}
              />
            </Grid>
          )}
        {handleDownloadPdf && (
          <DownloadPdf
            open={openModalDownloadPdf}
            setOpenModal={setOpenModalDownloadPdf}
            handleDownloadPdf={handleDownloadPdf}
          />
        )}

        <Grid item className={classes.downloadBtn}>
          <LanguageSelector />
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
