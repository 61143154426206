import { useTheme } from '@emotion/react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useWebexContext } from '../../../contexts/useWebexContext';
import { sendVerifyOTP, verifyWebexOTP } from '../../../redux/actions/documentfunctions';
import { TermsAndConditionBody } from '../../DedocoDifference/Sidebar/TermsAndCondition';
import { WebexSteps } from '../constants';
import WebexContainer from '../WebexContainer';
import { classes, Root } from './ConfirmIdentity.styles';

const ConfirmIdentity = (props) => {
  const { t } = useTranslation();
  const { handleConfirmIdentity, setStep, countDownTime, setCountDownTime } = props;
  const { userIdentitySelected: userIdentity, setUserIdentity } = useWebexContext();
  const [otp, setOTP] = useState('');
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const theme = useTheme();

  const handleOTPChange = useCallback((otp) => {
    setOTP(otp);
  }, []);

  const handleSendOTP = () => {
    if (countDownTime === 0) {
      setIsOTPSent(true);
      sendVerifyOTP(userIdentity);
      setCountDownTime(50);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const result = await verifyWebexOTP(userIdentity, otp);
      if (result?.status !== 200) return;
      handleConfirmIdentity();
    } catch (e) {
      setIsInvalidOTP(true);
    }
  };

  return (
    <WebexContainer
      body={
        <Root
          container
          direction="row"
          className="shared-app-main"
          justifyContent="center"
          alignContent="flex-start"
          isInvalidOTP={isInvalidOTP}
        >
          <Grid item xs={12}>
            <Typography variant="title_large" className="text-align-start" sx={{ display: 'flex' }}>
              {t('WebexConfirmIdentityTitle')}
            </Typography>
          </Grid>
          {!isOTPSent ? (
            <Grid item xs={12}>
              <Typography
                sx={{ display: 'flex', color: theme.palette.text.secondary }}
                variant="label_medium"
              >
                {t('WebexConfirmIdentityEmailAddress')}
              </Typography>
              <TextField
                id="webex-email-confirmation"
                fullWidth
                size="small"
                defaultValue={userIdentity.email}
                disabled={true}
                sx={{
                  '& .MuiInputBase-root': {
                    paddingRight: 1,
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                sx={{
                  display: 'flex',
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                }}
                variant="label_medium"
              >
                {!isOTPSent
                  ? t('WebexConfirmIdentitySendOTP')
                  : `Enter OTP sent to ${userIdentity.email}`}
              </Typography>
              <OtpInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={7}
                inputStyle={classes.otpInput}
                separator={<span>&nbsp;&nbsp;</span>}
              />
            </Grid>
          )}
          {isOTPSent && (
            <>
              <Grid item xs={12} container>
                <Grid item xs={6} className="text-align-start">
                  <Typography
                    variant="label_medium"
                    onClick={handleSendOTP}
                    sx={{ color: countDownTime === 0 ? 'initial' : theme.palette.lightGrey.main }}
                  >
                    {t('WebexConfirmIdentityResendButton')}
                  </Typography>
                </Grid>
                <Grid item xs={6} className="text-align-end">
                  <Typography variant="label_medium">
                    {countDownTime > 0 && `${t('WebexConfirmIdentityResendIn')} ${countDownTime}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TermsAndConditionBody
                  containerStyle={classes.termsConditionContainer}
                  contentStyle={classes.termsConditionContent}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {!isOTPSent ? (
              <Button fullWidth variant="contained" color="primary" onClick={handleSendOTP}>
                {t('WebexConfirmIdentitySendOTP')}
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleVerifyOTP}
                // disabled={otp.length !== 7}
              >
                {t('WebexConfirmIdentityProceedButton')}
              </Button>
            )}
          </Grid>
          {isOTPSent && (
            <Grid item xs={12}>
              <Typography variant="label_small">
                {t('WebexConfirmIdentityChooseOtherRole1')}{' '}
                <Typography
                  component={'span'}
                  variant="label_small"
                  onClick={() => {
                    setIsOTPSent(false);
                    setStep(WebexSteps.CLAIM_ROLE);
                    setUserIdentity(null);
                  }}
                >
                  {t('WebexConfirmIdentityChooseOtherRole2')}
                </Typography>
              </Typography>
            </Grid>
          )}
        </Root>
      }
    />
  );
};

export default ConfirmIdentity;
