import { SIGNATURE_TYPE } from '../../../../constant/common';

export const MAX_WIDTH = 370;

export const SIGNATURE_TYPE_LIST = [
  { value: SIGNATURE_TYPE.DRAW },
  { value: SIGNATURE_TYPE.UPLOAD },
  { value: SIGNATURE_TYPE.TYPE },
  { value: SIGNATURE_TYPE.SAVED },
];
export const INITIAL_TYPE_LIST = [{ value: SIGNATURE_TYPE.DRAW }, { value: SIGNATURE_TYPE.TYPE }];
export const STAMP_TYPE_LIST = [{ value: SIGNATURE_TYPE.UPLOAD }];
export const CUSTOM_TEXT_TYPE_LIST = [{ value: SIGNATURE_TYPE.TYPE }];
export const PEN_COLOR_PALETTE = ['#1A1A34', '#135CCA', '#CA1313'];

export const SIGNATURE_TAB_TITLE = {
  DRAW: 'Signature',
  UPLOAD: 'Stamp',
  TYPE: 'Text',
  SAVED: 'Previously Saved',
};
