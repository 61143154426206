import { useTheme } from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePdfTronContext } from '../../../../contexts/usePdfTronContext';
import {
  deleteFormFillerInput,
  formStateSelector,
  updateFieldsLoadingFlag,
  updateFormFillerInput,
  updateIsAllRequiredFieldsFilled,
  updateSelectedFormFieldId,
} from '../../../../redux/slices/formSlice';
import { base64ToBlob } from '../../../../utils/base64';
import { disableScroll, enableScroll, getElementWithRetries } from '../../../../utils/domUtil';
import {
  drawFormCheckboxInputs,
  drawFormDateInputs,
  drawFormDigitalSignatures,
  drawFormDropdownInputs,
  drawFormESignatureInputs,
  drawFormInitialsInputs,
  drawFormStampInputs,
  drawFormTextInputs,
  getAnnotationTextAsImage,
  getFieldIdFromAssociatedAnnotId,
  getSortedAnnotationsList,
  normalStyles,
  safeGetAssociatedSignatureAnnotation,
  setContentToSignatureAnnot,
} from '../../../../utils/pdftron';
import { setSignatureWidgetText } from '../../../../utils/pdftronWidgetCommon';
import { WebViewerRoot, WebViewerWrapper } from '../../PdfWebViewer/PdfWebViewer.style';
import Toolbar from '../../PdfWebViewer/Toolbar';
import { DateInput } from '../../UI/DateInput';
import { FloatingPopup } from '../../UI/FloatingPopup';
import { SignatureInput, SignatureInputTypes } from '../../UI/SignatureInput';
import { TextInput } from '../../UI/TextInput';
import { DropdownInput } from '../../UI/DropdownInput';
import { FormFieldTypes } from '../constants';
import { CheckboxInput } from '../../UI/CheckboxInput';
import useReferredState from '../../../../hooks/useReferredState';
import { useTaskQueue } from '../../../../hooks/useTaskQueue';
import { CypressIds } from '../../../../constant/cypressIds';

const PdfWebViewer = () => {
  const {
    instance,
    setInstance,
    pdfTronSettings,
    loadDocumentToWebViewer,
    setSortedAnnotationsList,
    moveNext,
    removeHotKeys,
  } = usePdfTronContext();
  const {
    form,
    formFillerInputs,
    fieldIdToTypeMap,
    selectedFieldId,
    isFormSubmitted,
    sidebarFieldItemClickForceUpdate,
  } = useSelector(formStateSelector);
  const dispatch = useDispatch();
  const webviewerDomContainerRef = useRef(null);
  const theme = useTheme();
  const [fieldPopupStates, setFieldPopupStates] = useReferredState({});
  const [webviewerRect, setWebViewerRect] = useState({});
  const [_, setPdfTronEventListeners] = useState({});
  const taskQueue = useTaskQueue();

  let documentViewer, Annotations, annotationManager, iframeWindow, fieldManager;

  if (instance) {
    ({ documentViewer, Annotations, annotationManager } = instance.Core);
    ({ iframeWindow } = instance.UI);
    fieldManager = annotationManager.getFieldManager();
  }

  const getUpdatedFieldPopupStates = useCallback(
    async (currFieldPopupStates, fieldIds) => {
      // Create a shallow copy to avoid directly modifying the state object.
      const newFieldPopupStates = { ...currFieldPopupStates };

      await Promise.all(
        fieldIds.map(async (fieldId) => {
          if (newFieldPopupStates[fieldId] || !(fieldId in fieldIdToTypeMap)) {
            // Do not re-init since initialisation is not idempotent.
            // Existing values should be safely reusable
            return;
          }

          // There is no guarantee that the DOM element is rendered when this function is called.
          const annotationDom = await getElementWithRetries(
            iframeWindow.document.getElementById.bind(iframeWindow.document, fieldId),
          );

          const fieldType = fieldIdToTypeMap[fieldId];
          const fieldInfo = form.fields[fieldType].find((field) => field.fieldId === fieldId);

          annotationDom?.addEventListener('click', () => {
            dispatch(updateSelectedFormFieldId(fieldId));
          });

          if (
            fieldType === FormFieldTypes.digiSignatures ||
            (fieldType === FormFieldTypes.dates && fieldInfo.defaultValue === 'Actual Date')
          ) {
            return;
          }

          newFieldPopupStates[fieldId] = {
            open: false,
            domRef: annotationDom,
            type: fieldType,
            label: fieldInfo?.label,
            description: fieldInfo?.description,
            dateFormat: fieldInfo?.dateFormat,
            options: fieldInfo?.options?.map((val) => ({ name: val, value: val })),
            isRequired: fieldInfo?.isRequired,
            canOpenAfterFilled: false,
          };

          if (fieldType === FormFieldTypes.checkboxes) {
            newFieldPopupStates[fieldId].canOpenAfterFilled = true;
            // Disable default click behavior of PDFTron's checkbox
            const checkboxEl = annotationDom?.querySelector('[role="checkbox"]');
            if (checkboxEl) {
              checkboxEl.style.pointerEvents = 'none';
            }
          }
        }),
      );

      return newFieldPopupStates;
    },
    [dispatch, fieldIdToTypeMap, form.fields, iframeWindow],
  );

  const updateFieldPopupStatesSync = useCallback(
    async (updateFunc) => {
      // Use this function to update fieldPopupStates to make sure that states are updated incrementally.
      // This prevents asynchronous updates from overriding each other, causing unexpected and inconsistent popup behaviors
      await taskQueue.executeTask(updateFunc);
    },
    [taskQueue],
  );

  const closeFieldPopup = useCallback(
    (fieldId) => {
      dispatch(updateSelectedFormFieldId(''));
      updateFieldPopupStatesSync(() => {
        setFieldPopupStates({
          ...fieldPopupStates.current,
          [fieldId]: {
            ...fieldPopupStates.current[fieldId],
            open: false,
          },
        });
      });
    },
    [dispatch, setFieldPopupStates, fieldPopupStates, updateFieldPopupStatesSync],
  );

  const openFieldPopup = (fieldId) => {
    updateFieldPopupStatesSync(async () => {
      // There is no guarantee that the field popup state for the fieldId is initialised when openFieldPopup is called.
      const newFieldPopupStates = await getUpdatedFieldPopupStates(fieldPopupStates.current, [
        fieldId,
      ]);

      for (const _fieldId in newFieldPopupStates) {
        newFieldPopupStates[_fieldId].open = _fieldId === fieldId;
      }

      setFieldPopupStates(newFieldPopupStates);
    });
  };

  const moveToNextAnnotation = useCallback(
    (fieldIdsToExclude, startFrom) => {
      const digiSignatureFieldId = form?.fields.digiSignatures?.[0]?.fieldId;
      if (digiSignatureFieldId) {
        fieldIdsToExclude.push(digiSignatureFieldId);
      }

      moveNext(
        { exclude: fieldIdsToExclude, startFrom: startFrom || fieldIdsToExclude[0] },
        (annot) => {
          if (!annot) return;
          const { id, isFilled } = annot;
          if (isFilled) return;
          dispatch(updateSelectedFormFieldId(id));
        },
      );
    },
    [moveNext, dispatch, form?.fields.digiSignatures],
  );

  const getTextInputAlignmentByFieldType = (fieldType) => {
    return fieldType === FormFieldTypes.esignatures ||
      fieldType === FormFieldTypes.stamps ||
      fieldType === FormFieldTypes.initials
      ? 'center'
      : 'left';
  };

  const saveFormFillerInput = useCallback(
    (payload) => {
      dispatch(updateFormFillerInput(payload));
    },
    [dispatch],
  );

  const deleteUserFormInput = useCallback(
    (fieldId) => {
      dispatch(deleteFormFillerInput({ fieldId }));
    },
    [dispatch],
  );

  const saveInputValue = useCallback(
    async (inputId, value, metadata) => {
      if (value === '' || value === null || value === undefined) {
        return;
      }

      const fieldType = fieldIdToTypeMap[inputId];
      let fieldsToPopulate = [inputId];

      if (metadata?.applySignatureToAll) {
        fieldsToPopulate = form.fields[fieldType].map((field) => {
          return field.fieldId;
        });
      }

      await Promise.all(
        fieldsToPopulate.map(async (fieldId) => {
          if (metadata?.isCheckbox) {
            fieldManager.getField(fieldId).setValue(value ? 'On' : 'Off');
            if (!value) {
              deleteUserFormInput(fieldId);
              return;
            }
          } else if (metadata?.isFile) {
            await setContentToSignatureAnnot(value, fieldId, instance);
          } else {
            const textAnnot = setSignatureWidgetText(value, fieldId, instance, {
              textColor: metadata?.textColor,
              textAlign: getTextInputAlignmentByFieldType(fieldType),
            });

            if (metadata?.isSignature) {
              value = await getAnnotationTextAsImage(textAnnot, instance.Core.documentViewer);
            }
          }

          saveFormFillerInput({
            fieldId,
            value,
            shouldConsiderForFurtherChange: false,
          });
        }),
      );

      closeFieldPopup(inputId);
      moveToNextAnnotation(fieldsToPopulate, inputId);
    },
    [
      fieldIdToTypeMap,
      form.fields,
      moveToNextAnnotation,
      saveFormFillerInput,
      closeFieldPopup,
      fieldManager,
      deleteUserFormInput,
      instance,
    ],
  );

  const drawFormAnnotations = () => {
    const fieldDetails = form.fields;
    for (const type in form.fields) {
      switch (type) {
        case FormFieldTypes.esignatures:
          drawFormESignatureInputs(fieldDetails[type], instance);
          break;
        case FormFieldTypes.initials:
          drawFormInitialsInputs(fieldDetails[type], instance);
          break;
        case FormFieldTypes.stamps:
          drawFormStampInputs(fieldDetails[type], instance);
          break;
        case FormFieldTypes.inputs:
          drawFormTextInputs(fieldDetails[type], instance);
          break;
        case FormFieldTypes.dates:
          drawFormDateInputs(fieldDetails[type], instance);
          break;
        case FormFieldTypes.digiSignatures:
          drawFormDigitalSignatures(fieldDetails[type], instance);
          break;
        case FormFieldTypes.checkboxes:
          drawFormCheckboxInputs(fieldDetails[type], theme.palette.primary.main, instance);
          break;
        case FormFieldTypes.dropdownLists:
          drawFormDropdownInputs(fieldDetails[type], instance);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    !instance &&
      WebViewer(pdfTronSettings, webviewerDomContainerRef.current).then((newInstance) => {
        setInstance(newInstance);
      });

    return () => {
      setInstance(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    removeHotKeys();
  }, [instance, removeHotKeys]);

  useEffect(() => {
    if (!instance || !form.base64) {
      return;
    }
    loadDocumentToWebViewer(base64ToBlob(form.base64), () => {
      setWebViewerRect(webviewerDomContainerRef.current.getBoundingClientRect());
      if (!isFormSubmitted) {
        drawFormAnnotations();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, form.base64]);

  const fillInDefaultValue = useCallback(
    (field, fieldType) => {
      const fieldId = field.fieldId;
      let defaultValue = field.defaultValue;
      let shouldConsiderForFurtherChange = true;

      const textAlign = getTextInputAlignmentByFieldType(fieldType);

      if (fieldType === FormFieldTypes.dates) {
        if (defaultValue !== 'Actual Date') {
          return;
        }
        defaultValue = dayjs().format(field.dateFormat.toUpperCase());
        // Actual dates cannot be changed and shouldn't be changed by user.
        shouldConsiderForFurtherChange = false;
        setSignatureWidgetText(defaultValue, fieldId, instance, {
          readOnly: true,
          textAlign,
        });
      } else if (fieldType === FormFieldTypes.checkboxes) {
        fieldManager.getField(fieldId)?.setValue(defaultValue ? 'On' : 'Off');
      } else if (defaultValue) {
        setSignatureWidgetText(field.defaultValue, fieldId, instance, { textAlign });
      }

      if (!defaultValue) {
        return;
      }

      saveFormFillerInput({
        fieldId,
        value: defaultValue,
        shouldConsiderForFurtherChange,
      });
    },
    [saveFormFillerInput, fieldManager, instance],
  );

  useEffect(() => {
    const handlePdfPageRender = (pageNumber) => {
      const currPageAnnots = annotationManager
        .getAnnotationsList()
        .filter((annot) => annot.PageNumber === pageNumber && annot.Id in fieldIdToTypeMap);

      updateFieldPopupStatesSync(async () => {
        const fieldIds = currPageAnnots.map((annot) => annot.Id);
        setFieldPopupStates(await getUpdatedFieldPopupStates(fieldPopupStates.current, fieldIds));
        dispatch(updateFieldsLoadingFlag(false));
      });
    };

    setPdfTronEventListeners((prevListeners) => {
      if (prevListeners.handlePdfPageRender) {
        documentViewer?.removeEventListener('pageComplete', prevListeners.handlePdfPageRender);
      }

      return {
        ...prevListeners,
        handlePdfPageRender,
      };
    });

    documentViewer?.addEventListener('pageComplete', handlePdfPageRender);
  }, [
    documentViewer,
    annotationManager,
    dispatch,
    fieldIdToTypeMap,
    form.fields,
    iframeWindow,
    fieldPopupStates,
    setFieldPopupStates,
    getUpdatedFieldPopupStates,
    updateFieldPopupStatesSync,
  ]);

  useEffect(() => {
    // Field-related initialisation
    const fieldInit = async () => {
      const fieldDetails = form.fields;
      for (const type in fieldDetails) {
        fieldDetails[type].forEach((field) => {
          fillInDefaultValue(field, type);
        });
      }

      dispatch(updateIsAllRequiredFieldsFilled());
    };

    setPdfTronEventListeners((prevListeners) => {
      if (prevListeners.fieldInit) {
        documentViewer?.removeEventListener('annotationsLoaded', prevListeners.fieldInit);
      }

      return {
        ...prevListeners,
        fieldInit,
      };
    });

    documentViewer?.addEventListener('annotationsLoaded', fieldInit);
  }, [documentViewer, form.fields, fillInDefaultValue, dispatch]);

  useEffect(() => {
    if (!selectedFieldId || !annotationManager) {
      return;
    }

    let isFilled = false;
    for (const type in formFillerInputs) {
      for (const input of formFillerInputs[type]) {
        if (input.fieldId === selectedFieldId) {
          isFilled = true;
          break;
        }
      }

      if (isFilled) {
        break;
      }
    }

    annotationManager.deselectAllAnnotations();

    if (isFilled) {
      const widgetAnnot = annotationManager.getAnnotationById(selectedFieldId);
      const valueAnnot = safeGetAssociatedSignatureAnnotation(widgetAnnot);
      if (valueAnnot) {
        annotationManager.selectAnnotation(valueAnnot);
      }

      if (fieldPopupStates.current[selectedFieldId]?.canOpenAfterFilled) {
        openFieldPopup(selectedFieldId);
      } else {
        openFieldPopup('');
      }
    } else {
      openFieldPopup(selectedFieldId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldId, annotationManager, sidebarFieldItemClickForceUpdate]);

  useEffect(() => {
    // Init sorted widget annotation list
    const initSortedAnnotationList = () => {
      const sortedAnnotationsList = getSortedAnnotationsList(annotationManager);
      setSortedAnnotationsList(sortedAnnotationsList);
    };

    setPdfTronEventListeners((prevListeners) => {
      if (prevListeners.initSortedAnnotationList) {
        documentViewer?.removeEventListener(
          'annotationsLoaded',
          prevListeners.initSortedAnnotationList,
        );
      }

      return {
        ...prevListeners,
        initSortedAnnotationList,
      };
    });
    documentViewer?.addEventListener('annotationsLoaded', initSortedAnnotationList);
  }, [documentViewer, annotationManager, setSortedAnnotationsList]);

  useEffect(() => {
    const handleAnnotChange = (annotations, action) => {
      annotations.forEach((annot) => {
        if (action === 'add') {
          if (annot instanceof Annotations.WidgetAnnotation) {
            // Add custom style when annotations are added
            // getCustomStyles function is called when page zoom changes.
            Annotations.WidgetAnnotation.getCustomStyles = normalStyles.bind(
              null,
              iframeWindow,
              documentViewer,
              Annotations,
            );
          }
          annot.disableRotationControl();
        } else if (action === 'delete') {
          const signatureWidgetId = getFieldIdFromAssociatedAnnotId(annot.Id);
          deleteUserFormInput(signatureWidgetId);
          dispatch(updateSelectedFormFieldId(''));
        }
      });
    };
    setPdfTronEventListeners((prevListeners) => {
      if (prevListeners.handleAnnotChange) {
        documentViewer?.removeEventListener('annotationChanged', prevListeners.handleAnnotChange);
      }

      return {
        ...prevListeners,
        handleAnnotChange,
      };
    });
    annotationManager?.addEventListener('annotationChanged', handleAnnotChange);
  }, [iframeWindow, Annotations, annotationManager, documentViewer, deleteUserFormInput, dispatch]);

  useEffect(() => {
    const filledFieldIds = new Set();
    for (const type in formFillerInputs) {
      for (const input of formFillerInputs[type]) {
        filledFieldIds.add(input.fieldId);
      }
    }

    for (const fieldId in fieldIdToTypeMap) {
      const widgetDom =
        fieldPopupStates.current[fieldId]?.domRef ?? iframeWindow?.document.getElementById(fieldId);

      if (!widgetDom) {
        continue;
      }

      if (filledFieldIds.has(fieldId)) {
        if (!fieldPopupStates.current[fieldId]?.canOpenAfterFilled) {
          widgetDom.style.pointerEvents = 'none';
        }
        // First child is the widget div that is created by us.
        widgetDom.firstChild?.hideLabel?.();
        widgetDom.firstChild?.hideIcon?.();
      } else {
        widgetDom.style.pointerEvents = 'auto';
        widgetDom.firstChild?.showLabel?.();
        widgetDom.firstChild?.showIcon?.();
      }
    }
    // Update sortedAnnotationList annotation status
    setSortedAnnotationsList((prevList) => {
      return prevList.map((fieldInfo) => ({
        id: fieldInfo.id,
        isFilled: filledFieldIds.has(fieldInfo.id),
      }));
    });
  }, [
    formFillerInputs,
    fieldIdToTypeMap,
    setSortedAnnotationsList,
    fieldPopupStates,
    iframeWindow?.document,
  ]);

  useEffect(() => {
    if (!instance || !iframeWindow) {
      return;
    }
    // Disable/enable webviewer scroll/zoom based on whether a popup is showing.
    const popupIsShowing = Object.values(fieldPopupStates.current).some((state) => {
      return state.open;
    });
    if (popupIsShowing) {
      instance.disableFeatures([instance.Feature.MouseWheelZoom]);
      disableScroll(iframeWindow);
    } else {
      instance.enableFeatures([instance.Feature.MouseWheelZoom]);
      enableScroll(iframeWindow);
    }
  }, [fieldPopupStates, instance, iframeWindow]);

  return (
    <WebViewerRoot>
      {Object.keys(fieldPopupStates.current).map((fieldId) => (
        <FieldPopup
          key={`floating-popup-${fieldId}`}
          fieldId={fieldId}
          popupState={fieldPopupStates.current[fieldId]}
          webviewerRect={webviewerRect}
          onSaveValue={saveInputValue}
          onClose={closeFieldPopup}
          iframeWindow={iframeWindow}
        />
      ))}
      <Toolbar documentViewer={documentViewer} fileName={form.name}></Toolbar>
      <WebViewerWrapper
        ref={webviewerDomContainerRef}
        data-cypress={CypressIds.pdfWebViewer}
      ></WebViewerWrapper>
    </WebViewerRoot>
  );
};

const FieldPopup = ({ fieldId, popupState, webviewerRect, onSaveValue, onClose, iframeWindow }) => {
  const { t } = useTranslation();
  const closeInputFunctionRef = useRef();
  const handlePopupClickAway = () => {
    closeInputFunctionRef.current.closeInput();
  };

  return (
    <FloatingPopup
      open={popupState?.open}
      onClickAway={handlePopupClickAway}
      anchorDomElement={popupState?.domRef}
      options={{
        wrapY: true,
        wrapX: true,
        boundary: {
          top: webviewerRect.y,
          right: webviewerRect.x + webviewerRect.width,
          bottom: webviewerRect.y + webviewerRect.height,
          left: webviewerRect.x,
        },
        offset: {
          x: webviewerRect.x,
          y: webviewerRect.y,
        },
        iframeWindow,
      }}
    >
      {popupState.type === FormFieldTypes.esignatures && (
        <SignatureInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          tabsToShow={Object.values(SignatureInputTypes)}
          showApplyToAllCheckbox={true}
          title={t('FormESignatureInputTitle')}
          submitButtonText={t('FormSignatureInputPopupNextItemButtonText')}
        />
      )}
      {popupState.type === FormFieldTypes.initials && (
        <SignatureInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          tabsToShow={[SignatureInputTypes.draw, SignatureInputTypes.type]}
          title={t('FormInitialsInputTitle')}
          submitButtonText={t('FormSignatureInputPopupNextItemButtonText')}
        />
      )}
      {popupState.type === FormFieldTypes.stamps && (
        <SignatureInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          tabsToShow={[SignatureInputTypes.upload]}
          title={t('FormStampInputTitle')}
          submitButtonText={t('FormSignatureInputPopupNextItemButtonText')}
        />
      )}
      {popupState.type === FormFieldTypes.dates && (
        <DateInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          submitButtonText={t('FormDateInputPopupNextItemButtonText')}
          dateFormat={popupState?.dateFormat?.toUpperCase()}
        />
      )}
      {popupState.type === FormFieldTypes.inputs && (
        <TextInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          title={popupState?.label}
          description={popupState?.description}
          submitButtonText={t('FormTextInputPopupNextItemButtonText')}
          isRequired={popupState?.isRequired}
        />
      )}
      {popupState.type === FormFieldTypes.dropdownLists && (
        <DropdownInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          options={popupState?.options}
          title={popupState?.label}
          description={popupState?.description}
          placeholder={t('FormDropdownInputPlaceHolder')}
          submitButtonText={t('FormDropdownInputPopupNextItemButtonText')}
          isRequired={popupState?.isRequired}
        />
      )}
      {popupState.type === FormFieldTypes.checkboxes && (
        <CheckboxInput
          ref={closeInputFunctionRef}
          inputId={fieldId}
          onConfirmInput={onSaveValue}
          onClose={onClose}
          title={popupState?.label}
          description={popupState?.description}
          submitButtonText={t('FormCheckboxInputPopupNextItemButtonText')}
          isChecked={
            popupState?.domRef?.querySelector('[role="checkbox"]')?.getAttribute('aria-checked') ===
            'true'
          }
          isRequired={popupState?.isRequired}
        />
      )}
    </FloatingPopup>
  );
};

export default PdfWebViewer;
