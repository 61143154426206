import { createTheme, ThemeProvider } from '@mui/material';

const dedocoDefaultThemeObject = Object.freeze({
  palette: {
    primary: {
      main: '#6333FF',
      light: '#C1ADFF',
      dark: '#3F21A3',
      extraLight: '#D9CDFF',
    },
    secondary: {
      main: '#FF6600',
      light: '#FFA366',
      dark: '#B94A00',
    },
    error: {
      main: '#F53838',
      light: '#F98888',
      dark: '#9D2424',
      extraLight: '#FEF3F3',
    },
    warning: {
      light: '#FED666E',
      main: '#FECC4A',
      dark: '#CFA63C',
    },
    info: {
      light: '#8AE0EB',
      extraLight: '#EFFBFC',
      main: '#3CCBDE',
      dark: '#26828E',
    },
    success: {
      extraLight: '#F3FFF6',
      light: '#A3FFBD',
      main: '#66FF91',
      dark: '#62A35D',
      extraDark: '#007A22',
      primary: '#1A936F',
    },
    darkGrey: {
      light: '#949495',
      main: '#4D4D4F',
      dark: '#1F1F20',
    },
    lightGrey: {
      light: '#EBEBEB',
      main: '#DEDEDE',
      dark: '#8E8E8E',
      border: '#EDEDF0',
      primary: '#828282',
    },
    background: {
      paper: '#FAFAFB',
      main: '#FFFFFF',
      base: '#1A1A34',
    },
    supporting: {
      light: '#F9F7FF',
      main: '#F3EFFF',
      dark: '#E6DEFF',
      extraLight: '#FCFBFF',
    },
    stroke: {
      light: '#FAFAFB',
      main: '#F6F6F7',
      dark: '#EDEDEF',
    },
    text: {
      primary: '#1A1A34',
      secondary: '#767685',
      tertiary: '#A3A3AE',
      contrast: {
        primary: '#FFFFFF',
      },
    },
    grey: {
      100: '#F6F6F7',
      500: '#70707A',
      700: '#404057',
      900: '#1A1A34',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: [
      'HCo Gotham',
      'Open Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    headline_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: 0,
    },
    headline_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '36px',
      letterSpacing: 0,
    },
    headline_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: 0,
    },
    title_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    title_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    title_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0,
    },
    label_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    label_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0,
    },
    label_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '12px',
      letterSpacing: 0,
    },
    body_large: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    body_medium: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    body_small: {
      fontFamily: 'HCo Gotham',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0,
    },
  },
});

const createNonMUITheme = (theme) => {
  const themeObject = Object.assign({}, theme);
  const spacingUnit = theme.spacing || 4;
  themeObject.spacing = (...multipliers) => {
    return multipliers
      .map((n) => {
        return n === 'auto' ? 'auto' : `${spacingUnit * n}px`;
      })
      .join(' ');
  };

  return themeObject;
};

const dedocoDefaultThemeForDOM = createNonMUITheme(dedocoDefaultThemeObject);

const dedocoDefaultTheme = createTheme(dedocoDefaultThemeObject);

const dedocoTheme = createTheme(dedocoDefaultTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained-round', color: 'primary' },
          style: ({ theme }) =>
            theme.unstable_sx({
              textTransform: 'none',
              borderRadius: '100px',
              bgcolor: theme.palette.primary.main,
              color: '#fff',
              ':hover': {
                bgcolor: theme.palette.primary.main,
              },
            }),
        },
        {
          props: { variant: 'tinted', color: 'primary' },
          style: ({ theme }) =>
            theme.unstable_sx({
              textTransform: 'none',
              bgcolor: theme.palette.supporting.main,
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.supporting.dark}`,
              borderRadius: 8,
            }),
        },
      ],
      styleOverrides: {
        root: {
          minWidth: '0',
          fontSize: '14px',
          lineHeight: '18px',
          textTransform: 'none',
          padding: '11px 16px',
          fontWeight: '500',
          borderRadius: '8px',
          '&.Mui-disabled': {
            color: dedocoDefaultTheme.palette.background.main,
            backgroundColor: dedocoDefaultTheme.palette.primary.main,
            filter: 'opacity(50%)',
          },
        },
      },
    },
  },
});

// eslint-disable-next-line react/display-name
const withDedocoTheme = (WrappedComponent) => (props) => {
  return (
    <ThemeProvider theme={dedocoTheme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
};

export { dedocoTheme, dedocoDefaultThemeForDOM, withDedocoTheme };
