import { CircularProgress } from '@mui/material';
import { Root } from './LoadingOverlay.style';

export function LoadingOverlay() {
  return (
    <Root justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
    </Root>
  );
}
