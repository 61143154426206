import { AccordionSummary, Accordion, styled, AccordionDetails } from '@mui/material';

const PREFIX = 'VerifyBox';

export const classes = {
  documentIcon: `${PREFIX}-documentIcon`,
  verifyDescription: `${PREFIX}-verifyDescription`,
  fileDrop: `${PREFIX}-fileDrop`,
  innerFileDrop: `${PREFIX}-innerFileDrop`,
  fileDropText: `${PREFIX}-fileDropText`,
  fileDropOr: `${PREFIX}-fileDropOr`,
  fileDropButton: `${PREFIX}-fileDropButton`,
  fileInput: `${PREFIX}-fileInput`,
  documentVerified: `${PREFIX}-documentVerified`,
  documentVerifiedMismatch: `${PREFIX}-documentVerifiedMismatch`,
  tryAgain: `${PREFIX}-tryAgain`,
  fileDropSpan: `${PREFIX}-fileDropSpan`,
};

export const VerifyBoxAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.supporting.light,
  border: `1px solid #EDEDEF !important`,
  boxShadow: 'none',
  borderRadius: `6px !important`,
}));

export const VerifyBoxAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.supporting.light,
  maxHeight: 24,
  minHeight: 'auto',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(4),
  borderRadius: 6,

  [`& .MuiAccordionSummary-content `]: {
    margin: 0,
  },

  [`& .${classes.documentIcon}`]: {
    height: 16,
    marginRight: theme.spacing(2),
    filter:
      'invert(25%) sepia(83%) saturate(4387%) hue-rotate(248deg) brightness(96%) contrast(111%)',
  },

  [`& .${classes.verifyDescription}`]: {
    color: theme.palette.text.secondary,
  },
}));

export const VerifyBoxAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: theme.spacing(4),
  marginTop: theme.spacing(-2),

  [`& .${classes.verifyDescription}`]: {
    color: theme.palette.text.secondary,
  },
}));

export const VerifySuccessBox = styled('div')(({ theme }) => ({
  backgroundColor: '#F3FFF6',
  border: `1px solid #007A22`,
  padding: theme.spacing(4),
  borderRadius: 6,
  margin: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  textAlign: 'left',

  [`& .${classes.documentVerified}`]: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const VerifyFailureBox = styled('div')(({ theme }) => ({
  backgroundColor: '#FEF3F3',
  border: `1px solid ${theme.palette.error.main}`,
  padding: theme.spacing(4),
  borderRadius: 6,
  margin: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  textAlign: 'left',

  [`& .${classes.documentVerifiedMismatch}`]: {
    display: 'flex',
    gap: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },

  [`& .${classes.tryAgain}`]: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
