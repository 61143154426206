import { LoadingButton } from '@mui/lab';
import { DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import React from 'react';
import { StyledDialog, classes, LightCancelButton } from './Dialog.style';

export default function SingleStatusDialog({
  title,
  content,
  open,
  onClose,
  onOk,
  okButtonText,
  cancelButtonText,
  isLoading = false,
  bringToFront = false,
  okButtonCypressId,
  cancelButtonCypressId,
}) {
  return (
    <StyledDialog open={open} onClose={onClose} bringToFront={bringToFront}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButtonText && (
          <LightCancelButton
            onClick={onClose}
            sx={(theme) => ({ margin: theme.spacing(0, 0, 6, 0) })}
            variant="contained"
            disabled={isLoading}
            data-cypress={cancelButtonCypressId}
          >
            {cancelButtonText}
          </LightCancelButton>
        )}
        {okButtonText && (
          <LoadingButton
            sx={(theme) => ({ margin: theme.spacing(0, 6, 6, 0) })}
            onClick={onOk}
            variant="contained"
            loading={isLoading}
            data-cypress={okButtonCypressId}
          >
            {okButtonText}
          </LoadingButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
