import {
  GETTING_ORGANIZATION_LOGO,
  GET_ORGANIZATION_LOGO_FAIL,
  GOT_ORGANIZATION_LOGO,
} from '../actions/actiontypes';

const initialState = {
  loading: false,
  main: '',
  shorthand: '',
};

const organizationLogo = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_ORGANIZATION_LOGO:
      return {
        ...state,
        loading: true,
      };
    case GOT_ORGANIZATION_LOGO:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_ORGANIZATION_LOGO_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default organizationLogo;
