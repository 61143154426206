import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Typography, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import 'flag-icon-css/css/flag-icon.min.css';
import cookie from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classes, Root, StyledMenu } from './LanguageSelector.styles';

const LanguageSelector = (props) => {
  const { color } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const wrapperRef = useRef(null);

  const languages = [
    { code: 'en', name: 'EN', country_code: 'gb' },
    { code: 'jp', name: 'JP', country_code: 'jp' },
  ];

  const currentLanguageCode = cookie.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.title = t('appTitle');
  }, [currentLanguage, t]);

  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    const value = e.target.dataset.value;
    i18n.changeLanguage(value);
    setAnchorEl(null);
  };

  const getButtonStyle = () => {
    if (color === 'dark') {
      return {
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
        color: theme.palette.background.main,
        border: 'none',
      };
    }
    return {
      backgroundColor: theme.palette.supporting.main,
      color: theme.palette.primary.dark,
      borderColor: '#EDEDEF',
    };
  };

  return (
    <Root ref={wrapperRef}>
      <Button
        variant="tinted"
        color="primary"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleOnClick}
        className={classes.button}
        style={getButtonStyle()}
      >
        {currentLanguage.name}
        <ArrowDropDownIcon className={classes.expandIcon} />
      </Button>
      <StyledMenu
        onClose={() => setAnchorEl(null)}
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {languages.map(({ code, name, country_code }) => (
          <MenuItem
            classes={classes.menuItem}
            onClick={(e) => handleClose(e)}
            data-value={code}
            key={`${code}${name}${country_code}`}
          >
            <Typography variant="label_medium">{name}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </Root>
  );
};

export default LanguageSelector;
