import sjcl from 'sjcl';

const DELIMITER = '#';

/**
 * split DSS key into short key ID and decryption key
 * @returns {object} { shortKey, decryptionKey }
 */
export function splitFileShareKey(fileShareKey) {
  const [shortKey, longKey] = fileShareKey.split(DELIMITER);

  const decryptionKey = longKey.replace(/_/g, '/');

  return { shortKey, decryptionKey };
}

/**
 * join DSS key ID and decryption key into the URL-safe file share key string
 */
export function generateFileShareKey(dssKey, decryptionKey) {
  const longKey = decryptionKey.replace(/\//g, '_');
  return `${dssKey}${DELIMITER}${longKey}`;
}

export function generateEncryptionKey() {
  //Use SJCL library to generate encryption key.
  return sjcl.codec.base64.fromBits(sjcl.random.randomWords(8, 10), 0);
}
