import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';
import { getInitals } from '../../utils/GetInititals';
import Typography from '@mui/material/Typography';

function SignerInfo({ name, email }) {
  if (name && email) {
    return (
      <Stack direction={'row'} alignItems="center" spacing={'10px'} sx={{ width: '100%' }}>
        <Avatar sx={{ bgcolor: '#B81C3B' }}>{getInitals(name)}</Avatar>
        <Stack>
          <Typography variant="label_medium" align="left">
            {name}
          </Typography>
          <Typography variant="body_small" align="left">
            {email}
          </Typography>
        </Stack>
      </Stack>
    );
  }
  return <></>;
}
export default SignerInfo;
