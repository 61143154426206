import { loadScript } from '../../utils/domUtil';

// See https://developer.webex.com/docs/embedded-apps for more information
export default function initializeWebexApp(appURL) {
  return new Promise((resolve) => {
    let app;
    loadScript(
      'https://binaries.webex.com/static-content-pipeline/webex-embedded-app/v1/webex-embedded-app-sdk.js',
      {
        attrs: {
          defer: true,
        },
      },
      () => {
        app = window.Webex ? new window.Webex.Application() : undefined;
        // Wait for onReady() promise to fulfill before using framework
        app?.onReady();
      },
    );

    function setSharedAppUrl(documentId) {
      const url = `${appURL}/sharedapp/${documentId}`;
      // "Shared App" is the title of the window or tab that will be created
      app?.setShareUrl(url, '', 'Shared App');
    }

    resolve({
      setSharedAppUrl,
    });
  });
}
