import styled from '@emotion/styled';

export const SnackbarContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3.5),
  marginTop: '6.25%',
  borderRadius: '8px',

  '& img': {
    color: theme.palette.info.main,
  },

  '&.error': {
    backgroundColor: theme.palette.error.extraLight,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '8px',
    color: theme.palette.error.dark,
  },
  '&.info': {
    backgroundColor: '#EFFBFC',
    border: `1px solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  },
}));
