import { styled } from '@mui/material';

const PREFIX = 'FieldItem';

export const classes = {
  detailsText: `${PREFIX}-detailsText`,
  fieldItem: `${PREFIX}-fieldItem`,
};

export const Root = styled('div')(({ theme, isSelected }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: isSelected ? theme.palette.background.paper : theme.palette.background.main,
}));
