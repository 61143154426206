import { useLocation, useRouteMatch } from 'react-router-dom';
import { getDocumentToSignSelector } from '../../redux/slices/dVideoSignSlice/dVideoSignSlice';
import { useSelector } from 'react-redux';

export const RecipientRoles = Object.freeze({
  SIGNER: 'signer',
  OBSERVER: 'observer',
  APPROVER: 'approver',
});

function useRecipientRole(useNew = false) {
  let isObserver = false;
  let isSigner = false;
  let isApprover = false;
  let role = '';
  let currentUser = { is_downloadable: true };
  const { pathname } = useLocation();
  const { params } = useRouteMatch();
  const oldDocumentToSign = useSelector((state) => state.documenttosign);
  const newDocumentToSign = useSelector(getDocumentToSignSelector);
  const documentToSign = useNew ? newDocumentToSign : oldDocumentToSign;
  const uid = params.uid;
  const { document } = documentToSign;
  const { signers = [], observers = [], approvers = [] } = document || {};

  if (pathname) {
    isObserver = pathname.includes('observe');
    isSigner = pathname.includes('sign');
    isApprover = pathname.includes('approve');
  }

  if (isObserver) {
    role = RecipientRoles.OBSERVER;
    currentUser = observers.find((m) => m.id === uid);
  } else if (isSigner) {
    role = RecipientRoles.SIGNER;
    currentUser = signers.find((m) => m.id === uid);
  } else if (isApprover) {
    role = RecipientRoles.APPROVER;
    currentUser = approvers.find((m) => m.approver_id === uid);
  }

  return {
    isObserver,
    isApprover,
    isSigner,
    RecipientRoles,
    role,
    currentUser,
  };
}

export default useRecipientRole;
