import React from 'react';
import Popup from 'reactjs-popup';
import {
  singPassSignConfirmationLoading,
  singPassSignConfirmation,
} from '../Signingrequest/ndiSigningFunctions';
import './InitialPopUp.css';

const InitialPopUp = (props) => {
  return (
    <div>
      <Popup
        className="my-popup2"
        nested
        contentStyle={{ width: '500px', height: '360px' }}
        modal
        open={props.onOpen}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="modal2">
            <button
              className="close"
              onClick={() => {
                close();
                props.onReset();
                props.setOpen(false);
              }}
            >
              &times;
            </button>
            {props.onConfirm ? (
              <>
                {singPassSignConfirmation}
                <div className="singPass-button-container">
                  <button
                    className="singpass-button"
                    disabled={props.isDisabledSubmitBtn}
                    onClick={() => {
                      close();
                      props.onQRPopUp();
                    }}
                  >
                    <img
                      className={`red-sign-img ${props.isDisabledSubmitBtn ? 'opacity-5' : ''}`}
                      alt="sign with singpass mobile"
                      src="/RED Sign with Singpass (Recommended).svg"
                    />
                  </button>
                </div>
              </>
            ) : (
              <>{singPassSignConfirmationLoading}</>
            )}
          </div>
        )}
      </Popup>
    </div>
  );
};

export default InitialPopUp;
