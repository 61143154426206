import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { joinMeetingApi, startMeetingApi, startRecordingApi, stopRecordingApi } from './twilioApi';

// can change its name by need
const SLICE_NAME = 'twilio';

function handleApiError(state, { payload = {} }) {
  console.error(payload.desc);
  // Set error state with erorr code
  state.error = payload.code;
  state.isLoading = false;
  state.isRecordScreenLoading = false;
}

export const startMeeting = createAsyncThunk(
  `${SLICE_NAME}/startMeeting`,
  async ({ documentId, name, email, role, userId }, { rejectWithValue }) => {
    try {
      const response = await startMeetingApi({
        document_id: documentId,
        name,
        email,
        role,
        userId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const joinMeeting = createAsyncThunk(
  `${SLICE_NAME}/joinMeeting`,
  async ({ documentId, name, email, userId }, { rejectWithValue }) => {
    try {
      const response = await joinMeetingApi({ document_id: documentId, name, email, userId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const startRecording = createAsyncThunk(
  `${SLICE_NAME}/startRecording`,
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const response = await startRecordingApi({ businessProcessId: documentId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const stopRecording = createAsyncThunk(
  `${SLICE_NAME}/stopRecording`,
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const response = await stopRecordingApi({ businessProcessId: documentId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  isLoading: false,
  error: null,
  roomToken: '',
  room: null,
  hasLeftMeeting: false,
  isAudioMuted: false,
  isVideoMuted: false,
  screenTrack: {},
  isConnecting: false,
  isRecordScreenLoading: false,
  isSharingScreen: false,
};

export const twilioSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setRoom: (state, action) => {
      state.room = action.payload;
      state.hasLeftMeeting = false;
    },
    removeRoom: (state) => {
      state.room = null;
      state.roomToken = '';
      state.hasLeftMeeting = true;
    },
    setIsAudioMuted: (state, action) => {
      state.isAudioMuted = action.payload;
    },
    setIsVideoMuted: (state, action) => {
      state.isVideoMuted = action.payload;
    },
    setScreenTrack: (state, action) => {
      const participantId = action.payload.name.split('participant-screen-')[1];
      state.screenTrack[participantId] = action.payload;
    },
    removeScreenTrack: (state, action) => {
      delete state.screenTrack[action.payload];
    },
    setConnecting: (state, action) => {
      state.isConnecting = action.payload;
    },
    setSharingScreen: (state, action) => {
      state.isSharingScreen = action.payload;
    },
    setHasLeftMeeting: (state, action) => {
      state.hasLeftMeeting = action.payload;
    },
  },
  extraReducers: (builder) => {
    //startMeeting
    builder.addCase(startMeeting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(startMeeting.rejected, handleApiError);
    builder.addCase(startMeeting.fulfilled, (state, action) => {
      state.roomToken = action.payload.roomToken;
      state.isLoading = false;
    });
    //joinMeeting
    builder.addCase(joinMeeting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(joinMeeting.rejected, handleApiError);
    builder.addCase(joinMeeting.fulfilled, (state, action) => {
      state.roomToken = action.payload.roomToken;
      state.isLoading = false;
    });
    //startRecording
    builder.addCase(startRecording.pending, (state) => {
      state.isRecordScreenLoading = true;
    });
    builder.addCase(startRecording.rejected, handleApiError);
    builder.addCase(startRecording.fulfilled, (state) => {
      state.isRecordScreenLoading = false;
    });
    //stopRecording
    builder.addCase(stopRecording.pending, (state) => {
      state.isRecordScreenLoading = true;
    });
    builder.addCase(stopRecording.rejected, handleApiError);
    builder.addCase(stopRecording.fulfilled, (state) => {
      state.isRecordScreenLoading = false;
    });
  },
});

const { reducer, actions } = twilioSlice;

export const {
  setRoom,
  removeRoom,
  setIsAudioMuted,
  setIsVideoMuted,
  setScreenTrack,
  removeScreenTrack,
  setConnecting,
  setSharingScreen,
  setHasLeftMeeting,
} = actions;

export const getTwilioSelector = (state) => state[SLICE_NAME];

export default reducer;
