export async function hasCamera() {
  try {
    return !!(await navigator.mediaDevices.getUserMedia({ video: true, audio: false }));
  } catch {
    return false;
  }
}

export async function hasMicrophone() {
  try {
    return !!(await navigator.mediaDevices.getUserMedia({ video: false, audio: true }));
  } catch {
    return false;
  }
}
