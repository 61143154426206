import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const PREFIX = 'Field';

export const classes = {
  detailsText: `${PREFIX}-detailsText`,
  fieldLabel: `${PREFIX}-fieldLabel`,
  fieldItem: `${PREFIX}-fieldItem`,
  numberOfFields: `${PREFIX}-numberOfFields`,
  signAllButton: `${PREFIX}-signAllButton`,
  signAllLabel: `${PREFIX}-signAllLabel`,
};

export const FieldAccordion = styled(Accordion)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.paper,
  borderRadius: '8px !important',

  // removes top border of 2nd Accordion onwards
  '&:before': {
    display: 'none',
  },
}));

export const FieldAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  height: 28,

  [`& .${classes.fieldLabel}`]: {
    margin: theme.spacing(4, 2),
    fontWeight: 600,
    display: 'flex',
    gap: theme.spacing(2),
  },

  [`& .${classes.numberOfFields}`]: {
    display: 'block',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '20px',
    width: 20,
    height: 20,
    fontSize: '12px',
  },

  [`& .${classes.signAllButton}`]: {
    height: 27,
    width: 74,
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    background: '#6531FF',
    margin: theme.spacing(0, 2),
  },

  [`& .${classes.signAllLabel}`]: {
    fontWeight: 600,
  },

  [`.MuiAccordionSummary-content`]: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const FieldAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.lightGrey.primary,
  backgroundColor: theme.palette.background.main,
  textAlign: 'left',
  padding: theme.spacing(1),
  margin: theme.spacing(0, 4, 4, 4),
  borderRadius: 4,

  [`& .${classes.fieldItem}`]: {
    cursor: 'pointer',
  },
}));
