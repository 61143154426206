import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, IconButton, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useTranslation } from 'react-i18next';
import uploadIcon from '../../../../assets/upload.svg';
import { formatBytes } from '../../../../utils/formatBytes';
import { classes, Root } from './UploadFile.styles';

const UploadFile = (props) => {
  const { onFilesUploaded, accept, innerText, onRemoveFile } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const fileInputRef = useRef();
  const [file, setFile] = useState(null);

  const handleFileUpload = async (file) => {
    setFile(file);
    onFilesUploaded?.(file);
  };

  const handleTargetClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
    setFile(null);
    onRemoveFile?.();
  };

  const inner = file ? (
    <div data-testid="sidebar-uploaded-file-box" className={classes.uploadedFileBox}>
      <div style={{ width: '100%' }}>
        <Typography variant="label_small" color="primary" sx={{ display: 'block' }}>
          {file.name}
        </Typography>
        <Typography
          variant="label_small"
          sx={{ fontSize: '10px', color: theme.palette.text.tertiary }}
        >
          {formatBytes(file.size)}
        </Typography>
      </div>
      <IconButton
        aria-label="delete"
        className={classes.removeFileButton}
        onClick={handleRemoveFile}
      >
        <CloseRoundedIcon />
      </IconButton>
    </div>
  ) : (
    <>
      <Typography variant="label_small" className={classes.fileDropText}>
        {innerText}
      </Typography>
      <div className={classes.fileDropOr}>
        <Typography className={classes.fileDropSpan} variant="body_small">
          {t('UploadFileOr')}
        </Typography>
      </div>
      <Button
        data-testid="sidebar-upload-file-button"
        className={classes.fileDropButton}
        onClick={handleTargetClick}
      >
        <img src={uploadIcon} />
        {t('UploadFileUploadFiles')}
      </Button>
    </>
  );

  return (
    <Root>
      <FileDrop onDrop={(files, _event) => handleFileUpload(files[0])}>
        <input
          data-testid="sidebar-file-input"
          onChange={(e) => handleFileUpload(e.target.files[0])}
          ref={fileInputRef}
          type="file"
          className={classes.fileInput}
          accept={accept}
        />
        <div className={classes.fileDrop}>
          <div className={classes.innerFileDrop}>{inner}</div>
        </div>
      </FileDrop>
    </Root>
  );
};

export default UploadFile;
