import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  verifyOTP as _verifyOTP,
  sendVerifyOTP,
  getOtpSelector,
} from '../../redux/slices/otpSlice/otpSlice';
import useRecipientRole from '../useRecipientRole';
import { useCallback } from 'react';

function useOTP() {
  const dispatch = useDispatch();
  const { role } = useRecipientRole();

  const { id, uid, public: publicId } = useParams();
  const { isLoading } = useSelector(getOtpSelector);

  const sendOTP = useCallback(() => {
    return dispatch(sendVerifyOTP({ id, uid, publicId, role })).unwrap();
  }, [dispatch, id, publicId, role, uid]);

  const verifyOTP = useCallback(
    (otp) => {
      return dispatch(_verifyOTP({ id, uid, otp, publicId, role })).unwrap();
    },
    [dispatch, id, publicId, role, uid],
  );

  return { sendOTP, verifyOTP, isLoading };
}

export default useOTP;
