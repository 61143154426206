import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';
import { StyledDialog, classes, RedBox, RedButton } from './Dialog.style';

export default function TwoStatusDialog({
  onClose,
  onOk,
  isRed = false,
  okButtonText,
  cancelButtonText,
  isLoading = false,
  title,
  content = [],
  open,
}) {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        {isRed ? (
          <RedBox>
            {content
              .filter((item) => item.isRed)
              .map((item, index) => (
                <DialogContentText
                  key={index}
                  className={item.class}
                  sx={(theme) => ({ marginTop: index === 0 ? 0 : theme.spacing(4) })}
                >
                  {item.text}
                </DialogContentText>
              ))}
          </RedBox>
        ) : (
          <>
            {content
              .filter((item) => !item.isRed)
              .map((item, index) => (
                <DialogContentText
                  key={index}
                  className={item.class}
                  sx={(theme) => ({ marginTop: theme.spacing(4) })}
                >
                  {item.text}
                </DialogContentText>
              ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {okButtonText && (
          <RedButton variant="contained" disabled={isLoading} isLoading={isLoading} onClick={onOk}>
            {okButtonText}
          </RedButton>
        )}
        {cancelButtonText && (
          <Button
            sx={(theme) => ({ margin: theme.spacing(0, 4, 6, 0) })}
            onClick={onClose}
            disabled={isLoading}
            variant="contained"
          >
            {cancelButtonText}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
