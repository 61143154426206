import { TextField } from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { MediumInputBase } from '../MediumInputBase';

function TextInputComponent(
  { inputId, onConfirmInput, onClose, title, submitButtonText, description, isRequired },
  ref,
) {
  const [text, setText] = useState();
  const closeInput = useCallback(() => {
    onClose(inputId);
  }, [inputId, onClose]);

  const onTextChange = useCallback((event) => {
    setText(event.target.value);
  }, []);

  const confirmInput = useCallback(() => {
    onConfirmInput(inputId, text);
  }, [inputId, onConfirmInput, text]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        confirmInput();
      }
    },
    [confirmInput],
  );

  useImperativeHandle(ref, () => ({ closeInput }), [closeInput]);

  return (
    <MediumInputBase
      onConfirmInput={confirmInput}
      onClose={closeInput}
      title={title}
      description={description}
      submitButtonText={submitButtonText}
      isRequired={isRequired}
    >
      <TextField onChange={onTextChange} onKeyDown={handleKeyDown} />
    </MediumInputBase>
  );
}

export const TextInput = forwardRef(TextInputComponent);
