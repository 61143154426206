import { dssApi } from './axios';
import { splitFileShareKey } from '../../utils/fileShareKey';
import sjcl from 'sjcl';

export const uploadFileToDSS = async (encrypted, errorHandlers, options) => {
  try {
    const params = { encrypted };
    if (typeof options?.expiresAt === 'number') {
      params['expires_at'] = options.expiresAt;
    }
    const { data } = await dssApi.post('/e', params);
    return data;
  } catch (err) {
    if (err.code === 'ECONNABORTED' && typeof errorHandlers?.timeoutErrorHandler === 'function') {
      errorHandlers.timeoutErrorHandler();
    } else if (typeof errorHandlers?.genericErrorHandler === 'function') {
      errorHandlers.genericErrorHandler(err);
    } else {
      throw err;
    }
  }
};

export const downloadFileFromDSS = async (fileShareKey) => {
  const { shortKey, decryptionKey } = splitFileShareKey(fileShareKey);
  const resp = await dssApi.get(`/e/${shortKey}`);
  const encrypted = resp.data?.encrypted;
  if (!encrypted) return undefined;

  return sjcl.decrypt(decryptionKey, encrypted);
};
