import { styled } from '@mui/material';

const PREFIX = 'timer';

export const classes = {
  timer: `${PREFIX}-timer`,
  lessTimer: `${PREFIX}-less-timer`,
};

export const Container = styled('div')(({ theme }) => ({
  border: '1px solid #FF660014',
  background:
    'linear-gradient(0deg, rgba(255, 102, 0, 0.08), rgba(255, 102, 0, 0.08)), linear-gradient(0deg, rgba(255, 102, 0, 0.04), rgba(255, 102, 0, 0.04))',
  margin: theme.spacing(0, 4),
  padding: theme.spacing(2, 3),
  borderRadius: '8px',
  ...theme.typography.body_medium,
  [`.${classes.timer}`]: {
    color: theme.palette.secondary.main,
    ...theme.typography.label_large,
  },
  [`&.${classes.lessTimer}`]: {
    background: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    color: 'white',
    ...theme.typography.body_medium,
    [`.${classes.timer}`]: {
      color: 'white',
      ...theme.typography.label_large,
    },
  },
}));
