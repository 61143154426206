import React, { useCallback, useEffect, useState } from 'react';
import LoginWithSingPassLogo from '../../../../assets/login-with-singpass-button.svg';
import { useDispatch } from 'react-redux';

import { useLocation, useParams } from 'react-router';
import useRecipientRole from '../../../../hooks/useRecipientRole';
import { LoadingOverlay } from '../../UI/LoadingOverlay';
import { useTranslation } from 'react-i18next';
import { classes, LoginErrorNoticeRoot, Root } from './SingPassLogin.style';
import { NoticeIcon } from '../../Icons/NoticeIcon';
import { Stack, Typography, useTheme } from '@mui/material';
import { process_env } from '../../../../utils/envData';
import {
  verifyApproverSingPassAuthCode,
  verifyObserverSingPassAuthCode,
  verifySignerSingPassAuthCode,
} from '../../../../redux/actions/documentfunctions';
import { generateFileShareKey } from '../../../../utils/fileShareKey';
import {
  useReplaceStringWithComponent,
  useReplaceSubstringsWithComponents,
} from '../../../../hooks/useReplaceStringWithComponent';

export function SingPassLogin() {
  const { hash: dssDecryptionKey } = useLocation();
  const singPassAuthCode = localStorage.getItem('singPassLoginAuthCode');
  localStorage.removeItem('singPassLoginAuthCode');
  const dispatch = useDispatch();
  const [loginFailed, setLoginFailed] = useState(false);
  const { id: documentId, uid: recipientId, key: dssEnvelopeId } = useParams();
  const { isObserver, isApprover } = useRecipientRole();

  const fileShareKey = generateFileShareKey(dssEnvelopeId, dssDecryptionKey.slice(1));

  const handleLoginFailed = () => {
    setLoginFailed(true);
  };

  useEffect(() => {
    if (!singPassAuthCode) {
      return;
    }

    if (isObserver) {
      dispatch(verifyObserverSingPassAuthCode(documentId, recipientId, singPassAuthCode)).catch(
        handleLoginFailed,
      );
    } else if (isApprover) {
      dispatch(verifyApproverSingPassAuthCode(documentId, recipientId, singPassAuthCode)).catch(
        handleLoginFailed,
      );
    } else {
      dispatch(verifySignerSingPassAuthCode(documentId, recipientId, singPassAuthCode)).catch(
        handleLoginFailed,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isObserver, isApprover, documentId, recipientId, singPassAuthCode]);

  return (
    <>
      {singPassAuthCode && !loginFailed ? (
        <LoadingOverlay />
      ) : (
        <Stack spacing={6}>
          {loginFailed && <LoginErrorNotice />}
          <LoginView
            recipientId={recipientId}
            documentId={documentId}
            fileShareKey={fileShareKey}
            isObserver={isObserver}
            isApprover={isApprover}
          />
        </Stack>
      )}
    </>
  );
}

const LoginView = ({ recipientId, documentId, fileShareKey, isObserver, isApprover }) => {
  const { t } = useTranslation();
  const loginWithSingPass = useCallback(() => {
    const singpassLoginDomain = process_env('REACT_APP_SINGPASS_AUTH_ENDPOINT');
    const loginRedirectUrl = encodeURIComponent(
      process_env('REACT_APP_SINGPASS_AUTH_REDIRECT_URI'),
    );
    const clientId = encodeURIComponent(process_env('REACT_APP_SINGPASS_AUTH_CLIENT_ID'));
    let action = 'sign';
    if (isObserver) {
      action = 'observe';
    } else if (isApprover) {
      action = 'approve';
    }
    const nonce = encodeURIComponent(window.btoa(Date.now()));
    // Using JSON format instead of delimiters to identify values to make it less prone to parsing error.
    // Keep key name short as there is a limit to the number of characters for state.
    const state = encodeURIComponent(
      window.btoa(
        `{"a":"${action}","id":"${documentId}","uid":"${recipientId}","f":"${fileShareKey}"}`,
      ),
    );

    const loginUrl = `${singpassLoginDomain}?scope=openid&response_type=code&redirect_uri=${loginRedirectUrl}&client_id=${clientId}&state=${state}&nonce=${nonce}`;
    window.location.href = loginUrl;
  }, [isObserver, isApprover, documentId, recipientId, fileShareKey]);

  const loginMessage = useReplaceSubstringsWithComponents(t('SingPassLoginCardMessage'), {
    [t('SingPassLoginCardMessagePrivacyPolicy')]: PrivacyPolicyLink,
    [t('SingPassLoginCardMessageTermsOfUse')]: TermsOfUseLink,
  });
  return (
    <Root>
      <Typography variant="title_large" color="text.primary">
        {t('SingPassLoginCardTitle')}
      </Typography>
      <div className={classes.loginCardMessage}>
        <Typography variant="body_small" color="darkGrey.main">
          {loginMessage}
        </Typography>
      </div>
      <img
        className={classes.loginButton}
        src={LoginWithSingPassLogo}
        onClick={loginWithSingPass}
      />
    </Root>
  );
};

const LoginErrorNotice = () => {
  const { t } = useTranslation();
  const noticeMessage = useReplaceStringWithComponent(
    t('SingPassLoginErrorMessage'),
    t('SingPassLoginErrorContactSupport'),
    ContactSupportLink,
  );
  return (
    <LoginErrorNoticeRoot>
      <NoticeIcon className={classes.loginErrorNoticeIcon} />
      <div className={classes.loginErrorNoticeMessage}>
        <Typography variant="title_medium" color="text.primary">
          {t('SingPassLoginErrorNoticeTitle')}
        </Typography>
        <Typography variant="body_medium" color="text.primary">
          {noticeMessage}
        </Typography>
      </div>
    </LoginErrorNoticeRoot>
  );
};

const LinkComponent = ({ children, href }) => {
  const theme = useTheme();
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
      href={href}
    >
      {children}
    </a>
  );
};

const PrivacyPolicyLink = ({ value }) => {
  return <LinkComponent href="https://www.dedoco.com/privacy-policy">{value}</LinkComponent>;
};

const TermsOfUseLink = ({ value }) => {
  return <LinkComponent href="https://www.dedoco.com/terms-of-use">{value}</LinkComponent>;
};

const ContactSupportLink = ({ value }) => {
  return <LinkComponent href="https://www.dedoco.com/contact-us">{value}</LinkComponent>;
};
