import sjcl from 'sjcl';
import { uploadFileToDSS } from '../lib/api/dss';
import { generateFileShareKey } from './fileShareKey';
import { DSS_EXPIRATION_OFFSET } from './GlobalConstant';

export async function encryptAndUploadFileToDSS(fileBase64, errorHandlers, options) {
  const encryptKey = sjcl.codec.base64.fromBits(sjcl.random.randomWords(8, 10), 0);
  const encryptedFile = sjcl.encrypt(encryptKey, fileBase64);
  const { id } = await uploadFileToDSS(encryptedFile, errorHandlers, options);
  return generateFileShareKey(id, encryptKey);
}

export function getDSSExpirationFromBpExpiration(bpExpiration) {
  // This is used to make sure the document only expires a constant number of days after the last action taken.
  // This is the agreed behavior at the time of writing.
  return bpExpiration === 0 ? 0 : bpExpiration + DSS_EXPIRATION_OFFSET;
}
