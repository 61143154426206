import { styled } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const WaitingForSignatureRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
  flexDirection: 'column',
  justifyContent: 'center',
  width: '220px',
  marginTop: theme.spacing(16),
}));

export const WaitingForSignatureImage = styled('img')(() => ({
  position: 'relative',
  left: '-100px',
  animation: 'slide 0.5s forwards',
  animationDelay: '0s',
}));

export const WaitingForSignatureDescription = styled('div')(() => ({
  fontSize: '16px',
  textAlign: 'center',
  color: '#504e56',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  opacity: 1,
  animationName: fadeIn,
  animationIterationCount: 1,
  animationTimingFunction: 'ease-in',
  animationDuration: '2s',
}));
