import { styled, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export const Root = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    backgroundColor: theme.palette.background.main,
    boxShadow: 'none',
    borderRadius: '8px',
    height: 'auto',
    width: '570px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: '90vh',
    },
  },
}));

export const Header = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  padding: '30px',
  fontWeight: 'bold',
  fontSize: '30px',
  fontFamily: 'Lato',
  color: '#504E56',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    height: '20px',
  },
}));

export const Content = styled(DialogContent)(({ theme }) => ({
  backgroundColor: '#E1E1E1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing(4)} !important`,
  overflow: 'auto',
}));

export const SingPassContent = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: '70%',
  boxSizing: 'border-box',
  minHeight: '510px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(4, 0),
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: 'auto',
  },
}));

export const LoadingSpinner = styled('img')(({ theme }) => ({
  margin: theme.spacing('auto', 0),
}));

export const Footer = styled(DialogActions)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FooterMessage = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: '20px',
  fontSize: '20px',
  fontFamily: 'Lato',
  color: '#504E56',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const CancelButton = styled('button')(() => ({
  maxWidth: '393px',
  width: '90%',
  height: '40px',
  fontSize: '15px',
  fontFamily: 'Lato',
  color: '#504E56',
}));
