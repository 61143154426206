import { styled } from '@mui/material';

const PREFIX = 'DownloadDocument';

export const classes = {
  container: `${PREFIX}-container`,
  subtext: `${PREFIX}-subtext`,
  visitDedoco: `${PREFIX}-visitDedoco`,
};

export const DownloadDocumentRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: '100%',
    marginTop: theme.spacing(5),
  },

  [`& .${classes.container}`]: {
    width: '60%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  [`& .${classes.subtext}`]: {
    marginTop: theme.spacing(5),
  },

  [`& .${classes.visitDedoco}`]: {
    margin: theme.spacing(0, 5),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0),
    },
  },
}));
