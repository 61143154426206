import { styled } from '@mui/material';

export const SomethingWrongErrorRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(28, 14, 0, 14),
}));

export const SomethingWrongErrorIcon = styled('img')(() => ({
  height: '60px',
  width: '60px',
}));

export const SomethingWrongErrorHeader = styled('div')(() => ({
  paddingTop: '16px',
  fontSize: '20px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
}));

export const SomethingWrongErrorMessage = styled('div')(() => ({
  paddingTop: '16px',
  textAlign: 'center',
  fontSize: '15px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
}));
