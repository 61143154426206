import { styled } from '@mui/material';

const PREFIX = 'RecipientDetails';

export const classes = {
  formRoot: `${PREFIX}-form-root`,
  contactDetailsContainer: `${PREFIX}-contactDetailsContainer`,
  recipientDetailsDescription: `${PREFIX}-recipientDetailsDescription`,
  contactDetailsTitle: `${PREFIX}-contactDetailsTitle`,
  textFieldLabel: `${PREFIX}-textFieldLabel`,
  textField: `${PREFIX}-textField`,
  termsConditionContainer: `${PREFIX}-termsConditionContainer`,
  termsConditionContent: `${PREFIX}-termsConditionContent`,
  proceedButton: `${PREFIX}-procced-button`,
  errorCaption: `${PREFIX}-error-caption`,
};

export const Root = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'space-between',

  [`& .${classes.formRoot}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },

  [`& .${classes.contactDetailsContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    borderRadius: '8px',
    width: '100%',
    alignItems: 'baseline',
  },

  [`& .${classes.contactDetailsTitle}`]: {
    margin: theme.spacing(4, 4, 2, 4),
    color: theme.palette.text.primary,
  },

  [`& .${classes.textFieldLabel}`]: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.textField}`]: {
    margin: theme.spacing(2),
    width: 240,
    [`& input`]: {
      border: `1px solid ${theme.palette.stroke.dark}`,
      borderRadius: '4px',
      width: '100%',
      height: 28,
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(0, 3),

      ['&::placeholder']: {
        color: theme.palette.text.tertiary,
      },
    },
  },

  [`& .${classes.recipientDetailsDescription}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.termsConditionContainer}`]: {
    backgroundColor: theme.palette.supporting.main,
    display: 'flex',
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    border: 'solid 2px',
    borderColor: theme.palette.lightGrey.border,
    padding: theme.spacing(3),
    width: 240,
  },

  [`& .${classes.termsConditionContent}`]: {
    backgroundColor: theme.palette.supporting.main,
    flexGrow: 1,
    width: 0,
  },

  [`& .${classes.proceedButton}`]: {
    marginBlock: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    width: 270,
    color: theme.palette.background.main,
    borderRadius: '8px',
    padding: theme.spacing(3),
  },

  [`& .${classes.errorCaption}`]: {
    color: theme.palette.error.main,
  },
}));
