import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReplaceStringWithComponent } from '../../../../hooks/useReplaceStringWithComponent';
import { StyledDialog, classes } from './Dialog.style';

const Red10Mins = ({ value }) => <span className={classes.dialogTitleRed}>{value}</span>;

export default function MeetingTimeRemainingDialog({ open, onClose }) {
  const { t } = useTranslation();

  const title = useReplaceStringWithComponent(
    t('MeetingRemaining10MinsTitle'),
    t('MeetingRemainingTimes'),
    Red10Mins,
  );

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>
          {t('MeetingRemaining10MinsContent')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={(theme) => ({ margin: theme.spacing(0, 6, 6, 0) })}
          onClick={onClose}
          variant="contained"
        >
          {t('Done')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
