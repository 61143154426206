import { Stack, styled } from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  zIndex: 9999,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));
