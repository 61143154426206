import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTimer } from 'react-timer-hook';
import useGetRequiredDedocoSigning from '../../../hooks/useDedocoSigning/useGetRequiredDedocoSigning';

import {
  completeITextPDF,
  getNDISessId,
  getNDISignature,
  getSignRef,
  prepareITextPDF,
  sendDocHash,
} from '../../../lib/api';

import NdiSigningConfirmationModal from '../components/NdiSigningConfirmationModal';
import QrAndChallengeCodeModal from '../components/QrAndChallengeCodeModal';
import { blobToBase64, blobToBase64DataUrl } from '../../../utils/base64';
import { SigningStates } from '../shared';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag, featureNameMap } from '../../../hooks/useFeatureFlag';
import { usePdfTronContext } from '../../usePdfTronContext';

const NdiSigningContext = createContext({});

const NdiSigningProvider = ({ children, useNewDedocoSigning = false }) => {
  const {
    uid,
    businessProcess,
    currentParticipant,
    signDocumentWithNdi,
    embedDocWithDigitalSignatures,
  } = useGetRequiredDedocoSigning(useNewDedocoSigning);
  const userCertQueryInterval = useRef();
  const signatureQueryInterval = useRef();
  const [ndiSessionId, setNdiSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [signingStep, setSigningStep] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [qrAndChallengeCodeModalOpen, setQrAndChallengeCodeModalOpen] = useState(false);
  const [qrData, setQrData] = useState('');
  const [userCert, setUserCert] = useState('');
  const [iTextId, setITextId] = useState('');
  const [docHash, setDocHash] = useState('');
  const [challengeCode, setChallengeCode] = useState('');
  const [ndiSigningCompletedCallback, setNdiSigningCompletedCallback] = useState(null);
  const [allowNdiSigning, setAllowNdiSigning] = useState(false);
  const [documentBase64, setDocumentBase64] = useState('');
  const history = useHistory();
  const isSingPassSigningMaintenance = useFeatureFlag(featureNameMap.singPassSigningMaintenance);
  const { instance } = usePdfTronContext();

  // TEMPORARY FIX, REMOVE AFTER BUILDER APP CHECK IS IMPLEMENTED
  const assumeAllBadSigningIsBadPdf = useFeatureFlag(featureNameMap.assumeAllBadSigningIsBadPdf);

  const {
    seconds: timerSeconds,
    minutes: timerMinutes,
    restart: restartTimer,
    pause: pauseTimer,
  } = useTimer({
    // This is a required param, but it is not used as the timer's expiration will be set using "restartTimer" function
    expiryTimestamp: new Date(),
    // Timer should not start immediately. It should be started when needed.
    autoStart: false,
    onExpire: () => console.warn('onExpire called'),
  });
  const { t } = useTranslation();

  const [snackbarDigitalState, setSnackbarDigitalState] = useState({
    open: false,
    severity: '',
    message: '',
    duration: null,
  });

  const generateNDISessId = useCallback(async () => {
    try {
      const res = await getNDISessId(businessProcess?.id, uid);
      return res?.ndiSessId;
    } catch (error) {
      console.error(error);
    }
  }, [businessProcess?.id, uid]);

  const initiateNdiSigning = useCallback(async () => {
    if (isSingPassSigningMaintenance) {
      history.push('/maintenance');
    }

    if (allowNdiSigning) {
      setNdiSessionId(await generateNDISessId());
      setConfirmationModalOpen(true);
    } else {
      setSnackbarDigitalState({
        open: true,
        severity: 'error',
        message: t('NdiSigningErrorMessage'),
        duration: 3000,
      });
    }
  }, [allowNdiSigning, generateNDISessId, t, isSingPassSigningMaintenance, history]);

  // This ref serves as an object with stable reference and it holds the updated instance of initiateNdiSigning
  // Used to allow functions that are not recreated during re-render to get access to the updated version of initiateNdiSigning through its "current" property.
  const initiateNdiSigningRef = useRef(null);
  initiateNdiSigningRef.current = initiateNdiSigning;

  const prepareDocToSignAndShowQrCode = async () => {
    setQrAndChallengeCodeModalOpen(true);
    setSigningStep(SigningStates.QR_SCANNING);
    setConfirmationModalOpen(false);
    // Embeding digital signatuers might take some time, make sure to generate QR code only after getting the document
    // This prevents the QR code and the NDI signing session from expiring before the signer can finish the NDI signing process.
    setDocumentBase64(await embedDocWithDigitalSignatures(instance, false));

    const { qr, code } = await generateQrAndChallengeCode();

    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    restartTimer(time);

    setQrData(qr);
    setChallengeCode(code);
    setIsLoading(false);
  };

  useEffect(() => {
    if (qrData && challengeCode) {
      queryUserCert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrData, challengeCode]);

  const generateQrAndChallengeCode = async () => {
    if (!ndiSessionId) {
      return {};
    }

    try {
      const signRef = await getSignRef(ndiSessionId);
      return {
        qr: signRef.ndi_qr_code.qr_code.payload,
        code: signRef.ndi_challenge_code,
      };
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  const queryUserCert = () => {
    if (userCertQueryInterval.current) {
      clearInterval(userCertQueryInterval.current);
    }

    userCertQueryInterval.current = setInterval(async () => {
      try {
        if (!ndiSessionId) {
          return;
        }

        const ndiSessionSignatureInfo = await getNDISignature(ndiSessionId);
        if (ndiSessionSignatureInfo.status !== 'pending-signature') {
          return;
        }

        clearInterval(userCertQueryInterval.current);

        setIsLoading(true);
        setUserCert(ndiSessionSignatureInfo.ndi_signer_cert);
      } catch (e) {
        setHasError(true);
      }
    }, 1000);
  };

  const prepareDocWithIText = async () => {
    if (!userCert) {
      return;
    }

    try {
      const iTextPdfOutput = await getITextPdfOutput(userCert);
      setDocHash(iTextPdfOutput.hash);
      setITextId(iTextPdfOutput.id);
      setSigningStep(SigningStates.SIGNING);
    } catch (e) {
      setHasError(true);
    }
  };

  const sendDocHashToUser = async () => {
    try {
      await sendDocHash(ndiSessionId, docHash);

      const time = new Date();
      time.setSeconds(time.getSeconds() + 120);
      restartTimer(time);
      setIsLoading(false);
    } catch (e) {
      setHasError(true);
    }
  };

  const prepareITextPDFWithMetadataForLogs = (...args) => {
    return prepareITextPDF(...args, {
      businessProcessId: businessProcess.id,
      signerId: currentParticipant.id,
    });
  };

  const getITextPdfOutput = async (userCert) => {
    const digiSignaturePlacement = currentParticipant?.digiSignatures[0].placement;

    let placementX = digiSignaturePlacement.x.includes('%')
      ? parseFloat(digiSignaturePlacement.x) / 100
      : digiSignaturePlacement.x;
    let placementY = digiSignaturePlacement.y.includes('%')
      ? parseFloat(digiSignaturePlacement.y) / 100
      : digiSignaturePlacement.y;

    try {
      return await prepareITextPDFWithMetadataForLogs(
        documentBase64,
        userCert,
        placementX,
        placementY,
        digiSignaturePlacement.page,
        'small',
      );
    } catch (e) {
      if (assumeAllBadSigningIsBadPdf) {
        const flattenedUnsignedDocRaw = await instance.Core.documentViewer
          .getDocument()
          .getFileData({ flatten: true });
        const blob = new Blob([new Uint8Array(flattenedUnsignedDocRaw)], {
          type: 'application/pdf',
        });

        const flattenedUnsignedDoc = await blobToBase64(blob);

        return prepareITextPDFWithMetadataForLogs(
          flattenedUnsignedDoc,
          userCert,
          placementX,
          placementY,
          digiSignaturePlacement.page,
          'small',
        );
      } else {
        throw e;
      }
    }
  };

  useEffect(() => {
    if (userCert) {
      prepareDocWithIText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCert]);

  useEffect(() => {
    if (iTextId && docHash) {
      sendDocHashToUser();
      queryNDISignature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iTextId, docHash]);

  const queryNDISignature = async () => {
    if (signatureQueryInterval.current) {
      clearInterval(signatureQueryInterval.current);
    }

    signatureQueryInterval.current = setInterval(async () => {
      try {
        const ndiSessionSignatureInfo = await getNDISignature(ndiSessionId);
        const status = ndiSessionSignatureInfo.status;
        if (status === 'second-leg-failed') {
          setHasError(true);
          clearInterval(signatureQueryInterval.current);
        } else if (status === 'completed') {
          completeNdiSignature(ndiSessionSignatureInfo.ndi_signature);
          clearInterval(signatureQueryInterval.current);
          setIsLoading(true);
        }
      } catch (e) {
        setHasError(true);
      }
    }, 1000);
  };

  const completeNdiSignature = async (ndiSignature) => {
    if (!iTextId || !userCert || !ndiSignature) {
      return;
    }

    const signedDocument = await completeITextPDF(iTextId, userCert, ndiSignature, {
      businessProcessId: businessProcess.id,
      signerId: currentParticipant.id,
    });
    const docDataUrl = await blobToBase64DataUrl(signedDocument);
    const { dssKey } = await signDocumentWithNdi(ndiSignature, docDataUrl);

    try {
      await ndiSigningCompletedCallback?.({
        ndiSignature,
        dssKey,
      });

      setQrAndChallengeCodeModalOpen(false);
      cleanUpNdiSigningState();
    } catch (e) {
      setHasError(true);
    }
  };

  const cleanUpNdiSigningState = useCallback(() => {
    setQrData('');
    setChallengeCode('');
    setUserCert('');
    setDocHash('');
    setITextId('');
    setIsLoading(true);
    setSigningStep('');
    setNdiSessionId('');
    setHasError(false);
    setQrAndChallengeCodeModalOpen(false);
    setConfirmationModalOpen(false);
    pauseTimer();
    if (signatureQueryInterval.current) {
      clearInterval(signatureQueryInterval.current);
    }
    if (userCertQueryInterval.current) {
      clearInterval(userCertQueryInterval.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshQr = useCallback(() => {
    cleanUpNdiSigningState();
    initiateNdiSigning();
  }, [initiateNdiSigning, cleanUpNdiSigningState]);

  const contextValue = useMemo(
    () => ({
      initiateNdiSigning,
      initiateNdiSigningRef,
      snackbarDigitalState,
      setSnackbarDigitalState,
      setNdiSigningCompletedCallback,
      setAllowNdiSigning,
    }),
    [initiateNdiSigning, snackbarDigitalState],
  );

  return (
    <NdiSigningContext.Provider value={contextValue}>
      <NdiSigningConfirmationModal
        open={confirmationModalOpen}
        onClose={cleanUpNdiSigningState}
        onConfirm={prepareDocToSignAndShowQrCode}
      />
      <QrAndChallengeCodeModal
        open={qrAndChallengeCodeModalOpen}
        onClose={cleanUpNdiSigningState}
        qrData={qrData}
        challengeCode={challengeCode}
        timerMinutes={timerMinutes}
        timerSeconds={timerSeconds}
        signingStep={signingStep}
        hasError={hasError}
        isLoading={isLoading}
        onRefreshQr={onRefreshQr}
      />
      {children}
    </NdiSigningContext.Provider>
  );
};

function useNdiSigningContext() {
  return useContext(NdiSigningContext);
}

const withNdiSigning =
  (WrappedComponent, useNewDedocoSigning = false) =>
  // eslint-disable-next-line react/display-name
  (props) => {
    return (
      <NdiSigningProvider useNewDedocoSigning={useNewDedocoSigning}>
        <WrappedComponent {...props} />
      </NdiSigningProvider>
    );
  };

export { NdiSigningProvider, useNdiSigningContext, withNdiSigning };
