import { isInitials, isSignature, isStamp } from '../../../../utils/signatureWidget';
import { INITIAL_TYPE_LIST, SIGNATURE_TYPE_LIST, STAMP_TYPE_LIST } from './constants';

export const getSigningConfigs = (currentFieldId) => {
  let fieldId = currentFieldId;
  if (Array.isArray(currentFieldId)) {
    fieldId = currentFieldId[0];
  }

  if (isSignature(fieldId)) {
    return {
      types: SIGNATURE_TYPE_LIST,
      saveSignatureOption: true,
    };
  }
  if (isInitials(fieldId)) {
    return {
      types: INITIAL_TYPE_LIST,
      saveSignatureOption: false,
    };
  }
  if (isStamp(fieldId)) {
    return {
      types: STAMP_TYPE_LIST,
      saveSignatureOption: false,
    };
  }

  return { types: [], saveSignatureOption: false };
};
