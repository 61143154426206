import { styled } from '@mui/material';

const PREFIX = 'FormSidebar';

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  fieldSummaryContainer: `${PREFIX}-field-summary-container`,
  actionButtons: `${PREFIX}-action-buttons`,
  submitFormButton: `${PREFIX}-submit-button`,
  notice: `${PREFIX}-notice`,
  signWithSingPassButton: `${PREFIX}-sign-with-singpass-button`,
  singPassSigningNotice: `${PREFIX}-singpass-signing-notice`,
  singPassSigningNoticeIcon: `${PREFIX}-singpass-signing-notice-icon`,
  singPassSigningNoticeMessage: `${PREFIX}-singpass-signing-notice-message`,
};

export const Root = styled('div')(({ isCompletion, theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.main,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 2, 4, 4),
  padding: theme.spacing(3, 4),
  position: 'relative',
  width: isCompletion ? '50%' : '346px',
  overflow: 'scroll',
  zIndex: 9998,

  [`& .${classes.wrapper}`]: {
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
  },

  [`& .${classes.fieldSummaryContainer}`]: {
    flexGrow: 1,
    overflow: 'scroll',
  },

  [`& .${classes.notice}`]: {
    [`& .${classes.singPassSigningNotice}`]: {
      marginTop: theme.spacing(5),
      borderRadius: '8px',
      border: `1px solid ${theme.palette.info.main}`,
      backgroundColor: theme.palette.info.extraLight,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      padding: theme.spacing(3),
    },

    [`& .${classes.singPassSigningNoticeIcon}`]: {
      display: 'block',
      margin: theme.spacing(2, 2, 0, 0),
      height: '22px',
      width: 'auto',
    },

    [`& .${classes.singPassSigningNoticeMessage}`]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },

  [`& .${classes.actionButtons}`]: {
    marginTop: theme.spacing(5),

    [`& .${classes.submitFormButton}`]: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.contrast.primary,
      borderRadius: 8,
    },

    [`& .${classes.signWithSingPassButton}`]: {
      width: '100%',
      backgroundColor: '#E14746',
      color: 'white',
      borderRadius: 8,
    },
  },
}));
