import { ButtonGroup, MenuList, Paper, styled } from '@mui/material';

const PREFIX = 'DownloadButton';

export const classes = {
  buttonGroup: `${PREFIX}-button-group`,
  rightButton: `${PREFIX}-right-button`,
  leftButton: `${PREFIX}-left-button`,
  downloadOption: `${PREFIX}-download-option`,
  optionContent: `${PREFIX}-option-content`,
  optionIcon: `${PREFIX}-option-icon`,
};

export const DownloadButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  gap: theme.spacing(0.25),
  boxShadow: 'none',
  lineHeight: '18px',
  display: 'flex',
  [`& .${classes.leftButton}`]: {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
  },
  [`& .${classes.rightButton}`]: {
    borderRadius: '8px',
    width: '40px',
    height: '100%',
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: '2px 2px 12px rgba(0, 0, 0, 0.08)',
  background: theme.palette.background.main,
  borderRadius: '8px',
}));

export const DownloadOptionList = styled(MenuList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  [`& .MuiMenuItem-root:hover`]: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.supporting.light,
  },
  [`& .${classes.downloadOption}`]: {
    padding: theme.spacing(3, 2),
    [`&:hover`]: {
      borderRadius: '8px',
    },
  },
  [`& .${classes.optionContent}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    gap: theme.spacing(2),
  },
  [`& .${classes.optionIcon}`]: {
    height: '24px',
    width: '24px',
  },
  [`& .MuiTypography-root`]: {
    fontWeight: 500,
  },
}));
