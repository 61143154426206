import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import signIcon from '../../../assets/sign.svg';
import { VideoMeetingType } from '../../../constant/videoMeeting';
import {
  NdiSigningProvider,
  useNdiSigningContext,
} from '../../../contexts/ndiSigningContexts/useNdiSigningContext';
import { PdfTronProvider, usePdfTronContext } from '../../../contexts/usePdfTronContext';
import { useWebexContext } from '../../../contexts/useWebexContext';
import { DedocoSigningProvider, useDedocoSigning } from '../../../hooks/useDedocoSigning';
import { featureNameMap, useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { dedocoTheme } from '../../../themes/dedocoTheme';
import { isRequestError } from '../../../utils/common';
import {
  TWO_FA_METHOD_EMAIL,
  TWO_FA_METHOD_SINGPASS,
  TWO_FA_METHOD_SINGPASS_SKIP_NRIC_CHECK,
  TWO_FA_METHOD_SMS,
} from '../../../utils/GlobalConstant';

import { getOtpTarget } from '../../../utils/otp';
import {
  checkIfUserCompletedFillingObjects,
  getObjectedFilledChecklist,
  getPdfDataUrlsToDownload,
} from '../../../utils/pdftron';
import useUnsavedChangesWarning from '../../../utils/unsavedChanges';
import Main from '../../Signingrequest/Main';
import BlurredPdf from '../BlurredPdf';
import HeaderDownloadButton from '../Buttons/HeaderDownloadButton';
import DedocoLayout from '../DedocoLayout';
import PdfWebViewer from '../PdfWebViewer';
import Sidebar from '../Sidebar';
import DedocoHashVerifyBox from '../Sidebar/DedocoHashVerifyBox';
import InfoSidebar from '../Sidebar/InfoSidebar';
import OTPCheck from '../Sidebar/OTPCheck';
import { SingPassLogin } from '../Sidebar/SingPassLogin';
import TermsAndCondition from '../Sidebar/TermsAndCondition';
import { CustomSnackbar } from '../UI/Snackbar';
import VideoSigningRequest from '../VideoSigningRequest';
import { Divider, useMediaQuery } from '@mui/material';
import useRecipientRole from '../../../hooks/useRecipientRole';
import { Root } from './SigningRequest.style';
import { LoadingOverlay } from '../UI/LoadingOverlay';
import { Stack } from '@mui/material';
import SignerInfo from '../../SignerInfo';

export function SigningRequest() {
  const isDVideoSignEnable = useFeatureFlag(featureNameMap.dVideoSign);

  const { t } = useTranslation();
  // state
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: '',
    message: '',
    duration: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [noOfRemainingFields, setNoOfRemainingFields] = useState(0);
  const [fileShareKeyForDownload, setFileShareKeyForDownload] = useState('');
  const [hasModified, setHasModified] = useState(false);
  const [activatePrompt, deactivatePrompt] = useUnsavedChangesWarning().slice(1);
  const { currentUser } = useRecipientRole();

  const { instance } = usePdfTronContext();
  const {
    currentParticipant,
    documentToSign,
    approveDocument,
    rejectDocument,
    signerInputs,
    signDocument,
    signDocumentDigitally,
    signDocumentWithNdi,
    isDigitalSigning,
    fileShareKey,
    loadDocument,
  } = useDedocoSigning();
  const {
    isMeetingHost: isWebexMeetingHost,
    isWebexSigningSessionComplete,
    addEventListener,
  } = useWebexContext();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setFileShareKeyForDownload(fileShareKey);
  }, [fileShareKey]);

  // Register Webex event listeners
  useEffect(() => {
    addEventListener('completed-session', (dssKey) => {
      setFileShareKeyForDownload(dssKey);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      isApprovingDocument,
      isSubmittingDocument,
      documentUpdatedApprovement,
      submitDocumentCompleted,
    } = documentToSign;

    if (hasModified || isApprovingDocument || isSubmittingDocument) {
      activatePrompt();
    }

    if (!hasModified || submitDocumentCompleted || documentUpdatedApprovement) {
      deactivatePrompt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasModified,
    activatePrompt,
    deactivatePrompt,
    documentToSign.isApprovingDocument,
    documentToSign.isSubmittingDocument,
    documentToSign.documentUpdatedApprovement,
    documentToSign.submitDocumentCompleted,
  ]);

  //ndi signing
  const {
    initiateNdiSigning,
    snackbarDigitalState,
    setSnackbarDigitalState,
    setNdiSigningCompletedCallback,
    setAllowNdiSigning,
  } = useNdiSigningContext();
  const isUsingNdi = currentParticipant?.esignatures?.length === 0;

  useEffect(() => {
    isUsingNdi &&
      setNdiSigningCompletedCallback(() => {
        return ({ dssKey }) => {
          setFileShareKeyForDownload(dssKey);
        };
      });
  }, [signDocumentWithNdi, setNdiSigningCompletedCallback, isUsingNdi]);

  useEffect(() => {
    if (snackbarDigitalState.open) {
      setSnackbarState(snackbarDigitalState);
    } else {
      setSnackbarState({
        ...snackbarDigitalState,
        open: false,
        duration: null,
      });
    }
  }, [snackbarDigitalState.open, snackbarDigitalState]);

  const handleSignDoc = async () => {
    if (isUsingNdi) {
      initiateNdiSigning();
      return;
    }

    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });

    setDisableSubmitButton(true);

    try {
      let shareKey;

      if (isDigitalSigning) {
        ({ shareKey } = await signDocumentDigitally(instance));
      } else {
        ({ shareKey } = await signDocument(instance));
      }
      setFileShareKeyForDownload(shareKey);
      setSnackbarState({
        ...snackbarState,
        open: false,
      });
    } catch (err) {
      const maybeErrorMessage = typeof err?.message === 'string' ? err.message : undefined;
      const maybeStringError = typeof err === 'string' ? err : undefined;
      const message = maybeErrorMessage ?? maybeStringError ?? t('SigningError');

      setSnackbarState({
        open: true,
        severity: 'error',
        message,
        duration: 3000,
        onClose: (_, reason) => {
          if (reason === 'timeout') {
            setSnackbarState({
              open: false,
              duration: null,
            });
          }
        },
      });
    } finally {
      setDisableSubmitButton(false);
    }
  };

  const handleApproveDoc = async () => {
    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });
    approveDocument(instance);
  };

  const handleRejectDoc = async (reason) => {
    setSnackbarState({
      severity: 'info',
      open: true,
      message: t('SubmittingDocumentText'),
    });
    rejectDocument(reason);
  };

  useEffect(() => {
    if (documentToSign?.isDocumentApproved || documentToSign?.isDocumentRejected) {
      setSnackbarState({
        ...snackbarState,
        open: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToSign?.isDocumentApproved, documentToSign?.isDocumentRejected]);

  const handleDownloadPdf = useCallback(
    async (downloadOptionDetails) => {
      const saveFile = downloadOptionDetails.downloadFunction;
      const pdfDataUrls = await getPdfDataUrlsToDownload(
        documentToSign,
        instance,
        fileShareKeyForDownload,
        isWebexSigningSessionComplete,
        isDigitalSigning,
      );

      const fileInfo = {
        data: pdfDataUrls,
        filename: documentToSign.fileName,
      };

      saveFile(fileInfo);
    },
    [
      documentToSign,
      instance,
      fileShareKeyForDownload,
      isWebexSigningSessionComplete,
      isDigitalSigning,
    ],
  );

  useEffect(() => {
    const objectsFilledChecklist = getObjectedFilledChecklist(signerInputs);
    const { documentHasCompleted, remainingFields } = checkIfUserCompletedFillingObjects(
      objectsFilledChecklist,
      currentParticipant,
    );
    setNoOfRemainingFields(remainingFields);
    setAllowNdiSigning(documentHasCompleted);
    setDisableSubmitButton(!documentHasCompleted);
    // eslint-disable-next-line
  }, [signerInputs, currentParticipant]);

  const shouldRenderTnC = useCallback(() => {
    return !!(!documentToSign?.tncAccepted && !isWebexMeetingHost);
  }, [documentToSign?.tncAccepted, isWebexMeetingHost]);

  const shouldRenderOTP = useCallback(() => {
    const data = documentToSign?.data;
    return (
      data?.require_authentication &&
      (data?.verify_method === TWO_FA_METHOD_SMS || data?.verify_method === TWO_FA_METHOD_EMAIL)
    );
  }, [documentToSign?.data]);

  const shouldRenderSingPassLogin = useCallback(() => {
    const data = documentToSign?.data;
    return (
      data?.require_authentication &&
      (data?.verify_method === TWO_FA_METHOD_SINGPASS ||
        data?.verify_method === TWO_FA_METHOD_SINGPASS_SKIP_NRIC_CHECK)
    );
  }, [documentToSign?.data]);

  const shouldRenderExpiryPage = useCallback(() => {
    return isRequestError(documentToSign?.errorCode);
  }, [documentToSign?.errorCode]);

  const shouldRenderDHC = useCallback(() => {
    return (
      documentToSign?.document?.document_share_method === 'manual' && !documentToSign?.isDHCVerified
    );
  }, [documentToSign?.isDHCVerified, documentToSign?.document?.document_share_method]);

  const renderContent = () => {
    if (!isMdUp) {
      if (shouldRenderExpiryPage()) {
        return (
          <InfoSidebar
            title={t('DocumentExpiredTitle')}
            body={t('ExpiredDocumentBody')}
            showDownloadButton={false}
          />
        );
      } else if (
        !documentToSign.isValidDocHash &&
        !shouldRenderOTP() &&
        !shouldRenderSingPassLogin()
      ) {
        return (
          <InfoSidebar
            title={t('DocumentNotUpdatedTitle')}
            body={t('DocumentNotUpdatedBody')}
            showDownloadButton={false}
          />
        );
      } else if (shouldRenderDHC()) {
        return <DedocoHashVerifyBox />;
      } else if (shouldRenderSingPassLogin()) {
        return <SingPassLogin />;
      } else if (shouldRenderOTP()) {
        return <OTPCheck otpTarget={getOtpTarget(documentToSign)} />;
      } else if (shouldRenderTnC()) {
        return <TermsAndCondition />;
      }
    }
    if (
      documentToSign.isValidDocHash &&
      (isWebexMeetingHost ||
        documentToSign.tncAccepted ||
        (documentToSign.document?.document_share_method === 'manual' &&
          documentToSign.isDHCVerified))
    ) {
      return (
        <PdfWebViewer
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          handleDownloadPdf={handleDownloadPdf}
          setHasModified={setHasModified}
          noOfRemainingFields={noOfRemainingFields}
        />
      );
    }
    return <BlurredPdf />;
  };

  const shouldShowLoadingOverlay =
    !documentToSign.pdfBase64String && // Make sure there is document to show before removing the overlay
    !shouldRenderOTP() &&
    !shouldRenderSingPassLogin() &&
    !shouldRenderExpiryPage();

  const isVideoSigning =
    documentToSign?.data?.is_videosign ||
    documentToSign?.data?.meeting?.meetingType === VideoMeetingType.TWILIO;

  useEffect(() => {
    // Things are quite tangled up now. Videosign will have its own loadDocument.
    if (
      documentToSign.isloaded &&
      !shouldRenderExpiryPage() &&
      !shouldRenderOTP() &&
      !shouldRenderSingPassLogin() &&
      !documentToSign.pdfBase64String &&
      !isVideoSigning
    ) {
      loadDocument();
    }
  }, [
    documentToSign.isloaded,
    documentToSign.pdfBase64String,
    shouldRenderOTP,
    shouldRenderSingPassLogin,
    shouldRenderExpiryPage,
    isVideoSigning,
    loadDocument,
  ]);

  const headerComponent = useMemo(() => {
    return (
      <Stack
        direction="row"
        spacing={4}
        sx={{
          display: 'flex',
        }}
        alignItems={'center'}
      >
        {currentUser?.is_downloadable && (
          <HeaderDownloadButton handleDownloadPdf={handleDownloadPdf} />
        )}
        {currentUser && !documentToSign?.submitDocumentCompleted && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <SignerInfo
              name={documentToSign?.data?.recipientName}
              email={documentToSign?.data?.recipientEmail}
            />
          </>
        )}
      </Stack>
    );
  }, [currentUser, documentToSign, handleDownloadPdf]);

  if (isVideoSigning) return isDVideoSignEnable ? <VideoSigningRequest /> : <Main />;

  return (
    <DedocoLayout
      headerComponent={headerComponent}
      handleDownloadPdf={handleDownloadPdf}
      actionIcon={signIcon}
      actionIconAlt={'sign'}
    >
      <Root>
        {shouldShowLoadingOverlay && <LoadingOverlay />}
        <CustomSnackbar
          {...snackbarState}
          onClose={(_, reason) => {
            if (snackbarDigitalState.open && reason === 'timeout') {
              setSnackbarDigitalState({
                ...snackbarState,
                open: false,
                duration: null,
              });
            }
            if (reason === 'clickaway') return;
            setSnackbarState({
              ...snackbarState,
              open: true,
            });
          }}
        />
        {renderContent()}
        <Sidebar
          showOTP={shouldRenderOTP()}
          showSingPassLogin={shouldRenderSingPassLogin()}
          showExpiration={shouldRenderExpiryPage()}
          showTnC={shouldRenderTnC()}
          showDHC={shouldRenderDHC()}
          handleSignDoc={handleSignDoc}
          handleApproveDoc={handleApproveDoc}
          handleRejectDoc={handleRejectDoc}
          disableSubmitButton={disableSubmitButton}
          noOfRemainingFields={noOfRemainingFields}
          handleDownloadPdf={handleDownloadPdf}
        />
      </Root>
    </DedocoLayout>
  );
}

function SigningRequestWithProviders(props) {
  return (
    <ThemeProvider theme={dedocoTheme}>
      <DedocoSigningProvider>
        <PdfTronProvider>
          <NdiSigningProvider>
            <SigningRequest {...props} />
          </NdiSigningProvider>
        </PdfTronProvider>
      </DedocoSigningProvider>
    </ThemeProvider>
  );
}

export default SigningRequestWithProviders;
