import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '../../../../../utils/domUtil';
import axios from 'axios';

import { base64ToBytes } from '../../../../../utils/base64';
import { process_env } from '../../../../../utils/envData';

// https://developers.google.com/drive/picker/reference#feature
// Reference: https://github.com/Jose-cd/React-google-drive-picker and https://github.com/sdoomz/react-google-picker
const GoogleDriverSaver = ({
  children,
  onClick,
  scope = ['https://www.googleapis.com/auth/drive.file'],
  viewId = 'FOLDERS',
  query,
  origin,
  allowMultiselect = false,
  supportDrives = false,
  mimeTypeFilter,
  locale = 'en',
  includeFolderInView = true,
  allowFolderSelect = true,
  disabled = false,
}) => {
  const GOOGLE_SDK_URL = '/scripts/gapi.js';
  const GOOGLE_SIGN_IN_SCRIPT_URL = '/scripts/gsi.js';
  const scriptLoadingStarted = useRef(false);
  const gapiAccessToken = useRef('');
  const CLIENT_ID = process_env('REACT_APP_GOOGLE_DRIVE_CLIENT_ID');
  const DEV_KEY = process_env('REACT_APP_GOOGLE_DRIVE_KEY');

  useEffect(() => {
    if (isGoogleApiReady()) {
      loadApis();
    } else if (!scriptLoadingStarted.current) {
      scriptLoadingStarted.current = true;
      loadScript(GOOGLE_SDK_URL, loadApis);
    }
  });

  const isGoogleApiReady = () => {
    return !!window.gapi;
  };

  const isGooglePickerReady = () => {
    return !!(window.google && window.google.picker);
  };

  const isGoogleSignInReady = () => {
    return !!(window.google && window.google.accounts);
  };

  const loadApis = () => {
    window.gapi.load('picker');

    if (!isGoogleSignInReady()) {
      loadScript(GOOGLE_SIGN_IN_SCRIPT_URL);
    }
  };

  const performAuth = (callback) => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: scope.join(' '),
      callback: callback,
    });
    client.requestAccessToken();
  };

  const createPicker = (accessToken) => {
    const pickerSdk = window.google.picker;
    const view = new pickerSdk.DocsView(pickerSdk.ViewId[viewId]);

    if (!view) {
      throw new Error("Can't find view by viewId");
    }

    if (mimeTypeFilter) {
      view.setMimeTypes(mimeTypeFilter.join(','));
    }

    if (query) {
      view.setQuery(query);
    }

    view.setIncludeFolders(includeFolderInView);
    view.setSelectFolderEnabled(allowFolderSelect);

    const picker = new pickerSdk.PickerBuilder()
      .addView(view)
      .setOAuthToken(accessToken)
      .setDeveloperKey(DEV_KEY)
      .setLocale(locale)
      .setCallback(handlePickerAction);

    if (allowMultiselect) {
      picker.enableFeature(pickerSdk.Feature.MULTISELECT_ENABLED);
    }

    if (origin) {
      picker.setOrigin(origin);
    }

    if (supportDrives) {
      picker.enableFeature(pickerSdk.Feature.SUPPORT_DRIVES);
    }

    picker.build().setVisible(true);

    //Make sure that picker is not covered by any other elements.
    const pickerElements = document.getElementsByClassName('picker-dialog');

    for (let el of pickerElements) {
      //Note that el is a DOM object, therefore it is passed by reference and be updated this way.
      el.style.zIndex = '2000';
    }
  };

  const handlePickerClick = () => {
    if (!isGoogleApiReady() || !isGooglePickerReady() || !isGoogleSignInReady() || disabled) {
      return null;
    }

    const accessToken = gapiAccessToken.current;

    if (accessToken) {
      createPicker(accessToken);
    } else {
      performAuth((response) => {
        if (response.access_token) {
          const token = response.access_token;
          gapiAccessToken.current = token;
          createPicker(token);
        } else {
          console.log('Google authentication failed.');
        }
      });
    }
  };

  const handlePickerAction = (pickerData) => {
    if (pickerData.action === 'picked') {
      const saveFileConfig = {
        folderId: pickerData.docs[0].id,
      };

      const saveToGoogleDrivePartial = (fileInfo, successCallback, errorCallback) => {
        saveToGoogleDrive(saveFileConfig, fileInfo, successCallback, errorCallback);
      };

      onClick(saveToGoogleDrivePartial);
    }
  };

  const saveToGoogleDrive = (saveFileConfig, fileInfo, successCallback, errorCallback) => {
    const url = 'https://www.googleapis.com/upload/drive/v3/files';
    const data = new FormData();
    const accessToken = gapiAccessToken.current;

    const fileData = fileInfo.data;
    const mimeType = fileData.substring(fileData.indexOf(':') + 1, fileData.indexOf(';'));
    const fileBase64 = fileData.substring(fileData.indexOf(',') + 1);
    const fileBlob = new Blob([base64ToBytes(fileBase64)], { type: mimeType });

    const metadata = {
      name: fileInfo.filename,
      parents: [saveFileConfig.folderId],
      mimeType: mimeType,
    };

    data.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    data.append('file', fileBlob);

    const options = {
      url: url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/related',
      },
      params: {
        uploadType: 'multipart',
      },
      data: data,
    };

    axios(options).then((response) => {
      if (response.status === 200) {
        successCallback?.();
      } else {
        errorCallback?.();
      }
    });
  };

  return <div onClick={handlePickerClick}>{children}</div>;
};

GoogleDriverSaver.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  scope: PropTypes.arrayOf(PropTypes.string),
  viewId: PropTypes.string,
  query: PropTypes.string,
  origin: PropTypes.string,
  allowMultiselect: PropTypes.bool,
  supportDrives: PropTypes.bool,
  mimeTypeFilter: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  includeFolderInView: PropTypes.bool,
  allowFolderSelect: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default GoogleDriverSaver;
