import { styled } from '@mui/material';

const PREFIX = 'OTPCheck';

export const classes = {
  root: `${PREFIX}-root`,
  otpInput: `${PREFIX}-input`,
  otpCheckTermsConditionContainer: `${PREFIX}-terms-condition-container`,
  otpCheckTermsConditionContent: `${PREFIX}-terms-condition`,
  resendButton: `${PREFIX}-resend-button`,
  timeCountDown: `${PREFIX}-time-count-down`,
  childContainer: `${PREFIX}-child-container`,
  disableResend: `${PREFIX}-disable-resend`,
  invalidOtp: `${PREFIX}-invalid-otp`,
};

export const OtpContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.stroke.light,
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4),
  },
}));

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.stroke.light,
  padding: theme.spacing(4),
  margin: theme.spacing(4, 4, -12, 4),
  borderRadius: '5px',
  display: 'grid',

  [`& .${classes.otpInput}`]: {
    width: '1.5rem !important',
    height: '3rem',
    borderColor: theme.palette.lightGrey.border,
    backgroundColor: theme.palette.background.main,
    borderStyle: 'solid',
    borderRadius: '5px',
  },

  [`& .${classes.otpCheckTermsConditionContainer}`]: {
    backgroundColor: theme.palette.supporting.main,
    display: 'flex',
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    border: 'solid 2px',
    borderColor: theme.palette.lightGrey.border,
    padding: theme.spacing(3),
  },

  [`& .${classes.otpCheckTermsConditionContent}`]: {
    backgroundColor: theme.palette.supporting.main,
    flexGrow: 1,
    width: 0,
  },

  [`& .${classes.childContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.resendButton}`]: {
    marginBlock: theme.spacing(4),
    fontWeight: 'bold',
    color: theme.palette.darkGrey.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.lightGrey.dark,
    },
  },

  [`& .${classes.disableResend}`]: {
    cursor: 'not-allowed',
    color: theme.palette.lightGrey.dark,
    marginBlock: theme.spacing(4),
  },

  [`& .${classes.timeCountDown}`]: {
    marginBlock: theme.spacing(4),
    fontWeight: 'bold',
    color: theme.palette.darkGrey.main,
    cursor: 'pointer',
    marginLeft: 'auto',
    '&:hover': {
      color: theme.palette.lightGrey.dark,
    },
  },

  [`& .${classes.invalidOtp}`]: {
    marginBlock: theme.spacing(4),
    color: theme.palette.error.main,
    marginLeft: 'auto',
    paddingLeft: theme.spacing(6),
  },
}));
