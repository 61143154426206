export function getInitals(FullName) {
  var Matches = FullName.split(' ');

  let Acronym = '';
  if (Matches.length === 1) {
    Acronym = Matches[0].charAt(0);
  } else {
    Acronym = Matches[0].charAt(0) + Matches[1].charAt(0);
  }
  return Acronym.toUpperCase();
}
