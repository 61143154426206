import ReactMarkdown from 'react-markdown';
import { RecipientRoles } from '../../hooks/useRecipientRole';
import {
  recordApproverTermsAndConditionsConsent,
  recordObserverTermsAndConditionsConsent,
  recordPublicApproverTermsAndConditionsConsent,
  recordPublicObserverTermsAndConditionsConsent,
  recordPublicSignerTermsAndConditionsConsent,
  recordSignerTermsAndConditionsConsent,
} from '../../lib/api';

export const submitTnCAgreement = async (
  businessProcessId,
  recipientId,
  participantRole,
  isPublic = false,
) => {
  let signerRecordFunc = recordSignerTermsAndConditionsConsent;
  let observerRecordFunc = recordObserverTermsAndConditionsConsent;
  let approverRecordFunc = recordApproverTermsAndConditionsConsent;

  if (isPublic) {
    signerRecordFunc = recordPublicSignerTermsAndConditionsConsent;
    observerRecordFunc = recordPublicObserverTermsAndConditionsConsent;
    approverRecordFunc = recordPublicApproverTermsAndConditionsConsent;
  }

  let success = false;
  if (participantRole === RecipientRoles.SIGNER) {
    success = await signerRecordFunc(businessProcessId, recipientId);
  } else if (participantRole === RecipientRoles.OBSERVER) {
    success = await observerRecordFunc(businessProcessId, recipientId);
  } else if (participantRole === RecipientRoles.APPROVER) {
    success = await approverRecordFunc(businessProcessId, recipientId);
  }

  return success;
};

const LinkRenderer = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

const DivRenderer = ({ children }) => <div>{children}</div>;

export const TnCMarkdownParser = ({ children, ...props }) => (
  // Replace <p/> with <div/> to remove unwanted margins
  <ReactMarkdown components={{ a: LinkRenderer, p: DivRenderer }} {...props}>
    {children}
  </ReactMarkdown>
);
