import { WebViewerRoot } from '../PdfWebViewer/PdfWebViewer.style';

function BlurredPdf() {
  return (
    <WebViewerRoot>
      <img style={{ height: '100%', width: '100%' }} src="/pdf-blurred.png" alt="pdf-blurred" />
    </WebViewerRoot>
  );
}

export default BlurredPdf;
