import { styled } from '@mui/material';

const PREFIX = 'InvalidForm';

export const classes = {
  messageContainer: `${PREFIX}-messageContainer`,
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',

  [`& .${classes.messageContainer}`]: {
    margin: theme.spacing(2, 0, 6, 0),
  },
}));
