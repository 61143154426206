import { useEffect, useState } from 'react';

function useCountDown() {
  const [countDownTime, setCountDownTime] = useState(0);

  useEffect(() => {
    let otpTimeout;
    if (countDownTime > 0) {
      otpTimeout = setTimeout(() => setCountDownTime(countDownTime - 1), 1000);
    }
    return () => {
      clearTimeout(otpTimeout);
    };
  }, [countDownTime]);

  return { countDownTime, setCountDownTime };
}

export default useCountDown;
