import React from 'react';
import './ndiSigningFunctions.css';
import 'reactjs-popup/dist/index.css';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//begin signing confirmation message
//TODO:refresh
export const singPassSignConfirmation = (
  <div>
    <div
      style={{
        fontSize: '30px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        color: '#504E56',
        borderBottom: 'none',
        textAlign: 'center',
      }}
      className="header"
    >
      Document Ready for Signing{' '}
    </div>
    <div style={{ textAlign: 'center' }} className="resultLogo">
      <CheckCircleIcon sx={{ width: '100px', height: '100px', color: '#66FF91' }} />
    </div>

    <div
      style={{
        textAlign: 'center',
        padding: '0',
        fontSize: '15px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        color: '#504E56',
      }}
      className="content"
    >
      {' '}
      <br />
      Document Information Saved
      <br />
      Please click on the button below to begin signing with SingPass
    </div>
  </div>
);

export const anotherSignerSigningErrorMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div
      style={{
        color: '#504E56',
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 'bolder',
        paddingTop: '16px',
      }}
    >
      Signing Service Unavailable
    </div>
    <div
      style={{
        color: '#504E56',
        fontFamily: 'Lato',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Another signer is signing this document at the moment. Please try again later.
    </div>
  </div>
);

export const signingErrorMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div
      style={{
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: '600',
        paddingTop: '16px',
      }}
    >
      Something Went Wrong
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Please use other methods to authorise your transaction or try again later.
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Contact support if this persists.
    </div>
  </div>
);

export const nricMismatchErrorMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div
      style={{
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: '600',
        paddingTop: '16px',
      }}
    >
      NRIC/FIN Mismatch
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      This Singpass account does not match the intended signer&#39;s NRIC/FIN.
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Make sure you have used your own Singpass, or contact the project creator for clarification.
    </div>
  </div>
);

export const singPassSignConfirmationLoading = (
  <div>
    <div
      style={{
        fontSize: '35px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        color: '#504E56',
        borderBottom: 'none',
        textAlign: 'center',
      }}
      className="header"
    >
      Preparing Document{' '}
    </div>
    <div style={{ textAlign: 'center', padding: '0', marginTop: '55px' }} className="content">
      <CircularProgress sx={{ color: 'rgb(144, 202, 249)' }} />
    </div>

    <div
      style={{
        textAlign: 'center',
        padding: '0',
        fontSize: '20px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        color: '#504E56',
      }}
      className="content"
    >
      {' '}
      <br />
      Saving Document Information
      <br />
      Please wait a moment...
    </div>
  </div>
);

export const requestExpiredMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div className="expiredHeader">Request has expired</div>
    <div className="expiredMessage">
      You took too long to authorise
      <br />
      the transaction. Please try again.
    </div>
  </div>
);

//transitioned message
export const transitionMessage = (
  <div id="fade-in" style={{ paddingTop: '24px' }}>
    <div className="transitionMessage">Authorise transaction on</div>
    <div className="transitionMessage">Singpass app </div>
  </div>
);

export const showNDISpinner = (
  <div
    style={{
      backgroundColor: 'white',
      width: '393px',
      textAlign: 'center',
      height: '524px',
    }}
  >
    <img
      alt="spinner"
      style={{ position: 'absolute', top: '355px', left: '297px' }}
      src="/Spinner.svg"
    />
  </div>
);

export const iTextErrorMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div
      style={{
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: '600',
        paddingTop: '16px',
      }}
    >
      Something Went Wrong
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      The PDF file selected is not supported for digital signing
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Please upload a different PDF file during project creation.
    </div>
  </div>
);

export const fileShareErrorMessage = (
  <div
    style={{
      width: '256px',
      position: 'absolute',
      top: '275px',
      left: '202px',
    }}
  >
    <img alt="alert" style={{ height: '60px', width: '60px' }} src="/Alert icon.svg" />
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '600',
        fontSize: '20px',
        paddingTop: '16px',
      }}
    >
      Something Went Wrong
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Dedoco file share has failed to upload your file, please refresh the page and try again.
    </div>
    <div
      style={{
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        paddingTop: '16px',
      }}
    >
      Contact support if this persists.
    </div>
  </div>
);
