import { CircularProgress } from '@mui/material';
import React from 'react';

export default function Previews() {
  return (
    <div style={{ maxWidth: 240, width: 240, height: '100%' }}>
      <CircularProgress sx={{ marginTop: '50%' }} />
    </div>
  );
}
