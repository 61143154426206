import { styled } from '@mui/material';

export const WebViewerRoot = styled('div')(({ theme }) => ({
  height: '100%',
  flex: 1,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
}));

export const WebViewerWrapper = styled('div')(({ theme, display }) => ({
  height: 'calc(100% - 50px)',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: `calc(100% - ${display ? '40px' : '108px'})`,
  },
}));

export const ActionButtonWrapper = styled('div')(({ theme, display }) => ({
  display: display ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),

  '& .MuiButton-root': {
    padding: theme.spacing(3.25),
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
