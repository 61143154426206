import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SingleStatusDialog from '../Dialog/SingleStatusDialog';

export function JoinMeetingErrorPage() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        background: 'white',
        width: '100vw',
        height: '100vh',
      }}
    >
      <SingleStatusDialog
        bringToFront={true}
        open={true}
        content={t('VideoSignUnableToJoinErrorMessage')}
        title={t('VideoSignUnableToJoinError')}
      />
    </Stack>
  );
}
