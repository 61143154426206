import Axios from 'axios';

import { process_env } from '../../utils/envData';

const dssApiUrl = process_env('REACT_APP_DSS_API') || 'http://localhost:3002';
const dssApi = Axios.create({ baseURL: dssApiUrl, timeout: 50000 });

dssApi.interceptors.request.use(addAuthToken);

const dedocoApiUrl =
  `${process_env('REACT_APP_DEDOCO_API')}/api/v1` || 'http://localhost:3001/api/v1';
const dedocoAxios = Axios.create({
  baseURL: dedocoApiUrl,
  timeout: 50000,
});
dedocoAxios.interceptors.request.use(addAuthToken);

const itextApiUrl = process_env('REACT_APP_ITEXT_API') || 'http://localhost:3003';
const itextAxios = Axios.create({
  baseURL: itextApiUrl,
  timeout: 50000,
});
itextAxios.interceptors.request.use(addAuthToken);

function addAuthToken(config) {
  const sessionToken = sessionStorage.getItem('sessionToken');
  if (sessionToken) {
    config.headers = {
      Authorization: `Bearer ${sessionToken}`,
      version: 'v2',
    };
  }
  return config;
}

export { dedocoAxios, itextAxios, dssApi };
