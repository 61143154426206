import { CheckCircle, Info } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { UploadAttachmentIcon } from '../../Icons/UploadAttachmentIcon';
import { useCallback, useRef, useState } from 'react';
import CircularProgress from '../../UI/CircularProgress';
import CloseRounded from '@mui/icons-material/CloseRounded';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFileAttachment,
  setAttachmentError,
  uploadFileAttachment,
} from '../../../../redux/slices/attachmentSlice';
import { useDedocoSigning } from '../../../../hooks/useDedocoSigning';
import { formatBytes } from '../../../../utils/formatBytes';

const SignerAttachments = ({ variant }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { businessProcess, currentParticipant, documentToSign } = useDedocoSigning();
  const { attachments, instructions } = useSelector((state) => state.attachment);

  const boxStyle = {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    width: '100%',
  };
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const fileInputRefs = useRef({});

  const handleFileUpload = async (attachment, file) => {
    await dispatch(
      uploadFileAttachment({
        bpId: businessProcess.id,
        signerId: currentParticipant.id ?? currentParticipant.approverId,
        attachmentId: attachment.id,
        fileBlob: file,
        isOpenApiV2: documentToSign.isOpenApiV2,
      }),
    ).unwrap();
  };

  const isFileUploaded = useCallback((attachment) => {
    return !!attachment.fileName;
  }, []);

  const handleFileDelete = (attachment) => {
    dispatch(
      deleteFileAttachment({
        bpId: businessProcess.id,
        signerId: currentParticipant.id ?? currentParticipant.approverId,
        attachmentId: attachment.id,
        isOpenApiV2: documentToSign.isOpenApiV2,
      }),
    );
  };

  return (
    <Stack spacing={3} alignItems={'flex-start'} textAlign={'start'}>
      {instructions && (
        <Stack
          spacing={2}
          sx={{
            ...boxStyle,
          }}
        >
          <Box sx={{ padding: 3 }}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Info sx={{ color: '#3D7BF7', fontSize: '12px' }} />
              <Typography
                sx={{ color: theme.palette.grey[500] }}
                fontSize={'12px'}
                fontWeight={500}
              >
                {t('Instructions')}
              </Typography>
            </Stack>
            <Typography variant="body2">{instructions}</Typography>
          </Box>
        </Stack>
      )}
      {attachments.length && (
        <>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            sx={{
              ...(variant === 'fullWidth' && {
                width: '100%',
                justifyContent: 'space-between',
              }),
            }}
          >
            <Typography fontSize={'16px'} fontWeight={700}>
              {t('attachmentsRequested', { noOfAttachments: attachments.length })}
            </Typography>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={openTooltip}
                  title={<Trans i18nKey="attachmentsRequirements" />}
                  arrow
                  sx={{ whiteSpace: 'pre-wrap' }}
                >
                  <IconButton
                    size="small"
                    onClick={handleTooltipOpen}
                    onMouseEnter={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                  >
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Stack>
          {attachments.map((attachment) => {
            const attachmentError = attachment.error;
            const attachmentId = attachment.id;
            const attachmentIsLoading = attachment.loading;

            return (
              <Stack
                key={`attachment-${attachmentId}`}
                sx={{
                  ...boxStyle,
                }}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  padding={3}
                >
                  <Stack spacing={3} direction={'row'} alignItems={'center'}>
                    {attachmentIsLoading && <CircularProgress size={20} />}
                    {isFileUploaded(attachment) && !attachmentIsLoading && (
                      <CheckCircle sx={{ color: '#0EA470' }} />
                    )}
                    {!isFileUploaded(attachment) && !attachmentError && <UploadAttachmentIcon />}
                    {attachmentError && <ErrorIcon sx={{ color: theme.palette.error.main }} />}
                    <Stack alignItems="start">
                      <Typography variant="body2" fontWeight={500}>
                        {attachment.name} {!attachment.required && `(${t('attachmentOptional')})`}
                      </Typography>
                      <input
                        type="file"
                        ref={(ref) => {
                          fileInputRefs.current[attachmentId] = ref;
                        }}
                        // add stop propagation here to prevent trigger onUploadButtonClick twice
                        onClick={(e) => e.stopPropagation()}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            if (file.size > 25 * 1024 * 1024) {
                              // File size exceeds 25MB, show error message
                              dispatch(
                                setAttachmentError({
                                  id: attachmentId,
                                  error: t('attachmentFileSizeExceedLimit', { limit: '25' }),
                                }),
                              );
                            } else {
                              handleFileUpload(attachment, file);
                            }
                            // Allow input to be reused for another file
                            event.target.value = null;
                          }
                        }}
                        // accept="image/*"
                        hidden
                      />
                      {isFileUploaded(attachment) && (
                        <Typography variant="body2" textOverflow={'ellipsis'}>{`${formatBytes(
                          attachment.sizeInBytes,
                        )} - ${attachment.fileName}`}</Typography>
                      )}
                      {!isFileUploaded(attachment) && !attachment.loading && (
                        <Link
                          component="button"
                          underline="hover"
                          variant="body2"
                          onClick={() => {
                            fileInputRefs.current[attachmentId]?.click();
                          }}
                        >
                          {t('attachFile')}
                        </Link>
                      )}
                    </Stack>
                  </Stack>
                  {isFileUploaded(attachment) && !attachmentIsLoading && (
                    <IconButton size="small" onClick={() => handleFileDelete(attachment)}>
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
                {attachmentError && (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    padding={3}
                    spacing={2}
                    sx={{
                      backgroundColor: theme.palette.error.extraLight,
                      color: '#B41818',
                      borderRadius: '0 0 8px 8px',
                    }}
                  >
                    <InfoOutlinedIcon fontSize="12px" />
                    <Typography fontSize={'12px'}>{attachmentError}</Typography>
                  </Stack>
                )}
              </Stack>
            );
          })}
        </>
      )}
    </Stack>
  );
};

export default SignerAttachments;
