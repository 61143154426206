import { Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import CustomTextPad from '../../CustomTextBox/CustomTextPad';
import { PEN_COLOR_PALETTE } from '../constants';
import { classes, Root } from './SigningPad.styles';
import SaveSignatureCheckbox from '../SaveSignatureCheckbox';
import { CypressIds } from '../../../../../constant/cypressIds';

const SigningPad = ({
  handleValueChange,
  type,
  textColor,
  setTextColor,
  refreshValue,
  enableSaveSignatureCheckbox,
  newDedocoSigning = false,
}) => {
  const { t } = useTranslation();

  const signPad = useRef();
  const textPad = useRef();

  const handleChangePenColor = (color) => {
    setTextColor(color);
  };
  const handleClear = () => {
    signPad.current?.clear();
    if (textPad.current) {
      textPad.current.value = '';
    }
    handleValueChange?.(null);
  };

  const handleSignatureDrawn = () => {
    handleValueChange?.({
      value: signPad.current.getCanvas().toDataURL('image/png'),
      type: 'image',
    });
  };

  const handleTextEntered = (text) => {
    handleValueChange?.({
      value: text,
      type: 'text',
    });
  };

  useEffect(() => {
    if (refreshValue) {
      handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshValue]);

  return (
    <Root container>
      <Grid item xs={12}>
        {type === 'Draw' && (
          <SignatureCanvas
            penColor={textColor}
            ref={signPad}
            onEnd={handleSignatureDrawn}
            canvasProps={{
              'data-testid': 'sidebar-signature-canvas',
              className: classes.signatureCanvas,
              role: 'signing-pad',
              'data-cypress': CypressIds.drawSignatureCanvas,
            }}
          />
        )}
        {type === 'Type' && (
          <CustomTextPad
            handleValueChange={handleTextEntered}
            penColor={textColor}
            style={{
              ['textarea']: {
                color: `${textColor} !important`,
                fontSize: '2em !important',
                lineHeight: '2em !important',
              },
            }}
            maxRows={1}
            inputRef={textPad}
          />
        )}
      </Grid>
      {enableSaveSignatureCheckbox && <SaveSignatureCheckbox newDedocoSigning={newDedocoSigning} />}
      <Grid container item>
        <Divider />
      </Grid>
      <Grid container item className={classes.colorPickerClearContainer}>
        <Grid item xs={6}>
          <Grid container className={classes.colorPickerBox}>
            {PEN_COLOR_PALETTE.map((color) => {
              return (
                <div
                  key={color}
                  className={`${textColor === color ? classes.colorPickerActive : ''} ${
                    classes.colorPickerOuter
                  }`}
                  role="color-picker"
                >
                  <div
                    onClick={() => handleChangePenColor(color)}
                    style={{ backgroundColor: color }}
                    className={classes.colorPicker}
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleClear} className={classes.clearButton}>
            <Typography variant="label_medium">{t('SigningPadClearButton')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};

export default SigningPad;
