import {
  Root,
  Header,
  Content,
  Actions,
  SignButton,
  CloseButton,
  CloseButtonIcon,
} from './NdiSigningConfirmationModal.styles';
import { useTranslation } from 'react-i18next';
import SignWithSingpassButton from '../../../../assets/sign-with-singpass-button.svg';

const NdiSigningConfirmationModal = ({ open, onConfirm, onClose, disableButton }) => {
  const { t } = useTranslation();
  const onClickProp = !disableButton ? { onClick: onConfirm } : {};

  return (
    <Root open={open}>
      <CloseButton onClick={onClose}>
        <CloseButtonIcon />
      </CloseButton>
      <Header>{t('SignWithSingPassConfirmationHeader')}</Header>
      <Content>{t('SignWithSingPassConfirmationMessage')}</Content>
      <Actions>
        <SignButton src={SignWithSingpassButton} {...onClickProp} />
      </Actions>
    </Root>
  );
};

export default NdiSigningConfirmationModal;
