import { dedocoAxios } from '../../lib/api/axios';
import {
  START_MEETING,
  START_MEETING_SUCCESS,
  START_MEETING_FAILED,
  JOIN_MEETING,
  JOIN_MEETING_SUCCESS,
  JOIN_MEETING_FAILED,
  START_RECORDING,
  START_RECORDING_SUCCESS,
  START_RECORDING_FAILED,
  STOP_RECORDING,
  STOP_RECORDING_SUCCESS,
  STOP_RECORDING_FAILED,
} from './actiontypes';

export function startPublicMeeting(bpId, hostInfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: START_MEETING,
      });
      dedocoAxios
        .post(`public/business-processes/${bpId}/start-meeting`, { host: hostInfo })
        .then(({ data }) => {
          dispatch({
            type: START_MEETING_SUCCESS,
            payload: data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: START_MEETING_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function joinPublicMeeting(bpId, participantInfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: JOIN_MEETING,
      });
      dedocoAxios
        .post(`public/business-processes/${bpId}/join-meeting`, { participant: participantInfo })
        .then(({ data }) => {
          dispatch({
            type: JOIN_MEETING_SUCCESS,
            payload: data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: JOIN_MEETING_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function startMeeting(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: START_MEETING,
      });
      dedocoAxios
        .post(`/businessprocess/meeting/start`, params)
        .then(({ data }) => {
          dispatch({
            type: START_MEETING_SUCCESS,
            payload: data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: START_MEETING_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function joinMeeting(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: JOIN_MEETING,
      });
      dedocoAxios
        .post(`/businessprocess/meeting/join`, params)
        .then(({ data }) => {
          dispatch({
            type: JOIN_MEETING_SUCCESS,
            payload: data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: JOIN_MEETING_FAILED,
          });
          reject(error);
        });
    });
  };
}

export function startRecording(businessProcessId, callback = () => {}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: START_RECORDING,
      });
      dedocoAxios
        .post(`/twilio/recording/start`, { businessProcessId })
        .then(() => {
          dispatch({
            type: START_RECORDING_SUCCESS,
          });
          callback(null);
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: START_RECORDING_FAILED,
          });
          callback(error);
          reject(error);
        });
    });
  };
}

export function stopRecording(businessProcessId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: STOP_RECORDING,
      });
      dedocoAxios
        .post(`/twilio/recording/stop`, { businessProcessId })
        .then(() => {
          dispatch({
            type: STOP_RECORDING_SUCCESS,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: STOP_RECORDING_FAILED,
          });
          reject(error);
        });
    });
  };
}
