import { dedocoDefaultThemeForDOM } from '../themes/dedocoTheme';

export function getElementBuilder(windowObject) {
  return (elementName, callback) => {
    const element = windowObject.document.createElement(elementName);
    return applyStyleToElementWithCallback(element, callback);
  };
}

export function applyStyleToElementWithCallback(element, callback) {
  const styles = callback(dedocoDefaultThemeForDOM, element.style);
  return applyStyleToElement(element, styles);
}

export function applyStyleToElement(element, styles) {
  for (const property in styles) {
    if (property === 'hover') {
      addHoverStyle(element, styles[property]);
    } else {
      element['style'][property] = styles[property];
    }
  }

  return element;
}

function addHoverStyle(element, styles) {
  const originalStyles = getStylesByProperty(element, Object.keys(styles));

  element.addEventListener('mouseenter', (event) => {
    applyStyleToElement(event.target, styles);
  });
  element.addEventListener('mouseleave', (event) => {
    applyStyleToElement(event.target, originalStyles);
  });
}

function getStylesByProperty(element, styleProperties) {
  const styles = {};
  for (const property of styleProperties) {
    styles[property] = element['style'][property];
  }
  return styles;
}
