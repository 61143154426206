import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';

export const BaseModalRoot = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    backgroundColor: theme.palette.background.main,
    boxShadow: 'none',
    borderRadius: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: 'auto',
      padding: theme.spacing(6, 4, 4, 4),
    },
  },
}));
