import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { classes, Root } from '../Sidebar/InfoSidebar/InfoSidebar.styles';
import { useTranslation } from 'react-i18next';
import { process_env } from '../../../utils/envData';

export function TryFreeSection({ sx }) {
  const { t } = useTranslation();

  if (process_env('REACT_APP_DISABLE_MARKETING') === 'true') {
    return null;
  }

  return (
    <>
      {/* Dedoco free trial marketing */}
      <Grid container className={classes.sideBox} sx={sx}>
        <Grid item>
          <Typography color="primary" variant="headline_small" className={classes.tryTitle}>
            {t('CompletionTryDedocoTitle')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body_medium">{t('CompletionTryDedocoText')}</Typography>
        </Grid>
        <Grid item>
          <Button
            href="https://www.dedoco.com/forms/lets-talk"
            target="_blank"
            variant="tinted"
            color="primary"
            startIcon={<ControlPointOutlinedIcon />}
            className={classes.createAccountButton}
          >
            {t('CompletionCreateAccountButton')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// eslint-disable-next-line react/display-name
const withRoot = (Component) => (props) => {
  return (
    <Root>
      <Component {...props} />
    </Root>
  );
};

export default withRoot(TryFreeSection);
