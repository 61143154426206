import { styled } from '@mui/material';

const PREFIX = 'DHCVerifyBox';

export const classes = {
  documentIcon: `${PREFIX}-documentIcon`,
  verifyDocumentHeader: `${PREFIX}-verifyDocumentHeader`,
  verifyDescription: `${PREFIX}-verifyDescription`,
  VerifyTermsAndCondition: `${PREFIX}-VerifyTermsAndCondition`,
  fileDrop: `${PREFIX}-fileDrop`,
  innerFileDrop: `${PREFIX}-innerFileDrop`,
  fileDropText: `${PREFIX}-fileDropText`,
  fileDropOr: `${PREFIX}-fileDropOr`,
  fileDropButton: `${PREFIX}-fileDropButton`,
  fileInput: `${PREFIX}-fileInput`,
  documentVerified: `${PREFIX}-documentVerified`,
  documentVerifiedMismatch: `${PREFIX}-documentVerifiedMismatch`,
  tryAgain: `${PREFIX}-tryAgain`,
  fileDropSpan: `${PREFIX}-fileDropSpan`,
};

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.supporting.light,
  border: `1px solid ${theme.palette.stroke.dark} !important`,
  boxShadow: 'none',
  borderRadius: `6px !important`,
  display: 'flex',
  flexDirection: 'Column',
}));

export const VerifyBoxSummary = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.supporting.light,
  maxHeight: 24,
  minHeight: 'auto',
  textAlign: 'Left',
  display: 'flex',
  flexDirection: 'Column',
  justifyContent: 'Left',
  alignItems: 'Left',
  margin: theme.spacing(4),
  marginBottom: theme.spacing(6),
  borderRadius: 6,

  [`& .${classes.verifyDocumentHeader}`]: {
    color: theme.palette.darkGrey.dark,
  },

  [`& .${classes.verifyDescription}`]: {
    color: theme.palette.text.secondary,
    marginBlock: theme.spacing(1),
  },
}));

export const VerifyBoxUpload = styled('div')(({ theme }) => ({
  marginInline: theme.spacing(4),
  marginTop: theme.spacing(10),
}));

export const VerifyTermsAndCondition = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  border: 'solid 2px',
  borderColor: theme.palette.lightGrey.border,
  marginInline: theme.spacing(4),
}));

export const VerifySuccessBoxContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'Column',
}));

export const VerifySuccessBox = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.palette.success.extraLight}`,
  border: `1px solid ${theme.palette.success.extraDark}`,
  padding: theme.spacing(4),
  borderRadius: 6,
  margin: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  textAlign: 'left',

  [`& .${classes.documentVerified}`]: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const VerifyFailureBox = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.palette.error.extraLight}`,
  border: `1px solid ${theme.palette.error.main}`,
  padding: theme.spacing(4),
  borderRadius: 6,
  margin: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  textAlign: 'left',
  height: 'fit-content',

  [`& .${classes.documentVerifiedMismatch}`]: {
    display: 'flex',
    gap: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },

  [`& .${classes.tryAgain}`]: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
