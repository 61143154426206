import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export const convertObjectKeyToCamelCase = (object) => {
  return mapKeys(object, (_, key) => camelCase(key));
};

export const deepAssign = (destObj, ...sources) => {
  return merge(destObj, ...sources);
};

export const deepCopy = (value) => {
  return cloneDeep(value);
};
