import { styled } from '@mui/material';

export const ExpiredSigningSessionRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(24, 14, 0, 14),
}));

export const ExpiredSigningSessionIcon = styled('img')(() => ({
  height: '60px',
  width: '60px',
}));

export const ExpiredSigningSessionHeader = styled('div')(() => ({
  paddingTop: '16px',
  fontSize: '20px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
}));

export const ExpiredSigningSessionMessage = styled('div')(({ theme }) => ({
  paddingTop: '16px',
  textAlign: 'center',
  fontSize: '15px',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  marginBottom: theme.spacing(16),
}));
