import { Checkbox } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { MediumInputBase } from '../MediumInputBase';

function CheckboxInputComponent(
  { inputId, onConfirmInput, onClose, title, submitButtonText, description, isChecked, isRequired },
  ref,
) {
  // checked stated pass to Checkbox component must be boolean
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!isChecked);
  }, [isChecked]);

  const closeInput = useCallback(() => {
    onClose(inputId);
  }, [inputId, onClose]);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
  }, []);

  const confirmInput = useCallback(() => {
    onConfirmInput(inputId, checked, {
      isCheckbox: true,
    });
  }, [inputId, onConfirmInput, checked]);

  useImperativeHandle(ref, () => ({ closeInput }), [closeInput]);

  return (
    <MediumInputBase
      onConfirmInput={confirmInput}
      onClose={closeInput}
      title={title}
      description={description}
      submitButtonText={submitButtonText}
      isRequired={isRequired}
    >
      <Checkbox
        sx={{ justifySelf: 'start', padding: 0 }}
        onChange={handleChange}
        checked={checked}
      />
    </MediumInputBase>
  );
}

export const CheckboxInput = forwardRef(CheckboxInputComponent);
