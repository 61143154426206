export const animateCSS = (element, animation) =>
  new Promise((resolve, _reject) => {
    const animationName = animation;
    const nodes = document.querySelectorAll(element);

    nodes.forEach((node) => node.classList.add(animationName));

    function handleAnimationEnd(event) {
      event.stopPropagation();
      nodes.forEach((node) => node.classList.remove(animationName));
      resolve('Animation ended');
    }

    nodes.forEach((node) =>
      node.addEventListener('animationend', handleAnimationEnd, { once: true }),
    );
  });
