export const FormFieldTypes = Object.freeze({
  esignatures: 'esignatures',
  digiSignatures: 'digiSignatures',
  initials: 'initials',
  stamps: 'stamps',
  checkboxes: 'checkboxes',
  dates: 'dates',
  inputs: 'inputs',
  dropdownLists: 'dropdownLists',
  fileUploads: 'fileUploads',
});

export const dFormRecaptchaAction = Object.freeze({
  FormSubmit: 'dFormSubmit',
});
