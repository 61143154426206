import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_IS_OPEN_API_V2 } from '../../../redux/actions/actiontypes';
import SigningRequest from '../SigningRequest';
import { dedocoTheme } from '../../../themes/dedocoTheme';
import { ThemeProvider } from '@mui/material';
import { PdfTronProvider } from '../../../contexts/usePdfTronContext';
import { NdiSigningProvider } from '../../../contexts/ndiSigningContexts/useNdiSigningContext';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export default function OpenApiV2() {
  const { path } = useRouteMatch();
  const documentToSign = useSelector((state) => state.documenttosign);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SET_IS_OPEN_API_V2,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!documentToSign.isOpenApiV2) return null;

  return (
    <ThemeProvider theme={dedocoTheme}>
      <PdfTronProvider>
        <NdiSigningProvider>
          <Switch>
            <Route exact path={path} component={SigningRequest} />
            <Route path={`${path}/:key`} component={SigningRequest} />
          </Switch>
        </NdiSigningProvider>
      </PdfTronProvider>
    </ThemeProvider>
  );
}
