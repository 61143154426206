import { Box, Button, styled, Tab, Tabs } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`signature-method-tabpanel-${index}`}
      aria-labelledby={`signature-method-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(6, 4, 4, 4),
  boxSizing: 'border-box',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.12)',
  display: 'grid',
  borderRadius: '8px',
  height: 'auto',
  width: '400px',
  position: 'relative',
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2, 3),
  fontSize: '0.75rem',
  minWidth: 'unset',
  maxWidth: 'unset',
  textTransform: 'none',
  letterSpacing: '0.1px',
  '&.Mui-selected': {
    borderRadius: '8px 8px 0px 0px',
    fontWeight: 700,
    color: theme.palette.primary.main,
    boxShadow: `inset 0 -3px 0 0 ${theme.palette.primary.main}`,
  },
}));

export const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.main,
}));

export const CustomTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const NextButton = styled(Button)(() => ({
  width: 'fit-content',
  alignSelf: 'end',
}));
