import {
  ExpiredSigningSessionRoot,
  ExpiredSigningSessionIcon,
  ExpiredSigningSessionHeader,
  ExpiredSigningSessionMessage,
} from './ExpiredSigningSession.styles';
import { RefreshQrButton } from '../Shared';
import { useTranslation } from 'react-i18next';
import AlertIcon from '../../../../../assets/AlertIcon.svg';

export const ExpiredSigningSession = ({ onRefreshQr }) => {
  const { t } = useTranslation();
  return (
    <ExpiredSigningSessionRoot>
      <ExpiredSigningSessionIcon src={AlertIcon} />
      <ExpiredSigningSessionHeader>
        {t('NdiSigningRequestExpiredHeader')}
      </ExpiredSigningSessionHeader>
      <ExpiredSigningSessionMessage>
        {t('NdiSigningRequestExpiredMessage')}
      </ExpiredSigningSessionMessage>
      <RefreshQrButton onClick={onRefreshQr}>{t('NdiRefreshQrButton')}</RefreshQrButton>
    </ExpiredSigningSessionRoot>
  );
};
