import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingCloseButton } from '../FloatingCloseButton';
import { classes, Root } from './DateInput.style';

function DateInputComponent(
  { inputId, onConfirmInput, onClose, submitButtonText, dateFormat = 'DD/MM/YYYY' },
  ref,
) {
  const [date, setDate] = useState();
  const disableSaveButton = Boolean(!date);
  const { t } = useTranslation();

  const closeInput = useCallback(() => {
    onClose(inputId);
  }, [inputId, onClose]);

  const confirmInput = useCallback(() => {
    onConfirmInput(inputId, date?.format(dateFormat));
  }, [inputId, date, onConfirmInput, dateFormat]);

  const onDateChange = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  useImperativeHandle(ref, () => ({ closeInput }), [closeInput]);

  return (
    <Root>
      <FloatingCloseButton onClick={closeInput} />
      <Box display="grid" direction="column" gap={4}>
        <Typography variant="title_medium" align="left">
          {t('DateInputTitle')}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker className={classes.calender} date={date} onChange={onDateChange} />
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSaveButton}
          onClick={confirmInput}
        >
          {submitButtonText}
        </Button>
      </Box>
    </Root>
  );
}

export const DateInput = forwardRef(DateInputComponent);
